import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const UpcomingIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='icons8-schedule_2_'
      data-name='icons8-schedule (2)'
      xmlns='http://www.w3.org/2000/svg'
      width='17.511'
      height='17.51'
      viewBox='0 0 17.511 17.51'
      {...props}
    >
      <path
        id='Path_13029'
        data-name='Path 13029'
        d='M18.3,21.009H8.71A2.69,2.69,0,0,1,6,18.3V14.547a.625.625,0,0,1,1.251,0V18.3A1.444,1.444,0,0,0,8.71,19.758H18.3A1.444,1.444,0,0,0,19.758,18.3V8.71A1.444,1.444,0,0,0,18.3,7.251H14.505a.625.625,0,1,1,0-1.251H18.3a2.69,2.69,0,0,1,2.71,2.71V18.3a2.69,2.69,0,0,1-2.71,2.71Z'
        transform='translate(-3.498 -3.499)'
        fill={palette.primary.main}
      />
      <path
        id='Line_1696'
        data-name='Line 1696'
        d='M7.38-.249H-.875A.625.625,0,0,1-1.5-.875.625.625,0,0,1-.875-1.5H7.38a.625.625,0,0,1,.625.625A.625.625,0,0,1,7.38-.249Z'
        transform='translate(9.505 7.337)'
        fill={palette.primary.main}
      />
      <path
        id='Line_1697'
        data-name='Line 1697'
        d='M-.875,1.627A.625.625,0,0,1-1.5,1V-.875A.625.625,0,0,1-.875-1.5a.625.625,0,0,1,.625.625V1A.625.625,0,0,1-.875,1.627Z'
        transform='translate(14.842 2.959)'
        fill={palette.primary.main}
      />
      <path
        id='Path_13030'
        data-name='Path 13030'
        d='M4.795,9.589A4.807,4.807,0,0,1,0,4.795a5.416,5.416,0,0,1,.14-1.22.625.625,0,0,1,1.221.271,4.16,4.16,0,0,0-.11.948A3.539,3.539,0,0,0,7.3,7.3a3.544,3.544,0,0,0-2.5-6.046,3.759,3.759,0,0,0-1.113.182.625.625,0,1,1-.359-1.2A5.018,5.018,0,0,1,4.795,0,4.789,4.789,0,0,1,8.181,8.181,4.761,4.761,0,0,1,4.795,9.589Z'
        transform='translate(0 0)'
        fill={palette.primary.main}
      />
      <path
        id='Path_13031'
        data-name='Path 13031'
        d='M11.459,9.169a.623.623,0,0,1-.442-.183l-.834-.834A.625.625,0,0,1,10,7.71V5.625a.625.625,0,0,1,1.251,0V7.451L11.9,8.1a.625.625,0,0,1-.442,1.068Z'
        transform='translate(-5.831 -2.915)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
