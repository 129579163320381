import { pickLocalizedValue, useTranslation } from "@toolkit/i18n"; // Adjust translation hook import for web
import { Box, format, formatDate, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { ComingSoonNotificationIcon } from "@/components";

type AppointmentReminderProps = {
  notificationType: string;
  englishText: string;
  arabicText: string;
  changes: { field: string; value: string }[];
  creationDate: string;
  isSeen: boolean;
};

export const AppointmentReminder: FC<AppointmentReminderProps> = props => {
  const { notificationType, englishText, arabicText, changes, creationDate, isSeen } = props;

  const { t, i18n } = useTranslation("consumer");
  const theme = useTheme();
  const lang = i18n.language;

  const { color } = getNotificationTextStyle(isSeen, theme);

  const confirmedStartTime = changes?.find(item => item?.field === "confirmedStartTime")?.value;
  const confirmedEndTime = changes?.find(item => item?.field === "confirmedEndTime")?.value;

  const appointmentDate = formatDate(confirmedStartTime!, lang);
  const startTime = format(confirmedStartTime!, "hh:mmA", lang);
  const endTime = format(confirmedEndTime!, "hh:mmA", lang);
  const formNowTzItem = moment(confirmedStartTime)?.fromNow();

  return (
    <NotificationCard
      title={notificationType === "CONFIRMATION" ? t("Appointment Confirmation") : t("Appointment Reminder")}
      date={creationDate}
      icon={<ComingSoonNotificationIcon />}
      iconColor={theme.palette.secondary.main}
      isSeen={isSeen}
    >
      <Box mt={2} display='flex' flexDirection='column'>
        <Typography color={color}>{pickLocalizedValue(englishText, arabicText)}</Typography>

        <Typography color={color}>
          <strong color={color}>{t("Appointment Date")}: </strong> {appointmentDate}
        </Typography>

        <Typography color={color}>
          <strong color={color}>{t("Appointment Time")}: </strong> {startTime} - {endTime}
        </Typography>

        <Typography color={color}>
          <strong color={color}>{t("Your Appointment in")}: </strong> {formNowTzItem || "-"}
        </Typography>
      </Box>
    </NotificationCard>
  );
};
