import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const FaceBookIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
      <path
        id='icons8-facebook_1_'
        data-name='icons8-facebook (1)'
        d='M18,3a15.074,15.074,0,0,0-2.243,29.969v-10.9H12.046V18.11h3.711V15.473c0-4.366,2.116-6.282,5.726-6.282a20.95,20.95,0,0,1,3.076.187v3.459H22.1c-1.532,0-2.067,1.461-2.067,3.107V18.11h4.491l-.609,3.963H20.03V33A15.074,15.074,0,0,0,18,3Z'
        transform='translate(-3 -3)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
