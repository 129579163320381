import { makeStyles, Theme } from "@toolkit/ui";

export const useStyle = makeStyles()((theme: Theme) => ({
  root: {
    height: "78px",
    color: "white",
    fontSize: "24px",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
  },
}));
