import React from "react";
import { Skeleton, Box } from "@toolkit/ui";
import { DoctorCardLayout } from "@/domains/doctor";
import { useStyles } from "./AppintmentDoctorCard.styles";
import { DoctorCardProps } from "@/domains/doctor/DoctorCard/DoctorCardLayout";

type AppointmentDoctorCardSkeletonProps = {
  classes?: DoctorCardProps["classes"];
};
const AppointmentDoctorCardSkeleton: React.FC<AppointmentDoctorCardSkeletonProps> = props => {
  const { classes: _classes } = props;
  const { classes, cx } = useStyles();

  return (
    <DoctorCardLayout
      classes={{ card: cx(_classes?.card) }}
      vendorAvatarNode={<Skeleton variant='circular' width={100} height={30} className={classes.vendorAvatar} />}
      infoNode={
        <Box>
          <Skeleton variant='text' width={180} height={20} />
          <Skeleton variant='text' width={190} height={16} />
          <Skeleton variant='text' width={180} height={16} />
          <Skeleton variant='text' width={80} height={16} />
          <Skeleton variant='text' width={100} height={16} />
        </Box>
      }
      doctorAvatarNode={<Skeleton variant='circular' width={80} height={80} />}
      actionsNode={
        <Box display='flex' gap={2.5} paddingBottom={"30px"} marginTop={"5px"}>
          <Skeleton variant='circular' width={53} height={53} />
          <Skeleton variant='circular' width={53} height={53} />
          <Skeleton variant='circular' width={53} height={53} />
        </Box>
      }
    />
  );
};

export default AppointmentDoctorCardSkeleton;
