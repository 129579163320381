import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const Time: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='21.818' viewBox='0 0 20 21.818' {...props}>
      <path
        id='icons8-time'
        d='M13.091,2a.909.909,0,0,0-.909.909V3.992a10,10,0,1,0,3.636,0V2.909A.909.909,0,0,0,14.909,2Zm8.173,1.809A.909.909,0,0,0,20.63,5.37l.909.909a.909.909,0,1,0,1.286-1.286l-.909-.909a.909.909,0,0,0-.652-.275ZM14,5.636a8.182,8.182,0,1,1-8.182,8.182A8.168,8.168,0,0,1,14,5.636Zm-.014,1.806a.909.909,0,0,0-.895.922v3.883a1.814,1.814,0,0,0,0,3.145v1.154a.909.909,0,1,0,1.818,0V15.39a1.814,1.814,0,0,0,0-3.145V8.364a.909.909,0,0,0-.923-.922Z'
        transform='translate(-4 -2)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
export default Time;
