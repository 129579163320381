import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const HealthBookings: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='37.926' height='37.926' viewBox='0 0 37.926 37.926' {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='37.926' height='37.926' viewBox='0 0 37.926 37.926'>
        <path
          id='icons8-calendar'
          d='M38.478,4H7.448A3.447,3.447,0,0,0,4,7.448v31.03a3.447,3.447,0,0,0,3.448,3.448h31.03a3.447,3.447,0,0,0,3.448-3.448V7.448A3.447,3.447,0,0,0,38.478,4ZM9.172,36.754A1.724,1.724,0,1,1,10.9,35.03,1.725,1.725,0,0,1,9.172,36.754Zm0-6.9A1.724,1.724,0,1,1,10.9,28.135,1.725,1.725,0,0,1,9.172,29.858Zm6.9,6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,16.067,36.754Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,16.067,29.858Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,16.067,22.963Zm6.9,13.791a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,22.963,36.754Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,22.963,29.858Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,22.963,22.963Zm6.9,13.791a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,29.858,36.754Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,29.858,29.858Zm-1.724-8.619a1.724,1.724,0,1,1,1.724,1.724A1.725,1.725,0,0,1,28.135,21.239Zm8.619,8.619a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,36.754,29.858Zm0-6.9a1.724,1.724,0,1,1,1.724-1.724A1.725,1.725,0,0,1,36.754,22.963Zm1.724-8.619H7.448v-6.9h31.03Z'
          transform='translate(-4 -4)'
          fill={palette.common.white}
        />
      </svg>
    </SvgIcon>
  );
};
export default HealthBookings;
