import { RejoinCallButton } from "@/domains/VirtualVisit/components/RejoinCallButton/RejoinCallButton";
import { openMeetingPlatformLink, ReJoinableVisitStatuses } from "@/domains/VirtualVisit/utils";
import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { AmountSourceType, Appointment, AppointmentStatus, AppointmentType, PaymentStatus, VisitStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AppointmentCancelationModal } from "../../components/AppointmentCancelationModal/AppointmentCancelationModal";
import { routes } from "../../routes";
import { getConfirmDoctorTimeContainerData } from "../../utils/confirm-doctor-time.utils";
import { useAppointmentNotificationDetailsActionsStyles } from "./useAppointmentNotificationDetailsActionsStyles";

type AppointmentNotificationDetailsActionsProps = {
  appointmentStatus: AppointmentStatus;
  paymentStatus?: PaymentStatus;
  appointmentId: string;
  doctorId: string;
  appointment: Appointment;
};

export const AppointmentNotificationDetailsActions: FC<AppointmentNotificationDetailsActionsProps> = ({
  appointmentStatus,
  paymentStatus,
  appointmentId,
  doctorId,
  appointment,
}) => {
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { classes } = useAppointmentNotificationDetailsActionsStyles();

  const handleReschedule = () => {
    navigate(routes.reschedule.getRouteWithParams({ appointmentId, doctorId }));
  };

  const handleConfirmClick = () => {
    navigate(routes.confirmDoctorTime.getRouteWithParams({ appointmentId, doctorId }), {
      state: getConfirmDoctorTimeContainerData(appointment),
    });
  };

  const handleJoin = (meetingPlatformLinkForJoinCall?: string | null) => {
    meetingPlatformLinkForJoinCall && openMeetingPlatformLink(meetingPlatformLinkForJoinCall);
  };

  const canRejoin = ReJoinableVisitStatuses.includes(appointment?.visit?.status as VisitStatus);

  const handlePayNow = () => {
    console.log("from not");
    PayNowDialog.open();
  };
  return (
    <>
      <PayNowDialog
        amountSource={AmountSourceType.Appointment}
        amountSourceId={appointmentId}
        onSuccess={() => {
          console.log("Payment Done, success");
        }}
        onError={() => {
          console.log("Payment Done, error");
        }}
      />
      {(appointmentStatus === AppointmentStatus.Approved || appointmentStatus === AppointmentStatus.Confirmed) && (
        <Box className={classes.footer}>
          <Box display='flex' flex={3} alignItems='center' justifyContent='start'>
            <Button className={classes.button} onClick={handlePayNow}>
              {t("Pay Now")}
            </Button>
            <Button variant='outlined' className={classes.rescheduleButton} onClick={handleReschedule}>
              {t("Reschedule")}
            </Button>
          </Box>
          <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
            <AppointmentCancelationModal id={appointmentId} />
          </Box>
        </Box>
      )}
      {appointmentStatus === AppointmentStatus.ComingSoon &&
        paymentStatus !== PaymentStatus.PaymentSucceeded &&
        paymentStatus !== PaymentStatus.FullyCovered && (
          <Box className={classes.footer}>
            <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
              <Button variant='outlined' className={classes.button} onClick={handlePayNow}>
                {t("Pay Now")}
              </Button>
            </Box>
          </Box>
        )}
      {appointmentStatus === AppointmentStatus.RescheduledByDoctor && (
        <Box className={classes.footer}>
          <Box display='flex' flex={3} alignItems='center' justifyContent='start'>
            <Button className={classes.button} onClick={handleConfirmClick}>
              {t("Confirm")}
            </Button>
            <AppointmentCancelationModal id={appointmentId} />
          </Box>
          <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
            <Button variant='outlined' className={classes.rescheduleButton} onClick={handleReschedule}>
              {t("Reschedule")}
            </Button>
          </Box>
        </Box>
      )}
      {appointmentStatus === AppointmentStatus.CallInProgress && (
        <Box className={classes.footer}>
          <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
            <Button
              disabled={!appointment?.visit?.meetingPlatformLinkForJoinCall}
              className={classes.button}
              onClick={() => handleJoin(appointment?.visit?.meetingPlatformLinkForJoinCall)}
            >
              {t("Join Now")}
            </Button>
          </Box>
        </Box>
      )}
      {appointmentStatus === AppointmentStatus.FinishedSuccessfully && appointment.type === AppointmentType.Online && (
        <Box className={classes.footer}>
          <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
            <RejoinCallButton isDisabled={canRejoin} className={classes.button} visitId={appointment.visit?.id || ""} />
          </Box>
        </Box>
      )}
    </>
  );
};
