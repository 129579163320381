import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box, format, Typography, useTheme } from "@toolkit/ui";
import { getNotificationTextStyle } from "@/domains/notifications/utils";
import { AppointmentItemProps } from "@/domains/notifications/types";
import { safeJsonParseWithDefault } from "@/domains/appointment/utils";

export const AppointmentDateTime: FC<AppointmentItemProps> = ({ appointmentDate, requestedTimeSlots, showTimes = true, isSeen }) => {
  const { t, i18n } = useTranslation("consumer");
  const lang = i18n.language;
  const theme = useTheme();
  const { color } = getNotificationTextStyle(isSeen!, theme);

  const times = requestedTimeSlots
    ?.map(_time => {
      const time = safeJsonParseWithDefault(_time, {
        startTime: "",
        endTime: "",
      });
      const start = format(time?.startTime, "hh:mm A", lang);
      const end = format(time?.endTime, "hh:mm A", lang);
      return `${start} - ${end}`;
    })
    .join(", ");

  return (
    <Box>
      {appointmentDate && (
        <Typography sx={{ color }}>
          <Typography component='span' fontWeight='bold'>
            {t("Appointment Date")}
          </Typography>
          : {appointmentDate}
        </Typography>
      )}
      {showTimes && requestedTimeSlots && requestedTimeSlots?.length > 0 && (
        <Typography sx={{ color }}>
          <Typography component='span' fontWeight='bold'>
            {t("Requested Timeframe")}
          </Typography>
          : {times}
        </Typography>
      )}
    </Box>
  );
};
