import { IconButton, styled, IconButtonProps } from "@toolkit/ui";

interface CircularIconButtonProps extends IconButtonProps {
  width?: number | string;
  height?: number | string;
}

export const CircularIconButton = styled(IconButton)<CircularIconButtonProps>(({ theme, height, width }) => ({
  width: width || height,
  height: height || width,
  borderRadius: "50%",
  boxShadow: theme.mixins.shadows.sm,
  border: "2px solid",
  borderColor: theme.palette.common.white,
  backgroundColor: theme.palette.background.default,
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[200],
  },
}));
