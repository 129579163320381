/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID'];
}>;


export type SurveyQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id?: string | null, description?: string | null, notification?: string | null, label?: string | null, questions?: Array<{ __typename?: 'Question', options?: Array<string | null> | null, question?: string | null, required?: boolean | null, questionType?: Types.QuestionType | null, id?: string | null }> | null } | null };


export const SurveyDocument = gql`
    query Survey($surveyId: ID!) {
  survey(id: $surveyId) {
    id
    description
    notification
    label
    questions {
      options
      question
      required
      questionType
      id
    }
  }
}
    `;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;