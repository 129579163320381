import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const RejectedNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-event-declined'
        d='M10.946,3.478a.875.875,0,0,0-.862.888V4.95H9.793A3.8,3.8,0,0,0,6,8.742v13.42a3.8,3.8,0,0,0,3.793,3.793h5.381a.875.875,0,1,0,0-1.751H9.793A1.984,1.984,0,0,1,7.75,22.162V8.742A1.984,1.984,0,0,1,9.793,6.7h.292v.292a.875.875,0,1,0,1.751,0V6.7H21.17v.292a.875.875,0,1,0,1.751,0V6.7h.292a1.984,1.984,0,0,1,2.042,2.042v.875H10.209a.875.875,0,1,0,0,1.751H25.255v2.707a.875.875,0,1,0,1.751,0V10.636a.875.875,0,0,0,0-.284V8.742A3.8,3.8,0,0,0,23.213,4.95h-.292V4.366a.875.875,0,1,0-1.75,0V4.95H11.835V4.366a.875.875,0,0,0-.889-.888Zm5.573,9.373a1.613,1.613,0,0,0-1.437.825l-.009.017-.446.864a.875.875,0,1,0,1.556.8l.336-.652.655,1.27a1.393,1.393,0,0,0,.948.734q.041.01.082.016l.746.113a6.445,6.445,0,0,0-2.162,3.141,1.047,1.047,0,0,0-.874.194l-1.5.711.247-1.646a.875.875,0,0,0,.009-.13,1.681,1.681,0,0,0-.291-.916.875.875,0,0,0-.109-.133l-1.085-1.085a.875.875,0,0,0-.3-1.7h-.1a.875.875,0,0,0-.133.01,1.657,1.657,0,0,0-1.3,1.078,1.627,1.627,0,0,0,.389,1.648L12.9,19.167l-.256,1.709a1.624,1.624,0,0,0,2.334,1.676l-.044.023,1.569-.743c0,.013,0,.025,0,.038a6.408,6.408,0,0,0,1.739,4.393.875.875,0,0,0,.651.284.875.875,0,0,0,.83-.24l5.953-5.953a.875.875,0,1,0-1.238-1.238l-5.322,5.322a4.545,4.545,0,0,1-.863-2.568,4.668,4.668,0,1,1,4.668,4.668,4.712,4.712,0,0,1-.778-.065.875.875,0,1,0-.288,1.727,6.418,6.418,0,1,0,1.067-12.748,6.367,6.367,0,0,0-1.717.242,1.6,1.6,0,0,0-.816-.408l-.014,0-1.722-.259-.687-1.331-.009-.017A1.613,1.613,0,0,0,16.519,12.852Z'
        transform='translate(6 8)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
