import React, { FC } from "react";
import { SvgIconProps, useTheme } from "../../base/mui";
export const PasswordIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <svg fill={palette.primary.main} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px' {...props}>
      <path d='M 15 2 C 11.145666 2 8 5.1456661 8 9 L 8 11 L 6 11 C 4.895 11 4 11.895 4 13 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 13 C 26 11.895 25.105 11 24 11 L 22 11 L 22 9 C 22 5.2715823 19.036581 2.2685653 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.773666 4 20 6.2263339 20 9 L 20 11 L 10 11 L 10 9 C 10 6.2263339 12.226334 4 15 4 z M 9 17 C 10.105 17 11 17.895 11 19 C 11 20.104 10.105 21 9 21 C 7.895 21 7 20.104 7 19 C 7 17.895 7.895 17 9 17 z M 15 17 C 16.105 17 17 17.895 17 19 C 17 20.104 16.105 21 15 21 C 13.895 21 13 20.104 13 19 C 13 17.895 13.895 17 15 17 z M 21 17 C 22.105 17 23 17.895 23 19 C 23 20.104 22.105 21 21 21 C 19.895 21 19 20.104 19 19 C 19 17.895 19.895 17 21 17 z' />
    </svg>
  );
};

export default PasswordIcon;
