import { makeStyles } from "@toolkit/ui";

const usePaymentWebViewStyle = makeStyles()(() => {
  return {
    root: {
      flexGrow: 1,
      height: "100%",
      marginBottom: -20,
    },
    listEmpty: {
      gap: 10,
    },
    webView: {
      flex: 1,
    },
  };
});

export default usePaymentWebViewStyle;
