import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const ErX: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='47.398' height='55.298' viewBox='0 0 47.398 55.298' {...props}>
      <path
        id='icons8-pharmacy'
        d='M7,4V38.232h7.9V29.016h7.506l9.6,15.457L22.8,59.3h9.3l6.5-10.461L45.1,59.3h9.3L44.991,44.157,54.4,29.016H45.1L38.4,39.793l-7.645-12.3A12.5,12.5,0,0,0,24.774,4H7ZM9.633,6.633H24.774a9.873,9.873,0,0,1,4.719,18.546L27.05,26.514l1.471,2.366,7.645,12.3L37.6,43.5h1.6l1.437-2.314,5.925-9.535h3.1L42.754,42.768l-.861,1.389.861,1.389,6.907,11.119h-3.1l-5.727-9.219-.818-1.314H37.179l-.818,1.314-5.727,9.219h-3.1l6.709-10.8.864-1.391-.864-1.389-9.6-15.457-.774-1.245h-11.6V35.6H9.633V6.633Zm2.633,2.633V23.749H24.774a7.241,7.241,0,0,0,0-14.483H12.266ZM14.9,11.9h9.875a4.608,4.608,0,0,1,0,9.216H14.9Z'
        transform='translate(-7 -4)'
        fill={props.fill || "#22d5cb"}
      />
    </SvgIcon>
  );
};
