import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const OnlineIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='16.255' height='17.592' viewBox='0 0 16.255 17.592' {...props}>
      <path
        id='icons8-play_4_'
        data-name='icons8-play (4)'
        d='M7.54,4.109A2.017,2.017,0,0,0,5.6,6.091V19.718a2.01,2.01,0,0,0,2.954,1.74l4.742-2.627a.663.663,0,1,0-.643-1.16L7.907,20.3a.644.644,0,0,1-.985-.58V6.091a.644.644,0,0,1,.985-.58l1.444.8A.663.663,0,1,0,9.994,5.15L8.55,4.35A1.914,1.914,0,0,0,7.54,4.109Zm4.242,2.119a.663.663,0,0,0-.326,1.248l8.75,4.847a.644.644,0,0,1,0,1.161l-4.691,2.6a.664.664,0,1,0,.643,1.161l4.69-2.6a2.012,2.012,0,0,0,0-3.481L12.1,6.316A.663.663,0,0,0,11.781,6.229Z'
        transform='translate(-5.596 -4.108)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
