import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const PaymentCardIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='Group_15473'
      data-name='Group 15473'
      width='19.268'
      height='13.763'
      viewBox='0 0 19.268 13.763'
      {...props}
    >
      <g id='icons8-debit-card' transform='translate(0 0)'>
        <path
          id='Path_23383'
          data-name='Path 23383'
          d='M25.456,22.763H15.088a.688.688,0,0,1,0-1.376H25.456a1.589,1.589,0,0,0,1.606-1.606v-7.8a1.589,1.589,0,0,0-1.606-1.606.688.688,0,0,1,0-1.376,2.96,2.96,0,0,1,2.982,2.982v7.8a2.96,2.96,0,0,1-2.982,2.982Z'
          transform='translate(-9.17 -9)'
          fill={fill}
        />
        <path
          id='Path_23384'
          data-name='Path 23384'
          d='M5.982,22.763A2.96,2.96,0,0,1,3,19.781v-7.8A2.96,2.96,0,0,1,5.982,9H14.7a.688.688,0,0,1,0,1.376H5.982a1.589,1.589,0,0,0-1.606,1.606v7.8a1.589,1.589,0,0,0,1.606,1.606.688.688,0,0,1,0,1.376Z'
          transform='translate(-3 -9)'
          fill={fill}
        />
        <path
          id='Path_23385'
          data-name='Path 23385'
          d='M11.753,19.615H9.918A.92.92,0,0,1,9,18.7v-.78A.92.92,0,0,1,9.918,17h1.835a.92.92,0,0,1,.918.918v.78A.92.92,0,0,1,11.753,19.615Z'
          transform='translate(-6.247 -13.33)'
          fill={fill}
        />
        <path
          id='Line_1938'
          data-name='Line 1938'
          d='M.564-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.564a.688.688,0,0,1,.688.688A.688.688,0,0,1,.564-.124Z'
          transform='translate(4.253 10.216)'
          fill={fill}
        />
        <path
          id='Line_1939'
          data-name='Line 1939'
          d='M.564-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.564a.688.688,0,0,1,.688.688A.688.688,0,0,1,.564-.124Z'
          transform='translate(7.923 10.216)'
          fill={fill}
        />
        <path
          id='Line_1940'
          data-name='Line 1940'
          d='M.564-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.564a.688.688,0,0,1,.688.688A.688.688,0,0,1,.564-.124Z'
          transform='translate(11.593 10.216)'
          fill={fill}
        />
        <path
          id='Line_1941'
          data-name='Line 1941'
          d='M.564-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.564a.688.688,0,0,1,.688.688A.688.688,0,0,1,.564-.124Z'
          transform='translate(15.263 10.216)'
          fill={fill}
        />
        <path
          id='Line_1942'
          data-name='Line 1942'
          d='M5.152-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H5.152a.688.688,0,0,1,.688.688A.688.688,0,0,1,5.152-.124Z'
          transform='translate(10.675 5.17)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
