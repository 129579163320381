import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const InPersonVisit: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22.089' height='23.857' viewBox='0 0 22.089 23.857'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={palette.primary.main} />
          <stop offset='1' stopColor={palette.secondary.main} />
        </linearGradient>
      </defs>
      <path
        id='icons8-permanent-job'
        d='M8.534,3a.884.884,0,1,0,0,1.767h10.6a.884.884,0,1,0,0-1.767ZM5.884,6.534a.884.884,0,1,0,0,1.767v15.9h5.3V19.788h5.3v3.286a7.308,7.308,0,0,1,1.886-.941l.179-.067A4.75,4.75,0,0,1,18,21.156a2.916,2.916,0,0,1-.761-2.023,2.821,2.821,0,0,1,.031-.425A4.108,4.108,0,0,1,17,17.284a4.451,4.451,0,0,1,1.248-3.153V12.719h1.767v.3a5.39,5.39,0,0,1,1.769-.3V8.3a.884.884,0,1,0,0-1.767ZM7.651,9.185H9.418v1.767H7.651Zm3.534,0h1.767v1.767H11.185Zm3.534,0h1.767v1.767H14.719Zm3.534,0h1.767v1.767H18.253Zm-10.6,3.534H9.418v1.767H7.651Zm3.534,0h1.767v1.767H11.185Zm3.534,0h1.767v1.767H14.719Zm7.069,1.765a2.763,2.763,0,0,0-3.015,2.8,2.555,2.555,0,0,0,.425,1.35s-.193.095-.193.5a.965.965,0,0,0,.507.977c.071.624,1.039,1.538,1.039,1.538v1.041c-.52,1.563-4.064.668-4.064,4.168h10.6c0-3.5-3.544-2.6-4.064-4.168V21.648s.969-.914,1.039-1.538a1.269,1.269,0,0,0,.507-.977c0-.435-.193-.5-.193-.5a3.251,3.251,0,0,0,.347-1.291c0-1.264-.633-2.238-1.7-2.238A1.644,1.644,0,0,0,21.788,14.485ZM7.651,16.254H9.418v1.767H7.651Zm3.534,0h1.767v1.767H11.185Zm3.534,0h1.767v1.767H14.719ZM7.651,19.788H9.418v1.767H7.651Z'
        transform='translate(-5 -3)'
        fill='url(#linear-gradient)'
      />
    </svg>
  );
};
