/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCategoriesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.MarketplaceCategoryFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceDefaultSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type MarketplaceProductCategoriesQuery = { __typename?: 'Query', marketplaceProductCategories?: { __typename?: 'MarketplaceProductCategoryConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceProductCategoryEdge', node: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null, image?: string | null } }> } | null };


export const MarketplaceProductCategoriesDocument = gql`
    query MarketplaceProductCategories($first: Int, $filter: MarketplaceCategoryFilterInput, $after: String, $sortBy: MarketplaceDefaultSortingInput, $before: String, $last: Int) {
  marketplaceProductCategories(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
        image
      }
    }
  }
}
    `;

/**
 * __useMarketplaceProductCategoriesQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMarketplaceProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceProductCategoriesQuery, MarketplaceProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductCategoriesQuery, MarketplaceProductCategoriesQueryVariables>(MarketplaceProductCategoriesDocument, options);
      }
export function useMarketplaceProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductCategoriesQuery, MarketplaceProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductCategoriesQuery, MarketplaceProductCategoriesQueryVariables>(MarketplaceProductCategoriesDocument, options);
        }
export type MarketplaceProductCategoriesQueryHookResult = ReturnType<typeof useMarketplaceProductCategoriesQuery>;
export type MarketplaceProductCategoriesLazyQueryHookResult = ReturnType<typeof useMarketplaceProductCategoriesLazyQuery>;
export type MarketplaceProductCategoriesQueryResult = Apollo.QueryResult<MarketplaceProductCategoriesQuery, MarketplaceProductCategoriesQueryVariables>;