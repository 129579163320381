import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const OrderBox: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='20.012' viewBox='0 0 20 20.012' {...props}>
      <path
        id='icons8-product'
        d='M13.95,3.968a.419.419,0,0,0-.045.015H7.24a.47.47,0,0,0-.208.1L4.22,6.437l-.045.03a.452.452,0,0,0-.06.045L4.1,6.526v.015H4.086v.03c-.011.015-.02.03-.03.045a.4.4,0,0,0-.045.089A.5.5,0,0,0,4,6.794a.359.359,0,0,0,0,.089V23.5a.477.477,0,0,0,.476.476H23.517a.477.477,0,0,0,.476-.476V6.913a.5.5,0,0,0,0-.074V6.765l-.015-.015V6.705a.222.222,0,0,0-.015-.03l-.015-.015v-.03l-.015-.015a.222.222,0,0,0-.015-.03L23.9,6.571l-.015-.015a.222.222,0,0,0-.015-.03l-.015-.015L23.844,6.5l-.015-.015-.015-.015-.045-.03a.225.225,0,0,0-.03-.03L20.958,4.087a.479.479,0,0,0-.3-.1H14.1A.446.446,0,0,0,13.95,3.968Zm-6.442.967h6.011V6.363H5.8Zm6.963,0h6.011l1.711,1.428H14.471ZM4.949,7.315H23.041V23.026H4.949Zm7.379,1.9a1.19,1.19,0,0,0,0,2.38h3.333a1.19,1.19,0,1,0,0-2.38Zm0,.952h3.333a.238.238,0,1,1,0,.476H12.329a.238.238,0,0,1,0-.476Zm4.523,6.665-1.428,1.428h.952v1.9h.952v-1.9h.952Zm3.333,0-1.428,1.428h.952v1.9h.952v-1.9h.952Zm-4.761,3.809V21.6h6.189v-.952Z'
        transform='translate(-3.994 -3.966)'
        fill={props?.fill ? props?.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
