import { routes } from "@/domains/VisitCall/routes";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const VisitCallRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.root.route} element={<routes.root.component />} />
    </Routes>
  );
};
