import React, { FC } from "react";
import { SvgIconProps } from "../../base/mui";
export const EmptyLineChart: FC<SvgIconProps> = ({ width }) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={width}
      height={200}
      viewBox='0 0 236 266'
      enableBackground='new 0 0 256 256'
    >
      <g>
        <g>
          <g>
            <path
              fill='#ced5e1'
              d='M232.9,29.5c-3.3,1.5-5.3,4.6-5.7,8.6l-0.3,2.6l-24.3,22.4l-24.3,22.5h-2.5c-1.4,0-3.1,0.2-3.9,0.5c-1.3,0.5-2.3,0-17.9-9.5l-16.6-9.9l-0.3-1.9c-1.1-6.3-8.3-9.5-13.8-6.3c-2.7,1.6-4.1,3.8-4.5,7.1l-0.3,2.5l-17,13.6l-17,13.6L82.4,95c-1.2-0.1-3,0-4.2,0.3c-2.7,0.7-5.7,3.7-6.4,6.3l-0.5,1.9l-23.1,10.7L25,124.9l-1.8-0.7c-4.2-1.7-9.1-0.3-11.7,3.4c-1.2,1.8-1.4,2.4-1.4,5.2c0,2.8,0.2,3.4,1.4,5.2c1.8,2.5,4.7,4.1,7.8,4.1c4.4,0,7.4-2.2,9-6.5l0.7-1.9l23.1-10.7l23.1-10.7l1.8,0.8c2.6,1.2,5.2,1.1,8-0.4c2.9-1.4,4.7-4.1,5.1-7.6l0.3-2.3l17-13.6l17-13.6l2.3,0.3c1.3,0.1,3.2,0,4.3-0.3l2-0.6l16.3,9.7c8.9,5.4,16.3,10,16.4,10.3c0,0.3,0.4,1.4,0.6,2.5c1.1,4,4.7,6.6,9,6.6c2.6,0,5.3-1.1,6.8-2.7c1.3-1.5,2.7-4.9,2.7-6.9v-2l24.5-22.6c23-21.3,24.6-22.6,25.9-22.4c5.4,0.9,10.8-3.7,10.9-9.2c0-2.7-2-6.4-4.2-7.9C239.5,28.9,235.1,28.5,232.9,29.5z'
            />
            <path
              fill='#ced5e1'
              d='M232.9,152.4c-3.5,1.6-5.8,5.1-5.8,8.9v2l-24.5,22.6l-24.5,22.6l-2.9-0.1l-2.8-0.1l-17.6-17.6L137.2,173v-2.4c0-3-0.8-4.9-2.9-7c-5.6-5.3-15.5-1.3-15.5,6.4v1.8l-17.3,13.8l-17.3,13.8l-1.7-0.4c-2.7-0.6-6,0.3-8.3,2.4c-0.9,0.9-1.3,0.8-23.5-2.6c-12.4-1.9-22.5-3.5-22.6-3.6c-0.7-1.7-2.8-3.8-4.6-4.7c-3-1.5-5.5-1.5-8.6,0.1c-2,1-2.7,1.8-3.7,3.7c-2.2,4.1-1.4,8.4,2.1,11.4c3.5,3.1,8.6,3.1,12,0.1l1.5-1.3l22.3,3.4c22,3.4,22.4,3.5,23.3,4.7c2.5,3.4,4.9,4.7,8.4,4.7c4.7,0,8.6-3.5,9.2-8.4l0.3-2.6l16.9-13.6l17-13.6l3.1,0.2l3.1,0.2l17.8,17.7l17.8,17.7l-0.1,1.9c-0.3,2.6,1,5.6,3.1,7.5c6.2,5.6,15.7,1,15.7-7.6c0-1.7,0.8-2.4,24.5-24.3l24.5-22.6l1.9,0.3c3.7,0.5,7.8-1.9,9.5-5.6C248.6,157.1,240.4,148.9,232.9,152.4z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
