import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, makeStyles, useMediaQuery, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

type ReasonsBaseModalProps = {
  open: boolean;
  onClose?: () => void;
  title: string;
  isConfirmDisabled?: boolean;
  handleConfirm?: () => void;
};

const useStyles = makeStyles()(theme => ({
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: theme.spacing(2),
    width: "100%",
  },
  button: {
    width: "100%",
    borderRadius: "24px",
  },
  content: {
    maxHeight: "400px",
  },
}));

export const ReasonsBaseModal: FC<PropsWithChildren<ReasonsBaseModalProps>> = props => {
  const { title, open, onClose, handleConfirm, children, isConfirmDisabled } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("consumer");
  const { classes } = useStyles();

  return (
    <>
      {!isMobileView && (
        <CustomDialog
          maxWidth='sm'
          type='base'
          open={open}
          DialogTitleProps={{
            hasCloseButton: true,
            onClose,
            title,
          }}
          DialogActionsProps={{
            children: (
              <Box>
                <Button onClick={handleConfirm} disabled={isConfirmDisabled}>
                  {t("Confirm")}
                </Button>
                <Button variant='outlined' onClick={onClose}>
                  {t("Close")}
                </Button>
              </Box>
            ),
          }}
        >
          {children}
        </CustomDialog>
      )}
      {isMobileView && (
        <CustomDialog
          classes={{ content: classes.content }}
          type='bottomSheet'
          open={open}
          handleClose={onClose}
          title={title}
          footer={
            <Box className={classes.actionsContainer}>
              <Button className={classes.button} onClick={handleConfirm} disabled={isConfirmDisabled}>
                {t("Confirm")}
              </Button>
              <Button className={classes.button} variant='outlined' onClick={onClose}>
                {t("Close")}
              </Button>
            </Box>
          }
        >
          {children}
        </CustomDialog>
      )}
    </>
  );
};
