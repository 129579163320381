import { Box } from "@toolkit/ui";
import React, { FC } from "react";
import { useStyle } from "./EmptyMessageLayoutStyle";

type EmptyMessageLayoutProps = {
  icon?: React.ReactNode;
  message?: React.ReactNode;
  subMessage?: React.ReactNode;
  action?: React.ReactNode;
  classes?: Partial<ReturnType<typeof useStyle>["classes"]>;
};

export const EmptyMessageLayout: FC<EmptyMessageLayoutProps> = props => {
  const { icon, message, subMessage, action } = props;
  const { classes } = useStyle();
  return (
    <Box className={classes.root}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.messageContainer}>{message}</Box>
      <Box className={classes.subMessageContainer}>{subMessage}</Box>
      <Box className={classes.actionContainer}>{action}</Box>
    </Box>
  );
};
