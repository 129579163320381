import { MainPageWithAppBarLayout } from "@/components";
import { VisitStartingNowCard } from "@/domains/VirtualVisit/components/VisitStartingNowCard/VisitStartingNowCard";
import AccountSettingsPage from "@/pages/AccountSettings/page";
import { AppointmentsRoutes } from "@/pages/Appointments/AppointmentRoutes";
import { HomeRoutes } from "@/pages/Home/HomeRoutes";
import { LoginPage } from "@/pages/login";
import { NotificationsRoutes } from "@/pages/Notifications/NotificationsRoutes";
import PageNotFound from "@/pages/PageNotFound";
import PageUnderConstruction from "@/pages/PageUnderConstruction";
import { paymentCallBackPagesRoutes } from "@/pages/PaymentCallback/route";
import Playground from "@/pages/Playground";
import { VisitCallRoutes } from "@/pages/VisitCall/VistCallRoutes";
import { FallbackComponent } from "@toolkit/ui";
import { FC, Suspense, useEffect } from "react";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { Route, Routes, useLocation } from "react-router-dom";
import { routesPaths } from "./routesPaths";

export const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageWithAppBarLayout />}>
        <Route path='404' element={<PageNotFound />} />
        <Route path='soon' element={<PageUnderConstruction />} />
      </Route>
      <Route path='login' element={<LoginPage />} />
      <Route path={paymentCallBackPagesRoutes.success.absRoute} Component={paymentCallBackPagesRoutes.success.component} />
      <Route path={paymentCallBackPagesRoutes.error.absRoute} Component={paymentCallBackPagesRoutes.error.component} />
      <Route path={paymentCallBackPagesRoutes.refunded.absRoute} Component={paymentCallBackPagesRoutes.refunded.component} />
      <Route path={paymentCallBackPagesRoutes.root.absRoute} Component={paymentCallBackPagesRoutes.root.component} />

      <Route path={routesPaths.playGround + "/*"} element={<Playground />} />
      <Route element={<VisitStartingNowCard />}>
        <Route path={routesPaths.appointments + "/*"} element={<AppointmentsRoutes />} />
        <Route path={routesPaths.home + "/*"} element={<HomeRoutes />} />
        <Route
          path='/*'
          element={
            <Suspense fallback={<FallbackComponent />}>
              <LoggedInRoutes />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export const LoggedInRoutes: FC = () => {
  const locaiton = useLocation();
  const auth = useOidcAuth();
  console.log("loading....auth", auth?.isAuthenticated);
  useEffect(() => {
    if (!auth?.isAuthenticated && !auth.isLoading) {
      auth?.signinRedirect({
        redirect_uri: window.location.href,
      });
    }
  }, [auth?.isAuthenticated, auth.isLoading, locaiton.pathname]);

  if (auth?.isLoading) {
    return <FallbackComponent />;
  }

  if (auth?.error) {
    console.log("auth.error", auth.error);
    return <FallbackComponent />;
  }

  return (
    <Routes>
      <Route path={routesPaths.accountSettings} element={<AccountSettingsPage />} />
      <Route path={routesPaths.notifications + "/*"} element={<NotificationsRoutes />} />
      <Route path={routesPaths.visitCal + "/*"} element={<VisitCallRoutes />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};
