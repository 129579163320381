import { makeStyles } from "@toolkit/ui";

export const useSlotsSectionStyles = makeStyles()(theme => ({
  iconWrapper: {
    "& svg": {
      width: "25px",
      height: "25px",
    },
    padding: 0,
    paddingTop: "5px",
    paddingInlineEnd: "10px",
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  timeSlotsWrapper: {
    marginTop: "5px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    gap: "5px",
    rowGap: "10px",
    padding: 0,
  },
  timeSlotButton: {
    borderRadius: "23px",
    textAlign: "center",
    border: "2px solid",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.xs,
    cursor: "pointer",
    margin: 0,
    padding: 0,
    minWidth: "100px",
    maxWidth: "130px",
    paddingButton: "5px",
    height: "40px",
    borderColor: theme.palette.common.white,
    "&.selected": {
      backgroundColor: theme.palette.primary[50],
      borderColor: theme.palette.primary.main,
    },
  },
  emptyLabel: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.text.primary,
    paddingBlock: "10px",
    paddingInlineStart: "35px",
    textAlign: "start",
  },
}));
