import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  popoverPaper: {
    position: "absolute",
    borderRadius: "0 0 10px 10px",
    minWidth: "250px",

    borderColor: theme.palette.divider,
    boxShadow: theme.mixins.shadows.xs,
  },
  userInfoContainer: {
    paddingBlock: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  },
  menuList: {
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  },
  avatar: {
    cursor: "pointer",
  },
  popoverContent: {
    width: "100px",
    height: "100px",
  },
  logoutButton: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
  },
}));
