import { CustomCard } from "@/components";
import { Box } from "@toolkit/ui";
import { useStyles } from "./DoctorCardLayout.styles";

export interface DoctorCardProps {
  actionsNode?: React.ReactNode;
  infoNode: React.ReactNode;
  doctorAvatarNode: React.ReactNode;
  vendorAvatarNode?: React.ReactNode;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
}

export const DoctorCardLayout: React.FC<DoctorCardProps> = props => {
  const { actionsNode, infoNode, doctorAvatarNode, vendorAvatarNode, classes: _classes } = props;
  const { classes, cx } = useStyles();

  return (
    <CustomCard className={cx(classes.card, _classes?.card)}>
      <Box className={cx(classes.container, _classes?.container)}>
        <Box className={cx(classes.avatarsContainer, _classes?.avatarsContainer)}>
          {doctorAvatarNode}
          <Box className={cx(classes.vendorAvatarContainer, _classes?.vendorAvatarContainer)}>{vendorAvatarNode}</Box>
        </Box>
        <Box className={cx(classes.infoContainer, _classes?.infoContainer)}>
          <Box className={cx(classes.textColumn, _classes?.textColumn)}>{infoNode}</Box>
          <Box className={cx(classes.actionsContainer, _classes?.actionsContainer)}>{actionsNode}</Box>
        </Box>
      </Box>
    </CustomCard>
  );
};
