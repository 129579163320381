import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Umbrella: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='24' height='25.014' viewBox='0 0 24 25.014' {...props}>
      <path
        id='icons8-umbrella'
        d='M14.984,1.986A1,1,0,0,0,14,3V4.049A12.036,12.036,0,0,0,3,15a1,1,0,0,0,1,1c.552,0,2-1,3-1s2,1,3,1,2-1,5-1,4,1,5,1,2-1,3-1,2.448,1,3,1a1,1,0,0,0,1-1A12.036,12.036,0,0,0,16,4.049V3a1,1,0,0,0-1.016-1.014Zm0,15A1,1,0,0,0,14.84,17H14v7a1,1,0,0,1-2,0V23a1,1,0,1,0-2,0v1a3,3,0,0,0,6,0V17h-.846a1,1,0,0,0-.17-.014Z'
        transform='translate(-3 -1.986)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
export default Umbrella;
