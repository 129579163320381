import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const DoctorMale: FC<IconProps> = ({ width = "18", height = "24", viewBox = "0 0 18 24", ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-doctor-male'
        d='M8,4a1,1,0,0,0-.971.758A46.628,46.628,0,0,0,6,15a1,1,0,0,0,.418.811A7.172,7.172,0,0,0,6,17.957V19.8a3.568,3.568,0,0,0,1.141,2.7,1.764,1.764,0,0,0,.744.408,7.671,7.671,0,0,0,7,5.084,1.116,1.116,0,0,0,.229,0,7.672,7.672,0,0,0,7-5.084,1.764,1.764,0,0,0,.744-.408A3.568,3.568,0,0,0,24,19.8V17.957a4.465,4.465,0,0,0-.568-2.057A1,1,0,0,0,24,15,46.628,46.628,0,0,0,22.971,4.758,1,1,0,0,0,22,4Zm.832,2H21.168a45.226,45.226,0,0,1,.764,8H20a5,5,0,0,0-10,0H8.068A45.226,45.226,0,0,1,8.832,6ZM15,11a2.985,2.985,0,0,1,3,3,3.051,3.051,0,0,1-.057.572,1,1,0,0,0-.09.316,2.984,2.984,0,0,1-5.705.006,1,1,0,0,0-.092-.324A3.05,3.05,0,0,1,12,14,2.985,2.985,0,0,1,15,11Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,15,13Zm4.521,3.1A3.489,3.489,0,0,1,22,19v.8a1.369,1.369,0,0,1-.459,1.2,1.016,1.016,0,0,1-.383.232l-.533.133-.174.52S19.133,26,15,26s-5.451-4.117-5.451-4.117l-.174-.52-.533-.133A1.016,1.016,0,0,1,8.459,21,1.369,1.369,0,0,1,8,19.8V18.572c0-1.2,1.733-.331,3-1.6a4.961,4.961,0,0,0,8.518-.871Z'
        transform='translate(-6 -4)'
        fill={palette.warning.main}
      />
    </SvgIcon>
  );
};
