import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const FirstStepIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_9423'
      data-name='Group 9423'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "69px"}
      height={height ? height : "69px"}
      viewBox={viewBox ? viewBox : "0 0 69 69"}
      {...props}
    >
      <g id='Ellipse_2569' data-name='Ellipse 2569' fill={palette.common.white} stroke={palette.success[900]} strokeWidth='5'>
        <circle cx='34.5' cy='34.5' r='34.5' stroke='none' />
        <circle cx='34.5' cy='34.5' r='32' fill='none' />
      </g>
      <circle
        id='Ellipse_2570'
        data-name='Ellipse 2570'
        cx='17.5'
        cy='17.5'
        r='17.5'
        transform='translate(17 17)'
        fill={palette.primary.main}
      />
      <circle
        id='Ellipse_2571'
        data-name='Ellipse 2571'
        cx='10.5'
        cy='10.5'
        r='10.5'
        transform='translate(24 24)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
