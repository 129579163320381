import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles<{ isMedicalMessageNotifications?: boolean }>()((theme, { isMedicalMessageNotifications }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
    flex: 1,
    backgroundColor: isMedicalMessageNotifications ? theme.palette.background.default : theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    maxHeight: "100%",
    overFlow: "auto",
    minHeight: 0,
  },
  contentForEmptyPage: {
    display: "flex",
    justifyContent: "center",
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    textAlign: "start",
    marginInlineStart: "20px",
    marginBlockStart: "40px",
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  loader: {
    marginTop: "25px",
  },
  notificationWithSeparator: {
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
    paddingInline: "10px",
  },
  notificationWithoutSeparator: {
    paddingInline: "10px",
    marginTop: "10px",
  },
  emptyMessageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    marginBottom: "100px",
  },
}));
