import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const IconCompleted: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='27.066' height='28.962' viewBox='0 0 27.066 28.962' {...props}>
      <path
        id='icons8-today_1_'
        data-name='icons8-today (1)'
        d='M12.373,3.478a1.128,1.128,0,0,0-1.11,1.144v.752h-.376A4.891,4.891,0,0,0,6,10.261V22.81a1.128,1.128,0,1,0,2.256,0V10.261A2.557,2.557,0,0,1,10.887,7.63h.376v.376a1.128,1.128,0,1,0,2.256,0V7.63H25.548v.376a1.128,1.128,0,1,0,2.256,0V7.63h.376a2.557,2.557,0,0,1,2.631,2.631V17a1.128,1.128,0,1,0,2.256,0V10.261a4.891,4.891,0,0,0-4.887-4.887H27.8V4.622a1.128,1.128,0,1,0-2.255,0v.752H13.518V4.622a1.128,1.128,0,0,0-1.145-1.144Zm-1.486,7.91a1.128,1.128,0,1,0,0,2.256H28.179a1.128,1.128,0,1,0,0-2.256ZM25.2,16.64a1.128,1.128,0,0,0-.869.385l-5.972,6.634-2.918-2.918a1.128,1.128,0,1,0-1.595,1.595L17.608,26.1a1.128,1.128,0,0,0,1.636-.043l6.766-7.518A1.128,1.128,0,0,0,25.2,16.64Zm6.718,4.692a1.128,1.128,0,0,0-1.11,1.142v5.079a2.557,2.557,0,0,1-2.631,2.631H10.887a2.557,2.557,0,0,1-2.631-2.631,1.128,1.128,0,1,0-2.256,0,4.891,4.891,0,0,0,4.887,4.887H28.179a4.891,4.891,0,0,0,4.887-4.887V22.474a1.128,1.128,0,0,0-1.145-1.142Z'
        transform='translate(-6 -3.478)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
