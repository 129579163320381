import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const MenuHeaderIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 26 16'>
      <path
        id='icons8-menu_5_'
        data-name='icons8-menu (5)'
        d='M3,7A1,1,0,1,0,3,9H27a1,1,0,1,0,0-2Zm0,7a1,1,0,1,0,0,2H27a1,1,0,1,0,0-2Zm0,7a1,1,0,1,0,0,2H27a1,1,0,1,0,0-2Z'
        transform='translate(-2 -7)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
