import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const SandTimerIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.948' height='22.672' viewBox='0 0 17.948 22.672' {...props}>
      <path
        id='icons8-sand-timer'
        d='M6,1V2.889A1.425,1.425,0,0,0,7.417,4.306h.945V7.48a4.257,4.257,0,0,0,1.889,3.542l1.978,1.314L10.251,13.65a4.257,4.257,0,0,0-1.889,3.542v3.173H7.417A1.425,1.425,0,0,0,6,21.783v1.889H23.948V21.783a1.425,1.425,0,0,0-1.417-1.417h-.945V17.192A4.257,4.257,0,0,0,19.7,13.65L17.72,12.336,19.7,11.022A4.257,4.257,0,0,0,21.587,7.48V4.306h.945a1.425,1.425,0,0,0,1.417-1.417V1Zm.945.945H23v.945a.464.464,0,0,1-.472.472H7.417a.464.464,0,0,1-.472-.472ZM9.306,4.306H20.642V7.48a3.3,3.3,0,0,1-1.476,2.745L16.6,11.937l-.59.4.59.4,2.568,1.712a3.3,3.3,0,0,1,1.476,2.745v3.173h-.974c-.339-3.841-4.694-5.3-4.694-5.3s-4.352,1.531-4.694,5.3H9.306V17.192a3.3,3.3,0,0,1,1.476-2.745l2.568-1.712.59-.4-.59-.4-2.568-1.712A3.3,3.3,0,0,1,9.306,7.48Zm-1.889,17H22.531a.464.464,0,0,1,.472.472v.945H6.945v-.945A.464.464,0,0,1,7.417,21.31Z'
        transform='translate(-6 -1)'
        fill={props.fill ? props.fill : "#ed7b15"}
      />
    </SvgIcon>
  );
};
export default SandTimerIcon;
