import { HomePage } from "./HomePage";

export const HomeBaseRoute = "/home";

export const routes = {
  root: {
    route: "/",
    component: HomePage,
    get absRoute() {
      return HomeBaseRoute;
    },
  },
};
