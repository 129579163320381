/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendOtpMessageForAnonymousAppointmentMutationVariables = Types.Exact<{
  consumerMobileNumber: Types.Scalars['String'];
  consumerName: Types.Scalars['String'];
}>;


export type SendOtpMessageForAnonymousAppointmentMutation = { __typename?: 'Mutation', sendOtpMessageForAnonymousAppointment?: boolean | null };


export const SendOtpMessageForAnonymousAppointmentDocument = gql`
    mutation SendOtpMessageForAnonymousAppointment($consumerMobileNumber: String!, $consumerName: String!) {
  sendOtpMessageForAnonymousAppointment(
    consumerMobileNumber: $consumerMobileNumber
    consumerName: $consumerName
  )
}
    `;
export type SendOtpMessageForAnonymousAppointmentMutationFn = Apollo.MutationFunction<SendOtpMessageForAnonymousAppointmentMutation, SendOtpMessageForAnonymousAppointmentMutationVariables>;

/**
 * __useSendOtpMessageForAnonymousAppointmentMutation__
 *
 * To run a mutation, you first call `useSendOtpMessageForAnonymousAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpMessageForAnonymousAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpMessageForAnonymousAppointmentMutation, { data, loading, error }] = useSendOtpMessageForAnonymousAppointmentMutation({
 *   variables: {
 *      consumerMobileNumber: // value for 'consumerMobileNumber'
 *      consumerName: // value for 'consumerName'
 *   },
 * });
 */
export function useSendOtpMessageForAnonymousAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpMessageForAnonymousAppointmentMutation, SendOtpMessageForAnonymousAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpMessageForAnonymousAppointmentMutation, SendOtpMessageForAnonymousAppointmentMutationVariables>(SendOtpMessageForAnonymousAppointmentDocument, options);
      }
export type SendOtpMessageForAnonymousAppointmentMutationHookResult = ReturnType<typeof useSendOtpMessageForAnonymousAppointmentMutation>;
export type SendOtpMessageForAnonymousAppointmentMutationResult = Apollo.MutationResult<SendOtpMessageForAnonymousAppointmentMutation>;
export type SendOtpMessageForAnonymousAppointmentMutationOptions = Apollo.BaseMutationOptions<SendOtpMessageForAnonymousAppointmentMutation, SendOtpMessageForAnonymousAppointmentMutationVariables>;