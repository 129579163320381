import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const Gmail: FC<SvgIconProps> = props => {
  return (
    <SvgIcon id='icons8-gmail_1_' xmlns='http://www.w3.org/2000/svg' width='28.794' height='21.938' viewBox='0 0 28.794 21.938' {...props}>
      <path
        id='Path_2309'
        d='M41.856,16.2l-3.428,1.885L35,21.342V32.517h4.8a2.057,2.057,0,0,0,2.057-2.057Z'
        transform='translate(-13.062 -10.578)'
        fill='#4caf50'
      />
      <path
        id='Path_2310'
        d='M3,16.2l2.478,1.172,4.378,3.969V32.517h-4.8A2.057,2.057,0,0,1,3,30.46Z'
        transform='translate(-3 -10.578)'
        fill='#1e88e5'
      />
      <path
        id='Path_2311'
        d='M27.768,11.2l-7.541,5.656L12.686,11.2,12,15.176l.686,4.593,7.541,5.656,7.541-5.656.686-4.593Z'
        transform='translate(-5.83 -9.006)'
        fill='#e53935'
      />
      <path
        id='Path_2312'
        d='M3,10.947v2.675l6.856,5.142v-8.57l-2.142-1.6A2.944,2.944,0,0,0,5.947,8h0A2.946,2.946,0,0,0,3,10.947Z'
        transform='translate(-3 -8)'
        fill='#c62828'
      />
      <path
        id='Path_2313'
        d='M41.856,10.947v2.675L35,18.764v-8.57l2.142-1.6A2.944,2.944,0,0,1,38.909,8h0A2.946,2.946,0,0,1,41.856,10.947Z'
        transform='translate(-13.062 -8)'
        fill='#fbc02d'
      />
    </SvgIcon>
  );
};
