import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const TrackingIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='25.695' height='31.525' viewBox='0 0 25.695 31.525' {...props}>
      <path
        id='icons8-tracking_1_'
        data-name='icons8-tracking (1)'
        d='M16.847,2A12.848,12.848,0,0,0,4,14.847c0,8.449,8.354,12.495,9.4,13.436a10.841,10.841,0,0,1,2.306,4.336,1.171,1.171,0,0,0,2.281,0,10.849,10.849,0,0,1,2.306-4.336c1.046-.941,9.4-4.987,9.4-13.436A12.848,12.848,0,0,0,16.847,2ZM12.176,9.008h9.344a1.168,1.168,0,0,1,1.168,1.168v9.344a1.168,1.168,0,0,1-1.168,1.168H12.176a1.168,1.168,0,0,1-1.168-1.168V10.176A1.168,1.168,0,0,1,12.176,9.008Zm2.336,2.336v1.168h4.672V11.344Z'
        transform='translate(-4 -2)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
