import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const ProductIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='14.995' height='16.771' viewBox='0 0 14.995 16.771' {...props}>
      <g id='icons8-product' transform='translate(-7 -4.974)'>
        <path
          id='Path_12973'
          data-name='Path 12973'
          d='M19.569,35.51H9.426A2.428,2.428,0,0,1,7,33.084V30.262a.662.662,0,0,1,1.323,0v2.823a1.1,1.1,0,0,0,1.1,1.1H19.569a1.1,1.1,0,0,0,1.1-1.1v-.617a.662.662,0,1,1,1.323,0v.617A2.428,2.428,0,0,1,19.569,35.51Z'
          transform='translate(0 -13.766)'
          fill={fill}
        />
        <path
          id='Path_12974'
          data-name='Path 12974'
          d='M21.333,20.556a.662.662,0,0,1-.662-.662V13.544a.223.223,0,0,0-.221-.221H8.544a.223.223,0,0,0-.221.221v2.337a.662.662,0,0,1-1.323,0V13.544A1.545,1.545,0,0,1,8.544,12H20.451a1.545,1.545,0,0,1,1.544,1.544v6.351A.662.662,0,0,1,21.333,20.556Z'
          transform='translate(0 -3.928)'
          fill={fill}
        />
        <path
          id='Path_12975'
          data-name='Path 12975'
          d='M19.9,9.307a.659.659,0,0,1-.468-.194L16.784,6.467a.587.587,0,0,0-.829,0l-.926.926a.662.662,0,0,1-.936-.936l.926-.926a1.911,1.911,0,0,1,2.7,0l2.646,2.646A.662.662,0,0,1,19.9,9.307Z'
          transform='translate(-3.857)'
          fill={fill}
        />
        <path
          id='Path_12976'
          data-name='Path 12976'
          d='M29.557,9.941a.659.659,0,0,1-.468-.194L26.752,7.41a.407.407,0,0,0-.608,0L25.129,8.424a.662.662,0,0,1-.936-.936l1.014-1.014a1.727,1.727,0,0,1,2.479,0l2.337,2.337a.662.662,0,0,1-.468,1.129Z'
          transform='translate(-9.503 -0.546)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
