/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { AppointmentNotificationData } from "@/domains/notifications/types";
import { getDoctorInfo } from ".";
import { IDoctorInfo } from "@/domains/appointment/types/types";
import { AppointmentType } from "@health/queries/types";

export type ConfirmDoctorTimeContainerData = {
  doctorInfo: IDoctorInfo & {
    onlineVisitPrice: number;
    onsiteVisitPrice: number;
    atHomeVisitPrice: number;
  };
  isIntegrated?: boolean;
  appointmentId: string;
  appointmentType?: AppointmentType | null;
  appointmentStatus?: string | null;
  paymentStatus?: string | null;
  paymentInfo?: {
    priceAmountAfterVat: number;
  } | null;
  suggestedTimeSlots?: string[] | null;
};

export const getConfirmDoctorTimeContainerData = (data: AppointmentNotificationData): ConfirmDoctorTimeContainerData => {
  return {
    doctorInfo: {
      ...getDoctorInfo(data?.doctor!),
      onlineVisitPrice: data?.doctor?.onlineVisitPrice!,
      onsiteVisitPrice: data?.doctor?.onsiteVisitPrice!,
      atHomeVisitPrice: data?.doctor?.atHomeVisitPrice!,
    },
    isIntegrated: data?.doctor?.vendor?.isIntegrated,
    appointmentId: data?.id,
    appointmentType: data?.type,
    appointmentStatus: data?.status,
    paymentStatus: data?.paymentStatus,
    paymentInfo:
      data?.paymentInfo?.priceAmountAfterVat !== undefined && data?.paymentInfo?.priceAmountAfterVat !== null
        ? { priceAmountAfterVat: data.paymentInfo.priceAmountAfterVat }
        : null,
    suggestedTimeSlots: data?.suggestedTimeSlots?.filter((slot): slot is string => slot !== null) || null,
  };
};
