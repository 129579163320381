import { Box, Button, Typography, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import { AppointmentScheduler, AppointmentSchedulerRef } from "../../components/AppointmentScheduler/AppointmentScheduler";
import { IFormattedTimeSlot } from "../../types/types";
import { useCommonStyles } from "../CommonContainer.styles";
import { useTranslation } from "@toolkit/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import queryString from "query-string";
import { getApolloContextFromToken } from "@/providers/auth";
import { useConsumerAppointmentRescheduleMutation } from "../../gql";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useAppointmentQuery } from "@/domains/notifications";

type QueryParams = {
  appointmentId?: string;
  doctorId?: string;
};

export const AppointmentRescheduleContainer: FC = () => {
  const ref = useRef<AppointmentSchedulerRef | null>(null);
  const { t } = useTranslation("consumer");

  const { classes } = useCommonStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: QueryParams = queryString.parse(location.search);
  const appointmentId = queryParams.appointmentId;

  const { user } = useAuth();

  const { failed, succeeded } = useAddToast();

  const { data: appointmentInfo, loading } = useAppointmentQuery({
    variables: { appointmentId: appointmentId! },
    context: getApolloContextFromToken(user?.access_token),
  });

  useEffect(() => {
    if (!loading && (!queryParams.appointmentId || !appointmentInfo?.appointment)) {
      navigate(-1);
    }
  }, [appointmentInfo]);

  const [rescheduleAppointment, { loading: isSubmitting }] = useConsumerAppointmentRescheduleMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.consumerAppointmentReschedule?.appointmentErrors || [];

      if (mutationErrors?.length) {
        failed(formatMessageErrors(mutationErrors) || t("Something went wrong"));
      } else {
        succeeded(t("Your appointment has been rescheduled successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors) || t("Something went wrong"));
    },
    context: getApolloContextFromToken(user?.access_token),
  });

  const doctorId = appointmentInfo?.appointment?.doctor?.id || queryParams.doctorId;

  const onSubmitClick = () => {
    ref.current?.submit();
  };

  const onSubmit = (selectedTimeSlots: IFormattedTimeSlot[]) => {
    rescheduleAppointment({
      variables: {
        consumerAppointmentRescheduleId: appointmentId!,
        input: {
          requestedTimeSlots: selectedTimeSlots,
        },
      },
    });
  };

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.title}>{t("Select a date")}</Typography>
        <AppointmentScheduler ref={ref} doctorId={doctorId!} onSubmit={onSubmit} />
      </Box>
      <Box className={classes.footer}>
        <Button disabled={loading || isSubmitting} onClick={onSubmitClick} className={classes.button} fullWidth>
          {t("Reschedule")}
        </Button>
      </Box>
    </Box>
  );
};
