import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const TearOffCalendar: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20' {...props}>
      <path
        id='icons8-tear-off-calendar'
        d='M7,1A1,1,0,0,0,6,2V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,7,1ZM5,8H19V19H5Zm9,5a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Z'
        transform='translate(-3 -1)'
        fill='#ce5145'
      />
    </SvgIcon>
  );
};
export default TearOffCalendar;
