import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const Info: FC<IconProps> = ({ width = "24", height = "24", viewBox = "0 0 24 24", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-info'
        d='M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3Zm1,18H14V14h2Zm-1-9.5A1.5,1.5,0,1,1,16.5,10,1.5,1.5,0,0,1,15,11.5Z'
        transform='translate(-3 -3)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
