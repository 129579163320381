import { makeStyles } from "@toolkit/ui";

export const useNotificationDetailsStyle = makeStyles()(() => ({
  contentContainer: {
    padding: "0!important",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  notificationsIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "260px",
    width: "100%",
    "& svg": {
      width: "150px",
      height: "150px",
    },
  },
  content: {
    flex: 1,
    overflowY: "auto",
    textAlign: "start",
    padding: "20px",
  },
}));
