import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const UserMale: FC<SvgIconProps> = ({ width = 60.211, height = 60.207, viewBox = "0 0 60.211 60.207", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-checked-user-male'
        d='M29.761,3C20.3,3,16.38,9.07,16.38,16.382a13.284,13.284,0,0,0,1.176,4.935,2.608,2.608,0,0,0-1.054,2.67c.366,2.861,1.606,3.59,2.4,3.65.3,2.669,3.169,6.084,4.173,6.581v4.46C20.84,45.368,3,40.908,3,56.519H29.643A20.008,20.008,0,0,1,40.3,28.917a5.955,5.955,0,0,0,.322-1.281c.789-.06,2.03-.789,2.4-3.65a2.606,2.606,0,0,0-1.054-2.67,11.8,11.8,0,0,0,1.176-4.935c0-5.415-2.125-10.035-6.69-10.035C36.451,6.347,34.865,3,29.761,3ZM47.6,31.988A15.61,15.61,0,1,0,63.211,47.6,15.61,15.61,0,0,0,47.6,31.988Zm6.69,8.92a2.23,2.23,0,0,1,1.577,3.807l-8.92,8.92a2.229,2.229,0,0,1-3.153,0l-4.46-4.46a2.23,2.23,0,0,1,3.153-3.153l2.883,2.883,7.343-7.343A2.222,2.222,0,0,1,54.291,40.908Z'
        transform='translate(-3 -3.002)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
