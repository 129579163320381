import React from "react";
import { useTheme } from "../../base/mui";
export const EducationIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9631' data-name='Group 9631' transform='translate(-308.283 -791)'>
        <circle
          id='Ellipse_2619'
          data-name='Ellipse 2619'
          cx='22'
          cy='22'
          r='22'
          transform='translate(308.283 791)'
          fill={palette.warning.main}
        />
        <path
          id='icons8-tuition'
          d='M7,3a3,3,0,1,0,3,3A3,3,0,0,0,7,3Zm5,1V6H25V17H12v.832a1,1,0,0,0,0,.326V19H27a1,1,0,1,0,0-2V5a1,1,0,0,0-1-1Zm3.98,4.992a1,1,0,0,0-.535.176L12.7,11H6a2,2,0,0,0-2,2V25.967a1.033,1.033,0,0,0,2.064.068L6.5,19.467a.5.5,0,0,1,1,0l.438,6.57A1.033,1.033,0,0,0,10,25.967V14a1,1,0,0,1,1-1h2a1,1,0,0,0,.555-.168l3-2a1,1,0,0,0-.574-1.84Z'
          transform='translate(315 798)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
