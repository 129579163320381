import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "fit-content",
    backgroundColor: theme?.palette.background.paper,
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    height: "min-content",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
    position: "relative",
    gap: "20px",
    alignItems: "end",
  },
  tab: {
    height: "inherit",
    borderRadius: "0px",
    paddingBlock: "2.5px",
    paddingInline: "7px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale[800],
    bottom: "-1px",
    "&&:disabled": {
      color: theme.palette.primary.main,
      borderBottom: "2px solid",
      borderColor: theme.palette.primary.main,
    },
  },
}));
