import { styled, Radio } from "@toolkit/ui";
import { CheckmarkIcon } from "../Icons";
export const CustomRadio = styled(Radio)(({ theme }) => ({
  width: 25,
  height: 25,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[1600],
    width: 25,
    height: 25,
    "& svg": {
      fill: theme.palette.common.white,
      width: 16,
      height: 12,
    },
  },
  color: theme.palette.text.primary,
}));

CustomRadio.defaultProps = {
  color: "default",
  checkedIcon: <CheckmarkIcon fill={"white"} width={25} height={25} />,
};
