import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const PresonVisitIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='10.139' height='21.186' viewBox='0 0 10.139 21.186' {...props}>
      <g id='icons8-person' transform='translate(-13.9 -3)'>
        <path
          id='Ellipse_3036'
          data-name='Ellipse 3036'
          d='M1.728-1.5A3.228,3.228,0,1,1-1.5,1.728,3.232,3.232,0,0,1,1.728-1.5Zm0,4.943A1.715,1.715,0,1,0,.013,1.728,1.717,1.717,0,0,0,1.728,3.443Z'
          transform='translate(17.266 4.5)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13029'
          data-name='Path 13029'
          d='M16.17,22.584a2.246,2.246,0,0,1-2.27-2.27V16.632A3.636,3.636,0,0,1,17.532,13h2.875a3.636,3.636,0,0,1,3.632,3.632.757.757,0,1,1-1.513,0,2.121,2.121,0,0,0-2.119-2.119H17.532a2.121,2.121,0,0,0-2.119,2.119v3.682a.732.732,0,0,0,.757.757.757.757,0,0,1,0,1.513Z'
          transform='translate(0 -4.956)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13030'
          data-name='Path 13030'
          d='M22.3,34.692H18.765A1.767,1.767,0,0,1,17,32.926V30a.757.757,0,0,1,1.513,0v2.926a.256.256,0,0,0,.252.252H22.3a.256.256,0,0,0,.252-.252V27.378a.757.757,0,0,1,.757-.757.732.732,0,0,0,.757-.757v-.908a.757.757,0,1,1,1.513,0v.908a2.242,2.242,0,0,1-1.513,2.145v4.916A1.767,1.767,0,0,1,22.3,34.692Z'
          transform='translate(-1.536 -10.506)'
          fill={palette.primary.main}
        />
        <path
          id='Line_1696'
          data-name='Line 1696'
          d='M-.743,4.049A.757.757,0,0,1-1.5,3.292V-.743A.757.757,0,0,1-.743-1.5a.757.757,0,0,1,.757.757V3.292A.757.757,0,0,1-.743,4.049Z'
          transform='translate(19.738 19.532)'
          fill={palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};
