import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const ChatNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-chat_1_'
        data-name='icons8-chat (1)'
        d='M15.5,4A11.518,11.518,0,0,0,4,15.5a11.4,11.4,0,0,0,1.3,5.215L4.054,25.183a1.468,1.468,0,0,0,1.773,1.773l4.74-1.324a.863.863,0,1,0-.464-1.662L5.854,25.156,7.04,20.909a.863.863,0,0,0-.078-.653A9.774,9.774,0,0,1,15.5,5.724q.392,0,.774.03a.863.863,0,1,0,.136-1.72Q15.964,4,15.5,4Zm4.062.8a.863.863,0,0,0-.324,1.665A9.78,9.78,0,0,1,15.5,25.282q-.2,0-.393-.008A.863.863,0,0,0,15.042,27q.231.009.463.009A11.506,11.506,0,0,0,19.9,4.87a.863.863,0,0,0-.336-.071Zm-8.951,7.828a.863.863,0,1,0,0,1.726h9.779a.863.863,0,1,0,0-1.726Zm0,4.027a.863.863,0,1,0,0,1.726h7.478a.863.863,0,1,0,0-1.726Z'
        transform='translate(9 8)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
