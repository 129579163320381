import { FC } from "react";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { useAppointmentItemStyles } from "./useAppointmentItemStyle";
import { getNotificationTextStyle } from "@/domains/notifications/utils";
import { AppointmentItemProps } from "@/domains/notifications/types";
import { AppointmentDateTime } from "./AppointmentDateTime";

export const AppointmentCancelled: FC<AppointmentItemProps> = ({
  type,
  doctor,
  appointmentDate,
  requestedTimeSlots,
  cancellationReason,
  isSeen,
}) => {
  const { classes, theme } = useAppointmentItemStyles();
  const { t } = useTranslation("consumer");
  const { color } = getNotificationTextStyle(isSeen!, theme);

  return (
    <Box className={classes.view}>
      <Typography color={color}>
        <Trans
          t={t}
          i18nKey='Your <b>{{type}}</b> Appointment with <b>{{doctor}}</b> has been canceled'
          values={{ type, doctor }}
          components={{ b: <Typography display='inline' fontWeight='bold' /> }}
        />
      </Typography>
      {cancellationReason && (
        <Typography sx={{ color }}>
          <Typography component='span' fontWeight='bold'>
            {t("Cancellation Reason")}
          </Typography>
          : {cancellationReason}
        </Typography>
      )}
      <AppointmentDateTime {...{ appointmentDate, requestedTimeSlots, isSeen }} />
    </Box>
  );
};
