import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    minHeight: "337px",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      paddingInline: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingInline: "70px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingInline: "140px",
    },
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
  },
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1.4fr 1fr 1fr 1fr",
    gap: "30px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr 1fr",
      gap: "50px",
    },
    paddingTop: "50px",
  },
  aboutUs: {
    width: "100%",
    height: "100%",
  },
  aboutUsTitle: {
    maxWidth: "325px",
    textAlign: "start",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    gap: "41px",
    "& svg": {
      height: "42px",
      width: "100px",
    },
  },
  serviceContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    gap: "30px",
  },
  serviceTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
    height: "42px",
    marginBottom: "10px",
  },
  service: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    textAlign: "start",
    textDecoration: "none",
    ": active": {
      color: theme.palette.secondary.main,
    },
  },
}));
