import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const SearchIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.27' height='17.27' viewBox='0 0 17.27 17.27' {...props}>
      <path
        id='icons8-search_3_'
        data-name='icons8-search (3)'
        d='M10.2,3a7.2,7.2,0,1,0,4.549,12.763l4.3,4.3a.72.72,0,1,0,1.018-1.018l-4.3-4.3A7.188,7.188,0,0,0,10.2,3Zm0,1.439A5.757,5.757,0,1,1,4.439,10.2,5.746,5.746,0,0,1,10.2,4.439Z'
        transform='translate(-3 -3)'
        fill={fill}
      />
    </SvgIcon>
  );
};
