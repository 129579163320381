import { BaseSyntheticEvent, ReactNode } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import { isRTL } from "@toolkit/i18n";
import { useStyles } from "./CardsContainerStyles";
import "react-tabs-scrollable/dist/rts.css";
import { EmptyCard } from "../Cards/EmptyCard";
import { Box, CircularProgress, Grid } from "../../base/mui";
import React from "react";
import { CardsContainerArrow } from "../../icons";


interface CardsContainerProps {
  isLoadingData?: boolean;
  cards: ReactNode[];
  CardSkeletonComponent?: ReactNode;
  countOfSkeletons?: number;
  EmptyCardComponent?: ReactNode;
  handleTabClick?: (e: React.BaseSyntheticEvent, id: number) => void;
  activeTabIndex?: number;
  cardsScrollAmount?: number;
  hideNavBtns?: boolean;
  hideNavBtnsOnMobile?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
}

export const CardsContainer: React.FC<CardsContainerProps> = ({
  isLoadingData = false,
  cards,
  CardSkeletonComponent = <CircularProgress />,
  countOfSkeletons = 5,
  EmptyCardComponent = <EmptyCard />,
  handleTabClick = () => {},
  activeTabIndex = 0,
  cardsScrollAmount = 1,
  hideNavBtns = false,
  hideNavBtnsOnMobile = false,
  classes: _classes,
}) => {
  const { classes, theme, cx } = useStyles();
  const isRtl = isRTL();

  return (
    <Grid className={cx(classes.cardsContainer, _classes?.cardsContainer)} columnSpacing={2} container sx={{ flexFlow: "row" }}>
      {isLoadingData ? (
        <Box className={cx(classes.skeletonContainer, _classes?.skeletonContainer)}>
          {[...Array(countOfSkeletons)].map((_, index) => (
            <Grid key={index} item xs={6}>
              {CardSkeletonComponent}
            </Grid>
          ))}
        </Box>
      ) : (
        <Tabs
          activeTab={activeTabIndex}
          tabsContainerClassName={cx(classes.cardsContainerClassName, _classes?.cardsContainerClassName)}
          tabsUpperContainerClassName={cx(classes.cardsUpperContainer, _classes?.cardsUpperContainer)}
          tabsScrollAmount={cardsScrollAmount}
          hideNavBtns={hideNavBtns}
          hideNavBtnsOnMobile={hideNavBtnsOnMobile}
          isRTL={isRtl}
          onTabClick={handleTabClick}
          navBtnClassName={cx(classes.navBtnClassName, _classes?.navBtnClassName)}
          navBtnContainerClassName={cx(classes.navBtnContainerClassName, _classes?.navBtnContainerClassName)}
          rightBtnIcon={<CardsContainerArrow fill={theme.palette.primary.main} />}
          leftBtnIcon={<CardsContainerArrow fill={theme.palette.primary.main} direction='left' />}
        >
          {cards?.length ? (
            cards?.map((card, cardIndex) => (
              <Tab key={cardIndex} className={cx(classes.card, _classes?.card)}>
                {card}
              </Tab>
            ))
          ) : (
            <Tab key={0} className={cx(classes.card, _classes?.card)}>
              {EmptyCardComponent}
            </Tab>
          )}
        </Tabs>
      )}
    </Grid>
  );
};
