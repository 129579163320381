import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()({
  container: {
    width: "calc(100% - 5px)",
    display: "flex",
    flexDirection: "column",
    maxWidth: "444px",
  },
  weekCard: {
    minWidth: "387px",
    height: "100%",
    display: "flex",
    gap: "12px",
  },
});
