import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const PreApprovalIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18.258' height='17.823' viewBox='0 0 18.258 17.823' {...props}>
      <g id='Group_15472' data-name='Group 15472' transform='translate(0 0)'>
        <g id='Group_15469' data-name='Group 15469' transform='translate(0 0)'>
          <path
            id='icons8-update-done'
            d='M20.017,5.978a.668.668,0,0,0-.658.678V8.039A8.013,8.013,0,1,0,12.8,21.928a.668.668,0,0,0,.2-1.321,6.679,6.679,0,1,1,5.533-11.5H16.91a.668.668,0,1,0,0,1.336h3.135l.049,0,.06-.009.051-.012.013,0,.006,0,.023-.009.04-.015q.028-.012.055-.026l.005,0q.027-.014.052-.031l.005,0q.025-.017.048-.037l.005,0q.023-.019.044-.041l0,0q.021-.022.04-.045l0-.005.01-.015.028-.039a.669.669,0,0,0,.033-.058q.014-.027.025-.055l0-.006q.011-.028.019-.057l0-.006q.008-.029.014-.059V9.881q0-.03.007-.061V9.805q0-.016,0-.032V6.656a.668.668,0,0,0-.678-.678Zm-3.552,8.471a1.569,1.569,0,0,0-1.559,1.559v6.234A1.569,1.569,0,0,0,16.465,23.8H20.25a.668.668,0,1,0,0-1.336H16.465a.213.213,0,0,1-.223-.223V16.008a.213.213,0,0,1,.223-.223h.445a.668.668,0,1,0,0-1.336Zm3.117,0a.668.668,0,1,0,0,1.336H22.7a.213.213,0,0,1,.223.223v6.234a.213.213,0,0,1-.223.223H22.24a.668.668,0,1,0,0,1.336H22.7a1.569,1.569,0,0,0,1.559-1.559V16.008A1.569,1.569,0,0,0,22.7,14.449Zm1.627,2.576a.668.668,0,0,0-.457.207l-1.666,1.7-.547-.547a.668.668,0,1,0-.945.945l1.025,1.024a.668.668,0,0,0,.949,0l2.138-2.182a.668.668,0,0,0-.5-1.142Z'
            transform='translate(-6 -5.978)'
            fill={fill}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
