import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CareTeamIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "92.455"}
      height={height ? height : "83.007"}
      viewBox={viewBox ? viewBox : "0 0 92.455 83.007"}
      {...props}
    >
      <g id='Group_8335' data-name='Group 8335' transform='translate(0.401 -3.2)'>
        <path
          id='Path_7779'
          data-name='Path 7779'
          d='M53.614,5.5H36.44C33.005,5.5,30,8.219,30,11.225v8.587H59.339v-8.73A5.621,5.621,0,0,0,53.614,5.5Zm-4.723,9.159-.859,1.431L45.743,14.8v2.576H44.168V14.8l-2.29,1.288-.859-1.431,2.29-1.288L41.163,11.94l.859-1.431,2.29,1.288V9.364h1.574V11.94l2.29-1.288.859,1.431L46.6,13.371Z'
          transform='translate(13.108 0.992)'
          fill={fill ? fill : palette.success.main}
        />
        <g id='Group_8323' data-name='Group 8323' transform='translate(-0.402 3.2)'>
          <path
            id='Path_7780'
            data-name='Path 7780'
            d='M59.85,51a17.008,17.008,0,0,0,8.587-15.17V12.216a8.642,8.642,0,0,0-2.576-6.3,9.4,9.4,0,0,0-6.3-2.719H42.39c-5.3,0-9.732,4.15-9.732,8.873V35.83A17.37,17.37,0,0,0,41.818,51,34.949,34.949,0,0,0,16.2,84.49a1.74,1.74,0,0,0,1.717,1.717,1.847,1.847,0,0,0,1.717-1.717A31.015,31.015,0,0,1,36.093,57.011a41.043,41.043,0,0,0-.716,4.437,9.87,9.87,0,0,0-7.156,9.446V81.627a4.925,4.925,0,0,0,4.58,4.58,1.74,1.74,0,0,0,1.717-1.717A1.654,1.654,0,0,0,32.8,82.772a1.553,1.553,0,0,1-1.145-1.145V70.894c0-3.292,2.433-6.011,5.438-6.011,2.862,0,5.438,2.719,5.438,6.011V81.627a1.553,1.553,0,0,1-1.145,1.145,1.74,1.74,0,0,0-1.717,1.717,1.775,1.775,0,0,0,1.861,1.717h0a4.925,4.925,0,0,0,4.58-4.58V70.894c0-4.58-3.005-8.587-7.156-9.446a22.255,22.255,0,0,1,1.288-5.725l8.873,12.022a1.967,1.967,0,0,0,1.145.716,1.924,1.924,0,0,0,1.288-.286l.429-.429L60.852,55.58C62.856,59.3,63.571,64.6,64,67.03a6.435,6.435,0,0,0-3.721,3.005,6.831,6.831,0,0,0-.716,5.009,6.717,6.717,0,0,0,13.024-3.292,6.493,6.493,0,0,0-4.866-4.866,56.235,56.235,0,0,0-2.147-9.732A31.161,31.161,0,0,1,81.461,84.49a1.74,1.74,0,0,0,1.717,1.717A1.847,1.847,0,0,0,84.9,84.49,34.44,34.44,0,0,0,59.85,51ZM66,70.321a3.149,3.149,0,1,1,0,6.3h0a3.176,3.176,0,0,1-3.149-3.149A3.275,3.275,0,0,1,66,70.321v0ZM64.859,24.095v3.721a30.726,30.726,0,0,1-13.882-3.721Zm-13.6,25.761h-.286a14.271,14.271,0,0,1-14.6-14.025V24.095h8.73c1.288,1.288,7.442,6.726,19.893,7.3V35.83A13.068,13.068,0,0,1,61,45.562,13.907,13.907,0,0,1,51.263,49.856Zm6.44,4.293-7.013,9.589-7.013-9.446a30.567,30.567,0,0,1,7.156-.859A22.021,22.021,0,0,1,57.7,54.149ZM42.39,6.921H59.564a5.283,5.283,0,0,1,5.3,5.3v8.3H36.236v-8.3C36.236,9.354,39.1,6.921,42.39,6.921Z'
            transform='translate(7.559 -3.2)'
            fill={fill ? fill : palette.primary.main}
          />
          <path
            id='Path_7781'
            data-name='Path 7781'
            d='M24.073,46.908a20.9,20.9,0,0,1,11.592,3.578,1.763,1.763,0,0,0,2.433-.429,1.948,1.948,0,0,0-.429-2.433,24.549,24.549,0,0,0-6.154-3.005,12.763,12.763,0,0,0,5.438-10.447V17.57a6.871,6.871,0,0,0-6.87-6.87H17.919a6.871,6.871,0,0,0-6.87,6.87V34.028a12.763,12.763,0,0,0,5.438,10.447A24.135,24.135,0,0,0-.4,67.66a1.74,1.74,0,0,0,1.717,1.717,1.874,1.874,0,0,0,1.717-1.86A21.175,21.175,0,0,1,24.073,46.908Zm-9.3-21.181H33.376v8.3a9.32,9.32,0,0,1-9.016,9.3h-.143a9.284,9.284,0,0,1-9.3-9.3v-8.3Zm3.292-11.449H30.227a3.176,3.176,0,0,1,3.149,3.149v4.58H14.771v-4.58A3.41,3.41,0,0,1,18.063,14.278Z'
            transform='translate(0.402 0.034)'
            fill={fill ? fill : palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
