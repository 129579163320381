import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const GooglePlay: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='40.531' height='46.008' viewBox='0 0 40.531 46.008' {...props}>
      <path
        id='icons8-google-play_1_'
        d='M6.781,2c-.012,0-.02.027-.031.031a.751.751,0,0,0-.094,0,1,1,0,0,0-.562.25A2.2,2.2,0,0,0,5,4.219V46a2.1,2.1,0,0,0,.781,1.656,1,1,0,0,0,.938.313,2.465,2.465,0,0,0,1.344-.312c.848-.492,18.156-10.5,18.156-10.5l9.031-5.219a.466.466,0,0,0,.063-.031l.125-.062s.121-.062.125-.062l.031-.031c.27-.156,7.668-4.414,8.688-5a2.222,2.222,0,0,0,1.25-1.875,2.141,2.141,0,0,0-1.219-1.781c-.352-.187-2.633-1.492-4.75-2.719s-4.125-2.406-4.125-2.406l-9.219-5.312L8.469,2.406A3.1,3.1,0,0,0,7.344,2,1.939,1.939,0,0,0,6.781,2ZM7,4.688,27.375,24.906,7,45.125Zm4.75,1.906,13.469,7.781,8.063,4.656-4.5,4.469ZM35.063,20.063c.48.281,1.7.992,3.5,2.031,2.117,1.227,4.289,2.469,4.813,2.75.051.027.035.043.063.063a1.368,1.368,0,0,1-.156.094c-.961.555-7.191,4.172-8.187,4.75l-4.906-4.844Zm-6.281,6.219,4.531,4.5-8.094,4.656s-8.164,4.711-13.437,7.75Z'
        transform='translate(-5 -1.979)'
        fill={palette.common.white}
        className='googlePlay'
      />
    </SvgIcon>
  );
};
