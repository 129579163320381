import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CheckUp: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon
      id='Group_8906'
      data-name='Group 8906'
      sx={{
        width: 44,
        height: 44,
      }}
      viewBox='0 0 44 44'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Group_9474' data-name='Group 9474' transform='translate(-328.066 -372)'>
        <circle
          id='Ellipse_2661'
          data-name='Ellipse 2661'
          cx='22'
          cy='22'
          r='22'
          transform='translate(328.066 372)'
          fill={palette.stale[900]}
        />
        <path
          id='icons8-health-checkup_5_'
          data-name='icons8-health-checkup (5)'
          d='M19.522,2A2.609,2.609,0,1,0,22.13,4.609,2.609,2.609,0,0,0,19.522,2ZM3.87,2.87A.87.87,0,0,0,3,3.739V7.217A5.233,5.233,0,0,0,6.592,12.17a1.732,1.732,0,0,0,.756.893v1.98a3.491,3.491,0,0,0,3.478,3.478h6.087a1.727,1.727,0,0,0,.35-.036,1.707,1.707,0,0,0,.323-.1,1.745,1.745,0,0,0,.929-.929,1.707,1.707,0,0,0,.1-.323v0a1.71,1.71,0,0,0,.036-.348v-2.85a1.739,1.739,0,1,1,1.739,0v2.85a3.428,3.428,0,0,1-.363,1.515l.307,4.6a.9.9,0,0,0,1.8-.059V17.652a.869.869,0,0,0,.87-.87V11.565a2.612,2.612,0,0,0-2.609-2.609H18.652a2.612,2.612,0,0,0-2.609,2.609v5.217H10.826a1.726,1.726,0,0,1-1.739-1.739v-1.98a1.73,1.73,0,0,0,.774-.953,5.168,5.168,0,0,0,3.573-4.893V3.739a.87.87,0,0,0-.869-.87H10.826a.87.87,0,1,0,0,1.739h.87V7.217a3.461,3.461,0,0,1-3.4,3.47.87.87,0,0,0-.092,0l-.066,0a3.461,3.461,0,0,1-3.4-3.47V4.609h.87a.87.87,0,1,0,0-1.739ZM18.927,19.607a3.453,3.453,0,0,1-2.014.654v2.58a.9.9,0,0,0,1.8.059Z'
          transform='translate(335 382)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
