import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const VerifiedIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <path
        id='icons8-verified-account'
        d='M21.183,9.611h0a4.069,4.069,0,0,0-4.793-4.793,4.069,4.069,0,0,0-6.778,0A4.069,4.069,0,0,0,4.818,9.611h0a4.069,4.069,0,0,0,0,6.778h0a4.069,4.069,0,0,0,4.793,4.793,4.069,4.069,0,0,0,6.778,0,4.069,4.069,0,0,0,4.793-4.793h0A4.069,4.069,0,0,0,21.183,9.611Zm-2.593.645-6.3,6.3a.833.833,0,0,1-1.178,0L8.233,13.678A.833.833,0,0,1,9.412,12.5L11.7,14.788l5.711-5.711a.833.833,0,0,1,1.178,1.178Z'
        transform='translate(-3 -3)'
        fill={palette.success.main}
      />
    </svg>
  );
};
