import { FC } from "react";
import { VisitCallEmbed } from "./VisitCallEmbed";
import { useAuth } from "react-oidc-context";

type VisitCallProps = {
  meetingLink: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink }) => {
  // todo: apply refresh token logic

  const { user } = useAuth();
  const token = user?.access_token;
  const src = meetingLink
    .replace(/(^")|("$)/g, "")
    .replace("@oauth_token", "iframe_exchange")
    .replace("@exit_url", "iframe");

  return !token ? <div>Unauthorized</div> : <VisitCallEmbed src={src} token={token} />;
};
