import { Box, IoHealthLogo, Typography } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./MainPageFooterLgStyle";
import { useTranslation } from "@toolkit/i18n";
import { Link } from "react-router-dom";

export const MainPageFooterLg: React.FC = () => {
  const { classes, cx } = useStyle();
  const { t } = useTranslation("consumer");
  return (
    <Box className={cx(classes.root)}>
      <Box className={cx(classes.container)}>
        <Box className={classes.aboutUs}>
          <Box className={classes.logoContainer}>
            <IoHealthLogo />
            <Typography className={classes.aboutUsTitle}>
              {t(
                "ioHealth is one of the latest ioHealth projects. ioHealth is the leading company in Saudi Arabia in procurement, logistics and supply chain management for pharmaceutical, medical devices and supplies for governmental hospitals in Saudi Arabia."
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.serviceContainer}>
          <Typography className={classes.serviceTitle}>{t("ioHealth Plus")}</Typography>
          <Link to='#' className={classes.service}>
            {t("About Wasfaty Plus")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("ioHealth Blogs")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("Latest News")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("FAQs")}
          </Link>
        </Box>
        <Box className={classes.serviceContainer}>
          <Typography className={classes.serviceTitle}>{t("E-Services")}</Typography>
          <Link to='#' className={classes.service}>
            {t("Registering a Pharmacy")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("Registering Health Facilities")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("Technical Support")}
          </Link>
        </Box>
        <Box className={classes.serviceContainer}>
          <Typography className={classes.serviceTitle}>{t("Customer Service")}</Typography>
          <Link to='#' className={classes.service}>
            {t("User’s Guide")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("Registration Criteria")}
          </Link>
          <Link to='#' className={classes.service}>
            {t("Terms and Conditions")}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
