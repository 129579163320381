import { AppointmentTypeEnum, DoctorFilterInput, PersonGenderEnum } from "@health/queries/types";
import { IAppointmentsListFiltersFormValues } from "./AppointmentsListFiltersFormSchema";

const mapFilterValues = <T, K>(items: T[] | undefined, mapFn: (item: T) => K | undefined): K[] | undefined => {
  return items?.map(mapFn).filter((value): value is K => value !== undefined);
};

export const convertAppointmentsFilterFormValuesToBackEndValues = (values: IAppointmentsListFiltersFormValues): DoctorFilterInput => {
  return {
    vendor: mapFilterValues(values.vendors, vendor => vendor?.value?.id),
    specializations: mapFilterValues(values.specializations, specialization => specialization?.value?.code),
    languages: mapFilterValues(values.languages, language => language?.value?.code),
    appointmentTypes: mapFilterValues(values.appointmentTypes, appointmentType => appointmentType?.value) as
      | AppointmentTypeEnum[]
      | undefined,
    gender: values?.gender?.value as PersonGenderEnum | undefined,
  };
};
