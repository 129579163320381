/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerAppointmentCancelMutationVariables = Types.Exact<{
  consumerAppointmentCancelId: Types.Scalars['ID'];
  input: Types.CancelInput;
}>;


export type ConsumerAppointmentCancelMutation = { __typename?: 'Mutation', consumerAppointmentCancel?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, code: Types.H_EntityErrorCode, message?: string | null }> | null } | null };


export const ConsumerAppointmentCancelDocument = gql`
    mutation ConsumerAppointmentCancel($consumerAppointmentCancelId: ID!, $input: CancelInput!) {
  consumerAppointmentCancel(id: $consumerAppointmentCancelId, input: $input) {
    appointment {
      id
    }
    appointmentErrors {
      field
      code
      message
    }
  }
}
    `;
export type ConsumerAppointmentCancelMutationFn = Apollo.MutationFunction<ConsumerAppointmentCancelMutation, ConsumerAppointmentCancelMutationVariables>;

/**
 * __useConsumerAppointmentCancelMutation__
 *
 * To run a mutation, you first call `useConsumerAppointmentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerAppointmentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerAppointmentCancelMutation, { data, loading, error }] = useConsumerAppointmentCancelMutation({
 *   variables: {
 *      consumerAppointmentCancelId: // value for 'consumerAppointmentCancelId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerAppointmentCancelMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerAppointmentCancelMutation, ConsumerAppointmentCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerAppointmentCancelMutation, ConsumerAppointmentCancelMutationVariables>(ConsumerAppointmentCancelDocument, options);
      }
export type ConsumerAppointmentCancelMutationHookResult = ReturnType<typeof useConsumerAppointmentCancelMutation>;
export type ConsumerAppointmentCancelMutationResult = Apollo.MutationResult<ConsumerAppointmentCancelMutation>;
export type ConsumerAppointmentCancelMutationOptions = Apollo.BaseMutationOptions<ConsumerAppointmentCancelMutation, ConsumerAppointmentCancelMutationVariables>;