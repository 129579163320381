import { AppAuth } from "@/providers/auth";
import { ApolloClient, createHttpLink, from, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { getEnvVariable } from "@/env";
import { oidcUserManager } from "../oidc";
import { cacheUtils } from "./cacheUtils";

const linkOptions = {
  credentials: "same-origin",
  uri: getEnvVariable("BASE_URL"),
};

export const link = createHttpLink(linkOptions);
export const getAccessToken = () => {
  return localStorage.getItem(AppAuth.TOKEN);
};
export const authLink = setContext(async (_, { headers }) => {
  const token = (await oidcUserManager.getUser())?.access_token || null;

  return {
    headers: JSON.parse(
      JSON.stringify({
        ...headers,
        authorization: !token ? undefined : `Bearer ${token}`,
      })
    ),
  };
});

const httpLink = authLink.concat(link);

export const doLogout = async () => {
  throw new Error("Not implemented");
};
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError && "statusCode" in networkError && networkError.statusCode === 401) {
    doLogout();
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (
        message.startsWith("401") ||
        message === "401: Unauthorized" ||
        message === "Exception: Invalid or expired token." ||
        message === "Invalid or expired token."
      ) {
        doLogout();
      }
    });
  }
  if (networkError) console.error(`[operationName]:${operation?.operationName}, [Network error]: ${networkError}`);
});

const splitLink =
  typeof window !== "undefined"
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        httpLink,
        httpLink
      )
    : httpLink;

export const client = new ApolloClient({
  ssrMode: true,
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(cacheUtils),
});
