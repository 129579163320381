import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "380px",
  },
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "10px",
    },
  },
  cardsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "space-between",
    overflowX: "auto",
    scrollbarWidth: "none",
    backgroundColor: "inherit",
  },
  card: {
    width: "100%",
    maxWidth: "100%",
    height: "150px",
    backgroundColor: "blue",
    borderRadius: "15px",
    minWidth: "240px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextAppointmentCardWrapper: {
    width: "33.33%",
    maxWidth: "500px",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      minWidth: "fit-content",
    },
    minWidth: "440px",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: "16px",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.mixins.fonts.fontSize.md,
      marginBottom: "4px",
    },
  },
}));
