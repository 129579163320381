import { Box, useTheme } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { HorizontalCalendar } from "../HorizontalCalendar/HorizontalCalendar";
import { AvailableTimeSlots } from "../AvailableTimeSlots/AvailableTimeSlots";
import moment from "moment";
import { useAppointmentScheduler } from "./useAppointmentScheduler";
import { IFormattedTimeSlot } from "../../types/types";

type AppointmentSchedulerProps = {
  doctorId: string;
  onSubmit: (selectedTimeSlots: IFormattedTimeSlot[]) => void;
};

export type AppointmentSchedulerRef = {
  submit: () => void;
};

const AppointmentSchedulerForwardRef: ForwardRefRenderFunction<AppointmentSchedulerRef, AppointmentSchedulerProps> = (props, ref) => {
  const { doctorId, onSubmit } = props;
  const theme = useTheme();
  const {
    handleDateChange,
    selectedTimeSlots,
    handleTimeSlotSelect,
    availableTimeSlots,
    error,
    onSubmit: _onSubmit,
    loading,
  } = useAppointmentScheduler({
    doctorId,
    initialSelectedDay: moment(),
    onSubmit,
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: _onSubmit,
    }),
    [selectedTimeSlots, onSubmit]
  );

  return (
    <>
      <Box width='100%' display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
        <HorizontalCalendar onChange={handleDateChange} minDate={moment()} />
      </Box>
      {error && (
        <Box marginTop='20px' color={theme.palette.error.main}>
          {error}
        </Box>
      )}
      {
        <AvailableTimeSlots
          isLoading={loading}
          selectedTimeSlots={selectedTimeSlots}
          availableTimeSlots={availableTimeSlots}
          onSelect={handleTimeSlotSelect}
        />
      }
    </>
  );
};

export const AppointmentScheduler = forwardRef(AppointmentSchedulerForwardRef);
