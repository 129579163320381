import { FC } from "react";
import { useSeenNotificationContext } from "../../SeenNotificationContext";
import { Box, CircularProgress, formatDate, Grid, Typography } from "@toolkit/ui";
import { truncate } from "lodash";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import Dot from "@/components/Dot";
import { useMedicalMessageNotificationCardStyle } from "./MedicalMessageNotificationCardStyle";
import { notificationsQueryNode } from "../../types";
import { safeJsonParseWithDefault } from "@/domains/appointment/utils";

type MedicalMessageNotificationCardProps = {
  item: notificationsQueryNode;
};

export const MedicalMessageNotificationCard: FC<MedicalMessageNotificationCardProps> = props => {
  const { item } = props;

  const { t } = useTranslation("consumer");

  const { classes } = useMedicalMessageNotificationCardStyle();

  const { seenId, handleViewNotification } = useSeenNotificationContext();

  const medicalMessageVariantId = item?.data
    ? safeJsonParseWithDefault(item?.data, {
        medicalMessageVariantId: "",
      })?.medicalMessageVariantId
    : "";

  const messages = item?.medicalMessage?.messages;
  const activeMessage = messages?.find(message => message?.id === medicalMessageVariantId?.toString()) || messages?.[0];

  const date = formatDate(item?.creationDate);

  const isLoading = seenId === item?.id;

  const truncateTitle = truncate(pickLocalizedValue(activeMessage?.bodyEnglish, activeMessage?.bodyArabic) || "", { length: 150 });

  const onMedicalMessageNotificationClick = () => {
    handleViewNotification?.({
      medicalMessage: item?.medicalMessage,
      activeMessage,
    });
  };

  return (
    <Box onClick={onMedicalMessageNotificationClick} className={classes.root}>
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={4}>
            <img src={item?.medicalMessage?.headerImage || ""} alt={t("Medical Message")} className={classes.image} />
          </Grid>

          <Grid item xs={8} className={classes.contentWrapper}>
            <Grid container justifyContent='space-between'>
              <Typography className={classes.title} noWrap>
                {pickLocalizedValue(activeMessage?.presentedTitleEnglish, activeMessage?.presentedTitleArabic)}
              </Typography>

              <Typography className={classes.date}>{date}</Typography>
            </Grid>

            <Typography className={classes.description}>{truncateTitle}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: "5%",
          top: "50%",
        }}
      >
        {!item?.seen && (isLoading ? <CircularProgress size={20} /> : <Dot color='primary' />)}
      </Box>
    </Box>
  );
};
