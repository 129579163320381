import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const ComingSoonNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-coming-soon'
        d='M13.04,3a.845.845,0,1,0,0,1.69h6.195a.845.845,0,1,0,0-1.69ZM24.087,5.244a.845.845,0,0,0-.569,1.46L24.84,7.988a.845.845,0,1,0,1.179-1.211L24.7,5.491a.845.845,0,0,0-.609-.247Zm-7.95.572a10.137,10.137,0,0,0-6.144,18.2.845.845,0,1,0,1.024-1.342,8.452,8.452,0,0,1-.932-12.616l4.8,2.957a.818.818,0,0,0,1.247-.7V7.505a8.414,8.414,0,0,1,3.2.626.845.845,0,1,0,.64-1.563A10.114,10.114,0,0,0,16.137,5.816Zm6.008,2.191a.845.845,0,0,0-.551,1.5,8.45,8.45,0,0,1-7.91,14.535.845.845,0,0,0-.489,1.618,10.138,10.138,0,0,0,9.49-17.442A.845.845,0,0,0,22.145,8.007Zm-11.688,4a.845.845,0,0,0-.386,1.575L15.7,16.959a.845.845,0,0,0,.869-1.45L10.94,12.131a.845.845,0,0,0-.483-.125Z'
        transform='translate(6 9)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
