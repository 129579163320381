import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";

export const ChatIcon: FC = () => {
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        width: 44,
        height: 44,
      }}
      viewBox='0 0 44 44'
    >
      <g id='Group_9640' data-name='Group 9640' transform='translate(-296.566 -969.064)'>
        <circle
          id='Ellipse_2640'
          data-name='Ellipse 2640'
          cx='22'
          cy='22'
          r='22'
          transform='translate(296.566 969.064)'
          fill={palette.primary.main}
        />
        <path
          id='icons8-speech_1_'
          data-name='icons8-speech (1)'
          d='M12.783,3C6.827,3,2,7.085,2,12.124a8.292,8.292,0,0,0,2.5,5.834c.031.078.83,2.135-1.536,4.232a.415.415,0,0,0,.373.708A11.032,11.032,0,0,0,9.005,20.66a12.481,12.481,0,0,0,3.778.588c5.956,0,10.783-4.085,10.783-9.124S18.738,3,12.783,3Z'
          transform='translate(305.783 977.611)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
