import { UserNotificationFilterInput } from "@health/queries/types";
import { useGetNotificationCountAfterNewNotification } from "@health/sse";
import { useAuth } from "react-oidc-context";
import { useNotificationsQuery } from "../../gql";

export const useGetNotificationsList = (filters?: UserNotificationFilterInput) => {
  const auth = useAuth();
  const { data, loading, fetchMore, refetch } = useNotificationsQuery({
    variables: {
      first: 10,
      filter: filters,
    },
    context: {
      headers: {
        Authorization: `Bearer ${auth?.user?.access_token}`,
      },
    },
  });

  const notifications = data?.me?.notifications?.edges?.map(edge => edge?.node);
  const pageInfo = data?.me?.notifications?.pageInfo;
  useGetNotificationCountAfterNewNotification(() => {
    refetch({
      first: 10,
      filter: filters,
      after: null,
    });
  });

  const loadMoreNotifications = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo.endCursor || "",
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.me?.notifications?.edges?.length) return prevResult;
          return {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prevResult?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          };
        },
      });
    }
  };
  return { notifications, loading, fetchMore: loadMoreNotifications, pageInfo, refetch };
};
