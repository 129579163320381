import { AppointmentBaseRoute } from "@/domains/appointment/routes";
import { NotificationsBaseRoute } from "@/domains/notifications/routes";
import { VisitCallBaseRoute } from "@/domains/VisitCall/routes";
import { HomeBaseRoute } from "@/pages/Home/routes";

export const routesPaths = {
  login: "/login",
  landing: "/",
  changeLanguage: "/change-language",
  getHelp: "/get-help",
  accountSettings: "/account-settings",
  playGround: "/playground",
  soon: "/soon",
  visitCal: VisitCallBaseRoute,
  appointments: AppointmentBaseRoute,
  notifications: NotificationsBaseRoute,
  home: HomeBaseRoute,
};
