import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const PaperIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='14.68' height='17.88' viewBox='0 0 14.68 17.88' {...props}>
      <g id='Group_15471' data-name='Group 15471' transform='translate(0 0)'>
        <g id='icons8-bill' transform='translate(0 0)'>
          <path
            id='Path_23381'
            data-name='Path 23381'
            d='M20.157,29.469H10.523A2.526,2.526,0,0,1,8,26.946V18.688a.688.688,0,1,1,1.376,0v8.258a1.148,1.148,0,0,0,1.147,1.147h9.634A1.148,1.148,0,0,0,21.3,26.946V25.661a.688.688,0,1,1,1.376,0v1.285A2.526,2.526,0,0,1,20.157,29.469Z'
            transform='translate(-8 -11.589)'
            fill={fill}
          />
          <path
            id='Path_23382'
            data-name='Path 23382'
            d='M21.946,16.309a.688.688,0,0,1-.688-.688V5.751l-.913.406q-.03.013-.062.024a1.2,1.2,0,0,1-.8,0l-.041-.015-1.83-.741-1.86.735a1.087,1.087,0,0,1-.865,0l-1.86-.735-1.83.741-.041.015a1.187,1.187,0,0,1-.874-.028l-.9-.4V8.877A.688.688,0,1,1,8,8.877V5.39A1.14,1.14,0,0,1,9.6,4.345l.008,0,1.147.51,1.831-.741a1.087,1.087,0,0,1,.868,0l1.861.736,1.861-.736a1.087,1.087,0,0,1,.868,0l1.831.741,1.147-.51.008,0a1.14,1.14,0,0,1,1.6,1.045V15.62A.688.688,0,0,1,21.946,16.309Z'
            transform='translate(-8 -4.026)'
            fill={fill}
          />
          <path
            id='Line_1933'
            data-name='Line 1933'
            d='M6.987-.124h-7.8A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5h7.8a.688.688,0,0,1,.688.688A.688.688,0,0,1,6.987-.124Z'
            transform='translate(4.253 6.993)'
            fill={fill}
          />
          <path
            id='Line_1934'
            data-name='Line 1934'
            d='M3.317-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H3.317a.688.688,0,0,1,.688.688A.688.688,0,0,1,3.317-.124Z'
            transform='translate(4.253 11.122)'
            fill={fill}
          />
          <path
            id='Line_1935'
            data-name='Line 1935'
            d='M.106-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.106a.688.688,0,0,1,.688.688A.688.688,0,0,1,.106-.124Z'
            transform='translate(11.134 11.122)'
            fill={fill}
          />
          <path
            id='Line_1936'
            data-name='Line 1936'
            d='M3.317-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H3.317a.688.688,0,0,1,.688.688A.688.688,0,0,1,3.317-.124Z'
            transform='translate(4.253 13.875)'
            fill={fill}
          />
          <path
            id='Line_1937'
            data-name='Line 1937'
            d='M.106-.124H-.812A.688.688,0,0,1-1.5-.812.688.688,0,0,1-.812-1.5H.106a.688.688,0,0,1,.688.688A.688.688,0,0,1,.106-.124Z'
            transform='translate(11.134 13.875)'
            fill={fill}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
