import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { MainPageWithAppBarLayout } from "@/components";
import { routes } from "./routes";

export const HomeRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageWithAppBarLayout withBlob withLgFooter />}>
        <Route path={routes.root.route} element={<routes.root.component />} />
      </Route>
    </Routes>
  );
};
