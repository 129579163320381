import { makeStyles } from "@toolkit/ui";

export const useAppointmentNotificationDetailsActionsStyles = makeStyles()(theme => ({
  footer: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  button: {
    borderRadius: "20px",
    height: "40px",
    padding: "8px 16px",
    textTransform: "none",
    display: "flex",
    paddingInline: "25px",
  },
  rescheduleButton: {
    borderRadius: "20px",
    height: "40px",
    padding: "8px 16px",
    textTransform: "none",
    display: "flex",
  },
}));
