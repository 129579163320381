import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const EmptyNotificationIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='131.187' height='128.69' viewBox='0 0 131.187 128.69' {...props}>
      <g id='Group_7198' data-name='Group 7198' transform='translate(-1077.73 -140.202)'>
        <path
          id='icons8-notification'
          d='M58.549,0A31.11,31.11,0,0,0,36.483,9.144l-7.749,7.742A46.442,46.442,0,0,1,13.448,27.012l49.3,49.3A46.482,46.482,0,0,1,72.874,61.026l7.749-7.749a31.2,31.2,0,0,0,4.518-38.358A7.8,7.8,0,1,0,74.848,4.625,31.16,31.16,0,0,0,58.549,0ZM3.9,27.271a3.9,3.9,0,0,0-2.72,6.7l21.16,21.16A9.743,9.743,0,1,0,34.646,67.434L55.8,88.587a3.9,3.9,0,1,0,5.517-5.517L6.7,28.452a3.9,3.9,0,0,0-2.8-1.181Z'
          transform='translate(1081.994 205.443) rotate(-45)'
          fill={theme.palette.gray[300]}
        />
        <g
          id='Rectangle_4582'
          data-name='Rectangle 4582'
          transform='translate(1195.24 140.202) rotate(48)'
          fill={theme.palette.gray[300]}
          stroke={theme.palette.common.white}
        >
          <rect width='7.691' height='158.126' rx='3.846' stroke='none' />
          <rect x='1' y='1' width='5.691' height='156.126' rx='2.846' fill='none' />
        </g>
      </g>
    </SvgIcon>
  );
};
