import { CircularIconButton, SupplementIcon } from "@/components";
import { Box, Typography, InlineSVG } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./MarketplaceCategoryButtonStyle";
import { useTranslation } from "@toolkit/i18n";

interface MarketplaceCategoryItemButtonProps {
  title?: string | null;
  imgSrc?: string | null;
  onClick?: () => void;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
}

export const MarketplaceCategoryItemButton: FC<MarketplaceCategoryItemButtonProps> = props => {
  const { title, imgSrc, onClick, classes: _classes } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("consumer");
  const isSvg = imgSrc?.endsWith(".svg");
  return (
    <Box className={cx(classes?.root, _classes?.root)}>
      <CircularIconButton onClick={onClick} className={cx(classes?.button, _classes?.button)}>
        {imgSrc &&
          (isSvg ? (
            <InlineSVG className={cx(classes.icon, _classes?.icon)} src={imgSrc!} alt={title || t("Item")} />
          ) : (
            <img src={imgSrc!} alt={title || t("Item")} className={cx(classes.icon, _classes?.icon)} />
          ))}
        {!imgSrc && <SupplementIcon className={cx(classes.icon, _classes?.icon)} />}
      </CircularIconButton>
      <Typography className={cx(classes?.title, _classes?.title)}>{title}</Typography>
    </Box>
  );
};
