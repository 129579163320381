import { Box, FormControlLabel, FormHelperText, RadioGroup, RadioGroupProps, RadioProps } from "@toolkit/ui";
import { get } from "lodash";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomRadio } from "../CustomRadio";

type Option = {
  value: string;
  name: string;
};

type FormRadioProps = RadioProps & {
  name: string;
  options: Option[];
  radioGroupProps?: RadioGroupProps;
};

export const FormRadio: FC<FormRadioProps> = props => {
  const { name, options, radioGroupProps, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup value={field.value ?? ""} onChange={event => field.onChange(event.target.value)} row {...radioGroupProps}>
            {options.map((option: Option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<CustomRadio {...rest} />}
                label={option.name}
                labelPlacement='bottom'
                sx={{
                  fontSize: "small",
                  "&.MuiFormControlLabel-root": {
                    marginInlineStart: "10px",
                  },
                }}
              />
            ))}
          </RadioGroup>
        )}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
