import React from "react";
import { List, Divider } from "@toolkit/ui";
import { MenuItem } from "./menuItems"; // Assuming you have an interface like this
import { MenuSection } from "./MenuSection";
import { useMenuMenuStyles } from "./Menu.styles";

interface MenuListProps {
  menuItems: MenuItem[][];
  classes: Partial<ReturnType<typeof useMenuMenuStyles>["classes"]>;
}

export const MenuList: React.FC<MenuListProps> = ({ menuItems, classes }) => {
  return (
    <List>
      {menuItems.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <MenuSection items={section} sectionIndex={sectionIndex} classes={classes} />
          {sectionIndex !== menuItems?.length - 1 && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </List>
  );
};
