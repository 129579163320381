import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const NotificationBellIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-notification' data-name='icons8-notification' transform='translate(15 15)'>
        <path
          id='Path_13'
          data-name='Path 13'
          d='M23.509,19.452a.7.7,0,1,1,0-1.405.21.21,0,0,0,.188-.092.29.29,0,0,0,.017-.27L22.65,15.6q-.011-.021-.02-.043a3.424,3.424,0,0,1-.291-1.307V11.021a5.552,5.552,0,0,0-1.714-4.036A5.694,5.694,0,0,0,16.416,5.4a5.583,5.583,0,0,0-4.78,3.254.7.7,0,1,1-1.268-.605,7.278,7.278,0,0,1,2.4-2.839,6.747,6.747,0,0,1,3.594-1.215,7.175,7.175,0,0,1,2.879.475,7.044,7.044,0,0,1,2.358,1.5,6.976,6.976,0,0,1,2.145,5.049v3.232a2.042,2.042,0,0,0,.169.733l1.066,2.085q.011.021.02.043a1.7,1.7,0,0,1-.128,1.614A1.621,1.621,0,0,1,23.509,19.452Z'
          transform='translate(-8.291 -3.989)'
          fill='#FFFFFF'
        />
        <path
          id='Path_14'
          data-name='Path 14'
          d='M17.9,28.07H7.646a1.621,1.621,0,0,1-1.361-.724,1.7,1.7,0,0,1-.128-1.614l.017-.037,1.068-2.135a1.82,1.82,0,0,0,.17-.736V20.9a.7.7,0,1,1,1.405,0v1.921a3.239,3.239,0,0,1-.291,1.307l-.017.037L7.443,26.3a.291.291,0,0,0,.016.272.21.21,0,0,0,.188.092H17.9a.7.7,0,1,1,0,1.405Z'
          transform='translate(-6.013 -12.606)'
          fill='#FFFFFF'
        />
        <path
          id='Path_15'
          data-name='Path 15'
          d='M20.811,39.013A2.8,2.8,0,0,1,18,36.2a.7.7,0,0,1,1.405,0,1.4,1.4,0,0,0,1.405,1.405,1.344,1.344,0,0,0,.9-.341.7.7,0,1,1,.925,1.058A2.73,2.73,0,0,1,20.811,39.013Z'
          transform='translate(-12.385 -20.74)'
          fill='#FFFFFF'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
