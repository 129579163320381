import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  pageContainer: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .section__root": {
      ":nth-of-type(2n + 1)": {
        backgroundColor: theme.palette.background.default,
      },
      ":nth-of-type(2n)": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&& .section__root": {
        backgroundColor: theme.palette.background.default,
        height: "fit-content",
      },
      "& .about-us": {
        display: "none",
      },
      "&& .user-profile": {
        order: 1,
      },
      "&& .health-services": {
        order: 2,
      },
      "&& .user-events-and-reads": {
        order: 3,
      },
      "&& .reels": {
        order: 4,
      },
      "& .health-packages": {
        order: 5,
      },
      "&& .categories": {
        order: 6,
      },
      "&& .products": {
        order: 7,
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&& .user-profile": {
        display: "none",
      },
      "&& .health-packages": {
        display: "none",
      },
    },
  },
  portrait: {
    width: "680px",
    height: "680px",
    position: "absolute",
    top: "-90px",
    [pickLocalizedValue("right", "left")]: "-65px",
    transform: pickLocalizedValue("", "scaleX(-1)"),
    [theme.breakpoints.down("xl")]: {
      width: "550px",
      height: "550px",
      top: "-75px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "400px",
      height: "400px",
      top: "-55px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  portraitBlob: {
    top: "-120px",
    [pickLocalizedValue("right", "left")]: "-85px",
    width: "695px",
    height: "695px",
    transform: pickLocalizedValue("", "scaleX(-1)"),
    "& path": {
      fill: theme.palette.primary.main,
      opacity: 0.05,
    },
    [theme.breakpoints.down("xl")]: {
      width: "565px",
      height: "565px",
      top: "-105px",
      [pickLocalizedValue("right", "left")]: "-85px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "415px",
      height: "415px",
      top: "-80px",
      [pickLocalizedValue("right", "left")]: "-85px",
    },
  },
}));
