import { customWebViewStyle } from "@/domains/payment/custom-web-view/custom-web-view.component.style";
import { Box } from "@toolkit/ui";
import _ from "lodash";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PaymentCallbackRootPage: FC = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  _.set(globalThis, "wpwlOptions", {
    style: "plain",
    locale: "en",
    showPlaceholders: true,
  });
  useEffect(() => {
    const script = document.createElement("script");

    script.src = params.get("checkout-payment-form-url")!;
    script.async = true;

    const style = document.createElement("style");
    style.innerHTML = customWebViewStyle;
    document.body.appendChild(script);
    document.body.appendChild(style);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  console.log("params All", params.get("result-url"), params.get("checkout-payment-form-url"));
  return (
    <Box p={2} sx={{ width: "100%", height: "100%" }} className='process-payment-page'>
      <form action={params.get("result-url")!} className='paymentWidgets' data-brands='VISA MASTER AMEX'></form>
    </Box>
  );
};
