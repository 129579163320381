import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const LabVisit: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24.72' height='24.685' viewBox='0 0 24.72 24.685'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.129' y1='0.294' x2='0.267' y2='1.199' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={palette.primary.main} />
          <stop offset='1' stopColor={palette.secondary.main} />
        </linearGradient>
      </defs>
      <path
        id='icons8-blood-sample'
        d='M20.134,3a1.03,1.03,0,0,0-.716,1.769l.294.294L4.233,20.539a4.226,4.226,0,0,0,0,5.954l.3.3H4.69a4.179,4.179,0,0,0,5.5-.3L25.666,11.015l.294.294a1.03,1.03,0,1,0,1.457-1.457l-3.26-3.26L20.875,3.31A1.03,1.03,0,0,0,20.134,3Zm1.034,3.52L22.7,8.049l1.509,1.509L20.464,13.3H14.383Zm4.5,14s-2.061,2.983-2.061,4.121a2.061,2.061,0,1,0,4.121,0C27.727,23.5,25.666,20.515,25.666,20.515Z'
        transform='translate(-3.007 -2.998)'
        fill='url(#linear-gradient)'
      />
    </svg>
  );
};
