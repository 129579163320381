import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Prescription2: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26.02' height='24.888' viewBox='0 0 26.02 24.888' {...props}>
      <path
        id='icons8-pills_5_'
        data-name='icons8-pills (5)'
        d='M8.222,3A6.244,6.244,0,0,0,2,9.246H2c0,.5,0,12,0,12.392H2a6.22,6.22,0,0,0,11.347,3.55,8.486,8.486,0,1,0,1.1-12.559c0-1.2,0-3,0-3.384h0A6.244,6.244,0,0,0,8.222,3Zm0,1.131a5.1,5.1,0,0,1,5.091,5.114c0,.517,0,3.864,0,4.409a8.619,8.619,0,0,0-.958,1.249.566.566,0,0,0-.173-.025H3.131c0-2.685,0-5.388,0-5.633A5.094,5.094,0,0,1,8.222,4.131ZM19.535,12.05A7.353,7.353,0,1,1,12.182,19.4,7.345,7.345,0,0,1,19.535,12.05Zm3.988,2.783a.566.566,0,0,0-.389.171l-8,8a.566.566,0,1,0,.8.8l8-8a.566.566,0,0,0-.411-.971ZM3.131,16.01h8.642a8.323,8.323,0,0,0-.393,5.709.565.565,0,0,0,.375,1.061,8.471,8.471,0,0,0,.835,1.483,5.155,5.155,0,0,1-.674.883.566.566,0,0,0-.819.7,5.033,5.033,0,0,1-5.754.008.564.564,0,0,0-.819-.708,5.1,5.1,0,0,1-1.393-3.508h0c0-.02,0-.481,0-.54a.566.566,0,1,0,0-1.131c0-.549,0-1.312,0-2.263a.566.566,0,0,0,0-1.131C3.131,16.313,3.131,16.279,3.131,16.01Zm3.394.566a.566.566,0,1,0,.566.566A.566.566,0,0,0,6.525,16.575Zm3.394,0a.566.566,0,1,0,.566.566A.566.566,0,0,0,9.919,16.575Zm-5.091,1.7a.566.566,0,1,0,.566.566A.566.566,0,0,0,4.828,18.272Zm3.394,0a.566.566,0,1,0,.566.566A.566.566,0,0,0,8.222,18.272Zm-1.7,1.7a.566.566,0,1,0,.566.566A.566.566,0,0,0,6.525,19.969Zm3.394,0a.566.566,0,1,0,.566.566A.566.566,0,0,0,9.919,19.969Zm-5.091,1.7a.566.566,0,1,0,.566.566A.566.566,0,0,0,4.828,21.666Zm3.394,0a.566.566,0,1,0,.566.566A.566.566,0,0,0,8.222,21.666Zm-1.7,1.7a.566.566,0,1,0,.566.566A.566.566,0,0,0,6.525,23.363Zm3.394,0a.566.566,0,1,0,.566.566A.566.566,0,0,0,9.919,23.363Zm-1.7,1.7a.566.566,0,1,0,.566.566A.566.566,0,0,0,8.222,25.06Z'
        transform='translate(-2 -3)'
        fill={props?.fill ? props?.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
