import { Trans, useTranslation } from "@toolkit/i18n";
import { INotificationViewItem } from "../../types";
import { getNotificationTextStyle } from "../../utils";
import { AppointmentStatus } from "@health/queries/types";
import {
  AppointmentCancelled,
  AppointmentDateTime,
  AppointmentPending,
  AppointmentRequestConfirmation,
} from "./AppointmentNotificationStatusViews";
import { Box, Typography, useTheme } from "@toolkit/ui";

export const AppointmentNotificationViewItem = ({
  id,
  status,
  doctor,
  appointmentType,
  timeframeStart,
  consumer,
  formNowTzItem,
  appointmentDate,
  cancellationReason,
  appointmentSlotTimePeriod,
  requestedTimeSlots,
  suggestedTimeSlots,
  confirmedEndTime,
  confirmedStartTime,
  isSeen,
  appointmentStatus,
}: INotificationViewItem) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  const { color } = getNotificationTextStyle(isSeen!, theme);
  switch (status) {
    case AppointmentStatus.Approved:
    case AppointmentStatus.Confirmed:
      return (
        <AppointmentRequestConfirmation
          id={id}
          doctor={doctor}
          patient={consumer}
          confirmedEndTime={confirmedEndTime}
          confirmedStartTime={confirmedStartTime}
          isSeen={isSeen}
        />
      );
    case AppointmentStatus.PendingApproval:
      return (
        <AppointmentPending
          id={id}
          requestedTimeSlots={requestedTimeSlots}
          appointmentDate={appointmentDate}
          patient={consumer}
          type={appointmentType}
          appointmentSlotTimePeriod={appointmentSlotTimePeriod!}
          doctor={doctor}
          isSeen={isSeen}
          appointmentStatus={appointmentStatus}
        />
      );
    case AppointmentStatus.CanceledByDoctor:
    case AppointmentStatus.Cancelled:
    case AppointmentStatus.CanceledByConsumer:
    case AppointmentStatus.PaymentFailed:
    case AppointmentStatus.PaymentPending:
    case AppointmentStatus.PaymentSucceeded:
      return (
        <AppointmentCancelled
          doctor={doctor}
          type={appointmentType}
          appointmentDate={appointmentDate}
          requestedTimeSlots={requestedTimeSlots}
          cancellationReason={cancellationReason}
          isSeen={isSeen}
        />
      );
    case AppointmentStatus.ChatInProgress:
    case AppointmentStatus.CallInProgress:
      return (
        <Box>
          <Typography color={color} align='left'>
            {t("Visit Starting Now")}
          </Typography>
          <Typography color={color}>
            <Trans
              t={t}
              i18nKey='<a>Starting at</a>: <b>{{appointmentDate}} {{timeframeStart}}</b>'
              values={{ appointmentDate, timeframeStart }}
              components={{
                a: <Typography color={color} display='inline-block' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
                b: <Typography color={color} display='inline-block' fontWeight={theme.mixins.fonts.fontWeight.regular} />,
              }}
            />
          </Typography>
        </Box>
      );
    case AppointmentStatus.Rejected:
      return (
        <Typography color={color}>
          <Trans
            t={t}
            i18nKey='Your <a>{{appointmentType}}</a> Appointment with <a>{{doctor}}</a> has been rejected'
            values={{ appointmentType, doctor }}
            components={{
              a: <Typography color={color} display='inline-block' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
            }}
          />
          <AppointmentDateTime {...{ appointmentDate, requestedTimeSlots, isSeen }} />
        </Typography>
      );
    case AppointmentStatus.RescheduledByConsumer:
      return (
        <Typography color={color}>
          <Trans
            t={t}
            i18nKey='Your <a>{{appointmentType}}</a> Appointment with <a>{{doctor}}</a> has been rescheduled'
            values={{
              appointmentType: appointmentType,
              doctor: doctor,
            }}
            components={{
              a: <Typography color={color} display='inline-block' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
            }}
          />
          <AppointmentDateTime {...{ appointmentDate, requestedTimeSlots, isSeen }} />
        </Typography>
      );
    case AppointmentStatus.RescheduledByDoctor:
      return (
        <Box>
          <Typography color={color}>
            <Typography display='inline' color={color}>
              {t("Appointment Type")}
            </Typography>
            : {appointmentType}
          </Typography>
          <AppointmentDateTime
            {...{
              appointmentDate,
              requestedTimeSlots: suggestedTimeSlots,
              isSeen,
            }}
          />
        </Box>
      );
    case AppointmentStatus.ComingSoon: {
      return (
        <Typography color={color}>
          <Trans
            t={t}
            i18nKey={"Your <b>{{appointmentType}}</b> Appointment with <b>{{doctor}}</b>, is <b>{{formNowTzItem}}</b>"}
            values={{
              appointmentType: appointmentType,
              doctor: doctor,
              formNowTzItem: formNowTzItem,
            }}
            components={{
              b: <Typography display='inline' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
            }}
          />
        </Typography>
      );
    }
    case AppointmentStatus.Request: {
      return <AppointmentDateTime showTimes {...{ appointmentDate, requestedTimeSlots, isSeen }} />;
    }
    default: {
      return <AppointmentDateTime {...{ appointmentDate, isSeen }} />;
    }
  }
};
