import { Box, BoxProps, CustomIcon, IconProps, Typography, useTheme } from "@toolkit/ui";
import { ReactNode } from "react";

interface ListEmptyComponentType {
  title?: string;
  description?: string | ReactNode;
  icon?: IconProps["icon"];
  iconSize?: number | string;
  sx?: BoxProps["sx"];
  actionButton?: ReactNode;
  inverseIcon?: boolean;
}

export type ListEmptyComponentProps = ListEmptyComponentType & BoxProps;

const ListEmptyComponent = (props: ListEmptyComponentProps): JSX.Element => {
  const { icon, sx = {}, iconSize, title, description, actionButton, inverseIcon } = props;
  const theme = useTheme();
  console.log(inverseIcon, iconSize);
  return (
    <Box sx={{ flexDirection: "column", height: "50vh", alignContent: "center", justifyContent: "center" }}>
      <CustomIcon icon={icon!} color={theme.palette.grey[700]} />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: theme.mixins.fonts.fontSize.xl,
          fontWeight: theme.mixins.fonts.fontWeight.medium,
          mt: 5,
          mb: 2,
          mx: 2,
        }}
      >
        {title}
      </Typography>
      <Typography
        color={theme.palette.stale[1100]}
        sx={{
          textAlign: "center",
          fontSize: theme.mixins.fonts.fontSize.md,
          ...sx,
        }}
        mx={1}
      >
        {description}
      </Typography>
      <Box>{actionButton}</Box>
    </Box>
  );
};

export default ListEmptyComponent;
