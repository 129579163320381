import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const RescheduledNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-rollback'
        d='M15.315,4A11.3,11.3,0,0,0,6.991,7.651.849.849,0,1,0,8.24,8.8a9.622,9.622,0,0,1,16.4,8.872.849.849,0,1,0,1.646.414A11.327,11.327,0,0,0,15.315,4Zm.27,4.514a.849.849,0,0,0-.835.861v5.872l-2.58,2.58a.849.849,0,1,0,1.2,1.2L16.2,16.2a.849.849,0,0,0,.249-.6V9.375a.849.849,0,0,0-.862-.861ZM5.455,11a.849.849,0,0,0-.823.58,11.323,11.323,0,0,0,9.925,15.027.849.849,0,1,0,.113-1.693A9.626,9.626,0,0,1,6.234,12.138.849.849,0,0,0,5.455,11Zm13.966,6.025a.849.849,0,0,0-.583.256l-1.68,1.68a.849.849,0,0,0,0,1.2l1.68,1.68a.849.849,0,1,0,1.2-1.2l-.231-.231H22.67a2.263,2.263,0,1,1,0,4.526H19.276a.849.849,0,1,0,0,1.7H22.67a3.96,3.96,0,1,0,0-7.921H19.807l.231-.231a.849.849,0,0,0-.617-1.456Z'
        transform='translate(8 8)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
