import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const PendingApprovalNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-schedule_3_' data-name='icons8-schedule (3)' transform='translate(11 13)'>
        <path
          id='Path_15015'
          data-name='Path 15015'
          d='M21.887,25.388H9.5a3.474,3.474,0,0,1-3.5-3.5V17.04a.808.808,0,1,1,1.616,0v4.847A1.865,1.865,0,0,0,9.5,23.772H21.887a1.865,1.865,0,0,0,1.885-1.885V9.5a1.865,1.865,0,0,0-1.885-1.885h-4.9a.808.808,0,1,1,0-1.616h4.9a3.474,3.474,0,0,1,3.5,3.5V21.887a3.474,3.474,0,0,1-3.5,3.5Z'
          transform='translate(-2.769 -2.769)'
          fill='#fff'
        />
        <path
          id='Line_1834'
          data-name='Line 1834'
          d='M9.971.116H-.692A.808.808,0,0,1-1.5-.692.808.808,0,0,1-.692-1.5H9.971a.808.808,0,0,1,.808.808A.808.808,0,0,1,9.971.116Z'
          transform='translate(11.84 9.04)'
          fill='#fff'
        />
        <path
          id='Line_1835'
          data-name='Line 1835'
          d='M-.692,2.539A.808.808,0,0,1-1.5,1.731V-.692A.808.808,0,0,1-.692-1.5a.808.808,0,0,1,.808.808V1.731A.808.808,0,0,1-.692,2.539Z'
          transform='translate(18.734 3.385)'
          fill='#fff'
        />
        <path
          id='Path_15016'
          data-name='Path 15016'
          d='M6.193,12.387A6.209,6.209,0,0,1,0,6.193,7,7,0,0,1,.181,4.618a.808.808,0,1,1,1.577.35,5.374,5.374,0,0,0-.142,1.225,4.571,4.571,0,0,0,7.81,3.232,4.578,4.578,0,0,0-3.232-7.81,4.856,4.856,0,0,0-1.437.235A.808.808,0,0,1,4.292.3a6.481,6.481,0,0,1,1.9-.3,6.187,6.187,0,0,1,4.375,10.568A6.15,6.15,0,0,1,6.193,12.387Z'
          fill='#fff'
        />
        <path
          id='Path_15017'
          data-name='Path 15017'
          d='M11.885,10.386a.805.805,0,0,1-.571-.237L10.237,9.072A.808.808,0,0,1,10,8.5V5.808a.808.808,0,1,1,1.616,0V8.166l.84.84a.808.808,0,0,1-.571,1.379Z'
          transform='translate(-4.614 -2.307)'
          fill='#fff'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
