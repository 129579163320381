import { FC } from "react";
import { HealthServicesIconProps, HealthServicesIconWrapper } from "./HealthServicesIconWrapper";

export const HealthServiceKnowledgeHubIcon: FC<HealthServicesIconProps> = props => {
  return (
    <HealthServicesIconWrapper {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='knowledge' transform='translate(-13,2)'>
        <path
          id='Path_22312'
          data-name='Path 22312'
          d='M55.866,16.914H41.353a3.114,3.114,0,0,0-3-2.414H38.32a3.116,3.116,0,0,0-3.12,3.091V42.879A3.141,3.141,0,0,0,38.32,46H53.157a1.91,1.91,0,0,0,1.913-1.913v-.5H55.9a1.91,1.91,0,0,0,1.913-1.913V18.827A1.954,1.954,0,0,0,55.866,16.914ZM38.232,41.171h.059A1.717,1.717,0,0,1,40,42.82v.059a.716.716,0,0,0,.707.707H53.628v.5a.516.516,0,0,1-.5.5H38.291a1.667,1.667,0,0,1-1.207-.5,1.718,1.718,0,0,1-.5-1.207A1.7,1.7,0,0,1,38.232,41.171Zm-1.649-.913V17.62a1.707,1.707,0,1,1,3.415,0V40.258a3.034,3.034,0,0,0-1.707-.5A3.285,3.285,0,0,0,36.584,40.258ZM56.366,18.827V41.672a.516.516,0,0,1-.5.5H41.441V18.327H55.866A.516.516,0,0,1,56.366,18.827Z'
          fill='#fff'
        />
        <path
          id='Path_22313'
          data-name='Path 22313'
          d='M60.784,37.842h6.064a.59.59,0,0,0,.589-.589V31.189a.59.59,0,0,0-.589-.589H60.784a.59.59,0,0,0-.589.589v6.064A.572.572,0,0,0,60.784,37.842Zm.589-1.207V31.778H66.23v4.857Z'
          transform='translate(-17.636 -11.36)'
          fill='#fff'
        />
        <path
          id='Path_22314'
          data-name='Path 22314'
          d='M92.621,30.5H89.589a.591.591,0,0,0-.589.589v1.825a.591.591,0,0,0,.589.589h3.032a.591.591,0,0,0,.589-.589V31.089A.591.591,0,0,0,92.621,30.5ZM92,31.707v.618H90.178v-.618Z'
          transform='translate(-37.962 -11.29)'
          fill='#fff'
        />
        <path
          id='Path_22315'
          data-name='Path 22315'
          d='M92.621,44.9H89.589a.591.591,0,0,0-.589.589v1.825a.59.59,0,0,0,.589.589h3.032a.59.59,0,0,0,.589-.589v-1.8A.6.6,0,0,0,92.621,44.9ZM92,46.107v.618H90.178v-.618Z'
          transform='translate(-37.962 -21.451)'
          fill='#fff'
        />
        <path
          id='Path_22316'
          data-name='Path 22316'
          d='M60.789,60.578h1.619a.589.589,0,0,0,0-1.178H60.789a.589.589,0,1,0,0,1.178Z'
          transform='translate(-17.64 -31.682)'
          fill='#fff'
        />
        <path
          id='Path_22317'
          data-name='Path 22317'
          d='M80.273,59.3H72.089a.589.589,0,0,0,0,1.178h8.184a.589.589,0,1,0,0-1.178Z'
          transform='translate(-25.614 -31.612)'
          fill='#fff'
        />
        <path
          id='Path_22318'
          data-name='Path 22318'
          d='M72.3,67.4H60.789a.589.589,0,0,0,0,1.178H72.3a.589.589,0,1,0,0-1.178Z'
          transform='translate(-17.64 -37.327)'
          fill='#fff'
        />
        <path
          id='Path_22319'
          data-name='Path 22319'
          d='M72.3,75.5H60.789a.589.589,0,0,0,0,1.178H72.3a.589.589,0,1,0,0-1.178Z'
          transform='translate(-17.64 -43.043)'
          fill='#fff'
        />
        <path
          id='Path_22320'
          data-name='Path 22320'
          d='M72.3,83.6H60.789a.589.589,0,0,0,0,1.178H72.3a.589.589,0,1,0,0-1.178Z'
          transform='translate(-17.64 -48.758)'
          fill='#fff'
        />
        <path
          id='Path_22321'
          data-name='Path 22321'
          d='M72.3,91.7H60.789a.589.589,0,0,0,0,1.178H72.3a.589.589,0,0,0,0-1.178Z'
          transform='translate(-17.64 -54.474)'
          fill='#fff'
        />
        <path
          id='Path_22322'
          data-name='Path 22322'
          d='M65.617,99.8H60.789a.589.589,0,1,0,0,1.178h4.857a.59.59,0,0,0,.589-.589A.6.6,0,0,0,65.617,99.8Z'
          transform='translate(-17.64 -60.189)'
          fill='#fff'
        />
        <path
          id='Path_22323'
          data-name='Path 22323'
          d='M88.246,99.8H83.389a.589.589,0,0,0,0,1.178h4.857a.589.589,0,1,0,0-1.178Z'
          transform='translate(-33.587 -60.189)'
          fill='#fff'
        />
      </g>
    </HealthServicesIconWrapper>
  );
};
