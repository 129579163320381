import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const ReminderNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-remind'
        d='M6.909,4.488a.807.807,0,0,0-.434.131A12.108,12.108,0,0,0,2.743,8.35a.808.808,0,1,0,1.364.864,10.527,10.527,0,0,1,3.23-3.23.807.807,0,0,0-.429-1.5Zm12.222,0a.807.807,0,0,0-.4,1.5,10.529,10.529,0,0,1,3.229,3.23A.808.808,0,0,0,23.32,8.35a12.107,12.107,0,0,0-3.73-3.731.807.807,0,0,0-.459-.131Zm-6.1,1.027A9.388,9.388,0,0,0,6.761,21.893L5.732,22.923a.808.808,0,1,0,1.143,1.142L8.5,22.439a.872.872,0,0,0-.112-1.293A7.781,7.781,0,0,1,15.324,7.472.807.807,0,1,0,15.8,5.929,9.4,9.4,0,0,0,13.033,5.515Zm5.4,1.916a.807.807,0,0,0-.5,1.441,7.779,7.779,0,0,1-7.187,13.473.808.808,0,0,0-.473,1.544,9.352,9.352,0,0,0,7.717-1.022l1.2,1.2a.808.808,0,1,0,1.143-1.142L19.3,21.886a9.371,9.371,0,0,0-.338-14.266A.807.807,0,0,0,18.437,7.431ZM12.324,9.216a.807.807,0,0,0-.795.819V15.6a.807.807,0,0,0,.807.807h3.713a.807.807,0,1,0,0-1.615H13.144V10.035a.807.807,0,0,0-.82-.819ZM1.852,10.672a.807.807,0,0,0-.795.819c0,.289-.024.252-.226.5A2.459,2.459,0,0,0,.25,13.507,2.459,2.459,0,0,0,.83,15.02c.2.252.227.215.227.5s-.025.254-.227.507a2.265,2.265,0,0,0,0,3.027c.2.253.227.216.227.506a.807.807,0,1,0,1.615,0,2.463,2.463,0,0,0-.58-1.514c-.2-.253-.227-.216-.227-.506s.025-.253.227-.506a2.466,2.466,0,0,0,.58-1.515,2.459,2.459,0,0,0-.58-1.513c-.2-.252-.227-.215-.227-.5s.024-.251.226-.5a2.459,2.459,0,0,0,.581-1.513.807.807,0,0,0-.82-.819Zm23.143,0a.807.807,0,0,0-.795.819c0,.289-.024.252-.226.5a2.459,2.459,0,0,0-.581,1.513,2.459,2.459,0,0,0,.58,1.513c.2.252.227.215.227.5s-.025.254-.227.507a2.265,2.265,0,0,0,0,3.027c.2.253.227.216.227.506a.807.807,0,1,0,1.615,0,2.463,2.463,0,0,0-.58-1.514c-.2-.253-.227-.216-.227-.506s.025-.253.227-.506a2.265,2.265,0,0,0,0-3.027c-.2-.252-.227-.215-.227-.5s.024-.251.226-.5a2.459,2.459,0,0,0,.581-1.513.807.807,0,0,0-.82-.819Z'
        transform='translate(10 9)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
