import { useTranslation } from "@toolkit/i18n";
import React, { FC, ReactElement, ReactNode } from "react";
import { useStyles } from "./NotificationCardStyle";
import { Box, Typography } from "@toolkit/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import "moment/locale/en-il";
import { NotificationCardSkeleton } from "../NotificationCardSkeleton/NotificationCardSkeleton";

type NotificationCardBodyProps = {
  title: string;
  date?: string;
  isLoading?: boolean;
  isSeen?: boolean;
  icon?: ReactElement;
  iconColor?: string;
  children?: ReactNode;
};

export const NotificationCard: FC<NotificationCardBodyProps> = props => {
  const { i18n } = useTranslation("consumer");
  const { title, date, isLoading = false, isSeen, iconColor, children, icon } = props;
  const { classes, theme } = useStyles({ isSeen });

  const creationDate = date ? moment(date).locale(i18n.language).fromNow() : "";

  return isLoading ? (
    <NotificationCardSkeleton />
  ) : (
    <Box className={classes.root} display='flex' alignItems='center'>
      {icon && (
        <Box className={classes.iconContainer}>
          {React.cloneElement(icon, {
            fill: isSeen ? theme.palette.gray[800] : iconColor || theme.palette.primary.main,
            variant: "circular",
          })}
        </Box>
      )}
      <Box flex={1} marginLeft={0}>
        <Box className={classes.cardHeader}>
          <Typography className={classes.title}>{title}</Typography>
          {!!date && (
            <Typography variant='caption' className={classes.date}>
              {creationDate}
            </Typography>
          )}
        </Box>

        {!!children && <Box className={classes.body}>{children}</Box>}
      </Box>
    </Box>
  );
};
