/* eslint-disable react/no-unknown-property */
import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const HealthIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='22.55' height='18.45' viewBox='0 0 22.55 18.45' {...props}>
      <defs>
        <radialGradient id='radial-gradient1' cx='0.31' cy='0.264' r='0.759' gradientUnits='objectBoundingBox'>
          <stop offset='0' stop-color='#73e544' />
          <stop offset='0.642' stop-color='#4eb915' />
          <stop offset='1' stop-color='#3da500' />
        </radialGradient>
        <linearGradient id='linear-gradient1' x1='0.506' y1='0.063' x2='0.486' y2='0.966' gradientUnits='objectBoundingBox'>
          <stop offset='0.147' stop-color={"#54c01c"} stop-opacity='0' />
          <stop offset='0.845' stop-color={"#3d8100"} />
        </linearGradient>
        <radialGradient id='radial-gradient-2' cx='0.5' cy='0.5' r='0.719' gradientUnits='objectBoundingBox'>
          <stop offset='0.129' stop-color={"#acf97a"} />
          <stop offset='0.834' stop-color={"#acf97a"} stop-opacity='0' />
        </radialGradient>
      </defs>
      <g id='icons8-heart_1_' data-name='icons8-heart (1)' transform='translate(-5.104 -8.533)'>
        <path
          id='Path_13037'
          data-name='Path 13037'
          d='M21.5,8.533a7.919,7.919,0,0,0-5.125,2.05,7.883,7.883,0,0,0-5.125-2.05,6.15,6.15,0,0,0-6.15,6.15c0,6.15,11.275,12.3,11.275,12.3s11.275-6.15,11.275-12.3A6.15,6.15,0,0,0,21.5,8.533Z'
          fill='url(#radial-gradient1)'
        />
        <path
          id='Path_13038'
          data-name='Path 13038'
          d='M21.5,8.533a7.919,7.919,0,0,0-5.125,2.05,7.883,7.883,0,0,0-5.125-2.05,6.15,6.15,0,0,0-6.15,6.15c0,6.15,11.275,12.3,11.275,12.3s11.275-6.15,11.275-12.3A6.15,6.15,0,0,0,21.5,8.533Z'
          fill='url(#linear-gradient1)'
        />
        <path
          id='Path_13039'
          data-name='Path 13039'
          d='M16.146,11.22c.756,1.31-.236,3.3-2.216,4.442s-4.2,1.008-4.954-.3.236-3.3,2.216-4.442S15.39,9.91,16.146,11.22Z'
          transform='translate(-1.459 -0.649)'
          opacity='0.69'
          fill='url(#radial-gradient-2)'
        />
      </g>
    </SvgIcon>
  );
};
