import { Box, Button, EditIcon, IconButton, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { DetailCard } from "../../components/DetailCard/DetailCard";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { useTranslation } from "@toolkit/i18n";
import { toTitleCase } from "@toolkit/core";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import { useCommonStyles } from "../CommonContainer.styles";
import { useAppointmentCreation } from "./useAppointmentCreation";
import { useAppointmentConfirmationData } from "./useAppointmentConfirmationData";
import Dot from "@/components/Dot";
import { MultiLineDetailCard } from "../../components/DetailCard/MultiLineDetailCard";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

export const AppointmentConfirmationInfoContainer: FC = () => {
  const { visitType, selectedTimeSlots, doctorInfo, guestInfo } = useAppointmentBookingContext();
  const { classes } = useCommonStyles();
  const theme = useTheme();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const { createAppointmentHandler, isSubmitting } = useAppointmentCreation(visitType!, selectedTimeSlots, doctorInfo, guestInfo);

  useEffect(() => {
    if (!doctorInfo?.doctorId) {
      navigate(routes.root.absRoute);
    }
  }, [doctorInfo]);
  const handleEdit = () => {
    navigate(routes.confirmForm.absRoute);
  };

  const { data, loading, isAuthenticated } = useAppointmentConfirmationData(doctorInfo?.doctorId, visitType!, guestInfo);

  const { contactInfo, insuranceInfo, paymentInfo } = data;

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography
          textAlign='start'
          fontSize={theme.mixins.fonts.fontSize.lg}
          fontWeight={theme.mixins.fonts.fontWeight.medium}
          marginBottom='10px'
        >
          {t("Appointment Confirmation")}
        </Typography>
        <DetailCard title={t("Visit Type")} content={t(toTitleCase(visitType || ""))} />
        <DetailCard title={t("Appointment Date")} content={selectedTimeSlots[0]?.startTime?.format("DD MMMM YYYY")} />
        <DetailCard
          title={t("Appointment Time")}
          content={
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {selectedTimeSlots.map(slot => `${slot?.startTime?.format("HH:mm A")}-${slot?.endTime?.format("HH:mm A")}`).join("\n")}
            </Typography>
          }
        />
        {contactInfo && (
          <>
            <MultiLineDetailCard
              title={t("My Contact Information")}
              items={[
                ...(contactInfo.email ? [{ label: t("Email"), value: contactInfo.email }] : []),
                { label: t("Mobile"), value: contactInfo.mobile || "-" },
              ]}
              actions={
                !isAuthenticated && (
                  <IconButton
                    sx={{
                      "& svg": {
                        width: "12px",
                        height: "12px",
                        fill: theme.palette.primary?.main,
                      },
                    }}
                    onClick={handleEdit}
                  >
                    <EditIcon />
                  </IconButton>
                )
              }
            />
            {insuranceInfo && (
              <MultiLineDetailCard
                title={t("Insurance Information")}
                items={[
                  {
                    label: t("Insurance Id"),
                    value: insuranceInfo?.insuranceId || "-",
                  },
                  {
                    label: t("Insurance Company"),
                    value: insuranceInfo?.companyName || "-",
                  },
                ]}
                actions={
                  !isAuthenticated && (
                    <IconButton
                      sx={{
                        "& svg": {
                          width: "12px",
                          height: "12px",
                          fill: theme.palette.primary?.main,
                        },
                      }}
                      onClick={handleEdit}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                }
              />
            )}
            {paymentInfo && (
              <DetailCard
                title={t("Payment Information")}
                content={
                  <Typography color={paymentInfo?.hasCoverage ? "success" : "secondary"}>
                    <Dot size={12} color={paymentInfo?.hasCoverage ? "success" : "secondary"} marginInlineEnd={1} />
                    {paymentInfo?.hasCoverage ? t("Covered by the Insurance") : t("Not Covered by the Insurance")}
                  </Typography>
                }
              />
            )}{" "}
          </>
        )}
        <AppointmentDoctorCard classes={{ card: classes.mgInline_0 }} {...doctorInfo} />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.button} disabled={loading || isSubmitting} onClick={createAppointmentHandler}>
          {t("Confirm")}
        </Button>
      </Box>
    </Box>
  );
};
