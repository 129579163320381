import React from "react";
import { Box, Typography, IconButton } from "@toolkit/ui";
import useStyles from "./DoctorServiceButton.styles";

interface ServiceOptionProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const DoctorServiceButton: React.FC<ServiceOptionProps> = props => {
  const { icon, label, onClick, disabled } = props;
  const { classes } = useStyles({ disabled });
  return (
    <Box className={classes.container} onClick={!disabled ? onClick : undefined} aria-label={label} aria-disabled={disabled}>
      <IconButton className={classes.button} aria-label={label} disabled={disabled}>
        {icon}
      </IconButton>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};
