import { useOpenState } from "@toolkit/core";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect } from "react";
import PaymentFormWebView, { PaymentFormWebViewProps } from "../payment-web-view/payment-web-view.component";

export const PayNowDialog: FC<PaymentFormWebViewProps> & {
  open: () => void;
  close: () => void;
} = props => {
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  PayNowDialog.open = () => {
    console.log("Open Called");
    handleOpen();
  };
  PayNowDialog.close = handleClose;
  useEffect(() => {
    localStorage.setItem("payment-call-back-source-page", location.href);
  }, []);
  return (
    <CustomDialog type='bottomSheet' open={isOpen} handleClose={handleClose}>
      <PaymentFormWebView {...props} />
    </CustomDialog>
  );
};

PayNowDialog.close = () => {};
PayNowDialog.open = () => {};
