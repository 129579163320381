import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const OKIconHealthProgram: FC<IconProps> = ({ width = "16", height = "16", viewBox = "0 0 16 16", ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-ok'
        d='M11,3a8,8,0,1,0,8,8A8,8,0,0,0,11,3Zm4.471,6.471-5.04,5.04a.666.666,0,0,1-.943,0l-2.3-2.3a.667.667,0,0,1,.943-.943L9.96,13.1l4.569-4.569a.667.667,0,1,1,.943.943Z'
        transform='translate(-3 -3)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
