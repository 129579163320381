/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type DoctorInfoFragmentFragment = { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, arabicDisplay?: string | null, display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, name: string, nameAr?: string | null }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null, id: string } | null> | null };

export const DoctorInfoFragmentFragmentDoc = gql`
    fragment DoctorInfoFragment on Doctor {
  id
  user {
    id
    firstName
    lastName
    gender
    photo
  }
  specializations {
    id
    arabicDisplay
    display
  }
  yearsOfExperience
  vendor {
    id
    logo
    name
    nameAr
  }
  appointmentTypes
  languages {
    display
    displayAr
    id
  }
}
    `;