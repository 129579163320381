import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "562px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "10px",
      gap: "0px",
    },
  },
  reelCardWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "155px",
      height: "255px",
    },
    width: "230px",
    height: "330px",
  },
  cardsContainerClassName: {
    height: "100%",
    overflowY: "hidden",
  },
  cardsUpperContainer: {
    gap: "0px",
  },
  cardsContainer: {},
  card: {
    marginInlineEnd: 16,
    [theme.breakpoints.down("sm")]: {
      marginInlineEnd: 5,
    },
    height: "fit-content",
  },
  navBtnClassName: {},
  navBtnContainerClassName: {},
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  seeAllButton__container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    "& .see-all__title": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  skeletonContainer: {
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },
}));
