import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "89px",
    height: "89px",
    boxShadow: theme.mixins.shadows.sm,
    objectFit: "cover",
  },
  default: {
    width: "89px",
    height: "89px",
    "& img": {
      width: "75px",
      height: "75px",
    },
    boxShadow: "unset",
  },
}));
