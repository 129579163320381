import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const CartIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.805' height='17.27' viewBox='0 0 20.805 17.27' {...props}>
      <g id='icons8-cart' transform='translate(-2 -6)'>
        <path
          id='Path_12972'
          data-name='Path 12972'
          d='M19.081,21.513H12.605a3.131,3.131,0,0,1-1.994-.717,3.058,3.058,0,0,1-1.065-1.843l-1.534-8.1a.72.72,0,0,1,1.414-.268l1.535,8.107,0,.016a1.646,1.646,0,0,0,1.641,1.366h6.476a.72.72,0,0,1,0,1.439Z'
          transform='translate(-3.122 -2.081)'
          fill={fill}
        />
        <path
          id='Path_12973'
          data-name='Path 12973'
          d='M25.734,21.561a.72.72,0,0,1,0-1.439,1.646,1.646,0,0,0,1.641-1.366l0-.014,1.2-6.428,0-.016a.708.708,0,0,0-.137-.6.694.694,0,0,0-.551-.255H16.62a.72.72,0,1,1,0-1.439H27.893a2.133,2.133,0,0,1,2.1,2.586L28.794,19a3.058,3.058,0,0,1-1.065,1.844A3.131,3.131,0,0,1,25.734,21.561Z'
          transform='translate(-7.232 -2.081)'
          fill={fill}
        />
        <path
          id='Path_12974'
          data-name='Path 12974'
          d='M5.6,9.358a.72.72,0,0,1-.706-.587l-.142-.757a.711.711,0,0,0-.686-.575H2.72A.72.72,0,1,1,2.72,6H4.063a2.125,2.125,0,0,1,1.353.489A2.2,2.2,0,0,1,6.158,7.72l0,.018.144.768a.72.72,0,0,1-.708.852Z'
          transform='translate(0 0)'
          fill={fill}
        />
        <circle
          id='Ellipse_2891'
          data-name='Ellipse 2891'
          cx='1.439'
          cy='1.439'
          r='1.439'
          transform='translate(9.196 20.392)'
          fill={fill}
        />
        <circle
          id='Ellipse_2892'
          data-name='Ellipse 2892'
          cx='1.439'
          cy='1.439'
          r='1.439'
          transform='translate(15.912 20.392)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
