import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const ScaleIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      viewBox={viewBox ? viewBox : "0 0 22 22"}
      {...props}
    >
      <path
        id='icons8-scale'
        d='M6,4A2,2,0,0,0,4,6V24a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Zm9,3a16.4,16.4,0,0,1,8.768,2.539L21,16H17a2,2,0,0,0-.137-.726l2.918-3.649a1,1,0,1,0-1.562-1.25l-2.92,3.65A1.982,1.982,0,0,0,13,16H9L6.232,9.539A16.4,16.4,0,0,1,15,7Z'
        transform='translate(-4 -4)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
