import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const NameDoctorIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18.024' height='18.065' viewBox='0 0 18.024 18.065' {...props}>
      <g id='icons8-stethoscope_1_' data-name='icons8-stethoscope (1)' transform='translate(0 0)'>
        <ellipse
          id='Ellipse_3031'
          data-name='Ellipse 3031'
          cx='0.614'
          cy='0.614'
          rx='0.614'
          ry='0.614'
          transform='translate(14.747 10.281)'
          fill='none'
        />
        <path
          id='Path_13032'
          data-name='Path 13032'
          d='M35.663,27.325a2.663,2.663,0,1,1,0-5.325.614.614,0,1,1,0,1.229A1.434,1.434,0,1,0,37.1,24.663a.614.614,0,0,1,1.229,0,2.643,2.643,0,0,1-2.663,2.663Z'
          transform='translate(-20.301 -13.767)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13033'
          data-name='Path 13033'
          d='M18.614,15.65a.614.614,0,0,1-.452-1.03l4.588-5,.018-.019a3.345,3.345,0,0,0,.967-2.351,1.025,1.025,0,0,0-1.024-1.024H22.3A.614.614,0,1,1,22.3,5h.41a2.256,2.256,0,0,1,2.253,2.253,4.583,4.583,0,0,1-1.317,3.21l-4.579,4.988A.613.613,0,0,1,18.614,15.65Z'
          transform='translate(-11.446 -3.731)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13034'
          data-name='Path 13034'
          d='M8.35,15.65a.613.613,0,0,1-.453-.2L3.317,10.463A4.521,4.521,0,0,1,2,7.253,2.256,2.256,0,0,1,4.253,5h.2a.614.614,0,1,1,0,1.229h-.2A1.025,1.025,0,0,0,3.229,7.253,3.3,3.3,0,0,0,4.2,9.6l.018.019,4.588,5a.614.614,0,0,1-.452,1.03Z'
          transform='translate(-2 -3.731)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13035'
          data-name='Path 13035'
          d='M16.606,23.372a.615.615,0,0,1-.507-.961.57.57,0,0,0,.037-.6.522.522,0,0,0-.472-.285H14.353a.522.522,0,0,0-.472.285.57.57,0,0,0,.037.6A.614.614,0,0,1,12.9,23.1a1.8,1.8,0,0,1-.109-1.864,1.736,1.736,0,0,1,1.559-.94h1.311a1.736,1.736,0,0,1,1.559.94,1.8,1.8,0,0,1-.109,1.864A.614.614,0,0,1,16.606,23.372Z'
          transform='translate(-8.249 -12.763)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13036'
          data-name='Path 13036'
          d='M21.67,35.964a4.088,4.088,0,0,1-3.538-1.913A8.021,8.021,0,0,1,17,29.614a.614.614,0,0,1,1.229,0,6.823,6.823,0,0,0,.926,3.756,2.873,2.873,0,0,0,2.515,1.364,4.04,4.04,0,0,0,3.943-3.595.614.614,0,0,1,1.208.225,5.857,5.857,0,0,1-1.756,3.3A4.942,4.942,0,0,1,21.67,35.964Z'
          transform='translate(-10.855 -17.899)'
          fill={palette.primary.main}
        />
        <path
          id='Ellipse_3032'
          data-name='Ellipse 3032'
          d='M.589-1.5A2.089,2.089,0,1,1-1.5.589,2.092,2.092,0,0,1,.589-1.5Zm0,2.949a.86.86,0,1,0-.86-.86A.861.861,0,0,0,.589,1.449Z'
          transform='translate(3.507 1.5)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13037'
          data-name='Path 13037'
          d='M20.989,6.078A2.083,2.083,0,0,1,18.9,3.989a.614.614,0,0,1,1.229,0,.86.86,0,1,0,.86-.86.614.614,0,1,1,0-1.229,2.089,2.089,0,1,1,0,4.178Z'
          transform='translate(-11.977 -1.9)'
          fill={palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};
