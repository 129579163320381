import {
  AppointmentConfirmationGuestFormContainer,
  AppointmentConfirmationGuestOTPContainer,
  AppointmentConfirmationInfoContainer,
  AppointmentRescheduleContainer,
  AppointmentsListContainer,
  TimeSelectionContainer,
} from "@/domains/appointment/containers";
import { AppointmentCreateSuccessContainer } from "./containers/AppointmentCreateSuccessContainer/AppointmentCreateSuccessContainer";
import { AppointmentConfirmDoctorTimeContainer } from "./containers/AppointmentConfirmDoctorTimeContainer/AppointmentConfirmDoctorTimeContainer";

export const AppointmentBaseRoute = "/appointments";
export const routes = {
  root: {
    route: "/",
    component: AppointmentsListContainer,
    get absRoute() {
      return AppointmentBaseRoute;
    },
  },
  schedule: {
    route: "/schedule",
    component: TimeSelectionContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
    getRouteWithParams({ visitType, doctorId }: { visitType: "ONSITE" | "ONLINE" | "AT_HOME"; doctorId: string }) {
      return `${this.absRoute}?visitType=${visitType}&doctorId=${doctorId}`;
    },
  },
  confirm: {
    route: "/confirm",
    component: AppointmentConfirmationInfoContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
  },
  confirmForm: {
    route: "/confirm-guest",
    component: AppointmentConfirmationGuestFormContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
  },
  confirmOTP: {
    route: "/confirm-otp",
    component: AppointmentConfirmationGuestOTPContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
  },
  createSuccess: {
    route: "/create-success",
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
    component: AppointmentCreateSuccessContainer,
  },
  reschedule: {
    route: "/reschedule",
    component: AppointmentRescheduleContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
    getRouteWithParams({ appointmentId, doctorId }: { appointmentId: string; doctorId: string }) {
      return `${this.absRoute}?appointmentId=${appointmentId}&doctorId=${doctorId}`;
    },
  },
  confirmDoctorTime: {
    route: "/confirm-doctor-time",
    component: AppointmentConfirmDoctorTimeContainer,
    get absRoute() {
      return `${AppointmentBaseRoute}${this.route}`;
    },
    getRouteWithParams({ appointmentId, doctorId }: { appointmentId: string; doctorId: string }) {
      return `${this.absRoute}?appointmentId=${appointmentId}&doctorId=${doctorId}`;
    },
  },
};
