import { usePatientProfileConsentFormQuery } from "../../gql";

export const useAccessRequestHook = (id: string | null) => {
  const {
    data,
    loading: loadingConsent,
    error: errorConsent,
  } = usePatientProfileConsentFormQuery({
    skip: !id,
    variables: id ? { patientProfileConsentFormId: id } : undefined,
  });

  const patientProfileConsentForm = data?.patientProfileConsentForm;

  return {
    errorConsent,
    loadingConsent,

    patientProfileConsentForm,
  };
};
