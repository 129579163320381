import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormCheckbox, FormPhoneInput, FormTextField } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import {
  AppointmentConfirmationFormSchema,
  appointmentConfirmationFormDefaultValues,
  IAppointmentConfirmationFormValues,
} from "./AppointmentConfirmationFormSchema";
import { pick } from "lodash";

type AppointmentConfirmationFormProps = {
  onSubmit: (values: IAppointmentConfirmationFormValues) => void;
  defaultValues?: IAppointmentConfirmationFormValues;
};

export type AppointmentConfirmationFormRef = {
  submit: () => void;
};

const AppointmentConfirmationFormForwardRef: ForwardRefRenderFunction<AppointmentConfirmationFormRef, AppointmentConfirmationFormProps> = (
  props,
  ref
) => {
  const { defaultValues, onSubmit } = props;
  const form = useCustomForm<IAppointmentConfirmationFormValues>({
    schema: AppointmentConfirmationFormSchema,
    defaultValues: defaultValues || appointmentConfirmationFormDefaultValues,
    mode: "onChange",
  });
  const { handleSubmit, watch } = form;
  const showInsuranceSection = watch("showInsuranceSection");
  const { t } = useTranslation("provider");
  const _onSubmit = (values: IAppointmentConfirmationFormValues) => {
    let _values = values;
    if (values.showInsuranceSection === false) {
      _values = pick(values, ["name", "phone", "nationality"]);
    }
    onSubmit?.(_values);
  };
  useImperativeHandle(ref, () => {
    return {
      submit: () => handleSubmit(_onSubmit)(),
    };
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection={"column"} alignItems='start' justifyContent='center' width='100%' gap={2} padding={"10px"}>
          <FormTextField name='name' label={t("Name")} />
          <FormTextField name='nationality' label={t("Nationality")} />
          <FormPhoneInput name='phone' label={t("Phone Number")} />
          <FormCheckbox name='showInsuranceSection' label={t("Have Insurance?")} />
          {showInsuranceSection && (
            <>
              <FormTextField name='nationalId' label={t("National ID")} />
              <FormTextField name='insuranceCompanyName' label={t("Insurance Name")} />
              <FormTextField name='insuranceId' label={t("Member Card ID")} />
            </>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export const AppointmentConfirmationForm = forwardRef(AppointmentConfirmationFormForwardRef);
