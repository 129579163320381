import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const Cities: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='64.738' height='67.351' viewBox='0 0 64.738 67.351' {...props}>
      <path
        id='icons8-place-marker'
        d='M33.369.063a18.822,18.822,0,0,0-18.8,18.8c0,8.587,4.5,18.434,8.935,26.258a132.531,132.531,0,0,0,8.851,13.613,1.249,1.249,0,0,0,2.023,0,136.3,136.3,0,0,0,8.851-13.74c4.425-7.871,8.935-17.723,8.935-26.131A18.822,18.822,0,0,0,33.369.063Zm0,2.529A16.238,16.238,0,0,1,49.637,18.86c0,7.492-4.257,17.2-8.6,24.909a121.09,121.09,0,0,1-7.671,12.054A115.55,115.55,0,0,1,25.7,43.9c-4.336-7.66-8.6-17.343-8.6-25.035A16.238,16.238,0,0,1,33.369,2.591Zm0,8.219a9.4,9.4,0,1,0,9.4,9.4A9.421,9.421,0,0,0,33.369,10.81Zm0,2.613a6.786,6.786,0,1,1-6.786,6.786A6.761,6.761,0,0,1,33.369,13.423ZM20.093,46.045A47.044,47.044,0,0,0,6.774,49.712a14.049,14.049,0,0,0-4.088,2.782A5.575,5.575,0,0,0,1,56.286c0,1.928,1.233,3.577,2.992,4.889a23.888,23.888,0,0,0,7.039,3.287,76.932,76.932,0,0,0,22.338,2.95,76.932,76.932,0,0,0,22.338-2.95,23.888,23.888,0,0,0,7.039-3.287c1.76-1.312,2.992-2.961,2.992-4.889a5.586,5.586,0,0,0-1.686-3.793,14.049,14.049,0,0,0-4.088-2.782,47.044,47.044,0,0,0-13.318-3.667,1.365,1.365,0,0,0-.421,2.7,45.306,45.306,0,0,1,12.56,3.414,11.958,11.958,0,0,1,3.33,2.234,2.726,2.726,0,0,1,.927,1.9c0,.774-.516,1.665-1.9,2.7a21.709,21.709,0,0,1-6.238,2.908,74.929,74.929,0,0,1-21.537,2.824,74.929,74.929,0,0,1-21.537-2.824,21.709,21.709,0,0,1-6.238-2.908c-1.38-1.033-1.9-1.923-1.9-2.7a2.726,2.726,0,0,1,.927-1.9,11.958,11.958,0,0,1,3.33-2.234,45.306,45.306,0,0,1,12.56-3.414,1.365,1.365,0,1,0-.421-2.7Z'
        transform='translate(-1 -0.063)'
        fill={props.fill || "#22d5cb"}
      />
    </SvgIcon>
  );
};
