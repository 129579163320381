import { useAddToast } from "@toolkit/ui";
import { useMarkAllNotificationsAsSeenMutation } from "../../gql";
import { formatMessageErrors } from "@toolkit/apollo";
import { useAuth } from "react-oidc-context";
export const useMakeAllNotificationsRead = () => {
  const auth = useAuth();
  const { failed } = useAddToast();
  const [markAllNotificationsAsSeenMutation, { loading: isSubmitting }] = useMarkAllNotificationsAsSeenMutation({
    onCompleted: response => {
      if (response.markAllNotificationsAsSeen?.errors?.length) {
        failed(formatMessageErrors(response.markAllNotificationsAsSeen?.errors));
      }
    },
    context: {
      headers: {
        Authorization: `Bearer ${auth?.user?.access_token}`,
      },
    },
  });
  const handleMarkAllNotificationsAsSeen = () => {
    markAllNotificationsAsSeenMutation();
  };
  return {
    isSubmitting,
    handleMarkAllNotificationsAsSeen,
  };
};
