import { useTranslation } from "@/i18n/i18n.config";
import { EyeIconVisitHistory, EyeOffIcon, IconButton, PasswordIcon2, TextFieldProps } from "@toolkit/ui";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RoundedTextField } from "../RoundedTextField";

export const PasswordField: FC<TextFieldProps & { isRequired?: boolean; errorMessage?: string; name: string }> = props => {
  const { isRequired, errorMessage, name, ...restProps } = props;
  const [showPass, setShowPass] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleClick = () => {
    setShowPass(show => !show);
  };
  const { register } = useFormContext();
  return (
    <RoundedTextField
      label={t("Password")}
      placeholder={t("Password")}
      type={showPass ? "text" : "password"}
      {...restProps}
      error={!!errorMessage}
      helperText={errorMessage}
      {...register(name, { required: isRequired })}
      InputProps={{
        startAdornment: <PasswordIcon2 sx={{ fontSize: "15px", marginInline: ["8px", "4px"] }} />,
        endAdornment: (
          <IconButton sx={{}} onClick={handleClick}>
            {!showPass ? <EyeIconVisitHistory fontSize='small' /> : <EyeOffIcon fontSize='small' />}
          </IconButton>
        ),
      }}
    />
  );
};
