import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const AppointmentsIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='14.999' height='16.041' viewBox='0 0 14.999 16.041' {...props}>
      <path
        id='icons8-calendar'
        d='M9.53,3.478a.625.625,0,0,0-.615.634v.416H8.707A2.709,2.709,0,0,0,6,7.235v9.578a2.709,2.709,0,0,0,2.707,2.707h6.817a.625.625,0,1,0,0-1.249H8.707a1.416,1.416,0,0,1-1.457-1.457V7.235A1.416,1.416,0,0,1,8.707,5.778h.208v.208a.625.625,0,1,0,1.249,0V4.112a.625.625,0,0,0-.634-.634Zm7.912,0a.625.625,0,0,0-.615.634v.416H11.8a.625.625,0,1,0,0,1.249h5.024v.208a.625.625,0,1,0,1.249,0V5.778h.208a1.416,1.416,0,0,1,1.457,1.457V7.86H9.074a.625.625,0,1,0,0,1.249H19.742v7.7a1.416,1.416,0,0,1-1.457,1.457H17.6a.625.625,0,1,0,0,1.249h.686a2.709,2.709,0,0,0,2.707-2.707V8.587a.625.625,0,0,0,0-.2V7.235a2.709,2.709,0,0,0-2.707-2.707h-.208V4.112a.625.625,0,0,0-.634-.634Zm-2.489,8.546A1.467,1.467,0,0,0,13.5,13.481v1.666A1.467,1.467,0,0,0,14.953,16.6h1.666a1.467,1.467,0,0,0,1.457-1.457V13.481a1.467,1.467,0,0,0-1.457-1.457Zm0,1.249h1.666a.2.2,0,0,1,.208.208v1.666a.2.2,0,0,1-.208.208H14.953a.2.2,0,0,1-.208-.208V13.481A.2.2,0,0,1,14.953,13.273Z'
        transform='translate(-6 -3.478)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
