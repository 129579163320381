import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { AuthContext } from "./context";
import { oidcUserManager } from "@/configs/oidc";
import { AppAuth } from "./constants";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const setAppToken = (token: string) => {
    localStorage.setItem(AppAuth.TOKEN, token);
    setIsAuthenticated(true);
  };

  const getAppToken = () => {
    return localStorage.getItem(AppAuth.TOKEN);
  };

  useEffect(() => {
    const token = getAppToken();
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const askToLogin = () => {
    oidcUserManager.signinRedirect();
  };

  const handleLogout = async () => {
    await oidcUserManager.clearStaleState();
    await oidcUserManager.signoutRedirect({
      id_token_hint: (await oidcUserManager.getUser())?.id_token,
      state: (await oidcUserManager.getUser())?.state,
      post_logout_redirect_uri: window.location.origin,
    });
    await oidcUserManager.removeUser();
    await oidcUserManager.revokeTokens();
    // localStorage.removeItem(AppAuth.TOKEN);
    // setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        askToLogin,
        setAppToken,
        getAppToken,
        doLogout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
