import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const AtHomeIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.268' height='16.424' viewBox='0 0 15.268 16.424' {...props}>
      <g id='icons8-home_1_' data-name='icons8-home (1)' transform='translate(-6 -4.274)'>
        <path
          id='Path_13029'
          data-name='Path 13029'
          d='M15.966,25.32H7.484A1.486,1.486,0,0,1,6,23.835V16.159a3.242,3.242,0,0,1,1.215-2.491l1.524-1.228a.636.636,0,0,1,.8.991l-1.527,1.23-.006,0a1.97,1.97,0,0,0-.732,1.493v7.676a.215.215,0,0,0,.212.212h8.482a.636.636,0,1,1,0,1.272Z'
          transform='translate(0 -4.622)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13030'
          data-name='Path 13030'
          d='M26.291,20.7H24.807a.636.636,0,0,1,0-1.272h1.484a.215.215,0,0,0,.212-.212V11.537a1.827,1.827,0,0,0-.715-1.48l-.017-.013L20.141,5.615,18.328,7.034a.636.636,0,1,1-.784-1l1.928-1.509A1.021,1.021,0,0,1,20.778,4.5l.012.009,5.76,4.532a3.1,3.1,0,0,1,1.226,2.5v7.676A1.486,1.486,0,0,1,26.291,20.7Z'
          transform='translate(-6.508)'
          fill={palette.primary.main}
        />
        <path
          id='Path_13031'
          data-name='Path 13031'
          d='M22.259,32.634A.636.636,0,0,1,21.623,32V26.272h-3.35v3.266a.636.636,0,0,1-1.272,0V26.06A1.037,1.037,0,0,1,18.06,25h3.774a1.037,1.037,0,0,1,1.06,1.06V32A.636.636,0,0,1,22.259,32.634Z'
          transform='translate(-6.335 -11.936)'
          fill={palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};
