import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";

export const PaymentCallbackRefundedPage: FC = () => {
  const { t } = useTranslation("provider");

  useEffect(() => {
    // navigate to the next page somwhow!
    location.href = localStorage.getItem("payment-call-back-source-page")!;
  }, []);
  return (
    <Box p={4}>
      <Typography variant='h4'>{t("Payment was Refunded!")}</Typography>
      <Typography variant='body1'>{t("Your payment has been processed Refunded successfully.")}</Typography>
    </Box>
  );
};
