import { Box, Typography } from "@toolkit/ui";
import { MultiLineDetailCard } from "@/domains/appointment/components/DetailCard/MultiLineDetailCard";
import { DetailCard } from "@/domains/appointment/components/DetailCard/DetailCard";
import Dot from "@/components/Dot";
import React from "react";

export const PlaygroundCardsPage: React.FC = () => {
  return (
    <Box sx={{ overflowY: "auto", height: "100vh" }}>
      <DetailCard title='Visit Type' content='Virtual' />
      <DetailCard title='Appointment Date' content='27 May 2023' />
      <DetailCard
        title='My Contact Info'
        variant='multipleInfo'
        items={[
          { label: "Email", value: "ebg.moath.omar@gmail.omc" },
          { label: "Mobile", value: "1234234" },
        ]}
      />
      <MultiLineDetailCard
        title='My Contact Info'
        items={[
          { label: "Email", value: "ebg.moath.omar@gmail.omc" },
          { label: "Mobile", value: "1234234" },
        ]}
      />
      <DetailCard
        title='Payment Information'
        content={
          <Typography color='success.main'>
            <Dot size={12} color='secondary' marginInlineEnd={1} />
            Covered by the Insurance
          </Typography>
        }
      />
    </Box>
  );
};
