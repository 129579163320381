import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const AddIconInCircle: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='31.001' height='31.001' viewBox='0 0 31.001 31.001' {...props}>
      <g id='Group_10821' data-name='Group 10821' transform='translate(-194 -427.999)'>
        <g id='icons8-add' transform='translate(194 427.999)'>
          <path
            id='Path_12899'
            data-name='Path 12899'
            d='M19.3,37.43a15.509,15.509,0,0,1-15.5-15.5,16.4,16.4,0,0,1,1.237-6.114,1.151,1.151,0,1,1,2.131.872A14.2,14.2,0,0,0,6.1,21.929a13.057,13.057,0,0,0,1.039,5.127,13.307,13.307,0,0,0,7.032,7.032A13.058,13.058,0,0,0,19.3,35.128a13.375,13.375,0,0,0,8.057-2.762,1.151,1.151,0,0,1,1.381,1.842A15.659,15.659,0,0,1,19.3,37.43Z'
            transform='translate(-3.8 -6.429)'
            fill={props.fill ? props.fill : palette.primary.main}
          />
          <path
            id='Path_12900'
            data-name='Path 12900'
            d='M34.311,25.516a1.152,1.152,0,0,1-1.075-1.561,13.2,13.2,0,0,0-.194-9.782,13.307,13.307,0,0,0-7.032-7.032A13.057,13.057,0,0,0,20.882,6.1a13.4,13.4,0,0,0-8.406,3.042,1.151,1.151,0,1,1-1.452-1.787A15.71,15.71,0,0,1,20.882,3.8a15.509,15.509,0,0,1,15.5,15.5,15.318,15.318,0,0,1-1,5.474A1.152,1.152,0,0,1,34.311,25.516Z'
            transform='translate(-5.382 -3.8)'
            fill={props.fill ? props.fill : palette.primary.main}
          />
          <path
            id='Line_1576'
            data-name='Line 1576'
            d='M-.349,16.149A1.151,1.151,0,0,1-1.5,15V-.349A1.151,1.151,0,0,1-.349-1.5,1.151,1.151,0,0,1,.8-.349V15A1.151,1.151,0,0,1-.349,16.149Z'
            transform='translate(15.849 8.176)'
            fill={props.fill ? props.fill : palette.primary.main}
          />
          <path
            id='Line_1577'
            data-name='Line 1577'
            d='M15,.8H-.349A1.151,1.151,0,0,1-1.5-.349,1.151,1.151,0,0,1-.349-1.5H15A1.151,1.151,0,0,1,16.149-.349,1.151,1.151,0,0,1,15,.8Z'
            transform='translate(8.176 15.849)'
            fill={props.fill ? props.fill : palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
