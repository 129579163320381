import React from "react";
import { Box, Typography, Button, CircularProgress } from "@toolkit/ui";
import { IFormattedTimeSlot } from "../../types/types";
import { useSlotsSectionStyles } from "./SlotsSection.styles";
import { useTranslation } from "@/i18n/i18n.config";

interface ISlotsSectionProps {
  label: string;
  timeSlots: IFormattedTimeSlot[];
  selectedTimeSlots: IFormattedTimeSlot[];
  onSelect: (timeSlot: IFormattedTimeSlot) => void;
  icon: React.ReactNode;
  isLoading?: boolean;
}

const SlotsSection: React.FC<ISlotsSectionProps> = ({ label, timeSlots, selectedTimeSlots, onSelect, icon, isLoading }) => {
  const { t } = useTranslation("consumer");

  const { classes } = useSlotsSectionStyles();

  const isSelected = (timeSlot: IFormattedTimeSlot) =>
    selectedTimeSlots.some(selected => selected.startTime.isSame(timeSlot.startTime) && selected.endTime.isSame(timeSlot.endTime));

  return (
    <Box>
      <Box display='flex' alignItems='center' alignContent='center'>
        <Box className={classes.iconWrapper}>{icon}</Box>
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      {isLoading && <CircularProgress />}
      {timeSlots.length && !isLoading ? (
        <Box className={classes.timeSlotsWrapper}>
          {timeSlots.map(slot => (
            <Button
              key={slot.startTime.format("dddd-HH:mm")}
              variant='text'
              onClick={() => onSelect(slot)}
              className={`${classes.timeSlotButton} ${isSelected(slot) ? "selected" : ""}`}
            >
              <Typography>{slot.startTime.format("hh:mm A")}</Typography>
            </Button>
          ))}
        </Box>
      ) : (
        <Box className={classes.emptyLabel}>{t("No Time Slots Available")}</Box>
      )}
    </Box>
  );
};

export default SlotsSection;
