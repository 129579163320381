/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SiteSettingsGetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SiteSettingsGetQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', defaultCurrency: string, timeZone?: number | null } };


export const SiteSettingsGetDocument = gql`
    query SiteSettingsGet {
  siteSettings {
    defaultCurrency
    timeZone
  }
}
    `;

/**
 * __useSiteSettingsGetQuery__
 *
 * To run a query within a React component, call `useSiteSettingsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteSettingsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteSettingsGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteSettingsGetQuery(baseOptions?: Apollo.QueryHookOptions<SiteSettingsGetQuery, SiteSettingsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteSettingsGetQuery, SiteSettingsGetQueryVariables>(SiteSettingsGetDocument, options);
      }
export function useSiteSettingsGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteSettingsGetQuery, SiteSettingsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteSettingsGetQuery, SiteSettingsGetQueryVariables>(SiteSettingsGetDocument, options);
        }
export type SiteSettingsGetQueryHookResult = ReturnType<typeof useSiteSettingsGetQuery>;
export type SiteSettingsGetLazyQueryHookResult = ReturnType<typeof useSiteSettingsGetLazyQuery>;
export type SiteSettingsGetQueryResult = Apollo.QueryResult<SiteSettingsGetQuery, SiteSettingsGetQueryVariables>;