import { FC, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Box, CustomInfiniteScroll, Typography, CircularProgress } from "@toolkit/ui";
import { useGetNotificationsList } from "./useGetNotificationsList";
import { useStyles } from "./BaseNotificationsListStyle";
import { NotificationType, UserNotificationFilterInput } from "@health/queries/types";
import { groupBy } from "lodash";
import moment from "moment";
import { SeenNotificationProvider } from "../../SeenNotificationContext";
import { NotificationCardWrapper } from "../NotificationCardWrapper/NotificationCardWrapper";
import { MedicalMessageNotificationCard } from "../MedicalMessageNotificationCard/MedicalMessageNotificationCard";
import { EmptyNotificationMessage } from "../EmptyNotificationMessage/EmptyNotificationMessage";

type BaseNotificationsListProps = {
  isSeparatorHidden?: boolean;
  filter?: UserNotificationFilterInput;
  isMedicalMessageNotifications?: boolean;
};

export const BaseNotificationsList: FC<BaseNotificationsListProps> = ({
  isSeparatorHidden = false,
  filter,
  isMedicalMessageNotifications = false,
}) => {
  const { notifications = [], fetchMore, loading, pageInfo, refetch } = useGetNotificationsList(filter);
  const { classes, cx } = useStyles({ isMedicalMessageNotifications });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const updateHeight = (entries: ResizeObserverEntry[]) => setContainerHeight(entries[0].contentRect.height);

    const observer = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const groupedNotifications = useMemo(() => {
    return groupBy(notifications, notification => moment(notification?.creationDate).format("YYYY-MM-DD"));
  }, [notifications]);

  const getDisplayDate = useCallback((dateString: string) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const notificationDate = moment(dateString);

    if (notificationDate.isSame(today, "day")) {
      return "Today";
    } else if (notificationDate.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return notificationDate.format("dddd, D MMMM YYYY");
    }
  }, []);

  return (
    <Box ref={containerRef} className={classes.container}>
      <Box className={cx(classes.content, !notifications.length && !loading && classes.contentForEmptyPage)}>
        {loading && notifications.length === 0 ? (
          <CircularProgress className={classes.loader} />
        ) : notifications.length === 0 ? (
          <Box className={classes.emptyMessageContainer}>
            <EmptyNotificationMessage />
          </Box>
        ) : (
          <CustomInfiniteScroll
            height={containerHeight ? containerHeight - 8 : undefined}
            dataLength={notifications.length}
            onFetchMore={fetchMore}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<CircularProgress className={classes.loader} />}
            onRefresh={refetch}
          >
            {Object.keys(groupedNotifications).map(date => (
              <Box key={date}>
                <Typography className={classes.date}>{getDisplayDate(date)}</Typography>
                {groupedNotifications[date].map((notification, idx) => (
                  <SeenNotificationProvider
                    key={notification?.id}
                    id={notification?.id || ""}
                    type={notification?.type as NotificationType}
                    isSeen={notification?.seen || false}
                  >
                    <Box
                      className={
                        idx === groupedNotifications[date].length - 1 || isSeparatorHidden
                          ? classes.notificationWithoutSeparator
                          : classes.notificationWithSeparator
                      }
                    >
                      {isMedicalMessageNotifications ? (
                        <MedicalMessageNotificationCard item={notification!} />
                      ) : (
                        <NotificationCardWrapper item={notification!} />
                      )}
                    </Box>
                  </SeenNotificationProvider>
                ))}
              </Box>
            ))}
          </CustomInfiniteScroll>
        )}
      </Box>
    </Box>
  );
};
