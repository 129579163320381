import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const Pharmacies: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='67.351' height='64.545' viewBox='0 0 67.351 64.545' {...props}>
      <path
        id='icons8-pharmacy-shop'
        d='M34.626,1a1.4,1.4,0,0,0-.817.3L5.8,23.22,1.537,26.555a1.4,1.4,0,0,0,1.732,2.209l1.94-1.518v36.9a1.4,1.4,0,0,0,1.4,1.4H62.738a1.4,1.4,0,0,0,1.4-1.4v-36.9l1.94,1.518a1.4,1.4,0,0,0,1.732-2.209l-4.187-3.275L35.541,1.3A1.4,1.4,0,0,0,34.626,1Zm.049,3.184,26.66,20.863v37.69H8.016V25.048Zm6.862,16.459a11.5,11.5,0,0,0-7.967,3.291q-.044.042-.085.088c.065-.075-.29.3-.723.743s-1.029,1.04-1.729,1.746c-1.4,1.411-3.227,3.243-5.056,5.078s-3.659,3.672-5.073,5.095c-.707.711-1.31,1.32-1.757,1.773-.422.428-.633.641-.808.836a11.277,11.277,0,0,0,8,19.236A11.42,11.42,0,0,0,34.3,55.238l.019-.019L49.6,39.891a11.255,11.255,0,0,0-.011-15.947l-.008-.008A11.564,11.564,0,0,0,41.538,20.644Zm0,2.806a8.7,8.7,0,0,1,6.089,2.5,8.4,8.4,0,0,1-.014,11.96h0c-.263.264-3.2,3.215-5.922,5.944L29.7,31.825c1.167-1.172,2.4-2.407,3.324-3.335.7-.707,1.3-1.308,1.737-1.754.395-.4.573-.58.754-.781A8.678,8.678,0,0,1,41.538,23.45ZM27.723,33.815,39.707,45.84l-7.353,7.377a8.49,8.49,0,0,1-6.018,2.5,8.4,8.4,0,0,1-6-2.486l-.008-.008a8.4,8.4,0,0,1,.008-11.965q.03-.029.058-.06c-.028.031.315-.328.756-.776s1.04-1.055,1.746-1.765C24.237,37.311,25.982,35.562,27.723,33.815Zm-1.466,3.667a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,26.257,37.482Zm-4.209,4.209a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,22.047,41.691Zm8.419,0a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,30.466,41.691ZM26.257,45.9a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,26.257,45.9Zm8.419,0a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,34.675,45.9ZM22.047,50.11a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,22.047,50.11Zm8.419,0a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,30.466,50.11Z'
        transform='translate(-1 -0.999)'
        fill='#22d5cb'
      />
    </SvgIcon>
  );
};
