import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const CareCenters: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='66.199' height='63.441' viewBox='0 0 66.199 63.441' {...props}>
      <path
        id='icons8-out-patient-department'
        d='M34.051,1a1.379,1.379,0,0,0-.8.294L5.719,22.84,1.528,26.118a1.379,1.379,0,1,0,1.7,2.171L5.138,26.8V63.062a1.379,1.379,0,0,0,1.379,1.379H61.682a1.379,1.379,0,0,0,1.379-1.379V26.8l1.907,1.492a1.379,1.379,0,0,0,1.7-2.171L62.555,22.9,34.951,1.294A1.379,1.379,0,0,0,34.051,1ZM34.1,4.13,60.3,24.637V61.682H7.9V24.637ZM25.825,17.55a2.758,2.758,0,0,0-2.384,1.379H23.2a4.261,4.261,0,0,0-4.269,4.21v7.02a10.807,10.807,0,0,0,9.654,10.562v6.955a9.656,9.656,0,1,0,19.308,0q0-.032,0-.065a5.292,5.292,0,0,0,2.758-1.476,5.807,5.807,0,0,0,0-7.521,5.745,5.745,0,0,0-8.269,0,5.807,5.807,0,0,0,0,7.521,5.292,5.292,0,0,0,2.758,1.476q0,.032,0,.065a6.9,6.9,0,1,1-13.791,0V40.721A10.807,10.807,0,0,0,41,30.159v-7.02a4.261,4.261,0,0,0-4.269-4.21h-.237a2.763,2.763,0,1,0-.005,2.758h.242a1.462,1.462,0,0,1,1.511,1.452v7.02a8.147,8.147,0,0,1-8.2,8.062,1.381,1.381,0,0,0-.154,0,8.147,8.147,0,0,1-8.2-8.062v-7.02A1.461,1.461,0,0,1,23.2,21.687h.237a2.758,2.758,0,1,0,2.389-4.137ZM46.512,39.616a2.539,2.539,0,0,1,2.071.83,3.051,3.051,0,0,1,0,3.857,3,3,0,0,1-4.143,0,3.051,3.051,0,0,1,0-3.857A2.539,2.539,0,0,1,46.512,39.616Z'
        transform='translate(-1 -0.999)'
        fill='#22d5cb'
      />
    </SvgIcon>
  );
};
