import { FC } from "react";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { useAppointmentItemStyles } from "./useAppointmentItemStyle";
import { getNotificationTextStyle } from "@/domains/notifications/utils";
import { AppointmentItemProps } from "@/domains/notifications/types";
import { AppointmentDateTime } from "./AppointmentDateTime";

export const AppointmentPending: FC<AppointmentItemProps> = ({ id, type, doctor, appointmentDate, requestedTimeSlots, isSeen }) => {
  const { classes, theme } = useAppointmentItemStyles();
  const { t } = useTranslation("consumer");
  const { color } = getNotificationTextStyle(isSeen!, theme);

  return (
    <Box className={classes.view}>
      <Typography color={color}>
        <Trans
          t={t}
          i18nKey={
            type !== "Online"
              ? "Please confirm your <a>{{type}}</a> appointment ({{id}}) with <a>{{doctor}}</a> by selecting a time slot and viewing your payment information"
              : "Please confirm your <a>{{type}}</a> appointment with <a>{{doctor}}</a> by selecting a time slot and viewing your payment information"
          }
          values={{ type, id, doctor }}
          components={{ a: <Typography display='inline' fontWeight='bold' /> }}
        />
      </Typography>
      <AppointmentDateTime {...{ appointmentDate, requestedTimeSlots, isSeen }} />
    </Box>
  );
};
