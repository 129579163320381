import { Box, Skeleton } from "@toolkit/ui";
import { FC } from "react";
import { useReelCardStyle } from "../ReelCard/ReelCardStyle";

export const ReelCardSkeleton: FC = () => {
  const { classes, theme } = useReelCardStyle({ src: "" });

  return (
    <Box className={classes.root} bgcolor={theme.palette.gray[400]}>
      <Box className={classes.cardBackground}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Box>

      <Box alignSelf='end' className={classes.title}>
        <Skeleton variant='text' width='80%' height='24px' />
      </Box>
    </Box>
  );
};
