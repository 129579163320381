/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentConfirmationDataQueryVariables = Types.Exact<{
  doctorId: Types.Scalars['ID'];
  visitCareType: Types.VisitCareType;
}>;


export type AppointmentConfirmationDataQuery = { __typename?: 'Query', patientShare?: { __typename?: 'PatientShare', patientShare?: number | null, coverage?: Types.Coverage | null } | null, me?: { __typename?: 'User', email?: string | null, mobile?: string | null } | null };


export const AppointmentConfirmationDataDocument = gql`
    query AppointmentConfirmationData($doctorId: ID!, $visitCareType: VisitCareType!) {
  patientShare(doctorId: $doctorId, visitCareType: $visitCareType) {
    patientShare
    coverage
  }
  me {
    email
    mobile
  }
}
    `;

/**
 * __useAppointmentConfirmationDataQuery__
 *
 * To run a query within a React component, call `useAppointmentConfirmationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentConfirmationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentConfirmationDataQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *      visitCareType: // value for 'visitCareType'
 *   },
 * });
 */
export function useAppointmentConfirmationDataQuery(baseOptions: Apollo.QueryHookOptions<AppointmentConfirmationDataQuery, AppointmentConfirmationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentConfirmationDataQuery, AppointmentConfirmationDataQueryVariables>(AppointmentConfirmationDataDocument, options);
      }
export function useAppointmentConfirmationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentConfirmationDataQuery, AppointmentConfirmationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentConfirmationDataQuery, AppointmentConfirmationDataQueryVariables>(AppointmentConfirmationDataDocument, options);
        }
export type AppointmentConfirmationDataQueryHookResult = ReturnType<typeof useAppointmentConfirmationDataQuery>;
export type AppointmentConfirmationDataLazyQueryHookResult = ReturnType<typeof useAppointmentConfirmationDataLazyQuery>;
export type AppointmentConfirmationDataQueryResult = Apollo.QueryResult<AppointmentConfirmationDataQuery, AppointmentConfirmationDataQueryVariables>;