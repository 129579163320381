import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const ByPatientIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.179' height='18.973' viewBox='0 0 15.179 18.973' {...props}>
      <path
        id='icons8-user_4_'
        data-name='icons8-user (4)'
        d='M15.589,4a4.744,4.744,0,0,0-3.535,7.906.712.712,0,1,0,1.06-.95,3.32,3.32,0,0,1,2.475-5.533c.059,0,.116,0,.173,0a.712.712,0,1,0,.077-1.421C15.757,4,15.673,4,15.589,4Zm2.462.85a.712.712,0,0,0-.416,1.278,3.321,3.321,0,0,1-2.751,5.861.712.712,0,1,0-.3,1.391,4.744,4.744,0,0,0,3.929-8.372A.712.712,0,0,0,18.052,4.85ZM10.7,15.384a2.714,2.714,0,0,0-2.7,2.7,4.3,4.3,0,0,0,2.234,3.507,9.983,9.983,0,0,0,5.356,1.379h.178a.712.712,0,1,0-.012-1.423h-.166A8.65,8.65,0,0,1,11,20.394a2.89,2.89,0,0,1-1.576-2.307,1.269,1.269,0,0,1,1.28-1.28h.623a.712.712,0,1,0,0-1.423Zm2.576,0a.712.712,0,1,0,0,1.423h7.2a1.269,1.269,0,0,1,1.28,1.28,2.532,2.532,0,0,1-1,1.88,6.681,6.681,0,0,1-3.032,1.376.712.712,0,1,0,.285,1.394,8.023,8.023,0,0,0,3.683-1.7,3.973,3.973,0,0,0,1.49-2.953,2.714,2.714,0,0,0-2.7-2.7Z'
        transform='translate(-8 -4)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
