import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SwitchIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19.35' height='15.48' viewBox='0 0 19.35 15.48' {...props}>
      <path
        id='icons8-update_3_'
        data-name='icons8-update (3)'
        d='M9.675,3A7.718,7.718,0,0,0,4.648,4.854a.645.645,0,1,0,.838.98,6.451,6.451,0,0,1,10.607,4.26h-1.9l2.58,3.87,2.58-3.87H17.382A7.748,7.748,0,0,0,9.675,3ZM2.58,7.515,0,11.385H1.968A7.735,7.735,0,0,0,14.7,16.625a.645.645,0,1,0-.838-.98,6.451,6.451,0,0,1-10.607-4.26h1.9Z'
        transform='translate(0 -3)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
