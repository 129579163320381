import { FC } from "react";
import { HealthServicesIconProps, HealthServicesIconWrapper } from "./HealthServicesIconWrapper";

export const HealthServiceConnectToACareTeamIcon: FC<HealthServicesIconProps> = props => {
  return (
    <HealthServicesIconWrapper {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='Group_10149' data-name='Group 10149' transform='translate(16 18)'>
        <path
          id='Path_12628'
          data-name='Path 12628'
          d='M95.423,49.98A6.324,6.324,0,0,0,95.889,49a12.614,12.614,0,0,0,.9-5.683,3.542,3.542,0,0,0-1.256-2.548,3.472,3.472,0,0,0-2.548-.553c-.634.1-1.169.141-1.6.173a5.393,5.393,0,0,0-1.031.132.526.526,0,0,0-.43.615.5.5,0,0,0,.505.382c.255-.01.512-.025.772-.04a13.918,13.918,0,0,1,2.485.008c1.054.133,1.711,1.088,1.85,2.689a10.272,10.272,0,0,1-1.179,4.858l-.091.214V46.495a.653.653,0,0,0-1.306,0v5.672h1.564a8.433,8.433,0,0,1,.9-2.187'
          transform='translate(-63.327 -28.296)'
          fill='#fff'
        />
        <path
          id='Path_12629'
          data-name='Path 12629'
          d='M3.945,51.565V46.242a.687.687,0,0,0-1.375,0v2.767C1.095,45.83.686,43.6,1.356,42.369a2.935,2.935,0,0,1,2.425-1.225,10.48,10.48,0,0,1,2.031.033.641.641,0,0,0,.716-.672.647.647,0,0,0-.58-.606c-.507-.048-1.3-.109-2.166-.122A3.6,3.6,0,0,0,.258,42.4c-.556,1.768-.164,3,.7,5.394A18.9,18.9,0,0,0,2.2,50.664a2.17,2.17,0,0,1,.34,1.223h1.4v-.322'
          transform='translate(0 -28.016)'
          fill='#fff'
        />
        <path
          id='Path_12630'
          data-name='Path 12630'
          d='M10.86,15.545a4.543,4.543,0,0,0,3.2,1.472h.084a4.485,4.485,0,0,0,.024-8.97,4.446,4.446,0,0,0-3.3,1.493A3.9,3.9,0,0,0,9.71,12.517a3.951,3.951,0,0,0,1.15,3.028m1.109-5.336a2.8,2.8,0,0,1,1.946-.746c.051,0,.1,0,.156,0h.02a2.506,2.506,0,0,1,1.993.643,3.4,3.4,0,0,1,1.184,2.421,3.2,3.2,0,0,1-1,2.217,2.912,2.912,0,0,1-2.1.894,3.069,3.069,0,0,1-3.147-3.262,3.073,3.073,0,0,1,.951-2.171'
          transform='translate(-6.829 -5.666)'
          fill='#fff'
        />
        <path
          id='Path_12631'
          data-name='Path 12631'
          d='M78.034,17.031h0c.05,0,.1,0,.149,0a4.376,4.376,0,0,0,3.115-1.4,4.01,4.01,0,0,0,1.2-3.043,3.951,3.951,0,0,0-1.126-2.993A4.543,4.543,0,0,0,78.2,8.069h-.041a4.481,4.481,0,1,0-.12,8.961M78.1,9.485h.073a2.983,2.983,0,0,1,2.188.839,3.086,3.086,0,0,1,.85,2.267,3.1,3.1,0,0,1-5.327,2.12,2.994,2.994,0,0,1-.937-2.123,3.062,3.062,0,0,1,3.154-3.1'
          transform='translate(-51.832 -5.683)'
          fill='#fff'
        />
        <path
          id='Path_12632'
          data-name='Path 12632'
          d='M33.526,43.466l0-1.055a.7.7,0,0,0-1.4,0c0,.424,0,.855,0,1.272,0,.073,0,.146,0,.219a11.92,11.92,0,0,1-1.046-5.44,2.166,2.166,0,0,1,.815-1.485,1.484,1.484,0,0,1,1.189-.36c.281.051.57.11.9.187v2.078a1.257,1.257,0,0,0,.92,2.338,1.257,1.257,0,0,0,.082-2.338V37.044a10.269,10.269,0,0,0,5.455.087v1.53a1.969,1.969,0,0,0-1.462,1.888v.365a.488.488,0,0,0,.977,0v-.365a.973.973,0,0,1,1.947,0v.365a.488.488,0,1,0,.977,0v-.366a1.968,1.968,0,0,0-1.462-1.888V36.883c.272-.076.538-.155.812-.24a1.092,1.092,0,0,1,1.009.154,1.892,1.892,0,0,1,.674,1.519v.044a13.657,13.657,0,0,1-.177,2.757c-.183.893-.459,1.784-.725,2.647-.061.2-.123.4-.184.6-.041-.517-.074-1.343-.074-1.991a.649.649,0,1,0-1.3,0v6.314h1.273a13.111,13.111,0,0,1,.874-3.5l.18-.436a14.233,14.233,0,0,0,1.538-6.244,3.5,3.5,0,0,0-1.279-2.668,2.519,2.519,0,0,0-2.2-.364c-4.177,1.1-5.775.521-6.943.1a4.065,4.065,0,0,0-1.526-.341A3.647,3.647,0,0,0,29.658,39,14.47,14.47,0,0,0,31,45.068c.07.165.14.325.208.482a9.858,9.858,0,0,1,.944,3.133h1.379c0-1.631,0-3.6-.007-5.218m.965-3.844a.408.408,0,1,1-.408.408.409.409,0,0,1,.408-.408'
          transform='translate(-20.887 -24.813)'
          fill='#fff'
        />
        <path
          id='Path_12633'
          data-name='Path 12633'
          d='M46.376,9.007h.008a4.431,4.431,0,0,0,3.164-1.314,4.478,4.478,0,0,0,1.3-3.155,4.387,4.387,0,0,0-1.287-3.21A4.363,4.363,0,0,0,46.338,0a4.418,4.418,0,0,0-4.413,4.52,4.433,4.433,0,0,0,4.451,4.485M44.125,2.313a3.051,3.051,0,0,1,2.236-.957h.059a3.091,3.091,0,0,1,3.058,3.3,3.115,3.115,0,0,1-3.1,2.993h-.025a3.147,3.147,0,0,1-2.3-1.047,2.753,2.753,0,0,1-.746-2.076v-.02a2.91,2.91,0,0,1,.815-2.195'
          transform='translate(-29.529 -0.001)'
          fill='#fff'
        />
      </g>
    </HealthServicesIconWrapper>
  );
};
