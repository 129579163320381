import {
  ApprovedNotificationIcon,
  CallNotificationIcon,
  CanceledByNotificationIcon,
  CancelledNotificationIcon,
  ChatNotificationIcon,
  ComingSoonNotificationIcon,
  NotificationBellIcon,
  PendingApprovalNotificationIcon,
  RejectedNotificationIcon,
  RescheduledNotificationIcon,
} from "@/components";
import { Appointment, AppointmentStatus, AppointmentType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { format, Theme } from "@toolkit/ui";

export const getNotificationAppointmentStatus = (theme: Theme) => {
  return {
    [AppointmentStatus.Approved]: {
      label: i18n.t("Appointment Confirmed", { ns: "consumer" }),
      color: theme.palette.success.main,
      icon: ApprovedNotificationIcon,
      value: AppointmentStatus.Approved,
    },
    [AppointmentStatus.CallInProgress]: {
      label: i18n.t("Visit Starting Now", { ns: "consumer" }),
      color: theme.palette.secondary.main,
      icon: CallNotificationIcon,
      value: AppointmentStatus.CallInProgress,
    },
    [AppointmentStatus.CanceledByConsumer]: {
      label: i18n.t("Appointment Cancelled By Consumer", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: CanceledByNotificationIcon,
      value: AppointmentStatus.CanceledByConsumer,
    },
    [AppointmentStatus.CanceledByDoctor]: {
      label: i18n.t("Appointment Cancelled By Doctor", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: CanceledByNotificationIcon,
      value: AppointmentStatus.CanceledByDoctor,
    },
    [AppointmentStatus.Cancelled]: {
      label: i18n.t("Appointment Cancelled", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: CancelledNotificationIcon,
      value: AppointmentStatus.Cancelled,
    },
    [AppointmentStatus.ChatInProgress]: {
      label: i18n.t("Visit Starting Now", { ns: "consumer" }),
      color: theme.palette.secondary.main,
      icon: ChatNotificationIcon,
      value: AppointmentStatus.ChatInProgress,
    },
    [AppointmentStatus.ComingSoon]: {
      label: i18n.t("Appointment Reminder", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: ComingSoonNotificationIcon,
      value: AppointmentStatus.ComingSoon,
    },
    [AppointmentStatus.PaymentFailed]: {
      label: i18n.t("Payment Failed", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.PaymentFailed,
    },
    [AppointmentStatus.PaymentPending]: {
      label: i18n.t("Payment Pending", { ns: "consumer" }),
      color: theme.palette.warning.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.PaymentPending,
    },
    [AppointmentStatus.PaymentSucceeded]: {
      label: i18n.t("Payment Succeeded", { ns: "consumer" }),
      color: theme.palette.success.main,
      icon: ApprovedNotificationIcon,
      value: AppointmentStatus.PaymentSucceeded,
    },
    [AppointmentStatus.PendingApproval]: {
      label: i18n.t("Appointment Pending Confirmation", { ns: "consumer" }),
      color: theme.palette.warning.main,
      icon: PendingApprovalNotificationIcon,
      value: AppointmentStatus.PendingApproval,
    },
    [AppointmentStatus.Rejected]: {
      label: i18n.t("Appointment Rejected", { ns: "consumer" }),
      color: theme.palette.error.main,
      icon: RejectedNotificationIcon,
      value: AppointmentStatus.Rejected,
    },
    [AppointmentStatus.Request]: {
      label: i18n.t("Appointment Request Sent Successfully", { ns: "consumer" }),
      color: theme.palette.success.main,
      icon: ApprovedNotificationIcon,
      value: AppointmentStatus.Request,
    },
    [AppointmentStatus.RescheduledByConsumer]: {
      label: i18n.t("Appointment Rescheduled By Consumer", { ns: "consumer" }),
      color: theme.palette.warning.main,
      icon: RescheduledNotificationIcon,
      value: AppointmentStatus.RescheduledByConsumer,
    },
    [AppointmentStatus.RescheduledByDoctor]: {
      label: i18n.t("Appointment Rescheduled By Doctor", { ns: "consumer" }),
      color: theme.palette.warning.main,
      icon: RescheduledNotificationIcon,
      value: AppointmentStatus.RescheduledByDoctor,
    },
    [AppointmentStatus.Tentative]: {
      label: i18n.t("Tentative", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.Tentative,
    },
    [AppointmentStatus.Confirmed]: {
      label: i18n.t("Appointment Confirmed", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.Confirmed,
    },
    [AppointmentStatus.FinishedSuccessfully]: {
      label: i18n.t("Appointment Finished Successfully", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.FinishedSuccessfully,
    },
    [AppointmentStatus.OutPatientJourneyAssessmentDone]: {
      label: i18n.t("Out Patient Journey Assessment Done", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyAssessmentDone,
    },
    [AppointmentStatus.OutPatientJourneyAssessmentPending]: {
      label: i18n.t("Out Patient Journey Assessment Pending", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyAssessmentPending,
    },
    [AppointmentStatus.OutPatientJourneyCheckedIn]: {
      label: i18n.t("Out Patient Journey Checked In", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyCheckedIn,
    },
    [AppointmentStatus.OutPatientJourneyConsultationDone]: {
      label: i18n.t("Out Patient Journey Consultation Done", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyConsultationDone,
    },
    [AppointmentStatus.OutPatientJourneyConsultationPending]: {
      label: i18n.t("Out Patient Journey Consultation Pending", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyConsultationPending,
    },
    [AppointmentStatus.OutPatientJourneyPending]: {
      label: i18n.t("Out Patient Journey Pending", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyPending,
    },
    [AppointmentStatus.OutPatientJourneyTreatmentDone]: {
      label: i18n.t("Out Patient Journey Treatment Done", { ns: "consumer" }),
      color: theme.palette.primary.main,
      icon: NotificationBellIcon,
      value: AppointmentStatus.OutPatientJourneyTreatmentDone,
    },
  };
};

export const getNotificationAppointmentType = () => {
  return {
    [AppointmentType.AtHome]: {
      label: i18n.t("At-Home", { ns: "consumer" }),
      value: AppointmentType.AtHome,
    },
    [AppointmentType.Diagnostic]: {
      label: i18n.t("Diagnostic", { ns: "consumer" }),
      value: AppointmentType.Diagnostic,
    },
    [AppointmentType.Online]: {
      label: i18n.t("Virtual", { ns: "consumer" }),
      value: AppointmentType.Online,
    },
    [AppointmentType.Onsite]: {
      label: i18n.t("In-Person", { ns: "consumer" }),
      value: AppointmentType.Onsite,
    },
  };
};

export const getAppointmentDateTime = (appointment: Appointment, formatStr = "YYYY-MM-DD") => {
  if (appointment?.requestedTimeSlots?.[0]) {
    return format(JSON.parse(appointment?.requestedTimeSlots?.[0]).startTime, formatStr, i18n.language);
  } else {
    if (appointment?.suggestedTimeSlots?.[0]) {
      return format(JSON.parse(appointment?.suggestedTimeSlots?.[0]).startTime, formatStr, i18n.language);
    } else {
      return format(appointment?.created, formatStr, i18n.language);
    }
  }
};
