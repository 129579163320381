import { i18n } from "@toolkit/i18n";
import { VisitStatus } from "@health/queries/types";
import { AppToastProvider } from "@toolkit/ui";

export const openMeetingPlatformLink = async (meetingLink?: string) => {
  if (meetingLink && meetingLink.length > 0) {
    const queries = new URLSearchParams({ meetingLink });
    window.open(`${location.origin}/visit-call?${queries.toString()}`);
  } else {
    AppToastProvider.addFailedToast(i18n.t("Failed to rejoin the call. Please retry."));
  }
};

export const ReJoinableVisitStatuses = [
  VisitStatus.CallTimedOut,
  VisitStatus.Canceled,
  VisitStatus.ChatInProgress,
  VisitStatus.Incompleted,
  VisitStatus.EndedByOneOfTheParticipants,
];
