/* eslint-disable max-lines */
import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const UaePassIcon: FC<SvgIconProps> = props => {
  const { width, height } = props;
  return (
    <SvgIcon
      id='Group_10656'
      data-name='Group 10656'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width ? width : "32.482"}
      height={height ? height : "33.596"}
      viewBox='0 0 32.482 33.596'
      {...props}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_6343' data-name='Rectangle 6343' width='32.482' height='33.596' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_10655' data-name='Group 10655' clipPath='url(#clip-path)'>
        <path
          id='Path_12911'
          data-name='Path 12911'
          d='M39.5.174A18.346,18.346,0,0,1,49.991,1.824a4.6,4.6,0,0,1,1.517.947A.968.968,0,0,1,51.3,3.992c-.395.349-.905.066-1.3-.116A17.2,17.2,0,0,0,41.47,1.8a15.559,15.559,0,0,0-6.817,1.943,3.742,3.742,0,0,1-1.048.432.921.921,0,0,1-.422-1.67A16.311,16.311,0,0,1,39.5.174'
          transform='translate(-26.002 0)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12912'
          data-name='Path 12912'
          d='M71.181,19.881a3.349,3.349,0,0,1,1.2-.1,15.569,15.569,0,0,1,9.24,3,16.844,16.844,0,0,1,3.568,3.663.913.913,0,0,1-.606,1.414c-.583.118-.9-.453-1.2-.833a13.993,13.993,0,0,0-11.642-5.412.927.927,0,0,1-.558-1.728'
          transform='translate(-56.089 -15.688)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12913'
          data-name='Path 12913'
          d='M21.281,22.25a.91.91,0,0,1,1.269,1.091c-.2.651-.986.643-1.515.862a13.273,13.273,0,0,0-6.786,5.451c-.283.4-.579.893-1.114.967a.974.974,0,0,1-.814-1.453,19.689,19.689,0,0,1,4.109-4.378,17.866,17.866,0,0,1,4.852-2.54'
          transform='translate(-9.655 -17.567)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12914'
          data-name='Path 12914'
          d='M19.821,22.628a.908.908,0,1,1,.136,1.569.944.944,0,0,1-.136-1.569'
          transform='translate(-15.441 -17.807)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12915'
          data-name='Path 12915'
          d='M29.58,38.225a12.225,12.225,0,0,1,9.343,2.369,11.268,11.268,0,0,1,4.4,8.427,9.24,9.24,0,0,1-.874,4.907,3.535,3.535,0,0,1-3.469,1.705.934.934,0,0,1-.256-1.707c.548-.2,1.189-.07,1.7-.395a3.206,3.206,0,0,0,1.327-2.671A10.505,10.505,0,0,0,39.7,43.738a9.643,9.643,0,0,0-5.188-3.328,11.25,11.25,0,0,0-8.543.957,10.854,10.854,0,0,0-5.136,8.043,14.73,14.73,0,0,0,.54,6,1.029,1.029,0,0,1-.252,1.2.884.884,0,0,1-1.408-.5,16.118,16.118,0,0,1,.24-10.441,12.548,12.548,0,0,1,9.624-7.448'
          transform='translate(-15.046 -30.205)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12916'
          data-name='Path 12916'
          d='M79.606,57.816a.893.893,0,0,1,1.367.56.911.911,0,0,1-1.217,1.065.943.943,0,0,1-.149-1.625'
          transform='translate(-62.835 -45.742)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12917'
          data-name='Path 12917'
          d='M95.6,63.716a.879.879,0,0,1,1.059.045,9.438,9.438,0,0,1,3.481,6.089,3.657,3.657,0,0,1-.045,1.654.87.87,0,0,1-1.6-.395,8.777,8.777,0,0,0-2.908-5.85.948.948,0,0,1,.018-1.544'
          transform='translate(-75.516 -50.423)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12918'
          data-name='Path 12918'
          d='M142.577,64.9a.907.907,0,1,1-.027,1.548.943.943,0,0,1,.027-1.548'
          transform='translate(-112.773 -51.372)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12919'
          data-name='Path 12919'
          d='M1.251,69.833a.882.882,0,0,1,1.2.391c.236.4-.014.839-.118,1.238a13.4,13.4,0,0,0-.581,3.309.872.872,0,0,1-1.147.711.9.9,0,0,1-.6-.93A14.767,14.767,0,0,1,.7,70.682a1.293,1.293,0,0,1,.554-.85'
          transform='translate(0 -55.313)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12920'
          data-name='Path 12920'
          d='M58.712,76.876a2.809,2.809,0,0,1,1.538-.153.925.925,0,0,1,.157,1.6c-.508.238-1.112.18-1.606.465A3.035,3.035,0,0,0,57.247,81.5a10.471,10.471,0,0,0,2.811,7.241,10.83,10.83,0,0,0,4.481,2.774.938.938,0,0,1,.779,1.131.85.85,0,0,1-1.12.626,10.446,10.446,0,0,1-6.061-4.056A11.864,11.864,0,0,1,55.5,80.88a4.725,4.725,0,0,1,3.212-4'
          transform='translate(-43.984 -60.815)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12921'
          data-name='Path 12921'
          d='M149.034,79.181a.912.912,0,1,1-.112,1.662.943.943,0,0,1,.112-1.662'
          transform='translate(-117.786 -62.758)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12922'
          data-name='Path 12922'
          d='M88.38,82.812c.593-.374,1.222.155,1.424.711a6.817,6.817,0,0,1,.61,1.91.948.948,0,0,1-1.691.589c-.294-.643-.434-1.348-.705-2a.924.924,0,0,1,.362-1.209'
          transform='translate(-69.749 -65.595)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12923'
          data-name='Path 12923'
          d='M74.547,95.868a.807.807,0,0,1,1.267.558,8.778,8.778,0,0,0,.893,3.533,6.328,6.328,0,0,0,5.631,3.353c.5.037,1.127-.178,1.5.271a.932.932,0,0,1-.467,1.515,8.14,8.14,0,0,1-7.985-3.834,8.384,8.384,0,0,1-1.251-4.239,1.289,1.289,0,0,1,.416-1.155'
          transform='translate(-58.808 -75.943)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12924'
          data-name='Path 12924'
          d='M97.737,107.946a.917.917,0,0,1,1.162,1.18.9.9,0,0,1-1.408.43.951.951,0,0,1,.246-1.61'
          transform='translate(-77.068 -85.577)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12925'
          data-name='Path 12925'
          d='M129.613,125.863a.91.91,0,1,1-.029,1.54.945.945,0,0,1,.029-1.54'
          transform='translate(-102.495 -99.725)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12926'
          data-name='Path 12926'
          d='M28.488,135.867a.911.911,0,1,1-.012,1.581.948.948,0,0,1,.012-1.581'
          transform='translate(-22.258 -107.678)'
          fill='#1d1c1b'
        />
        <path
          id='Path_12927'
          data-name='Path 12927'
          d='M42.794,59.158c.368-.124.844-.242,1.154.068a.912.912,0,0,1-.3,1.55,7.041,7.041,0,0,0-4.43,4.08,9.035,9.035,0,0,0-.387,4.537,13.753,13.753,0,0,0,1.687,5.044.9.9,0,0,1-.918,1.346c-.711-.157-.858-1-1.164-1.548-1.13-2.842-1.995-6.025-1.172-9.066a8.613,8.613,0,0,1,5.531-6.011'
          transform='translate(-29.302 -46.827)'
          fill='#1eab75'
        />
        <path
          id='Path_12928'
          data-name='Path 12928'
          d='M55.865,144.636a.913.913,0,0,1,1.28.2,16.877,16.877,0,0,0,2.121,1.815.855.855,0,0,1-.6,1.523,6.972,6.972,0,0,1-2.9-2.152.958.958,0,0,1,.1-1.391'
          transform='translate(-44.039 -114.595)'
          fill='#e82026'
        />
      </g>
    </SvgIcon>
  );
};
