import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Communication: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26' height='25' viewBox='0 0 26 25' {...props}>
      <path
        id='icons8-communication'
        d='M13,3C6.925,3,2,7.477,2,13a9.538,9.538,0,0,0,3.4,7.205,5.559,5.559,0,0,1-2.072,2.826l0,0a.5.5,0,0,0,.279.955,7.975,7.975,0,0,0,4.912-1.873,11.7,11.7,0,0,0,1.957.609A8.116,8.116,0,0,1,11.055,16H9a1,1,0,0,1,0-2h3.568a9.971,9.971,0,0,1,2.871-2H9a1,1,0,0,1,0-2h8a.922.922,0,0,1,.963,1.189A11.08,11.08,0,0,1,20,11a10.894,10.894,0,0,1,3.9.713C23.2,6.8,18.594,3,13,3Zm7,10c-4.418,0-8,3.134-8,7s3.582,7,8,7a8.966,8.966,0,0,0,2.984-.51,7.724,7.724,0,0,0,4.41,1.5.5.5,0,0,0,.277-.957,5.835,5.835,0,0,1-1.854-2.238A6.532,6.532,0,0,0,28,20C28,16.134,24.418,13,20,13Zm-3,4h6a1,1,0,0,1,0,2H17a1,1,0,0,1,0-2Zm0,4h6a1,1,0,0,1,0,2H17a1,1,0,0,1,0-2Z'
        transform='translate(-2 -3)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
export default Communication;
