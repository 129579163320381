import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const PhoneReceiverIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox='0 0 44.589 44.589' {...props}>
      <path
        d='M4.63 26.701a1.525 1.525 0 0 1-.593-1.285l.13-6.256a2.5 2.5 0 0 1 .17-.954 3.243 3.243 0 0 1 .476-.878q.023-.023.132-.15t.3-.315a15.394 15.394 0 0 1 1.627-1.271 15.6 15.6 0 0 1 3.24-1.645 29.15 29.15 0 0 1 5.126-1.401 34.732 34.732 0 0 1 7.235-.491 36.593 36.593 0 0 1 5.848.477 31.406 31.406 0 0 1 4.48 1.08 20.953 20.953 0 0 1 3.258 1.356 17.186 17.186 0 0 1 2.193 1.357 8.27 8.27 0 0 1 1.25 1.097q.393.454.44.503a3.246 3.246 0 0 1 .447.893 2.5 2.5 0 0 1 .137.96l-.108 6.28a1.471 1.471 0 0 1-.443 1.078 1.246 1.246 0 0 1-.726.354 2.322 2.322 0 0 1-.838-.05l-7.28-2.39a1.625 1.625 0 0 1-.933-.725 2.081 2.081 0 0 1-.334-1.163l.04-2.314a.557.557 0 0 0-.068-.237.824.824 0 0 0-.137-.215 6.03 6.03 0 0 0-1.544-.733 14.714 14.714 0 0 0-2.246-.546 21.69 21.69 0 0 0-3.553-.262 22.12 22.12 0 0 0-3.579.139 15.427 15.427 0 0 0-2.275.458 5.192 5.192 0 0 0-1.2.486l-.357.23a.8.8 0 0 0-.122.186.558.558 0 0 0-.074.235l-.02 2.713a2.046 2.046 0 0 1-.636 1.357 1.745 1.745 0 0 1-.786.482l-.024.023-7.28 1.742A1.68 1.68 0 0 1 4.63 26.7Z'
        data-name='Path 1227'
      />
    </SvgIcon>
  );
};
