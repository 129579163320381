import { Box, IconButton } from "@toolkit/ui";
import { ExpandMoreIcon } from "../Icons";
import { useStyles } from "./VirtualScrollingContainer.styles";
import { useVirtualScrolling } from "./useVirtualScrolling";

interface VirtualScrollingContainerProps {
  cards: JSX.Element[];
  cardWidth?: number;
  gap?: number;
  visibleCardCount?: number;
  onReachEnd?: () => void;
  onReachStart?: () => void;
  onActiveCardChange?: (index: number) => void;
}

export const VirtualScrollingContainer: React.FC<VirtualScrollingContainerProps> = ({
  cards,
  cardWidth = 600,
  gap = 10,
  visibleCardCount = 3,
  onReachEnd,
  onReachStart,
  onActiveCardChange,
}) => {
  const { classes } = useStyles({ gap, cardWidth });
  const { ref, filteredCards, firstCardWidth, lastCardWidth, handleScrollButton, handleScroll } = useVirtualScrolling({
    cards,
    cardWidth,
    gap,
    visibleCardCount,
    onReachEnd,
    onReachStart,
    onActiveCardChange,
  });
  return (
    <Box className={classes.container}>
      <IconButton className={classes.button} onClick={() => handleScrollButton("prev")}>
        <ExpandMoreIcon direction='left' />
      </IconButton>
      <Box onScroll={handleScroll} ref={ref} className={classes.scrollArea}>
        {firstCardWidth > 0 ? <Box sx={{ minWidth: `${firstCardWidth}px` }} /> : null}
        {filteredCards}
        {lastCardWidth > 0 ? <Box sx={{ minWidth: `${lastCardWidth}px` }} /> : null}
      </Box>
      <IconButton className={classes.button} onClick={() => handleScrollButton("next")}>
        <ExpandMoreIcon />
      </IconButton>
    </Box>
  );
};
