import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CallIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "23.939"}
      height={height ? height : "23.94"}
      viewBox={viewBox ? viewBox : "0 0 23.939 23.94"}
      {...props}
    >
      <path
        id='icons8-call'
        d='M22.554,3a9.481,9.481,0,0,0-3.767,1.009,30.905,30.905,0,0,0-8.393,6.383,30.926,30.926,0,0,0-6.385,8.395A9.508,9.508,0,0,0,3,22.554v.028a1.919,1.919,0,0,0,.531,1.2A10.3,10.3,0,0,0,4.56,24.9a20.277,20.277,0,0,0,1.953,1.651,1.643,1.643,0,0,0,2.329-.174c.329-.459,2.826-4.252,3.2-4.87h0a1.8,1.8,0,0,0-.119-1.9c-.261-.478-1-1.757-1.211-2.129a24.4,24.4,0,0,1,3.166-3.6l0,0v0a21.393,21.393,0,0,1,3.6-3.164c.363.208,1.621.933,2.116,1.2a1.849,1.849,0,0,0,1.88.147l.006,0,0,0c.291-.174,1.373-.878,2.482-1.606.555-.363,1.1-.727,1.543-1.016s.722-.482.867-.591a1.644,1.644,0,0,0,.174-2.329A20.879,20.879,0,0,0,24.9,4.545,9.652,9.652,0,0,0,23.774,3.51,1.807,1.807,0,0,0,22.554,3Zm.055,1.086a3.966,3.966,0,0,1,.508.289,8.932,8.932,0,0,1,.992.918A20.508,20.508,0,0,1,25.66,7.146c.338.472.1.795.081.81l-.006.006-.006,0c-.047.038-.391.272-.827.561s-.982.652-1.536,1.014c-1.1.722-2.242,1.462-2.433,1.579h0a.985.985,0,0,1-.782-.138l-.008-.006-.011,0c-.5-.272-2.418-1.375-2.418-1.375l-.27-.155-.27.155a22.89,22.89,0,0,0-4.065,3.514,0,0,0,0,1,0,0v0A25.991,25.991,0,0,0,9.6,17.16l-.166.274.159.278s1.1,1.921,1.373,2.418a1.337,1.337,0,0,1,.168.769l-.006.011-.006.013c-.217.361-3.019,4.613-3.166,4.819-.015.021-.34.257-.81-.081a20.066,20.066,0,0,1-1.834-1.549,9.555,9.555,0,0,1-.916-.994,2.947,2.947,0,0,1-.3-.533A7.829,7.829,0,0,1,5,19.25a29.9,29.9,0,0,1,6.168-8.087A29.9,29.9,0,0,1,19.248,5,7.929,7.929,0,0,1,22.609,4.088Z'
        transform='translate(-3 -3.001)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
