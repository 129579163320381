import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  container: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    minWidth: "130px",
    height: "40px",
    borderRadius: "24px",
    borderColor: theme.palette.primary[50],
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: `${theme.palette.text.primary}!important`,
    "& path": {
      fill: theme.palette.text.primary,
    },
    "& svg": {
      width: "12px",
      height: "12px",
    },
  },
  divider: {
    width: "calc(100% - 20px)",
    height: "1px",
    marginBlock: "15px",
    marginLeft: "10px",
    backgroundColor: theme.palette.primary[50],
  },
  versionText: {
    marginTop: "-5px",
    marginBottom: "7px",
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));

export default useStyles;
