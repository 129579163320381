import React from "react";
import { useTheme } from "../../base/mui";
export const InteractiveIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='45' viewBox='0 0 44 45'>
      <g id='Group_9632' data-name='Group 9632' transform='translate(-308.283 -850)'>
        <ellipse
          id='Ellipse_2620'
          data-name='Ellipse 2620'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(308.283 850)'
          fill={palette.success.main}
        />
        <path
          id='icons8-doctor-female'
          d='M8,4a1,1,0,0,0-.971.758A46.628,46.628,0,0,0,6,15a1,1,0,0,0,.105.443A14.006,14.006,0,0,0,4,23v1.857a2.285,2.285,0,0,0,2.285,2.285H13s-4-1.475-4-5.475c0-3.934,1.675-4.039,2.521-5.092A4.95,4.95,0,0,0,18.971,16h2.148A18.92,18.92,0,0,1,22,21.143v1.143c0,3.429-2.715,3.572-2.715,3.572h4.43A2.288,2.288,0,0,0,26,23.57v-2.9a9.971,9.971,0,0,0-2.01-6,46.782,46.782,0,0,0-1.021-9.9A1,1,0,0,0,22,4Zm.832,2H21.168a45.226,45.226,0,0,1,.764,8H19.9a5,5,0,1,0-9.8,0H8.068A45.226,45.226,0,0,1,8.832,6ZM15,10a3,3,0,1,1-3,3A2.985,2.985,0,0,1,15,10Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,15,12Z'
          transform='translate(315.999 855.93)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
