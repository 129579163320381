import { i18n } from "@toolkit/i18n";
import { getMenuItems } from "../MenuList/menuItems";
import { MenuList } from "../MenuList/MenuList";
import { useMenuMenuStyles } from "../MenuList/Menu.styles";

export const UserProfileMenu = () => {
  const menu = getMenuItems("0 AED", i18n);
  const { classes } = useMenuMenuStyles();
  return <MenuList menuItems={[menu[2]]} classes={classes} />;
};
