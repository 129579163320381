import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "500px",
  },
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.x40,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    color: theme.palette.text.primary,
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale.main,
    marginTop: "5px",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginBottom: "20px",
  },
  servicesContainer: {
    display: "flex",
    flexDirection: "row",
    height: "280px",
    gap: "40px",
    paddingBlockEnd: "20px",
    [theme.breakpoints.down("sm")]: {
      gap: "0px",
      height: "auto",
      marginTop: "0px",
      width: "100%",
      justifyContent: "center",

      "&& .service-button-container": {
        width: "max(calc(20vw - 5px), 90px)",
        "& svg": {
          width: "100%",
        },
        ".service-label": {
          fontSize: theme.mixins.fonts.fontSize.xs,
          marginTop: "5px",
          maxWidth: "70px",
        },
        ".service-button": {
          width: "100%",
        },
      },
    },
  },
  serviceButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  serviceButton: {
    "& svg": {
      width: "90px",
      height: "90px",
    },
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  serviceLabel: {
    marginTop: "15px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.text.primary,
    width: "80px",
  },
}));
