import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const CirclePlus: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19.433' height='19.433' viewBox='0 0 19.433 19.433' {...props}>
      <path
        id='icons8-plus-_'
        d='M11.717,2a9.717,9.717,0,1,0,9.717,9.717A9.723,9.723,0,0,0,11.717,2Zm0,.845a8.872,8.872,0,1,1-8.872,8.872A8.865,8.865,0,0,1,11.717,2.845Zm-.422,3.8v4.647H6.647v.845h4.647v4.647h.845V12.139h4.647v-.845H12.139V6.647Z'
        transform='translate(-2 -2)'
      />
    </SvgIcon>
  );
};
