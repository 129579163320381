import { AppointmentConfirmationFormRef } from "@/domains/appointment/forms/AppointmentConfirmation/AppointmentConfirmationForm";
import { Button } from "@toolkit/ui";
import React from "react";

export const PlaygroundFormsPage = () => {
  const formRef = React.useRef<AppointmentConfirmationFormRef>(null);
  return (
    <>
      {/* <AppointmentConfirmationForm ref={formRef} /> */}
      <Button onClick={() => formRef.current?.submit()}>Submit</Button>
    </>
  );
};
