import React from "react";
import { useTheme } from "../../base/mui";

export const BoxImportant = () => {
  const { palette } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60.848' height='60.848' viewBox='0 0 60.848 60.848'>
      <path
        id='icons8-box-important'
        d='M32.424,2A30.424,30.424,0,1,0,62.848,32.424,30.444,30.444,0,0,0,32.424,2Zm0,2.646A27.778,27.778,0,1,1,4.646,32.424,27.758,27.758,0,0,1,32.424,4.646ZM30.838,18.271a.483.483,0,0,0-.53.527V36.656a.485.485,0,0,0,.53.53h3.041a.485.485,0,0,0,.53-.53V18.8a.483.483,0,0,0-.53-.527Zm0,23.81a.483.483,0,0,0-.53.527v3.441a.483.483,0,0,0,.53.527H34.01a.483.483,0,0,0,.53-.527V42.608a.483.483,0,0,0-.53-.527Z'
        transform='translate(-2 -2)'
        fill={palette.warning.main}
      />
    </svg>
  );
};
