import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DietIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24px"}
      height={height ? height : "24.923px"}
      viewBox={viewBox ? viewBox : "0 0 24 24.923"}
      {...props}
    >
      <path
        id='icons8-healthy-eating_1_'
        data-name='icons8-healthy-eating (1)'
        d='M19.175,0A4.429,4.429,0,0,0,15,4.266a.367.367,0,0,0,.409.348,4.466,4.466,0,0,0,4.2-4.248A.361.361,0,0,0,19.175,0Zm-8.8.912a.923.923,0,0,0-.5,1.7,5.862,5.862,0,0,1,2.221,3.508,5.966,5.966,0,0,0-2.632-.584C5.9,5.538,3,8.742,3,12.68,3,19.8,7.651,23.568,10.623,23.96a1.853,1.853,0,0,0,1.608.963h9.231a5.55,5.55,0,0,0,5.533-5.441.923.923,0,0,0,.005-.1v-12a.923.923,0,1,0-1.846,0v4.6s0,.008,0,.013a3.668,3.668,0,0,1-2.082,3.314,14.69,14.69,0,0,0,.236-2.634c0-3.938-2.9-7.141-6.462-7.141a5.937,5.937,0,0,0-2.861.694A8.07,8.07,0,0,0,10.9,1.078a.923.923,0,0,0-.521-.162ZM25.154,16.042v3.343a3.679,3.679,0,0,1-3.692,3.692H12.231V17.538h1.846v1.846a.923.923,0,1,0,1.846,0V17.538h1.846v.923a.923.923,0,1,0,1.846,0v-.923h1.846v1.846a.923.923,0,1,0,1.846,0V17.538a.923.923,0,0,0-.052-.312A5.4,5.4,0,0,0,25.154,16.042Z'
        transform='translate(-3 0)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
