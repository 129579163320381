import React from "react";
import { Box, Card, CardMedia, Skeleton, makeStyles } from "@toolkit/ui";

export const ProductCardSkeleton: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Card className={classes.root}>
      <Box className={classes.cardTitleContainer}>
        <Skeleton variant='text' width='60%' />
      </Box>
      <CardMedia className={classes.cardImageContainer}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </CardMedia>
      <Box className={classes.cardDetailsContainer}>
        <Skeleton variant='text' width='40%' />
        <Skeleton variant='text' width='80%' />
        <Skeleton variant='text' width='70%' />
      </Box>
      <Box className={classes.cardActionContainer}>
        <Box className={classes.priceContainer}>
          <Skeleton variant='text' width='100%' />
        </Box>
        <Box className={classes.cardActionButtonsContainer}>
          <Skeleton variant='circular' width={35} height={35} />
          <Skeleton variant='circular' width={35} height={35} />
        </Box>
      </Box>
    </Card>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    gap: "10px",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  cardTitleContainer: {
    textAlign: "start",
  },
  cardImageContainer: {
    height: 120,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  cardDetailsContainer: {
    textAlign: "start",
  },
  cardActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #e0e0e0",
    paddingTop: "10px",
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    width: "20%",
  },
  cardActionButtonsContainer: {
    display: "flex",
    gap: "8px",
  },
}));
