import { Box, BoxProps } from "@toolkit/ui";
import { FC } from "react";
interface DotProps extends BoxProps {
  size?: number;
  color?: "success" | "error" | "warning" | "info" | "primary" | "secondary";
}

export const Dot: FC<DotProps> = ({ size = 12, color = "success", ...props }) => {
  const _color = color + ".main";
  return (
    <Box
      {...props}
      sx={{
        width: size,
        height: size,
        color: _color,
        backgroundColor: _color,
        border: `1px solid ${_color}`,
        borderRadius: size / 2,
        display: "inline-block",
        ...props.sx,
      }}
    />
  );
};

export default Dot;
