import { Box } from "@toolkit/ui";
import portraitForMuslimFemaleDoctor from "@/assets/portraitForMuslimFemaleDoctor.png";
import React from "react";
import { useTranslation } from "@toolkit/i18n";
import { useStyle } from "./HomePageStyle";
import { HealthServicesSection } from "./components/HealthServicesSection/HealthServicesSection";
import { UserUpcomingAppointmentAndProgressSection } from "./components/UserUpcomingAppointmentAndProgressSection/UserUpcomingAppointmentAndProgressSection";
import { CategoriesSection } from "./components/CategoriesSection/CategoriesSection";
import { ProductsSection } from "./components/ProductsSection/ProductsSection";
import { ReelsSection } from "./components/ReelsSection/ReelsSection";
import { HealthPackagesSection } from "./components/HealthPackagesSection/HealthPackagesSection";
import { UserProfileSection } from "./components/UserProfileSection/UserProfileSection";
import { PortraitBackgroundIcon } from "@/components";
import { useAuth } from "react-oidc-context";

export const HomePage: React.FC = () => {
  const { classes, cx } = useStyle();
  const { t } = useTranslation("consumer");
  const { isAuthenticated } = useAuth();
  return (
    <Box className={classes.pageContainer}>
      <HealthServicesSection />
      <UserUpcomingAppointmentAndProgressSection />
      <CategoriesSection />
      {isAuthenticated && <ProductsSection />}
      <ReelsSection />

      {/* mobile sections */}
      <UserProfileSection />
      {isAuthenticated && <HealthPackagesSection />}

      <PortraitBackgroundIcon className={cx(classes.portrait, classes.portraitBlob)} />
      <Box className={classes.portrait} component='img' src={portraitForMuslimFemaleDoctor} alt={t("portrait.description")} />
    </Box>
  );
};
