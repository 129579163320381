import { FC } from "react";
import { HealthServicesIconProps, HealthServicesIconWrapper } from "./HealthServicesIconWrapper";

export const HealthServiceBookAnAppointmentIcon: FC<HealthServicesIconProps> = props => {
  return (
    <HealthServicesIconWrapper {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='Group_10149' data-name='Group 10149' transform='translate(20 20)'>
        <path
          id='Path_12758'
          data-name='Path 12758'
          d='M24.027,28H9.972A3.942,3.942,0,0,1,6,24.027v-5.5a.917.917,0,0,1,1.833,0v5.5a2.116,2.116,0,0,0,2.139,2.139H24.027a2.116,2.116,0,0,0,2.139-2.139V9.972a2.116,2.116,0,0,0-2.139-2.139H18.466a.917.917,0,1,1,0-1.833h5.561A3.942,3.942,0,0,1,28,9.972V24.027A3.942,3.942,0,0,1,24.027,28Z'
          transform='translate(-2.333 -2.333)'
          fill='#fff'
        />
        <path
          id='Line_1486'
          data-name='Line 1486'
          d='M11.516.333H-.583A.917.917,0,0,1-1.5-.583.917.917,0,0,1-.583-1.5h12.1a.917.917,0,0,1,.917.917A.917.917,0,0,1,11.516.333Z'
          transform='translate(13.233 10.055)'
          fill='#fff'
        />
        <path
          id='Line_1487'
          data-name='Line 1487'
          d='M-.583,3.083A.917.917,0,0,1-1.5,2.167V-.583A.917.917,0,0,1-.583-1.5a.917.917,0,0,1,.917.917v2.75A.917.917,0,0,1-.583,3.083Z'
          transform='translate(21.055 3.639)'
          fill='#fff'
        />
        <path
          id='Path_12759'
          data-name='Path 12759'
          d='M7.028,14.055A7.046,7.046,0,0,1,0,7.028,7.938,7.938,0,0,1,.205,5.24a.917.917,0,0,1,1.79.4,6.1,6.1,0,0,0-.162,1.39A5.187,5.187,0,0,0,10.7,10.7,5.194,5.194,0,0,0,7.028,1.833,5.51,5.51,0,0,0,5.4,2.1.917.917,0,1,1,4.87.344,7.354,7.354,0,0,1,7.028,0a7.02,7.02,0,0,1,4.964,11.992A6.978,6.978,0,0,1,7.028,14.055Z'
          transform='translate(0 0)'
          fill='#fff'
        />
        <path
          id='Path_12760'
          data-name='Path 12760'
          d='M12.139,11.111a.914.914,0,0,1-.648-.268L10.268,9.62A.917.917,0,0,1,10,8.972V5.917a.917.917,0,0,1,1.833,0V8.592l.954.954a.917.917,0,0,1-.648,1.565Z'
          transform='translate(-3.889 -1.945)'
          fill='#fff'
        />
      </g>
    </HealthServicesIconWrapper>
  );
};
