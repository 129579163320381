import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CalenderIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      viewBox={viewBox ? viewBox : "0 0 22 22"}
      {...props}
    >
      <path
        id='icons8-calendar'
        d='M24,4H6A2,2,0,0,0,4,6V24a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V6A2,2,0,0,0,24,4ZM7,23a1,1,0,1,1,1-1A1,1,0,0,1,7,23Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,7,19Zm4,4a1,1,0,1,1,1-1A1,1,0,0,1,11,23Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,11,19Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,11,15Zm4,8a1,1,0,1,1,1-1A1,1,0,0,1,15,23Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,15,19Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,15,15Zm4,8a1,1,0,1,1,1-1A1,1,0,0,1,19,23Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,19,19Zm-1-5a1,1,0,1,1,1,1A1,1,0,0,1,18,14Zm5,5a1,1,0,1,1,1-1A1,1,0,0,1,23,19Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,23,15Zm1-5H6V6H24Z'
        transform='translate(-4 -4)'
        width={width ? width : "22px"}
        height={height ? height : "22px"}
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
