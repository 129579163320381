import { makeStyles } from "@toolkit/ui";

export const useMedicalMessageNotificationCardStyle = makeStyles()(theme => ({
  root: {
    position: "relative",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.grey[100],
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    width: "calc(100% - 20px)",
    display: "flex",
    margin: "5px 10px",

    overflow: "hidden",
  },
  image: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    minHeight: 150,
    objectFit: "scale-down",
    padding: "10px",
  },
  contentWrapper: {
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "left",
    width: "65%",
  },
  date: {
    color: theme.palette.stale.main,
    fontSize: "12px",
  },
  description: {
    color: theme.palette.stale.main,
    marginTop: 8,
    textAlign: "left",
  },
  seenIconWrapper: {
    position: "absolute",
    right: "5%",
    top: "50%",
  },
}));
