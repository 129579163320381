import { Box, Button, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import {
  AppointmentConfirmationForm,
  AppointmentConfirmationFormRef,
} from "../../forms/AppointmentConfirmation/AppointmentConfirmationForm";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { IAppointmentConfirmationFormValues } from "../../forms/AppointmentConfirmation/AppointmentConfirmationFormSchema";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useCommonStyles } from "../CommonContainer.styles";
import { useTranslation } from "@toolkit/i18n";
import { useSendOtpMessageForAnonymousAppointmentMutation } from "../../gql";

export const AppointmentConfirmationGuestFormContainer: FC = () => {
  const ref = useRef<AppointmentConfirmationFormRef | null>(null);
  const { onGuestInfoChange, doctorInfo, guestInfo, otp } = useAppointmentBookingContext();
  const { classes } = useCommonStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const { failed } = useAddToast();
  const onSubmitClick = () => {
    ref.current?.submit();
  };
  useEffect(() => {
    if (!doctorInfo?.doctorId) {
      navigate(routes.root.absRoute);
    }
  }, [doctorInfo]);

  const [sendOTPForGuest, { loading: isLoading }] = useSendOtpMessageForAnonymousAppointmentMutation({
    onCompleted: () => {
      if (!otp) {
        navigate(routes.confirmOTP.absRoute);
      } else {
        navigate(routes.confirm.absRoute);
      }
    },
    onError: () => {
      failed(t("Something went wrong, please try again"));
    },
  });
  const onSubmit = (values: IAppointmentConfirmationFormValues) => {
    onGuestInfoChange(values);
    values?.name &&
      values?.phone &&
      sendOTPForGuest({
        variables: {
          consumerName: values?.name,
          consumerMobileNumber: values?.phone,
        },
      });
  };
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <AppointmentConfirmationForm ref={ref} onSubmit={onSubmit} defaultValues={guestInfo} />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.button} disabled={isLoading} onClick={onSubmitClick}>
          {t("Next")}
        </Button>
      </Box>
    </Box>
  );
};
