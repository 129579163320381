import { Box, format, Grid, Typography } from "@toolkit/ui";
import { useStyles } from "./ComingSoonCardStyle";
import { BookAnAppointmentIcon } from "@/components";
import { i18n, useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { ComingSoonAppointmentDataType } from "../../types/types";

type BookAnAppointmentCardProps = {
  className?: string;
  appointment?: ComingSoonAppointmentDataType;
};

export const BookAnAppointmentCardSm: FC<BookAnAppointmentCardProps> = props => {
  const { className, appointment } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("consumer");
  const time = format(appointment?.confirmedStartTime, "DD MMMM . hh:mm A", i18n.language);

  return (
    <Box role='button' className={cx(className, classes.root)}>
      <Grid height='100%' className={"card__container"} container gap={2} alignItems='center' justifyContent='start'>
        <Grid item className={cx(classes.iconWrapper, "card__startIcon")}>
          <BookAnAppointmentIcon />
        </Grid>
        <Grid item className={classes.textWrapper}>
          <Typography className={cx(classes.typographyTitle, "card__title")}>{time}</Typography>
          <Typography className={cx(classes.typographySubtitle, "card__subtitle", "card__vendorTitle")}>
            {t("Appointment | {{vendorName}}", { vendorName: appointment?.vendor?.name })}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
