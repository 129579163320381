import { makeStyles } from "@toolkit/ui";

export const useCommonStyles = makeStyles()(theme => ({
  pageContainer: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  pageContent: {
    padding: "10px",
    flex: 1,
  },
  title: {
    marginBottom: "10px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    textAlign: "start",
  },
  footer: {
    position: "sticky",
    bottom: "0px",
    left: "0px",
    height: "74px",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  button: {
    width: "215px",
    height: "48px",
    borderRadius: "24px",
  },
  mgInline_0: {
    marginInline: "0px!important",
  },
}));
