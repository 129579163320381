import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const ContactIcon: FC<SvgIconProps> = ({ fill, width, height, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "17.12px"}
      height={height ? height : "17.12px"}
      viewBox='0 0 17.118 17.119'
      {...props}
    >
      <path
        id='icons8-call'
        d='M10.037,16.82a.956.956,0,0,0-.005-.974l-.907-1.523a.953.953,0,0,1,.065-1.079,24.776,24.776,0,0,1,1.889-2.167,24.776,24.776,0,0,1,2.167-1.889.953.953,0,0,1,1.079-.065l1.523.907a.96.96,0,0,0,.977,0L20.642,7.8a.963.963,0,0,0,.449-1.063A3.17,3.17,0,0,0,20.2,5.257C18.48,3.535,15.575,2.62,9.1,9.1s-5.563,9.382-3.84,11.1a3.17,3.17,0,0,0,1.482.889A.959.959,0,0,0,7.8,20.643Z'
        transform='translate(-4 -3.999)'
        fill={fill ? fill : palette.gray.light}
      />
    </SvgIcon>
  );
};
