import {
  appointmentListFiltersFormDefaultValues,
  AppointmentsListFiltersFormSchema,
  IAppointmentsListFiltersFormValues,
} from "../AppointmentsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentFilterFormProps } from "../types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode } from "@health/queries/types";

export const SpecializationsFilterForm: React.FC<AppointmentFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IAppointmentsListFiltersFormValues>
      schema={AppointmentsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='specializations'
      dialogTitle={t("Specialty")}
      formLabel={t("Specialty")}
      render={name => <SystemCodeAutocomplete name={name} multiple isCodeHidden filter={{ codeSystemCode: CodeSystemCode.Speciality }} />}
      defaultValues={appointmentListFiltersFormDefaultValues}
    />
  );
};
