import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const EllipseExpectation: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8906'
      data-name='Group 8906'
      xmlns='http://www.w3.org/2000/svg'
      width={44}
      height={45}
      viewBox='0 0 44 45'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={palette.error[1500]} />
          <stop offset='1' stopColor={palette.error[1500]} />
        </linearGradient>
      </defs>
      <g id='Group_9447' data-name='Group 9447' transform='translate(-1165.066 -427)'>
        <ellipse
          id='Ellipse_2623'
          data-name='Ellipse 2623'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(1165.066 427)'
          fill='url(#linear-gradient)'
        />
        <path
          id='icons8-full-family_1_'
          data-name='icons8-full-family (1)'
          d='M7,2a3,3,0,1,0,3,3A3,3,0,0,0,7,2ZM23,2a3,3,0,1,0,3,3A3,3,0,0,0,23,2Zm3,4a3.306,3.306,0,0,0,.75,2.246A3.306,3.306,0,0,0,29,9a3.306,3.306,0,0,0-.75-2.246A3.306,3.306,0,0,0,26,6ZM6,10a3.015,3.015,0,0,0-3,3v6a1,1,0,0,0,1,1.014v5.953a1.033,1.033,0,0,0,2.064.068l.438-6.57a.5.5,0,0,1,1,0l.438,6.568A1.033,1.033,0,0,0,10,25.967V20.18a1,1,0,0,0,1.676.559l.031-.031.719-.717L12,25.93a1,1,0,1,0,2,.141L14,26l.385-2.705a.619.619,0,0,1,1.225,0l.381,2.678.008.1a1,1,0,0,0,2-.141l-.424-5.941.7.7.016.018,0,0,.029.029A1,1,0,0,0,20,20.176v5.791a1.033,1.033,0,0,0,2.064.068L22.4,21h1.2l.336,5.035A1.033,1.033,0,0,0,26,25.967V21h1a1,1,0,0,0,.977-1.217l-1.568-7.049a3.147,3.147,0,0,0-1.467-2.006,2.972,2.972,0,0,0-1.8-.715A1,1,0,0,0,23,10a3.5,3.5,0,0,0-3.408,2.734L18.4,18.068A5.211,5.211,0,0,0,15.254,17h-.508a5.206,5.206,0,0,0-2.969.934l-.586-5.266A3.011,3.011,0,0,0,8.211,10Zm9,2a2,2,0,1,0,2,2A2,2,0,0,0,15,12Z'
          transform='translate(1171.5 435)'
          fill={palette.common.white}
        />
      </g>{" "}
    </SvgIcon>
  );
};
