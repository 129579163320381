import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    "& svg": {
      width: "173px",
      height: "173px",
    },
  },
  messageContainer: {
    marginTop: "30px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  subMessageContainer: {
    marginTop: "10px",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale.main,
  },
  actionContainer: {
    marginTop: "30px",
    height: "50px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));
