import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const ApprovedNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-today_1_'
        data-name='icons8-today (1)'
        d='M10.936,3.478a.874.874,0,0,0-.86.886v.582H9.785A3.788,3.788,0,0,0,6,8.732v9.72a.874.874,0,1,0,1.747,0V8.732A1.981,1.981,0,0,1,9.785,6.694h.291v.291a.874.874,0,1,0,1.747,0V6.694h9.318v.291a.874.874,0,1,0,1.747,0V6.694h.291a1.981,1.981,0,0,1,2.038,2.038v5.223a.874.874,0,1,0,1.747,0V8.732A3.788,3.788,0,0,0,23.18,4.947h-.291V4.364a.874.874,0,1,0-1.747,0v.582H11.824V4.364a.874.874,0,0,0-.887-.886ZM9.785,9.606a.874.874,0,1,0,0,1.747H23.18a.874.874,0,1,0,0-1.747Zm11.089,4.067a.874.874,0,0,0-.673.3L15.575,19.11l-2.26-2.26a.874.874,0,1,0-1.235,1.235L14.991,21a.874.874,0,0,0,1.267-.033L21.5,15.14a.874.874,0,0,0-.626-1.467Zm5.2,3.634a.874.874,0,0,0-.86.885v3.934a1.981,1.981,0,0,1-2.038,2.038H9.785a1.981,1.981,0,0,1-2.038-2.038.874.874,0,1,0-1.747,0,3.789,3.789,0,0,0,3.785,3.785H23.18a3.788,3.788,0,0,0,3.785-3.785V18.192a.874.874,0,0,0-.887-.885Z'
        transform='translate(7 8)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
