import { Box, Button, CardsContainer, Typography, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./ReelsSectionStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useGetEventList } from "@sada/react/queries";
import { ReelCard } from "@/domains/reelsAndVideos";
import { useTranslation } from "@toolkit/i18n";
import { ArrowIcon, CircularIconButton } from "@/components";
import { ReelCardSkeleton } from "@/domains/reelsAndVideos/components/ReelCardSkeleton/ReelCardSkeleton";
import { IEvent } from "@sada/react/schema";

export const ReelsSection: React.FC = () => {
  const { t } = useTranslation("consumer");
  const { classes, cx, theme } = useStyle();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { events, loading } = useGetEventList({
    type: "short",
    itemsPerPage: 10,
  });

  const handleOnReelCardCLick = (event: IEvent) => {
    return () => {
      console.log(event?.slug);
      // implement on click
    };
  };

  const handleSeeAll = () => {
    // implement see all
  };
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "reels")}>
      <Box className={cx(commonClasses.container, classes.container)}>
        <Box className={commonClasses.titleContainer}>
          <Typography className={cx(commonClasses.title, "title")}>{t("Videos & Reels")}</Typography>
          <Button onClick={handleSeeAll} className={cx(commonClasses.titleSeeAll, "title__btn")} variant='text'>
            {t("See All")}
          </Button>
        </Box>
        <CardsContainer
          classes={{
            cardsContainerClassName: classes.cardsContainerClassName,
            navBtnClassName: classes.navBtnClassName,
            navBtnContainerClassName: classes.navBtnContainerClassName,
            cardsContainer: classes.cardsContainer,
            cardsUpperContainer: classes.cardsUpperContainer,
            card: classes.card,
            skeletonContainer: classes.skeletonContainer,
          }}
          isLoadingData={loading}
          countOfSkeletons={7}
          CardSkeletonComponent={
            <Box className={cx(classes.reelCardWrapper, "reelCard__wrapper")}>
              <ReelCardSkeleton />
            </Box>
          }
          cardsScrollAmount={2}
          hideNavBtns={isSmall}
          cards={events.map(event => (
            <Box onClick={handleOnReelCardCLick(event)} key={event?.slug} className={cx(classes.reelCardWrapper, "reelCard__wrapper")}>
              <ReelCard imgSrc={event.image_thumbs.event_small} title={event?.title} />
            </Box>
          ))}
        />
        <Box className={classes.actionsContainer}>
          <Box className={classes.seeAllButton__container}>
            <Typography className={"see-all__title"}>{t("See All")}</Typography>
            <CircularIconButton onClick={handleSeeAll} height={"46px"} className={commonClasses.seeAllButton}>
              <ArrowIcon fill={theme.palette.common.white} />
            </CircularIconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
