import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const FamilyIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "23.414px"}
      height={height ? height : "22.514px"}
      viewBox={viewBox ? viewBox : "0 0 23.414 22.514"}
      {...props}
    >
      <path
        id='icons8-full-family'
        d='M6.6,2A2.7,2.7,0,1,0,9.3,4.7,2.7,2.7,0,0,0,6.6,2ZM21.011,2a2.7,2.7,0,1,0,2.7,2.7A2.7,2.7,0,0,0,21.011,2Zm2.7,3.6a2.977,2.977,0,0,0,.675,2.023,2.977,2.977,0,0,0,2.023.675,2.977,2.977,0,0,0-.675-2.023A2.977,2.977,0,0,0,23.714,5.6ZM5.7,9.2a2.715,2.715,0,0,0-2.7,2.7v5.4a.9.9,0,0,0,.9.913v5.361a.931.931,0,0,0,1.859.062l.394-5.917a.45.45,0,0,1,.9,0l.394,5.915A.931.931,0,0,0,9.3,23.583V18.372a.9.9,0,0,0,1.509.5l.028-.028.647-.646-.382,5.349a.9.9,0,0,0,1.8.127l.005-.065.346-2.436a.557.557,0,0,1,1.1,0l.343,2.411.007.09a.9.9,0,0,0,1.8-.127l-.382-5.35.63.63.014.016,0,0,.026.026a.9.9,0,0,0,1.511-.5v5.215a.931.931,0,0,0,1.859.062l.3-4.534h1.08l.3,4.534a.931.931,0,0,0,1.859-.062V19.11h.9a.9.9,0,0,0,.879-1.1L24.08,11.667A2.834,2.834,0,0,0,22.759,9.86a2.676,2.676,0,0,0-1.618-.644.9.9,0,0,0-.13-.012,3.154,3.154,0,0,0-3.069,2.462l-1.069,4.8a4.693,4.693,0,0,0-2.837-.962h-.457a4.688,4.688,0,0,0-2.673.841l-.528-4.742A2.712,2.712,0,0,0,7.693,9.2Zm8.1,1.8a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,13.807,11.005Z'
        transform='translate(-3 -2)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
