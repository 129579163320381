import { GraphQLError, GraphQLFormattedError } from "graphql";

export const formatGraphQLError = (graphQLErrors: readonly GraphQLError[] | readonly GraphQLFormattedError[]): string => {
  return graphQLErrors
    ?.map(error => {
      return `${error.message || ""}`;
    })
    .join(", ");
};

export const formatMessageErrors = (
  errors:
    | Partial<{
        __typename?: "H_EntityError" | string | undefined;
        field?: string | null | undefined;
        message?: string | null | undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        code?: any;
      }>[]
    | undefined
    | null,
  ignoreField?: boolean
) => {
  return (
    errors
      ?.map(error => {
        if (ignoreField || !error.field) {
          return `${error.message || ""}\r\n`;
        }
        return `${error.field || ""}, ${error.message || ""}\r\n`;
      })!
      .join(", ") || ""
  );
};
