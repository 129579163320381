import {
  appointmentListFiltersFormDefaultValues,
  AppointmentsListFiltersFormSchema,
  IAppointmentsListFiltersFormValues,
} from "../AppointmentsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentFilterFormProps } from "../types";
import { VendorsAutocomplete } from "@health/autocompletes";

export const FacilityFilterForm: React.FC<AppointmentFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IAppointmentsListFiltersFormValues>
      schema={AppointmentsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='vendors'
      dialogTitle={t("Facility")}
      formLabel={t("Facility")}
      render={name => <VendorsAutocomplete name={name} multiple />}
      defaultValues={appointmentListFiltersFormDefaultValues}
    />
  );
};
