import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const ChatMessageIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24.945"}
      height={height ? height : "23.857"}
      viewBox={viewBox ? viewBox : "0 0 24.945 23.857"}
      {...props}
    >
      <path
        id='icons8-chat-message'
        d='M14.543,3.07C7.7,3.07,2.07,7.887,2.07,13.911a10.39,10.39,0,0,0,4.857,8.547,3.142,3.142,0,0,1-.149.876,8.912,8.912,0,0,1-1.352,2.8.506.506,0,0,0,.411.8,7.452,7.452,0,0,0,5.607-2.544,14.2,14.2,0,0,0,3.1.369c6.845,0,12.472-4.817,12.472-10.84S21.387,3.07,14.543,3.07Zm0,1.011C20.915,4.082,26,8.52,26,13.911S20.915,23.74,14.543,23.74a13.256,13.256,0,0,1-3.138-.373.506.506,0,0,0-.514.174,7.611,7.611,0,0,1-4.078,2.205A10.259,10.259,0,0,0,7.755,23.6a5.856,5.856,0,0,0,.22-1.35.506.506,0,0,0-.235-.427,9.437,9.437,0,0,1-4.658-7.909C3.082,8.52,8.169,4.082,14.543,4.082ZM8.559,10.1a.544.544,0,1,0,0,1.088H20.526a.544.544,0,1,0,0-1.088ZM6.384,13.367a.544.544,0,1,0,0,1.088H15.63a.544.544,0,1,0,0-1.088Zm12.51,0a.544.544,0,1,0,0,1.088H22.7a.544.544,0,1,0,0-1.088ZM8.559,16.63a.544.544,0,1,0,0,1.088H20.526a.544.544,0,1,0,0-1.088Z'
        transform='translate(-2.07 -3.07)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
