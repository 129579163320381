import { SvgIcon, useTheme } from "@toolkit/ui";
import React from "react";
import { SvgWithSecondaryColorProps } from "../types";

export const KnowledgeHubIcon: React.FC<SvgWithSecondaryColorProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main, secondaryColor = theme.palette.secondary.main, removeSecondaryColor = false } = props;
  const secondaryColorFill = removeSecondaryColor ? fill : secondaryColor;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-play_4_' data-name='icons8-play_4_' viewBox='0 0 20.082 20.288' {...props}>
      <g id='knowledge_1_' data-name='knowledge (1)' transform='translate(0 -1.4)'>
        <path
          id='Path_23363'
          data-name='Path 23363'
          d='M9.1,19.2a1.861,1.861,0,0,0,3.7,0Z'
          transform='translate(-0.656 -1.178)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23364'
          data-name='Path 23364'
          d='M19.639,18.911H10.755a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555h8.884a.4.4,0,0,0,.37-.37V5.955a.533.533,0,0,1,.555-.555.491.491,0,0,1,.555.555V17.523A1.336,1.336,0,0,1,19.639,18.911Z'
          transform='translate(-0.738 -0.149)'
          fill={fill}
        />
        <path
          id='Path_23365'
          data-name='Path 23365'
          d='M10.516,15.986,10.5,5.806A2.389,2.389,0,0,1,12.906,3.4h6.756a1.462,1.462,0,0,1,1.481,1.481v.74a.533.533,0,0,1-.555.555.491.491,0,0,1-.555-.555v-.74a.4.4,0,0,0-.37-.37H12.906A1.423,1.423,0,0,0,11.518,5.9V15.986C11.518,16.264,10.516,16.356,10.516,15.986Z'
          transform='translate(-0.761)'
          fill={fill}
        />
        <path
          id='Path_23366'
          data-name='Path 23366'
          d='M.848,16.449A.53.53,0,0,1,.3,15.893V4.881A1.452,1.452,0,0,1,1.761,3.4H8.427A2.373,2.373,0,0,1,10.8,5.806c0,1.388-.183.555-.548.555a.488.488,0,0,1-.548-.555A1.4,1.4,0,0,0,8.427,4.418H1.761a.4.4,0,0,0-.365.37V15.893A.53.53,0,0,1,.848,16.449Z'
          fill={fill}
        />
        <path
          id='Path_23367'
          data-name='Path 23367'
          d='M10.295,19.724H1.781A1.462,1.462,0,0,1,.3,18.243V16.855A.533.533,0,0,1,.855,16.3a.491.491,0,0,1,.555.555v1.388a.4.4,0,0,0,.37.37h8.607a.533.533,0,0,1,.555.555.491.491,0,0,1-.555.555Z'
          transform='translate(0 -0.962)'
          fill={fill}
        />
        <path
          id='Path_23368'
          data-name='Path 23368'
          d='M8.055,8.711A.533.533,0,0,1,7.5,8.155c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,8.055,8.711Z'
          transform='translate(-0.537 -0.313)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23369'
          data-name='Path 23369'
          d='M8.055,12.211a.533.533,0,0,1-.555-.555c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,8.055,12.211Z'
          transform='translate(-0.537 -0.574)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23370'
          data-name='Path 23370'
          d='M8.055,15.711a.533.533,0,0,1-.555-.555c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,8.055,15.711Z'
          transform='translate(-0.537 -0.835)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23371'
          data-name='Path 23371'
          d='M5.421,8.711H3.755A.533.533,0,0,1,3.2,8.155.491.491,0,0,1,3.755,7.6H5.421a.533.533,0,0,1,.555.555.491.491,0,0,1-.555.555Z'
          transform='translate(-0.216 -0.313)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23372'
          data-name='Path 23372'
          d='M5.421,12.211H3.755a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555H5.421a.533.533,0,0,1,.555.555.491.491,0,0,1-.555.555Z'
          transform='translate(-0.216 -0.574)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23373'
          data-name='Path 23373'
          d='M5.421,15.711H3.755a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555H5.421a.533.533,0,0,1,.555.555.491.491,0,0,1-.555.555Z'
          transform='translate(-0.216 -0.835)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23374'
          data-name='Path 23374'
          d='M18.355,8.711a.533.533,0,0,1-.555-.555c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,18.355,8.711Z'
          transform='translate(-1.305 -0.313)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23375'
          data-name='Path 23375'
          d='M18.355,12.211a.533.533,0,0,1-.555-.555c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,18.355,12.211Z'
          transform='translate(-1.305 -0.574)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23376'
          data-name='Path 23376'
          d='M18.355,15.711a.533.533,0,0,1-.555-.555c0-.37.185-.555.463-.555h.093a.533.533,0,0,1,.555.555A.491.491,0,0,1,18.355,15.711Z'
          transform='translate(-1.305 -0.835)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23377'
          data-name='Path 23377'
          d='M15.721,8.711H14.055a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555h1.666a.533.533,0,0,1,.555.555A.491.491,0,0,1,15.721,8.711Z'
          transform='translate(-0.984 -0.313)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23378'
          data-name='Path 23378'
          d='M15.721,12.211H14.055a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555h1.666a.533.533,0,0,1,.555.555A.491.491,0,0,1,15.721,12.211Z'
          transform='translate(-0.984 -0.574)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_23379'
          data-name='Path 23379'
          d='M15.721,15.711H14.055a.533.533,0,0,1-.555-.555.491.491,0,0,1,.555-.555h1.666a.533.533,0,0,1,.555.555A.491.491,0,0,1,15.721,15.711Z'
          transform='translate(-0.984 -0.835)'
          fill={secondaryColorFill}
        />
      </g>
    </SvgIcon>
  );
};
