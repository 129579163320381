import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const PharmacyIcon: FC<IconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "62.609"}
      height={height ? height : "60"}
      viewBox={viewBox ? viewBox : "0 0 62.609 60"}
      {...props}
    >
      <path
        id='icons8-pharmacy-shop_2_'
        data-name='icons8-pharmacy-shop (2)'
        d='M32.258,1a1.3,1.3,0,0,0-.759.278L5.463,21.655,1.5,24.756a1.3,1.3,0,1,0,1.61,2.053l1.8-1.411V59.7a1.3,1.3,0,0,0,1.3,1.3H58.391a1.3,1.3,0,0,0,1.3-1.3V25.4l1.8,1.411a1.3,1.3,0,1,0,1.61-2.053l-3.893-3.044L33.109,1.278A1.3,1.3,0,0,0,32.258,1ZM32.3,3.96,57.086,23.355V58.391H7.522V23.355Zm6.379,15.3a10.694,10.694,0,0,0-7.406,3.06q-.041.039-.079.082c.06-.069-.269.281-.673.69s-.956.967-1.607,1.623c-1.3,1.311-3,3.015-4.7,4.721s-3.4,3.413-4.715,4.736c-.657.661-1.218,1.227-1.633,1.648-.392.4-.588.6-.752.777a10.483,10.483,0,0,0,7.434,17.881,10.616,10.616,0,0,0,7.406-3.06l.018-.018,14.2-14.248a10.462,10.462,0,0,0-.01-14.824l-.008-.008A10.749,10.749,0,0,0,38.683,19.261Zm0,2.609a8.083,8.083,0,0,1,5.661,2.323,7.808,7.808,0,0,1-.013,11.117h0l-5.505,5.526L27.68,29.655c1.085-1.089,2.233-2.238,3.09-3.1.653-.657,1.207-1.216,1.615-1.63.367-.373.533-.539.7-.726A8.067,8.067,0,0,1,38.683,21.869ZM25.841,31.5l11.14,11.179-6.835,6.858a7.892,7.892,0,0,1-5.594,2.328,7.812,7.812,0,0,1-5.579-2.311l-.008-.008a7.808,7.808,0,0,1,.008-11.122q.028-.027.053-.056c-.026.029.293-.3.7-.721s.967-.981,1.623-1.641C22.6,34.754,24.223,33.128,25.841,31.5Zm-1.363,3.409a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,24.478,34.913Zm-3.913,3.913a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,20.565,38.826Zm7.826,0a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,28.391,38.826Zm-3.913,3.913a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,24.478,42.739Zm7.826,0a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,32.3,42.739ZM20.565,46.652a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,20.565,46.652Zm7.826,0a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,28.391,46.652Z'
        transform='translate(-1 -0.999)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
