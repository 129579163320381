import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SleepIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      viewBox={viewBox ? viewBox : "0 0 22 22"}
      {...props}
    >
      <path
        id='icons8-sleep'
        d='M4,4V6h7.125L4,12v2H14V12H6.875L14,6V4Zm14,6v2h5.2L18,18v2h8V18H20.859L26,12V10ZM8,18v2h4.031L8.008,24,8,24.01V26h7V24H11.023L15,20V18Z'
        transform='translate(-4 -4)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
