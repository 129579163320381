import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PillSmallIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24.945"}
      height={height ? height : "23.861"}
      viewBox={viewBox ? viewBox : "0 0 24.945 23.861"}
      {...props}
    >
      <path
        id='icons8-pills_2_'
        data-name='icons8-pills (2)'
        d='M7.965,3A5.986,5.986,0,0,0,2,8.988H2c0,.484,0,11.5,0,11.881H2a5.963,5.963,0,0,0,10.879,3.4A8.136,8.136,0,1,0,13.93,12.234c0-1.151,0-2.872,0-3.244h0A5.986,5.986,0,0,0,7.965,3Zm0,1.085a4.885,4.885,0,0,1,4.881,4.9c0,.5,0,3.7,0,4.227a8.263,8.263,0,0,0-.918,1.2.542.542,0,0,0-.166-.024H3.085c0-2.574,0-5.165,0-5.4A4.884,4.884,0,0,1,7.965,4.085Zm10.846,7.592a7.05,7.05,0,1,1-7.05,7.05A7.042,7.042,0,0,1,18.811,11.677Zm3.824,2.668a.542.542,0,0,0-.373.164l-7.668,7.668a.542.542,0,1,0,.767.767l7.668-7.668a.542.542,0,0,0-.394-.931ZM3.085,15.473h8.285a7.979,7.979,0,0,0-.377,5.474.541.541,0,0,0,.359,1.017,8.121,8.121,0,0,0,.8,1.421,4.943,4.943,0,0,1-.646.846.543.543,0,0,0-.785.676,4.825,4.825,0,0,1-5.516.007.541.541,0,0,0-.785-.679,4.891,4.891,0,0,1-1.336-3.363h0c0-.019,0-.461,0-.518a.542.542,0,0,0,0-1.085c0-.526,0-1.258,0-2.169a.542.542,0,1,0,0-1.085C3.085,15.763,3.085,15.731,3.085,15.473Zm3.254.542a.542.542,0,1,0,.542.542A.542.542,0,0,0,6.338,16.015Zm3.254,0a.542.542,0,1,0,.542.542A.542.542,0,0,0,9.592,16.015ZM4.711,17.642a.542.542,0,1,0,.542.542A.542.542,0,0,0,4.711,17.642Zm3.254,0a.542.542,0,1,0,.542.542A.542.542,0,0,0,7.965,17.642ZM6.338,19.269a.542.542,0,1,0,.542.542A.542.542,0,0,0,6.338,19.269Zm3.254,0a.542.542,0,1,0,.542.542A.542.542,0,0,0,9.592,19.269ZM4.711,20.9a.542.542,0,1,0,.542.542A.542.542,0,0,0,4.711,20.9Zm3.254,0a.542.542,0,1,0,.542.542A.542.542,0,0,0,7.965,20.9ZM6.338,22.522a.542.542,0,1,0,.542.542A.542.542,0,0,0,6.338,22.522Zm3.254,0a.542.542,0,1,0,.542.542A.542.542,0,0,0,9.592,22.522ZM7.965,24.149a.542.542,0,1,0,.542.542A.542.542,0,0,0,7.965,24.149Z'
        transform='translate(-2 -3)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
