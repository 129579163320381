import { IconButton, useMediaQuery } from "@toolkit/ui";
import { useStyle } from "./HeaderSearchStyle";
import { FC } from "react";
import { SearchIcon } from "../Icons";
import { useTranslation } from "@toolkit/i18n";
import { RoundedTextField } from "../TextFields";
import useHeaderStyle from "../PageHeader/PageHeader.styles";

type HeaderSearchProps = {
  classes?: Partial<ReturnType<typeof useHeaderStyle>["classes"]>;
};

export const HeaderSearch: FC<HeaderSearchProps> = props => {
  const { classes: _classes } = props;
  const { theme, cx } = useStyle();
  const { t } = useTranslation("consumer");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return isSmallScreen ? (
    <IconButton className={cx(_classes?.iconButton)}>
      <SearchIcon />
    </IconButton>
  ) : (
    <RoundedTextField
      sx={{
        marginInline: "20px",
        width: "100%",
      }}
      placeholder={t("Search Services, doctors, videos…")}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
};
