import React, { FC } from "react";
import { useTheme } from "../../base/mui";

export const TwitterIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
      <path
        id='icons8-twitter-circled_1_'
        data-name='icons8-twitter-circled (1)'
        d='M18,3A15,15,0,1,0,33,18,15,15,0,0,0,18,3Zm8.08,11.919c.007.166.011.331.011.5A10.939,10.939,0,0,1,9.25,24.631a7.792,7.792,0,0,0,5.695-1.6,3.851,3.851,0,0,1-3.594-2.671,3.861,3.861,0,0,0,1.737-.066A3.848,3.848,0,0,1,10,16.527v-.049a3.822,3.822,0,0,0,1.742.481,3.852,3.852,0,0,1-1.191-5.135,10.921,10.921,0,0,0,7.929,4.02,3.89,3.89,0,0,1-.1-.876,3.848,3.848,0,0,1,6.654-2.632,7.732,7.732,0,0,0,2.444-.934,3.862,3.862,0,0,1-1.692,2.129A7.687,7.687,0,0,0,28,12.926,7.755,7.755,0,0,1,26.08,14.919Z'
        transform='translate(-3 -3)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
