import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const NoseIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20px"}
      height={height ? height : "27px"}
      viewBox={viewBox ? viewBox : "0 0 20 27"}
      {...props}
    >
      <path
        id='icons8-runny-nose'
        d='M11,2V5c0,3.372-.417,5.417-2,7-1.651.138-4,1.661-4,4,0,3,3.381,3,4,3,1,0,2,3,6,3s5-3,6-3c.619,0,4,0,4-3,0-2.339-2.349-3.862-4-4-1.583-1.583-2-3.628-2-7V2ZM21,21s-2,2.9-2,4a2,2,0,0,0,4,0C23,23.9,21,21,21,21ZM10,23s-2,2.9-2,4a2,2,0,0,0,4,0C12,25.9,10,23,10,23Z'
        transform='translate(-5 -2)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
