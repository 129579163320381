import { useConsumerAppointmentCreateForAnonymousMutation, useConsumerAppointmentCreateMutation } from "../../gql/mutations";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useAuth } from "react-oidc-context";
import { AppointmentType } from "@health/queries/types";
import { IDoctorInfo, IFormattedTimeSlot } from "../../types/types";
import { IAppointmentConfirmationFormValues } from "../../forms/AppointmentConfirmation/AppointmentConfirmationFormSchema";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@/i18n/i18n.config";
import { isNumber, toNumber } from "lodash";
import { AppointmentScheduleSuccessContainerData } from "../AppointmentCreateSuccessContainer/type";
export const useAppointmentCreation = (
  visitType: string,
  selectedTimeSlots: IFormattedTimeSlot[],
  doctorInfo?: IDoctorInfo,
  guestInfo?: IAppointmentConfirmationFormValues,
  otp?: string
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { failed } = useAddToast();
  const [createAppointmentForGuest, { loading: loadingForGuest }] = useConsumerAppointmentCreateForAnonymousMutation({
    onCompleted: data => {
      const appointment = data?.consumerAppointmentCreateForAnonymous?.appointment;
      if (appointment?.id) {
        navigate(routes.createSuccess.absRoute, {
          state: {
            doctorInfo: doctorInfo,
            selectedTimeSlots: appointment.requestedTimeSlots,
            visitType: visitType,
          } as AppointmentScheduleSuccessContainerData,
        });
      } else {
        failed(t("Failed to book appointment"));
      }
    },
    onError: () => {
      failed(t("Failed to book appointment"));
    },
  });

  const [createAppointment, { loading: loadingForUser }] = useConsumerAppointmentCreateMutation({
    onCompleted: data => {
      const appointment = data?.consumerAppointmentCreate?.appointment;
      if (appointment?.id) {
        navigate(routes.createSuccess.absRoute, {
          state: {
            doctorInfo: doctorInfo,
            selectedTimeSlots: selectedTimeSlots?.map(slot => JSON.stringify(slot)),
            visitType: visitType,
          } as AppointmentScheduleSuccessContainerData,
        });
      } else {
        failed(t("Failed to book appointment"));
      }
    },
    onError: () => {
      failed(t("Failed to book appointment"));
    },
  });

  const createAppointmentHandler = () => {
    if (!auth?.isAuthenticated) {
      createAppointmentForGuest({
        variables: {
          input: {
            requestedTimeSlots: selectedTimeSlots.map(slot => ({
              startTime: slot.startTime.toISOString(),
              endTime: slot.endTime.toISOString(),
            })),
            type: visitType as AppointmentType,
            doctor: doctorInfo?.doctorId || "",
            nationalId: guestInfo?.nationalId,
            name: guestInfo?.name || "",
            phone: guestInfo?.phone || "",
            insuranceCompanyName: guestInfo?.insuranceCompanyName || "",
            insuranceId: guestInfo?.insuranceId || "",
            verificationCode: isNumber(otp) ? toNumber(otp) : undefined!,
            nationality: guestInfo?.nationality || "",
          },
        },
      });
    } else {
      createAppointment({
        variables: {
          input: {
            requestedTimeSlots: selectedTimeSlots.map(slot => ({
              startTime: slot.startTime.toISOString(),
              endTime: slot.endTime.toISOString(),
            })),
            type: visitType as AppointmentType,
            doctor: doctorInfo?.doctorId || "",
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${auth?.user?.access_token}`,
          },
        },
      });
    }
  };

  return { createAppointmentHandler, isSubmitting: loadingForGuest || loadingForUser };
};
