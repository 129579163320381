import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const DependentsIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.625' height='13.516' viewBox='0 0 21.625 13.516' {...props}>
      <path
        id='icons8-baby-face'
        d='M6.983,9a.676.676,0,0,0-.159.018A6.285,6.285,0,0,0,1.03,14.331a1.838,1.838,0,0,0,.077,3.337,6.294,6.294,0,0,0,1.377,2.715A.676.676,0,1,0,3.5,19.489a4.939,4.939,0,0,1-1.151-2.336.676.676,0,0,0-.49-.525.663.663,0,0,1-.034-1.278.676.676,0,0,0,.461-.571,4.945,4.945,0,0,1,4.755-4.419.237.237,0,0,1,.187.084.516.516,0,0,1,.1.312.61.61,0,0,1-.111.355.341.341,0,0,1-.285.144.676.676,0,1,0,.12,1.346,1.773,1.773,0,0,0,1.232-.661,1.915,1.915,0,0,0,.4-1.183,1.818,1.818,0,0,0-.409-1.18,1.611,1.611,0,0,0-.649-.451A.676.676,0,0,0,7.208,9c-.054,0-.106.006-.16.007C7.026,9.006,7.005,9,6.983,9Zm7.208,0a.676.676,0,0,0-.345.09,6.4,6.4,0,0,0-1.377.216.676.676,0,1,0,.417,1.286,4.975,4.975,0,0,1,1.37-.215.29.29,0,0,1,.176.067.516.516,0,0,1,.1.312.61.61,0,0,1-.111.355.341.341,0,0,1-.285.144.676.676,0,1,0,.12,1.346,1.773,1.773,0,0,0,1.232-.661,1.915,1.915,0,0,0,.4-1.183c0-.045-.018-.09-.021-.136a4.906,4.906,0,0,1,3.485,4.156.676.676,0,0,0,.461.571.663.663,0,0,1-.034,1.278.676.676,0,0,0-.49.525,4.958,4.958,0,0,1-6.248,3.815.676.676,0,1,0-.377,1.3,6.263,6.263,0,0,0,7.85-4.587,1.841,1.841,0,0,0,.092-3.347A6.282,6.282,0,0,0,14.417,9c-.046,0-.086.019-.131.02S14.225,9,14.191,9Zm-4.207.721a.676.676,0,0,0-.343,1.268,4.957,4.957,0,0,1,2.494,3.79.676.676,0,0,0,.461.571.663.663,0,0,1-.034,1.278.676.676,0,0,0-.49.525,4.959,4.959,0,0,1-6.2,3.83.676.676,0,1,0-.363,1.3,6.25,6.25,0,0,0,7.47-3.652,1.785,1.785,0,0,0,3.04-.265.253.253,0,0,0-.232-.357H13.2c.033-.11.068-.219.1-.332a1.837,1.837,0,0,0,.117-3.329,6.3,6.3,0,0,0-3.106-4.538.676.676,0,0,0-.322-.092ZM5.406,14.406a.9.9,0,1,0,.9.9A.9.9,0,0,0,5.406,14.406Zm3.6,0a.9.9,0,1,0,.9.9A.9.9,0,0,0,9.01,14.406Zm7.208,0a.9.9,0,1,0,.9.9A.9.9,0,0,0,16.219,14.406ZM5.836,18.01a.252.252,0,0,0-.232.356,1.792,1.792,0,0,0,3.21,0,.253.253,0,0,0-.232-.357Z'
        transform='translate(0 -9)'
        fill={fill}
      />
    </SvgIcon>
  );
};
