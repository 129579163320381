import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  card: {
    display: "flex",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginBlock: "10px",
    marginInline: "0px",
  },
  container: {
    display: "flex",
    width: "100%",
    height: "fit-content",
  },
  avatarsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
  vendorAvatarContainer: {
    marginTop: "5px",
    width: "calc(100% - 10px)",
    minHeight: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexWrap: "wrap",
    paddingInlineStart: "10px",
  },
  textColumn: {
    display: "flex",
    alignContent: "start",
    flexDirection: "column",
    textAlign: "start",
    paddingInlineStart: 2,
    width: "calc(100% - 23px)",
    paddingBottom: "10px",
  },
  actionsContainer: {
    width: "max-content",
    display: "flex",
    alignSelf: "center",
    gap: "20px",
    flexWrap: "wrap",
    marginInlineEnd: "40px",
  },
}));
