import { SvgIcon, useTheme } from "@toolkit/ui";
import React from "react";
import { SvgWithSecondaryColorProps } from "../types";

export const WatchIcon: React.FC<SvgWithSecondaryColorProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main, secondaryColor = theme.palette.secondary.main, removeSecondaryColor = false } = props;
  const secondaryColorFill = removeSecondaryColor ? fill : secondaryColor;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-play_4_' data-name='icons8-play_4_' viewBox='0 0 24.75 22.563' {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='icons8-play_4_' data-name='icons8-play (4)' transform='translate(0 0)'>
        <path
          id='Path_12707'
          data-name='Path 12707'
          d='M27.282,22.516a.887.887,0,0,1-.746-1.367,9.8,9.8,0,0,0,1.515-5.317A10.078,10.078,0,0,0,14.021,6.582a9.529,9.529,0,0,0-3.188,2.226A.887.887,0,0,1,9.542,7.591a11.293,11.293,0,0,1,3.778-2.639A11.853,11.853,0,0,1,29.827,15.831a11.567,11.567,0,0,1-1.8,6.277A.887.887,0,0,1,27.282,22.516Z'
          transform='translate(-6.165 -4)'
          fill={fill}
        />
        <path
          id='Path_12708'
          data-name='Path 12708'
          d='M15.831,33.707A11.844,11.844,0,0,1,4,21.876a13.816,13.816,0,0,1,.249-2.335A.887.887,0,0,1,6,19.833a12.256,12.256,0,0,0-.225,2.043A10.068,10.068,0,0,0,15.831,31.932a10.2,10.2,0,0,0,5.388-1.523.887.887,0,1,1,.936,1.508A11.976,11.976,0,0,1,15.831,33.707Z'
          transform='translate(-4 -10.045)'
          fill={fill}
        />
        <path
          id='Path_12709'
          data-name='Path 12709'
          d='M18.471,15.159h0a1.5,1.5,0,0,1,.726.191l7.115,3.736a1.5,1.5,0,0,1,0,2.606l-.023.013-7.132,3.8-.035.015a1.6,1.6,0,0,1-.632.131A1.47,1.47,0,0,1,17,24.175V16.662a1.508,1.508,0,0,1,.429-1.064A1.45,1.45,0,0,1,18.471,15.159Zm6.511,5.232-6.208-3.259V23.7Z'
          transform='translate(-9.31 -8.558)'
          fill={secondaryColorFill}
        />
      </g>
    </SvgIcon>
  );
};
