import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PrescriptionPill: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "90"}
      height={height ? height : "86.13"}
      viewBox={viewBox ? viewBox : "0 0 90 86.13"}
      {...props}
    >
      <path
        id='icons8-pills_8_'
        data-name='icons8-pills (8)'
        d='M23.283,3A21.357,21.357,0,0,0,2,24.366v0C2,26.1,2,65.412,2,66.757v0A21.277,21.277,0,0,0,40.813,78.9a29.028,29.028,0,1,0,3.752-42.958c0-4.106,0-10.246,0-11.575v0A21.358,21.358,0,0,0,23.283,3Zm0,3.87A17.427,17.427,0,0,1,40.7,24.362c0,1.767,0,13.217,0,15.081a29.481,29.481,0,0,0-3.276,4.274,1.935,1.935,0,0,0-.593-.087H5.87c0-9.185,0-18.428,0-19.268A17.426,17.426,0,0,1,23.283,6.87Zm38.7,27.087A25.152,25.152,0,1,1,36.826,59.109,25.123,25.123,0,0,1,61.978,33.957ZM75.62,43.476a1.935,1.935,0,0,0-1.33.586L46.931,71.42a1.935,1.935,0,1,0,2.736,2.736L77.026,46.8a1.935,1.935,0,0,0-1.406-3.322ZM5.87,47.5H35.428a28.467,28.467,0,0,0-1.345,19.529,1.931,1.931,0,0,0,1.281,3.628,28.975,28.975,0,0,0,2.857,5.071,17.634,17.634,0,0,1-2.305,3.019,1.936,1.936,0,0,0-2.8,2.411,17.216,17.216,0,0,1-19.68.027,1.93,1.93,0,0,0-2.8-2.422,17.45,17.45,0,0,1-4.765-12v0c0-.067,0-1.645,0-1.848a1.935,1.935,0,0,0,0-3.87c0-1.878,0-4.489,0-7.739a1.935,1.935,0,1,0,0-3.87C5.87,48.536,5.87,48.421,5.87,47.5Zm11.609,1.935a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,17.478,49.435Zm11.609,0a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,29.087,49.435Zm-17.413,5.8a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,11.674,55.239Zm11.609,0a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,23.283,55.239Zm-5.8,5.8a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,17.478,61.043Zm11.609,0a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,29.087,61.043Zm-17.413,5.8a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,11.674,66.848Zm11.609,0a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,23.283,66.848Zm-5.8,5.8a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,17.478,72.652Zm11.609,0a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,29.087,72.652Zm-5.8,5.8a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,23.283,78.457Z'
        transform='translate(-1.5 -2.5)'
        fill={palette.common.white}
        stroke={palette.common.white}
        strokeWidth='1'
      />
    </SvgIcon>
  );
};
