import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PharmacyOrdersIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='44.489' height='44.632' viewBox='0 0 44.489 44.632' {...props}>
      <path
        id='icons8-pills_2_'
        data-name='icons8-pills (2)'
        d='M33.48,3.014a15.236,15.236,0,0,0-1.6.018A15.427,15.427,0,0,0,22.05,7.685L7.666,22.069C1.931,27.8,1.41,37.234,7.023,43.089A14.723,14.723,0,0,0,24.81,45.78a14.843,14.843,0,0,1,2.484-21.049l-7.2-7.2a1.858,1.858,0,0,1,0-2.63l4.954-4.954A10.982,10.982,0,0,1,42.5,22.908a14.849,14.849,0,0,1,3.211,1.965A14.722,14.722,0,0,0,33.48,3.014Zm2.928,22.3A11.158,11.158,0,1,0,47.566,36.474,11.158,11.158,0,0,0,36.408,25.316Zm-3.945,5.35a1.855,1.855,0,0,1,1.315.548L41.668,39.1a1.86,1.86,0,0,1-2.63,2.63l-7.889-7.889a1.863,1.863,0,0,1,1.315-3.178Z'
        transform='translate(-3.078 -3.001)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
export default PharmacyOrdersIcon;
