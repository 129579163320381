import { getApolloContextFromToken } from "@/providers/auth";
import { VisitStatus } from "@health/queries/types";
import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import moment from "moment";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useMeVisitsCallInProgressQuery, useVisitCallEndMutation, useVisitCallRejoinMutation } from "../../gql";
import useVisitStartingNowNotification from "../../hooks/useVisitStartingNowNotification";
import { openMeetingPlatformLink, ReJoinableVisitStatuses } from "../../utils";

type UseVisitStartingNowCardProps = {
  isBlockedPage?: boolean;
};

export const useVisitStartingNowCard = ({ isBlockedPage }: UseVisitStartingNowCardProps) => {
  const { startNotificationSound, stopNotificationSound } = useVisitStartingNowNotification();
  const { user } = useAuth();
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("consumer");

  const { data, refetch } = useMeVisitsCallInProgressQuery({
    skip: !user?.access_token || isBlockedPage,
    nextFetchPolicy: "cache-and-network",
    context: getApolloContextFromToken(user?.access_token),
  });
  const activeCall = data?.me?.visits?.edges[0]?.node;

  const [visitCallEnd, { loading: isCallEndSubmitting }] = useVisitCallEndMutation({
    onCompleted: request => {
      const visitErrors = request?.visitCallEnd?.visitErrors;
      if (visitErrors?.length === 0) {
        succeeded(t("Visit call ended successfully"));
        stopNotificationSound();
        refetch();
      } else {
        formatMessageErrors(visitErrors);
        failed(t("Visit Call End Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFromToken(user?.access_token),
  });

  const [rejoinCallMutation] = useVisitCallRejoinMutation({
    context: getApolloContextFromToken(user?.access_token),
  });

  const handleJoin = (meetingPlatformLinkForJoinCall?: string | null) => {
    stopNotificationSound();
    meetingPlatformLinkForJoinCall && openMeetingPlatformLink(meetingPlatformLinkForJoinCall);
  };

  const handleJoinNow = () => {
    if (activeCall?.status === VisitStatus.CallInProgress && activeCall?.meetingPlatformLinkForJoinCall) {
      openMeetingPlatformLink(activeCall?.meetingPlatformLinkForJoinCall);
      stopNotificationSound();
    } else if (activeCall?.status && ReJoinableVisitStatuses.includes(activeCall?.status)) {
      rejoinCallMutation({
        variables: { visitId: activeCall?.id || "" },
        onCompleted: request => {
          const visitResponse = request?.visitCallRejoin;
          if (visitResponse?.visitErrors?.length === 0) {
            failed(formatMessageErrors(visitResponse?.visitErrors));
          } else {
            handleJoin(visitResponse?.visit?.meetingPlatformLinkForJoinCall);
          }
        },
        onError: ({ graphQLErrors }) => {
          failed(formatGraphQLError(graphQLErrors));
        },
      });
    } else if (activeCall?.meetingPlatformLinkForJoinCall) {
      handleJoin(activeCall?.meetingPlatformLinkForJoinCall);
    } else {
      refetch();
    }
  };

  const handleDecline = () => {
    visitCallEnd({
      variables: { visitId: activeCall?.id || "" },
    });
  };

  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      refetch();
    }
  });

  useEffect(() => {
    if (!activeCall) return;
    const consumerJoinedDate = activeCall.consumerJoinedDate ? moment(activeCall.consumerJoinedDate) : null;
    const consumerLeftDate = activeCall.consumerLeftDate ? moment(activeCall.consumerLeftDate) : null;

    const shouldPlaySound =
      !activeCall.consumerJoined ||
      (activeCall.consumerLeft && consumerLeftDate && consumerJoinedDate && consumerLeftDate.isAfter(consumerJoinedDate));

    if (shouldPlaySound) {
      startNotificationSound();
    } else {
      stopNotificationSound();
    }
  }, [activeCall]);

  return { activeCall, isCallEndSubmitting, handleJoinNow, handleDecline };
};
