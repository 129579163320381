import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PlusAddIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20.932"}
      height={height ? height : "20.932"}
      viewBox={viewBox ? viewBox : "0 0 20.932 20.932"}
      {...props}
    >
      <path
        id='icons8-plus_1_'
        data-name='icons8-plus (1)'
        d='M13.466,3A10.466,10.466,0,1,0,23.932,13.466,10.466,10.466,0,0,0,13.466,3ZM18.7,14.338H14.338V18.7a.872.872,0,1,1-1.744,0V14.338H8.233a.872.872,0,0,1,0-1.744h4.361V8.233a.872.872,0,0,1,1.744,0v4.361H18.7a.872.872,0,0,1,0,1.744Z'
        transform='translate(-3 -3)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
