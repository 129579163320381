import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const MenuIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme?.palette?.primary?.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.527' height='10.786' viewBox='0 0 17.527 10.786' {...props}>
      <g id='Group_10314' data-name='Group 10314' transform='translate(13.25 -9350)'>
        <path
          id='icons8-menu_3_'
          data-name='icons8-menu (3)'
          d='M2.674,7a.674.674,0,1,0,0,1.348H18.853a.674.674,0,1,0,0-1.348Zm0,9.438a.674.674,0,1,0,0,1.348H18.853a.674.674,0,1,0,0-1.348Z'
          transform='translate(-15.25 9343)'
          fill={fill}
        />
        <path
          id='icons8-menu_3_2'
          data-name='icons8-menu (3)'
          d='M2.674,21.134a.674.674,0,1,0,0,1.348H18.853a.674.674,0,1,0,0-1.348Z'
          transform='translate(-15.25 9333.584)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
