import { useAppointmentConfirmationDataQuery } from "../../gql/queries";
import { useAuth } from "react-oidc-context";
import { AppointmentType, IGuestInfo } from "../../types/types";
import { Coverage, VisitCareType } from "@health/queries/types";

type IData = {
  contactInfo?: {
    email?: string;
    mobile?: string;
  };
  insuranceInfo?: {
    insuranceId?: string;
    companyName?: string;
  };
  paymentInfo?: {
    hasCoverage?: boolean;
  };
};

export const useAppointmentConfirmationData = (doctorId?: string, visitType?: AppointmentType, guestInfo?: IGuestInfo) => {
  const { isAuthenticated, user } = useAuth();
  const visitTypeMapper = {
    AT_HOME: VisitCareType.HomeVisit,
    ONLINE: VisitCareType.Virtual,
    ONSITE: VisitCareType.InPerson,
  };

  const { data: _data, loading: _loading } = useAppointmentConfirmationDataQuery({
    variables: {
      doctorId: doctorId!,
      visitCareType: visitTypeMapper[visitType!],
    },
    skip: !doctorId || !visitType || !isAuthenticated,
    context: {
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  });

  const loading = _loading || false;
  const data: IData = {};

  if (isAuthenticated) {
    data["contactInfo"] = {
      email: _data?.me?.email || "-",
      mobile: _data?.me?.mobile || "-",
    };
    data["paymentInfo"] = {
      hasCoverage: _data?.patientShare?.coverage ? _data?.patientShare?.coverage === Coverage.FullyCovered : undefined,
    };
  } else {
    data["contactInfo"] = {
      mobile: guestInfo?.phone,
    };
    data["insuranceInfo"] = {
      insuranceId: guestInfo?.insuranceId,
      companyName: guestInfo?.insuranceCompanyName,
    };
  }

  return { data, loading, isAuthenticated };
};
