import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const ConnectToCareTeamIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='23.083' height='16.438' viewBox='0 0 23.083 16.438'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_5894' data-name='Rectangle 5894' width='23.083' height='16.438' transform='translate(0 0)' fill={fill} />
        </clipPath>
      </defs>
      <g id='Group_10149' data-name='Group 10149' clip-path='url(#clip-path)'>
        <path
          id='Path_12628'
          data-name='Path 12628'
          d='M93.706,46.927a4.355,4.355,0,0,0,.321-.673,8.686,8.686,0,0,0,.618-3.914,2.439,2.439,0,0,0-.865-1.754,2.391,2.391,0,0,0-1.755-.381c-.437.07-.8.1-1.1.119a3.713,3.713,0,0,0-.71.091.362.362,0,0,0-.3.424.348.348,0,0,0,.348.263c.176-.007.352-.017.531-.027a9.584,9.584,0,0,1,1.711.006c.726.092,1.178.749,1.274,1.852a7.074,7.074,0,0,1-.812,3.345l-.062.148v-1.9a.45.45,0,1,0-.9,0v3.906h1.077a5.807,5.807,0,0,1,.619-1.506'
          transform='translate(-71.604 -31.994)'
          fill={fill}
        />
        <path
          id='Path_12629'
          data-name='Path 12629'
          d='M2.716,47.895V44.229a.473.473,0,0,0-.947,0v1.905C.754,43.945.472,42.407.934,41.562a2.021,2.021,0,0,1,1.67-.844,7.216,7.216,0,0,1,1.4.023.442.442,0,0,0,.493-.463.445.445,0,0,0-.4-.417c-.349-.033-.892-.075-1.492-.084A2.481,2.481,0,0,0,.177,41.584C-.205,42.8.064,43.649.66,45.3a13.018,13.018,0,0,0,.854,1.975,1.494,1.494,0,0,1,.234.842h.967v-.222'
          transform='translate(0 -31.678)'
          fill={fill}
        />
        <path
          id='Path_12630'
          data-name='Path 12630'
          d='M10.5,13.209A3.129,3.129,0,0,0,12.7,14.223h.058a3.089,3.089,0,0,0,.016-6.177,3.061,3.061,0,0,0-2.27,1.028,2.687,2.687,0,0,0-.8,2.05,2.721,2.721,0,0,0,.792,2.085m.764-3.675a1.925,1.925,0,0,1,1.34-.513l.108,0h.014a1.726,1.726,0,0,1,1.372.443,2.344,2.344,0,0,1,.816,1.667,2.2,2.2,0,0,1-.691,1.527,2.005,2.005,0,0,1-1.446.616,2.182,2.182,0,0,1-1.512-3.741'
          transform='translate(-7.721 -6.406)'
          fill={fill}
        />
        <path
          id='Path_12631'
          data-name='Path 12631'
          d='M76.65,14.24h0l.1,0a3.013,3.013,0,0,0,2.145-.966,2.762,2.762,0,0,0,.826-2.1,2.721,2.721,0,0,0-.775-2.061,3.128,3.128,0,0,0-2.187-1.051h-.029a3.086,3.086,0,1,0-.082,6.171m.046-5.2h.05a2.054,2.054,0,0,1,1.506.578,2.125,2.125,0,0,1,.585,1.561,2.132,2.132,0,0,1-3.668,1.46,2.062,2.062,0,0,1-.645-1.462A2.109,2.109,0,0,1,76.7,9.044'
          transform='translate(-58.607 -6.426)'
          fill={fill}
        />
        <path
          id='Path_12632'
          data-name='Path 12632'
          d='M32.321,40.9l0-.726a.481.481,0,0,0-.962,0c0,.292,0,.589,0,.876,0,.05,0,.1,0,.151a8.209,8.209,0,0,1-.72-3.746,1.491,1.491,0,0,1,.561-1.023,1.022,1.022,0,0,1,.819-.248c.194.035.393.076.623.129v1.431a.866.866,0,0,0,.634,1.61.866.866,0,0,0,.056-1.61V36.479a7.072,7.072,0,0,0,3.757.06v1.053a1.356,1.356,0,0,0-1.007,1.3v.252a.336.336,0,0,0,.673,0v-.252a.67.67,0,0,1,1.341,0v.252a.336.336,0,1,0,.672,0v-.252a1.355,1.355,0,0,0-1.007-1.3V36.368c.187-.052.371-.106.559-.166a.752.752,0,0,1,.695.106,1.3,1.3,0,0,1,.464,1.046v.03a9.4,9.4,0,0,1-.122,1.9c-.126.615-.316,1.229-.5,1.823-.042.136-.085.274-.127.413-.029-.356-.051-.925-.051-1.371a.447.447,0,1,0-.894,0v4.348h.877a9.029,9.029,0,0,1,.6-2.41l.124-.3a9.8,9.8,0,0,0,1.059-4.3,2.407,2.407,0,0,0-.881-1.837,1.734,1.734,0,0,0-1.515-.251c-2.876.758-3.977.359-4.781.067a2.8,2.8,0,0,0-1.051-.235,2.511,2.511,0,0,0-2.562,2.6A9.964,9.964,0,0,0,30.583,42c.048.114.1.224.143.332a6.789,6.789,0,0,1,.65,2.158h.95c0-1.123,0-2.482,0-3.593m.665-2.647a.281.281,0,1,1-.281.281.281.281,0,0,1,.281-.281'
          transform='translate(-23.617 -28.056)'
          fill={fill}
        />
        <path
          id='Path_12633'
          data-name='Path 12633'
          d='M44.99,6.2H45A3.051,3.051,0,0,0,47.175,5.3a3.084,3.084,0,0,0,.9-2.173A3.021,3.021,0,0,0,47.185.914,3,3,0,0,0,44.964,0a3.042,3.042,0,0,0-3.039,3.113A3.052,3.052,0,0,0,44.99,6.2m-1.55-4.61A2.1,2.1,0,0,1,44.98.934h.041a2.129,2.129,0,0,1,2.106,2.273,2.145,2.145,0,0,1-2.133,2.061h-.017a2.167,2.167,0,0,1-1.584-.721,1.9,1.9,0,0,1-.514-1.429V3.1a2,2,0,0,1,.561-1.511'
          transform='translate(-33.389 -0.001)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
