import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const InsuranceIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='16.57' height='16.57' viewBox='0 0 16.57 16.57' {...props}>
      <g id='icons8-umbrella' transform='translate(-4 -4)'>
        <path
          id='Path_13041'
          data-name='Path 13041'
          d='M4.62,16.5a.62.62,0,0,1-.62-.62,9.645,9.645,0,0,1,.606-3.391,8.857,8.857,0,0,1,1.7-2.829A7.911,7.911,0,0,1,12.264,7a7.737,7.737,0,0,1,3.542.809.62.62,0,1,1-.554,1.109,6.526,6.526,0,0,0-2.987-.678,6.69,6.69,0,0,0-2.818.607A6.773,6.773,0,0,0,7.23,10.492a7.623,7.623,0,0,0-1.463,2.435,8.41,8.41,0,0,0-.528,2.957A.62.62,0,0,1,4.62,16.5Z'
          transform='translate(0 -1.76)'
          fill={fill}
        />
        <path
          id='Path_13042'
          data-name='Path 13042'
          d='M23.354,20.318a.62.62,0,0,1-.473-.219l-.451-.533a1.822,1.822,0,0,0-1.626-.607,1.871,1.871,0,0,0-1.441,1.017.62.62,0,0,1-1,.151,3.65,3.65,0,0,0-5.3,0,.62.62,0,0,1-.964-.084l-.165-.248a1.937,1.937,0,0,0-1.542-.882.62.62,0,0,1,.065-1.238,3.135,3.135,0,0,1,2.257,1.1,4.887,4.887,0,0,1,5.986-.011,3.123,3.123,0,0,1,3.895-.648,7.825,7.825,0,0,0-1.655-3.6.62.62,0,1,1,.952-.794A9.321,9.321,0,0,1,23.974,19.7a.62.62,0,0,1-.62.62Z'
          transform='translate(-3.403 -5.574)'
          fill={fill}
        />
        <path
          id='Path_13043'
          data-name='Path 13043'
          d='M14.71,32.6a2.721,2.721,0,0,1-2.81-2.686.62.62,0,1,1,1.24,0,1.362,1.362,0,0,0,.446,1.027,1.646,1.646,0,0,0,1.124.42,1.585,1.585,0,0,0,1.57-1.57V25.12a.62.62,0,1,1,1.24,0v4.669a2.824,2.824,0,0,1-2.81,2.81Z'
          transform='translate(-4.636 -12.029)'
          fill={fill}
        />
        <path
          id='Line_1741'
          data-name='Line 1741'
          d='M-.88.979A.62.62,0,0,1-1.5.36V-.88A.62.62,0,0,1-.88-1.5a.62.62,0,0,1,.62.62V.36A.62.62,0,0,1-.88.979Z'
          transform='translate(13.145 5.5)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
