import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const GlassIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "18px"}
      height={height ? height : "24.013px"}
      viewBox={viewBox ? viewBox : "0 0 18 24.013"}
      {...props}
    >
      <path
        id='icons8-wine-bar'
        d='M8.787,3a1,1,0,0,0-.973.768C7.319,5.864,6,11.751,6,15c0,3.6,3.5,6.566,8,6.953V24.2l-4.176.818-.057.012-.029.006v0A1,1,0,0,0,10,27h4.832a1,1,0,0,0,.326,0H20a1,1,0,0,0,.262-1.969l-.027-.006q-.04-.009-.08-.016L16,24.2V21.953c4.5-.387,8-3.35,8-6.953,0-3.249-1.319-9.136-1.814-11.232A1,1,0,0,0,21.213,3Zm.795,2H20.418c.225.984.542,2.442.834,4h-9.7a1,1,0,0,0-.98.807A29.842,29.842,0,0,0,10,15a7.1,7.1,0,0,0,.246,1.7.749.749,0,0,1-1.311.652A3.74,3.74,0,0,1,8,15,58.449,58.449,0,0,1,9.582,5Z'
        transform='translate(-6 -3)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
