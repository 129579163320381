import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Address: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='25.345' height='24.888' viewBox='0 0 25.345 24.888' {...props}>
      <path
        id='icons8-address'
        d='M14.25,2A7.042,7.042,0,0,0,7.217,9.034c0,2.748,1.68,6.14,3.322,8.923a55.567,55.567,0,0,0,3.28,4.933l.431.56.431-.56a55.567,55.567,0,0,0,3.28-4.933c1.642-2.783,3.322-6.176,3.322-8.923A7.042,7.042,0,0,0,14.25,2Zm0,1.082A5.944,5.944,0,0,1,20.2,9.034c0,2.28-1.566,5.651-3.17,8.374a41.691,41.691,0,0,1-2.781,4.178,41.691,41.691,0,0,1-2.781-4.178c-1.6-2.722-3.17-6.093-3.17-8.374A5.944,5.944,0,0,1,14.25,3.082Zm0,3.246a2.705,2.705,0,1,0,2.705,2.705A2.713,2.713,0,0,0,14.25,6.328Zm0,1.082a1.623,1.623,0,1,1-1.623,1.623A1.616,1.616,0,0,1,14.25,7.41ZM4.672,18.231,1.578,26.888H26.923l-3.094-8.657h-4.14q-.317.558-.634,1.082h4.013l2.316,6.493H3.117l2.316-6.493H9.447q-.317-.523-.634-1.082Z'
        transform='translate(-1.578 -2)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
