export const customWebViewStyle = `
:root{

  --bg-color: #f8f9fb;
  --primary-color: #25287f;
  --input-label-color: #5b7798;
  --input-data-color: var(--primary-color);
  --submit-button-color: var(--primary-color);
}
body{
    margin: 0;
    padding:0;
    max-height: 100vh;
}
.process-payment-page {
    padding: 10px;
    background-color: var(bg-color);
    min-height: 100vh;
  
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
  }
  .process-payment-page .wpwl-group {
    max-width: 405px;
    height: 55px;
    /* UI Properties */
    /* background: transparent url("img/Rectangle 4087.png") 0% 0% no-repeat padding-box; */
    box-shadow: 0px 3px 6px #0000000d;
    border-radius: 28px;
    opacity: 1;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
  }
  
  .process-payment-page .wpwl-group .wpwl-label {
    font-size: 10px;
    color: var(--input-label-color);
    flex: 0;
    height: 13px;
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
    text-transform: capitalize;
  }
  
  .process-payment-page .wpwl-group input {
    border: unset;
    font-size: 10px;
    color: var(--input-data-color);
    flex: 1;
    background: transparent !important;
    margin-top: 5px;
  }
  
  .process-payment-page .wpwl-hint.wpwl-hint {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
  }
  
  .process-payment-page .wpwl-control.wpwl-control-iframe,
  .process-payment-page .wpwl-control.wpwl-control-brand {
    font-size: 14px;
    z-index: 0;
    position: relative;
    padding: 0;
    border: unset;
    background-color: transparent;
  }
  
  .process-payment-page .wpwl-wrapper.wpwl-wrapper-brand {
    width: 100%;
  }
  .process-payment-page .wpwl-wrapper.wpwl-wrapper-submit,
  .process-payment-page .wpwl-group.wpwl-group-submit {
    all: unset;
    border: unset;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .process-payment-page .wpwl-wrapper.wpwl-wrapper-submit button,
  .process-payment-page .wpwl-wrapper.wpwl-wrapper-submit button:hover {
    flex: 1;
    border-radius: 28px;
    border: 1px solid var(--submit-button-color);
    background-color: var(--submit-button-color);
    margin-top: 0;
    font-family: "Airbnb Cereal App Medium", "Arab Kufi Regular";
  }
  
  .process-payment-page .wpwl-group.wpwl-group-submit.wpwl-clearfix {
    max-width: 405px;
  }
  
  .process-payment-page .wpwl-wrapper.wpwl-wrapper-submit {
    width: 90%;
    margin-top: 0px;
  }
  
  .process-payment-page .wpwl-brand-card {
    border-radius: 5px;
    z-index: 1;
    width: 55px;
    height: 35px;
    display: inline-block;
  }
  
  .process-payment-page .wpwl-control.wpwl-control-brand,
  .process-payment-page .wpwl-label.wpwl-label-brand {
    display: none;
  }
  .process-payment-page .wpwl-group.wpwl-group-brand {
    height: 70px;
    width: 105px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #5555550d;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
  }
  
  .process-payment-page .store-details-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 20px;
  }
  .process-payment-page .store-details-label {
    font-size: 12px;
    color: var(--primary-color);
    flex: 1;
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
  }
  .process-payment-page .store-details-checkbox {
    display: flex;
  }
  
  .process-payment-page .wpwl-registration {
    display: flex !important;
    flex-direction: row;
    /* width: 100%; */
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: unset;
    clear: both;
    margin: 0;
    all: unset;
    flex-wrap: wrap;
  }
  .process-payment-page .wpwl-wrapper-registration.wpwl-wrapper-registration-registrationId {
    width: 20px;
    display: flex;
    align-items: center;
  }
  
  .process-payment-page .wpwl-wrapper-registration.wpwl-wrapper-registration-brand {
    width: 55px;
    margin: auto;
  }
  .process-payment-page .wpwl-wrapper-registration.wpwl-wrapper-registration-details {
    font-size: 14px;
    flex: 1;
    width: auto;
    float: none;
    clear: both;
    align-items: center;
    margin-inline: 5px;
    display: flex;
    justify-content: space-between;
    font-family: "Airbnb Cereal App Book", " Arab Kufi Regular";
  }
  .process-payment-page .wpwl-wrapper-registration.wpwl-wrapper-registration-details > div {
    padding-right: unset;
    font-family: "Airbnb Cereal App Medium", " Arab Kufi Regular";
  }
  
  .process-payment-page .wpwl-selected {
    border-color: var(--primary-color);
  }
  `;
