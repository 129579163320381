import { useState, useCallback, useEffect } from "react";
import moment, { Moment } from "moment";
import { HorizontalCalendarProps, UseHorizontalCalendarReturnType } from "./types";

export const useHorizontalCalendar = ({
  onChange,
  selectedDate: _selectedDate,
}: HorizontalCalendarProps): UseHorizontalCalendarReturnType => {
  const [weeks, setWeeks] = useState<Moment[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment>(_selectedDate ?? moment());

  const initializeWeeks = useCallback(() => {
    const startOfCurrentWeek = moment(selectedDate).startOf("week");
    const newWeeksLength = weeks.length ? weeks.length + 1 : 7;
    const weeksArray = Array.from({ length: newWeeksLength }, (_, i) =>
      startOfCurrentWeek.clone().add(i - Math.floor(newWeeksLength / 2), "weeks")
    );
    setWeeks(weeksArray);
  }, [selectedDate]);

  useEffect(() => {
    initializeWeeks();
  }, [initializeWeeks]);

  const handleWeekChange = (direction: "start" | "end") => {
    setWeeks(prevWeeks => {
      const newWeeks = [...prevWeeks];
      if (direction === "start") {
        const startWeek = newWeeks[0].clone().subtract(prevWeeks.length, "weeks");
        const additionalWeeks = Array.from({ length: prevWeeks.length }, (_, i) => startWeek.clone().add(i, "weeks"));
        newWeeks.unshift(...additionalWeeks);
      } else if (direction === "end") {
        const endWeek = newWeeks[newWeeks.length - 1].clone().add(1, "week");
        const additionalWeeks = Array.from({ length: prevWeeks.length - 1 }, (_, i) => endWeek.clone().add(i, "weeks"));
        newWeeks.push(...additionalWeeks);
      }
      return newWeeks;
    });
  };

  const handleDateChange = (date: Moment) => {
    setSelectedDate(date);
    onChange && onChange(date);
  };

  return {
    weeks,
    selectedDate,
    handleDateChange,
    handleWeekChange,
  };
};
