import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CloseIconBook: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' {...props}>
      <path
        id='icons8-close'
        d='M6.665,6.281l-.384.384L10.9,11.281,6.281,15.9l.384.384,4.616-4.616L15.9,16.281l.384-.384-4.616-4.616,4.616-4.616L15.9,6.281,11.281,10.9Z'
        transform='translate(-6.281 -6.281)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
