import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    paddingInlineStart: "43px",
  },
  circularProgress: {
    color: theme.palette.primary[1600],
  },
  avatar: {
    width: "55px",
    height: "55px",
    color: theme.palette.primary[1600],
    backgroundColor: "white",
    "& svg": {
      width: "35px",
      height: "35px",
    },
  },
  button: {
    backgroundColor: "inherit",
    ":hover": {
      backgroundColor: "inherit",
    },
  },
  buttonIcon: {
    "& svg": {
      width: "12.7px",
      height: "12.7px",
    },
    "& path": {
      fill: "white",
    },
  },
}));

export default useStyles;
