import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const HelpIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.339' height='17.339' viewBox='0 0 17.339 17.339' {...props}>
      <path
        id='icons8-help'
        d='M11.669,3a8.669,8.669,0,1,0,8.669,8.669A8.67,8.67,0,0,0,11.669,3Zm0,14.088A1.084,1.084,0,1,1,12.753,16,1.084,1.084,0,0,1,11.669,17.088Zm1.494-5.057c-.478.441-.772.731-.772,1.084H10.947a2.955,2.955,0,0,1,1.236-2.146c.577-.533.931-.889.931-1.467a1.445,1.445,0,0,0-2.89,0H8.78a2.89,2.89,0,1,1,5.78,0A3.36,3.36,0,0,1,13.163,12.031Z'
        transform='translate(-3 -3)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
