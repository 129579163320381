import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const BirthdayIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.966' height='16.631' viewBox='0 0 15.966 16.631' {...props}>
      <path
        id='icons8-birthday_1_'
        data-name='icons8-birthday (1)'
        d='M6.326,1A8.793,8.793,0,0,0,5,3.661a1.331,1.331,0,0,0,2.661,0A8.793,8.793,0,0,0,6.326,1Zm4.657,0A8.793,8.793,0,0,0,9.653,3.661a1.331,1.331,0,0,0,2.661,0A8.793,8.793,0,0,0,10.983,1ZM15.64,1a8.793,8.793,0,0,0-1.331,2.661,1.331,1.331,0,0,0,2.661,0A8.793,8.793,0,0,0,15.64,1ZM5,6.322V9.079a2.666,2.666,0,0,0-2,2.565,2.636,2.636,0,0,0,.665,1.737V16.3A1.33,1.33,0,0,0,5,17.631H16.97A1.33,1.33,0,0,0,18.3,16.3v-2.92a2.636,2.636,0,0,0,.665-1.737,2.666,2.666,0,0,0-2-2.565V6.322H14.309V8.983h-2V6.322H9.653V8.983h-2V6.322Zm.665,3.992H16.305a1.331,1.331,0,0,1,0,2.661,1.3,1.3,0,0,1-.885-.347l-.446-.4-.446.4a1.3,1.3,0,0,1-1.77,0l-.446-.4-.446.4a1.3,1.3,0,0,1-1.77,0l-.446-.4-.446.4a1.3,1.3,0,0,1-1.77,0l-.446-.4-.446.4a1.3,1.3,0,0,1-.885.347,1.331,1.331,0,1,1,0-2.661Z'
        transform='translate(-3 -1)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
