import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const CycleIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "23.897px"}
      height={height ? height : "24px"}
      viewBox={viewBox ? viewBox : "0 0 23.897 24"}
      {...props}
    >
      <path
        id='icons8-synchronize'
        d='M15,3A12,12,0,0,0,3.113,13.439,1,1,0,1,0,5.1,13.7,9.973,9.973,0,0,1,22.059,7.941L20,10l6,1L25,5,23.471,6.529A11.945,11.945,0,0,0,15,3ZM25.912,15.418A1,1,0,0,0,24.9,16.3,9.967,9.967,0,0,1,7.465,21.535L9,20,3,19l1,6,2.049-2.049a11.964,11.964,0,0,0,20.838-6.391,1,1,0,0,0-.975-1.143Z'
        transform='translate(-3 -3)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
