import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const HomeAddressIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='25.695' height='31.525' viewBox='0 0 25.695 31.525' {...props}>
      <path
        id='icons8-home-address'
        d='M16.847,2A12.848,12.848,0,0,0,4,14.847c0,8.449,8.354,12.495,9.4,13.436a10.841,10.841,0,0,1,2.306,4.336,1.171,1.171,0,0,0,2.281,0,10.849,10.849,0,0,1,2.306-4.336c1.046-.941,9.4-4.987,9.4-13.436A12.848,12.848,0,0,0,16.847,2Zm0,7.008a.58.58,0,0,1,.349.116l4.672,3.5a.584.584,0,0,1-.351,1.052v5.84h-3.5v-3.5H15.679v3.5h-3.5V13.668a.571.571,0,0,1-.468-.221.587.587,0,0,1,.119-.819l4.672-3.5A.587.587,0,0,1,16.85,9.008Z'
        transform='translate(-4 -2)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
