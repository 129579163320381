/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type NotificationFragmentFragment = { __typename?: 'UserNotification', id?: string | null, seen?: boolean | null, type?: Types.NotificationType | null, data?: string | null, updateDate?: any | null, creationDate?: any | null, medicalMessage?: { __typename?: 'MedicalMessage', headerImage?: string | null, messages?: Array<{ __typename?: 'MedicalMessageVariant', id: string, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, bodyArabic?: string | null, bodyEnglish?: string | null, buttonTextArabic?: string | null, buttonTextEnglish?: string | null, contentURLArabic?: string | null, contentURLEnglish?: string | null, extraData?: string | null } | null> | null } | null, errors?: Array<{ __typename?: 'SubscriptionGraphqlError', code?: Types.SubscriptionErrorCodes | null, field?: string | null, message?: string | null } | null> | null };

export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on UserNotification {
  id
  seen
  type
  data
  medicalMessage {
    headerImage
    messages {
      id
      presentedTitleArabic
      presentedTitleEnglish
      bodyArabic
      bodyEnglish
      buttonTextArabic
      buttonTextEnglish
      contentURLArabic
      contentURLEnglish
      extraData
    }
  }
  updateDate
  creationDate
  errors {
    code
    field
    message
  }
}
    `;