import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const CanceledByNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <path
        id='icons8-calendar-delete'
        d='M10.466,3.478a.792.792,0,0,0-.78.8V4.81H9.423A3.435,3.435,0,0,0,5.991,8.242V9.7a.792.792,0,0,0,0,.257v2.575a.792.792,0,1,0,1.584,0v-1.91H21.039a.792.792,0,1,0,0-1.584H7.575V8.242A1.8,1.8,0,0,1,9.423,6.394h.264v.264a.792.792,0,1,0,1.584,0V6.394h8.448v.264a.792.792,0,1,0,1.584,0V6.394h.264a1.8,1.8,0,0,1,1.848,1.848v4.736a.792.792,0,1,0,1.584,0V8.242A3.435,3.435,0,0,0,21.567,4.81H21.3V4.282a.792.792,0,1,0-1.584,0V4.81H11.271V4.282a.792.792,0,0,0-.8-.8ZM21.578,14.3a5.692,5.692,0,0,0-.672.075.792.792,0,0,0,.268,1.561,4.163,4.163,0,0,1,3.635,1.152,4.22,4.22,0,0,1,.315,5.595.792.792,0,1,0,1.23,1,5.8,5.8,0,0,0-.425-7.712A5.75,5.75,0,0,0,21.578,14.3ZM6.77,15.051a.792.792,0,0,0-.78.8v4.534a3.435,3.435,0,0,0,3.432,3.432h8c.1.118.2.237.315.349a5.774,5.774,0,0,0,4.1,1.711.792.792,0,1,0,0-1.584,4.143,4.143,0,0,1-2.977-1.247,4.2,4.2,0,0,1,0-5.956.792.792,0,1,0-1.12-1.12,5.81,5.81,0,0,0-1.276,6.263H9.423a1.8,1.8,0,0,1-1.848-1.848V15.853a.792.792,0,0,0-.8-.8Zm16.273,3.056a.792.792,0,0,0-.552.236l-.687.672-.687-.672a.792.792,0,1,0-1.108,1.131l.663.649-.663.649A.792.792,0,1,0,21.118,21.9l.687-.672.687.672A.792.792,0,1,0,23.6,20.771l-.663-.649.663-.649a.792.792,0,0,0-.557-1.367Z'
        transform='translate(8 9)'
        fill='#fff'
      />
    </NotificationWrapperIcon>
  );
};
