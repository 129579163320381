import { SvgIconProps, SvgIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EmptyNotificationIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='173.754' height='172.978' viewBox='0 0 173.754 172.978' {...props}>
      <g id='notification' transform='translate(-65.279 -66.252)'>
        <path
          id='Path_12746'
          d='M152.462,235.723c-20.071-4.54-38.191-11.708-54.878-24.452-16.527-12.7-31.7-30.864-32.3-49.461-.4-18.558,13.66-37.474,28.992-52.329a153.345,153.345,0,0,1,51.293-33.93c19.753-8.164,43.368-13.5,59.5-4.938,16.049,8.642,24.611,31.182,29.43,52.05,4.9,20.828,6.013,39.983,2.429,59.9s-11.907,40.421-27.12,49.78c-15.213,9.478-37.236,7.726-57.347,3.385'
          fill='#2b3078'
          opacity='0.09'
        />
        <g id='Group_11349' data-name='Group 11349' transform='translate(94.2 99.743)'>
          <g id='Group_11348' data-name='Group 11348' transform='translate(0 0)'>
            <g id='No_Notification'>
              <path
                id='Path_13345'
                data-name='Path 13345'
                d='M398.789,122.616a3.813,3.813,0,0,1-6.889,3.27,3.755,3.755,0,0,1-.348-1.218h0a3.814,3.814,0,0,1,7.237-2.053Zm-5.219,2.435a1.984,1.984,0,0,0,3.584-1.7,2.07,2.07,0,0,0-2.018-1.148,2.011,2.011,0,0,0-1.74,2.192,1.238,1.238,0,0,0,.174.661Z'
                transform='translate(-295.421 -113.709)'
                fill={fill}
              />
              <path
                id='Path_13346'
                data-name='Path 13346'
                d='M422.818,150.964a2.929,2.929,0,1,1,.591-2.088A2.931,2.931,0,0,1,422.818,150.964Zm-3.271-1.392a1.154,1.154,0,0,0,1.461.557,1.141,1.141,0,0,0,.557-1.461,1.075,1.075,0,0,0-1.113-.626,1.1,1.1,0,0,0-.974,1.218A.355.355,0,0,0,419.548,149.573Z'
                transform='translate(-312.422 -130.541)'
                fill={fill}
              />
              <path
                id='Path_13347'
                data-name='Path 13347'
                d='M127.044,329.438H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.871H125.2V308.04h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2v-1.809h1.809l.035,1.809Zm0-4.871H125.2v-1.809h1.809l.035,1.809Zm0-4.941H125.2v-1.809h1.809l.035,1.809Zm0-4.871H125.2v-1.809h1.809l.035,1.809Zm0-4.906H125.2V273.7h1.809l.035,1.844Z'
                transform='translate(-121.756 -213.648)'
                fill='#a9b4d3'
              />
              <g id='Group_11345' data-name='Group 11345' transform='translate(46.796 110.71)'>
                <path
                  id='Path_13348'
                  data-name='Path 13348'
                  d='M275.3,435.861H261.7A11.931,11.931,0,0,1,249.8,424v-4.7h37.367V424A11.834,11.834,0,0,1,275.3,435.861Zm-21.815-12.838V424a8.212,8.212,0,0,0,8.176,8.176h13.6A8.212,8.212,0,0,0,283.444,424v-.974Z'
                  transform='translate(-249.8 -419.3)'
                  fill={fill}
                />
              </g>
              <path
                id='Path_13349'
                data-name='Path 13349'
                d='M239.209,275.809H237.4V274h1.809Zm-4.871,0h-1.809V274h1.809Zm-4.941,0h-1.809V274H229.4Zm-4.871,0h-1.844V274h1.809l.035,1.809Zm-4.906,0h-1.809V274h1.809Zm-4.906,0h-1.809V274h1.809Zm-4.906,0H208V274h1.809Z'
                transform='translate(-175.747 -213.844)'
                fill='#a9b4d3'
              />
              <path
                id='Path_13350'
                data-name='Path 13350'
                d='M262.865,219.2H169.9s9.568-4.732,9.568-16.039V164.68a36.9,36.9,0,0,1,73.795,0v38.446C253.3,214.468,262.865,219.2,262.865,219.2Z'
                transform='translate(-150.903 -118.51)'
                fill='#f5f5f5'
              />
              <path
                id='Path_13351'
                data-name='Path 13351'
                d='M229.336,152.3a30.785,30.785,0,0,0-18.579,28.321v38.446a23.09,23.09,0,0,1-2.157,10.02h20.7Z'
                transform='translate(-176.139 -134.486)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13352'
                data-name='Path 13352'
                d='M257.664,396.349a5.969,5.969,0,0,1-5.949,5.95H158.749a5.95,5.95,0,1,1,0-11.9h92.965a5.992,5.992,0,0,1,5.949,5.949Z'
                transform='translate(-139.753 -289.745)'
                fill='#f5f5f5'
              />
              <path
                id='Path_13353'
                data-name='Path 13353'
                d='M257.664,407.5a5.969,5.969,0,0,1-5.949,5.95H158.749a5.969,5.969,0,0,1-5.95-5.95H257.664Z'
                transform='translate(-139.753 -300.896)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13354'
                data-name='Path 13354'
                d='M298.121,109.993v2.679a37.213,37.213,0,0,0-12.421,0v-2.679a6.21,6.21,0,0,1,12.421,0Z'
                transform='translate(-226.414 -102.861)'
                fill='#fff'
              />
              <g id='Group_11346' data-name='Group 11346' transform='translate(47.179 112.449)'>
                <path
                  id='Path_13355'
                  data-name='Path 13355'
                  d='M351.335,438.3c0,.07-.035.139-.035.244C351.3,438.439,351.335,438.37,351.335,438.3Z'
                  transform='translate(-316.368 -433.429)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13356'
                  data-name='Path 13356'
                  d='M255.435,437.039a.209.209,0,0,0-.035-.139A.347.347,0,0,1,255.435,437.039Z'
                  transform='translate(-253.834 -432.516)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13357'
                  data-name='Path 13357'
                  d='M351.635,437c0,.07-.035.1-.035.174A.314.314,0,0,1,351.635,437Z'
                  transform='translate(-316.564 -432.581)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13358'
                  data-name='Path 13358'
                  d='M255.1,432.8a8.578,8.578,0,0,0,.1,1.357c-.035-.452-.07-.87-.1-1.357Z'
                  transform='translate(-253.639 -429.843)'
                  fill='#2b3078'
                />
                <path id='Path_13359' data-name='Path 13359' d='M351.8,435.6v0Z' transform='translate(-316.694 -431.668)' fill='#2b3078' />
                <path id='Path_13360' data-name='Path 13360' d='M351.9,434.2v0Z' transform='translate(-316.76 -430.756)' fill='#2b3078' />
                <path
                  id='Path_13361'
                  data-name='Path 13361'
                  d='M251.039,442.587a1.7,1.7,0,0,0-.139-.383c1.566,4.975,5.706,8.524,10.612,8.559h15.274c4.941,0,9.15-3.653,10.646-8.663a3.06,3.06,0,0,1-.174.522l-36.219-.035Z'
                  transform='translate(-250.9 -435.907)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13362'
                  data-name='Path 13362'
                  d='M350.6,440.8c-.035.1-.07.209-.1.348C350.535,441.009,350.57,440.9,350.6,440.8Z'
                  transform='translate(-315.847 -435.059)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13363'
                  data-name='Path 13363'
                  d='M350.97,439.6c-.035.1-.035.174-.07.278A.655.655,0,0,0,350.97,439.6Z'
                  transform='translate(-316.108 -434.277)'
                  fill='#2b3078'
                />
                <path
                  id='Path_13364'
                  data-name='Path 13364'
                  d='M256.435,441.27c0-.035,0-.035-.035-.07A.108.108,0,0,1,256.435,441.27Z'
                  transform='translate(-254.486 -435.32)'
                  fill='#2b3078'
                />
                <path id='Path_13365' data-name='Path 13365' d='M352,432.8v0Z' transform='translate(-316.825 -429.843)' fill='#f5f5f5' />
                <path
                  id='Path_13366'
                  data-name='Path 13366'
                  d='M255.451,436.87v-.07C255.416,436.8,255.416,436.835,255.451,436.87Z'
                  transform='translate(-253.851 -432.451)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13367'
                  data-name='Path 13367'
                  d='M255.1,427.418v0Z'
                  transform='translate(-253.639 -424.496)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13368'
                  data-name='Path 13368'
                  d='M255.913,438.692a9,9,0,0,1-.313-1.392A5.02,5.02,0,0,0,255.913,438.692Z'
                  transform='translate(-253.965 -432.777)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13369'
                  data-name='Path 13369'
                  d='M256.5,441.713l-.1-.313A1.106,1.106,0,0,1,256.5,441.713Z'
                  transform='translate(-254.486 -435.45)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13370'
                  data-name='Path 13370'
                  d='M350.835,440.4c0,.07-.035.1-.035.174A.708.708,0,0,1,350.835,440.4Z'
                  transform='translate(-316.042 -434.798)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13371'
                  data-name='Path 13371'
                  d='M350.4,441.87a.121.121,0,0,1,.035-.07A.121.121,0,0,0,350.4,441.87Z'
                  transform='translate(-315.782 -435.711)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13372'
                  data-name='Path 13372'
                  d='M351.17,439a.886.886,0,0,1-.07.244A2.222,2.222,0,0,1,351.17,439Z'
                  transform='translate(-316.238 -433.885)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13373'
                  data-name='Path 13373'
                  d='M351.47,437.4a1.015,1.015,0,0,1-.07.313C351.435,437.609,351.435,437.5,351.47,437.4Z'
                  transform='translate(-316.434 -432.842)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13374'
                  data-name='Path 13374'
                  d='M351.835,434.4c0,.139-.035.278-.035.452A2.14,2.14,0,0,0,351.835,434.4Z'
                  transform='translate(-316.694 -430.886)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13375'
                  data-name='Path 13375'
                  d='M351.735,436c0,.139-.035.244-.035.348C351.7,436.244,351.735,436.1,351.735,436Z'
                  transform='translate(-316.629 -431.929)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13376'
                  data-name='Path 13376'
                  d='M288.4,430.145c0-.07.035-.1.035-.174.035-.1.035-.174.07-.278a2.194,2.194,0,0,0,.07-.244c0-.07.035-.139.035-.244.035-.1.035-.209.07-.313,0-.07.035-.1.035-.174a1.428,1.428,0,0,0,.035-.383V428.2c0-.139.035-.313.035-.452V424.3H255.1v2.853a8.706,8.706,0,0,0,.1,1.392v.07a.209.209,0,0,0,.035.139,8.992,8.992,0,0,0,.313,1.392c0,.035,0,.035.035.07l.1.313.1.313h32.357c.035-.139.1-.278.139-.418a.121.121,0,0,1,.035-.07Z'
                  transform='translate(-253.639 -424.3)'
                  fill='#f5f5f5'
                />
              </g>
              <path
                id='Path_13377'
                data-name='Path 13377'
                d='M158.7,218.344l3.549-1.74a17.33,17.33,0,0,0,9.081-15.239V162.919a37.819,37.819,0,1,1,75.639,0v38.446a17.452,17.452,0,0,0,9.081,15.239l3.514,1.74Zm6.993-1.844H252.5a18.893,18.893,0,0,1-7.411-15.135V162.919a35.993,35.993,0,0,0-71.986,0v38.446A18.738,18.738,0,0,1,165.693,216.5Z'
                transform='translate(-143.6 -116.75)'
                fill={fill}
              />
              <path
                id='Path_13378'
                data-name='Path 13378'
                d='M297.365,111.99l-1.079-.174a37.209,37.209,0,0,0-12.108,0l-1.079.174v-3.758a7.132,7.132,0,0,1,14.265,0v3.758Zm-1.879-2.157v-1.6a5.288,5.288,0,0,0-10.577,0v1.6a35.922,35.922,0,0,1,10.577,0Z'
                transform='translate(-224.718 -101.1)'
                fill={fill}
              />
              <path
                id='Path_13379'
                data-name='Path 13379'
                d='M249.954,401.478H156.989a6.889,6.889,0,1,1,0-13.778h92.965a6.889,6.889,0,0,1,0,13.778Zm-92.965-11.9a5.01,5.01,0,1,0,0,10.02h92.965a5.01,5.01,0,0,0,0-10.02Z'
                transform='translate(-137.992 -287.985)'
                fill={fill}
              />
              <path
                id='Path_13380'
                data-name='Path 13380'
                d='M128.73,447.462l-9.255,9.255h9.046v3.34H115.4v-4l9.081-9.116H115.3V443.6h13.43Z'
                transform='translate(-115.3 -324.436)'
                fill='#2b3078'
              />
              <path
                id='Path_13381'
                data-name='Path 13381'
                d='M163.97,470.938v-2.853l4.941-4.906H163.9V460.5h8.663v2.749l-5.01,5.045h4.871v2.644Z'
                transform='translate(-146.991 -335.456)'
                fill='#2b3078'
              />
              <path
                id='Path_13382'
                data-name='Path 13382'
                d='M199.87,470.938v-2.853l4.906-4.906H199.8V460.5h8.629v2.749l-5.045,5.045h4.941v2.644Z'
                transform='translate(-170.4 -335.456)'
                fill='#2b3078'
              />
              <circle
                id='Ellipse_3062'
                data-name='Ellipse 3062'
                cx='1.531'
                cy='1.531'
                r='1.531'
                transform='translate(41.855 132.455)'
                fill='#2b3078'
              />
              <circle
                id='Ellipse_3063'
                data-name='Ellipse 3063'
                cx='1.531'
                cy='1.531'
                r='1.531'
                transform='translate(48.361 132.455)'
                fill='#2b3078'
              />
              <g id='Group_11347' data-name='Group 11347' transform='translate(16.902 10.473)'>
                <path
                  id='Path_13383'
                  data-name='Path 13383'
                  d='M177.362,145.162h0a.9.9,0,0,1,1.322,0l2.923,2.923a.9.9,0,0,1,0,1.322h0a.9.9,0,0,1-1.322,0l-2.923-2.923A.9.9,0,0,1,177.362,145.162Z'
                  transform='translate(-172.484 -140.117)'
                  fill={fill}
                />
                <path
                  id='Path_13384'
                  data-name='Path 13384'
                  d='M164.791,169.5h4.14a.9.9,0,0,1,.9.9h0a.9.9,0,0,1-.9.9h-4.14a.9.9,0,0,1-.9-.9h0A.84.84,0,0,1,164.791,169.5Z'
                  transform='translate(-163.88 -156.174)'
                  fill={fill}
                />
                <path
                  id='Path_13385'
                  data-name='Path 13385'
                  d='M201.5,131.2h0a.9.9,0,0,1,.9.9v4.14a.9.9,0,0,1-.9.9h0a.9.9,0,0,1-.9-.9V132.1A.9.9,0,0,1,201.5,131.2Z'
                  transform='translate(-187.824 -131.2)'
                  fill={fill}
                />
              </g>
              <path
                id='Path_13386'
                data-name='Path 13386'
                d='M156.926,275.809h-1.809V274h1.809Zm-4.906,0h-1.809V274h1.809Zm-4.871,0h-1.809V274h1.809Zm-4.941,0H140.4V274h1.809Z'
                transform='translate(-131.667 -213.844)'
                fill='#a9b4d3'
              />
            </g>
          </g>
          <path
            id='Path_13387'
            data-name='Path 13387'
            d='M385.13,322.4l.035,22.65-11.377.035C372.709,335.656,375.458,327.132,385.13,322.4Z'
            transform='translate(-283.71 -245.404)'
            fill='#d3d9ed'
          />
        </g>
        <g id='Group_11328' data-name='Group 11328' transform='translate(188.059 184.99)'>
          <circle
            id='Ellipse_3054'
            data-name='Ellipse 3054'
            cx='20.059'
            cy='20.059'
            r='20.059'
            transform='translate(1.967 1.694)'
            fill='#46a200'
          />
          <path
            id='Path_13196'
            data-name='Path 13196'
            d='M400.925,318.4a22.025,22.025,0,1,0,22.025,22.025A22.047,22.047,0,0,0,400.925,318.4Zm19.847,22.086a19.877,19.877,0,1,1-19.877-19.877A19.91,19.91,0,0,1,420.772,340.486Z'
            transform='translate(-378.9 -318.4)'
            fill='#fff'
          />
          <path
            id='Path_13197'
            data-name='Path 13197'
            d='M437.848,364.887l-4.387-4.387-10.77,10.77-5.2-5.2-4.387,4.357,5.234,5.234h-.03l4.387,4.387h0l4.417-4.387h0Z'
            transform='translate(-402.753 -347.763)'
            fill='#fff'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
