import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const SuccessNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-bank-cards' transform='translate(8 7)'>
        <path
          id='Path_13389'
          data-name='Path 13389'
          d='M24.346,28.206H11.154A3.158,3.158,0,0,1,8,25.051v-.229a.86.86,0,0,1,1.721,0v.229a1.435,1.435,0,0,0,1.434,1.434H24.346a3.741,3.741,0,0,0,3.728-3.728v-8.6a1.435,1.435,0,0,0-1.434-1.434h-.516a.86.86,0,0,1,0-1.721h.516a3.158,3.158,0,0,1,3.154,3.154v8.6a5.462,5.462,0,0,1-5.449,5.449Z'
          transform='translate(-1.706 -1.706)'
          fill='#fff'
        />
        <path
          id='Path_13390'
          data-name='Path 13390'
          d='M22.066,29.219H7.154A3.158,3.158,0,0,1,4,26.065V23.771a.86.86,0,1,1,1.721,0v2.294A1.435,1.435,0,0,0,7.154,27.5H22.066A1.435,1.435,0,0,0,23.5,26.065v-5.1a.86.86,0,1,1,1.721,0v5.1A3.158,3.158,0,0,1,22.066,29.219Z'
          transform='translate(0 -5.587)'
          fill='#fff'
        />
        <path
          id='Path_13391'
          data-name='Path 13391'
          d='M4.86,15.89a.86.86,0,0,1-.86-.86V10.154A3.158,3.158,0,0,1,7.154,7H22.066a3.158,3.158,0,0,1,3.154,3.154v1.721a.86.86,0,1,1-1.721,0V10.154a1.435,1.435,0,0,0-1.434-1.434H7.154a1.435,1.435,0,0,0-1.434,1.434v4.875A.86.86,0,0,1,4.86,15.89Z'
          fill='#fff'
        />
        <path
          id='Line_1789'
          data-name='Line 1789'
          d='M2.228.221H-.64A.86.86,0,0,1-1.5-.64.86.86,0,0,1-.64-1.5H2.228a.86.86,0,0,1,.86.86A.86.86,0,0,1,2.228.221Z'
          transform='translate(18.691 19.971)'
          fill='#fff'
        />
        <path
          id='Line_1790'
          data-name='Line 1790'
          d='M18.287.221H-.64A.86.86,0,0,1-1.5-.64.86.86,0,0,1-.64-1.5H18.287a.86.86,0,0,1,.86.86A.86.86,0,0,1,18.287.221Z'
          transform='translate(5.787 12.515)'
          fill='#fff'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
