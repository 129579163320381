import { FC } from "react";
import { IFormattedTimeSlot, IGroupedAvailableTimeSlots } from "../../types/types";
import { Box } from "@toolkit/ui";
import SlotsSection from "../SlotsSection/SlotsSection";
import { AfterNoonIcon, EveningIcon, MorningIcon } from "@/components";

export interface AvailableTimeSlotsProps {
  selectedTimeSlots: IFormattedTimeSlot[];
  availableTimeSlots: IGroupedAvailableTimeSlots;
  onSelect: (time: IFormattedTimeSlot) => void;
  isLoading?: boolean;
}

export const AvailableTimeSlots: FC<AvailableTimeSlotsProps> = props => {
  const { availableTimeSlots, selectedTimeSlots, isLoading, onSelect } = props;
  return (
    <Box display='flex' flexDirection='column' gap={"25px"}>
      <SlotsSection
        isLoading={isLoading}
        label='Morning'
        timeSlots={availableTimeSlots.morningSlots}
        selectedTimeSlots={selectedTimeSlots}
        onSelect={onSelect}
        icon={<MorningIcon />}
      />
      <SlotsSection
        isLoading={isLoading}
        label='Afternoon'
        timeSlots={availableTimeSlots.afternoonSlots}
        selectedTimeSlots={selectedTimeSlots}
        onSelect={onSelect}
        icon={<AfterNoonIcon />}
      />
      <SlotsSection
        isLoading={isLoading}
        label='Evening'
        timeSlots={availableTimeSlots.eveningSlots}
        selectedTimeSlots={selectedTimeSlots}
        onSelect={onSelect}
        icon={<EveningIcon />}
      />
    </Box>
  );
};
