import { useNotificationSound } from "@/domains/notifications";
import { getApolloContextFromToken } from "@/providers/auth";
import {
  useGetNotificationCountAfterNewNotification,
  useGetNotificationCountAfterReadAllNotification,
  useGetNotificationCountAfterReadANotification,
} from "@health/sse";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useUnReadMessagesCountQuery } from "./gql";

type UseGetNotificationsCount = {
  unreadNotificationsCount: number | undefined;
};

export const useGetNotificationsCount = (): UseGetNotificationsCount => {
  const auth = useAuth();
  const { data: notificationsCountData } = useUnReadMessagesCountQuery({
    context: getApolloContextFromToken(auth?.user?.access_token),
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    skip: !auth?.isAuthenticated,
  });
  const unreadNotifications = notificationsCountData?.me?.unReadMessagesCount ?? undefined;
  const { playNotificationSound } = useNotificationSound();

  const [unreadNotificationsCount, setUnReadNotificationsCount] = useState<number | undefined>(unreadNotifications);

  useEffect(() => {
    setUnReadNotificationsCount(unreadNotifications);
  }, [unreadNotifications]);

  useGetNotificationCountAfterReadANotification(({ data }) => {
    setUnReadNotificationsCount(data?.count);
  });

  useGetNotificationCountAfterReadAllNotification(({ data }) => {
    setUnReadNotificationsCount(data?.count);
  });

  useGetNotificationCountAfterNewNotification(({ data }) => {
    setUnReadNotificationsCount(data?.count);
    playNotificationSound();
  });

  return { unreadNotificationsCount };
};
