import { FC } from "react";
import { IGuidedCareTaskNotificationData } from "../../types";
import { getNotificationTextStyle } from "../../utils";
import { Typography, useTheme } from "@toolkit/ui";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { pickLocalizedValue } from "@toolkit/i18n";
import { ApprovedNotificationIcon } from "@/components";

type GuidedCareTaskNotificationCardProps = {
  data: IGuidedCareTaskNotificationData;
  creationDate: string;
  isSeen: boolean;
};

export const GuidedCareTaskNotificationCard: FC<GuidedCareTaskNotificationCardProps> = props => {
  const { data, creationDate, isSeen } = props;

  const theme = useTheme();
  const { color } = getNotificationTextStyle(isSeen, theme);

  return (
    <NotificationCard
      title={pickLocalizedValue(data?.notificationTitleEn, data?.notificationTitleAr || data?.notificationTitleEn)}
      date={creationDate}
      icon={<ApprovedNotificationIcon />}
      isSeen={isSeen}
    >
      <Typography style={{ color }} alignSelf={"flex-start"}>
        {pickLocalizedValue(data?.notificationMessageEn, data?.notificationMessageAr || data?.notificationMessageEn)}
      </Typography>
    </NotificationCard>
  );
};
