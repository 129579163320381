import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const RotationCycleIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='45' viewBox='0 0 44 45'>
      <g id='Group_9642' data-name='Group 9642' transform='translate(-286.566 -850)'>
        <ellipse
          id='Ellipse_2620'
          data-name='Ellipse 2620'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(286.566 850)'
          fill={palette.success.main}
        />
        <path
          id='icons8-update-left-rotation_2_'
          data-name='icons8-update-left-rotation (2)'
          d='M13.125,3A10.51,10.51,0,0,0,2.669,12.625H0l3.5,5.25L7,12.625H4.418a8.752,8.752,0,0,1,14.39-5.78.875.875,0,1,0,1.136-1.33A10.471,10.471,0,0,0,13.125,3ZM22.75,9.125l-3.5,5.25h2.582a8.752,8.752,0,0,1-14.39,5.78.875.875,0,1,0-1.136,1.33,10.494,10.494,0,0,0,17.274-7.109H26.25Z'
          transform='translate(295.566 859)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
