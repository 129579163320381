import { SvgIconProps, SvgIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EmptyDoctorsIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='137.533' height='135.118' viewBox='0 0 137.533 135.118' {...props}>
      <defs>
        <linearGradient id='linear-gradient' x1='-0.006' y1='166.154' x2='0.996' y2='166.154' gradientUnits='objectBoundingBox'>
          <stop offset='0' stop-color='#cbcfdd' />
          <stop offset='1' stop-color='#eef0f1' />
        </linearGradient>
        <linearGradient id='linear-gradient-2' x1='9.729' y1='166.154' x2='10.731' y2='166.154' />
        <linearGradient id='linear-gradient-3' x1='-0.009' y1='138.544' x2='0.992' y2='138.544' />
      </defs>
      <g id='No_Avaliable_Doctors' data-name='No Avaliable Doctors' transform='translate(0 0)'>
        <g id='Group_12081' data-name='Group 12081'>
          <g id='Group_12080' data-name='Group 12080'>
            <path
              id='Path_12746_00000121959265908568702120000003119399686751359141_'
              d='M110.58,198.63c-15.678-3.546-29.832-9.146-42.866-19.1-12.91-9.923-24.762-24.108-25.228-38.636-.311-14.5,10.67-29.272,22.646-40.875a119.781,119.781,0,0,1,40.067-26.5c15.429-6.377,33.876-10.545,46.475-3.857,12.536,6.75,19.225,24.357,22.989,40.658,3.826,16.269,4.7,31.232,1.9,46.786s-9.3,31.574-21.184,38.884c-11.883,7.4-29.086,6.035-44.795,2.644'
              transform='translate(-42.479 -66.252)'
              fill={fill}
              opacity='0.09'
            />
          </g>
        </g>
        <g id='Group_12089' data-name='Group 12089' transform='translate(8.561 37.529)'>
          <g id='Group_12088' data-name='Group 12088' transform='translate(0 4.325)'>
            <g id='Pharmacist_00000139286014915037752060000009361723119253678014_' transform='translate(0 30.05)'>
              <path
                id='Path_15155'
                data-name='Path 15155'
                d='M126.038,314.478v14.87H71.6v-14.87a11.243,11.243,0,0,1,5.195-9.457c1.12-.715.778-.5,14.372-6.222h15.274l12.443,5.226A11.224,11.224,0,0,1,126.038,314.478Z'
                transform='translate(-71.102 -298.364)'
                fill='#fff'
              />
              <path
                id='Path_15156'
                data-name='Path 15156'
                d='M185.9,299.733c-2.115-.871-2.24-.933-2.209-.933Z'
                transform='translate(-148.323 -298.364)'
                fill='url(#linear-gradient)'
              />
              <path
                id='Path_15157'
                data-name='Path 15157'
                d='M134.5,298.869a6.665,6.665,0,0,0,6.657,6.657h1.96a6.665,6.665,0,0,0,6.657-6.657h0C149.8,298.838,150.147,298.869,134.5,298.869Z'
                transform='translate(-114.436 -298.403)'
                fill='#dee1ec'
                stroke='#d3d9ed'
                stroke-width='1'
              />
              <path
                id='Path_15158'
                data-name='Path 15158'
                d='M71.033,328.452H70.1V313.614a11.7,11.7,0,0,1,7.4-10.857L90.04,297.5h7.621v.933h-7.4l-12.381,5.195a10.849,10.849,0,0,0-6.844,10.017v14.807Z'
                transform='translate(-70.069 -297.469)'
                fill='#535783'
              />
              <path
                id='Path_15159'
                data-name='Path 15159'
                d='M190.915,301.582l-.715-.311.187-.435c-.031-.031-.28-.093,0,0l.187-.436.715.28Z'
                transform='translate(-152.809 -299.467)'
                fill='#5f6276'
              />
              <path
                id='Path_15160'
                data-name='Path 15160'
                d='M114.383,298.9v30.517h-2.426l-3.608-10.545H105.3l4.946-18.26Z'
                transform='translate(-94.319 -298.433)'
                fill='#d3d9ed'
              />
              <path
                id='Path_15161'
                data-name='Path 15161'
                d='M183.7,298.9v30.517h2.426l3.577-10.545h3.049l-4.915-18.26Z'
                transform='translate(-148.331 -298.433)'
                fill='#d3d9ed'
              />
              <path
                id='Path_15162'
                data-name='Path 15162'
                d='M110.213,328.539,106.729,318.3H103.4l5.164-19.069,5.475-1.835-.5,30.983h-.933v-29.8l-3.266,1.369L104.613,317.4h2.769l3.733,10.857Z'
                transform='translate(-93.01 -297.4)'
                fill='#535783'
              />
              <path
                id='Path_15163'
                data-name='Path 15163'
                d='M185.529,328.539l-.9-.28,3.733-10.857h2.769L186.4,299.951l-3.3-1.369v29.8h-.9l.529-30.983,4.448,1.835,5.164,19.069h-3.329Z'
                transform='translate(-147.297 -297.4)'
                fill='#535783'
              />
              <path
                id='Path_15164'
                data-name='Path 15164'
                d='M134.5,298.9a6.665,6.665,0,0,0,6.657,6.657h1.96a6.665,6.665,0,0,0,6.657-6.657h0v30.548H134.5Z'
                transform='translate(-114.436 -298.433)'
                fill='#535783'
              />
              <path
                id='Path_15165'
                data-name='Path 15165'
                d='M149.207,329.417h-.933v-27a7.189,7.189,0,0,1-6.19,3.608h-1.96a7.124,7.124,0,0,1-6.19-3.608v27.033H133V298.9h.933a6.187,6.187,0,0,0,6.19,6.19h1.96a6.187,6.187,0,0,0,6.19-6.19h.933Z'
                transform='translate(-113.402 -298.433)'
                fill='#535783'
              />
              <path
                id='Path_15166'
                data-name='Path 15166'
                d='M125.4,328.383h-.933V313.514a10.9,10.9,0,0,0-6.813-10.017L105.276,298.3H90.158L77.777,303.5a10.881,10.881,0,0,0-6.844,10.017v14.869H70V313.514a11.608,11.608,0,0,1,7.4-10.857L90.967,297.4h14.4l12.536,5.257a11.639,11.639,0,0,1,7.4,10.857v14.869Z'
                transform='translate(-70 -297.4)'
                fill='#535783'
              />
            </g>
            <g id='Group_12084' data-name='Group 12084' transform='translate(9.868 28.414)'>
              <g id='Group_12082' data-name='Group 12082' transform='translate(4.807)'>
                <path
                  id='Path_15167'
                  data-name='Path 15167'
                  d='M118.364,312.254a1.1,1.1,0,0,1-.342-.809c-.591-6.781-3.733-16.86,7.559-19.287.684-.093.653.187,1.089.311.218.062.187.155.28.28.093.093.218.249.187.373-.031.218.156.529-.187.653s-.467,0-1.058.093c-9.519,1.68-6.159,14.434-5.942,16.86s.062.622.062.653c.093.9-.156.809-.5,1.12a.314.314,0,0,1-.249.093c-.062.031-.062-.031-.093-.031h-.093C118.738,312.534,118.582,312.534,118.364,312.254Z'
                  transform='translate(-117.174 -292.14)'
                  fill={fill}
                />
              </g>
              <g id='Group_12083' data-name='Group 12083' transform='translate(21.306 0.093)'>
                <path
                  id='Path_15168'
                  data-name='Path 15168'
                  d='M178.327,312.741a1.09,1.09,0,0,0,.373-.778c.809-6.781,4.293-16.736-6.906-19.5-.653-.093-.684.187-1.089.28-.218.062-.187.156-.28.249s-.218.249-.187.373c.031.218-.156.529.156.653.342.156.467,0,1.058.124,9.457,1.991,5.693,14.621,5.413,17.047-.311,2.426-.093.622-.093.653-.124.9.156.809.467,1.12a.613.613,0,0,0,.249.124c.062.031.062-.031.093-.031h.093C177.923,312.989,178.109,313.021,178.327,312.741Z'
                  transform='translate(-170.212 -292.44)'
                  fill={fill}
                />
              </g>
              <path
                id='Path_15169'
                data-name='Path 15169'
                d='M105.168,363.876a.91.91,0,0,1-.715.311l-1.369.093a.993.993,0,0,1-1.058-.9l-.28-3.453a7.148,7.148,0,0,1,1.68-5.288,6.882,6.882,0,0,1,3.049-2.084,7.872,7.872,0,0,1,1.929-.4,7.25,7.25,0,0,1,7.777,6.657l.28,3.453a.978.978,0,0,1-.218.747.91.91,0,0,1-.715.311l-1.369.124a.983.983,0,1,1-.156-1.96l.373-.031-.187-2.458a5.227,5.227,0,0,0-10.421.84l.187,2.458.4-.031a.979.979,0,0,1,.747.218.91.91,0,0,1,.311.715A1.087,1.087,0,0,1,105.168,363.876Z'
                transform='translate(-101.722 -333.465)'
                fill={fill}
              />
              <path
                id='Path_15170'
                data-name='Path 15170'
                d='M178.187,358.169a4.189,4.189,0,1,0,4.386-3.982A4.24,4.24,0,0,0,178.187,358.169Zm1.991.093V358.2a2.348,2.348,0,0,1,2.3-2.022,2.2,2.2,0,1,1-2.3,2.084Z'
                transform='translate(-154.396 -334.881)'
                fill={fill}
              />
              <circle
                id='Ellipse_3180'
                data-name='Ellipse 3180'
                cx='2.178'
                cy='2.178'
                r='2.178'
                transform='translate(25.813 21.327)'
                fill='#d3d9ed'
              />
            </g>
            <g id='Group_12087' data-name='Group 12087' transform='translate(12.972)'>
              <g id='Group_12086' data-name='Group 12086' transform='translate(7.061 23.548)'>
                <g id='Group_12085' data-name='Group 12085'>
                  <path
                    id='Path_15171'
                    data-name='Path 15171'
                    d='M140.093,306.779h3.888a4.3,4.3,0,0,0,3.982-2.644l1.867-4.511s-.093-1.306-4.013-1.524l-7.59.4a8.147,8.147,0,0,0-3.826.871l1.68,4.542A4.184,4.184,0,0,0,140.093,306.779Z'
                    transform='translate(-134.4 -291.381)'
                    fill='#fff'
                  />
                  <rect
                    id='Rectangle_8139'
                    data-name='Rectangle 8139'
                    width='7.684'
                    height='11.51'
                    transform='translate(3.64)'
                    fill='#fff'
                  />
                  <line
                    id='Line_1838'
                    data-name='Line 1838'
                    y2='11.51'
                    transform='translate(3.64)'
                    fill='none'
                    stroke='#535783'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='4'
                  />
                  <line
                    id='Line_1839'
                    data-name='Line 1839'
                    y2='11.51'
                    transform='translate(11.323)'
                    fill='none'
                    stroke='#535783'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='4'
                  />
                </g>
              </g>
              <path
                id='Path_15172'
                data-name='Path 15172'
                d='M132.934,231.417A11.617,11.617,0,0,1,121.3,219.782v-5.848a11.634,11.634,0,0,1,23.269,0v5.848a11.636,11.636,0,0,1-11.634,11.634Z'
                transform='translate(-118.314 -201.833)'
                fill='#fff'
              />
              <path
                id='Path_15173'
                data-name='Path 15173'
                d='M115.72,239.8h-.5a2.53,2.53,0,0,0-2.022,2.458v1.649a2.447,2.447,0,0,0,.715,1.742l.778.778a2.131,2.131,0,0,0,1.027.591C115.689,246.426,115.72,240.764,115.72,239.8Z'
                transform='translate(-112.733 -227.668)'
                fill='#fff'
              />
              <path
                id='Path_15174'
                data-name='Path 15174'
                d='M115.215,246.606a4.171,4.171,0,0,1-1.866-.9l-.778-.778a2.944,2.944,0,0,1-.871-2.084v-1.649a2.991,2.991,0,0,1,2.4-2.893h1.027C115.153,246.419,115.122,245.3,115.215,246.606Zm-1-7.373a2.065,2.065,0,0,0-1.587,1.991v1.649a1.979,1.979,0,0,0,.591,1.4,10.819,10.819,0,0,0,1,.964Z'
                transform='translate(-111.7 -226.635)'
                fill='#535783'
              />
              <path
                id='Path_15175'
                data-name='Path 15175'
                d='M196.1,239.8h.5a2.53,2.53,0,0,1,2.022,2.458v1.649a2.447,2.447,0,0,1-.715,1.742l-.778.778a2.131,2.131,0,0,1-1.027.591C196.131,246.426,196.1,240.764,196.1,239.8Z'
                transform='translate(-169.845 -227.668)'
                fill='#fff'
              />
              <path
                id='Path_15176'
                data-name='Path 15176'
                d='M194.4,246.537c.093-1.338.093-.031.093-8.337h1.027a2.99,2.99,0,0,1,2.458,2.893v1.649a2.944,2.944,0,0,1-.871,2.084C195.769,246.1,195.893,246.1,194.4,246.537Zm1-1.4a11.466,11.466,0,0,0,1-.964,2,2,0,0,0,.591-1.431v-1.649A2.058,2.058,0,0,0,195.4,239.1Z'
                transform='translate(-168.674 -226.566)'
                fill='#535783'
              />
              <path
                id='Path_15177'
                data-name='Path 15177'
                d='M131.9,230.85a12.094,12.094,0,0,1-12.1-12.1V212.9a12.1,12.1,0,1,1,24.2,0v5.848A12.134,12.134,0,0,1,131.9,230.85Zm0-29.148a11.178,11.178,0,0,0-11.168,11.168v5.848a11.168,11.168,0,0,0,22.335,0V212.9A11.185,11.185,0,0,0,131.9,201.7Z'
                transform='translate(-117.28 -200.8)'
                fill='#535783'
              />
            </g>
          </g>
          <path
            id='Path_15178'
            data-name='Path 15178'
            d='M82.4,213.961a1.8,1.8,0,0,1,1.711-1.369c2.551-.311,2.769-2.862,2.551-9.768-.093-1.493-.093-2.955-.093-4.666,0-3.08.964-5.288,2.644-6.47,2.457-1.742,7.061.249,7.466.4a12.492,12.492,0,0,1,9.986-5.195,13.791,13.791,0,0,1,13.159,13.781A2.017,2.017,0,0,1,117.8,202.7h-4.884a15.117,15.117,0,0,1-9.954-3.422v.031a11.683,11.683,0,0,1-7.87,5.164v10.577a4.19,4.19,0,0,1,.031,1.058c-.342,1.68-2.706,3.484-6.564,3.391a5.181,5.181,0,0,1-3.08-.964C83.46,217.476,82.091,215.36,82.4,213.961Z'
            transform='translate(-78.514 -186.896)'
            fill={fill}
          />
        </g>
        <g id='Group_12096' data-name='Group 12096' transform='translate(79.455 39.49)'>
          <g id='Pharmacist_00000171719841606118950610000006845167499876465059_' transform='translate(0 32.725)'>
            <path
              id='Path_15179'
              data-name='Path 15179'
              d='M306.555,305.026,319,299.8h15.274c13.594,5.724,13.252,5.506,14.372,6.221a11.211,11.211,0,0,1,5.195,9.457v14.869H299.4V315.478A11.239,11.239,0,0,1,306.555,305.026Z'
              transform='translate(-298.933 -299.364)'
              fill='#fff'
            />
            <path
              id='Path_15180'
              data-name='Path 15180'
              d='M357.509,299.8c.031,0-.093.062-2.209.933Z'
              transform='translate(-337.444 -299.364)'
              fill='url(#linear-gradient-2)'
            />
            <path
              id='Path_15181'
              data-name='Path 15181'
              d='M362.488,299.869h0a6.665,6.665,0,0,0,6.657,6.657h1.96a6.665,6.665,0,0,0,6.657-6.657C362.114,299.869,362.457,299.838,362.488,299.869Z'
              transform='translate(-342.392 -299.403)'
              fill='#dee1ec'
              stroke='#d3d9ed'
              stroke-width='1'
            />
            <path
              id='Path_15182'
              data-name='Path 15182'
              d='M414.028,329.383V314.545a10.849,10.849,0,0,0-6.844-10.017l-12.381-5.2h-7.4V298.4h7.621l12.536,5.257a11.763,11.763,0,0,1,7.4,10.857v14.869Z'
              transform='translate(-359.559 -298.4)'
              fill='#535783'
            />
            <path
              id='Path_15183'
              data-name='Path 15183'
              d='M352.4,301.58l.715-.28.187.436c.28-.093.031-.031,0,0l.187.435-.716.311Z'
              transform='translate(-335.446 -300.398)'
              fill='#5f6276'
            />
            <path
              id='Path_15184'
              data-name='Path 15184'
              d='M415.737,301.611l4.915,18.26h-3.017L414,330.417h-2.4V299.9Z'
              transform='translate(-376.231 -299.433)'
              fill='#d3d9ed'
            />
            <path
              id='Path_15185'
              data-name='Path 15185'
              d='M338.215,301.611l-4.915,18.26h3.049l3.577,10.546h2.426V299.9Z'
              transform='translate(-322.288 -299.433)'
              fill='#d3d9ed'
            />
            <path
              id='Path_15186'
              data-name='Path 15186'
              d='M411.424,329.228l3.733-10.825h2.737l-4.7-17.451-3.266-1.4v29.832H409l-.5-30.983,5.475,1.835,5.164,19.069H415.81l-3.484,10.234Z'
              transform='translate(-374.095 -298.4)'
              fill='#535783'
            />
            <path
              id='Path_15187'
              data-name='Path 15187'
              d='M334.629,319.3H331.3l5.133-19.069,4.448-1.835.56,30.983h-.9V299.551l-3.3,1.4L332.513,318.4h2.769l3.7,10.825-.871.311Z'
              transform='translate(-320.91 -298.4)'
              fill='#535783'
            />
            <path
              id='Path_15188'
              data-name='Path 15188'
              d='M377.774,330.348H362.5V299.8h0a6.665,6.665,0,0,0,6.657,6.657h1.96a6.665,6.665,0,0,0,6.657-6.657v30.548Z'
              transform='translate(-342.404 -299.364)'
              fill='#535783'
            />
            <path
              id='Path_15189'
              data-name='Path 15189'
              d='M361,299.9h.933a6.187,6.187,0,0,0,6.19,6.19h1.96a6.187,6.187,0,0,0,6.19-6.19h.933v30.517h-.933V303.384a7.123,7.123,0,0,1-6.19,3.608h-1.96a7.188,7.188,0,0,1-6.19-3.608v27.033H361Z'
              transform='translate(-341.371 -299.433)'
              fill='#535783'
            />
            <path
              id='Path_15190'
              data-name='Path 15190'
              d='M297.993,329.383V314.514a11.608,11.608,0,0,1,7.4-10.857l12.536-5.257h14.4l13.563,5.257a11.639,11.639,0,0,1,7.4,10.857v14.869h-.933V314.514a10.881,10.881,0,0,0-6.844-10.017L333.145,299.3H318.027L305.646,304.5a10.9,10.9,0,0,0-6.813,10.017v14.869h-.84Z'
              transform='translate(-297.9 -298.4)'
              fill='#535783'
            />
          </g>
          <g id='Group_12092' data-name='Group 12092' transform='translate(10.303 31.4)'>
            <g id='Group_12090' data-name='Group 12090' transform='translate(4.807)'>
              <path
                id='Path_15191'
                data-name='Path 15191'
                d='M347.664,314.254a1.1,1.1,0,0,1-.342-.809c-.591-6.781-3.733-16.86,7.559-19.287.684-.093.653.187,1.089.311.218.062.187.156.28.28.093.093.218.249.187.373-.031.218.156.529-.187.653s-.467,0-1.058.093c-9.519,1.68-6.159,14.434-5.942,16.86s.062.622.062.653c.093.9-.156.809-.5,1.12a.314.314,0,0,1-.249.093c-.062,0-.062-.031-.093-.031h-.093C348.038,314.534,347.851,314.534,347.664,314.254Z'
                transform='translate(-346.474 -294.14)'
                fill={fill}
              />
            </g>
            <g id='Group_12091' data-name='Group 12091' transform='translate(21.274 0.062)'>
              <path
                id='Path_15192'
                data-name='Path 15192'
                d='M407.527,314.641a1.09,1.09,0,0,0,.373-.778c.809-6.781,4.293-16.736-6.906-19.5-.653-.093-.684.187-1.089.28-.218.062-.187.156-.28.249s-.218.249-.187.373c.031.218-.156.529.156.653.342.156.467,0,1.058.124,9.457,1.991,5.693,14.621,5.413,17.047-.311,2.426-.093.622-.093.653-.124.9.156.809.467,1.12a.614.614,0,0,0,.249.124c.062.031.062-.031.093-.031h.093C407.154,314.921,407.34,314.952,407.527,314.641Z'
                transform='translate(-399.411 -294.34)'
                fill={fill}
              />
            </g>
            <path
              id='Path_15193'
              data-name='Path 15193'
              d='M334.468,365.876a.911.911,0,0,1-.715.311l-1.369.093a.993.993,0,0,1-1.058-.9l-.28-3.453a7.147,7.147,0,0,1,1.68-5.288,6.882,6.882,0,0,1,3.049-2.084,7.875,7.875,0,0,1,1.929-.4,7.25,7.25,0,0,1,7.777,6.657l.28,3.453a.979.979,0,0,1-.218.747.91.91,0,0,1-.715.311l-1.369.124a.983.983,0,1,1-.156-1.96l.373-.031-.187-2.457a5.227,5.227,0,0,0-10.421.84l.187,2.458.4-.031a.978.978,0,0,1,.747.218.911.911,0,0,1,.311.716A1.305,1.305,0,0,1,334.468,365.876Z'
              transform='translate(-331.022 -335.465)'
              fill={fill}
            />
            <path
              id='Path_15194'
              data-name='Path 15194'
              d='M407.487,360.169a4.189,4.189,0,1,0,4.386-3.982A4.24,4.24,0,0,0,407.487,360.169Zm1.991.093V360.2a2.348,2.348,0,0,1,2.3-2.022,2.2,2.2,0,1,1-2.3,2.084Z'
              transform='translate(-383.696 -336.881)'
              fill={fill}
            />
            <circle
              id='Ellipse_3181'
              data-name='Ellipse 3181'
              cx='2.178'
              cy='2.178'
              r='2.178'
              transform='translate(25.781 21.296)'
              fill='#d3d9ed'
            />
          </g>
          <g id='Group_12095' data-name='Group 12095' transform='translate(13.19)'>
            <g id='Group_12094' data-name='Group 12094' transform='translate(6.844 26.224)'>
              <g id='Group_12093' data-name='Group 12093'>
                <path
                  id='Path_15195'
                  data-name='Path 15195'
                  d='M376.05,305.017l1.68-4.542a8.147,8.147,0,0,0-3.826-.871l-7.59-.4c-3.92.218-4.013,1.524-4.013,1.524l1.866,4.511a4.3,4.3,0,0,0,3.982,2.644h3.888A4.391,4.391,0,0,0,376.05,305.017Z'
                  transform='translate(-362.3 -292.45)'
                  fill='#fff'
                />
                <rect
                  id='Rectangle_8140'
                  data-name='Rectangle 8140'
                  width='7.684'
                  height='11.51'
                  transform='translate(4.044)'
                  fill='#fff'
                />
                <line
                  id='Line_1840'
                  data-name='Line 1840'
                  y1='11.51'
                  transform='translate(11.728)'
                  fill='none'
                  stroke='#535783'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='4'
                />
                <line
                  id='Line_1841'
                  data-name='Line 1841'
                  y1='11.51'
                  transform='translate(4.044)'
                  fill='none'
                  stroke='#535783'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='4'
                />
              </g>
            </g>
            <path
              id='Path_15196'
              data-name='Path 15196'
              d='M361.634,232.417A11.617,11.617,0,0,1,350,220.782v-5.848a11.634,11.634,0,1,1,23.268,0v5.848a11.636,11.636,0,0,1-11.634,11.634Z'
              transform='translate(-346.982 -200.158)'
              fill='#fff'
            />
            <path
              id='Path_15197'
              data-name='Path 15197'
              d='M424.8,247.917a2.284,2.284,0,0,0,1.027-.591l.778-.778a2.447,2.447,0,0,0,.716-1.742v-1.649A2.589,2.589,0,0,0,425.3,240.7h-.5C424.8,241.7,424.831,247.357,424.8,247.917Z'
              transform='translate(-398.514 -225.924)'
              fill='#fff'
            />
            <path
              id='Path_15198'
              data-name='Path 15198'
              d='M423.093,239.3h1.027a2.965,2.965,0,0,1,2.4,2.893v1.649a2.944,2.944,0,0,1-.871,2.084l-.778.778a3.87,3.87,0,0,1-1.866.9C423.093,246.3,423.093,247.419,423.093,239.3Zm.933,6.906a10.817,10.817,0,0,0,1-.964,1.858,1.858,0,0,0,.591-1.4v-1.649a2.065,2.065,0,0,0-1.586-1.991Z'
              transform='translate(-397.274 -224.959)'
              fill='#535783'
            />
            <path
              id='Path_15199'
              data-name='Path 15199'
              d='M344.32,247.917a2.284,2.284,0,0,1-1.027-.591l-.778-.778a2.447,2.447,0,0,1-.715-1.742v-1.649a2.589,2.589,0,0,1,2.022-2.457h.5Z'
              transform='translate(-341.333 -225.924)'
              fill='#fff'
            />
            <path
              id='Path_15200'
              data-name='Path 15200'
              d='M341.171,245.826a2.945,2.945,0,0,1-.871-2.084v-1.649a2.985,2.985,0,0,1,2.458-2.893h1.027c0,8.306-.031,6.968.093,8.337C342.322,247.1,342.478,247.1,341.171,245.826Zm1.649-5.693a2.032,2.032,0,0,0-1.586,1.991v1.649a2.134,2.134,0,0,0,.591,1.431,11.472,11.472,0,0,0,1,.964Z'
              transform='translate(-340.3 -224.89)'
              fill='#535783'
            />
            <path
              id='Path_15201'
              data-name='Path 15201'
              d='M348.5,219.749V213.9a12.1,12.1,0,1,1,24.2,0v5.848a12.1,12.1,0,1,1-24.2,0ZM360.6,202.7a11.145,11.145,0,0,0-11.168,11.2v5.817a11.168,11.168,0,1,0,22.335,0V213.87A11.178,11.178,0,0,0,360.6,202.7Z'
              transform='translate(-345.949 -199.125)'
              fill='#535783'
            />
            <path
              id='Path_15202'
              data-name='Path 15202'
              d='M366.793,205.245a3.717,3.717,0,0,0,3.7,3.733h2.3v-7h0a3.644,3.644,0,0,0-3.64-3.64h-.591a3.644,3.644,0,0,0-3.64-3.64h-9.643a7.68,7.68,0,0,0-7.684,7.684v6.408h14.123a3.769,3.769,0,0,0,2.644-1.089l2.426-2.457Z'
              transform='translate(-345.329 -194.233)'
              fill={fill}
            />
            <path
              id='Path_15203'
              data-name='Path 15203'
              d='M365.418,205.27l-1.773,1.773a4.164,4.164,0,0,1-2.955,1.213H346.1v-6.875a8.191,8.191,0,0,1,8.181-8.181h9.643A4.081,4.081,0,0,1,368,196.84h.187a4.1,4.1,0,0,1,4.106,4.106v7.466h-2.8A4.175,4.175,0,0,1,365.418,205.27Zm5.848-4.262a3.154,3.154,0,0,0-3.173-3.2h-1.058v-.467a3.166,3.166,0,0,0-3.173-3.173h-9.643A7.222,7.222,0,0,0,347,201.381v5.942h13.656a3.225,3.225,0,0,0,2.3-.964l3.266-3.266v1.12a3.246,3.246,0,0,0,3.266,3.266h1.866Z'
              transform='translate(-344.296 -193.2)'
              fill={fill}
            />
          </g>
        </g>
        <g id='Group_12103' data-name='Group 12103' transform='translate(39.295 26.674)'>
          <g id='Pharmacist_00000051367545359556890040000010729692836252341913_' transform='translate(0 39.071)'>
            <path
              id='Path_15204'
              data-name='Path 15204'
              d='M235.839,298.158v17.794H170.7V298.158a13.432,13.432,0,0,1,6.222-11.323c1.338-.84.933-.591,17.2-7.435h18.26l14.9,6.253A13.467,13.467,0,0,1,235.839,298.158Z'
              transform='translate(-170.109 -278.84)'
              fill='#fff'
            />
            <path
              id='Path_15205'
              data-name='Path 15205'
              d='M307.437,280.52c-2.52-1.058-2.675-1.12-2.644-1.12Z'
              transform='translate(-262.487 -278.84)'
              fill='url(#linear-gradient-3)'
            />
            <path
              id='Path_15206'
              data-name='Path 15206'
              d='M246,279.469a7.979,7.979,0,0,0,7.964,7.964H256.3a7.979,7.979,0,0,0,7.964-7.964h0C264.291,279.438,264.7,279.469,246,279.469Z'
              transform='translate(-221.985 -278.878)'
              fill='#dee1ec'
              stroke='#d3d9ed'
              stroke-width='1'
            />
            <path
              id='Path_15207'
              data-name='Path 15207'
              d='M170.02,314.749H168.9V296.987a14.064,14.064,0,0,1,8.866-13L192.76,277.7h9.115v1.12h-8.866L178.2,285.041a12.941,12.941,0,0,0-8.181,11.976v17.731Z'
              transform='translate(-168.869 -277.669)'
              fill='#535783'
            />
            <path
              id='Path_15208'
              data-name='Path 15208'
              d='M313.371,282.631l-.871-.373.218-.529c-.031-.031-.342-.124,0,0l.218-.529.871.342Z'
              transform='translate(-267.798 -280.08)'
              fill='#5f6276'
            />
            <path
              id='Path_15209'
              data-name='Path 15209'
              d='M221.957,279.5v36.489h-2.893l-4.324-12.6H211.1l5.91-21.837Z'
              transform='translate(-197.941 -278.909)'
              fill='#d3d9ed'
            />
            <path
              id='Path_15210'
              data-name='Path 15210'
              d='M304.8,279.5v36.489h2.893l4.293-12.6h3.64l-5.879-21.837Z'
              transform='translate(-262.494 -278.909)'
              fill='#d3d9ed'
            />
            <path
              id='Path_15211'
              data-name='Path 15211'
              d='M216.95,314.936l-4.168-12.225H208.8l6.159-22.8,6.564-2.209-.591,37.049h-1.12V279.1l-3.92,1.649-5.63,20.873h3.3l4.448,12.972Z'
              transform='translate(-196.357 -277.669)'
              fill='#535783'
            />
            <path
              id='Path_15212'
              data-name='Path 15212'
              d='M306.982,314.936l-1.058-.342,4.448-12.972h3.3l-5.63-20.873-3.951-1.649v35.649H303l.653-37.049,5.319,2.209,6.159,22.8H311.15Z'
              transform='translate(-261.254 -277.669)'
              fill='#535783'
            />
            <path
              id='Path_15213'
              data-name='Path 15213'
              d='M246,279.5a7.979,7.979,0,0,0,7.964,7.964H256.3a7.979,7.979,0,0,0,7.964-7.964h0v36.52H246Z'
              transform='translate(-221.985 -278.909)'
              fill='#535783'
            />
            <path
              id='Path_15214'
              data-name='Path 15214'
              d='M263.58,315.989h-1.12V283.7a8.6,8.6,0,0,1-7.4,4.324h-2.333a8.536,8.536,0,0,1-7.4-4.324V316.02H244.2V279.5h1.12a7.421,7.421,0,0,0,7.4,7.4h2.333a7.421,7.421,0,0,0,7.4-7.4h1.12Z'
              transform='translate(-220.745 -278.909)'
              fill='#535783'
            />
            <path
              id='Path_15215'
              data-name='Path 15215'
              d='M235.059,314.68h-1.12V296.887a12.991,12.991,0,0,0-8.15-11.976l-14.807-6.222H192.908L178.1,284.91a12.973,12.973,0,0,0-8.181,11.976V314.68H168.8V296.887a13.94,13.94,0,0,1,8.866-13L193.9,277.6h17.234l14.994,6.284a13.94,13.94,0,0,1,8.866,13V314.68Z'
              transform='translate(-168.8 -277.6)'
              fill='#535783'
            />
          </g>
          <g id='Group_12099' data-name='Group 12099' transform='translate(15.518 37.531)'>
            <g id='Group_12097' data-name='Group 12097' transform='translate(20.765)'>
              <path
                id='Path_15216'
                data-name='Path 15216'
                d='M295.971,296.68a1.331,1.331,0,0,0,.4-.964c.716-8.119,4.48-20.158-9.052-23.051-.809-.093-.809.249-1.307.373-.249.062-.218.218-.342.311a.6.6,0,0,0-.218.467c.062.249-.187.622.218.778s.56,0,1.275.124c11.385,1.991,7.373,17.265,7.124,20.189s-.062.747-.062.778c-.124,1.058.187.964.622,1.338.124.124.218.093.28.124s.062-.031.093-.031h.124C295.5,297.022,295.722,297.022,295.971,296.68Z'
                transform='translate(-285.437 -272.649)'
                fill={fill}
              />
            </g>
            <g id='Group_12098' data-name='Group 12098' transform='translate(1.452 0.051)'>
              <path
                id='Path_15217'
                data-name='Path 15217'
                d='M225.17,297.1c-.249-.342-.373-.342-.436-.933-1-8.088-5.133-20,8.275-23.331.809-.124.809.218,1.307.342.249.062.218.187.342.311a.79.79,0,0,1,.249.436c-.031.249.187.622-.218.778s-.56,0-1.275.156c-11.292,2.364-6.813,17.482-6.47,20.407.342,2.893.093.747.093.778.156,1.058-.156.964-.56,1.369a.348.348,0,0,1-.28.124c-.062.031-.062-.031-.093-.031h-.124A1.029,1.029,0,0,1,225.17,297.1Z'
                transform='translate(-223.35 -272.814)'
                fill={fill}
              />
            </g>
            <path
              id='Path_15218'
              data-name='Path 15218'
              d='M299.257,358.3a1.121,1.121,0,0,0,.84.373l1.649.124a1.217,1.217,0,0,0,1.275-1.089l.311-4.137a8.709,8.709,0,0,0-1.991-6.346,8.4,8.4,0,0,0-3.64-2.489,10.792,10.792,0,0,0-2.3-.5,8.707,8.707,0,0,0-6.346,2.053,8.439,8.439,0,0,0-2.955,5.879l-.311,4.137a1.2,1.2,0,0,0,.249.9,1.121,1.121,0,0,0,.84.373l1.649.124a1.186,1.186,0,0,0,.187-2.364l-.467-.031.218-2.955a6.182,6.182,0,0,1,2.209-4.262,6.11,6.11,0,0,1,4.542-1.493,6.182,6.182,0,0,1,4.262,2.209,6.11,6.11,0,0,1,1.493,4.542l-.218,2.955-.467-.031a1.2,1.2,0,0,0-.9.249,1.121,1.121,0,0,0-.373.84A1.241,1.241,0,0,0,299.257,358.3Z'
              transform='translate(-264.909 -321.949)'
              fill={fill}
            />
            <path
              id='Path_15219'
              data-name='Path 15219'
              d='M223.448,346.789a5.015,5.015,0,1,0,5.257,4.759A5.094,5.094,0,0,0,223.448,346.789Zm.373,7.621a2.616,2.616,0,1,1-.249-5.226,2.785,2.785,0,0,1,2.737,2.426v.062A2.674,2.674,0,0,1,223.822,354.41Z'
              transform='translate(-218.684 -323.722)'
              fill={fill}
            />
            <circle
              id='Ellipse_3182'
              data-name='Ellipse 3182'
              cx='2.613'
              cy='2.613'
              r='2.613'
              transform='translate(2.4 25.462)'
              fill='#d3d9ed'
            />
          </g>
          <g id='Group_12102' data-name='Group 12102' transform='translate(15.523)'>
            <g id='Group_12101' data-name='Group 12101' transform='translate(8.368 31.325)'>
              <g id='Group_12100' data-name='Group 12100'>
                <path
                  id='Path_15220'
                  data-name='Path 15220'
                  d='M252.475,288.89h4.635a5.151,5.151,0,0,0,4.759-3.173l2.24-5.382s-.124-1.555-4.822-1.835l-9.083.5a9.788,9.788,0,0,0-4.6,1.058l1.991,5.444A5.239,5.239,0,0,0,252.475,288.89Z'
                  transform='translate(-245.6 -270.474)'
                  fill='#fff'
                />
                <rect
                  id='Rectangle_8141'
                  data-name='Rectangle 8141'
                  width='9.208'
                  height='13.781'
                  transform='translate(4.417)'
                  fill='#fff'
                />
                <line
                  id='Line_1842'
                  data-name='Line 1842'
                  y2='13.781'
                  transform='translate(4.417)'
                  fill='none'
                  stroke='#535783'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='4'
                />
                <line
                  id='Line_1843'
                  data-name='Line 1843'
                  y2='13.781'
                  transform='translate(13.625)'
                  fill='none'
                  stroke='#535783'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='4'
                />
              </g>
            </g>
            <path
              id='Path_15221'
              data-name='Path 15221'
              d='M244.105,198.809A13.9,13.9,0,0,1,230.2,184.9v-7a13.905,13.905,0,1,1,27.81,0v7a13.88,13.88,0,0,1-13.905,13.905Z'
              transform='translate(-226.623 -160.267)'
              fill='#fff'
            />
            <path
              id='Path_15222'
              data-name='Path 15222'
              d='M223.517,208.8h-.591a3.026,3.026,0,0,0-2.426,2.924v1.96a2.944,2.944,0,0,0,.871,2.084l.933.933a2.684,2.684,0,0,0,1.213.715C223.486,216.732,223.517,209.951,223.517,208.8Z'
              transform='translate(-219.94 -191.131)'
              fill='#fff'
            />
            <path
              id='Path_15223'
              data-name='Path 15223'
              d='M222.9,217.023a4.716,4.716,0,0,1-2.24-1.089l-.933-.933a3.532,3.532,0,0,1-1.027-2.489v-1.96a3.531,3.531,0,0,1,2.862-3.453h1.244C222.837,216.806,222.806,215.437,222.9,217.023Zm-1.182-8.835a2.432,2.432,0,0,0-1.9,2.364v1.96a2.306,2.306,0,0,0,.715,1.68,15.859,15.859,0,0,0,1.182,1.151Z'
              transform='translate(-218.7 -189.96)'
              fill='#535783'
            />
            <path
              id='Path_15224'
              data-name='Path 15224'
              d='M319.7,208.8h.591a3.026,3.026,0,0,1,2.426,2.924v1.96a2.945,2.945,0,0,1-.871,2.084l-.933.933a2.684,2.684,0,0,1-1.213.715C319.731,216.732,319.7,209.951,319.7,208.8Z'
              transform='translate(-288.281 -191.131)'
              fill='#fff'
            />
            <path
              id='Path_15225'
              data-name='Path 15225'
              d='M317.6,216.954c.124-1.618.093-.031.093-9.954h1.213a3.556,3.556,0,0,1,2.924,3.453v1.96A3.532,3.532,0,0,1,320.8,214.9C319.249,216.426,319.4,216.426,317.6,216.954Zm1.213-1.68c.187-.124.156-.156,1.182-1.151a2.45,2.45,0,0,0,.715-1.711v-1.96a2.425,2.425,0,0,0-1.9-2.364Z'
              transform='translate(-286.835 -189.891)'
              fill='#535783'
            />
            <path
              id='Path_15226'
              data-name='Path 15226'
              d='M242.865,198.129A14.458,14.458,0,0,1,228.4,183.664v-7a14.465,14.465,0,0,1,28.93,0v7A14.5,14.5,0,0,1,242.865,198.129Zm0-34.841a13.342,13.342,0,0,0-13.345,13.345v7a13.345,13.345,0,1,0,26.69,0v-6.968a13.329,13.329,0,0,0-13.345-13.376Z'
              transform='translate(-225.383 -159.027)'
              fill='#535783'
            />
            <path
              id='Path_15227'
              data-name='Path 15227'
              d='M232.786,166.33l2.924,2.924a4.5,4.5,0,0,0,3.173,1.307h16.891v-7.652a9.207,9.207,0,0,0-9.208-9.208H235.026a4.348,4.348,0,0,0-4.355,4.355h-.715a4.348,4.348,0,0,0-4.355,4.355h0v8.368h2.769a4.4,4.4,0,0,0,4.417-4.448Z'
              transform='translate(-223.454 -153.171)'
              fill={fill}
            />
            <path
              id='Path_15228'
              data-name='Path 15228'
              d='M226.96,170.2H223.6V161.27a4.925,4.925,0,0,1,4.915-4.915h.218A4.914,4.914,0,0,1,233.617,152h11.541a9.784,9.784,0,0,1,9.768,9.768v8.212H237.474a5.014,5.014,0,0,1-3.546-1.462l-2.115-2.115A5.032,5.032,0,0,1,226.96,170.2Zm-2.24-1.12h2.24a3.9,3.9,0,0,0,3.92-3.92v-1.338l3.888,3.888a3.91,3.91,0,0,0,2.769,1.151h16.332v-7.093a8.649,8.649,0,0,0-8.648-8.648H233.679a3.79,3.79,0,0,0-3.8,3.8v.56h-1.275a3.777,3.777,0,0,0-3.8,3.826Z'
              transform='translate(-222.076 -152)'
              fill={fill}
            />
          </g>
        </g>
        <g id='Group_12104' data-name='Group 12104' transform='translate(22.777 110.136)'>
          <path
            id='Path_15229'
            data-name='Path 15229'
            d='M118.406,425.713a2.706,2.706,0,1,1,2.706-2.706h0A2.689,2.689,0,0,1,118.406,425.713Zm0-3.671a.964.964,0,1,0,.964.964h0A.969.969,0,0,0,118.406,422.042Z'
            transform='translate(-115.7 -420.3)'
            fill='#535783'
          />
          <path
            id='Path_15230'
            data-name='Path 15230'
            d='M156.5,452.073a3.686,3.686,0,1,1,3.671-3.7h0A3.664,3.664,0,0,1,156.5,452.073Zm0-5.693a1.991,1.991,0,1,0,1.991,1.991h0A1.985,1.985,0,0,0,156.5,446.38Z'
            transform='translate(-141.259 -437.11)'
            fill='#535783'
          />
        </g>
        <g id='Group_12105' data-name='Group 12105' transform='translate(53.823 104.536)'>
          <path
            id='Path_15231'
            data-name='Path 15231'
            d='M267.481,432.042H265.77V430.3h1.742v1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.573,0H252.02V430.3h1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.6,0h-1.742V430.3h1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.573,0h-1.711V430.3h1.742v1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.573,0h-1.742V430.3h1.742Zm-4.479,0h-1.742V430.3h1.742Zm-4.573,0H215.5V430.3h1.742Z'
            transform='translate(-215.5 -421.59)'
            fill='#aab5d3'
          />
          <path
            id='Path_15232'
            data-name='Path 15232'
            d='M217.242,408.459H215.5v-1.742h1.742Zm0-4.573H215.5V402.3h1.742Z'
            transform='translate(-215.5 -402.3)'
            fill='#aab5d3'
          />
        </g>
        <path
          id='Path_15233'
          data-name='Path 15233'
          d='M451.325,289.833l-1.555.062-.062-1.555,1.555-.062Zm-.156-4.137-1.555.062c-.031-.5-.031-.933-.093-1.524l1.555-.124c.062.622.062,1.058.093,1.586Zm-1.991-3.92c-.093-.5-.187-1-.311-1.493l1.524-.373a13.032,13.032,0,0,1,.342,1.586Zm-1.058-3.857a13.933,13.933,0,0,0-.591-1.369l1.4-.653a12.586,12.586,0,0,1,.622,1.493Zm-1.742-3.608c-.28-.436-.529-.84-.84-1.244l1.275-.9c.311.436.591.871.9,1.338Zm-2.4-3.2c-.311-.373-.684-.715-1.027-1.089l1.089-1.12a13.526,13.526,0,0,1,1.12,1.182Zm-2.924-2.675c-.373-.311-.809-.591-1.213-.9l.84-1.307c.435.28.9.622,1.307.933Zm-3.422-2.146a12.533,12.533,0,0,0-1.338-.591l.5-1.493a16.41,16.41,0,0,1,1.524.684Z'
          transform='translate(-313.792 -202.623)'
          fill='#aab5d3'
        />
        <g id='Group_12106' data-name='Group 12106' transform='translate(43.65 19.302)'>
          <path
            id='Path_15234'
            data-name='Path 15234'
            d='M214.115,132.531a2.092,2.092,0,1,1,1.151-.311A2.449,2.449,0,0,1,214.115,132.531Zm0-3.173a1.027,1.027,0,1,0,1,1.275,1,1,0,0,0-.778-1.213c-.062-.031-.156-.031-.218-.062Z'
            transform='translate(-202.917 -128.3)'
            fill='#535783'
          />
          <path
            id='Path_15235'
            data-name='Path 15235'
            d='M184.449,168.3a1.649,1.649,0,0,1,0-3.3,1.66,1.66,0,0,1,.9,3.049A1.973,1.973,0,0,1,184.449,168.3Zm0-2.178a.524.524,0,0,0-.529.529.545.545,0,0,0,.529.529.569.569,0,0,0,.529-.4.578.578,0,0,0-.4-.653Z'
            transform='translate(-182.8 -153.583)'
            fill='#535783'
          />
        </g>
        <rect
          id='Rectangle_8142'
          data-name='Rectangle 8142'
          width='102.531'
          height='1.866'
          transform='translate(5.606 102.67)'
          fill='#525682'
        />
        <path
          id='Path_15236'
          data-name='Path 15236'
          d='M352.858,343.253h-6.377c-2.084-7.715-2.178-16.269,7.4-23.953l1.182,2.613,7.279,18.789a22.653,22.653,0,0,1-9.488,2.551Z'
          transform='translate(-251.129 -240.583)'
          fill='#d3d9ed'
        />
        <g id='Group_12107' data-name='Group 12107' transform='translate(98.182 76.415)'>
          <circle
            id='Ellipse_3183'
            data-name='Ellipse 3183'
            cx='18.665'
            cy='18.665'
            r='18.665'
            transform='translate(0.964 0.964)'
            fill='#fff'
          />
          <path
            id='Path_15237'
            data-name='Path 15237'
            d='M377.729,351.158a19.629,19.629,0,1,1,19.629-19.629h0A19.648,19.648,0,0,1,377.729,351.158Zm0-37.329a17.7,17.7,0,1,0,17.7,17.7h0A17.69,17.69,0,0,0,377.729,313.829Z'
            transform='translate(-358.1 -311.9)'
            fill='#525682'
          />
          <rect
            id='Rectangle_8143'
            data-name='Rectangle 8143'
            width='20.998'
            height='7.124'
            transform='translate(9.646 24.465) rotate(-45)'
            fill='#2b3078'
          />
          <rect
            id='Rectangle_8144'
            data-name='Rectangle 8144'
            width='7.124'
            height='20.998'
            transform='translate(9.646 14.654) rotate(-45)'
            fill='#2b3078'
          />
        </g>
        <path id='Path_15238' data-name='Path 15238' d='M379.1,328.2' transform='translate(-274.385 -246.714)' fill='#d3d9ed' />
      </g>
    </SvgIcon>
  );
};
