import { Box, IconButton, PulseConnection, Typography, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { useStyle } from "./VisitStartingNowCardStyle";
import { PhoneReceiverIcon } from "@/components";
import { Outlet, useLocation } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import { useVisitStartingNowCard } from "./useVisitStartingNowCard";
import { useTranslation } from "@toolkit/i18n";

export const VisitStartingNowCard: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyle();
  const theme = useTheme();
  const location = useLocation();
  const blockedPagesRoutes = [routesPaths.visitCal];
  const isBlockedPage = blockedPagesRoutes.includes(location.pathname);
  const { t } = useTranslation("consumer");

  const { activeCall, isCallEndSubmitting, handleJoinNow, handleDecline } = useVisitStartingNowCard({ isBlockedPage });

  return (
    <Box className={classes.root}>
      {!isBlockedPage && activeCall?.id && (
        <Box className={classes.callBoxRoot}>
          <Box className={classes.callBox}>
            <IconButton disabled={!activeCall?.meetingPlatformLinkForJoinCall} className={classes.iconButton} onClick={handleJoinNow}>
              <PulseConnection width='55px' height='55px' disableAnimation={!activeCall?.meetingPlatformLinkForJoinCall} />
            </IconButton>
            <Box className={classes.textBox} color={theme.palette.success[1100]}>
              <Typography className={classes.mainText}>
                {activeCall.appointment?.id ? t("New Call Request") : t("Visit starting now")}
              </Typography>
              <Typography className={classes.subText}>{t("Tap to join")}</Typography>
            </Box>
            <Box className={classes.rightIconBox}>
              <IconButton disabled={isCallEndSubmitting} onClick={handleDecline} className={classes.phoneIconButton}>
                <PhoneReceiverIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
      {children}
      <Outlet />
    </Box>
  );
};
