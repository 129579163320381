import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DoctorIcon7: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "15.264"}
      height={height ? height : "20.352"}
      viewBox={viewBox ? viewBox : "0 0 15.264 20.352"}
      {...props}
    >
      <path
        id='icons8-doctor-male_7_'
        data-name='icons8-doctor-male (7)'
        d='M7.7,4a.848.848,0,0,0-.823.643A39.539,39.539,0,0,0,6,13.328a.848.848,0,0,0,.354.687A6.082,6.082,0,0,0,6,15.835V17.4a3.026,3.026,0,0,0,.967,2.291,1.5,1.5,0,0,0,.631.346,6.505,6.505,0,0,0,5.939,4.311.946.946,0,0,0,.194,0,6.506,6.506,0,0,0,5.934-4.311,1.5,1.5,0,0,0,.631-.346,3.026,3.026,0,0,0,.967-2.291V15.835a3.787,3.787,0,0,0-.482-1.744.848.848,0,0,0,.482-.764,39.539,39.539,0,0,0-.873-8.685A.848.848,0,0,0,19.568,4ZM8.4,5.7H18.862a38.351,38.351,0,0,1,.648,6.784H17.872a4.24,4.24,0,1,0-8.48,0H7.754A38.351,38.351,0,0,1,8.4,5.7Zm5.23,4.24a2.531,2.531,0,0,1,2.544,2.544,2.587,2.587,0,0,1-.048.485.848.848,0,0,0-.076.268,2.53,2.53,0,0,1-4.838,0,.848.848,0,0,0-.078-.275,2.586,2.586,0,0,1-.048-.484A2.531,2.531,0,0,1,13.632,9.936Zm0,1.7a.848.848,0,1,0,.848.848A.848.848,0,0,0,13.632,11.632Zm3.834,2.627a2.959,2.959,0,0,1,2.1,2.461V17.4a1.161,1.161,0,0,1-.389,1.015.862.862,0,0,1-.325.2l-.452.113-.147.441s-1.118,3.491-4.622,3.491-4.622-3.491-4.622-3.491l-.147-.441-.452-.113a.862.862,0,0,1-.325-.2A1.161,1.161,0,0,1,7.7,17.4V16.357c0-1.013,1.47-.281,2.547-1.36a4.207,4.207,0,0,0,7.223-.739Z'
        transform='translate(-6 -4)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
