import { makeStyles } from "@toolkit/ui";

export const useCommonSectionsStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "380px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      paddingInline: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingInline: "70px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingInline: "140px",
    },
  },
  container: {
    width: "100%",
    height: "100%",
    maxWidth: theme.breakpoints.values.xl,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingInline: "10px",
    },
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      "& .title__btn": {
        display: "none",
      },
      "& .title": {
        fontSize: theme?.mixins.fonts.fontSize.xxxl,
        paddingBlock: "10px",
      },
    },
  },
  title: {
    fontSize: theme?.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  titleSeeAll: {
    fontSize: theme?.mixins.fonts.fontSize.sm,
    color: theme.palette.secondary.main,
    padding: 0,
    margin: 0,
    minWidth: "fit-content",
  },
  seeAllButton: {
    background: `linear-gradient(212deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
}));
