import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const WishListHeartIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-love_2_'
      data-name='icons8-love (2)'
      width='16.57'
      height='14.54'
      viewBox='0 0 16.57 14.54'
      {...props}
    >
      <path
        id='Path_12972'
        data-name='Path 12972'
        d='M26.461,19.024a.637.637,0,0,1-.423-1.115,13.322,13.322,0,0,0,2.615-3.062,6.264,6.264,0,0,0,.994-3.174,3.425,3.425,0,0,0-3.4-3.4,3.764,3.764,0,0,0-3.086,1.975.637.637,0,0,1-1.051-.721A5,5,0,0,1,26.249,7a4.7,4.7,0,0,1,4.674,4.674A7.508,7.508,0,0,1,29.749,15.5a14.4,14.4,0,0,1-2.866,3.364A.635.635,0,0,1,26.461,19.024Z'
        transform='translate(-14.352 -7)'
        fill={fill}
      />
      <path
        id='Path_12973'
        data-name='Path 12973'
        d='M12.306,21.54a1.541,1.541,0,0,1-.689-.162l-.023-.012a19.479,19.479,0,0,1-5.19-4A8.59,8.59,0,0,1,4,11.674,4.7,4.7,0,0,1,8.674,7a4.12,4.12,0,0,1,2,.5.637.637,0,1,1-.6,1.122,2.838,2.838,0,0,0-1.4-.349,3.425,3.425,0,0,0-3.4,3.4,7.364,7.364,0,0,0,2.088,4.848A18.19,18.19,0,0,0,12.2,20.243a.263.263,0,0,0,.223,0,12.219,12.219,0,0,0,1.523-.963.637.637,0,1,1,.765,1.02,13.51,13.51,0,0,1-1.695,1.071L13,21.379A1.541,1.541,0,0,1,12.306,21.54Z'
        transform='translate(-4 -7)'
        fill={fill}
      />
      <path
        id='Path_12974'
        data-name='Path 12974'
        d='M21.737,12.459a.637.637,0,0,1-.523-1c.061-.088.121-.177.178-.263a8.348,8.348,0,0,1,1.352-1.622.637.637,0,1,1,.877.925,7.081,7.081,0,0,0-1.17,1.406c-.06.09-.122.182-.189.278A.637.637,0,0,1,21.737,12.459Z'
        transform='translate(-13.834 -8.38)'
        fill={fill}
      />
    </SvgIcon>
  );
};
