import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const GoogleIcon: FC<SvgIconProps> = props => {
  const { width, height } = props;
  return (
    <SvgIcon
      id='icons8-google_2_'
      data-name='icons8-google (2)'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "31.633"}
      height={height ? height : "31.633"}
      viewBox='0 0 31.633 31.633'
      {...props}
    >
      <path
        id='Path_12929'
        data-name='Path 12929'
        d='M35.326,16.719H34.052v-.066H19.817V22.98h8.939a9.5,9.5,0,1,1-2.643-10.25l4.474-4.474a15.8,15.8,0,1,0,5.047,11.56A15.924,15.924,0,0,0,35.326,16.719Z'
        transform='translate(-4 -4)'
        fill='#fbc02d'
      />
      <path
        id='Path_12930'
        data-name='Path 12930'
        d='M6.306,12.455l5.2,3.811A9.471,9.471,0,0,1,26.595,12.73l4.474-4.474a15.786,15.786,0,0,0-24.762,4.2Z'
        transform='translate(-4.482 -4)'
        fill='#e53935'
      />
      <path
        id='Path_12931'
        data-name='Path 12931'
        d='M20.276,40.664a15.743,15.743,0,0,0,10.6-4.106l-4.9-4.142a9.465,9.465,0,0,1-14.632-4.362L6.2,32.028A15.8,15.8,0,0,0,20.276,40.664Z'
        transform='translate(-4.459 -9.032)'
        fill='#4caf50'
      />
      <path
        id='Path_12932'
        data-name='Path 12932'
        d='M39.509,20.066,39.5,20H24v6.327h8.939a9.522,9.522,0,0,1-3.232,4.406l0,0,4.9,4.142a15.3,15.3,0,0,0,5.212-11.71A15.924,15.924,0,0,0,39.509,20.066Z'
        transform='translate(-8.183 -7.347)'
        fill='#1565c0'
      />
    </SvgIcon>
  );
};
