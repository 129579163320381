import { PageLayout } from "./PageLayout";
import { PageHeaderWithPack } from "../PageHeader";
import { PageLayoutProps } from "./type";

export const PageWithBackHeaderLayout = (props: PageLayoutProps) => {
  const { containerBackground, containerBreakpoint, withBlob = false, children } = props;
  return (
    <PageLayout
      containerBackground={containerBackground}
      containerBreakpoint={containerBreakpoint}
      displayBlob={withBlob}
      header={<PageHeaderWithPack />}
    >
      {children}
    </PageLayout>
  );
};
