import { FC, useCallback, useEffect, useRef, useState } from "react";
import AppointmentDoctorCardSkeleton from "../../components/AppointmentDoctorCard/DoctorCardSkeleton";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import { Box, CustomInfiniteScroll, Typography } from "@toolkit/ui";
import { useGetAvailableDoctorsList } from "./useGetAvailableDoctorsList";
import { useTranslation } from "@/i18n/i18n.config";
import { useStyles } from "./AppointmentListContainer.styles";
import { getDoctorInfo } from "../../utils";
import { AppointmentAppointmentListFiltersForm } from "../../forms/AppointmentsListFilters/AppointmentsListFiltersForm";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { IDoctorInfo } from "../../types/types";
import { EmptyDoctorsIcon, EmptyMessageLayout } from "@/components";

export const AppointmentsListContainer: FC = () => {
  const { doctors, fetchMore, loading, pageInfo, onFilterValuesChange, refetchDoctors } = useGetAvailableDoctorsList();
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  const navigate = useNavigate();
  const { onDoctorInfoChange, onVisitTypeChange } = useAppointmentBookingContext();
  const handleOnActionCLick = useCallback((doctorInfo: IDoctorInfo) => {
    return (visitType: "ONSITE" | "ONLINE" | "AT_HOME") => {
      onVisitTypeChange(visitType);
      onDoctorInfoChange(doctorInfo);
      navigate(routes.schedule.getRouteWithParams({ visitType, doctorId: doctorInfo?.doctorId || "" }));
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(entries => {
        setContainerHeight(entries[0].contentRect.height);
      });

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.title}>
        {t("Book an Appointment")}
      </Typography>
      <Box className={classes.filterContainer}>
        <AppointmentAppointmentListFiltersForm onSubmit={onFilterValuesChange} />
      </Box>
      <Box ref={containerRef} className={cx(classes.content, !loading && !doctors?.length && classes.contentForEmptyPage)}>
        {loading && !doctors?.length ? (
          Array.from({ length: 3 }).map((_, index) => <AppointmentDoctorCardSkeleton key={index} classes={{ card: classes.listCard }} />)
        ) : !doctors?.length ? (
          <Box className={classes.emptyPageContainer}>
            <EmptyMessageLayout
              icon={<EmptyDoctorsIcon />}
              message={t("No doctors found")}
              subMessage={t("Please try adjusting your search/filter to find what you are looking for.")}
            />
          </Box>
        ) : (
          <CustomInfiniteScroll
            onRefresh={() => {
              refetchDoctors();
            }}
            height={containerHeight ? containerHeight - 10 : undefined}
            dataLength={Number(doctors?.length) || 0}
            onFetchMore={fetchMore}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<AppointmentDoctorCardSkeleton classes={{ card: classes.listCard }} />}
          >
            {doctors?.map(doctor => (
              <Box key={doctor?.id} marginInline={"10px"}>
                <AppointmentDoctorCard
                  {...getDoctorInfo(doctor)}
                  isVirtualVisitDisabled={!doctor?.appointmentTypes?.some(val => val! === "ONLINE")}
                  isAtHomeDisabled={!doctor?.appointmentTypes?.some(val => val! === "AT_HOME")}
                  isOnSiteDisabled={!doctor?.appointmentTypes?.some(val => val! === "ONSITE")}
                  hasActions={true}
                  handleOnActionCLick={handleOnActionCLick(getDoctorInfo(doctor))}
                />
              </Box>
            ))}
          </CustomInfiniteScroll>
        )}
      </Box>
    </Box>
  );
};
