import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { getNotificationTextStyle } from "../../utils";
import { useAuth } from "react-oidc-context";
import { NotificationBellIcon } from "@/components";

type ChronicProgramEnrolmentNotificationProps = {
  programName: string;
  creationDate: string;
  isSeen: boolean;
};

export const ChronicProgramEnrolmentNotification: FC<ChronicProgramEnrolmentNotificationProps> = ({
  programName,
  creationDate,
  isSeen,
}) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  const { user } = useAuth();
  const userName = user?.profile?.name;

  const { color } = getNotificationTextStyle(isSeen, theme);

  return (
    <NotificationCard title={t("Program Enrollment")} date={creationDate} icon={<NotificationBellIcon />} isSeen={isSeen}>
      <Box>
        <Typography sx={{ color }}>
          {t(
            "Hi {{userName}}, Following your recent diagnosis and thorough examination, your doctor has reviewed your case. It is recommended that you follow established medical guidelines to avoid future complications. To better manage this condition we would like to enroll you into {{programName}} care plan. For any concerns, please feel free to ask your doctor or your assigned care team coordinator.",
            { userName, programName }
          )}
        </Typography>
      </Box>
    </NotificationCard>
  );
};
