import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const FoodIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "21px"}
      height={height ? height : "21px"}
      viewBox={viewBox ? viewBox : "0 0 21 21"}
      {...props}
    >
      <path
        id='icons8-hamper'
        d='M7.812,3a.875.875,0,1,0,0,1.75h1.75a.875.875,0,1,0,0-1.75Zm15.1,0c-.964.076-2.29,1.431-4.484,4.662L19.4,9.007l1.227-.06a1.466,1.466,0,0,1-.719.759l1.179,1.62c2.494-4.683,3.631-7.479,2.439-8.177A1.028,1.028,0,0,0,22.908,3ZM7.375,6.5V9.125a3.9,3.9,0,0,0-2.625,3.5h7.875A3.9,3.9,0,0,0,10,9.125V6.5Zm7.219.073a1.236,1.236,0,0,0-.439.173L11.928,8.163a5.284,5.284,0,0,1,2.447,4.462h5.489L15.819,7.062A1.236,1.236,0,0,0,14.594,6.573Zm7.275.15a1.3,1.3,0,0,1-.959.834c-.514.074-.7-.383-.5-.764ZM3.875,14.375a.875.875,0,1,0,0,1.75h1.75a.875.875,0,0,1,0,1.75H3.964A8.107,8.107,0,0,0,8.8,24H18.2a8.107,8.107,0,0,0,4.833-6.125H12.625a.875.875,0,1,1,0-1.75h10.5a.875.875,0,1,0,0-1.75Z'
        transform='translate(-3 -3)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
