import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const SehaCityIcon: FC<IconProps> = ({ width = 90.122, height = 16.288, viewBox = "0 0 90.122 16.288", ...props }) => {
  return (
    <SvgIcon color={"inherit"} width={width} height={height} viewBox={viewBox} {...props} xmlns='http://www.w3.org/2000/svg'>
      <g id='Group_6860' data-name='Group 6860' transform='translate(0 -1.471)'>
        <g id='Group_5834' data-name='Group 5834' transform='translate(0 1.471)'>
          <path
            id='Path_3840'
            data-name='Path 3840'
            d='M15.924,13.886l-.957.957-.955.957-.957.955-.954.957-1.515,1.514a4.618,4.618,0,0,1-6.53,0L3.5,18.667l-.957-.954-.9-.9-.057-.059A5.408,5.408,0,0,1,9.231,9.108l.059.057-.957.957-.955.957-.06-.059A2.7,2.7,0,0,0,3.5,14.843l.057.059,2.291,2.29a2.087,2.087,0,0,0,2.951,0l2.349-2.349,1.912-1.912.957-.957a1.352,1.352,0,1,1,1.912,1.912'
            transform='translate(0 -7.524)'
            fill='#1b2346'
          />
          <path
            id='Path_3841'
            data-name='Path 3841'
            d='M43.67,9.108a5.411,5.411,0,0,0-7.65,0l-.955.955-.957.957-.957.954-.494.5a.651.651,0,0,1-.922,0l-.437-.436-.059-.06a1.352,1.352,0,0,0-1.912,1.912l1.684,1.684a1.676,1.676,0,0,0,2.371,0l2.638-2.638.957-.957.955-.954c.032-.033.068-.065.1-.095.054-.049.108-.095.165-.138a2.559,2.559,0,0,1,.273-.184,1.955,1.955,0,0,1,.235-.124,2.636,2.636,0,0,1,.368-.141c.084-.024.17-.046.254-.062a2.767,2.767,0,0,1,1.033,0c.084.016.17.038.254.062a2.643,2.643,0,0,1,.368.141,1.953,1.953,0,0,1,.235.124,2.532,2.532,0,0,1,.273.184c.057.043.111.089.165.138.033.03.068.062.1.095a2.7,2.7,0,0,1,0,3.824s-4.666,5.069-4.936,5.4A5.336,5.336,0,0,1,31.8,22l.843.744a3.5,3.5,0,0,0,4.991.038L39.721,20.7l.054-.054.515-.515.51-.509.509-.51.894-.894.508-.507.957-.957a5.407,5.407,0,0,0,0-7.647'
            transform='translate(-24.877 -7.524)'
            fill='#83d147'
          />
        </g>
        <g id='logo_darck_blue_v1' data-name='logo darck blue v1' transform='translate(24.2 2.079)'>
          <g id='Group_5266' data-name='Group 5266' transform='translate(0)'>
            <path
              id='Path_3843'
              data-name='Path 3843'
              d='M26.51,34.873l.74-1.464a5.544,5.544,0,0,0,2.866.817c1.217,0,1.725-.339,1.725-.91,0-1.572-5.084-.093-5.084-3.373,0-1.557,1.4-2.573,3.621-2.573a6.534,6.534,0,0,1,3.082.709l-.74,1.464a4.509,4.509,0,0,0-2.357-.617c-1.171,0-1.725.386-1.725.925,0,1.665,5.084.185,5.084,3.405,0,1.54-1.417,2.543-3.729,2.543A6.548,6.548,0,0,1,26.51,34.873Z'
              transform='translate(-26.51 -23.796)'
              fill='#1b2346'
            />
            <path
              id='Path_3844'
              data-name='Path 3844'
              d='M83.539,32.192H77.084a2.49,2.49,0,0,0,2.666,1.971,2.953,2.953,0,0,0,2.234-.893l1.033,1.186A4.172,4.172,0,0,1,79.7,35.8c-2.758,0-4.544-1.773-4.544-4.221a4.092,4.092,0,0,1,4.253-4.206,4.013,4.013,0,0,1,4.16,4.253C83.57,31.776,83.555,32.009,83.539,32.192Zm-6.47-1.279h4.667a2.24,2.24,0,0,0-2.327-2A2.274,2.274,0,0,0,77.069,30.914Z'
              transform='translate(-66.963 -23.796)'
              fill='#1b2346'
            />
            <path
              id='Path_3845'
              data-name='Path 3845'
              d='M144.153,15.618v4.715h-1.926V15.864c0-1.464-.725-2.172-1.956-2.172a2.185,2.185,0,0,0-2.295,2.48v4.16H136.05V8.9h1.926v4.16a3.671,3.671,0,0,1,2.743-1.048C142.689,12.012,144.153,13.138,144.153,15.618Z'
              transform='translate(-117.593 -8.438)'
              fill='#1b2346'
            />
            <path
              id='Path_3846'
              data-name='Path 3846'
              d='M202.663,30.876V35.7h-1.818v-1a2.914,2.914,0,0,1-2.588,1.109c-1.88,0-3.067-1.033-3.067-2.465,0-1.372.925-2.45,3.421-2.45h2.126v-.123c0-1.124-.677-1.788-2.049-1.788a4.069,4.069,0,0,0-2.5.817l-.755-1.4a5.786,5.786,0,0,1,3.481-1.016C201.293,27.378,202.663,28.5,202.663,30.876Zm-1.926,2.249V32.17H198.75c-1.309,0-1.665.494-1.665,1.094,0,.693.585,1.141,1.572,1.141A2.1,2.1,0,0,0,200.737,33.126Z'
              transform='translate(-166.768 -23.804)'
              fill='#1b2346'
            />
            <path
              id='Path_3847'
              data-name='Path 3847'
              d='M250.6,31.586a4.162,4.162,0,0,1,4.452-4.206,3.7,3.7,0,0,1,3.513,1.911l-1.479.863a2.33,2.33,0,0,0-2.049-1.141,2.414,2.414,0,0,0-2.5,2.573,2.406,2.406,0,0,0,2.5,2.573,2.326,2.326,0,0,0,2.049-1.141l1.479.863a3.743,3.743,0,0,1-3.513,1.926A4.175,4.175,0,0,1,250.6,31.586Z'
              transform='translate(-212.841 -23.804)'
              fill='#83d147'
            />
            <path
              id='Path_3848'
              data-name='Path 3848'
              d='M306.19,7.316a1.174,1.174,0,0,1,1.233-1.156,1.145,1.145,0,0,1,1.232,1.109,1.234,1.234,0,0,1-2.465.047Zm.263,2.511h1.926v8.228h-1.926Z'
              transform='translate(-259.064 -6.16)'
              fill='#83d147'
            />
            <path
              id='Path_3849'
              data-name='Path 3849'
              d='M333.719,26.729a3.03,3.03,0,0,1-1.848.554,2.527,2.527,0,0,1-2.834-2.774v-3.96H327.68V19.01h1.356V17.13h1.926v1.88h2.2v1.54h-2.2v3.913c0,.8.4,1.232,1.141,1.232a1.733,1.733,0,0,0,1.078-.339Z'
              transform='translate(-276.933 -15.282)'
              fill='#83d147'
            />
            <path
              id='Path_3850'
              data-name='Path 3850'
              d='M373.113,27.92l-3.852,8.89c-1.051,2.174-2.189,2.642-3.105,2.492a1.9,1.9,0,0,1-1.5-2.229h0a2.086,2.086,0,0,0,1.417.57c.677,0,1.078-.324,1.449-1.141l.138-.324L364.07,27.92h2l2.588,6.086,2.6-6.086h1.848Z'
              transform='translate(-307.191 -24.253)'
              fill='#83d147'
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
