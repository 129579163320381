/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePaymentCheckoutIdMutationVariables = Types.Exact<{
  input: Types.PaymentCheckoutInput;
}>;


export type CreatePaymentCheckoutIdMutation = { __typename?: 'Mutation', checkout?: { __typename?: 'PaymentCheckoutResponse', id?: string | null, shopperResultUrl?: string | null, checkoutPaymentFormUrl?: string | null, merchantTransactionId?: string | null, result?: { __typename?: 'PaymentResponseResult', code?: string | null, description?: string | null } | null } | null };


export const CreatePaymentCheckoutIdDocument = gql`
    mutation CreatePaymentCheckoutId($input: PaymentCheckoutInput!) {
  checkout: processPaymentCheckout(paymentCheckoutInput: $input) {
    id
    shopperResultUrl
    checkoutPaymentFormUrl
    merchantTransactionId
    result {
      code
      description
    }
  }
}
    `;
export type CreatePaymentCheckoutIdMutationFn = Apollo.MutationFunction<CreatePaymentCheckoutIdMutation, CreatePaymentCheckoutIdMutationVariables>;

/**
 * __useCreatePaymentCheckoutIdMutation__
 *
 * To run a mutation, you first call `useCreatePaymentCheckoutIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentCheckoutIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentCheckoutIdMutation, { data, loading, error }] = useCreatePaymentCheckoutIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentCheckoutIdMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentCheckoutIdMutation, CreatePaymentCheckoutIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentCheckoutIdMutation, CreatePaymentCheckoutIdMutationVariables>(CreatePaymentCheckoutIdDocument, options);
      }
export type CreatePaymentCheckoutIdMutationHookResult = ReturnType<typeof useCreatePaymentCheckoutIdMutation>;
export type CreatePaymentCheckoutIdMutationResult = Apollo.MutationResult<CreatePaymentCheckoutIdMutation>;
export type CreatePaymentCheckoutIdMutationOptions = Apollo.BaseMutationOptions<CreatePaymentCheckoutIdMutation, CreatePaymentCheckoutIdMutationVariables>;