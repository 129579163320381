import React, { FC } from "react";
import { SvgIconProps } from "../../base/mui";
export const LikeIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='49.5' height='46.895' viewBox='0 0 49.5 46.895'>
      <g id='icons8-facebook-like-skin-type-2' transform='translate(-5 -6)'>
        <path
          id='Path_4580'
          data-name='Path 4580'
          d='M24.738,18H44.331V49.263H24.738a7.736,7.736,0,0,1-7.657-7.811V25.816A7.735,7.735,0,0,1,24.738,18Z'
          transform='translate(3.656 3.632)'
          fill='#ffb74d'
        />
        <path
          id='Path_4581'
          data-name='Path 4581'
          d='M38.416,25.539a3.908,3.908,0,0,0,3.908,3.908h3.908a3.908,3.908,0,0,0,3.908-3.908h0a3.908,3.908,0,0,0-3.908-3.908H42.324a3.908,3.908,0,0,0-3.908,3.908Zm-3.908,7.816a3.908,3.908,0,0,0,3.908,3.908h7.816a3.908,3.908,0,0,0,3.908-3.908h0a3.908,3.908,0,0,0-3.908-3.908H38.416a3.908,3.908,0,0,0-3.908,3.908Zm-1.3,7.816a3.908,3.908,0,0,0,3.908,3.908h7.816a3.908,3.908,0,0,0,3.908-3.908h0a3.908,3.908,0,0,0-3.908-3.908H37.113a3.908,3.908,0,0,0-3.908,3.908Zm-1.3,7.816a3.908,3.908,0,0,0,3.908,3.908h7.816a3.908,3.908,0,0,0,3.908-3.908h0a3.908,3.908,0,0,0-3.908-3.908H35.811A3.908,3.908,0,0,0,31.9,48.987ZM26.075,15.856a11.018,11.018,0,0,0,2.5-4.73l.943-3.849A1.655,1.655,0,0,1,31.117,6H32c2.32,0,3.866,2.605,3.866,4.29V12.1A21.466,21.466,0,0,1,33.5,22.619l-3.421,6.2L19.408,23.265Z'
          transform='translate(4.36)'
          fill='#ffb74d'
        />
        <path
          id='Path_4582'
          data-name='Path 4582'
          d='M43.118,24H37.908a1.3,1.3,0,0,0,0,2.605Zm-1.3,7.816H36.605a1.3,1.3,0,0,0,0,2.605Zm-1.3,7.816H35.3a1.3,1.3,0,0,0,0,2.605Z'
          transform='translate(8.776 5.447)'
          fill='#ffa726'
        />
        <path id='Path_4583' data-name='Path 4583' d='M21.934,47.658H8.908L5,19H21.934Z' transform='translate(0 3.934)' fill='#1e2446' />
        <path
          id='Path_4584'
          data-name='Path 4584'
          d='M13.605,34a2.605,2.605,0,1,0,2.605,2.605A2.6,2.6,0,0,0,13.605,34Z'
          transform='translate(1.816 8.474)'
          fill='#f3e5f5'
        />
      </g>
    </svg>
  );
};
