import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CircularIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8906'
      data-name='Group 8906'
      sx={{
        width: 44,
        height: 44,
      }}
      viewBox='0 0 44 44'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Group_9473' data-name='Group 9473' transform='translate(-328.066 -312)'>
        <ellipse
          id='Ellipse_2660'
          data-name='Ellipse 2660'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(328.066 312)'
          fill={palette.success.main}
        />
        <path
          id='icons8-update-left-rotation_2_'
          data-name='icons8-update-left-rotation (2)'
          d='M13.125,3A10.51,10.51,0,0,0,2.669,12.625H0l3.5,5.25L7,12.625H4.418a8.752,8.752,0,0,1,14.39-5.78.875.875,0,1,0,1.136-1.33A10.471,10.471,0,0,0,13.125,3ZM22.75,9.125l-3.5,5.25h2.582a8.752,8.752,0,0,1-14.39,5.78.875.875,0,1,0-1.136,1.33,10.494,10.494,0,0,0,17.274-7.109H26.25Z'
          transform='translate(337.066 321)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
