import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const HomeIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.339' height='17.339' viewBox='0 0 17.339 17.339' {...props}>
      <path
        id='icons8-home'
        d='M11.669,2a.722.722,0,0,0-.505.206L3.284,8.652l-.027.021L3.23,8.7h0a.722.722,0,0,0,.492,1.25h.722v7.947A1.445,1.445,0,0,0,5.89,19.339H17.449a1.445,1.445,0,0,0,1.445-1.445V9.947h.722A.723.723,0,0,0,20.109,8.7L20.1,8.687q-.024-.022-.049-.041L18.894,7.7V4.89a.723.723,0,0,0-.722-.722h-.722a.723.723,0,0,0-.722.722V5.93L12.159,2.193A.722.722,0,0,0,11.669,2Zm2.167,9.392h2.89v5.78h-2.89Z'
        transform='translate(-3 -2)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
