import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const FilterTimeIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9633' data-name='Group 9633' transform='translate(-296 -761)'>
        <circle id='Ellipse_2619' data-name='Ellipse 2619' cx='22' cy='22' r='22' transform='translate(296 761)' fill='#e05729' />
        <path
          id='icons8-filter'
          d='M3.875,4a.875.875,0,1,0,0,1.75h.7l6.3,7.875h5.25l6.3-7.875h.7a.875.875,0,1,0,0-1.75Zm7,11.375V23.25l5.25-1.75V15.375Z'
          transform='translate(305 771)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
