import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const EmailIcon: FC<SvgIconProps> = ({ fill, width, height, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20px"}
      height={height ? height : "12.923px"}
      viewBox='0 0 20.103 12.923'
      {...props}
    >
      <path
        id='icons8-mail'
        d='M2.1,6,10.2,13.554a1.253,1.253,0,0,0,1.708,0L20,6ZM1,6.934V18.923H21.1V6.934L12.886,14.6a2.694,2.694,0,0,1-3.668,0Z'
        transform='translate(-1 -6)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
