import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PlayIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8906'
      data-name='Group 8906'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        id='icons8-play-button-circled_1_'
        data-name='icons8-play-button-circled (1)'
        d='M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3Zm5.3,12.4L12.7,19.794c-.383.221-.7.04-.7-.4V10.607c0-.442.313-.623.7-.4L20.3,14.6A.426.426,0,0,1,20.3,15.4Z'
        transform='translate(-3 -3)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
