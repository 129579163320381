import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const RingIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='45' viewBox='0 0 44 45'>
      <g id='Group_9634' data-name='Group 9634' transform='translate(-296 -820)'>
        <ellipse
          id='Ellipse_2620'
          data-name='Ellipse 2620'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(296 820)'
          fill={palette.success.main}
        />
        <path
          id='icons8-alarm'
          d='M13,3a1.692,1.692,0,0,0-1.692,1.692V4.94a5.924,5.924,0,0,0-4.231,5.675v2.334a8.665,8.665,0,0,1-2.538,6.128.846.846,0,0,0,0,1.692H21.462a.846.846,0,0,0,0-1.692,8.665,8.665,0,0,1-2.538-6.128V10.615A5.924,5.924,0,0,0,14.692,4.94V4.692A1.692,1.692,0,0,0,13,3ZM3.654,7.221a.846.846,0,0,0-.719.478A9.2,9.2,0,0,0,2,11.462a9.2,9.2,0,0,0,.935,3.763.846.846,0,0,0,1.514-.757,8.365,8.365,0,0,1-.757-3.006,8.365,8.365,0,0,1,.757-3.006.846.846,0,0,0-.795-1.235Zm18.667,0a.846.846,0,0,0-.77,1.235,8.365,8.365,0,0,1,.757,3.006,8.365,8.365,0,0,1-.757,3.006.846.846,0,0,0,1.514.757A9.2,9.2,0,0,0,24,11.462,9.2,9.2,0,0,0,23.065,7.7.846.846,0,0,0,22.321,7.221ZM10.462,22.462a2.538,2.538,0,1,0,5.077,0Z'
          transform='translate(305 829)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
