import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const ExerciseIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      viewBox={viewBox ? viewBox : "0 0 24 24"}
      {...props}
    >
      <path
        id='icons8-exercise'
        d='M24.5,3A2.5,2.5,0,1,0,27,5.5,2.5,2.5,0,0,0,24.5,3ZM15.947,4a1,1,0,0,0-.143.018l-5,1a1,1,0,0,0,.324,1.973l4.811-.578L17.42,8l-4.295,3.414a2.495,2.495,0,0,0-.516,3.719l2.762,3.453L10,19h0l-.023,0A1,1,0,1,0,10,21h7.439a1.587,1.587,0,0,0,1.4-2.205l.012-.012L17,14.662l3.123-2.471.914,2.078a1,1,0,0,0,.113.258l.006.012h0A1,1,0,0,0,22,15h4a1,1,0,1,0,.008-2l-3.238-.264-.8-3.631a2.5,2.5,0,0,0-.066-.3l0,0a2.5,2.5,0,0,0-1.111-1.441L16.6,4.2A1,1,0,0,0,15.947,4ZM6,9,5,10h5.686l1.26-1ZM3,12v1H9.049a4.493,4.493,0,0,1,.229-1Zm1,3,1,1H9.787a4.529,4.529,0,0,1-.52-1Zm10,8-1.795,2.393a1,1,0,0,0,1.559,1.254l0,0,0,0L16.9,23Z'
        transform='translate(-3 -3)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
