import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const MorningIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  theme.palette.primary.main;
  const fillValue = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26.5' height='26.5' viewBox='0 0 26.5 26.5' {...props}>
      <g id='icons8-sun' transform='translate(-4 -4)'>
        <path
          id='Line_1596'
          data-name='Line 1596'
          d='M-.506,2.475A.994.994,0,0,1-1.5,1.481V-.506A.994.994,0,0,1-.506-1.5a.994.994,0,0,1,.994.994V1.481A.994.994,0,0,1-.506,2.475Z'
          transform='translate(17.756 5.5)'
          fill={fillValue}
        />
        <path
          id='Line_1597'
          data-name='Line 1597'
          d='M.885,1.879a.991.991,0,0,1-.7-.291L-1.209.2a.994.994,0,0,1,0-1.405.994.994,0,0,1,1.405,0L1.588.182a.994.994,0,0,1-.7,1.7Z'
          transform='translate(9.078 9.078)'
          fill={fillValue}
        />
        <path
          id='Line_1598'
          data-name='Line 1598'
          d='M1.481.488H-.506A.994.994,0,0,1-1.5-.506.994.994,0,0,1-.506-1.5H1.481a.994.994,0,0,1,.994.994A.994.994,0,0,1,1.481.488Z'
          transform='translate(5.5 17.756)'
          fill={fillValue}
        />
        <path
          id='Line_1599'
          data-name='Line 1599'
          d='M-.506,1.879a.991.991,0,0,1-.7-.291.994.994,0,0,1,0-1.405L.182-1.209a.994.994,0,0,1,1.405,0A.994.994,0,0,1,1.588.2L.2,1.588A.991.991,0,0,1-.506,1.879Z'
          transform='translate(9.078 25.044)'
          fill={fillValue}
        />
        <path
          id='Line_1600'
          data-name='Line 1600'
          d='M-.506,2.475A.994.994,0,0,1-1.5,1.481V-.506A.994.994,0,0,1-.506-1.5a.994.994,0,0,1,.994.994V1.481A.994.994,0,0,1-.506,2.475Z'
          transform='translate(17.756 28.025)'
          fill={fillValue}
        />
        <path
          id='Line_1601'
          data-name='Line 1601'
          d='M.885,1.879a.991.991,0,0,1-.7-.291L-1.209.2a.994.994,0,0,1,0-1.405.994.994,0,0,1,1.405,0L1.588.182a.994.994,0,0,1-.7,1.7Z'
          transform='translate(25.044 25.044)'
          fill={fillValue}
        />
        <path
          id='Line_1602'
          data-name='Line 1602'
          d='M1.481.488H-.506A.994.994,0,0,1-1.5-.506.994.994,0,0,1-.506-1.5H1.481a.994.994,0,0,1,.994.994A.994.994,0,0,1,1.481.488Z'
          transform='translate(28.025 17.756)'
          fill={fillValue}
        />
        <path
          id='Line_1603'
          data-name='Line 1603'
          d='M-.506,1.879a.991.991,0,0,1-.7-.291.994.994,0,0,1,0-1.405L.182-1.209a.994.994,0,0,1,1.405,0A.994.994,0,0,1,1.588.2L.2,1.588A.991.991,0,0,1-.506,1.879Z'
          transform='translate(25.044 9.078)'
          fill={fillValue}
        />
        <path
          id='Path_12994'
          data-name='Path 12994'
          d='M20.288,29.7A7.327,7.327,0,0,1,13,22.414a6.763,6.763,0,0,1,.469-2.489.994.994,0,1,1,1.845.738,4.758,4.758,0,0,0-.326,1.751,5.34,5.34,0,0,0,5.3,5.3,8.123,8.123,0,0,0,1.706-.241.994.994,0,0,1,.431,1.94A9.835,9.835,0,0,1,20.288,29.7Z'
          transform='translate(-3.038 -5.164)'
          fill={fillValue}
        />
        <path
          id='Path_12995'
          data-name='Path 12995'
          d='M28.174,23.335a.994.994,0,0,1-.943-1.308,5.647,5.647,0,0,0,.28-1.74,5.34,5.34,0,0,0-5.3-5.3,5.331,5.331,0,0,0-2.135.454.994.994,0,0,1-.764-1.835,7.323,7.323,0,0,1,2.9-.606A7.327,7.327,0,0,1,29.5,20.288a7.688,7.688,0,0,1-.382,2.368A.994.994,0,0,1,28.174,23.335Z'
          transform='translate(-4.961 -3.037)'
          fill={fillValue}
        />
      </g>
    </SvgIcon>
  );
};
