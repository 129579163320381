import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from "../../base/mui";
import DOMPurify from 'dompurify';

interface InlineSVGProps extends BoxProps {
  src?: string;
  svgString?: string;
  width?: string | number;
  height?: string | number;
  alt?: string;
}

export const InlineSVG: React.FC<InlineSVGProps> = ({
  src,
  svgString,
  width = '24px',
  height = '24px',
  alt = '',
  ...props
}) => {
  const [svgContent, setSvgContent] = useState<string>('');

  useEffect(() => {
    let isMounted = true;

    const fetchSVG = async () => {
      if (src) {
        try {
          const response = await fetch(src);
          const text = await response.text();
          if (isMounted) {
            setSvgContent(DOMPurify.sanitize(text));
          }
        } catch (error) {
          console.error('Failed to fetch the SVG:', error);
        }
      }
    };

    if (src) {
      fetchSVG();
    } else if (svgString) {
      setSvgContent(DOMPurify.sanitize(svgString));
    }

    return () => {
      isMounted = false;
    };
  }, [src, svgString]);

  if (!src && !svgString) {
    console.error('You must provide either a `src` or `svgString` prop.');
    return null;
  }

  return (
    <Box
      component="span"
      dangerouslySetInnerHTML={{ __html: svgContent }}
      aria-label={alt}
      {...props}
    />
  );
};
