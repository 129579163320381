import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const AdjustIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "82.2"}
      height={height ? height : "82.315"}
      viewBox={viewBox ? viewBox : "0 0 82.2 82.315"}
      {...props}
    >
      <path
        id='icons8-adjust'
        d='M12.3,1.972a2.038,2.038,0,0,0-.218.054A1.747,1.747,0,0,0,10.717,3.77v12.2H7.23A5.258,5.258,0,0,0,2,21.2v3.487a5.258,5.258,0,0,0,5.23,5.23H17.691a5.258,5.258,0,0,0,5.23-5.23V21.2a5.258,5.258,0,0,0-5.23-5.23H14.2V3.77a1.745,1.745,0,0,0-1.907-1.8Zm29.639,0a2.038,2.038,0,0,0-.218.054A1.747,1.747,0,0,0,40.356,3.77V52.587H36.87a5.258,5.258,0,0,0-5.23,5.23V61.3a5.258,5.258,0,0,0,5.23,5.23H47.33a5.258,5.258,0,0,0,5.23-5.23V57.818a5.258,5.258,0,0,0-5.23-5.23H43.843V3.77a1.745,1.745,0,0,0-1.907-1.8Zm29.639,0a2.038,2.038,0,0,0-.218.054A1.747,1.747,0,0,0,70,3.77V21.2H66.509a5.258,5.258,0,0,0-5.23,5.23v3.487a5.258,5.258,0,0,0,5.23,5.23H76.969a5.258,5.258,0,0,0,5.23-5.23V26.435a5.258,5.258,0,0,0-5.23-5.23H73.483V3.77a1.745,1.745,0,0,0-1.907-1.8ZM7.23,19.461H17.691A1.713,1.713,0,0,1,19.435,21.2v3.487a1.713,1.713,0,0,1-1.743,1.743H7.23a1.713,1.713,0,0,1-1.743-1.743V21.2A1.713,1.713,0,0,1,7.23,19.461Zm59.278,5.23H76.969a1.713,1.713,0,0,1,1.743,1.743v3.487a1.713,1.713,0,0,1-1.743,1.743H66.509a1.713,1.713,0,0,1-1.743-1.743V26.435A1.713,1.713,0,0,1,66.509,24.692ZM10.717,31.666V43.543a1.313,1.313,0,0,0,0,.327V80.483a1.744,1.744,0,1,0,3.487,0V43.87a1.313,1.313,0,0,0,0-.327V31.666ZM70,36.9V48.774a1.312,1.312,0,0,0,0,.327V80.483a1.744,1.744,0,1,0,3.487,0V49.1a1.312,1.312,0,0,0,0-.327V36.9ZM36.87,56.074H47.33a1.713,1.713,0,0,1,1.743,1.743V61.3a1.713,1.713,0,0,1-1.743,1.743H36.87A1.713,1.713,0,0,1,35.126,61.3V57.818A1.713,1.713,0,0,1,36.87,56.074Zm3.487,12.2v3.16a1.313,1.313,0,0,0,0,.327v8.717a1.744,1.744,0,1,0,3.487,0V71.766a1.313,1.313,0,0,0,0-.327v-3.16Z'
        transform='translate(-1 -0.962)'
        fill={palette.common.white}
        stroke={palette.common.white}
        strokeWidth='2'
      />
    </SvgIcon>
  );
};
