import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const MedsIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width ? width : "71.286"}
      height={height ? height : "76.755"}
      viewBox={viewBox ? viewBox : "0 0 71.286 76.755"}
      {...props}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_5461' data-name='Rectangle 5461' width='71.286' height='76.755' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_8916' data-name='Group 8916' clipPath='url(#clip-path)'>
        <path
          id='Path_11992'
          data-name='Path 11992'
          d='M84.473,104.416a10.311,10.311,0,0,0,8.916-1.573,9.88,9.88,0,0,0,3.658-5.037A10.142,10.142,0,0,0,81.68,86.314,10.217,10.217,0,0,0,77.955,98.5a11.063,11.063,0,0,1-1.705-.419c-.391-.065-.775-.178-1.167-.238-1.139-.177-1.14-.171-1.279-1.332A13.715,13.715,0,1,1,89.88,108.15a14.577,14.577,0,0,1-3.34.218c-.478-.017-.768-.152-.866-.688a12.255,12.255,0,0,0-1.2-3.265'
          transform='translate(-39.964 -43.905)'
          fill={fill ? fill : "#1b2346"}
        />
        <path
          id='Path_11993'
          data-name='Path 11993'
          d='M44.506,13.339a4.772,4.772,0,0,1,4.19,7.117c-1.8,3.142-3.626,6.274-5.433,9.414a1.235,1.235,0,0,1-.715.6,11.978,11.978,0,0,0-3.941,2.21.73.73,0,0,1-.987.083q-2.34-1.387-4.71-2.722c-.418-.236-.565-.43-.283-.913,2.629-4.5,5.224-9.019,7.852-13.519a4.542,4.542,0,0,1,4.027-2.271'
          transform='translate(-17.614 -7.233)'
          fill={fill ? fill : "#d89d00"}
        />
        <path
          id='Path_11994'
          data-name='Path 11994'
          d='M53.743,67.257a4.614,4.614,0,0,1,3.4-4.389c4.985-1.346,9.977-2.665,14.961-4.015.565-.153.831-.08.987.544.546,2.188,1.137,4.365,1.744,6.537.154.552.033.73-.544.811A15.648,15.648,0,0,0,66.7,69.962a2.843,2.843,0,0,1-1.058.516c-1.945.51-3.883,1.045-5.831,1.545a4.736,4.736,0,0,1-6.067-4.766'
          transform='translate(-29.143 -31.877)'
          fill={fill ? fill : "#d89d00"}
        />
        <path
          id='Path_11995'
          data-name='Path 11995'
          d='M94.375,110.594c-2.236,0-4.472-.019-6.707.013-.592.009-.854-.179-.909-.767a10.159,10.159,0,0,1,.014-2.149c.062-.536.305-.686.827-.682,3.4.02,6.807.009,10.21.009,1.192,0,2.385.017,3.577-.006.46-.009.666.147.733.612a8.9,8.9,0,0,1,.012,2.3c-.06.536-.3.689-.825.683-2.31-.024-4.621-.011-6.931-.011Z'
          transform='translate(-47.02 -58.028)'
          fill={fill ? fill : "#d89d00"}
        />
        <path
          id='Path_11996'
          data-name='Path 11996'
          d='M70.568,131.91a10.438,10.438,0,0,0-10.6-9.21,10.585,10.585,0,0,0-10.179,9.958c-.273,4.949,4.054,11.259,10.248,10.582a12.551,12.551,0,0,0,3.772-.343,10.4,10.4,0,0,0,6.762-10.986m-3.011,2.075A15.681,15.681,0,0,0,67.016,136c-.117.571-.355.7-.93.542-3.258-.9-6.526-1.759-9.79-2.632-1.008-.27-2.014-.552-3.026-.805-.4-.1-.525-.293-.407-.695.238-.809.452-1.625.666-2.442.074-.282.2-.458.39-.388,2.03.545,3.926,1.055,5.822,1.562,2.424.649,4.845,1.313,7.277,1.932.564.144.739.343.54.915'
          transform='translate(-26.991 -66.534)'
          fill={fill ? fill : "#d89d00"}
        />
        <path
          id='Path_11997'
          data-name='Path 11997'
          d='M93.237,45.081a10.589,10.589,0,0,0-13.149-8.74l-.255.067Q64.144,40.521,48.5,44.787a10.492,10.492,0,0,0-7.459,12.757,10.6,10.6,0,0,0,12.122,8.117c.37-.059.886.02,1.045-.427a21.359,21.359,0,0,1,1.974-4.011c-.992.265-1.8.475-2.608.7a7.331,7.331,0,0,1-3.973.093A7.182,7.182,0,0,1,44.3,55a6.989,6.989,0,0,1,5.433-6.865q8.453-2.271,16.913-4.515c4.022-1.073,8.035-2.18,12.064-3.226q1.2-.312,2.407-.617a6.883,6.883,0,0,1,7.875,3.669,7.145,7.145,0,0,1-1.663,8.743,6.7,6.7,0,0,1-1.92,1.135,14.674,14.674,0,0,1-1.765.559c-2.322.6-4.637,1.234-7.122,1.9a19.967,19.967,0,0,1,3.688,2.487c.226.2.464.094.7.03,1.511-.407,3.025-.8,4.532-1.219a11.176,11.176,0,0,0,4.2-2.189,10.522,10.522,0,0,0,3.587-9.8'
          transform='translate(-22.084 -19.517)'
          fill={fill ? fill : "#1b2346"}
        />
        <path
          id='Path_11998'
          data-name='Path 11998'
          d='M35.108,3.727a10.594,10.594,0,0,0-15.2-.964A12.176,12.176,0,0,0,17.4,5.886c-5.251,9.145-10.554,18.26-15.831,27.39a10.58,10.58,0,0,0,4.95,15.345A10.64,10.64,0,0,0,19.434,45.17a.737.737,0,0,0-.054-1.131,9.038,9.038,0,0,1-1.256-1.75c-.548-.92-.549-.921-1.116.036a6.8,6.8,0,0,1-6.55,3.623A7.111,7.111,0,0,1,4.63,35.127q7.949-13.8,15.945-27.582a19.746,19.746,0,0,1,1.45-1.853,7.036,7.036,0,0,1,.63-.584,7.121,7.121,0,0,1,8.527.071,6.976,6.976,0,0,1,2.044,9.084c-.1.174-.194.346-.291.519-.02.028-.039.057-.06.085h.013c-1.039,1.855-2.129,3.682-3.193,5.523-.307.532-.606,1.067-.947,1.667a1.877,1.877,0,0,0,.373,0c1.37-.357,2.736-.724,4.106-1.078a.949.949,0,0,0,.574-.544c.866-1.505,3.468-6.488,3.5-6.5A10.521,10.521,0,0,0,35.108,3.727'
          transform='translate(0 0)'
          fill={fill ? fill : "#1b2346"}
        />
      </g>
    </SvgIcon>
  );
};
