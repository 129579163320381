import { makeStyles } from "@toolkit/ui";

export const useNotificationsListContainerStyle = makeStyles()(theme => ({
  button: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  header: {
    position: "sticky",
    top: "0px",
    left: "0px",
    zIndex: 10,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
