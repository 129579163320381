import { VideoCallIcon, InPersonIcon } from "@/components";
import { DoctorAvatar, DoctorCardLayout, DoctorServiceButton } from "@/domains/doctor";
import { Typography, AtHomeIcon, Avatar } from "@toolkit/ui";
import { useStyles } from "./AppintmentDoctorCard.styles";
import { useTranslation } from "@/i18n/i18n.config";
import { IDoctorInfo } from "../../types/types";
import { DoctorCardProps } from "@/domains/doctor/DoctorCard/DoctorCardLayout";

interface AppointmentDoctorCardProps extends IDoctorInfo {
  isVirtualVisitDisabled?: boolean;
  isAtHomeDisabled?: boolean;
  isOnSiteDisabled?: boolean;
  hasActions?: boolean;
  classes?: DoctorCardProps["classes"];
  handleOnActionCLick?: (visitType: "ONSITE" | "ONLINE" | "AT_HOME") => void;
}

export const AppointmentDoctorCard: React.FC<AppointmentDoctorCardProps> = props => {
  const {
    name,
    gender,
    doctorImageUrl,
    isVirtualVisitDisabled,
    isAtHomeDisabled,
    isOnSiteDisabled,
    vendorImageUrl,
    specialty,
    vendorName,
    languages,
    yearsOfExperience,
    hasActions = false,
    handleOnActionCLick,
    classes: _classes,
  } = props;

  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <DoctorCardLayout
      classes={{ card: cx(_classes?.card) }}
      vendorAvatarNode={vendorImageUrl ? <Avatar className={classes.vendorAvatar} title={vendorName} src={vendorImageUrl} /> : null}
      infoNode={
        <>
          <Typography className={classes.doctorName}>{name}</Typography>
          <Typography className={classes.specialty}>{specialty}</Typography>
          <Typography className={classes.vendorName}>{vendorName}</Typography>
          <Typography className={classes.experience}>{yearsOfExperience}</Typography>
          <Typography className={classes.languages}>{languages}</Typography>
        </>
      }
      doctorAvatarNode={<DoctorAvatar gender={gender!} doctorName={name} imageUrl={doctorImageUrl!} />}
      actionsNode={
        hasActions ? (
          <>
            <DoctorServiceButton
              icon={<InPersonIcon />}
              disabled={isOnSiteDisabled}
              label={t("In-person")}
              onClick={() => handleOnActionCLick?.("ONSITE")}
            />
            <DoctorServiceButton
              icon={<VideoCallIcon />}
              disabled={isVirtualVisitDisabled}
              label={t("Virtual")}
              onClick={() => handleOnActionCLick?.("ONLINE")}
            />
            <DoctorServiceButton
              icon={<AtHomeIcon />}
              disabled={isAtHomeDisabled}
              label={t("At-Home")}
              onClick={() => handleOnActionCLick?.("AT_HOME")}
            />
          </>
        ) : null
      }
    />
  );
};
