import { CustomCard } from "@/components/CustomCard";
import { CardProps, styled, Typography, useTheme, Box } from "@toolkit/ui";
import { ReactNode } from "react";

const Card = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  row-gap: 4px;
  position: relative;
`;

interface DetailCardProps extends Omit<CardProps, "title" | "variant" | "content"> {
  title: string;
  variant?: "singleInfo" | "multipleInfo";
  actions?: ReactNode;
}
export interface MultipleInfoDetailCardProps extends DetailCardProps {
  variant: "multipleInfo";
  items: Array<{ label: string; value: string }>;
  actions?: ReactNode;
}
interface SingleInfoDetailCardProps extends DetailCardProps {
  variant?: "singleInfo";
  content: string | ReactNode;
  actions?: ReactNode;
}
export const DetailCard: React.FC<MultipleInfoDetailCardProps | SingleInfoDetailCardProps> = props => {
  const { title, variant = "singleInfo", sx = {}, actions, ...cardProps } = props;
  const theme = useTheme();

  return (
    <Card {...(cardProps as CardProps)} sx={{ my: 1, ...sx }}>
      {actions && <Box sx={{ position: "absolute", top: 8, right: 8 }}>{actions}</Box>}
      <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={16}>
        {title}
      </Typography>
      {variant === "singleInfo" ? (
        <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.regular}>
          {(props as SingleInfoDetailCardProps).content}
        </Typography>
      ) : (
        (props as MultipleInfoDetailCardProps).items.map(item => (
          <Typography
            key={item.label + item.value}
            fontSize={theme.mixins.fonts.fontSize.sm}
            fontWeight={theme.mixins.fonts.fontWeight.regular}
          >
            <Typography component={"span"} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {item.label}:{" "}
            </Typography>
            {item.value}
          </Typography>
        ))
      )}
    </Card>
  );
};
