import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
export const PickUpIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        width: 44,
        height: 44,
      }}
      viewBox='0 0 44 44'
    >
      <g id='Group_9638' data-name='Group 9638' transform='translate(-296.566 -850)'>
        <ellipse
          id='Ellipse_2620'
          data-name='Ellipse 2620'
          cx='22'
          cy='22.5'
          rx='22'
          ry='22.5'
          transform='translate(296.566 850)'
          fill={palette.success.main}
        />
        <path
          id='icons8-journey_1_'
          data-name='icons8-journey (1)'
          d='M20.226,2a2.611,2.611,0,0,0-2.441,1.736H6.34a2.617,2.617,0,0,0-2.6,2.6V6.5a2.584,2.584,0,0,0,0,4.882v.163a2.617,2.617,0,0,0,2.6,2.6H20.226a.854.854,0,0,1,.868.868v.163a2.584,2.584,0,0,0,0,4.882v.163a.854.854,0,0,1-.868.868H8.781a2.6,2.6,0,1,0,0,1.736H20.226a2.617,2.617,0,0,0,2.6-2.6v-.163a2.584,2.584,0,0,0,0-4.882v-.163a2.617,2.617,0,0,0-2.6-2.6H6.34a.854.854,0,0,1-.868-.868v-.163a2.584,2.584,0,0,0,0-4.882V6.34a.854.854,0,0,1,.868-.868H17.785A2.6,2.6,0,1,0,20.226,2Zm0,1.736a.868.868,0,1,1-.858.917.867.867,0,0,0,0-.1A.849.849,0,0,1,20.226,3.736ZM4.555,8.086a.868.868,0,0,0,.1,0,.859.859,0,0,1,0,1.715l-.063,0-.037,0a.859.859,0,0,1,0-1.715Zm17.358,8.679a.868.868,0,0,0,.1,0,.859.859,0,0,1,0,1.715l-.063,0-.037,0a.859.859,0,0,1,0-1.715ZM6.34,21.094a.849.849,0,0,1,.858.819.868.868,0,0,0,0,.1.864.864,0,1,1-.858-.919Z'
          transform='translate(305 859)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
