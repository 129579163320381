import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  container: {
    width: "100%",
    height: "120px",
    backgroundColor: theme.palette.primary[50],
    overflow: "hidden",
    position: "absolute",
    top: 0,
  },
  circleBackground: {
    width: "550px",
    height: "550px",
    content: "''",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    insetInlineStart: "60px",
    insetBlockStart: "-300px",
    zIndex: 0,
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    zIndex: 1,
  },
}));

export default useStyles;
