import React, { FC } from "react";
import { useTheme } from "../../base/mui";

export const CloseCallIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='22' viewBox='0 0 24 22'>
      <path
        id='icons8-close-pane_1_'
        data-name='icons8-close-pane (1)'
        d='M5,4A2,2,0,0,0,3,6V24a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2ZM5,6H17V24H5Zm6.68,6a.746.746,0,0,0-.68.746V14H8a1,1,0,1,0,0,2h3v1.25a.749.749,0,0,0,1.279.529l2.25-2.25a.745.745,0,0,0,0-1.059l-2.25-2.25A.745.745,0,0,0,11.68,12Z'
        transform='translate(-3 -4)'
        fill={palette.stale.main}
      />
    </svg>
  );
};
