import doctorMale from "@/assets/doctorMale.png";
import doctorFemale from "@/assets/doctorFemale.png";
import { UserGender } from "@health/queries/types";
export const getDefaultDoctorPhotoByGender = ({ photo, gender }: { photo?: string | null; gender?: UserGender }) => {
  if (photo) {
    return photo;
  } else if (gender === "FEMALE") {
    return doctorFemale;
  } else {
    return doctorMale;
  }
};
