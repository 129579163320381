import { FC } from "react";
import { i18n, Trans, useTranslation } from "@toolkit/i18n";
import { Box, format, Typography } from "@toolkit/ui";
import { useAppointmentItemStyles } from "./useAppointmentItemStyle";
import { getNotificationTextStyle } from "@/domains/notifications/utils";
import { AppointmentItemProps } from "@/domains/notifications/types";

export const AppointmentRequestConfirmation: FC<AppointmentItemProps> = ({
  patient,
  doctor,
  confirmedEndTime,
  confirmedStartTime,
  isSeen,
}) => {
  const lang = i18n.language;
  const { classes, theme } = useAppointmentItemStyles();
  const { color } = getNotificationTextStyle(isSeen!, theme);
  const formattedConfirmedStartDate = format(confirmedStartTime!, "YYYY-MM-DD", lang);
  const formattedConfirmedStartTime = format(confirmedStartTime!, "hh:mm A", lang);
  const formattedConfirmedEndTime = format(confirmedEndTime!, "hh:mm A", lang);
  const { t } = useTranslation("consumer");

  return (
    <Box className={classes.view}>
      <Typography color={color}>
        <Trans
          t={t}
          i18nKey='Hi <b>{{firstName}}</b> Your appointment with <b>{{doctor}}</b> has been confirmed. Thank you for choosing iO Health, your health journey companion.'
          values={{ firstName: patient?.firstName || "", doctor: doctor! }}
          components={{ b: <Typography display='inline' fontWeight='bold' /> }}
        />
      </Typography>
      <Typography color={color}>
        <Trans
          t={t}
          i18nKey='<a>Confirmation Date</a>: {{formattedConfirmedStartDate}} ({{formattedConfirmedStartTime}}-{{formattedConfirmedEndTime}})'
          values={{ formattedConfirmedStartDate, formattedConfirmedStartTime, formattedConfirmedEndTime }}
          components={{ a: <Typography component='span' fontWeight='bold' /> }}
        />
      </Typography>
    </Box>
  );
};
