import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const PhysiciansIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9635' data-name='Group 9635' transform='translate(-296 -939.064)'>
        <circle id='Ellipse_2640' data-name='Ellipse 2640' cx='22' cy='22' r='22' transform='translate(296 939.064)' fill='#1a203d' />
        <path
          id='icons8-stethoscope_5_'
          data-name='icons8-stethoscope (5)'
          d='M6.167,2a1.583,1.583,0,0,0-1.37.792H4.583A1.6,1.6,0,0,0,3,4.375v4.75A6.345,6.345,0,0,0,6.992,15,2.362,2.362,0,0,0,8.542,16.9v.145a4.762,4.762,0,0,0,4.75,4.75h2.375a4.762,4.762,0,0,0,4.75-4.75v-3.3a2.377,2.377,0,1,0-1.583,0v3.306a3.155,3.155,0,0,1-3.167,3.167H13.292a3.155,3.155,0,0,1-3.167-3.167V16.9A2.362,2.362,0,0,0,11.674,15a6.345,6.345,0,0,0,3.992-5.879V4.375a1.6,1.6,0,0,0-1.583-1.583h-.215a1.582,1.582,0,1,0,0,1.583h.213v4.75a4.75,4.75,0,0,1-9.5,0V4.375H4.8A1.582,1.582,0,1,0,6.167,2Z'
          transform='translate(306 950)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
