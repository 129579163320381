import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const GlucometerIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "18px"}
      height={height ? height : "23.552px"}
      viewBox={viewBox ? viewBox : "0 0 18 23.552"}
      {...props}
    >
      <path
        id='icons8-glucometer'
        d='M13.683,3A19.446,19.446,0,0,0,5.208,4.726a1.1,1.1,0,0,0-.523,1.029l.954,14.328a2.258,2.258,0,0,0,2.252,2.108h11.58a2.259,2.259,0,0,0,2.254-2.108L22.68,5.755a1.1,1.1,0,0,0-.523-1.029A19.455,19.455,0,0,0,13.683,3Zm0,2.617a16.456,16.456,0,0,1,5.863,1.159,1,1,0,0,1,.627,1l-.431,5.755a.874.874,0,0,1-.871.808H8.493a.872.872,0,0,1-.869-.808L7.193,7.777a.993.993,0,0,1,.627-1A16.425,16.425,0,0,1,13.683,5.617Zm0,1.745s-1.832,2.181-1.832,3.4a1.832,1.832,0,1,0,3.663,0C15.514,9.543,13.683,7.362,13.683,7.362Zm-4.464,8.57h1.745a.872.872,0,1,1,0,1.745H9.219a.872.872,0,0,1,0-1.745Zm6.978,0h1.745a.872.872,0,1,1,0,1.745H16.2a.872.872,0,0,1,0-1.745Zm-3.387,8v2.617h1.745V23.935Z'
        transform='translate(-4.682 -3)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
