import { FC, useCallback } from "react";
import { useAppBarFooterStyle } from "./AppBarFooterStyle";
import { Box, IconButton, Typography } from "@toolkit/ui";
import { AppBarHomeIcon, ApplicationBrandIcon, KnowledgeHubIcon, ShoppingBagIcon, WatchIcon } from "@/components/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { routesPaths } from "@/routing/routesPaths";

export const AppBarFooter: FC = () => {
  const { classes, theme } = useAppBarFooterStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleNavigateTo = useCallback(
    (path: string) => {
      return () => navigate(path);
    },
    [navigate]
  );
  const { pathname } = useLocation();

  const isSelected = (path: string) => pathname === path;

  return (
    <Box className={classes.root}>
      <Box className={classes.brandButtonContainer}>
        <IconButton onClick={handleNavigateTo(routesPaths.home)} className={classes.brandIconButton}>
          <ApplicationBrandIcon />
        </IconButton>
      </Box>

      <Box className={classes.rowContainer}>
        <Box className={classes.iconContainer} role={"navigation"}>
          <IconButton className={classes.iconButton} onClick={handleNavigateTo(routesPaths.home)}>
            <AppBarHomeIcon fill={isSelected(routesPaths.home) ? theme.palette.secondary.main : undefined} />
          </IconButton>
          <Typography
            className={classes.text}
            color={isSelected(routesPaths.home) ? theme.palette.secondary.main : theme.palette.primary.main}
          >
            {t("Home")}
          </Typography>
        </Box>
        <Box className={classes.iconContainer} role={"navigation"}>
          <IconButton className={classes.iconButton} onClick={handleNavigateTo(routesPaths.soon)}>
            <ShoppingBagIcon />
          </IconButton>
          <Typography className={classes.text} color={theme.palette.text.primary}>
            {t("Shop")}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.rowContainer}>
        <Box className={classes.iconContainer} role={"navigation"}>
          <IconButton className={classes.iconButton} onClick={handleNavigateTo(routesPaths.soon)}>
            <WatchIcon />
          </IconButton>
          <Typography className={classes.text} color={theme.palette.text.primary}>
            {t("Watch")}
          </Typography>
        </Box>
        <Box className={classes.iconContainer} role={"navigation"}>
          <IconButton className={classes.iconButton} onClick={handleNavigateTo(routesPaths.soon)}>
            <KnowledgeHubIcon />
          </IconButton>
          <Typography className={classes.text} color={theme.palette.text.primary}>
            {t("Read")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
