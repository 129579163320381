import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const HeightIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20px"}
      height={height ? height : "22px"}
      viewBox={viewBox ? viewBox : "0 0 20 22"}
      {...props}
    >
      <path
        id='icons8-height'
        d='M6,4A1,1,0,1,0,6,6H24a1,1,0,1,0,0-2Zm8.984,2a1,1,0,0,0-.691.293l-2,2a1,1,0,1,0,1.414,1.414L14,9.414V20.586l-.293-.293a1,1,0,1,0-1.414,1.414l2,2a1,1,0,0,0,1.414,0l2-2a1,1,0,1,0-1.414-1.414L16,20.586V9.414l.293.293a1,1,0,1,0,1.414-1.414l-2-2A1,1,0,0,0,14.984,6Zm.031,18H6a1,1,0,1,0,0,2H24a1,1,0,1,0,0-2ZM6,8A1,1,0,1,0,7,9,1,1,0,0,0,6,8ZM24,8a1,1,0,1,0,1,1A1,1,0,0,0,24,8ZM6,12a1,1,0,1,0,1,1A1,1,0,0,0,6,12Zm18,0a1,1,0,1,0,1,1A1,1,0,0,0,24,12ZM6,16a1,1,0,1,0,1,1A1,1,0,0,0,6,16Zm18,0a1,1,0,1,0,1,1A1,1,0,0,0,24,16ZM6,20a1,1,0,1,0,1,1A1,1,0,0,0,6,20Zm18,0a1,1,0,1,0,1,1A1,1,0,0,0,24,20Z'
        transform='translate(-5 -4)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
