import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Shopping: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='93.918' height='104' viewBox='0 0 93.918 104' {...props}>
      <path
        id='icons8-shopping-bag'
        d='M49.834,1A21.191,21.191,0,0,0,28.61,22.224v4.245H11.829l-.2,1.857L3.14,102.612,2.875,105H96.793l-.265-2.388-8.49-74.286-.2-1.857H71.059V22.224A21.191,21.191,0,0,0,49.834,1Zm0,4.245a16.855,16.855,0,0,1,16.98,16.98v4.245H32.855V22.224A16.855,16.855,0,0,1,49.834,5.245ZM15.676,30.714H28.61v4.842a4.245,4.245,0,1,0,4.245,0V30.714H66.814v4.842a4.245,4.245,0,1,0,4.245,0V30.714H83.992l8.026,70.041H7.651Z'
        transform='translate(-2.875 -1)'
        fill={palette.primary[1200]}
      />
    </SvgIcon>
  );
};
