import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "500px",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      height: "156px",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
