import { makeStyles } from "@toolkit/ui";

type styleProps = {
  disabled?: boolean;
};

const useStyles = makeStyles<styleProps>()((theme, { disabled }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "max-content",
  },
  button: {
    width: "53px",
    height: "53px",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.sm,
    border: "2px solid",
    borderColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "& svg": {
      width: "22px",
      height: "22px",
      color: disabled ? theme.palette.grey[600] : theme.palette.text.primary,
    },
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: disabled ? theme.palette.grey[300] : theme.palette.text.primary,
    cursor: disabled ? "default" : "pointer",
    marginTop: "5px",
  },
}));

export default useStyles;
