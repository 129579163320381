import { SvgIcon, useTheme } from "@toolkit/ui";
import React from "react";
import { SvgWithSecondaryColorProps } from "../types";

export const ShoppingBagIcon: React.FC<SvgWithSecondaryColorProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main, secondaryColor = theme.palette.secondary.main, removeSecondaryColor = false } = props;
  const secondaryColorFill = removeSecondaryColor ? fill : secondaryColor;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-play_4_' data-name='icons8-play_4_' viewBox='0 0 22.75 25.563' {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='icons8-shopping-bag' transform='translate(-4 0)'>
        <path
          id='Path_12988'
          data-name='Path 12988'
          d='M24.059,10.784a.824.824,0,0,1-.824-.824V6.941a3.294,3.294,0,0,0-6.588,0v3.02a.824.824,0,1,1-1.647,0V6.941a4.941,4.941,0,0,1,9.882,0v3.02A.824.824,0,0,1,24.059,10.784Z'
          transform='translate(-4.654)'
          fill={fill}
        />
        <path
          id='Path_12989'
          data-name='Path 12989'
          d='M7.324,18.126a.832.832,0,0,1-.1-.006.824.824,0,0,1-.72-.915l.605-5.062A2.486,2.486,0,0,1,9.574,10h2.361a.824.824,0,1,1,0,1.647H9.574a.834.834,0,0,0-.83.708l-.6,5.044A.824.824,0,0,1,7.324,18.126Z'
          transform='translate(-0.82 -3.608)'
          fill={fill}
        />
        <path
          id='Path_12990'
          data-name='Path 12990'
          d='M23.413,34.747H7.162a2.474,2.474,0,0,1-1.839-.826A2.518,2.518,0,0,1,4.7,31.966V31.96l.6-5.106a.824.824,0,0,1,1.636.193l-.6,5.1a.869.869,0,0,0,.217.672.814.814,0,0,0,.612.278H23.413a.814.814,0,0,0,.612-.278.869.869,0,0,0,.217-.67l-.823-6.526a.824.824,0,0,1,1.634-.206l.825,6.545a2.518,2.518,0,0,1-.626,1.955A2.474,2.474,0,0,1,23.413,34.747Z'
          transform='translate(0 -10.237)'
          fill={secondaryColorFill}
        />
        <path
          id='Path_12991'
          data-name='Path 12991'
          d='M32.238,15.875a.824.824,0,0,1-.817-.727l-.329-2.793a.834.834,0,0,0-.83-.707H24.224a.824.824,0,0,1,0-1.647h6.039a2.486,2.486,0,0,1,2.463,2.142l0,.013.329,2.8a.824.824,0,0,1-.722.914A.832.832,0,0,1,32.238,15.875Z'
          transform='translate(-8.442 -3.608)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
