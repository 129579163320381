import { makeStyles } from "@toolkit/ui";

export const useReelCardStyle = makeStyles<{ src: string }>()((theme, { src }) => ({
  root: {
    width: "100%",
    height: "calc(100%)",
    borderRadius: "7px",
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: 0,
  },
  cardBackground: {
    bottom: "-3px",
    position: "absolute",
    height: "inherent",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    width: "100%",
    position: "absolute",
    padding: "12px",
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.md,
    opacity: theme.zIndex.tooltip,
  },
}));
