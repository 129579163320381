import { FC } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@toolkit/ui";
import { useConfirmDoctorTime } from "./useConfirmDoctorTime";

import { CustomCard } from "@/components";
import Dot from "@/components/Dot";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import { MultiLineDetailCard } from "../../components/DetailCard/MultiLineDetailCard";
import { DetailCard } from "../../components/DetailCard/DetailCard";
import SlotsSection from "../../components/SlotsSection/SlotsSection";
import { useCommonStyles } from "../CommonContainer.styles";
import { useTranslation } from "@toolkit/i18n";
import { useSiteSettingsGetQuery } from "@/gql/queries/__generated__/siteSettings";

export const AppointmentConfirmDoctorTimeContainer: FC = () => {
  const location = useLocation();
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery();
  const currency = data?.siteSettings?.defaultCurrency || "";

  const { classes } = useCommonStyles();
  const {
    isSubmitting,
    selectedTimeSlot,
    paymentAmount,
    appointmentDate,
    timeFrame,
    doctorInfo,
    handleTimeSlotSelect,
    handleAppointmentConfirmation,
    suggestedTimeSlots,
  } = useConfirmDoctorTime(location);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.title}>{t("Select a date")}</Typography>
        <CustomCard
          sx={{
            "& .MuiButton-root": {
              boxShadow: theme => theme.mixins.shadows.xs,
              border: "2px solid",
              borderColor: theme => theme.palette.gray[200],
            },
            paddingBlock: "10px 16px",
            paddingInline: "16px",
          }}
        >
          <SlotsSection
            isLoading={false}
            timeSlots={suggestedTimeSlots}
            selectedTimeSlots={selectedTimeSlot ? [selectedTimeSlot] : []}
            onSelect={handleTimeSlotSelect}
            label={""}
            icon={""}
          />
        </CustomCard>

        <Typography className={classes.title} marginTop='10px'>
          {t("Appointment Information's")}
        </Typography>
        <MultiLineDetailCard
          title=''
          items={[
            { label: t("Appointment Date"), value: appointmentDate },
            { label: t("Requested TimeFrame"), value: timeFrame || "-" },
          ]}
        />
        <DetailCard
          title='Payment Information'
          content={
            <Typography color='success'>
              <Dot size={12} color='success' marginInlineEnd={1} />
              {t("You will pay")} {paymentAmount ? `${paymentAmount} ${currency}` : "-"}
            </Typography>
          }
        />
        <AppointmentDoctorCard {...doctorInfo} />
      </Box>
      <Box className={classes.footer}>
        <Button disabled={isSubmitting || !selectedTimeSlot} onClick={handleAppointmentConfirmation} className={classes.button} fullWidth>
          {t("Confirm")}
        </Button>
      </Box>
    </Box>
  );
};
