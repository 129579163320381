import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useStyles } from "./NotificationsTabsStyle";
import { useTranslation } from "@toolkit/i18n";

export const NotificationsTabs: FC = () => {
  const { t } = useTranslation("consumer");
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.tabsContainer}>
          <Button
            className={classes.tab}
            variant='text'
            disabled={location.pathname === routes.root.absRoute}
            onClick={() => navigate(routes.root.absRoute)}
          >
            {t("Notifications")}
          </Button>
          <Button
            className={classes.tab}
            variant='text'
            disabled={location.pathname === routes.healthEngagementMessages.absRoute}
            onClick={() => navigate(routes.healthEngagementMessages.absRoute)}
          >
            {t("Health Engagement Massages")}
          </Button>
        </Box>
      </Box>
      <Outlet />
    </>
  );
};
