import { Box, Typography, useTheme, VerificationInputRef, Button } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import { OTPInput } from "@/components/InputFields/OTPInput/OTPInput";
import { Trans, useTranslation } from "@toolkit/i18n";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useCommonStyles } from "../CommonContainer.styles";
import { useAppointmentConfirmationGuestOTPContainerStyles } from "./AppointmentConfirmationGuestOTPContainer.styles"; // Assuming styles are separated into a hook
import { useOTPVerification } from "./useOTPVerification";

export const AppointmentConfirmationGuestOTPContainer: FC = () => {
  const { classes: commonClasses } = useCommonStyles();
  const theme = useTheme();
  const { t } = useTranslation("consumer");
  const { doctorInfo, guestInfo, onOtpChange } = useAppointmentBookingContext();
  const navigate = useNavigate();

  const { otp, resetRemainingTime, isDisabled, handleChange, handleResend, isNextDisabled } = useOTPVerification({ initialResetTime: 30 });

  const ref = useRef<VerificationInputRef>(null);
  const { classes: otpClasses } = useAppointmentConfirmationGuestOTPContainerStyles({ isDisabled });

  useEffect(() => {
    doctorInfo?.doctorId || navigate(routes.root.absRoute);
  }, [doctorInfo]);

  const handleConfirm = () => {
    onOtpChange(otp);
    navigate(routes.confirm.absRoute);
  };

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={commonClasses.pageContent}>
        <Typography className={otpClasses.verificationTitle}>{t("Verification")}</Typography>
        <Typography className={otpClasses.verificationDescription}>
          <Trans
            i18nKey='Please enter the 4-digit verification code that was sent to <bold>{{ContactInformation}}</bold>'
            components={{
              bold: <Typography fontWeight={theme.mixins.fonts.fontWeight.bold} display='inline' />,
            }}
            values={{ ContactInformation: guestInfo?.phone }}
          />
        </Typography>
        <Box className={otpClasses.otpContainer}>
          <OTPInput ref={ref} onChange={handleChange} />
        </Box>
        <Box className={otpClasses.resendContainer}>
          <Typography fontSize={theme.mixins.fonts.fontSize.lg} sx={{ display: "inline" }}>
            {t("Didn't receive an OTP? ")}
          </Typography>
          <Typography variant='button' onClick={handleResend} className={otpClasses.resendButton} component='span'>
            {t("Resend {{resetRemainingTime}}", { resetRemainingTime })}
          </Typography>
        </Box>
      </Box>
      <Box className={commonClasses.footer}>
        <Button disabled={isNextDisabled} className={commonClasses.button} onClick={handleConfirm}>
          {t("Next")}
        </Button>
      </Box>
    </Box>
  );
};
