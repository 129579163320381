import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const PortraitBackgroundIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='49.864' height='50.497' viewBox='0 0 49.864 50.497' {...props}>
      <path
        id='Path_12745'
        data-name='Path 12745'
        d='M32.073,49.984a39.574,39.574,0,0,1-17.3-.98C9.006,47.265,3.057,43.922,1,38.968c-2.009-4.961-.149-11.5,2.461-17.08A44.28,44.28,0,0,1,13.82,7.488C18.317,3.26,24.134-.587,29.365.074,34.574.766,39.2,5.966,42.628,11.109a41.382,41.382,0,0,1,6.783,15.9c1.072,5.733.927,12.124-2.224,16.2-3.12,4.1-9.247,5.889-15.114,6.771'
        transform='translate(-0.149 0)'
        fill={fill}
        opacity='0.1'
      />
    </SvgIcon>
  );
};
