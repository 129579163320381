import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const CheckmarkIcon: FC<SvgIconProps> = props => {
  const { width, height, ...rest } = props;
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width || "15.738"} height={height || "11.562"} viewBox='0 0 15.738 11.562' {...rest}>
      <path
        id='icons8-check-mark'
        d='M24.37,13.064l-9.143,9.143a1.209,1.209,0,0,1-1.71,0L9.341,18.031a1.209,1.209,0,0,1,1.71-1.71l3.321,3.321,8.288-8.288a1.209,1.209,0,1,1,1.71,1.71Z'
        transform='translate(-8.987 -11)'
        fill={theme.palette.common.white}
      />
    </SvgIcon>
  );
};
