import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const MoneyIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.149' height='19.26' viewBox='0 0 21.149 19.26' {...props}>
      <path
        id='icons8-money_1_'
        data-name='icons8-money (1)'
        d='M5.542,5.967a.4.4,0,0,0-.043.014H2.377a.459.459,0,0,0-.458.458V17.481a.456.456,0,0,0,.458.415H22.541A.459.459,0,0,0,23,17.439v-11a.459.459,0,0,0-.458-.458H19.419a.445.445,0,0,0-.129-.014.536.536,0,0,0-.057.014H5.685A.429.429,0,0,0,5.542,5.967ZM2.835,6.9H5.126V16.98H2.835Zm3.208,0H18.874V16.98H6.043Zm13.748,0h2.291V16.98H19.791ZM12.459,8.273a2.725,2.725,0,0,0-2.664,2.291H9.709a1.833,1.833,0,1,0,0,3.666.458.458,0,1,0,0-.917.917.917,0,0,1,0-1.833h.458a.459.459,0,0,0,.458-.458,1.833,1.833,0,0,1,3.666,0,.459.459,0,0,0,.458.458h.458a.917.917,0,0,1,0,1.833.458.458,0,1,0,0,.917,1.833,1.833,0,1,0,0-3.666h-.086A2.725,2.725,0,0,0,12.459,8.273Zm5.915,10.068a.536.536,0,0,0-.057.014.459.459,0,0,0-.358.458V20c-.337-.086-.678-.165-1.088-.258A66.032,66.032,0,0,0,2.377,18.355H2.291a.46.46,0,0,0,.086.917,65.133,65.133,0,0,1,14.292,1.36,20.323,20.323,0,0,1,4.3,1.346.458.458,0,0,0,.659-.415v-2.75a.458.458,0,1,0-.917,0v2.062a16.8,16.8,0,0,0-1.833-.63V18.813a.459.459,0,0,0-.5-.473ZM2.248,19.73a.463.463,0,1,0,.129.917,34.688,34.688,0,0,1,13.147,2.263,14.675,14.675,0,0,1,3.967,2.205.458.458,0,0,0,.759-.344V22.479a.458.458,0,1,0-.917,0v1.4a13.463,13.463,0,0,0-1.375-.859V21.878a.455.455,0,0,0-.154-.345.458.458,0,0,0-.763.345v.687c-.351-.166-.736-.337-1.174-.516a35.661,35.661,0,0,0-13.49-2.32H2.248Z'
        transform='translate(-1.849 -5.966)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
