import { Box, Button, Typography } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import { AppointmentScheduler, AppointmentSchedulerRef } from "../../components/AppointmentScheduler/AppointmentScheduler";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useTranslation } from "@toolkit/i18n";
import { IFormattedTimeSlot } from "../../types/types";
import { useAuth } from "react-oidc-context";
import { useCommonStyles } from "../CommonContainer.styles";

export const TimeSelectionContainer: FC = () => {
  const { doctorInfo, onTimeSlotSelectChange } = useAppointmentBookingContext();
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const { classes } = useCommonStyles();
  const ref = useRef<AppointmentSchedulerRef | null>(null);

  useEffect(() => {
    if (!doctorInfo?.doctorId) {
      navigate(routes.root.absRoute);
    }
  }, [doctorInfo]);

  const onSubmitClick = () => {
    ref.current?.submit();
  };

  const onSubmit = (selectedTimeSlots: IFormattedTimeSlot[]) => {
    onTimeSlotSelectChange(selectedTimeSlots);
    if (!auth?.isAuthenticated) {
      navigate(routes.confirmForm.absRoute);
      return;
    } else {
      navigate(routes.confirm.absRoute);
    }
  };

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.title}>{t("Select a date")}</Typography>
        <AppointmentScheduler ref={ref} doctorId={doctorInfo?.doctorId || ""} onSubmit={onSubmit} />
      </Box>
      <Box className={classes.footer}>
        <Button onClick={onSubmitClick} className={classes.button} fullWidth>
          {auth?.isAuthenticated ? t("Book now") : t("Next")}
        </Button>
      </Box>
    </Box>
  );
};
