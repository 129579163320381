import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const SurveyNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-choice' data-name='icons8-choice (3)' transform='translate(6.5 7.5)'>
        <path
          id='Path_12994'
          data-name='Path 12994'
          d='M24.328,28.969H10.672A2.643,2.643,0,0,1,8,26.3V9.672A2.643,2.643,0,0,1,10.672,7a.891.891,0,0,1,0,1.781.861.861,0,0,0-.891.891V26.3a.861.861,0,0,0,.891.891H24.328a.861.861,0,0,0,.891-.891v-.712a.891.891,0,1,1,1.781,0V26.3A2.643,2.643,0,0,1,24.328,28.969Z'
          transform='translate(0 -1.219)'
          fill='#fff'
        />
        <path
          id='Path_12995'
          data-name='Path 12995'
          d='M34.641,23.031a.891.891,0,0,1-.891-.891V9.672a.861.861,0,0,0-.891-.891H29.891a.891.891,0,0,1,0-1.781h2.969a2.643,2.643,0,0,1,2.672,2.672V22.141A.891.891,0,0,1,34.641,23.031Z'
          transform='translate(-8.531 -1.219)'
          fill='#fff'
        />
        <path
          id='Path_12996'
          data-name='Path 12996'
          d='M22.828,9.344a.891.891,0,0,1,0-1.781.891.891,0,0,0,0-1.781H18.672a.891.891,0,0,0,0,1.781h1.247a.891.891,0,0,1,0,1.781H18.672a2.672,2.672,0,0,1,0-5.344h4.156a2.672,2.672,0,0,1,0,5.344Z'
          transform='translate(-3.25)'
          fill='#fff'
        />
        <path
          id='Line_1638'
          data-name='Line 1638'
          d='M2.953.281H-.609A.891.891,0,0,1-1.5-.609.891.891,0,0,1-.609-1.5H2.953a.891.891,0,0,1,.891.891A.891.891,0,0,1,2.953.281Z'
          transform='translate(13.063 15)'
          fill='#fff'
        />
        <path
          id='Line_1639'
          data-name='Line 1639'
          d='M2.953.281H-.609A.891.891,0,0,1-1.5-.609.891.891,0,0,1-.609-1.5H2.953a.891.891,0,0,1,.891.891A.891.891,0,0,1,2.953.281Z'
          transform='translate(13.063 21.531)'
          fill='#fff'
        />
        <path
          id='Path_12997'
          data-name='Path 12997'
          d='M26.484,17h2.375a1.452,1.452,0,0,1,1.484,1.484v2.375a1.452,1.452,0,0,1-1.484,1.484H26.484A1.452,1.452,0,0,1,25,20.859V18.484A1.452,1.452,0,0,1,26.484,17Zm2.078,1.781H26.781v1.781h1.781Z'
          transform='translate(-6.906 -5.281)'
          fill='#fff'
        />
        <path
          id='Path_12998'
          data-name='Path 12998'
          d='M26.484,28h2.375a1.452,1.452,0,0,1,1.484,1.484v2.375a1.452,1.452,0,0,1-1.484,1.484H26.484A1.452,1.452,0,0,1,25,31.859V29.484A1.452,1.452,0,0,1,26.484,28Zm2.078,1.781H26.781v1.781h1.781Z'
          transform='translate(-6.906 -9.75)'
          fill='#fff'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
