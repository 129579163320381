import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const MadaIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='60.659' height='20.427' viewBox='0 0 60.659 20.427' {...props}>
      <g id='mada' transform='translate(-2.3 -5.3)'>
        <path id='Path_3884' data-name='Path 3884' d='M27.975,5.3v8.551H2.3V5.3Z' transform='translate(0 0)' fill='#209dd7' />
        <path id='Path_3885' data-name='Path 3885' d='M27.975,118.2v8.551H2.3V118.2Z' transform='translate(0 -101.025)' fill='#84b93d' />
        <path
          id='Path_3886'
          data-name='Path 3886'
          d='M439.521,9.786c-.095.042-.063.137-.084.2a3.3,3.3,0,0,1-3.66,2.661,5.692,5.692,0,0,1-1.725-.326,1.312,1.312,0,0,0-.694.063,5.167,5.167,0,0,1-.9.084h-9.067c-.252,0-.273-.074-.231-.284.074-.347.116-.694.168-1.041.01-.105.053-.137.158-.137,1.052,0,2.1,0,3.155.011a1.065,1.065,0,0,1,.41.095.245.245,0,0,0,.032-.221,19.027,19.027,0,0,0-.063-2.566A1.5,1.5,0,0,0,426.005,7a1.932,1.932,0,0,0-.547-.053H424c-.158,0-.231-.021-.2-.2.063-.368.105-.736.158-1.094.01-.084.032-.168.147-.158a19.4,19.4,0,0,1,2.346.053,2.925,2.925,0,0,1,2.545,2.524,14.331,14.331,0,0,1,.095,2.514c.01.431,0,.431.431.431,1.01,0,2.019-.011,3.029.011.221,0,.305-.095.326-.284.011-.126.021-.252.021-.379V7.714a1.905,1.905,0,0,1,1.6-2.1c.305-.084.61-.137.915-.21h1.115c.021.053.063.042.105.042a3.15,3.15,0,0,1,2.7,2.293c.074.21.116.431.168.642C439.521,8.839,439.521,9.313,439.521,9.786Zm-4.691-.579c0,.463.01.926-.011,1.388-.011.21.074.273.263.295a8.149,8.149,0,0,0,1.01.074,1.311,1.311,0,0,0,1.4-1.062,3.638,3.638,0,0,0,.01-1.841,1.187,1.187,0,0,0-1.231-.978A3.692,3.692,0,0,0,435.2,7.2c-.4.116-.4.105-.368.494a2.979,2.979,0,0,1,.01.305C434.83,8.4,434.83,8.8,434.83,9.208Z'
          transform='translate(-376.573 -0.089)'
          fill='#23242a'
        />
        <path
          id='Path_3887'
          data-name='Path 3887'
          d='M526.093,137.2c-.526-.095-1.062-.179-1.588-.284a2.218,2.218,0,0,0-.873.011,4.873,4.873,0,0,1-2.3.021,1.959,1.959,0,0,1-.116-3.745,5.119,5.119,0,0,1,1.693-.242h1.083c.126,0,.168-.021.179-.158.031-.8-.294-1.157-1.1-1.146a7.72,7.72,0,0,0-2.44.463c-.074.021-.147.053-.21.074a.185.185,0,0,1-.063-.01l-.189-1.294c-.01-.095,0-.137.095-.168a9.639,9.639,0,0,1,3.355-.589,4.1,4.1,0,0,1,1.062.189,1.942,1.942,0,0,1,1.378,1.641c.01.042-.021.105.053.126C526.093,133.791,526.093,135.5,526.093,137.2Zm-2.714-3.061a2.809,2.809,0,0,0-1.041.105.772.772,0,0,0-.494.82.706.706,0,0,0,.61.673,3.726,3.726,0,0,0,1.662-.21c.095-.021.074-.105.074-.168V134.3c0-.137-.053-.179-.179-.168C523.831,134.138,523.642,134.138,523.379,134.138Z'
          transform='translate(-463.144 -111.694)'
          fill='#23242a'
        />
        <path
          id='Path_3888'
          data-name='Path 3888'
          d='M283.271,14.047c-1.462,0-2.935.021-4.4-.011a2.877,2.877,0,0,1-3.029-2.914,6.82,6.82,0,0,1,.21-2.724,2.968,2.968,0,0,1,2.808-2.083c.862-.021,1.714,0,2.577-.011.147,0,.179.032.158.179a9.443,9.443,0,0,0-.158,1.094c-.021.158-.084.189-.221.179-.5-.011-1.01,0-1.515,0a1.578,1.578,0,0,0-1.757,1.6,9.555,9.555,0,0,0-.01,1.82,1.414,1.414,0,0,0,1.409,1.4c.81.042,1.62.032,2.419.032,1.8.011,3.608.011,5.406.011a1.986,1.986,0,0,0,.431-.032.935.935,0,0,0,.789-1.01.917.917,0,0,0-.947-.884c-.778-.021-1.567.032-2.346-.032a1.97,1.97,0,0,1-2-1.736A2.121,2.121,0,0,1,284.66,6.41a5.129,5.129,0,0,1,1.546-.21h4.218c.179,0,.231.032.2.221a8.887,8.887,0,0,0-.158,1.041c-.021.168-.084.2-.242.2H286.28a2.681,2.681,0,0,0-.578.042.758.758,0,0,0-.663.894.8.8,0,0,0,.81.8c.61.021,1.21.011,1.82.011a3.433,3.433,0,0,1,1.262.179,2.14,2.14,0,0,1,1.43,2.135,2.09,2.09,0,0,1-.9,1.893,3.144,3.144,0,0,1-1.588.442C286.322,14.1,284.8,14.047,283.271,14.047Z'
          transform='translate(-244.736 -0.805)'
          fill='#23242a'
        />
        <path
          id='Path_3889'
          data-name='Path 3889'
          d='M275.887,134.191V131.72a1.022,1.022,0,0,1,.873-1.2,6.279,6.279,0,0,1,3.387-.2,1.2,1.2,0,0,1,.5.21c.189.158.347.105.547.021a4.692,4.692,0,0,1,2.914-.242,1.872,1.872,0,0,1,1.5,1.767c.074.852.021,1.714.032,2.577.01.726,0,1.452,0,2.167,0,.158-.032.221-.21.21-.526-.011-1.062-.011-1.588,0-.168,0-.21-.053-.21-.21.01-1.3,0-2.609,0-3.913,0-.137,0-.273-.011-.4a.727.727,0,0,0-.694-.673,2.484,2.484,0,0,0-1.136.179c-.095.042-.168.063-.105.189a1.021,1.021,0,0,1,.074.473v4.144c0,.179-.053.221-.221.221-.515-.011-1.031-.011-1.536,0-.189,0-.242-.042-.231-.231.01-1.283-.011-2.577.01-3.86.011-.968-.41-1.241-1.346-1.073-.536.095-.536.105-.536.642,0,1.42,0,2.84.01,4.27,0,.221-.063.263-.263.263-.5-.01-1.01-.021-1.515,0-.221.011-.263-.063-.252-.273C275.9,135.906,275.887,135.054,275.887,134.191Z'
          transform='translate(-244.809 -111.736)'
          fill='#23242a'
        />
        <path
          id='Path_3890'
          data-name='Path 3890'
          d='M454.842,106.113v3.965c0,.757-.168,1-.894,1.22a6.579,6.579,0,0,1-3,.221,2.9,2.9,0,0,1-2.493-2.766,5.385,5.385,0,0,1,.2-2.167,2.594,2.594,0,0,1,2.482-1.872,4.034,4.034,0,0,1,1.462.2c.179.063.221.021.221-.168-.011-.841,0-1.683-.011-2.524,0-.179.053-.252.221-.273.526-.084,1.052-.158,1.567-.263.2-.042.231.011.231.2C454.842,103.283,454.842,104.7,454.842,106.113Zm-2.009,2.1v-1.241c0-.578,0-.578-.568-.694-.957-.189-1.546.137-1.746,1.01a3.592,3.592,0,0,0,.032,1.8,1.306,1.306,0,0,0,1.378,1.031c.221,0,.431-.053.652-.074s.274-.095.274-.316C452.812,109.226,452.833,108.721,452.833,108.216Z'
          transform='translate(-399.204 -86.234)'
          fill='#23242a'
        />
        <path
          id='Path_3891'
          data-name='Path 3891'
          d='M385.373,134.751V137.1c0,.189-.032.221-.221.179-.547-.116-1.1-.2-1.662-.316a1.475,1.475,0,0,0-.526.063,5.1,5.1,0,0,1-2.325.032,1.953,1.953,0,0,1-.126-3.744,5.59,5.59,0,0,1,1.82-.242h.989c.095,0,.147-.01.158-.137.063-.789-.274-1.178-1.073-1.168a7.01,7.01,0,0,0-2.461.473c-.231.084-.316.063-.326-.2-.021-.337-.084-.663-.137-1-.021-.105-.011-.168.116-.2a9.474,9.474,0,0,1,3.282-.589,3.888,3.888,0,0,1,1.252.242,1.961,1.961,0,0,1,1.252,1.946c0,.768-.021,1.536-.011,2.314Zm-2.651-.5v-.011c-.263.011-.526.011-.778.042a.805.805,0,0,0-.8.862c.01.452.337.736.873.757a5.314,5.314,0,0,0,1.357-.231.137.137,0,0,0,.116-.179c-.011-.358-.011-.7-.011-1.062,0-.137-.053-.168-.179-.168C383.112,134.246,382.912,134.246,382.723,134.246Z'
          transform='translate(-337.235 -111.802)'
          fill='#23242a'
        />
      </g>
    </SvgIcon>
  );
};
