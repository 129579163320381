import { useContext } from "react";
import { AppointmentBookingContext } from "./AppointmentBookingContext";

export const useAppointmentBookingContext = () => {
  const context = useContext(AppointmentBookingContext);
  if (context === undefined) {
    throw new Error("useAppointmentBookingContext must be used within an AppointmentBookingProvider");
  }
  return context;
};
