import { Theme } from "@toolkit/ui";
import { i18n, isLanguageEnglish } from "@toolkit/i18n";
import { CalculatedPaymentStatusForSubscription } from "@health/queries/types";
import { CacheNotificationIcon } from "@/components";

type PaymentNotificationStatus = {
  display: string;
  color?: string;
  icon: typeof CacheNotificationIcon;
};

export const paymentNotificationStatusMapper = (
  theme: Theme
): Record<CalculatedPaymentStatusForSubscription, PaymentNotificationStatus> => ({
  [CalculatedPaymentStatusForSubscription.PaymentRejected]: {
    display: i18n.t("Payment Rejected", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundRejected]: {
    display: i18n.t("Refund Rejected", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentPending]: {
    display: i18n.t("Payment Pending", { ns: "consumer" }),
    color: theme.palette.warning.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundPending]: {
    display: i18n.t("Refund Pending", { ns: "consumer" }),
    color: theme.palette.warning.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentSucceeded]: {
    display: i18n.t("Payment Succeeded", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundSucceeded]: {
    display: i18n.t("Refund Succeeded", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundedToWallet]: {
    display: i18n.t("Refunded to Wallet", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentChargedBack]: {
    display: i18n.t("Payment Charged Back", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundChargedBack]: {
    display: i18n.t("Refund Charged Back", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
});

export const formatCurrency = (value: number, currency: string): string => {
  if ((!value && value !== 0) || !currency) {
    return "";
  }
  let trustedCurrency = currency;
  if (trustedCurrency !== "SAR") {
    trustedCurrency = "AED";
  }
  let amount = "0";
  /*
   * when language is english, the currency symbol should be on the left side of the number
   * when language is arabic, the currency symbol should be on the right side of the number:
   * because of that we added this work around to make sure the currency symbol is displayed correctly
   * when arabic it was return the currency symbol on the left side of the number
   */

  try {
    const formattedCurrency = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "code",
    }).format(value);

    amount = formattedCurrency.replace("USD", "");
  } catch (error) {
    amount = value.toFixed(2);
  }
  return trsCurrency(trustedCurrency) + " " + amount;
};
export const trsCurrency = (currency: string) => {
  if (isLanguageEnglish()) {
    return currency;
  }

  if (currency === "AED") {
    return "د.إ";
  }

  if (currency === "SAR") {
    return "ر.س";
  }

  return currency;
};
