import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const CounterClockIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "84.797"}
      height={height ? height : "84.864"}
      viewBox={viewBox ? viewBox : "0 0 84.797 84.864"}
      {...props}
    >
      <path
        id='icons8-last-24-hours'
        d='M12.759,1.972a2.129,2.129,0,0,0-.228.057,1.825,1.825,0,0,0-1.423,1.822v16.4H27.5a1.822,1.822,0,1,0,0-3.643H17.143a38.322,38.322,0,1,1-7.23,9.792L6.668,24.743a42.313,42.313,0,1,0,8.084-10.816V3.851a1.824,1.824,0,0,0-1.992-1.879ZM43.9,7.494A1.822,1.822,0,1,0,45.72,9.316,1.825,1.825,0,0,0,43.9,7.494ZM61.2,12.106a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,61.2,12.106ZM73.9,24.8a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,73.9,24.8ZM31.83,29.184c-5.052,0-8.653,3.295-8.653,8.027v.057h3.757V37.21a4.383,4.383,0,0,1,4.725-4.554c2.647,0,4.668,1.751,4.668,4.1,0,1.886-.833,3.252-4.27,6.831L23.4,52.581v2.9H40.711V51.9H28.927v-.285l5.75-5.807c4.305-4.355,5.579-6.561,5.579-9.279C40.255,32.293,36.7,29.184,31.83,29.184Zm24.08.626c-4.576,6.853-8.4,12.823-10.7,17.021v3.7H57.732v4.953h3.814V50.531h3.586V46.945H61.546V29.81Zm1.651,3.529h.228V47.059H49.022v-.285C52.046,41.523,55.021,37.018,57.561,33.339ZM9.287,42.106a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,9.287,42.106Zm69.224,0a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,78.51,42.106ZM13.9,59.412a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,13.9,59.412Zm60,0a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,73.9,59.412ZM26.593,72.107a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,26.593,72.107Zm34.612,0a1.822,1.822,0,1,0,1.822,1.822A1.825,1.825,0,0,0,61.2,72.107ZM43.9,76.718A1.822,1.822,0,1,0,45.72,78.54,1.825,1.825,0,0,0,43.9,76.718Z'
        transform='translate(-1.5 -1.463)'
        fill={palette.common.white}
        stroke={palette.common.white}
        strokeWidth='1'
      />
    </SvgIcon>
  );
};
