import { Button, styled } from "@toolkit/ui";

export const CalenderButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "86.1px",
  width: "45px!important",
  borderRadius: "23px",
  padding: "0px",
  minWidth: "45px",
  margin: "0px",
  "&&.selected": {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
    },
  },
  "&.MuiButton-root": {
    border: "1px solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.xs,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[600],
      opacity: 0.5,
    },
  },
}));
