import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const InPersonIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main } = props;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-hospital' width='20.192' height='21.563' viewBox='0 0 20.192 21.563'>
      <path
        id='Line_1486'
        data-name='Line 1486'
        d='M6.051.178H-.661A.839.839,0,0,1-1.5-.661.839.839,0,0,1-.661-1.5H6.051a.839.839,0,0,1,.839.839A.839.839,0,0,1,6.051.178Z'
        transform='translate(7.429 12.994)'
        fill={fill}
      />
      <path
        id='Line_1487'
        data-name='Line 1487'
        d='M-.661,6.89A.839.839,0,0,1-1.5,6.051V-.661A.839.839,0,0,1-.661-1.5a.839.839,0,0,1,.839.839V6.051A.839.839,0,0,1-.661,6.89Z'
        transform='translate(10.785 9.638)'
        fill={fill}
      />
      <path
        id='Path_12780'
        data-name='Path 12780'
        d='M11.133,26.013h-2.1a3.08,3.08,0,0,1-3.076-3.074L5.9,13.88s0,0,0-.005a4.276,4.276,0,0,1,1.607-3.289l7.272-5.706.015-.011a2.031,2.031,0,0,1,1.224-.42,1.883,1.883,0,0,1,1.21.442l2.293,1.817a.839.839,0,0,1-1.042,1.315L16.174,6.2l-.025-.021c-.127-.109-.289-.01-.343.03l-7.262,5.7a2.6,2.6,0,0,0-.965,1.967l.056,9.058s0,0,0,.005a1.4,1.4,0,0,0,1.4,1.4h2.1a.839.839,0,0,1,0,1.678Z'
        transform='translate(-5.9 -4.45)'
        fill={fill}
      />
      <path
        id='Path_12781'
        data-name='Path 12781'
        d='M29.065,29.562h-8.6a.839.839,0,1,1,0-1.678h8.6a1.4,1.4,0,0,0,1.4-1.4V17.481a2.41,2.41,0,0,0-.944-1.952l-.025-.019L27.593,14a.839.839,0,0,1,1.042-1.315l1.89,1.5a4.094,4.094,0,0,1,1.616,3.3v9.005A3.08,3.08,0,0,1,29.065,29.562Z'
        transform='translate(-11.949 -8)'
        fill={fill}
      />
    </SvgIcon>
  );
};
