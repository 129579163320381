import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const SettingIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18.782' height='18.784' viewBox='0 0 18.782 18.784' {...props}>
      <path
        id='icons8-gear_1_'
        data-name='icons8-gear (1)'
        d='M20.442,9.849l-2.413-.394a6.915,6.915,0,0,0-.576-1.39l1.409-2.008a.409.409,0,0,0-.046-.523L17.227,3.945A.408.408,0,0,0,16.7,3.9L14.723,5.324a6.849,6.849,0,0,0-1.4-.583l-.421-2.4A.409.409,0,0,0,12.5,2H10.252a.408.408,0,0,0-.4.343l-.39,2.39a6.879,6.879,0,0,0-1.4.577L6.083,3.9a.409.409,0,0,0-.526.043L3.969,5.531a.408.408,0,0,0-.046.523L5.312,8.04a6.916,6.916,0,0,0-.587,1.413l-2.381.4a.409.409,0,0,0-.341.4V12.5a.409.409,0,0,0,.337.4l2.381.422a6.882,6.882,0,0,0,.587,1.413L3.9,16.7a.409.409,0,0,0,.043.526l1.588,1.589a.408.408,0,0,0,.523.046l1.989-1.393a6.889,6.889,0,0,0,1.407.581l.4,2.393a.407.407,0,0,0,.4.341H12.5a.408.408,0,0,0,.4-.337l.427-2.405a6.867,6.867,0,0,0,1.4-.586l2,1.405a.408.408,0,0,0,.523-.045l1.588-1.589a.409.409,0,0,0,.042-.528l-1.428-1.983a6.835,6.835,0,0,0,.575-1.392l2.417-.424a.408.408,0,0,0,.338-.4V10.252A.408.408,0,0,0,20.442,9.849Zm-9.049,4.4a2.858,2.858,0,1,1,2.858-2.858A2.858,2.858,0,0,1,11.393,14.25Z'
        transform='translate(-2.002 -2)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
