/* eslint-disable max-lines */
import React, { FC } from "react";
import { SvgIconProps } from "../../base/mui";

export const NotFound: FC<SvgIconProps> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 432.354 331.668' width={"432.354"} height={"331.668"} {...props}>
      <defs>
        <clipPath id='clip-path'>
          <path
            id='Path_4528'
            data-name='Path 4528'
            d='M1036.051,673.577c-9.111,12.99-21.9,24.087-37.568,32.238-30.219,15.724-66.668,19.786-100.308,22.643a492.629,492.629,0,0,1-105.089-2.312q-1.764-.23-3.527-.471c-16.084-2.211-31.944-6.346-46.01-14.3a88.146,88.146,0,0,1-11.505-7.733c-9.185-7.3-50.468,2.273-56.064-8.043-15.256-28.117-39.667-38.356-27.159-67.546,3.1-7.227,65.338-53.409,69.873-60.4a188.823,188.823,0,0,1,32.671-37.585c44.776-40.084,111.741-53.615,155.6-24.748a85.179,85.179,0,0,1,9.576,7.318c19.183,16.939,40.183,20.872,60.254,25.513,25.049,5.8,80.446,22.078,97.257,56.754C1089.255,626.27,1053.5,648.7,1036.051,673.577Z'
            transform='translate(-645.373 -489.888)'
            fill='none'
          />
        </clipPath>
      </defs>
      <g id='_02' data-name='02' transform='translate(-115.411 -46.723)'>
        <path
          id='Path_4446'
          data-name='Path 4446'
          d='M1431.054,2437.606c-43-.025-86.391.063-128.4,8.354-8.978,1.772-18.488,4.263-24.295,10.684a5.159,5.159,0,0,0-1.394,2.355c-.351,1.827,1.16,3.5,2.7,4.726,7.9,6.322,18.736,8.565,29.132,10.245,19.423,3.141,39.057,5.1,58.7,6.809,45.185,3.936,91.125,6.348,135.827-2.4q4.321-.846,8.612-1.812c8.731-1.967,17.415-4.324,25.42-8.029,3.35-1.551,6.664-3.41,8.831-6.186s2.947-6.66,1.041-9.587a12.926,12.926,0,0,0-4.05-3.563c-8.579-5.442-18.82-8.469-29.182-9.95-18.579-2.654-38.132-1.512-56.894-1.594Q1444.078,2437.6,1431.054,2437.606Z'
          transform='translate(-1080.943 -2112.99)'
          fill='#f8f9fb'
        />
        <path
          id='Path_4447'
          data-name='Path 4447'
          d='M2549.272,2569.6s9.283-8.037,12.354-5.7-6.433,12.07-19.736,16.306Z'
          transform='translate(-2184.548 -2222.912)'
          fill='#231f20'
          opacity='0.29'
        />
        <path
          id='Path_4448'
          data-name='Path 4448'
          d='M2251.694,2524.747s21.814-4.182,24.6-6.889-2.935-5.248-6.088-4.142S2244.512,2520.309,2251.694,2524.747Z'
          transform='translate(-1930.146 -2179.268)'
          fill='#231f20'
          opacity='0.29'
        />
        <path
          id='Path_4449'
          data-name='Path 4449'
          d='M2228.7,2483.041a4.286,4.286,0,0,1-2.962,3.365c-4.661,1.809-17.788,7.326-23.21,6.47s-6.849-6.659-3.709-7.461a106.38,106.38,0,0,0,12.936-4.917,7.1,7.1,0,0,0,.6-.406,15.422,15.422,0,0,0,1.776-1.579s13.031-2.95,13.7-.856C2228.219,2478.883,2229.041,2481.075,2228.7,2483.041Z'
          transform='translate(-1883.803 -2147.385)'
          fill='#ffb957'
        />
        <path
          id='Path_4450'
          data-name='Path 4450'
          d='M2527.518,2505.95c-.2,3.954-1.971,6.633-5.062,11.189-3.805,5.613-10.368,9.131-13.508,7.515s-2.094-3.615,0-6.378c1.491-1.967,4.483-9.155,6.077-13.119.638-1.6,1.056-2.673,1.056-2.673s10.655-5.326,11.32.476A18,18,0,0,1,2527.518,2505.95Z'
          transform='translate(-2154.08 -2167.753)'
          fill='#ffb957'
        />
        <path
          id='Path_4451'
          data-name='Path 4451'
          d='M2332.541,2483.041a20.564,20.564,0,0,1-5.626.227,29.457,29.457,0,0,1-10.716-3.177,15.461,15.461,0,0,0,1.776-1.579s13.031-2.949,13.7-.856C2332.064,2478.883,2332.885,2481.075,2332.541,2483.041Z'
          transform='translate(-1987.648 -2147.385)'
          fill='#ca5303'
        />
        <path
          id='Path_4452'
          data-name='Path 4452'
          d='M2582.633,2505.985a9.265,9.265,0,0,1-3.731,1.2c-2.79.187-6.506-1.083-8.761-1.988.638-1.6,1.056-2.673,1.056-2.673s10.654-5.326,11.32.476A18.015,18.015,0,0,1,2582.633,2505.985Z'
          transform='translate(-2209.194 -2167.788)'
          fill='#ca5303'
        />
        <path
          id='Path_4453'
          data-name='Path 4453'
          d='M1395.936,673.612c-9.111,12.99-21.9,24.087-37.568,32.238-30.219,15.724-66.668,19.785-100.308,22.643a492.632,492.632,0,0,1-105.089-2.311q-1.764-.23-3.527-.471c-16.083-2.211-31.944-6.346-46.01-14.3a87.281,87.281,0,0,1-33.932-34.457c-15.255-28.117-14.9-58.722-2.392-87.906a142.438,142.438,0,0,1,11.473-21.356,188.823,188.823,0,0,1,32.671-37.585c44.776-40.084,111.741-53.615,155.6-24.748a85.161,85.161,0,0,1,9.576,7.318c19.183,16.939,40.183,20.872,60.254,25.513,25.049,5.8,48.648,12.7,65.46,47.374C1417.341,616.923,1413.387,648.738,1395.936,673.612Z'
          transform='translate(-889.847 -413.878)'
          fill='#f8f9fb'
        />
        <path
          id='Path_4454'
          data-name='Path 4454'
          d='M1706.374,1918.849c-9.11,12.99-21.9,24.087-37.568,32.238-30.219,15.724-66.668,19.786-100.307,22.643a492.626,492.626,0,0,1-105.089-2.312q-1.764-.229-3.527-.471c-16.083-2.211-31.944-6.346-46.01-14.3a9.281,9.281,0,0,1,.881-6.261s58.611,11.254,130.94-8.3C1609.677,1924.787,1684.246,1912.746,1706.374,1918.849Z'
          transform='translate(-1200.285 -1659.116)'
          fill='#1b2346'
          opacity='0.142'
        />
        <path
          id='Path_4455'
          data-name='Path 4455'
          d='M2064.232,1347.1c-1.358,4.016-1.2,9.615-6.506,9.786-3.393.108-6.6-6.109-8.479-10.613-1.062-2.551-1.7-4.546-1.7-4.546l10.176-13.777s.575,1.239,1.32,2.655h0c1,1.906,2.31,4.134,2.928,4.1a7.139,7.139,0,0,0,2.263-.624S2065.594,1343.086,2064.232,1347.1Z'
          transform='translate(-1753.269 -1144.997)'
          fill='#f8a078'
        />
        <path
          id='Path_4456'
          data-name='Path 4456'
          d='M2058.88,1334.262c-.227,1.809-2.942,6.278-5.94,9.728-1.5,1.731-2.806,2.223-3.733,2.283-1.062-2.552-1.7-4.546-1.7-4.546l10.176-13.777s.576,1.239,1.321,2.655h0A26.286,26.286,0,0,1,2058.88,1334.262Z'
          transform='translate(-1753.234 -1144.997)'
          fill='#f76f45'
        />
        <path
          id='Path_4457'
          data-name='Path 4457'
          d='M2524.347,1730.47s1.366,3.486,2.821,4.28,10.754-1.005,10.754-1.005.273-2.524.313-4.241-.885-4.581-2.424-5.2-6-2.6-7.844-.529S2523.846,1728.382,2524.347,1730.47Z'
          transform='translate(-2169.181 -1489.525)'
          fill='#facbaf'
        />
        <path
          id='Path_4458'
          data-name='Path 4458'
          d='M2089.708,1727.682c-.893-.223-3.738,0-4.295,2.679s-1.337,31.936.671,36.581c0,0,8.036-3.947,15.507-4.84s6.144-17.063,4.132-22.2S2089.708,1727.682,2089.708,1727.682Z'
          transform='translate(-1785.755 -1493.68)'
          fill='#2a2e3f'
        />
        <path
          id='Path_4459'
          data-name='Path 4459'
          d='M2244.15,1871.278c-6,4.072-14.651,0-14.651,0s-.282-5.6-1.3-13.18-6.561-20.815-7.071-24.1-3.224-16.516-5.656-25.962-6.052-11.82-6.9-9.9.623,29.3,2.375,37.275,3.676,31,3.676,31a15.654,15.654,0,0,1-4.828,1.758,19.822,19.822,0,0,1-11.46-1.531c.51-3.444-4.3-23.953-5.035-27.427s-7.946-29.695-9.162-34.531q-.115-.457-.243-.99c-1.276-5.259-3.445-15.443-3.445-15.443a18.268,18.268,0,0,1,3.461-4.863c2.121-2,36.143-10.97,40.216-7.58a9.624,9.624,0,0,0,1.716,1.12c4.513,2.359,11.751,2.62,12.593,2.273.962-.4,1.867,4.808,2.263,11.6s1.531,31.166,1.244,35.181.566,5.882,2.489,19.854A86.881,86.881,0,0,1,2244.15,1871.278Z'
          transform='translate(-1869.215 -1535.064)'
          fill='#373a4f'
        />
        <path
          id='Path_4460'
          data-name='Path 4460'
          d='M2087.178,1432.233a6.565,6.565,0,0,1-.593,1.56c-2.828,4.75-13.179,6.165-17.308,3.62s-9.39-39.142-9.39-38.971c0-.023-.04-.233-.1-.594h0c-.482-3.046-2.559-16.695-2.559-16.695h.565s2.206,5.526,4.3,7.28,3.393,1.469,3.627,2.262a10.269,10.269,0,0,0,1.4,2.262,12.2,12.2,0,0,0,.623-3.506c-.17-.962,2.329-3.794,3.062-9.506s.173-6.575.173-6.575c-.53-1.24,5.414-1.171,6.378-.774s10.181,12.1,11.313,16.345C2089.73,1392.865,2089.475,1423.859,2087.178,1432.233Z'
          transform='translate(-1761.714 -1183.724)'
          fill='#fff'
        />
        <path
          id='Path_4461'
          data-name='Path 4461'
          d='M2225.843,1776.815c-.222.227-.355.352-.355.352s-7.677,10.819-16.207,16.328c-6.866,4.439-18.115,11.041-25.018,11.56-1.276-5.261-3.813-16.924-3.813-16.924a18.265,18.265,0,0,1,3.461-4.863c2.121-2,36.143-10.971,40.216-7.58A9.618,9.618,0,0,0,2225.843,1776.815Z'
          transform='translate(-1869.215 -1534.959)'
          fill='#120c28'
        />
        <path
          id='Path_4462'
          data-name='Path 4462'
          d='M2104.68,1481.865a6.6,6.6,0,0,1-.593,1.56c-1.45,2.438-4.883,4-8.437,4.592a19.857,19.857,0,0,1-2.349.255,11.9,11.9,0,0,1-6.522-1.227c-1.128-.7-2.34-3.932-3.507-8.262-3.1-11.532-5.883-30.833-5.883-30.709,0-.023-.039-.234-.1-.594h0c.015-.182.723-8.389,5.932-7.153a4.011,4.011,0,0,0,.422.864,14.992,14.992,0,0,0,.858,1.237.235.235,0,0,1,.032.041c.031.038.053.069.07.088a.278.278,0,0,1,.024.032,17.892,17.892,0,0,0,.466-1.8,5.59,5.59,0,0,0,.157-1.7s3.281-1.471,5.939,4.581c0,0,3.054-9.389,2.657-13.522s3.9,6.561,5.2,16.008C2100.126,1453.9,2103.9,1473.13,2104.68,1481.865Z'
          transform='translate(-1779.215 -1233.356)'
          fill='#ffe5d7'
        />
        <path
          id='Path_4463'
          data-name='Path 4463'
          d='M2117.422,1569.289a19.811,19.811,0,0,1-2.348.255c-5.845-6.615-9.414-38.119-9.657-40.515-.255-2.489-3.176-5.959-3.176-5.959.05-1.244,2.934-.662,4.035.626a.233.233,0,0,1,.032.041.747.747,0,0,1,.07.088c.009.01.017.022.024.032C2107.3,1525.139,2115.96,1562.9,2117.422,1569.289Z'
          transform='translate(-1800.983 -1314.626)'
          fill='#efb595'
        />
        <path
          id='Path_4464'
          data-name='Path 4464'
          d='M2079.281,1519.633c-.863,2.211,3.073,14.423,3.653,20.18a137.069,137.069,0,0,1,.376,14.007c-3.1-11.532-5.883-30.833-5.883-30.709,0-.023-.04-.233-.1-.595h0c.015-.182.723-8.389,5.932-7.152a4.022,4.022,0,0,0,.422.864C2081.7,1516.967,2079.755,1518.422,2079.281,1519.633Z'
          transform='translate(-1779.25 -1308.392)'
          fill='#efb595'
        />
        <path
          id='Path_4465'
          data-name='Path 4465'
          d='M2158.055,1481.848a6.569,6.569,0,0,1-.593,1.56c-2.828,4.75-13.179,6.165-17.308,3.62,5.568,1.972,11.9-1.806,11.975-6.934.127-8.322-3.529-36.386-4.227-38.188s-2.093,1.57-2.907,2.965-1.8-1.569-3.78-3.546a3.057,3.057,0,0,0-2.745-.558,5.579,5.579,0,0,0,.157-1.7s3.281-1.471,5.94,4.581c0,0,3.054-9.389,2.657-13.522s3.9,6.561,5.2,16.008C2153.5,1453.887,2157.275,1473.112,2158.055,1481.848Z'
          transform='translate(-1832.591 -1233.339)'
          fill='#efb595'
        />
        <path
          id='Path_4466'
          data-name='Path 4466'
          d='M2234.107,1414.728c-1.075-1.947-7.692-2.692-10.352-1.842a5.834,5.834,0,0,0-3.532,2.806h0a83.908,83.908,0,0,1-7.372,8.511h0a69.508,69.508,0,0,1-11.208,9.377c-10.634,6.957-13.97,6.109-13.97,6.109s.791-2.092.848-10.293c.037-5.39-2.1-18.96-4.291-31.227-1.148-6.4-2.3-12.452-3.175-16.8-2.551-12.67-5.374-18.779-5.374-18.779,2.382-.832,3.082-.035,3.11,0h0s12.9-1.64,18.214.765,29.581,20.958,31.9,24.407,5.6,21.942,5.656,23.982A6.353,6.353,0,0,1,2234.107,1414.728Z'
          transform='translate(-1865.054 -1174.747)'
          fill='#353f58'
        />
        <path
          id='Path_4467'
          data-name='Path 4467'
          d='M2141.53,1589.63s-4.525-6.42-5.3-8.089-4.4-18.552-5.308-24.548c-.594-3.933-1.174-8.278-1.5-10.8h0c-.171-1.325-.275-2.149-.275-2.149l2.257-1.021s.467.9,1.373,3.02c.538,1.263,1.233,2.958,2.078,5.154,2.261,5.883,7.155,27.377,7.354,28.678S2141.53,1589.63,2141.53,1589.63Z'
          transform='translate(-1824.459 -1332.632)'
          fill='#ff9e3f'
        />
        <path
          id='Path_4468'
          data-name='Path 4468'
          d='M2132.778,1546.041a6.2,6.2,0,0,1-2.109.425,3.24,3.24,0,0,1-1.244-.275h0c-.171-1.325-.276-2.149-.276-2.149l2.257-1.021S2131.873,1543.922,2132.778,1546.041Z'
          transform='translate(-1824.46 -1332.632)'
          fill='#f77a34'
        />
        <path
          id='Path_4469'
          data-name='Path 4469'
          d='M1907.784,1504.1c0,.056-2.151-7.016-2.715-21.269a192.27,192.27,0,0,0-2.093-23.02h0c-.145.178-10.779,13.123-15.554,13.235A4.3,4.3,0,0,1,1883.4,1471h0c-1.19-1.85-1.513-4.677-1.914-7.286-.679-4.412-1.075-22.485-1.075-22.485s7.409-3.139,7.58-3.189a48.41,48.41,0,0,1,3.224,8.089s1.584-11.652,6.958-16.742a18.484,18.484,0,0,1,3.271-2.627c1.967-1.125,1.819.362,1.819.362s4.365,19.968,5.915,31.847c.07.538.134,1.044.189,1.519h0c1.171,10.026-.829,6.959-1.749,21.159C1906.648,1496.518,1907.784,1504.041,1907.784,1504.1Z'
          transform='translate(-1607.45 -1230.837)'
          fill='#353f58'
        />
        <path
          id='Path_4470'
          data-name='Path 4470'
          d='M2187.7,1386.268s-1.795,10.145-3.5,13.035c-1.148-6.4-2.3-12.452-3.175-16.8-2.552-12.67-5.374-18.779-5.374-18.779,2.382-.832,3.082-.035,3.11,0h0s7.694,15.1,8.2,16.516a13.332,13.332,0,0,0-1.922,3A17.409,17.409,0,0,0,2187.7,1386.268Z'
          transform='translate(-1865.028 -1175.882)'
          fill='#3c4a60'
        />
        <path
          id='Path_4471'
          data-name='Path 4471'
          d='M2047.039,1448.6l1.074-2.942-1.7-1.3c0-1.577-.893-13.808-1.2-17.672,1.967-1.125,1.819.362,1.819.362s4.365,19.968,5.915,31.847c.07.538.134,1.043.188,1.519A45.449,45.449,0,0,0,2047.039,1448.6Z'
          transform='translate(-1751.236 -1230.767)'
          fill='#3c4a60'
        />
        <path
          id='Path_4472'
          data-name='Path 4472'
          d='M1923.377,1615.028h0c-.145.179-10.779,13.123-15.554,13.235a4.3,4.3,0,0,1-4.023-2.041c6.327,2.874,17.337-8.588,17.094-12.884s-2.15-11.143-.4-8.484S1923.377,1615.028,1923.377,1615.028Z'
          transform='translate(-1627.857 -1386.057)'
          fill='#120c28'
        />
        <path
          id='Path_4473'
          data-name='Path 4473'
          d='M1965,1564.927s3.1,8.71,1.976,13.971,1.021,1.852,1.475.383a20.627,20.627,0,0,0,0-9.666,27.5,27.5,0,0,0-3.017-7.1Z'
          transform='translate(-1681.249 -1349.644)'
          fill='#120c28'
        />
        <path
          id='Path_4474'
          data-name='Path 4474'
          d='M2365.216,1552.833h0a84.006,84.006,0,0,1-7.372,8.511,41.1,41.1,0,0,0,2.366-15.078c-.509-6.991-3-13.5-5.2-15.7s-15.946-11.537-12.612-11.312c0,0,17.025,10.69,17.761,12.331S2363.663,1553.434,2365.216,1552.833Z'
          transform='translate(-2010.048 -1311.893)'
          fill='#120c28'
        />
        <path
          id='Path_4475'
          data-name='Path 4475'
          d='M2200.872,1365.6s8.275,15.2,8.445,16.893-1.866,2.715-1.531,3.395,1.98,2.262,2.092,2.771-1.913,10.3-4.151,14.032l-.255-1.421s-1.355-21.2-2.095-23.5-3.337-12.077-3.337-12.077Z'
          transform='translate(-1886.306 -1177.844)'
          fill='#120c28'
        />
        <path
          id='Path_4476'
          data-name='Path 4476'
          d='M2382.153,2026.086a15.807,15.807,0,0,0,.589-5.98c-.191-4-3.331-25.018-4.09-32.626s-.95-22.26,0-26.54,2.277-4.879,2.277-4.879c-.848,1.924.622,29.3,2.375,37.275s3.676,31,3.676,31A15.647,15.647,0,0,1,2382.153,2026.086Z'
          transform='translate(-2041.573 -1692.982)'
          fill='#120c28'
        />
        <path
          id='Path_4477'
          data-name='Path 4477'
          d='M2860.929,260.793a34.47,34.47,0,0,0-20.312,1.768,17.878,17.878,0,0,0-6.854,4.855c-4.529,5.369-5.162,14.032-2.829,21.016s7.159,12.51,12.417,16.909c9.3,7.78,20.5,12.729,31.977,13.294a27.776,27.776,0,0,0,12.524-1.913c7.187-3.116,14.707-11.5,12.773-21.073-.926-4.583-3.428-8.524-6.123-12.054C2885.788,272.214,2874.035,263.4,2860.929,260.793Z'
          transform='translate(-2435.564 -213.308)'
          fill='#f8f9fb'
        />
        <path
          id='Path_4478'
          data-name='Path 4478'
          d='M2274.339,659.064c-1.517,1.748-4.465,2.527-6.952,2.948a121.225,121.225,0,0,0-38.6,13.547c-5.982,3.326-11.766,7.2-18.319,9.172a34.371,34.371,0,0,1-12.453,1.365,15.045,15.045,0,0,1-7.917-2.471,38.382,38.382,0,0,1-4.094-4.128c-4.642-4.677-11.406-6.633-17.969-7.192s-13.179.087-19.757-.269c-3.62-.2-7.321-.726-10.492-2.481-1.932-1.068-3.774-2.934-3.572-5.131a6.506,6.506,0,0,1,2.479-3.942c11.163-10.175,27.554-11.589,41.275-17.918,8.407-3.881,15.753-9.594,23.6-14.433.956-.589,1.938-1.276,2.943-1.9a85.41,85.41,0,0,1,9.575,7.318C2233.269,650.488,2254.269,654.422,2274.339,659.064Z'
          transform='translate(-1828.866 -532.792)'
          fill='#fff'
        />
        <path
          id='Path_4479'
          data-name='Path 4479'
          d='M1679.537,1008.973c-3.513-.466-7.027-.933-10.548-1.337-4.975-.573-10.243-.978-14.78,1.148a23.3,23.3,0,0,0-6.212,4.625,26.862,26.862,0,0,0-5.979,7.887c-2.477,5.443-2.193,12.327-6.448,16.527-4.23,4.175-10.98,3.813-16.72,5.358-1.925.518-4.129,1.764-4.045,3.758.082,1.925,2.249,3,4.132,3.411,5.022,1.1,10.25.432,15.316-.439a190.455,190.455,0,0,0,35.926-9.885c7.676-2.947,15.544-6.7,20.171-13.5,1.594-2.342,2.759-5.358,1.671-7.973C1690.126,1014,1684.27,1009.6,1679.537,1008.973Z'
          transform='translate(-1375.724 -865.145)'
          fill='#fff'
        />
        <path
          id='Path_4480'
          data-name='Path 4480'
          d='M1311.9,1352.441a10.1,10.1,0,0,0-3.422,2.807,4.029,4.029,0,0,0-.406,4.21c.814,1.384,2.562,1.887,4.16,2.041,4.848.462,9.627-1.188,14.221-2.807,3.324-1.175,7.246-2.551,9.282-5.651,1.777-2.7,1.38-5.334-1.8-6.3C1327.248,1344.676,1317.523,1349.191,1311.9,1352.441Z'
          transform='translate(-1107.751 -1160.935)'
          fill='#fff'
        />
        <path
          id='Path_4481'
          data-name='Path 4481'
          d='M1191.687,1071.5a23.251,23.251,0,0,1-6.846,3.2q-10.237,3.465-20.566,6.649c-11.119,3.424-22.516,6.625-34.074,7.219a142.408,142.408,0,0,1,11.473-21.356c3.317-.574,6.691-.952,9.839-1.213,4.948-.411,9.926-.19,14.888-.357a182.315,182.315,0,0,0,24.417-3c1.3-.222,2.865-.356,3.717.652a2.914,2.914,0,0,1,.582,1.764A8.03,8.03,0,0,1,1191.687,1071.5Z'
          transform='translate(-952.941 -913.39)'
          fill='#fff'
        />
        <path
          id='Path_4482'
          data-name='Path 4482'
          d='M1872.022,1456c.235.883-.51,4.444-3.037,5.146a7.419,7.419,0,0,1-2.424,0l-.494.205a7.02,7.02,0,0,1-1.755-.417c-1.583-.625-.893-2.412-.893-2.412-.98-.14-1.4-.8-1.448-2.058a1.113,1.113,0,0,1,1.028-1.293,1.819,1.819,0,0,1-.468-2.212,2.117,2.117,0,0,1,1.786-1.138,1.606,1.606,0,0,1-.939-1.349c-.092-.945,2.055-1.486,3.551-1.531a3.381,3.381,0,0,1,.651.036c.933.145,1.125.666,1.125.666,1.684-.656,5-1.123,5.33,1.588S1871.8,1455.115,1872.022,1456Z'
          transform='translate(-1591.362 -1250.55)'
          fill='#f8a078'
        />
        <path
          id='Path_4483'
          data-name='Path 4483'
          d='M1904.125,1436.12l-.042.881-.732,15.65a.981.981,0,1,1-1.96-.091l.735-15.662.032-.7.127.052c.1.01.2.018.3.023A5.309,5.309,0,0,0,1904.125,1436.12Z'
          transform='translate(-1625.753 -1239.368)'
          fill='#120c28'
        />
        <path
          id='Path_4484'
          data-name='Path 4484'
          d='M1909.9,1456.3c-.383-.785-2.052-3.6-2.052-3.6-2.1-.022-2.679-1.874-2.392-3.418.933.146,1.125.666,1.125.666,1.684-.656,5-1.123,5.33,1.588S1909.677,1455.415,1909.9,1456.3Z'
          transform='translate(-1629.243 -1250.849)'
          fill='#facbaf'
        />
        <path
          id='Path_4485'
          data-name='Path 4485'
          d='M2105.85,1506.593s1.956,4.957,3.069,5.2,2.716-.278,2.821-.765.078-5.646-.292-5.922a1.629,1.629,0,0,0-.645-.006s-.719,2.679-4.294,1.311A2.459,2.459,0,0,0,2105.85,1506.593Z'
          transform='translate(-1804.132 -1299.524)'
          fill='#ff9e3f'
        />
        <path
          id='Path_4486'
          data-name='Path 4486'
          d='M1939.663,1250.885s1.443,4.452,0,7.218-8.782,9.984-14.557,9.744c-4.4-.184-9.493-7.344-11.725-13.086a14.935,14.935,0,0,1-1.148-4.72,16.414,16.414,0,0,1,2.647-9.141s8.9-1.685,12.175-1.2,9.718,5.413,9.718,5.413,1.619-1.556,3.109-1.175a2.476,2.476,0,0,1,1.583,1.531C1943.031,1248.844,1939.663,1250.885,1939.663,1250.885Z'
          transform='translate(-1635.211 -1067.926)'
          fill='#facbaf'
        />
        <path
          id='Path_4487'
          data-name='Path 4487'
          d='M1948.245,1369.086a1.47,1.47,0,0,0-.041,1.013,3.009,3.009,0,0,0,.319.883.69.69,0,0,0,1.256-.528c-.066-.241-.188-.478-.274-.713a1.775,1.775,0,0,0-.4-.765A.556.556,0,0,0,1948.245,1369.086Z'
          transform='translate(-1666.536 -1180.654)'
          fill='#532f21'
        />
        <path
          id='Path_4488'
          data-name='Path 4488'
          d='M1930.26,1342.395a4.707,4.707,0,0,1,1.056-1.685c.411-.431.943-.732,1.339-1.167.107-.119,0-.364-.171-.295a5.226,5.226,0,0,0-2.614,3.04c-.083.247.3.351.383.107Z'
          transform='translate(-1650.591 -1154.843)'
          fill='#42180b'
        />
        <path
          id='Path_4489'
          data-name='Path 4489'
          d='M1937.477,1249.236c.038,1.554-.255,2.884-1.68.944s-6.656-1.166-10.7.716-7.942-2.679-8.742-4.267-1.6-2.008-2.664-.854.311,4.793.909,6.434c.383,1.062-.527,2.02-1.222,2.562a14.933,14.933,0,0,1-1.148-4.72,16.412,16.412,0,0,1,2.647-9.141s8.9-1.685,12.175-1.2,9.718,5.413,9.718,5.413,1.619-1.556,3.109-1.175A8.921,8.921,0,0,0,1937.477,1249.236Z'
          transform='translate(-1635.211 -1067.935)'
          fill='#f8a078'
        />
        <path
          id='Path_4490'
          data-name='Path 4490'
          d='M1889.6,1251.364c.489,1.415-.255,1.615-.477,1.643a.477.477,0,0,1-.056,0s-.824.383-2.634-1.021-1.868-2.942,0-7.693a10.1,10.1,0,0,1,2.322-3.777,2.766,2.766,0,0,1,1.751-.906,27.189,27.189,0,0,1-1.286,5.5C1888.385,1247.316,1889.038,1249.724,1889.6,1251.364Z'
          transform='translate(-1611.505 -1067.926)'
          fill='#532f21'
        />
        <path
          id='Path_4491'
          data-name='Path 4491'
          d='M1906.046,1251.407c.489,1.415-.255,1.615-.477,1.643a15.42,15.42,0,0,1-1.616-7.637,25.365,25.365,0,0,1,1.248-4.847,2.766,2.766,0,0,1,1.751-.906,27.191,27.191,0,0,1-1.286,5.5C1904.826,1247.366,1905.479,1249.768,1906.046,1251.407Z'
          transform='translate(-1627.946 -1067.97)'
          fill='#42180b'
        />
        <path
          id='Path_4492'
          data-name='Path 4492'
          d='M1872.292,1342.548a6.411,6.411,0,0,0-1.621,12.708,6.791,6.791,0,0,0,1.957.108,6.414,6.414,0,0,0-.335-12.816Zm4.578,6.618c0,.071-.008.143-.014.213v.042a1.355,1.355,0,0,1-.018.173.192.192,0,0,1-.01.075c-.009.069-.018.137-.029.2a.045.045,0,0,1,0,.013c-.011.07-.024.14-.039.209a.1.1,0,0,1-.01.053c-.014.068-.031.128-.046.2s-.04.152-.063.226-.046.149-.07.222a.065.065,0,0,1,0,.015c-.02.063-.043.128-.066.184a.161.161,0,0,1-.013.035c-.02.055-.042.108-.065.163-.008.018-.014.036-.022.052-.028.064-.057.128-.087.19h0c-.032.068-.065.135-.1.2s-.071.128-.11.2-.073.128-.112.188l-.014.02c-.033.056-.07.11-.107.165l-.024.037c-.033.048-.069.1-.1.145a.206.206,0,0,1-.017.022c-.037.051-.076.1-.115.15l-.013.015c-.046.057-.093.115-.143.171a.054.054,0,0,0-.013.017l-.116.127c-.064.069-.128.135-.2.2-.046.045-.094.088-.142.127-.068.061-.135.12-.2.177-.045.036-.088.073-.128.107l-.162.12-.041.029-.127.089c-.08.054-.161.1-.244.152-.048.029-.1.059-.148.085h-.011c-.065.036-.128.069-.2.1s-.128.061-.193.091-.128.054-.189.078c-.027.011-.054.023-.083.033l-.162.059-.193.061h-.008c-.07.022-.14.039-.212.059l-.157.037-.158.032c-.1.018-.2.035-.3.049h-.026l-.156.017-.1.009a.756.756,0,0,0-.128.008h-.527c-.089,0-.2-.013-.3-.023-.045-.017-.087-.035-.127-.052a5.711,5.711,0,0,1-3.444-5.586,5.6,5.6,0,0,1,5.74-5.465,2.856,2.856,0,0,1,.295.022A5.759,5.759,0,0,1,1876.871,1349.166Z'
          transform='translate(-1594.299 -1157.726)'
          fill='#353f58'
        />
        <path
          id='Path_4493'
          data-name='Path 4493'
          d='M1875.669,1347.59a5.23,5.23,0,0,1,1.623.338c-.1-.01-.2-.018-.295-.022a5.608,5.608,0,0,0-5.741,5.465,5.712,5.712,0,0,0,3.444,5.586l.128.052a5.74,5.74,0,0,1,.841-11.42Z'
          transform='translate(-1598.296 -1162.127)'
          fill='#120c28'
        />
        <path
          id='Path_4494'
          data-name='Path 4494'
          d='M1889.979,1355.727a5.6,5.6,0,0,1-5.74,5.466,2.861,2.861,0,0,1-.3-.023,5.693,5.693,0,0,1-3.572-5.638,5.6,5.6,0,0,1,5.74-5.465,2.854,2.854,0,0,1,.295.022A5.705,5.705,0,0,1,1889.979,1355.727Z'
          transform='translate(-1607.408 -1164.287)'
          fill='#fff'
          opacity='0.38'
        />
        <path
          id='Path_4495'
          data-name='Path 4495'
          d='M1939.412,1201.963c-2.934.946-1.929,7.654-2.382,6.52s-3.434-3.848-9.784-1.322-9.528-1.684-10.971-3.97-3.155,0-3.155,0c-.409.255-.629,0-.667-.582h0a11.854,11.854,0,0,1,3.989-8.154c3.563-2.657,13.235-4.242,17.42-1.356,3.062,2.109,4.613,5.868,5.23,7.745C1939.313,1201.531,1939.412,1201.963,1939.412,1201.963Z'
          transform='translate(-1635.401 -1026.006)'
          fill='#532f21'
        />
        <path
          id='Path_4496'
          data-name='Path 4496'
          d='M1939.459,1265.063c-2.934.947-1.929,7.654-2.382,6.52s-3.434-3.847-9.784-1.322-9.528-1.684-10.971-3.97-3.155,0-3.155,0c-.409.255-.629,0-.667-.582h0s1.218-3.4,3.7-.913,5.656,6.732,12.556,4.356a41.509,41.509,0,0,0,10.372-5.208C1939.359,1264.63,1939.459,1265.063,1939.459,1265.063Z'
          transform='translate(-1635.447 -1089.106)'
          fill='#42180b'
        />
        <path
          id='Path_4497'
          data-name='Path 4497'
          d='M2121.919,1292.091a6.117,6.117,0,0,0-.3,1.005,1.074,1.074,0,0,0,.128,1.021.452.452,0,0,0,.546.07,1.04,1.04,0,0,0,.383-.945,6,6,0,0,0-.032-1.051.374.374,0,0,0-.731-.1Z'
          transform='translate(-1817.829 -1113.483)'
          fill='#f76f45'
        />
        <g id='Group_6740' data-name='Group 6740' transform='translate(291.136 184.56)' opacity='0.33'>
          <path
            id='Path_4498'
            data-name='Path 4498'
            d='M2025.32,1341.124c-.737-.175-1.291.485-1.685,1.009a2.43,2.43,0,0,0-.7,1.989,1.919,1.919,0,0,0,1.786,1.412,2.406,2.406,0,0,0,2.049-1.14c.741-1.111,1.23-3.874-.839-3.845a2.449,2.449,0,0,0-1.82,1.306,2.706,2.706,0,0,0-.638,2.388.9.9,0,0,0,.63.63c.741.128,1.021-.354,1.217-1a3.287,3.287,0,0,1,.361-.792,3.488,3.488,0,0,1,.242-.375l.066-.079c.193-.139.162-.176-.089-.112l-.433-.255q.023-.157-.008-.019a3.975,3.975,0,0,1-.112.4,2.068,2.068,0,0,1-.218.471c-.062.094-.181.191-.239.287-.033.055-.334.128-.01.151a.765.765,0,0,0,.038.07,1.906,1.906,0,0,1,.111-.255c.075-.165.156-.327.241-.485a4,4,0,0,0,.495-.973A.647.647,0,0,0,2025.32,1341.124Z'
            transform='translate(-2022.892 -1340.548)'
            fill='#f76f45'
          />
        </g>
        <path
          id='Path_4499'
          data-name='Path 4499'
          d='M2013.785,1342.686a1.855,1.855,0,0,0,.291.852,2.552,2.552,0,0,0,.489.693.772.772,0,0,0,1.007.06.762.762,0,0,0,.2-.989,5.347,5.347,0,0,0-.522-.616,2.311,2.311,0,0,0-.537-.532.623.623,0,0,0-.925.532Z'
          transform='translate(-1723.811 -1157.321)'
          fill='#532f21'
        />
        <path
          id='Path_4500'
          data-name='Path 4500'
          d='M1977.865,1377.133a1.647,1.647,0,0,0,.765,1.926,2.159,2.159,0,0,0,2.271-.436c.189-.165-.054-.458-.263-.339a2.151,2.151,0,0,1-1.646.44c-.714-.193-.893-.893-.883-1.556,0-.14-.229-.18-.255-.034Z'
          transform='translate(-1692.436 -1187.826)'
          fill='#f76f45'
        />
        <path
          id='Path_4501'
          data-name='Path 4501'
          d='M2003.249,1415.609a10.385,10.385,0,0,1,.648,1.041,3.2,3.2,0,0,0,.573,1.008.272.272,0,0,0,.457-.189,2.223,2.223,0,0,0-.51-1.119,9.79,9.79,0,0,0-.792-1.021c-.172-.2-.524.066-.369.285Z'
          transform='translate(-1714.591 -1221.169)'
          fill='#f76f45'
        />
        <path
          id='Path_4502'
          data-name='Path 4502'
          d='M1990.174,1318.542a2.908,2.908,0,0,1,.748-.418c.255-.092.531-.1.773-.2.127-.055.17-.265,0-.3a2.4,2.4,0,0,0-1.709.6C1989.8,1318.362,1989.985,1318.688,1990.174,1318.542Z'
          transform='translate(-1702.979 -1135.966)'
          fill='#42180b'
        />
        <path
          id='Path_4503'
          data-name='Path 4503'
          d='M2041.91,1342.35a4.128,4.128,0,0,0,.06.676c.038.219.069.471.285.582a.281.281,0,0,0,.383-.1c.112-.207.028-.371-.015-.584a2.889,2.889,0,0,1-.055-.574.327.327,0,0,0-.653,0Z'
          transform='translate(-1748.348 -1157.286)'
          fill='#fff'
        />
        <path
          id='Path_4504'
          data-name='Path 4504'
          d='M2049.512,1343.014a1.867,1.867,0,0,0,.021.461c.03.171.064.315.242.383a.207.207,0,0,0,.23-.094c.088-.127.021-.255,0-.393a1.437,1.437,0,0,1,0-.355.246.246,0,0,0-.245-.245.255.255,0,0,0-.243.245Z'
          transform='translate(-1754.976 -1157.927)'
          fill='#fff'
        />
        <path
          id='Path_4505'
          data-name='Path 4505'
          d='M1869.242,1455.73s.873.921.145,1.746a2.192,2.192,0,0,1-1.54.814,1.67,1.67,0,0,1-.7,2.679c-.341.166-.7.325-1.064.468a7.025,7.025,0,0,1-1.755-.417c-1.583-.625-.893-2.412-.893-2.412-.98-.14-1.4-.8-1.448-2.058a1.113,1.113,0,0,1,1.028-1.294,1.819,1.819,0,0,1-.468-2.212,2.117,2.117,0,0,1,1.786-1.138,1.606,1.606,0,0,1-.939-1.35c-.092-.945,2.055-1.486,3.551-1.531s1.776.7,1.776.7.807,1.575-.178,2.078a2.6,2.6,0,0,1,2.216,2.169C1870.757,1453.97,1871.123,1455,1869.242,1455.73Z'
          transform='translate(-1591.38 -1250.627)'
          fill='#facbaf'
        />
        <path
          id='Path_4506'
          data-name='Path 4506'
          d='M1879.383,1456.069a21.656,21.656,0,0,0,3.1.328,1.957,1.957,0,0,0,1.276-.264,1.365,1.365,0,0,0,.573-.957,2.34,2.34,0,0,0-.489-1.382.028.028,0,0,0-.048.028,1.6,1.6,0,0,1,.161,1.531,1.263,1.263,0,0,1-1.063.735,12.74,12.74,0,0,1-1.708,0c-.6-.027-1.2-.062-1.786-.115-.059,0-.071.092-.014.1Z'
          transform='translate(-1606.525 -1254.777)'
          fill='#f76f45'
        />
        <path
          id='Path_4507'
          data-name='Path 4507'
          d='M1871.18,1473.926a6.61,6.61,0,0,0,2.512.575,20.079,20.079,0,0,0,2.679.027,3.119,3.119,0,0,0,1.237-.334,3.228,3.228,0,0,0,.986-.658,1.947,1.947,0,0,0-.032-2.091,2.45,2.45,0,0,0-.834-.912,2.25,2.25,0,0,0-1.2-.383.084.084,0,0,0-.022.166,3.121,3.121,0,0,1,.976.342,2.4,2.4,0,0,1,.772.73,2.281,2.281,0,0,1,.37,1.021,1.041,1.041,0,0,1-.309,1,4.728,4.728,0,0,1-.916.485,2.955,2.955,0,0,1-1.148.274c-.788.031-1.587.035-2.375,0a14.863,14.863,0,0,1-2.614-.408c-.1-.024-.176.127-.074.176Z'
          transform='translate(-1599.36 -1269.057)'
          fill='#f76f45'
        />
        <path
          id='Path_4508'
          data-name='Path 4508'
          d='M1873.548,1505.174a4.35,4.35,0,0,0,.91.1c.314.015.63.011.945,0a13.137,13.137,0,0,0,1.87-.2c.971-.176,2.382-.436,2.491-1.658a1.715,1.715,0,0,0-.068-.668,1.167,1.167,0,0,0-.174-.288.5.5,0,0,0-.191-.181.038.038,0,0,0-.046.06.705.705,0,0,1,.156.191,1.249,1.249,0,0,1,.057.255,1.437,1.437,0,0,1,.025.584,1.486,1.486,0,0,1-.864.929,6.016,6.016,0,0,1-1.55.415,15.262,15.262,0,0,1-1.649.205c-.3.022-.61.032-.916.04s-.653-.01-.976.027c-.094.01-.127.149-.024.177Z'
          transform='translate(-1601.406 -1297.084)'
          fill='#f76f45'
        />
        <path
          id='Path_4509'
          data-name='Path 4509'
          d='M1893.53,1524.421a2.358,2.358,0,0,0,1.2-.255,1.8,1.8,0,0,0,.893-.717,2.117,2.117,0,0,0-.32-2.3c-.032-.049-.112,0-.079.046a1.976,1.976,0,0,1,.407,1.136,2.052,2.052,0,0,1-.328.958,1.634,1.634,0,0,1-.765.6,4.49,4.49,0,0,1-.5.2,3.012,3.012,0,0,0-.523.176c-.056.032-.051.137.019.148Z'
          transform='translate(-1618.844 -1313.536)'
          fill='#f76f45'
        />
        <g id='Group_6743' data-name='Group 6743' transform='translate(115.411 76.045)' clipPath='url(#clip-path)'>
          <path
            id='Path_4510'
            data-name='Path 4510'
            d='M978.935,1936.045a152.3,152.3,0,0,0-17.405-8.535,122.514,122.514,0,0,0-12.817-4.231c-5.5-1.518-11.277-2.86-15.726-6.434a18.783,18.783,0,0,1-4.465-5.2,11.081,11.081,0,0,1-1.649-4.975,6.194,6.194,0,0,1,1.772-4.813c1.691-1.578,4.238-1.786,6.543-1.6a45.7,45.7,0,0,1,8.653,1.773,27.667,27.667,0,0,1,4.318,1.493,28.845,28.845,0,0,1,4.873,3.142,100.33,100.33,0,0,1,8.951,7.335c6.734,6.386,11.8,14.287,16.794,22.114'
            transform='translate(-890.956 -1720.292)'
            fill='#5fa02e'
          />
          <path
            id='Path_4511'
            data-name='Path 4511'
            d='M1006.682,1663.5c-.819,4.516-1.892,9.1-1.276,13.65h-.269a43.17,43.17,0,0,1-3.673-2.813,44.076,44.076,0,0,1-11.407-15.053c-2.471-5.374-3.855-11.25-6.866-16.344a41.6,41.6,0,0,0-10.15-11.078,61.838,61.838,0,0,1-5.23-4.36,21.557,21.557,0,0,1-5.217-8.491,4.891,4.891,0,0,1,2.474-6.562,24.074,24.074,0,0,1,16.2-3.5,43.387,43.387,0,0,1,15.873,5.586,29.122,29.122,0,0,1,6.788,4.918,14.115,14.115,0,0,1,2.947,4.337,21.934,21.934,0,0,1,1.334,6.625A125.271,125.271,0,0,1,1006.682,1663.5Z'
            transform='translate(-921.742 -1466.028)'
            fill='#1b2346'
          />
          <path
            id='Path_4512'
            data-name='Path 4512'
            d='M1260.924,1736.634c-.819,4.516-1.892,9.1-1.276,13.65h-.269a43.131,43.131,0,0,1-3.673-2.813c-2.346-13.522-4.169-35.08,2.475-54.881a14.119,14.119,0,0,1,2.947,4.337,21.941,21.941,0,0,1,1.334,6.624A125.273,125.273,0,0,1,1260.924,1736.634Z'
            transform='translate(-1175.984 -1539.166)'
            fill='#133831'
          />
          <path
            id='Path_4513'
            data-name='Path 4513'
            d='M1310.664,1497.306c-.162,9.981-2.721,19.685-6.367,29.028-1.865,4.78-4.014,9.467-6.271,14.047-3.375,6.844-7.026,13.586-9.567,20.783s-3.925,14.974-2.6,22.49l-.114-.638a76.92,76.92,0,0,1-4.646-11.264,88.03,88.03,0,0,1-3.9-32.96,55.81,55.81,0,0,1,1.54-10.31,78.587,78.587,0,0,1,3.925-10.69c4.128-9.756,8.623-19.992,17.152-26.279,2.377-1.751,5.664-3.146,8.173-1.592C1310.319,1491.371,1310.7,1494.564,1310.664,1497.306Z'
            transform='translate(-1196.413 -1361.764)'
            fill='#5fa02e'
          />
          <path
            id='Path_4514'
            data-name='Path 4514'
            d='M1332.313,1780c-1.865,4.78-4.014,9.467-6.271,14.048-3.375,6.844-7.026,13.586-9.568,20.783s-3.925,14.974-2.6,22.49l-.114-.638a76.9,76.9,0,0,1-4.646-11.264c1.148-10.773,3.685-22.528,8.347-29.446A63.876,63.876,0,0,1,1332.313,1780Z'
            transform='translate(-1224.44 -1615.426)'
            fill='#3d5c57'
            opacity='0.24'
          />
          <path
            id='Path_4515'
            data-name='Path 4515'
            d='M1382.364,1801.051s-7.19-8.215-18.125.242-18.724,32.74-19.029,42.406-14.68-4-14.68-4,.591-31.827,17-46.78c0,0,11.575-12.416,24.383-6.5S1382.364,1801.051,1382.364,1801.051Z'
            transform='translate(-1243.127 -1619.638)'
            fill='#1b2346'
          />
          <path
            id='Path_4516'
            data-name='Path 4516'
            d='M1463.45,1769.508a17.844,17.844,0,0,1,23.009-2.387c5.6,3.846,8.573,10.524,13.636,15.053,1.674,1.5,3.837,2.806,6.042,2.382a5.825,5.825,0,0,0,4.067-3.88,15.176,15.176,0,0,0,.579-5.813,16.358,16.358,0,0,0-1.411-6.932,14.375,14.375,0,0,0-3.675-4.226,23.936,23.936,0,0,0-8.5-4.8,25.491,25.491,0,0,0-6.418-.9c-10.223-.31-20.921,3.355-27.382,11.282Z'
            transform='translate(-1359.048 -1596.222)'
            fill='#1b2346'
          />
          <path
            id='Path_4517'
            data-name='Path 4517'
            d='M1009.484,1635.273a51.194,51.194,0,0,1,7.725,3.271,68.61,68.61,0,0,1,7.187,4.337,80.1,80.1,0,0,1,6.432,4.881,45.466,45.466,0,0,1,5.534,5.471,28.158,28.158,0,0,1,4.128,6.456c.128.28.248.56.362.845a.268.268,0,0,0,.32.182.263.263,0,0,0,.182-.321,27.653,27.653,0,0,0-3.955-6.706,42.356,42.356,0,0,0-5.448-5.646,77.94,77.94,0,0,0-6.387-4.975,71.412,71.412,0,0,0-7.195-4.5,53.569,53.569,0,0,0-7.759-3.465c-.329-.115-.658-.227-.99-.334s-.457.4-.139.5Z'
            transform='translate(-962.875 -1488.708)'
            fill='#133831'
          />
          <path
            id='Path_4518'
            data-name='Path 4518'
            d='M3710.245,1512.643a95.506,95.506,0,0,1,18.077-44.763c-.148,7.654,1.968,14.819,1.036,22.593-.941,7.844-4.331,14.678-8.075,21.593-1.309,2.424-7.846,12.126-11.76,9.741-.893-.546,1.615-1.6,1.513-2.643a64.28,64.28,0,0,1-.287-7.607'
            transform='translate(-3318.474 -1343.122)'
            fill='#1b2346'
            opacity='0.215'
          />
          <path
            id='Path_4519'
            data-name='Path 4519'
            d='M3636.21,2088.415q.568-.289,1.109-.615c.083.08.166.159.256.239Z'
            transform='translate(-3254.681 -1883.961)'
            fill='#cec84c'
          />
          <path
            id='Path_4520'
            data-name='Path 4520'
            d='M3607.941,1548.635a29.5,29.5,0,0,1-3.207,8.275c-2.392,4.647-5.09,9.419-8.821,12.966a21.419,21.419,0,0,1-3.8,2.908c-5.171-4.975-6.775-12.629-7.364-19.808a105.836,105.836,0,0,1,.311-20.538c.585-5.182,1.551-10.307,2.616-15.414.383-1.817.765-3.63,1.162-5.444l3.042-14.1,12.391,29.352a49.786,49.786,0,0,1,2.36,6.4,47.71,47.71,0,0,1,1.117,6.506A30.479,30.479,0,0,1,3607.941,1548.635Z'
            transform='translate(-3209.472 -1368.946)'
            fill='#1b2346'
            opacity='0.215'
          />
          <path
            id='Path_4521'
            data-name='Path 4521'
            d='M3597.587,1695.541a32.774,32.774,0,0,1-1.685,8,21.425,21.425,0,0,1-3.8,2.908c-5.172-4.975-6.775-12.629-7.364-19.808a105.837,105.837,0,0,1,.312-20.538c.585-5.182,1.551-10.307,2.616-15.414C3591.828,1661.587,3598.6,1681.779,3597.587,1695.541Z'
            transform='translate(-3209.464 -1502.611)'
            fill='#5fa02e'
            opacity='0.24'
          />
          <path
            id='Path_4522'
            data-name='Path 4522'
            d='M3022.571,1799.361a70.18,70.18,0,0,1-15.594-26.309c-.678-2.086-1.26-4.21-2.107-6.232,9.157,5.282,18.153,11.378,24.341,19.948,2.594,3.594,10.622,16.762,6.743,19.579-1.957,1.42-8.945-1.809-11.54-5.158'
            transform='translate(-2703.878 -1603.927)'
            fill='#1b2346'
            opacity='0.215'
          />
          <g id='Group_6741' data-name='Group 6741' transform='translate(283.867 211.744)' opacity='0.54'>
            <path
              id='Path_4523'
              data-name='Path 4523'
              d='M2908.082,2175.984c-1.658.84-3.66.51-5.443-.013a27.617,27.617,0,0,1-11.214-6.5c-4.058-3.881-6.85-8.93-10.869-12.846a28.521,28.521,0,0,0-9.926-6.292c4.864.268,9.728-.646,14.6-.5,1.972.06,3.943.293,5.915.219,1.6-.059,3.189-.319,4.782-.282,3.678.084,7.132,1.729,10.314,3.572a35.4,35.4,0,0,1,7.72,5.74,17.493,17.493,0,0,1,4.72,8.271,4.215,4.215,0,0,1,.114,1.621,4.446,4.446,0,0,1-1.64,2.424,19.415,19.415,0,0,1-9.292,4.538'
              transform='translate(-2870.63 -2149.772)'
              fill='#5fa02e'
            />
          </g>
          <g id='Group_6742' data-name='Group 6742' transform='translate(278.411 175.004)' opacity='0.55'>
            <path
              id='Path_4524'
              data-name='Path 4524'
              d='M2889.33,1916.593c-8.646-1.3-17.51-3.67-24.085-9.432a56.21,56.21,0,0,1-8.179-9.707l-10.538-14.615c-5.511-7.645-11.14-15.411-18.668-21.079,5.526,2.679,11.888,2.817,17.933,3.9a112.053,112.053,0,0,1,11.96,3.074c10.339,3.062,21.279,6.514,28.275,14.722,4.123,4.839,6.488,10.948,7.98,17.128,1.191,4.936,1.671,10.732-1.7,14.526-.793.893-2.074,1.658-3.132,1.1'
              transform='translate(-2827.86 -1861.76)'
              fill='#aaa'
            />
          </g>
          <path
            id='Path_4525'
            data-name='Path 4525'
            d='M3331.084,1418.279c-7.856-7.159-10.531-18.318-11.848-28.867a176.44,176.44,0,0,1,3.359-62.242,141.329,141.329,0,0,0,9.25,17.121c6.912,10.918,15.367,20.973,20.5,32.829a66.948,66.948,0,0,1,2.236,46.744,15.891,15.891,0,0,1-3.572,6.651,12.263,12.263,0,0,1-4.975,2.806,8.3,8.3,0,0,1-4.828.328c-2.169-.638-3.66-2.574-5-4.4l-8.442-11.481'
            transform='translate(-2976.968 -1220.361)'
            fill='#1b2346'
            opacity='0.215'
          />
          <path
            id='Path_4526'
            data-name='Path 4526'
            d='M3162.856,1678.751a20.773,20.773,0,0,1-12-2.963,46.315,46.315,0,0,1-9.711-7.9,53.339,53.339,0,0,1-6.56-7.73,54.765,54.765,0,0,1-6.413-14.571,138.688,138.688,0,0,1-5.978-46.179,11.039,11.039,0,0,0,2.236,6.138c6.527,9.936,17.364,16.049,25.98,24.238,2.873,2.731,5.506,5.7,8.121,8.683a68.77,68.77,0,0,1,7.113,9.115A27.588,27.588,0,0,1,3170,1664.3c-.529,5.83-3.6,11.55-8.6,14.6'
            transform='translate(-2806.139 -1457.873)'
            fill='#1b2346'
            opacity='0.215'
          />
          <path
            id='Path_4527'
            data-name='Path 4527'
            d='M3636.21,2088.415q.568-.289,1.109-.615c.083.08.166.159.256.239Z'
            transform='translate(-3254.681 -1883.961)'
            fill='#cec84c'
          />
        </g>
        <path
          id='Path_4529'
          data-name='Path 4529'
          d='M3704.059,1457.8a2.232,2.232,0,0,0-2.669-.139,5,5,0,0,0-1.786,2.424,13.518,13.518,0,0,0-.979,6.443,12.522,12.522,0,0,0,2.582,6.684c.216.271,1.135.128.893-.255a15.762,15.762,0,0,1-.881-1.658.487.487,0,0,0,.255-.143,11.957,11.957,0,0,0,1.658-3.224,27.207,27.207,0,0,0,1.786-7.144A3.313,3.313,0,0,0,3704.059,1457.8Zm-.489,3.53a23.276,23.276,0,0,1-.579,3.1,26.1,26.1,0,0,1-1.054,3.234c-.342.853-.806,1.671-1.173,2.517a13.551,13.551,0,0,1-.494-7.885,11.039,11.039,0,0,1,.924-2.527c.309-.575,1.072-1.732,1.847-1.227.854.556.627,1.947.529,2.79Z'
          transform='translate(-3193.672 -1257.833)'
          fill='#1b2346'
        />
        <path
          id='Path_4530'
          data-name='Path 4530'
          d='M3170.514,1578.62a83.278,83.278,0,0,0-2.231-9.7c-.422-1.531-.833-3.074-1.313-4.592a5.869,5.869,0,0,0-1.982-3.189,3.413,3.413,0,0,0-3.189-.447,2.643,2.643,0,0,0-1.531,2.636,11.088,11.088,0,0,0,1.059,3.747,32.958,32.958,0,0,0,1.742,3.616,29.205,29.205,0,0,0,5.966,7.507.538.538,0,0,0,.334.109c.042.139.083.279.127.418C3169.608,1579.115,3170.581,1579.076,3170.514,1578.62Zm-8.523-13.139a4.587,4.587,0,0,1-.344-3.166,1.632,1.632,0,0,1,2.594-.375,5.558,5.558,0,0,1,1.62,3.03c.383,1.371.74,2.753,1.093,4.133.668,2.613,1.275,5.252,2.008,7.847a34.091,34.091,0,0,1-6.97-11.473Z'
          transform='translate(-2724.04 -1347.853)'
          fill='#1b2346'
        />
        <path
          id='Path_4531'
          data-name='Path 4531'
          d='M3393.226,1298.825q-.207-1.987-.422-3.976l-.413-3.855a4.939,4.939,0,0,0-.967-2.966,2.266,2.266,0,0,0-3.963,1.181,9.329,9.329,0,0,0,.269,3.266,26.944,26.944,0,0,0,.878,3.43,22.262,22.262,0,0,0,3.412,6.661.47.47,0,0,0,.383.1c.009.044.016.087.027.128.109.485,1.115.4,1.124-.114A32.7,32.7,0,0,0,3393.226,1298.825Zm-4.479-7.316a4.4,4.4,0,0,1,.127-2.792c.6-1.02,1.573-.383,1.931.472a10.7,10.7,0,0,1,.467,3.009q.163,1.627.326,3.253.186,1.87.383,3.735c.076.751.128,1.514.228,2.269A29.823,29.823,0,0,1,3388.747,1291.509Z'
          transform='translate(-2922.194 -1109.479)'
          fill='#1b2346'
        />
        <path
          id='Path_4532'
          data-name='Path 4532'
          d='M3829.752,1674.548a33.515,33.515,0,0,1-2.424,9.617,34.364,34.364,0,0,1-2.149,4.291c-.447.755-.926,1.489-1.43,2.206-.452.638-1,1.237-1.4,1.913-.209.351.6.356.765.2a12.088,12.088,0,0,0,1.54-1.981c.465-.667.884-1.319,1.29-2a33.016,33.016,0,0,0,2.276-4.582,33.965,33.965,0,0,0,2.319-9.672C3830.563,1674.232,3829.785,1674.218,3829.752,1674.548Z'
          transform='translate(-3301.631 -1447.169)'
          fill='#3d5c57'
        />
        <path
          id='Path_4533'
          data-name='Path 4533'
          d='M2865.557,1923.2a22.817,22.817,0,0,0-1.753-2.967c-.6-.953-1.218-1.883-1.846-2.807q-1.913-2.836-3.97-5.571a123.952,123.952,0,0,0-8.9-10.543c-.226-.24-1.031-.06-.729.272q4.592,5.059,8.7,10.533,2.018,2.693,3.9,5.485.94,1.394,1.844,2.814a34.65,34.65,0,0,0,1.953,3.012C2864.932,1923.647,2865.728,1923.586,2865.557,1923.2Z'
          transform='translate(-2451.86 -1645.126)'
          fill='#3d5c57'
        />
        <path
          id='Path_4534'
          data-name='Path 4534'
          d='M3509.15,1380.015a5.358,5.358,0,0,0-.558-2.521,2.744,2.744,0,0,0-3.545-1.264c-1.262.657-1.595,2.229-1.809,3.507a33.822,33.822,0,0,0,0,11.023,34.6,34.6,0,0,0,4.135,11.63.3.3,0,0,0,.148.12c0,.011,0,.022,0,.033.031.591,1.1.578,1.158,0,.219-2.161.181-4.363.226-6.533.045-2.137.094-4.273.127-6.41.034-2.177.072-4.354.094-6.533C3509.138,1382.051,3509.175,1381.032,3509.15,1380.015Zm-5.41,2.862a16.956,16.956,0,0,1,.785-4.761,2.266,2.266,0,0,1,1.259-1.487c.939-.283,1.744.665,2.041,1.445a6.244,6.244,0,0,1,.271,2.359c-.025,1.042-.075,2.086-.114,3.128q-.1,2.834-.185,5.67-.105,3.328-.184,6.655c-.045,1.7-.14,3.411-.128,5.115A33.983,33.983,0,0,1,3503.74,1382.878Z'
          transform='translate(-3022.866 -1186.89)'
          fill='#1b2346'
        />
        <path
          id='Path_4535'
          data-name='Path 4535'
          d='M3574.532,1425.412a1.368,1.368,0,0,0-1.546-.674,2.4,2.4,0,0,0-1.531,1.383,10.009,10.009,0,0,0-.963,2.594,10.331,10.331,0,0,0,.255,5.4c.079.255.777.2.685-.11a10.128,10.128,0,0,1-.337-4.318,8.007,8.007,0,0,1,1.587-4.114c.342-.368.914-.439,1.075.115a2.083,2.083,0,0,1-.032.963,18.263,18.263,0,0,1-.653,2.075c-.528,1.531-1.287,2.99-1.743,4.532-.112.383.606.365.766.128a15.533,15.533,0,0,0,1.338-2.967c.38-1.008.718-2.041,1.014-3.079A2.841,2.841,0,0,0,3574.532,1425.412Z'
          transform='translate(-3081.722 -1229.41)'
          fill='#1b2346'
        />
        <path
          id='Path_4536'
          data-name='Path 4536'
          d='M2912.435,1834.011a29.808,29.808,0,0,0-5.351-3.146c-1.332-.611-3.444-1.78-4.776-.557a1.387,1.387,0,0,0-.177,1.851,2.989,2.989,0,0,0,2.068,1.092c1.1.2,2.209.336,3.317.5a22.637,22.637,0,0,0,3.3.45.511.511,0,0,0,.419-.245c.212.136.423.274.638.415C2912.124,1834.552,2912.834,1834.307,2912.435,1834.011Zm-3.935-.791-2.437-.365a9.428,9.428,0,0,1-2.424-.467c-.729-.327-1.225-1.373-.336-1.827.7-.36,1.641.164,2.3.435q1.064.434,2.095.944c.973.481,1.924,1.021,2.851,1.585-.673-.144-1.374-.2-2.045-.3Z'
          transform='translate(-2498.597 -1582.804)'
          fill='#1b2346'
        />
        <path
          id='Path_4537'
          data-name='Path 4537'
          d='M939.37,1686.126a26.89,26.89,0,0,1-14.938-14.479,33.776,33.776,0,0,1-1.778-5.673c-.07-.3-.875-.255-.834.073a23.755,23.755,0,0,0,3.911,10,26.89,26.89,0,0,0,13.244,10.492C939.294,1686.657,939.851,1686.307,939.37,1686.126Z'
          transform='translate(-771.142 -1439.726)'
          fill='#3d5c57'
        />
        <path
          id='Path_4538'
          data-name='Path 4538'
          d='M1824.725,1812.784a17.288,17.288,0,0,0-4.22-9.64c-.18-.207-.893-.069-.638.224a17.3,17.3,0,0,1,3.99,9.622,16.887,16.887,0,0,1-.383,5.255,17.384,17.384,0,0,1-.777,2.456c-.346.865-.834,1.658-1.2,2.509-.15.349.591.4.75.2a13.126,13.126,0,0,0,2.147-5.214A17.256,17.256,0,0,0,1824.725,1812.784Z'
          transform='translate(-1554.583 -1559.486)'
          fill='#3d5c57'
        />
        <path
          id='Path_4539'
          data-name='Path 4539'
          d='M2035.1,1184.254a8.256,8.256,0,0,0-2.169-2.8,13.4,13.4,0,0,0-2.988-2.112,13.254,13.254,0,0,0-6.91-1.486c-.4.025-.41.4,0,.383a12.212,12.212,0,0,1,6.671,1.63,12.563,12.563,0,0,1,2.679,2.06c.765.776,1.295,1.688,1.981,2.517C2034.527,1184.634,2035.229,1184.6,2035.1,1184.254Z'
          transform='translate(-1731.615 -1014.032)'
          fill='#532f21'
        />
        <path
          id='Path_4540'
          data-name='Path 4540'
          d='M1872.93,1306.3a12.573,12.573,0,0,1-.374-1.666c-.034-.51.115-1.021.082-1.518-.019-.29-.575-.3-.721-.119a3.011,3.011,0,0,0,.255,3.433C1872.3,1306.577,1873,1306.632,1872.93,1306.3Z'
          transform='translate(-1599.669 -1123.124)'
          fill='#532f21'
        />
        <path
          id='Path_4541'
          data-name='Path 4541'
          d='M1386.524,1510.892a41.5,41.5,0,0,0-6.814,6.322,33.622,33.622,0,0,0-5.136,7.716c-.161.355.63.4.787.135a54.828,54.828,0,0,1,5.128-7.552,41.449,41.449,0,0,1,6.585-6.285C1387.443,1510.947,1386.769,1510.71,1386.524,1510.892Z'
          transform='translate(-1166.122 -1304.546)'
          fill='#fff'
        />
        <path
          id='Path_4542'
          data-name='Path 4542'
          d='M954.313,1975.988c-.906-.616-1.948-1.04-2.869-1.646a21.774,21.774,0,0,1-2.775-2.169,22.77,22.77,0,0,1-4.3-5.338c-.148-.255-.9-.171-.74.127a21.99,21.99,0,0,0,4.337,5.574,22.892,22.892,0,0,0,2.679,2.116,12,12,0,0,0,3.175,1.732C954.049,1976.446,954.721,1976.266,954.313,1975.988Z'
          transform='translate(-790.154 -1702.25)'
          fill='#fff'
        />
        <path
          id='Path_4543'
          data-name='Path 4543'
          d='M3152.7,1810.749a133.523,133.523,0,0,1-9.092-26.846q-.876-4.034-1.485-8.121c-.052-.347-.86-.327-.811,0a128.6,128.6,0,0,0,7.328,27.567c.991,2.551,2.053,5.077,3.24,7.544C3152.034,1811.2,3152.869,1811.113,3152.7,1810.749Z'
          transform='translate(-2707.506 -1535.481)'
          fill='#fff'
        />
        <path
          id='Path_4544'
          data-name='Path 4544'
          d='M3383.038,1411.9c-.645-.987-1.429-1.891-2.107-2.857q-1.14-1.629-2.146-3.345a50.812,50.812,0,0,1-3.317-6.761c-.106-.263-.836-.19-.713.117a50.882,50.882,0,0,0,3.345,6.863q.938,1.6,1.991,3.132a22.127,22.127,0,0,0,2.3,3.014C3382.523,1412.2,3383.205,1412.16,3383.038,1411.9Z'
          transform='translate(-2911.156 -1206.789)'
          fill='#fff'
        />
        <path
          id='Path_4545'
          data-name='Path 4545'
          d='M3657.091,1573.823c-.433-1.549-1.066-3.054-1.63-4.558s-1.148-3.023-1.731-4.534c-.1-.269-.844-.2-.726.128.553,1.517,1.1,3.036,1.67,4.548s1.079,3.062,1.77,4.525C3656.547,1574.141,3657.173,1574.112,3657.091,1573.823Z'
          transform='translate(-3153.911 -1351.428)'
          fill='#fff'
        />
        <path
          id='Path_4546'
          data-name='Path 4546'
          d='M1463.72,2673.61s-21.382,5.763-22.723,7.842-.134,5.161,3.351,6.168,25-7.709,25-7.709Z'
          transform='translate(-1223.625 -2318.997)'
          fill='#1b2346'
        />
        <path
          id='Path_4547'
          data-name='Path 4547'
          d='M1648.52,2659.868l5.5,6.234s4.224-1.207,4.893-3.486S1655.224,2657.053,1648.52,2659.868Z'
          transform='translate(-1405.141 -2306.06)'
          fill='#1b2346'
        />
        <path
          id='Path_4548'
          data-name='Path 4548'
          d='M1534.951,2749.55s-21.383,5.765-22.722,7.843-.135,5.161,3.351,6.167,25-7.709,25-7.709Z'
          transform='translate(-1285.771 -2385.25)'
          fill='#1b2346'
        />
        <path
          id='Path_4549'
          data-name='Path 4549'
          d='M1719.76,2735.811l5.5,6.234s4.222-1.207,4.893-3.485S1726.464,2733,1719.76,2735.811Z'
          transform='translate(-1467.294 -2372.315)'
          fill='#1b2346'
        />
        <path
          id='Path_4550'
          data-name='Path 4550'
          d='M1907.315,2587.1s-18.956,5.11-20.145,6.952-.119,4.576,2.971,5.468,22.166-6.834,22.166-6.834Z'
          transform='translate(-1612.935 -2243.523)'
          fill='#1b2346'
        />
        <path
          id='Path_4551'
          data-name='Path 4551'
          d='M2071.16,2574.92l4.873,5.526s3.744-1.069,4.337-3.09S2077.1,2572.424,2071.16,2574.92Z'
          transform='translate(-1773.867 -2232.056)'
          fill='#1b2346'
        />
        <path
          id='Path_4552'
          data-name='Path 4552'
          d='M1970.479,2654.42s-18.958,5.112-20.145,6.954-.119,4.576,2.971,5.466,22.166-6.834,22.166-6.834Z'
          transform='translate(-1668.041 -2302.255)'
          fill='#1b2346'
        />
        <path
          id='Path_4553'
          data-name='Path 4553'
          d='M2134.31,2642.245l4.873,5.527s3.744-1.07,4.337-3.091S2140.253,2639.749,2134.31,2642.245Z'
          transform='translate(-1828.961 -2290.792)'
          fill='#1b2346'
        />
        <path
          id='Path_4554'
          data-name='Path 4554'
          d='M2769.64,2395.73s-16.787,4.072-17.839,5.539-.106,3.646,2.63,4.356,19.628-5.444,19.628-5.444Z'
          transform='translate(-2367.315 -2076.565)'
          fill='#1b2346'
        />
        <path
          id='Path_4555'
          data-name='Path 4555'
          d='M2914.72,2386.03l4.315,4.4s3.317-.852,3.841-2.462S2919.986,2384.041,2914.72,2386.03Z'
          transform='translate(-2509.817 -2067.432)'
          fill='#1b2346'
        />
        <path
          id='Path_4556'
          data-name='Path 4556'
          d='M2825.562,2449.37s-16.786,4.072-17.839,5.539-.1,3.646,2.632,4.356,19.627-5.444,19.627-5.444Z'
          transform='translate(-2416.104 -2123.362)'
          fill='#1b2346'
        />
        <path
          id='Path_4557'
          data-name='Path 4557'
          d='M2970.64,2439.669l4.315,4.4s3.317-.852,3.841-2.462S2975.9,2437.681,2970.64,2439.669Z'
          transform='translate(-2558.604 -2114.229)'
          fill='#1b2346'
        />
        <path
          id='Path_4558'
          data-name='Path 4558'
          d='M2249.074,2535.183c-4.149,1.566-8.238,3.207-12.5,4.444q-3.189.926-6.441,1.658c-2.217.5-4.559.893-6.75.078-.269-.1-.765.19-.347.34a12.2,12.2,0,0,0,6.41.176c2.227-.422,4.435-.985,6.622-1.584a81.726,81.726,0,0,0,13.487-4.728C2249.952,2535.371,2249.312,2535.093,2249.074,2535.183Z'
          transform='translate(-1906.228 -2198.213)'
          fill='#ca5303'
        />
        <path
          id='Path_4559'
          data-name='Path 4559'
          d='M2564.9,2563.616a10.413,10.413,0,0,1-1.884,4.433,40.893,40.893,0,0,1-3.013,3.944,24.694,24.694,0,0,1-3.53,3.393,22.863,22.863,0,0,1-3.99,2.4c-.383.189.24.424.45.349a19.258,19.258,0,0,0,7.788-5.875,35.911,35.911,0,0,0,3.046-4.082,10.292,10.292,0,0,0,1.762-4.559C2565.555,2563.382,2564.934,2563.374,2564.9,2563.616Z'
          transform='translate(-2193.687 -2222.879)'
          fill='#ca5303'
        />
        <path
          id='Path_4560'
          data-name='Path 4560'
          d='M1828.1,2082.345a3.545,3.545,0,1,0-3.545,3.546A3.607,3.607,0,0,0,1828.1,2082.345Z'
          transform='translate(-1555.625 -1800.064)'
          fill='#fff'
        />
        <path
          id='Path_4561'
          data-name='Path 4561'
          d='M2018.282,2131.49a1.382,1.382,0,0,0,0,2.762A1.382,1.382,0,0,0,2018.282,2131.49Z'
          transform='translate(-1726.569 -1846.033)'
          fill='#fff'
        />
        <path
          id='Path_4562'
          data-name='Path 4562'
          d='M1757.736,2247.8l-.115-.857-.325-.765-.51-.653-.653-.51-.766-.325a2.454,2.454,0,0,0-1.287-.057,1.6,1.6,0,0,0-.814.221,2.415,2.415,0,0,0-1.04.667l-.066.084a3.459,3.459,0,0,0-.911,2.2l.111.827a3.111,3.111,0,0,0,.8,1.373l.066.084.653.5.765.325a2.442,2.442,0,0,0,1.287.059,3.189,3.189,0,0,0,2.521-1.928A2.432,2.432,0,0,0,1757.736,2247.8Z'
          transform='translate(-1494.766 -1944.689)'
          fill='#fff'
        />
        <path
          id='Path_4563'
          data-name='Path 4563'
          d='M2170.8,2252.79a1.856,1.856,0,0,0,0,3.709A1.856,1.856,0,0,0,2170.8,2252.79Z'
          transform='translate(-1859.231 -1951.859)'
          fill='#fff'
        />
        <path
          id='Path_4564'
          data-name='Path 4564'
          d='M2764.955,1972.754c0-.091-.106-.862-.127-.943l-.019-.069c-.031-.1-.289-.709-.339-.806a3.718,3.718,0,0,0-1.982-1.531l-.069-.019-.027-.008-.929-.128a2.632,2.632,0,0,0-1.347.3,1.733,1.733,0,0,0-.765.449,2.637,2.637,0,0,0-.9.981l-.352.834c-.042.31-.083.62-.127.93.042.309.083.619.127.929a2.644,2.644,0,0,0,.625,1.189,1.769,1.769,0,0,0,.628.626,2.62,2.62,0,0,0,1.188.625l.93.128.928-.128a2.631,2.631,0,0,0,1.189-.625,1.77,1.77,0,0,0,.626-.626,1.746,1.746,0,0,0,.449-.766A2.613,2.613,0,0,0,2764.955,1972.754Z'
          transform='translate(-2373.063 -1704.488)'
          fill='#fff'
        />
        <path
          id='Path_4565'
          data-name='Path 4565'
          d='M2786.628,2172.05a2.391,2.391,0,1,0,2.391,2.391A2.418,2.418,0,0,0,2786.628,2172.05Z'
          transform='translate(-2395.977 -1881.419)'
          fill='#fff'
        />
        <path
          id='Path_4566'
          data-name='Path 4566'
          d='M2899.284,1691.055l-.145-1.081a4.054,4.054,0,0,0-1.043-1.786l-.822-.638a4.046,4.046,0,0,0-2.044-.551,4.173,4.173,0,0,0-4.061,4.062l.146,1.079a4.056,4.056,0,0,0,1.043,1.786l.823.638a4.039,4.039,0,0,0,2.049.555,4.172,4.172,0,0,0,4.06-4.06Z'
          transform='translate(-2489.271 -1458.244)'
          fill='#fff'
        />
        <circle
          id='Ellipse_2272'
          data-name='Ellipse 2272'
          cx='2.082'
          cy='2.082'
          r='2.082'
          transform='translate(442.275 198.832)'
          fill='#fff'
        />
        <path
          id='Path_4567'
          data-name='Path 4567'
          d='M3265,1727.76a1.134,1.134,0,0,0,0,2.267A1.134,1.134,0,0,0,3265,1727.76Z'
          transform='translate(-2814.457 -1493.805)'
          fill='#fff'
        />
        <path
          id='Path_4568'
          data-name='Path 4568'
          d='M3013.057,1623.44a1.423,1.423,0,0,0,0,2.845A1.423,1.423,0,0,0,3013.057,1623.44Z'
          transform='translate(-2594.412 -1402.793)'
          fill='#fff'
        />
        <path
          id='Path_4569'
          data-name='Path 4569'
          d='M3652,1270.119l-.145-1.074a4.027,4.027,0,0,0-1.039-1.786l-.818-.638a4.03,4.03,0,0,0-2.041-.551,4.15,4.15,0,0,0-4.04,4.04q.07.536.145,1.074a4.03,4.03,0,0,0,1.038,1.786l.817.638a4.021,4.021,0,0,0,2.041.552,4.154,4.154,0,0,0,4.04-4.04Z'
          transform='translate(-3145.997 -1091.011)'
          fill='#fff'
        />
        <path
          id='Path_4570'
          data-name='Path 4570'
          d='M3729.825,1375.28a1.836,1.836,0,0,0,0,3.669A1.836,1.836,0,0,0,3729.825,1375.28Z'
          transform='translate(-3219.397 -1186.289)'
          fill='#fff'
        />
        <path
          id='Path_4571'
          data-name='Path 4571'
          d='M1241.427,1547.009l-.138-1.021-.383-.92a2.906,2.906,0,0,0-.994-1.082,1.924,1.924,0,0,0-.85-.5,2.9,2.9,0,0,0-1.486-.332l-1.021.138-.921.383a3.862,3.862,0,0,0-1.383,1.383l-.061.1a3.628,3.628,0,0,0,0,3.7l.061.1a2.92,2.92,0,0,0,.994,1.082,1.923,1.923,0,0,0,.85.495,2.9,2.9,0,0,0,1.485.332l1.021-.138.921-.383a2.911,2.911,0,0,0,1.081-.993,1.924,1.924,0,0,0,.5-.851A2.9,2.9,0,0,0,1241.427,1547.009Z'
          transform='translate(-1043.225 -1332.75)'
          fill='#fff'
        />
        <path
          id='Path_4572'
          data-name='Path 4572'
          d='M1572.655,2098.41c-2.6,0-2.6,4.04,0,4.04S1575.262,2098.41,1572.655,2098.41Z'
          transform='translate(-1337.252 -1817.173)'
          fill='#fff'
        />
        <path
          id='Path_4573'
          data-name='Path 4573'
          d='M1498.552,702.433c-.027.022-.054.043-.082.064.464-.351.458-.349.346-.265l-.171.127Z'
          transform='translate(-1274.233 -599.058)'
          fill='#ffe3c2'
        />
        <path
          id='Path_4574'
          data-name='Path 4574'
          d='M1116.238,378.165a3.405,3.405,0,0,0-3.2-2.435c-.591,0-1.159.006-1.732.043a16.272,16.272,0,0,0-5.493.552c-1.538.335-3.062.682-4.573,1.148q-4.39,1.329-8.7,2.912a190.384,190.384,0,0,0-77.292,52.652q-3.036,3.425-5.905,7c-2.055,2.559-3.925,5.23-5.835,7.891-1.778,2.481-3.37,5.058-5.024,7.622s-3.241,5.164-4.77,7.8-3,5.32-4.4,8.037q-1.066,2.063-2.082,4.15c-.371.765-.669,1.55-.966,2.342-.019.054-.128.295-.136.337-.054.116-.111.232-.166.348l-.531,1.138q-3.907,8.451-6.975,17.256-.737,2.129-1.424,4.276a1.9,1.9,0,1,0,3.673,1.013c1.778-4.819,3.661-9.325,5.8-13.967q1.6-3.466,3.338-6.866c.592-1.158,1.332-2.213,1.949-3.352q.893-1.658,1.824-3.286,3.812-6.707,8.153-13.092,1.059-1.558,2.147-3.093.546-.765,1.1-1.531c.172-.237.352-.469.521-.709-.424.6.227-.235.375-.407.972-1.137,1.84-2.363,2.75-3.55a1.409,1.409,0,0,0,.082-.117c.032-.036.061-.066.074-.083l.538-.689c.412-.524.841-1.032,1.276-1.531,3.345-3.895,6.6-7.857,10.1-11.617s7.155-7.384,10.953-10.843c1.906-1.739,3.836-3.46,5.828-5.1.494-.406,1-.8,1.5-1.2l.7-.554c.1-.079.2-.156.3-.236-1.148.931.32-.247.51-.394.986-.788,1.973-1.578,2.968-2.356l-.127.1c.07-.052.14-.107.209-.161l.046-.036c.065-.051.157-.12.218-.166l.638-.487q.811-.615,1.629-1.221,1.531-1.137,3.09-2.243,3.16-2.25,6.414-4.374a198.961,198.961,0,0,1,27.545-14.888q1.48-.659,2.969-1.291,6.353-2.655,12.884-4.848c2.253-.756,4.549-1.413,6.78-2.227,2.435-.893,5.1-1.51,7.222-3.031.577-.27,1.135-.574,1.7-.876A3.364,3.364,0,0,0,1116.238,378.165Z'
          transform='translate(-819.253 -314.225)'
          fill='#f8f9fb'
        />
        <path
          id='Path_4575'
          data-name='Path 4575'
          d='M2193.283,342.555l-.217-.51a3,3,0,0,0,.276.619,2.619,2.619,0,0,0,.851.851,4,4,0,0,0,1.513.375c1.009.148,2.011.255,3.028.337,1.693.139,3.386.2,5.081.3,1.651.094,3.3.223,4.948.346q1.234.092,2.466.218.554.057,1.106.128l.615.075c.781.092-.646-.1.069.008a61.554,61.554,0,0,1,9.028,1.939c1.355.413,2.679.893,4,1.424l.98.39c.552.219-.213-.085-.264-.114.182.1.392.17.582.255q.965.431,1.9.913c1.43.736,2.863,1.481,4.221,2.345,1.557.991,3.42,2.462,5.385,1.907a2.061,2.061,0,0,0,1.433-1.433c.49-2-1.028-3.9-2.433-5.135a34.428,34.428,0,0,0-3.988-3.083,36.139,36.139,0,0,0-8.711-3.934,62.16,62.16,0,0,0-20.219-2.57c-1.945.05-3.891.112-5.824.329-1.139.128-2.274.255-3.4.471a3.323,3.323,0,0,0-1.993.792,2.345,2.345,0,0,0-.43,2.768Z'
          transform='translate(-1880.167 -281.488)'
          fill='#f8f9fb'
        />
        <path
          id='Path_4576'
          data-name='Path 4576'
          d='M3278.77,2030.659a20.58,20.58,0,0,0,8.394.04,35.748,35.748,0,0,0,7.36-2.1,83.036,83.036,0,0,0,14.137-7.46,139.27,139.27,0,0,0,24.11-19.117q2.954-2.973,5.74-6.107a40.443,40.443,0,0,0,3.006-3.838c.866-1.251,1.957-2.517,2.223-4.044a2.4,2.4,0,0,0-.592-2.239,2.262,2.262,0,0,0-2.24-.593,6.124,6.124,0,0,0-2.168,1.307q-.706.69-1.424,1.371c-.906.861-1.84,1.684-2.679,2.6q-2.5,2.718-5.125,5.313-5.3,5.23-10.988,10.031-1.389,1.171-2.806,2.31-.687.551-1.38,1.095l-.347.272c-.094.074-.477.383-.1.08s-.009,0-.1.079l-.439.335q-2.983,2.273-6.079,4.395a120.492,120.492,0,0,1-12.854,7.811c-1.275.659-2.581,1.259-3.892,1.845-.134.06-.273.127-.412.181.638-.237-.08.024-.167.057q-.474.184-.954.356c-.672.241-1.351.457-2.032.669-2.694.838-5.439,1.348-8.187,1.961a1.771,1.771,0,0,0,0,3.389Z'
          transform='translate(-2826.333 -1718.311)'
          fill='#5fa02e'
        />
      </g>
    </svg>
  );
};
