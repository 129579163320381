import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const SendEmailIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "14.313px"}
      height={height ? height : "14.313px"}
      viewBox={viewBox ? viewBox : "0 0 14.313 14.313"}
      {...props}
    >
      <path
        id='icons8-email-send_1_'
        data-name='icons8-email-send (1)'
        d='M16.716,3a.6.6,0,0,0-.169.026l-.048.015L3.388,7.211v0a.595.595,0,0,0-.1,1.067l3.985,3.161L15.15,5.163,8.872,13.04l3.159,3.982a.6.6,0,0,0,1.069-.1h0L17.275,3.8q.006-.018.012-.036A.593.593,0,0,0,16.716,3Z'
        transform='translate(-3 -3)'
        fill={fill ? fill : "#3b5d98"}
      />
    </SvgIcon>
  );
};
