import { AvatarUserIcon, PortraitBackgroundIcon } from "@/components";
import { EmptyUserAvatarWithBorderIconProps, UserAvatarWithDefaultFillIcon } from "@/components/Icons/UserAvatarWithDefaultFillIcon";
import { useStyles } from "./PatientAvatarStyles";
import { Box } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

interface PatientAvatarsProps extends EmptyUserAvatarWithBorderIconProps {
  src?: string;
  width: string;
  height: string;
  id?: string;
  isLoading?: boolean;
  withoutBackground?: boolean;
  withoutDefaultIcon?: boolean;
}

export const PatientAvatar: FC<PropsWithChildren<PatientAvatarsProps>> = props => {
  const { src, width, height, isLoading, id = "", withoutBackground = false, withoutDefaultIcon = false, children, ...rest } = props;
  const { classes } = useStyles({ width, height });

  return (
    <Box className={classes.root}>
      {!withoutBackground && <PortraitBackgroundIcon className={classes.background} />}
      <UserAvatarWithDefaultFillIcon
        id={id}
        width={width}
        height={height}
        src={!isLoading ? src : undefined}
        className={classes.avatar}
        {...rest}
      />
      {!withoutDefaultIcon && !isLoading && !src && <AvatarUserIcon className={classes.userIcon} />}

      {children}
    </Box>
  );
};
