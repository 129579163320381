import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const DiscardInboxIcon: FC<SvgIconProps> = ({ fill, width, height, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20.994"}
      height={height ? height : "20.994"}
      viewBox={viewBox ? viewBox : "0 0 20.994 20.994"}
      {...props}
    >
      <path
        id='icons8-remove-from-inbox'
        d='M19.342,0a5.652,5.652,0,1,0,5.652,5.652A5.651,5.651,0,0,0,19.342,0ZM7.123,3.23a1.625,1.625,0,0,0-1.6,1.386L4.008,15.228A.767.767,0,0,0,4,15.342v4.037a1.627,1.627,0,0,0,1.615,1.615H20.149a1.627,1.627,0,0,0,1.615-1.615V15.342a.767.767,0,0,0-.008-.114l-.372-2.605a7.234,7.234,0,0,1-1.591.274l.349,2.444H15.156a2.409,2.409,0,0,1-4.548,0H5.623l1.5-10.5v0h5A7.246,7.246,0,0,1,12.5,3.23Zm10.6,0a.8.8,0,0,1,.571.237L19.342,4.51l1.044-1.044a.807.807,0,1,1,1.142,1.142L20.484,5.652,21.528,6.7a.807.807,0,1,1-1.142,1.142L19.342,6.794,18.3,7.838A.807.807,0,0,1,17.156,6.7L18.2,5.652,17.156,4.608a.807.807,0,0,1,.571-1.378Z'
        transform='translate(-4)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
