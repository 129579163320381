import { useTranslation } from "@toolkit/i18n";
import { ChangeEvent, useState } from "react";
import { useReasonsQuery } from "@/components/Reasons/gql/queries/__generated__/reasons";
import { AffectedType } from "@health/queries/types";

interface ReasonsFormState {
  reason: string;
  other: string;
  isOther: boolean;
}

interface UseReasonsFormProps {
  isCancellation: boolean;
  onSubmit?: (reason: string) => void;
}

interface UseReasonsFormReturn {
  state: ReasonsFormState;
  reasons: Array<{ id: string; text: string; text_ar: string } | null>;
  disabled: boolean;
  error: string | null;
  handleChangeOtherReason: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeReason: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

export const useReasonsForm = ({ isCancellation, onSubmit }: UseReasonsFormProps): UseReasonsFormReturn => {
  const [state, setState] = useState<ReasonsFormState>({ reason: "", other: "", isOther: false });
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation("consumer");

  const { data, loading: reasonsLoading } = useReasonsQuery({
    variables: {
      affectedType: AffectedType.Appointment,
      isCancellation: isCancellation,
    },
    fetchPolicy: "cache-first",
  });

  const _reasons = isCancellation ? data?.cancellationReasons : data?.rejectionReasons;
  const reasons = _reasons?.length
    ? _reasons.map(reason => (reason ? { ...reason, text: reason.text ?? "", text_ar: reason.text_ar ?? "" } : reason))
    : [{ id: "1", text: "Other", text_ar: t("Other") }];

  const disabled = reasonsLoading || (state.isOther ? !state.other : !state.reason);

  const handleChangeReason = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === "Other") {
      setState({ ...state, isOther: true, reason: event.target.value });
    } else {
      setState({ ...state, isOther: false, reason: event.target.value, other: "" });
    }
  };

  const handleChangeOtherReason = (event: ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, other: event.target.value, isOther: true });
  };
  const handleSubmit = (): void => {
    const reason = state?.isOther ? state?.other : state.reason;
    if (!reason || reasonsLoading) {
      setError(t("Please add your reason"));
    } else {
      setError("");
      onSubmit?.(reason);
    }
  };

  return {
    state,
    reasons,
    disabled,
    handleChangeOtherReason,
    handleChangeReason,
    handleSubmit,
    error,
  };
};
