import { getApolloContextFromToken } from "@/providers/auth";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { useAuth } from "react-oidc-context";
import { useMeComingSoonAppointmentQuery } from "../../gql";

export const useComingSoonCard = () => {
  const { user } = useAuth();
  const { data, loading, refetch } = useMeComingSoonAppointmentQuery({
    context: getApolloContextFromToken(user?.access_token),
    skip: !user?.access_token,
  });

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetch();
    }
  });

  const appointment = data?.appointments?.edges[0]?.node;
  return {
    appointment,
    loading,
  };
};
