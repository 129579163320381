import { makeStyles, Theme } from "@toolkit/ui";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    direction: "ltr",
    backgroundColor: theme.palette.background.default,
  },
  input: {
    width: "60px",
    minHeight: "60px",
    margin: "0px 5px",
    boxShadow: theme.mixins.shadows.xs,
    borderRadius: "10px",
    border: "none",
    fontSize: theme.mixins.fonts.fontSize.xl,
    textAlign: "center",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
    direction: "ltr",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
}));
