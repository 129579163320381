import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  uploadContainer: {
    display: "none",
  },
  uploadButton: {
    position: "absolute",
    [theme.direction === "ltr" ? "left" : "right"]: "0",
    bottom: "10%",
    width: "30%",
    height: "30%",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.sm,
    border: "2px solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
    "& svg": {
      width: "18px",
      height: "14px",
      zIndex: theme.zIndex.appBar,
    },
  },
  loadingProgress: {
    position: "absolute",
    width: "44%",
    height: "44%",
    top: "calc(50% - 20%)",
    [theme.direction === "ltr" ? "left" : "right"]: "calc(50% - 21%)",
  },
  firstDependentBox: {
    width: "32%",
    height: "32%",
    position: "absolute",
    top: "32%",
    right: "0",
    transform: theme?.direction === "ltr" ? "scaleX(-1)" : "scaleX(1)",
    cursor: "pointer",
  },
  secondDependentBox: {
    width: "26%",
    height: "26%",
    position: "absolute",
    bottom: "10%",
    right: "20px",
    transform: theme?.direction === "ltr" ? "scaleX(-1)" : "scaleX(1)",
    cursor: "pointer",
  },
  remainDependentsBox: {
    width: "25%",
    height: "25%",
    position: "absolute",
    top: "12%",
    right: "10%",
    transform: theme?.direction === "rtl" ? "scaleX(-1)" : "scaleX(1)",
    cursor: "pointer",
  },
  dependentTypography: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: theme.mixins.fonts.fontSize.lg,
    color: theme.palette.common.white,
  },
}));
