import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { getNotificationTextStyle } from "../../utils";
import { NotificationBellIcon } from "@/components";

type ERXCreateProps = {
  reference: string;
  creationDate: string;
  isSeen: boolean;
};

export const ERXCreate: FC<ERXCreateProps> = ({ reference, creationDate, isSeen }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();

  const { color } = getNotificationTextStyle(isSeen, theme);

  return (
    <NotificationCard title={t("New Prescription")} date={creationDate} icon={<NotificationBellIcon />} isSeen={isSeen}>
      <Box>
        <Typography sx={{ color }}>{t("Your provider has issued a new prescription for you")}</Typography>
        <Typography sx={{ color }}>{reference}</Typography>
      </Box>
    </NotificationCard>
  );
};
