/* eslint-disable react/no-unknown-property */
import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Important: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.301' height='15.301' viewBox='0 0 15.301 15.301' {...props}>
      <path
        id='icons8-box-important_1_'
        data-name='icons8-box-important (1)'
        d='M10.65,3a7.65,7.65,0,1,0,7.65,7.65A7.651,7.651,0,0,0,10.65,3Zm.773,3.188L11.3,11.925h-1.29L9.878,6.188Zm-.771,9.046c-.528,0-.843-.281-.843-.754a.738.738,0,0,1,.843-.762.762.762,0,1,1,0,1.516Z'
        transform='translate(-3 -3)'
        fill={props.fill ? props.fill : palette.secondary.main}
      />
    </SvgIcon>
  );
};
