import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SpeechIconBooking: FC<SvgIconProps> = props => {
  const { fill } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='22' height='20.308' viewBox='0 0 22 20.308' {...props}>
      <path
        id='icons8-speech'
        d='M13,3C6.925,3,2,7.167,2,12.308a8.459,8.459,0,0,0,2.547,5.951c.032.08.847,2.178-1.567,4.317a.423.423,0,0,0,.38.722,11.254,11.254,0,0,0,5.786-2.282,12.732,12.732,0,0,0,3.854.6c6.075,0,11-4.167,11-9.308S19.075,3,13,3Z'
        transform='translate(-2 -3)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
