import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const NotificationIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-notification' width='15.926' height='17.27' viewBox='0 0 15.926 17.27'>
      <path
        id='Path_13'
        data-name='Path 13'
        d='M22.784,18.6a.664.664,0,1,1,0-1.328.2.2,0,0,0,.177-.087.274.274,0,0,0,.016-.255l-1.006-1.968q-.01-.02-.019-.041a3.235,3.235,0,0,1-.275-1.236V10.635a5.247,5.247,0,0,0-1.62-3.814,5.381,5.381,0,0,0-3.977-1.5A5.276,5.276,0,0,0,11.563,8.4a.664.664,0,1,1-1.2-.572,6.879,6.879,0,0,1,2.267-2.683,6.377,6.377,0,0,1,3.4-1.148,6.781,6.781,0,0,1,2.721.449,6.657,6.657,0,0,1,2.229,1.42,6.593,6.593,0,0,1,2.027,4.771v3.055a1.93,1.93,0,0,0,.159.693l1.007,1.971q.01.02.019.041a1.61,1.61,0,0,1-.121,1.526A1.532,1.532,0,0,1,22.784,18.6Z'
        transform='translate(-8.402 -3.989)'
        fill={fill}
      />
      <path
        id='Path_14'
        data-name='Path 14'
        d='M17.252,27.637h-9.7a1.532,1.532,0,0,1-1.286-.684,1.61,1.61,0,0,1-.121-1.526l.016-.035,1.009-2.018a1.72,1.72,0,0,0,.16-.7V20.864a.664.664,0,1,1,1.328,0v1.815a3.061,3.061,0,0,1-.275,1.236l-.016.035L7.364,25.965a.275.275,0,0,0,.015.257.2.2,0,0,0,.177.087h9.7a.664.664,0,1,1,0,1.328Z'
        transform='translate(-6.013 -13.024)'
        fill={fill}
      />
      <path
        id='Path_15'
        data-name='Path 15'
        d='M20.656,38.82A2.648,2.648,0,0,1,18,36.164a.664.664,0,0,1,1.328,0,1.32,1.32,0,0,0,1.328,1.328,1.27,1.27,0,0,0,.847-.323.664.664,0,1,1,.875,1A2.58,2.58,0,0,1,20.656,38.82Z'
        transform='translate(-12.693 -21.55)'
        fill={fill}
      />
    </SvgIcon>
  );
};
