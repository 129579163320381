import { Avatar, Box, Button, CircularProgress } from "@toolkit/ui";
import useStyles from "./Profile.styles";

export const Profile: React.FC = () => {
  const { classes } = useStyles();
  const loading = false;

  return (
    <Box className={classes.container}>
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <>
          <Avatar src={"NoPhoto.png"} className={classes.avatar} />
          <Button className={classes.button} endIcon={<Box className={classes.buttonIcon}></Box>}>
            {"Username" ?? "" + " " + "HardCorded" ?? ""}
          </Button>
        </>
      )}
    </Box>
  );
};
