import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const AfterNoonIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  theme.palette.primary.main;
  const fillValue = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26.5' height='26.5' viewBox='0 0 26.5 26.5' {...props}>
      <g id='icons8-sun_2_' data-name='icons8-sun (2)' transform='translate(-4 -4)'>
        <path
          id='Line_1582'
          data-name='Line 1582'
          d='M-.469,2.625A1.031,1.031,0,0,1-1.5,1.594V-.469A1.031,1.031,0,0,1-.469-1.5,1.031,1.031,0,0,1,.563-.469V1.594A1.031,1.031,0,0,1-.469,2.625Z'
          transform='translate(18.219 5.5)'
          fill={fillValue}
        />
        <path
          id='Line_1583'
          data-name='Line 1583'
          d='M.975,2.006a1.028,1.028,0,0,1-.729-.3L-1.2.26A1.031,1.031,0,0,1-1.2-1.2,1.031,1.031,0,0,1,.26-1.2L1.7.246a1.031,1.031,0,0,1-.729,1.76Z'
          transform='translate(9.213 9.213)'
          fill={fillValue}
        />
        <path
          id='Line_1584'
          data-name='Line 1584'
          d='M1.594.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H1.594A1.031,1.031,0,0,1,2.625-.469,1.031,1.031,0,0,1,1.594.563Z'
          transform='translate(5.5 18.219)'
          fill={fillValue}
        />
        <path
          id='Line_1585'
          data-name='Line 1585'
          d='M1.594.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H1.594A1.031,1.031,0,0,1,2.625-.469,1.031,1.031,0,0,1,1.594.563Z'
          transform='translate(28.875 18.219)'
          fill={fillValue}
        />
        <path
          id='Line_1586'
          data-name='Line 1586'
          d='M-.469,2.006A1.028,1.028,0,0,1-1.2,1.7,1.031,1.031,0,0,1-1.2.246L.246-1.2A1.031,1.031,0,0,1,1.7-1.2,1.031,1.031,0,0,1,1.7.26L.26,1.7A1.028,1.028,0,0,1-.469,2.006Z'
          transform='translate(25.781 9.213)'
          fill={fillValue}
        />
        <path
          id='Path_12990'
          data-name='Path 12990'
          d='M14.788,24.688a1.031,1.031,0,0,1-.909-.543A7.68,7.68,0,0,1,13,20.563,7.6,7.6,0,0,1,20.563,13a7.057,7.057,0,0,1,1.371.089,1.031,1.031,0,1,1-.4,2.022,5.719,5.719,0,0,0-.967-.049,5.542,5.542,0,0,0-5.5,5.5,5.583,5.583,0,0,0,.633,2.6,1.032,1.032,0,0,1-.907,1.52Z'
          transform='translate(-2.813 -2.813)'
          fill={fillValue}
        />
        <path
          id='Path_12991'
          data-name='Path 12991'
          d='M31.368,25.531a1.032,1.032,0,0,1-.907-1.52,5.583,5.583,0,0,0,.633-2.6,5.339,5.339,0,0,0-1.671-3.925,1.031,1.031,0,0,1,1.416-1.5,7.382,7.382,0,0,1,2.317,5.425,7.68,7.68,0,0,1-.88,3.583A1.031,1.031,0,0,1,31.368,25.531Z'
          transform='translate(-7.844 -3.656)'
          fill={fillValue}
        />
        <path
          id='Line_1587'
          data-name='Line 1587'
          d='M15.069.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H15.069A1.031,1.031,0,0,1,16.1-.469,1.031,1.031,0,0,1,15.069.563Z'
          transform='translate(12.65 25.438)'
          fill={fillValue}
        />
        <path
          id='Line_1588'
          data-name='Line 1588'
          d='M1.112.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H1.112A1.031,1.031,0,0,1,2.144-.469,1.031,1.031,0,0,1,1.112.563Z'
          transform='translate(8.25 25.438)'
          fill={fillValue}
        />
        <path
          id='Line_1589'
          data-name='Line 1589'
          d='M.975.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H.975A1.031,1.031,0,0,1,2.006-.469,1.031,1.031,0,0,1,.975.563Z'
          transform='translate(22.619 29.563)'
          fill={fillValue}
        />
        <path
          id='Line_1590'
          data-name='Line 1590'
          d='M6.613.563H-.469A1.031,1.031,0,0,1-1.5-.469,1.031,1.031,0,0,1-.469-1.5H6.613A1.031,1.031,0,0,1,7.644-.469,1.031,1.031,0,0,1,6.613.563Z'
          transform='translate(12.375 29.563)'
          fill={fillValue}
        />
      </g>
    </SvgIcon>
  );
};
