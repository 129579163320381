import { Box, Skeleton } from "@toolkit/ui"; // or your Box component's source
import { useNotificationDetailsStyle } from "../../styles/NotificationDetailsStyle";
import { NotificationWrapperIcon } from "../../../../components/Icons/NotificationsIcon/NotificationWrapperIcon";
import { FC } from "react";

export const NotificationDetailsSkeleton: FC = () => {
  const { classes, theme } = useNotificationDetailsStyle();

  return (
    <Box className={classes.contentContainer}>
      <Box className={classes.notificationsIcon}>
        <NotificationWrapperIcon fill={theme.palette.gray[300]} />
      </Box>
      <Box className={classes.content}>
        <Skeleton variant='text' width='100%' height={20} />
        <Skeleton variant='text' width='80%' height={18} />
        <Skeleton variant='text' width='90%' height={20} />
      </Box>
    </Box>
  );
};

export default NotificationDetailsSkeleton;
