import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "@/domains/notifications/routes";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/components";

export const NotificationsRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout />}>
        <Route path={routes.root.route} element={<routes.root.component />} />
        <Route path={routes.healthEngagementMessages.route} element={<routes.healthEngagementMessages.component />} />
      </Route>

      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.appointmentNotification.route} element={<routes.appointmentNotification.component />} />
      </Route>

      <Route path='*' element={<div>Not Found</div>} />
    </Routes>
  );
};
