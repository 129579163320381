import { z } from "zod";
import { zodSchema } from "@health/domains";

export const AppointmentsListFiltersFormSchema = z.object({
  specializations: z.array(zodSchema.systemCodeAutocompleteOptional).optional(),
  appointmentTypes: z.array(zodSchema.appointmentTypeAutocompleteEnumOptional).optional(),
  gender: zodSchema.userGenderTypeAutocompleteEnumOptional,
  vendors: z.array(zodSchema.vendorAutocompleteOptional).optional(),
  languages: z.array(zodSchema.languageAutocompleteOptional).optional(),
});

export type IAppointmentsListFiltersFormValues = z.infer<typeof AppointmentsListFiltersFormSchema>;
export const appointmentListFiltersFormDefaultValues: Partial<IAppointmentsListFiltersFormValues> = {
  specializations: undefined,
  appointmentTypes: undefined,
  gender: undefined,
  vendors: undefined,
  languages: undefined,
};
