import VisitCallPage from "./VisitCallPage";

export const VisitCallBaseRoute = "/visit-call";
export const routes = {
  root: {
    route: "/",
    component: VisitCallPage,
    get absRoute() {
      return this.route;
    },
  },
};
