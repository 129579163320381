import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "none",
  },
  callBoxRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  callBox: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    minHeight: "90px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "0 5px",
    maxWidth: theme.breakpoints.values.lg,
    justifySelf: "center",
    alignSelf: "center",
    zIndex: theme.zIndex.drawer,
  },
  iconButton: {
    borderRadius: "100%",
    aspectRatio: 1,
    height: "70px",
    width: "70px",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  textBox: {
    textAlign: "start",
    marginInlineStart: "10px",
  },
  mainText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  subText: {},
  rightIconBox: {
    marginLeft: "auto",
  },
  phoneIconButton: {
    borderRadius: "100%",
    aspectRatio: 1,
    height: "50px",
    width: "50px",
    marginInline: "10px",
    backgroundColor: theme.palette.error[900],
    "&:hover": {
      backgroundColor: theme.palette.error[1100],
    },
    "&:disabled": {
      backgroundColor: theme.palette.error[300],
    },
    "& svg": {
      backgroundColor: "transparent",
      fill: theme.palette.common.white,
      width: "25px",
      height: "25px",
    },
  },
}));
