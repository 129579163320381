import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const SupplementIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='36.459' height='45.517' viewBox='0 0 36.459 45.517' {...props}>
      <path
        id='icons8-protein-supplement'
        d='M18.813,4A4.009,4.009,0,0,0,14.83,7.983v2.276a3.714,3.714,0,0,0,.136.658A7.377,7.377,0,0,0,8,18.224v.858a1.707,1.707,0,0,0,.5,1.5l.009.009.022.022a1.707,1.707,0,0,0,1.454.451H41v5.205a1.707,1.707,0,1,0,3.414,0V19.642a1.707,1.707,0,0,0,0-.553v-.865a7.377,7.377,0,0,0-6.963-7.308,3.714,3.714,0,0,0,.136-.658V7.983A4.009,4.009,0,0,0,33.606,4H18.813Zm0,3.414h2.276v3.414H18.813a.544.544,0,0,1-.569-.569V7.983A.544.544,0,0,1,18.813,7.414Zm5.69,0h3.414v3.414H24.5Zm6.828,0h2.276a.544.544,0,0,1,.569.569v2.276a.544.544,0,0,1-.569.569H31.33ZM15.4,14.241H37.02a3.943,3.943,0,0,1,3.927,3.414H11.472A3.943,3.943,0,0,1,15.4,14.241Zm8.683,9.1a1.136,1.136,0,0,0-1.093.825l-1.565,5.474a1.14,1.14,0,0,0,1.1,1.451h1.667l-1.625,4.287a1.138,1.138,0,0,0,1.88,1.149l6.252-6.8a1.138,1.138,0,0,0-.793-1.954H27.507l.978-2.931a1.138,1.138,0,0,0-1.08-1.5Zm-14.4,1.271A1.707,1.707,0,0,0,8,26.347V40.7a1.707,1.707,0,0,0,0,.553v.865a7.423,7.423,0,0,0,7.4,7.4H37.02a7.423,7.423,0,0,0,7.4-7.4V33.246a1.707,1.707,0,1,0-3.414,0V42.12A3.958,3.958,0,0,1,37.02,46.1H15.4a3.943,3.943,0,0,1-3.927-3.414h22a1.707,1.707,0,1,0,0-3.414H11.417V26.347a1.707,1.707,0,0,0-1.734-1.731Z'
        transform='translate(-7.98 -4)'
        fill={props.fill || theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
