import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const HealthServices: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='43.547' height='39.918' viewBox='0 0 43.547 39.918' {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='43.547' height='39.918' viewBox='0 0 43.547 39.918'>
        <path
          id='icons8-heart-health_1_'
          data-name='icons8-heart-health (1)'
          d='M26.056,28.871a1.814,1.814,0,0,1-2.566,0L14.418,19.8a1.814,1.814,0,0,1,2.566-2.566l7.789,7.789,17.9-17.9a11.815,11.815,0,0,0-8-3.121c-6.269,0-9.9,5.443-9.9,5.443S21.145,4,14.876,4A11.875,11.875,0,0,0,3,15.876c0,11.505,13.465,22.51,19.589,27.311l.007-.011a3.565,3.565,0,0,0,4.355,0l.007.011c6.124-4.8,19.589-15.806,19.589-27.311a11.816,11.816,0,0,0-1.584-5.91Z'
          transform='translate(-3 -4)'
          fill={palette.common.white}
        />
      </svg>
    </SvgIcon>
  );
};
export default HealthServices;
