import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, useMediaQuery, useTheme, makeStyles } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

type FilterDialogProps = {
  open: boolean;
  onClose?: () => void;
  title: string;
  isApplyDisabled?: boolean;
  isClearDisabled?: boolean;
  handleApply?: () => void;
  handleReset?: () => void;
};

const useStyles = makeStyles()(theme => ({
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: theme.spacing(2),
    width: "100%",
  },
  button: {
    width: "100%",
    borderRadius: "24px",
  },
}));

export const FilterDialog: FC<PropsWithChildren<FilterDialogProps>> = props => {
  const { title, open, onClose, handleApply, handleReset, children, isApplyDisabled, isClearDisabled } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("consumer");
  const { classes } = useStyles();

  return (
    <>
      {!isMobileView && (
        <CustomDialog
          type='base'
          open={open}
          DialogTitleProps={{
            hasCloseButton: true,
            onClose,
            title,
          }}
          DialogActionsProps={{
            children: (
              <Box>
                <Button onClick={handleApply} disabled={isApplyDisabled}>
                  {t("Apply")}
                </Button>
                <Button variant='outlined' onClick={handleReset} disabled={isClearDisabled}>
                  {t("Clear")}
                </Button>
              </Box>
            ),
          }}
        >
          {children}
        </CustomDialog>
      )}
      {isMobileView && (
        <CustomDialog
          type='bottomSheet'
          open={open}
          handleClose={onClose}
          title={title}
          footer={
            <Box className={classes.actionsContainer}>
              <Button className={classes.button} onClick={handleApply} disabled={isApplyDisabled}>
                {t("Apply")}
              </Button>
              <Button className={classes.button} variant='outlined' onClick={handleReset} disabled={isClearDisabled}>
                {t("Clear")}
              </Button>
            </Box>
          }
        >
          {children}
        </CustomDialog>
      )}
    </>
  );
};
