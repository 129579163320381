import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const ProgramSubscriptions: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='38' height='35.286' viewBox='0 0 38 35.286' {...props}>
      <path
        id='icons8-stop-property'
        d='M4.714,4A2.714,2.714,0,0,0,2,6.714V33.857H21V31.143H4.714v-19H34.571v8.143h2.714V6.714A2.714,2.714,0,0,0,34.571,4Zm5.429,12.214A1.357,1.357,0,1,0,11.5,17.571,1.357,1.357,0,0,0,10.143,16.214Zm5.429,0a1.357,1.357,0,1,0,0,2.714H27.786a1.357,1.357,0,1,0,0-2.714Zm-5.429,5.429A1.357,1.357,0,1,0,11.5,23,1.357,1.357,0,0,0,10.143,21.643Zm5.429,0a1.357,1.357,0,1,0,0,2.714H21V21.643ZM26.429,23a2.714,2.714,0,0,0-2.714,2.714V36.571a2.714,2.714,0,0,0,2.714,2.714H37.286A2.714,2.714,0,0,0,40,36.571V25.714A2.714,2.714,0,0,0,37.286,23Z'
        transform='translate(-2 -4)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
export default ProgramSubscriptions;
