import { Box, Breakpoint, CircularProgress, Theme } from "@toolkit/ui";
import { FC, ReactNode, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useStyles } from "./PageLayoutStyle";
import { BlobIcon } from "../Icons";

type PageLayoutProps = {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  displayBlob?: boolean;
  containerBreakpoint?: Breakpoint;
  containerBackground?: keyof Theme["palette"]["background"];
};

export const PageLayout: FC<PageLayoutProps> = props => {
  const { children, header, footer, displayBlob, containerBreakpoint, containerBackground } = props;
  const { classes } = useStyles({
    containerBreakpoint: containerBreakpoint,
    containerBackground: containerBackground,
  });

  return (
    <Box flex={1} className={classes.root}>
      <Box className={classes.contentWrapper}>
        {header && <Box className={classes.header}>{header}</Box>}
        {displayBlob && (
          <Box className={classes.blobContainer}>
            <BlobIcon />
          </Box>
        )}
        <Suspense fallback={<CircularProgress />}>
          <Box className={classes.container}>
            <Outlet />
            {children}
          </Box>
        </Suspense>
      </Box>
      {footer && <Box className={classes.footer}>{footer}</Box>}
    </Box>
  );
};
