/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientProfileConsentFormQueryVariables = Types.Exact<{
  patientProfileConsentFormId: Types.Scalars['ID'];
}>;


export type PatientProfileConsentFormQuery = { __typename?: 'Query', patientProfileConsentForm?: { __typename?: 'PatientProfileConsentForm', id: string, requesterUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null };


export const PatientProfileConsentFormDocument = gql`
    query PatientProfileConsentForm($patientProfileConsentFormId: ID!) {
  patientProfileConsentForm(id: $patientProfileConsentFormId) {
    id
    requesterUser {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __usePatientProfileConsentFormQuery__
 *
 * To run a query within a React component, call `usePatientProfileConsentFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientProfileConsentFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientProfileConsentFormQuery({
 *   variables: {
 *      patientProfileConsentFormId: // value for 'patientProfileConsentFormId'
 *   },
 * });
 */
export function usePatientProfileConsentFormQuery(baseOptions: Apollo.QueryHookOptions<PatientProfileConsentFormQuery, PatientProfileConsentFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientProfileConsentFormQuery, PatientProfileConsentFormQueryVariables>(PatientProfileConsentFormDocument, options);
      }
export function usePatientProfileConsentFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientProfileConsentFormQuery, PatientProfileConsentFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientProfileConsentFormQuery, PatientProfileConsentFormQueryVariables>(PatientProfileConsentFormDocument, options);
        }
export type PatientProfileConsentFormQueryHookResult = ReturnType<typeof usePatientProfileConsentFormQuery>;
export type PatientProfileConsentFormLazyQueryHookResult = ReturnType<typeof usePatientProfileConsentFormLazyQuery>;
export type PatientProfileConsentFormQueryResult = Apollo.QueryResult<PatientProfileConsentFormQuery, PatientProfileConsentFormQueryVariables>;