import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const GraduationCap: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='30' height='27' viewBox='0 0 30 27' {...props}>
      <path
        id='icons8-graduation-cap'
        d='M15,3a1,1,0,0,0-.473.119L.537,10.113v0a1,1,0,0,0,0,1.77v0l13.984,6.99a.995.995,0,0,0,.951,0l13.98-6.99.01,0v0a1,1,0,0,0,0-1.77v0L15.479,3.123A1,1,0,0,0,15,3ZM29,14.354l-2,1v8.924A1.99,1.99,0,0,0,26,26c0,1.1,2,4,2,4s2-2.9,2-4a1.99,1.99,0,0,0-1-1.723Zm-23,2.5V21c0,2.761,4.029,5,9,5s9-2.239,9-5V16.852L16.365,20.67a1.975,1.975,0,0,1-.254.105,2.852,2.852,0,0,1-2.219,0,1.9,1.9,0,0,1-.258-.107Z'
        transform='translate(0 -3)'
        fill='#46a200'
      />
    </SvgIcon>
  );
};
export default GraduationCap;
