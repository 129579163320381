import { makeStyles } from "@toolkit/ui";

type NotificationCardStyleParams = {
  isSeen?: boolean;
};

export const useStyles = makeStyles<NotificationCardStyleParams>()((theme, props) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "13px",
    alignItems: "start",
    justifyContent: "start",
    width: "100%",
  },
  titleView: {
    marginBottom: 10,
  },
  iconContainer: {
    marginTop: "8px",
    "& svg": {
      width: "50px",
      height: "50px",
    },
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: props.isSeen ? theme.mixins.fonts.fontWeight.medium : theme.mixins.fonts.fontWeight.semiBold,
    color: props.isSeen ? theme.palette.stale.main : theme.palette.text.primary,
    textTransform: "capitalize",
    alignSelf: "flex-start",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "80%",
    textAlign: "start",
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: props.isSeen ? theme.palette.stale.main : theme.palette.text.primary,
    marginInlineStart: 8,
    alignSelf: "flex-start",
  },
  body: {
    flex: 1,
    width: "calc(100% - 20px)",
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
}));
