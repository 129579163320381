import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Checkmark2Icon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.727' height='21.727' viewBox='0 0 21.727 21.727' {...props}>
      <path
        id='icons8-checkmark'
        d='M12.864,2a10.879,10.879,0,1,0,9.146,5.012l-.647.764a9.965,9.965,0,1,1-1.826-2.229l.618-.73A10.822,10.822,0,0,0,12.864,2Zm8.614,2.718-9.126,10.77L8.509,11.9l-.644.691,4.566,4.258L22.2,5.328Z'
        transform='translate(-2 -2)'
        fill={props.fill ? props.fill : palette.success.main}
      />
    </SvgIcon>
  );
};
export default Checkmark2Icon;
