import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const ReferralsIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "86.257px"}
      height={height ? height : "79.406"}
      viewBox={viewBox ? viewBox : "0 0 86.257 79.406"}
      {...props}
    >
      <g id='Group_8325' data-name='Group 8325' transform='translate(57.608 36.433)'>
        <path
          id='Path_7782'
          data-name='Path 7782'
          d='M56.409,31.2H49.247A2.577,2.577,0,0,0,46.6,33.535v3.581H58.744V33.535h0A2.2,2.2,0,0,0,56.409,31.2Zm-1.868,3.737-.311.623-.934-.467v1.09h-.623v-1.09l-1.09.467-.311-.623.934-.467L51.271,34l.311-.623.934.467v-1.09h.623v1.09l.934-.467.311.623-.934.467Z'
          transform='translate(-38.348 -29.954)'
          fill={fill ? fill : "#305e9d"}
        />
        <g id='Group_8324' data-name='Group 8324' transform='translate(0 0)'>
          <path
            id='Path_7783'
            data-name='Path 7783'
            d='M59.517,50.174A7,7,0,0,0,63.1,43.946V34.137a3.646,3.646,0,0,0-1.09-2.647,3.415,3.415,0,0,0-2.647-1.09H52.2a3.965,3.965,0,0,0-4.048,3.737V43.79a7.274,7.274,0,0,0,3.737,6.228A14.343,14.343,0,0,0,41.3,63.875a.778.778,0,1,0,1.557,0,12.751,12.751,0,0,1,6.851-11.366,7.815,7.815,0,0,0-.311,1.868,4.017,4.017,0,0,0-2.958,3.892v4.515a2.009,2.009,0,0,0,1.868,1.868.778.778,0,0,0,0-1.557.5.5,0,0,1-.467-.467V58.114a2.333,2.333,0,0,1,2.335-2.491,2.43,2.43,0,0,1,2.335,2.491V62.63a.5.5,0,0,1-.467.467.778.778,0,0,0,0,1.557h0a2.009,2.009,0,0,0,1.868-1.868V58.27a3.885,3.885,0,0,0-2.958-3.892,7.685,7.685,0,0,1,.467-2.335l3.737,4.982c.156.156.311.311.467.311s.467,0,.467-.156l.156-.156,3.737-4.982a16.982,16.982,0,0,1,1.246,4.827,2.474,2.474,0,0,0-1.557,1.246,3.113,3.113,0,0,0-.311,2.024,2.813,2.813,0,0,0,5.449-1.4,2.94,2.94,0,0,0-2.024-2.024,17.06,17.06,0,0,0-.934-4.048,12.954,12.954,0,0,1,6.539,11.366.778.778,0,0,0,1.557,0A14.205,14.205,0,0,0,59.517,50.174Zm2.491,8.1a1.228,1.228,0,0,1,1.246,1.246,1.339,1.339,0,0,1-1.246,1.246h0a1.246,1.246,0,0,1,0-2.491Zm-.467-19.307V40.52a13.6,13.6,0,0,1-5.761-1.557ZM55.936,49.707h0a5.963,5.963,0,0,1-6.228-5.761V39.119h3.581c.467.467,3.114,2.8,8.252,2.958V43.79a5.094,5.094,0,0,1-1.713,4.048A4.724,4.724,0,0,1,55.936,49.707Zm2.647,1.713-2.958,4.048-2.8-3.892a9.581,9.581,0,0,1,2.958-.311A8.5,8.5,0,0,1,58.583,51.419ZM52.2,31.8h7.162a2.129,2.129,0,0,1,2.18,2.18v3.581H49.708V34.137A2.538,2.538,0,0,1,52.2,31.8Z'
            transform='translate(-41.3 -30.4)'
            fill={fill ? fill : "#1b2346"}
          />
        </g>
      </g>
      <g id='Group_8327' data-name='Group 8327' transform='translate(0 8.719)'>
        <g id='Group_8326' data-name='Group 8326'>
          <path
            id='Path_7785'
            data-name='Path 7785'
            d='M22.517,32.374A7,7,0,0,0,26.1,26.146V16.337a3.646,3.646,0,0,0-1.09-2.647,3.415,3.415,0,0,0-2.647-1.09H15.2a3.965,3.965,0,0,0-4.048,3.737v9.809a7.274,7.274,0,0,0,3.737,6.228A14.343,14.343,0,0,0,4.3,46.231a.735.735,0,0,0,.778.778.736.736,0,0,0,.778-.778,12.751,12.751,0,0,1,6.851-11.366,7.815,7.815,0,0,0-.311,1.868,4.017,4.017,0,0,0-2.958,3.892v4.515a2.009,2.009,0,0,0,1.868,1.868.778.778,0,0,0,0-1.557.5.5,0,0,1-.467-.467V40.47a2.333,2.333,0,0,1,2.335-2.491A2.43,2.43,0,0,1,15.51,40.47v4.515a.5.5,0,0,1-.467.467.778.778,0,0,0,0,1.557h0a2.009,2.009,0,0,0,1.868-1.868V40.626a3.885,3.885,0,0,0-2.958-3.892A7.685,7.685,0,0,1,14.42,34.4l3.737,4.982c.156.156.311.311.467.311s.467,0,.467-.156l.156-.156L22.984,34.4a16.982,16.982,0,0,1,1.246,4.827,2.474,2.474,0,0,0-1.557,1.246,3.113,3.113,0,0,0-.311,2.024,2.813,2.813,0,1,0,5.449-1.4,2.94,2.94,0,0,0-2.024-2.024,17.06,17.06,0,0,0-.934-4.048,12.954,12.954,0,0,1,6.539,11.366.778.778,0,1,0,1.557,0A14.619,14.619,0,0,0,22.517,32.374Zm2.491,8.1a1.228,1.228,0,0,1,1.246,1.246,1.339,1.339,0,0,1-1.246,1.246h0a1.228,1.228,0,0,1-1.246-1.246,1.146,1.146,0,0,1,1.246-1.246Zm-.467-19.307V22.72a13.6,13.6,0,0,1-5.761-1.557ZM18.936,31.907h0a5.963,5.963,0,0,1-6.228-5.761V21.319h3.581c.467.467,3.114,2.8,8.252,2.958v1.868a5.094,5.094,0,0,1-1.713,4.048A5.06,5.06,0,0,1,18.936,31.907Zm2.647,1.713-2.958,4.048-2.958-3.892a9.581,9.581,0,0,1,2.958-.311A9.214,9.214,0,0,1,21.583,33.619ZM15.2,14h7.162a2.129,2.129,0,0,1,2.18,2.18v3.425H12.708V16.181A2.413,2.413,0,0,1,15.2,14Z'
            transform='translate(-4.3 -12.6)'
            fill={fill ? fill : "#305e9d"}
          />
        </g>
      </g>
      <path
        id='Path_7786'
        data-name='Path 7786'
        d='M76.2,30.51H70.593V23.66A16.679,16.679,0,0,0,53.933,7H24.506a14.583,14.583,0,0,0-5.761,1.09A2.086,2.086,0,0,0,17.5,10.114a2.065,2.065,0,0,0,.934,1.557,2.142,2.142,0,0,0,1.713.156,13.508,13.508,0,0,1,4.36-.778H53.933A12.825,12.825,0,0,1,66.7,23.815v6.851H61.1l7.474,9.5Z'
        transform='translate(3.052 -7)'
        fill={fill ? fill : "#1b2346"}
      />
      <path
        id='Path_7787'
        data-name='Path 7787'
        d='M31.009,69.864H60.436a23.228,23.228,0,0,0,3.27-.311,1.869,1.869,0,0,0,1.4-1.868v-.467a1.922,1.922,0,0,0-.623-1.246,1.81,1.81,0,0,0-1.557-.311,11.784,11.784,0,0,1-2.335.156H31.009A12.825,12.825,0,0,1,18.242,53.048V46.2h5.605l-7.474-9.5L8.9,46.2h5.605v6.851A16.484,16.484,0,0,0,31.009,69.864Z'
        transform='translate(-1.738 9.542)'
        fill={fill ? fill : "#1b2346"}
      />
    </SvgIcon>
  );
};
