import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Next2Icon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.501' height='20.501' viewBox='0 0 20.501 20.501' {...props}>
      <path
        id='icons8-next'
        d='M6.441,4A2.448,2.448,0,0,0,4,6.441V22.06A2.448,2.448,0,0,0,6.441,24.5H22.06A2.448,2.448,0,0,0,24.5,22.06V6.441A2.448,2.448,0,0,0,22.06,4Zm0,.976H22.06a1.457,1.457,0,0,1,1.464,1.464V22.06a1.457,1.457,0,0,1-1.464,1.464H6.441A1.457,1.457,0,0,1,4.976,22.06V6.441A1.457,1.457,0,0,1,6.441,4.976Zm4.881,3.987V19.538l8.225-5.287Zm.976,1.788,5.443,3.5-5.443,3.5Z'
        transform='translate(-4 -4)'
        fill={props.fill ? props.fill : palette.yellow[1100]}
      />
    </SvgIcon>
  );
};
export default Next2Icon;
