import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DoctorPatientIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8907'
      data-name='Group 8907'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "99.818"}
      height={height ? height : "89.618"}
      viewBox={viewBox ? viewBox : "0 0 99.818 89.618"}
      {...props}
    >
      <g id='Group_8323' data-name='Group 8323' transform='translate(0 0)'>
        <path
          id='Path_7780'
          data-name='Path 7780'
          d='M63.327,54.808A18.362,18.362,0,0,0,72.6,38.429V12.934a9.331,9.331,0,0,0-2.781-6.8,10.143,10.143,0,0,0-6.8-2.936H44.476c-5.717,0-10.507,4.481-10.507,9.58V38.429a18.753,18.753,0,0,0,9.889,16.378A37.733,37.733,0,0,0,16.2,90.964a1.879,1.879,0,0,0,1.854,1.854,1.994,1.994,0,0,0,1.854-1.854A33.485,33.485,0,0,1,37.677,61.3a44.311,44.311,0,0,0-.773,4.79,10.656,10.656,0,0,0-7.726,10.2V87.874a5.317,5.317,0,0,0,4.944,4.944,1.879,1.879,0,0,0,1.854-1.854,1.785,1.785,0,0,0-1.854-1.854,1.677,1.677,0,0,1-1.236-1.236V76.285c0-3.554,2.627-6.49,5.872-6.49,3.09,0,5.872,2.936,5.872,6.49V87.874a1.677,1.677,0,0,1-1.236,1.236,1.879,1.879,0,0,0-1.854,1.854,1.916,1.916,0,0,0,2.009,1.854h0a5.317,5.317,0,0,0,4.944-4.944V76.285c0-4.944-3.245-9.271-7.726-10.2a24.027,24.027,0,0,1,1.391-6.181l9.58,12.979a2.123,2.123,0,0,0,1.236.773,2.077,2.077,0,0,0,1.391-.309l.464-.464,9.58-13.134c2.163,4.017,2.936,9.734,3.4,12.361a6.947,6.947,0,0,0-4.017,3.245,7.375,7.375,0,0,0-.773,5.408,7.251,7.251,0,0,0,14.061-3.554,7.01,7.01,0,0,0-5.253-5.253,60.713,60.713,0,0,0-2.318-10.507A33.643,33.643,0,0,1,86.658,90.964a1.879,1.879,0,0,0,1.854,1.854,1.994,1.994,0,0,0,1.854-1.854A37.183,37.183,0,0,0,63.327,54.808Zm6.644,20.859a3.4,3.4,0,1,1,0,6.8h0a3.429,3.429,0,0,1-3.4-3.4,3.536,3.536,0,0,1,3.4-3.4v0ZM68.735,25.759v4.017a33.173,33.173,0,0,1-14.988-4.017ZM54.056,53.572h-.309c-8.807,0-15.76-6.8-15.76-15.142V25.759h9.425c1.391,1.391,8.035,7.262,21.477,7.88v4.79a14.109,14.109,0,0,1-4.326,10.507A15.015,15.015,0,0,1,54.056,53.572Zm6.953,4.635L53.438,68.559l-7.571-10.2a33,33,0,0,1,7.726-.927A23.775,23.775,0,0,1,61.009,58.207ZM44.476,7.217H63.018a5.7,5.7,0,0,1,5.717,5.717V21.9h-30.9V12.934C37.832,9.844,40.922,7.217,44.476,7.217Z'
          transform='translate(9.452 -3.2)'
          fill={palette.common.white}
        />
        <path
          id='Path_7781'
          data-name='Path 7781'
          d='M26.023,49.792a22.565,22.565,0,0,1,12.516,3.863,1.9,1.9,0,0,0,2.627-.464,2.1,2.1,0,0,0-.464-2.627,26.5,26.5,0,0,0-6.644-3.245,13.779,13.779,0,0,0,5.872-11.28V18.117A7.418,7.418,0,0,0,32.512,10.7H19.379a7.418,7.418,0,0,0-7.417,7.417V35.886a13.779,13.779,0,0,0,5.872,11.28A26.058,26.058,0,0,0-.4,72.2a1.879,1.879,0,0,0,1.854,1.854,2.023,2.023,0,0,0,1.854-2.009,22.862,22.862,0,0,1,22.714-22.25ZM15.979,26.924H36.066v8.962a10.063,10.063,0,0,1-9.734,10.043h-.155A10.023,10.023,0,0,1,16.134,35.886V26.924Zm3.554-12.361H32.667a3.429,3.429,0,0,1,3.4,3.4v4.944H15.979V17.962A3.681,3.681,0,0,1,19.533,14.563Z'
          transform='translate(0.402 0.889)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
