import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PasswordIcon2: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : "15"}
      height={props.height ? props.height : "17.045"}
      viewBox={props.viewBox ? props.viewBox : "0 0 15 17.045"}
      {...props}
    >
      <path
        id='icons8-password'
        d='M11.5,2A4.783,4.783,0,0,0,6.727,6.773V8.136H5.364A1.363,1.363,0,0,0,4,9.5v8.182a1.363,1.363,0,0,0,1.364,1.364H17.636A1.363,1.363,0,0,0,19,17.682V9.5a1.363,1.363,0,0,0-1.364-1.364H16.273V6.773a4.757,4.757,0,0,0-4.53-4.723A.682.682,0,0,0,11.5,2Zm0,1.364a3.4,3.4,0,0,1,3.409,3.409V8.136H8.091V6.773A3.4,3.4,0,0,1,11.5,3.364Z'
        transform='translate(-4 -2)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
