import { PageLayout } from "./PageLayout";
import { PageHeader } from "../PageHeader";
import { PageLayoutProps } from "./type";
import { AppBarFooter } from "../PageFooter/AppBarFooter/AppBarFooter";
import { MainPageFooterLg } from "../PageFooter/MainPageFooterLg/MainPageFooterLg";

export const MainPageWithAppBarLayout = (props: PageLayoutProps) => {
  const { containerBackground, containerBreakpoint, withBlob = false, withLgFooter = false, children } = props;
  return (
    <PageLayout
      containerBackground={containerBackground}
      displayBlob={withBlob}
      header={<PageHeader hasBlob={withBlob} />}
      footer={<AppBarFooter />}
      containerBreakpoint={containerBreakpoint}
    >
      {children}
      {withLgFooter && <MainPageFooterLg />}
    </PageLayout>
  );
};
