import { makeStyles } from "@toolkit/ui";
export const useStyles = makeStyles<{ gap: number; cardWidth: number }>()((_, { cardWidth, gap }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  scrollArea: {
    maxWidth: `${cardWidth + gap}px`,
    width: "100%",
    height: "100%",
    display: "flex",
    overflowX: "auto",
    padding: `${gap / 2}px`,
    gap: `${gap}px`,
    scrollbarWidth: "none",
  },
  button: {
    width: "25px",
    "& svg": {
      width: "18px",
      height: "18px",
    },
  },
}));
