import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SmokingIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      viewBox={viewBox ? viewBox : "0 0 22 22"}
      {...props}
    >
      <path
        id='icons8-smoking'
        d='M18-.006a.9.9,0,0,0-.406.1A4.191,4.191,0,0,0,15.3,3.6a3.03,3.03,0,0,0,1.167,2.294A10.184,10.184,0,0,0,18.5,7.1a9.518,9.518,0,0,1,1.682.983A1.076,1.076,0,0,1,20.7,9a.9.9,0,1,0,1.8,0,3.03,3.03,0,0,0-1.167-2.294A10.184,10.184,0,0,0,19.3,5.494a9.518,9.518,0,0,1-1.682-.983A1.076,1.076,0,0,1,17.1,3.6,2.478,2.478,0,0,1,18.4,1.7.9.9,0,0,0,18-.006ZM20.7,1.8a.9.9,0,1,0,0,1.8,2.438,2.438,0,0,1,1.125.478A1.6,1.6,0,0,1,22.5,5.4a.9.9,0,0,0,.9.9,2.785,2.785,0,0,1,1.1.483A1.6,1.6,0,0,1,25.2,8.1a.9.9,0,1,0,1.8,0,3.5,3.5,0,0,0-1.391-2.733,3.569,3.569,0,0,0-1.462-.573A3.6,3.6,0,0,0,20.7,1.8ZM.9,11.7a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9H26.1a.9.9,0,0,0,.9-.9V12.6a.9.9,0,0,0-.9-.9ZM9,13.5H21.6v1.8H9Zm14.4,0h1.8v1.8H23.4Z'
        transform='translate(0 0.006)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
