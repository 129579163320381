import { FC } from "react";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { getNotificationTextStyle } from "../../utils";
import { useTranslation } from "../../../../i18n/i18n.config";
import { Typography, useTheme } from "@toolkit/ui";
import { SuccessNotificationIcon } from "@/components";

type PrescriptionNotificationProps = {
  status: string;
  creationDate: string;
  isSeen: boolean;
};

export const PrescriptionNotification: FC<PrescriptionNotificationProps> = ({ status, creationDate, isSeen }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { color } = getNotificationTextStyle(isSeen, theme);

  const title = status === "Waiting ERX Hub Approval" ? t("Order Payment") : `${t("Order")} ${t(status)}`;

  return (
    <NotificationCard
      title={title}
      date={creationDate}
      icon={<SuccessNotificationIcon />}
      iconColor={theme.palette.success.main}
      isSeen={isSeen}
    >
      <Typography style={{ color }}>{title}</Typography>
    </NotificationCard>
  );
};
