import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const MyPrescription: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='100' height='95.652' viewBox='0 0 100 95.652' {...props}>
      <path
        id='icons8-pills_5_'
        d='M25.913,3A24,24,0,0,0,2,27.006v0c0,1.94,0,46.115,0,47.627v0A23.906,23.906,0,0,0,45.61,88.284a32.615,32.615,0,1,0,4.216-48.268c0-4.613,0-11.512,0-13.005v0A24,24,0,0,0,25.913,3Zm0,4.348A19.581,19.581,0,0,1,45.478,27c0,1.986,0,14.85,0,16.945a33.125,33.125,0,0,0-3.681,4.8,2.174,2.174,0,0,0-.667-.1H6.348c0-10.32,0-20.706,0-21.65A19.579,19.579,0,0,1,25.913,7.348ZM69.391,37.783A28.261,28.261,0,1,1,41.13,66.043,28.228,28.228,0,0,1,69.391,37.783Zm15.328,10.7a2.174,2.174,0,0,0-1.495.658L52.484,79.877a2.174,2.174,0,1,0,3.074,3.074L86.3,52.21a2.174,2.174,0,0,0-1.579-3.732ZM6.348,53H39.559a31.986,31.986,0,0,0-1.512,21.943,2.17,2.17,0,0,0,1.439,4.076,32.557,32.557,0,0,0,3.21,5.7,19.812,19.812,0,0,1-2.59,3.392,2.175,2.175,0,0,0-3.146,2.709,19.344,19.344,0,0,1-22.113.03A2.169,2.169,0,0,0,11.7,88.127,19.607,19.607,0,0,1,6.348,74.646v0c0-.075,0-1.848,0-2.076a2.174,2.174,0,0,0,0-4.348c0-2.11,0-5.044,0-8.7a2.174,2.174,0,0,0,0-4.348C6.348,54.164,6.348,54.035,6.348,53Zm13.043,2.174a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,19.391,55.174Zm13.043,0a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,32.435,55.174ZM12.87,61.7a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,12.87,61.7Zm13.043,0a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,25.913,61.7Zm-6.522,6.522a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,19.391,68.217Zm13.043,0a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,32.435,68.217ZM12.87,74.739a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,12.87,74.739Zm13.043,0a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,25.913,74.739Zm-6.522,6.522a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,19.391,81.261Zm13.043,0a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,32.435,81.261Zm-6.522,6.522a2.174,2.174,0,1,0,2.174,2.174A2.174,2.174,0,0,0,25.913,87.783Z'
        transform='translate(-2 -3)'
        fill={palette.primary[1200]}
      />
    </SvgIcon>
  );
};
