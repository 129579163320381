/* eslint-disable max-lines */
import React from "react";

export const ReferralIDIcon = () => {
  return (
    <svg
      id='Group_8376'
      data-name='Group 8376'
      xmlns='http://www.w3.org/2000/svg'
      width='186.104'
      height='211.962'
      viewBox='0 0 186.104 211.962'
    >
      <defs>
        <clipPath id='clipPath'>
          <rect id='Rectangle_5253' data-name='Rectangle 5253' width='186.104' height='211.962' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_8376-2' data-name='Group 8376' clipPath='url(#clipPath)'>
        <path
          id='Path_11176'
          data-name='Path 11176'
          d='M192.207,109.857v13.094a7.482,7.482,0,0,1-7.482,7.482H60.142a7.482,7.482,0,0,0-7.482,7.482v37.412a7.482,7.482,0,0,0,7.482,7.482H203.8a7.482,7.482,0,0,1,7.482,7.482v72.579H53.034v-16.96'
          transform='translate(-37.775 -78.805)'
          fill='none'
          stroke='#d9dbe1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='5'
        />
        <path
          id='Path_11177'
          data-name='Path 11177'
          d='M493.738,563.993v0a16.56,16.56,0,0,1-24.22-2.215l-2.776-3.614-23.409-30.491-6.857-8.93,18.163-16.379,9.035,8.556,26.989,25.56,3.371,3.195a16.552,16.552,0,0,1-.3,24.314'
          transform='translate(-313.103 -360.371)'
          fill='#dca86c'
        />
        <path
          id='Path_11178'
          data-name='Path 11178'
          d='M463.674,510.924l-.138.172a64.415,64.415,0,0,1-20.2,16.581l-6.857-8.93,18.163-16.379Z'
          transform='translate(-313.103 -360.371)'
          fill='#e18031'
        />
        <path
          id='Path_11179'
          data-name='Path 11179'
          d='M249.772,218.142A64.189,64.189,0,0,1,199.509,280.8a65.125,65.125,0,0,1-13.906,1.511,64.169,64.169,0,1,1,64.169-64.169'
          transform='translate(-87.11 -110.452)'
          fill='#e6a561'
        />
        <path
          id='Path_11180'
          data-name='Path 11180'
          d='M377.6,576.076a7.023,7.023,0,0,1-.815,3.18,62.992,62.992,0,0,1-6.977,1.137,23.24,23.24,0,0,1-1.246-4.441l9.974-5.922,1.122,3.554Z'
          transform='translate(-264.386 -408.908)'
          fill='#e18031'
        />
        <path
          id='Path_11181'
          data-name='Path 11181'
          d='M273.68,257.6a48.623,48.623,0,1,1-48.623-48.623A48.623,48.623,0,0,1,273.68,257.6'
          transform='translate(-126.564 -149.907)'
          fill='#e18031'
        />
        <path
          id='Path_11182'
          data-name='Path 11182'
          d='M278.34,265.286a45.594,45.594,0,1,1-45.594-45.594,45.594,45.594,0,0,1,45.594,45.594'
          transform='translate(-134.253 -157.595)'
          fill='#fff'
        />
        <path
          id='Path_11183'
          data-name='Path 11183'
          d='M225.76,529.62c.007.209.011.419.019.629h0Z'
          transform='translate(-161.948 -379.92)'
          fill='#a53a30'
        />
        <path
          id='Path_11184'
          data-name='Path 11184'
          d='M289.515,272.815c-.153,18.044-11.377,27.834-14.127,25.511a45.537,45.537,0,0,1-14.557,8.967H228.47a45.651,45.651,0,0,1-18.8-13.4q.022,1.117.052,2.267l-.056-2.271a45.332,45.332,0,0,1-5.919-9.08c.55-15.021,1.235-32.8,1.534-37.191a21.642,21.642,0,0,1,3.057-9.581c3.67-6.233,9.921-10.786,14.987-13.693a61.333,61.333,0,0,1,5.608-2.843,0,0,0,0,0,0,0l0,0c.266-.116.512-.221.737-.318,1.066-.453,1.7-.681,1.7-.681l2.555-.168,15.552-1.014,7.157-.464.55-.038c15.245.935,29.106,13.854,30.678,23.757.576,3.625.973,7.662,1.235,11.9.367,5.915.467,12.219.411,18.339'
          transform='translate(-146.158 -156.966)'
          fill='#fbe6d9'
        />
        <path
          id='Path_11185'
          data-name='Path 11185'
          d='M193.617,329.913a44.974,44.974,0,0,1-3.992-12.166c1.706-13.588,4.022-26.8,7.03-33.4a14.5,14.5,0,0,1,1.519-2.66s0,.146.011.527c.007.221.015.528.022.928.011.333.019.733.03,1.212.008.191.011.393.015.606q.005.219.011.449t.011.483l.019.793c0,.094.007.187.007.284,0,.239.011.49.019.748l.019.816c.011.531.026,1.1.041,1.706l.015.606c0,.123,0,.25.008.378l.019.744.019.8.03,1.261c.007.288.015.587.022.887l.045,1.9c0,.165.008.329.011.5l.037,1.56.026,1.1.041,1.721.1,4.415c.008.221.011.449.015.677l.034,1.388.034,1.436c.008.243.011.49.019.737l.053,2.234c.015.591.03,1.19.041,1.8l.038,1.482c.007.277.011.557.019.838l.041,1.717.041,1.7q.1,4.074.206,8.717.028,1.15.056,2.338l.056,2.409q.062,2.447.12,5.047l.007.371a45.319,45.319,0,0,1-5.919-9.08'
          transform='translate(-136.027 -202.065)'
          fill='#1e2342'
        />
        <path
          id='Path_11186'
          data-name='Path 11186'
          d='M314.928,273.741s-4.739,21.948-4.614,39.781'
          transform='translate(-222.6 -196.367)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_11187'
          data-name='Path 11187'
          d='M328.651,196.937l-14.841,1.687-6.663-1.1.284-13.887,0-.124.2-9.783,0-.116,4.991.988,5.253.535,8.964.909.7.022.037.726Z'
          transform='translate(-220.33 -124.539)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11188'
          data-name='Path 11188'
          d='M328.861,176.36s-.183.277-.55.726c-1.856,2.275-8.455,9-20.158,6.843l0-.124.2-9.783,4.677.872,5.571.535,9.507.909Z'
          transform='translate(-221.052 -124.834)'
          fill='#26120c'
        />
        <path
          id='Path_11189'
          data-name='Path 11189'
          d='M257.688,42.074s-.86-10.087,2.1-15.246,7.9-12.412,23.5-11.561c15.765.86,16.529,16.774,15.765,25.182Z'
          transform='translate(-184.768 -10.903)'
          fill='#4a3226'
        />
        <path
          id='Path_11190'
          data-name='Path 11190'
          d='M266.712,44.61s-2.213,30,17.706,30.192,20.64-17.136,20.349-29.172c-.246-10.191-4.8-21.274-18.812-20.892-8.852.241-18.413,4.586-19.243,19.873'
          transform='translate(-191.272 -17.738)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11191'
          data-name='Path 11191'
          d='M396.5,94.873s1.784-5.35,4.841-3.312,1.274,14.4-5.223,10.064Z'
          transform='translate(-284.155 -65.348)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11192'
          data-name='Path 11192'
          d='M252.327,98.391s-1.784-5.35-4.841-3.312-1.274,14.4,5.223,10.064Z'
          transform='translate(-176.491 -67.872)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11193'
          data-name='Path 11193'
          d='M265.37,46.207c.08.581,1.047,1.071.892-.892-.177-2.237.573-10.573,1.847-13.44s7.8,1.382,16.591.872,12.225-7.657,15.1-.446c2.817,7.077,2.611,11.72,2.611,11.72s-.123,1.362.382,1.338c.928-.044.764-2.971.764-2.971s.064-10.956-3.057-15.1-9.745-8.026-18.408-6.879-18.191,6.314-17.045,23c0,0,.2,1.875.326,2.792'
          transform='translate(-190.06 -14.497)'
          fill='#4a3226'
        />
        <path
          id='Path_11194'
          data-name='Path 11194'
          d='M276.927,25.569s-17.261,9.109-28.79,3.694-8.408-17.07,5.287-14.65,29.3,5.733,23.5,10.956'
          transform='translate(-172.923 -10.249)'
          fill='#4e3124'
        />
        <path
          id='Path_11195'
          data-name='Path 11195'
          d='M405.064,104.121s-.05-4.186,1.643-4.212'
          transform='translate(-290.57 -71.669)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_11196'
          data-name='Path 11196'
          d='M255.43,105.435s-.928-5.272-2.7-3.247'
          transform='translate(-181.295 -72.972)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_11197'
          data-name='Path 11197'
          d='M343.962,201.5s.624,12.814-14.341,17.116A33.972,33.972,0,0,1,334,228.559a.976.976,0,0,0,1.477.627c4.7-2.951,19-15.376,8.489-27.691'
          transform='translate(-236.452 -144.541)'
          fill='#f1a253'
        />
        <path
          id='Path_11198'
          data-name='Path 11198'
          d='M300.924,229.06s-6.033-3.079-6.109-13c0,0-9.947,1.962-2.323,21.592a.618.618,0,0,0,1.074.043c1.116-2.066,3.2-6.356,7.358-8.636'
          transform='translate(-207.755 -154.99)'
          fill='#f1a253'
        />
        <path
          id='Path_11199'
          data-name='Path 11199'
          d='M246.533,316.04a44.08,44.08,0,0,1-6.5-1.919,45.651,45.651,0,0,1-18.8-13.4c0-.127-.007-.251-.011-.374q-.061-2.6-.12-5.047-.028-1.223-.056-2.409t-.056-2.338q-.112-4.646-.206-8.717c-.015-.576-.026-1.145-.041-1.7-.015-.58-.026-1.152-.041-1.717-.007-.281-.011-.561-.019-.838-.011-.5-.022-1-.037-1.481-.011-.61-.026-1.209-.041-1.8-.019-.763-.034-1.508-.052-2.234-.007-.247-.011-.494-.019-.737q-.017-.73-.034-1.437c-.011-.471-.023-.935-.034-1.388,0-.228-.008-.456-.015-.677q-.056-2.346-.1-4.414c-.015-.591-.026-1.164-.041-1.721-.007-.374-.015-.737-.026-1.1-.011-.535-.023-1.055-.038-1.56,0-.168-.007-.333-.011-.5-.015-.662-.03-1.294-.045-1.9-.008-.3-.015-.6-.022-.887-.008-.438-.019-.857-.03-1.261,0-.273-.011-.539-.019-.8s-.011-.5-.019-.745c0-.127,0-.254-.007-.378,0-.206-.011-.408-.015-.606-.015-.61-.03-1.175-.041-1.706,0-.284-.011-.554-.019-.815s-.015-.509-.019-.748c0-.1,0-.191-.008-.284,0-.277-.011-.542-.019-.793q-.006-.253-.011-.483t-.011-.449c0-.213-.008-.415-.015-.606-.011-.479-.019-.879-.03-1.212-.007-.4-.015-.707-.022-.928-.008-.382-.011-.527-.011-.527s1.478-1.706,3.969-4.029a66.575,66.575,0,0,1,8.2-6.566s0,0,.008,0a42.555,42.555,0,0,1,8.448-4.482c-.03.037-.157.194-.337.468-.958,1.44-3.457,6.181-1.616,14.239,2.245,9.821,6.873,22.735,7.2,27.591.344,5.073.636,30.348.767,43.491.007.673.011,1.317.019,1.919'
          transform='translate(-157.723 -163.793)'
          fill='#1f2340'
        />
        <path
          id='Path_11200'
          data-name='Path 11200'
          d='M367.7,274.619c-6.831,7.12-14.849,14.363-18.085,17.318a45.31,45.31,0,0,1-4.28,3.461c-.243.176-.494.352-.745.52-.3.2-.591.4-.89.595q-1.122.735-2.293,1.4c-.135.079-.273.157-.412.228-.258.15-.52.292-.785.43-.12.067-.243.131-.367.191-.008.008-.011.011-.019.008-.161.09-.322.172-.486.25a.581.581,0,0,1-.082.041c-.139.075-.281.142-.423.209a.135.135,0,0,1-.041.019c-.224.112-.445.221-.673.322-.4.191-.8.37-1.212.546-.2.086-.4.172-.6.251a.1.1,0,0,1-.041.019c-.116.052-.232.1-.352.146-.172.071-.344.139-.52.2-.2.082-.4.161-.61.232-.093.037-.187.075-.28.1l-.337.123c-.056.023-.112.041-.168.06-.086.03-.168.06-.254.086-.041.015-.079.03-.12.041-.262.094-.524.179-.789.262-.09.03-.18.06-.269.086-.187.06-.378.12-.569.176-.071.022-.142.045-.217.064-.135.041-.269.079-.408.116-.209.064-.419.12-.628.176-.116.034-.236.064-.352.094-.09.023-.18.049-.266.067q-.343.09-.685.168c-.071.019-.138.037-.21.052l-.052.011c-.138.034-.277.064-.419.094-.09.019-.176.041-.266.056-.3.063-.591.127-.89.183-.041.008-.086.019-.127.022-.314.064-.629.12-.947.172-.052.011-.1.019-.154.026-.127.022-.254.041-.382.06s-.273.045-.412.064l-.165.022c-.2.03-.4.06-.6.082-.071.011-.142.019-.213.026-.094.015-.191.026-.284.037-.2.023-.4.045-.595.064-.127.015-.254.03-.382.041a.8.8,0,0,1-.1.007q-.241.028-.482.045c-.075.008-.15.011-.224.015-.015,0-.03,0-.041.008s-.041,0-.06,0a.927.927,0,0,1-.1.007c-.146.011-.292.023-.438.03-.3.023-.6.038-.9.053s-.584.022-.875.03c-.049,0-.094,0-.142,0-.367.011-.737.015-1.107.015-.393,0-.786,0-1.179-.015-.333-.008-.67-.023-1-.038-.1,0-.2-.007-.3-.015-.307-.015-.614-.034-.92-.06-1.119-.079-2.23-.2-3.326-.367-.2-.026-.4-.06-.6-.093-.4-.064-.793-.135-1.186-.21s-.785-.153-1.175-.239c0,.008,0,.008,0,0-.3-.064-.595-.127-.89-.2q-.539-.123-1.077-.269V263.242c0-11.347,18.242-29.656,23.259-37.255,2.552-3.865,2.065-8.294,1.1-11.523-.221-.737-.468-1.41-.711-2a18.76,18.76,0,0,0-1.242-2.555c3.8.232,7.4,3.326,7.4,3.326s22.593,6.031,25.814,17.9c3.18,11.714,4.437,28.5,4.856,43.488'
          transform='translate(-220.388 -150.576)'
          fill='#1e2342'
        />
        <path
          id='Path_11201'
          data-name='Path 11201'
          d='M411,359.574l-9.353,31.738s10.118-10.251,12.346-14.279C415.614,374.1,411,359.574,411,359.574'
          transform='translate(-288.119 -257.939)'
          fill='#f4e9dc'
        />
        <path
          id='Path_11202'
          data-name='Path 11202'
          d='M308.93,317.388c-.172-7.786-.267-21.5.428-39.814.093-2.437,16.274-16.274,29.368-22.167l-3.928-8.044,7.389-.374s.031-8.449-2.962-14.06'
          transform='translate(-221.521 -167.091)'
          fill='none'
          stroke='#373f56'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_11203'
          data-name='Path 11203'
          d='M273.1,282.322a10.091,10.091,0,0,0-2.619-7.108c-1.923-2.184-10.1-11.161-12.221-20.14l5.238-4.988-3.617-3.367a18.527,18.527,0,0,1,3.929-7.17'
          transform='translate(-185.26 -171.839)'
          fill='none'
          stroke='#373f56'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_11204'
          data-name='Path 11204'
          d='M328.073,299.913a.9.9,0,1,1-.9-.9.9.9,0,0,1,.9.9'
          transform='translate(-234.05 -214.495)'
          fill='#fff'
        />
        <path
          id='Path_11205'
          data-name='Path 11205'
          d='M322.611,337.05a.9.9,0,1,1-.9-.9.9.9,0,0,1,.9.9'
          transform='translate(-230.131 -241.135)'
          fill='#fff'
        />
        <path
          id='Path_11206'
          data-name='Path 11206'
          d='M352.715,100.2a5.871,5.871,0,1,1-5.872-5.872A5.872,5.872,0,0,1,352.715,100.2Z'
          transform='translate(-244.595 -67.669)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_11207'
          data-name='Path 11207'
          d='M289.028,101.964a5.871,5.871,0,1,1-5.872-5.872A5.872,5.872,0,0,1,289.028,101.964Z'
          transform='translate(-198.909 -68.931)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <line
          id='Line_1433'
          data-name='Line 1433'
          x2='4.671'
          y2='3.827'
          transform='translate(74.131 26.78)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <line
          id='Line_1434'
          data-name='Line 1434'
          y1='3.838'
          x2='6.14'
          transform='translate(107.491 25.88)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_11208'
          data-name='Path 11208'
          d='M318.133,108.827a9.991,9.991,0,0,1,6.637-.464'
          transform='translate(-228.211 -77.485)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <line
          id='Line_1435'
          data-name='Line 1435'
          x2='0.811'
          y2='42.525'
          transform='translate(62.336 79.931)'
          fill='none'
          stroke='#373f56'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_11209'
          data-name='Path 11209'
          d='M359.2,110.007A1.507,1.507,0,1,1,357.7,108.5a1.507,1.507,0,0,1,1.507,1.507'
          transform='translate(-255.51 -77.832)'
          fill='#26120c'
        />
        <path
          id='Path_11210'
          data-name='Path 11210'
          d='M295.416,112.646a1.507,1.507,0,1,1-1.507-1.507,1.507,1.507,0,0,1,1.507,1.507'
          transform='translate(-209.754 -79.725)'
          fill='#26120c'
        />
        <path
          id='Path_11211'
          data-name='Path 11211'
          d='M324.7,133.746a1.908,1.908,0,0,1-1.24.26c-.911-.076-1.862-.6-1.957-1.367-.177-1.439,1.75-5.16,1.75-5.16.481-.89.785-1.139,1.157-.072.112.322.285.879.448,1.533.434,1.725.8,4.141-.159,4.806'
          transform='translate(-230.623 -90.891)'
          fill='#de5042'
        />
        <path
          id='Path_11212'
          data-name='Path 11212'
          d='M241.255,550.624l-29.916,26.761a1.131,1.131,0,0,1-1.6-.089L175,538.456a1.131,1.131,0,0,1,.089-1.6l29.917-26.76a1.131,1.131,0,0,1,1.6.089l34.742,38.84a1.131,1.131,0,0,1-.089,1.6'
          transform='translate(-125.329 -365.71)'
          fill='#f6e7d1'
        />
        <rect
          id='Rectangle_5250'
          data-name='Rectangle 5250'
          width='37.911'
          height='49.882'
          transform='matrix(0.745, -0.667, 0.667, 0.745, 52.087, 172.08)'
          fill='#fff'
        />
        <path
          id='Path_11213'
          data-name='Path 11213'
          d='M217.463,536.837,205.431,547.6a.748.748,0,0,1-1.057-.059l-2.385-2.666a.748.748,0,0,1,.059-1.057l12.033-10.763a.748.748,0,0,1,1.057.059l2.385,2.666a.748.748,0,0,1-.059,1.057'
          transform='translate(-144.759 -382.248)'
          fill='#26120c'
        />
        <path
          id='Path_11214'
          data-name='Path 11214'
          d='M218.127,541.746a3.585,3.585,0,1,1-5.063-.282,3.585,3.585,0,0,1,5.063.282'
          transform='translate(-151.983 -387.762)'
          fill='#26120c'
        />
        <rect
          id='Rectangle_5251'
          data-name='Rectangle 5251'
          width='2.505'
          height='7.202'
          transform='matrix(0.745, -0.667, 0.667, 0.745, 67.885, 163.156)'
          fill='#1f2340'
        />
        <rect
          id='Rectangle_5252'
          data-name='Rectangle 5252'
          width='7.202'
          height='2.505'
          transform='matrix(0.745, -0.667, 0.667, 0.745, 67.7, 166.472)'
          fill='#1f2340'
        />
        <line
          id='Line_1436'
          data-name='Line 1436'
          y1='8.771'
          x2='9.806'
          transform='translate(66.051 171.733)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1437'
          data-name='Line 1437'
          y1='18.956'
          x2='21.192'
          transform='translate(68.545 164.336)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1438'
          data-name='Line 1438'
          y1='16.192'
          x2='18.102'
          transform='translate(71.039 169.889)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1439'
          data-name='Line 1439'
          y1='18.956'
          x2='21.192'
          transform='translate(73.533 169.913)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1440'
          data-name='Line 1440'
          y1='13.718'
          x2='15.336'
          transform='translate(78.522 180.728)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1441'
          data-name='Line 1441'
          y1='18.956'
          x2='21.192'
          transform='translate(81.016 178.278)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1442'
          data-name='Line 1442'
          y1='16.545'
          x2='18.496'
          transform='translate(83.51 183.478)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <line
          id='Line_1443'
          data-name='Line 1443'
          y1='18.956'
          x2='21.192'
          transform='translate(86.005 183.855)'
          fill='none'
          stroke='#26120c'
          strokeWidth='2'
        />
        <path
          id='Path_11215'
          data-name='Path 11215'
          d='M329.7,139.42a1.908,1.908,0,0,1-1.24.26l1.392-5.066h.007c.434,1.725.8,4.141-.159,4.806'
          transform='translate(-235.618 -96.565)'
          fill='#26120c'
        />
        <path
          id='Path_11216'
          data-name='Path 11216'
          d='M336.873,556.045l6.235-6.547s-14.591-9.228-14.778-9.041-10.787,10.538-10.787,10.538,3.928,6.8,8.355,8.106,10.974-3.055,10.974-3.055'
          transform='translate(-227.789 -387.692)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11217'
          data-name='Path 11217'
          d='M320.372,165.477a9.171,9.171,0,0,0,7.594-1.326'
          transform='translate(-229.817 -117.753)'
          fill='none'
          stroke='#26120c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_11218'
          data-name='Path 11218'
          d='M338.624,171.189c.278-.215,1.13-.9,1.6-.784s.316.678-.362.9-1.7.241-1.235-.12'
          transform='translate(-242.825 -122.229)'
          fill='#e58c83'
        />
        <path
          id='Path_11219'
          data-name='Path 11219'
          d='M348.834,84.879s3.675-.652,4.6-.863c1.09-.248,1.867-1.077-.1-1.25-2.2-.194-5.114.222-6.1,1.076-.939.815-.291,1.471,1.593,1.038'
          transform='translate(-248.785 -59.338)'
          fill='#26120c'
        />
        <path
          id='Path_11220'
          data-name='Path 11220'
          d='M289.171,89.464s-3.732,0-4.684-.025c-.732-.018-2.24-.6-.127-1.248a11.823,11.823,0,0,1,6.192-.034c1.149.415.853,1.29-1.382,1.306'
          transform='translate(-203.209 -62.957)'
          fill='#26120c'
        />
        <path
          id='Path_11221'
          data-name='Path 11221'
          d='M303.216,565.113c-1.26.423-8.953,8.329-10.236,9.842s-1.493,2.929-.432,3.852c1.523,1.326,3.041-.151,4.2-1.3s6.617-5.783,6.617-5.783,1.108-7.036-.152-6.613'
          transform='translate(-209.37 -405.368)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11222'
          data-name='Path 11222'
          d='M313.354,587.919l-3.346-.593-7.443,9.5s-2,1.948-.554,3.518,3.093.176,4.4-1.209,8.625-9.769,8.625-9.769Z'
          transform='translate(-216.271 -421.315)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11223'
          data-name='Path 11223'
          d='M332.141,592.832l-3.279-.846-7.51,9.752s-2,1.948-.554,3.518,3.093.176,4.4-1.209,8.52-9.338,8.52-9.338Z'
          transform='translate(-229.747 -424.658)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11224'
          data-name='Path 11224'
          d='M358.986,583.04l-6.1,1.979-6.855,10.343s-1.718,2.2-.076,3.561,3.089-.241,4.2-1.789,4.762-5.754,6.463-7.983c2.619-3.429,2.37-6.111,2.37-6.111'
          transform='translate(-247.656 -418.241)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11225'
          data-name='Path 11225'
          d='M360.355,367.325s36.6-36.36,37.1-45.518c.748-13.718-3.71-30.59-10.349-43.914L367.09,293.249l5.8,25.44-30.8,32.05Z'
          transform='translate(-245.394 -199.345)'
          fill='#1d2344'
        />
        <path
          id='Path_11226'
          data-name='Path 11226'
          d='M368.15,355.6s4.552,16.586,3.118,18.581c-6.352,8.837-29.181,32.3-29.181,32.3l9.852,8.979'
          transform='translate(-245.394 -255.091)'
          fill='none'
          stroke='#ead2b2'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <line
          id='Line_1444'
          data-name='Line 1444'
          x1='4.314'
          y2='4.292'
          transform='translate(89.744 165.922)'
          fill='none'
          stroke='#ef8f87'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_1445'
          data-name='Line 1445'
          x1='5.513'
          y2='6.311'
          transform='translate(93.257 168.051)'
          fill='none'
          stroke='#ef8f87'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_1446'
          data-name='Line 1446'
          x1='3.679'
          y2='4.669'
          transform='translate(98.547 171.471)'
          fill='none'
          stroke='#ef8f87'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_11227'
          data-name='Path 11227'
          d='M323.371,549.82s5.5.5,6.593-.547l-3.776,3.691Z'
          transform='translate(-231.969 -394.018)'
          fill='#f4b6ac'
        />
        <path
          id='Path_11228'
          data-name='Path 11228'
          d='M525.418,17.986c0,8.471-9.612,24.542-15.339,24.542S494.74,26.457,494.74,17.986a15.339,15.339,0,1,1,30.678,0'
          transform='translate(-354.899 -1.899)'
          fill='#26120c'
        />
        <path
          id='Path_11229'
          data-name='Path 11229'
          d='M521.447,15.339c0,8.471-9.612,24.542-15.339,24.542S490.769,23.81,490.769,15.339a15.339,15.339,0,0,1,30.678,0'
          transform='translate(-352.051)'
          fill='#76b161'
        />
        <path
          id='Path_11230'
          data-name='Path 11230'
          d='M533.268,34.848a7.652,7.652,0,1,1-7.652-7.652,7.652,7.652,0,0,1,7.652,7.652'
          transform='translate(-371.559 -19.509)'
          fill='#fff'
        />
        <path
          id='Path_11231'
          data-name='Path 11231'
          d='M32.252,446.311c0,7.885-8.947,22.844-14.278,22.844S3.7,454.2,3.7,446.311a14.278,14.278,0,1,1,28.556,0'
          transform='translate(-2.651 -309.917)'
          fill='#26120c'
        />
        <path
          id='Path_11232'
          data-name='Path 11232'
          d='M28.556,443.847c0,7.886-8.947,22.844-14.278,22.844S0,451.732,0,443.847a14.278,14.278,0,0,1,28.556,0'
          transform='translate(0 -308.149)'
          fill='#76b161'
        />
        <path
          id='Path_11233'
          data-name='Path 11233'
          d='M39.56,462.006a7.122,7.122,0,1,1-7.123-7.123,7.123,7.123,0,0,1,7.123,7.123'
          transform='translate(-18.16 -326.308)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
