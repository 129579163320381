import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const ChatHeaderIcon: FC<SvgIconProps> = props => {
  const { fill, ...rest } = props;
  const theme = useTheme();
  const fillIcon = fill || theme?.palette?.primary?.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18.743' height='17.805' viewBox='0 0 18.743 17.805' {...rest}>
      <g id='icons8-chat_3_' data-name='icons8-chat (3)' transform='translate(-4 -7)'>
        <path
          id='Path_12758'
          data-name='Path 12758'
          d='M30.874,20.937A.937.937,0,1,1,29.937,20,.94.94,0,0,1,30.874,20.937Z'
          transform='translate(-13.286 -6.909)'
          fill={fillIcon}
        />
        <path
          id='Path_12759'
          data-name='Path 12759'
          d='M16.874,20.937A.937.937,0,1,1,15.937,20,.94.94,0,0,1,16.874,20.937Z'
          transform='translate(-5.846 -6.909)'
          fill={fillIcon}
        />
        <path
          id='Path_12760'
          data-name='Path 12760'
          d='M23.874,20.937A.937.937,0,1,1,22.937,20,.94.94,0,0,1,23.874,20.937Z'
          transform='translate(-9.566 -6.909)'
          fill={fillIcon}
        />
        <path
          id='Path_12761'
          data-name='Path 12761'
          d='M19.7,21.057H16.558a.7.7,0,0,1,0-1.406H19.7a1.623,1.623,0,0,0,1.64-1.64V10.046a1.623,1.623,0,0,0-1.64-1.64H7.046a1.623,1.623,0,0,0-1.64,1.64v1.921a.7.7,0,0,1-1.406,0V10.046A3.023,3.023,0,0,1,7.046,7H19.7a3.023,3.023,0,0,1,3.046,3.046v7.966A3.023,3.023,0,0,1,19.7,21.057Z'
          fill={fillIcon}
        />
        <path
          id='Path_12762'
          data-name='Path 12762'
          d='M8.917,32.777a1.169,1.169,0,0,1-1.168-1.171V29.029h-.7A3.023,3.023,0,0,1,4,25.983V22.7a.7.7,0,0,1,1.406,0v3.28a1.623,1.623,0,0,0,1.64,1.64H8.451a.7.7,0,0,1,.7.7v2.811l4.5-3.374a.7.7,0,1,1,.843,1.125L9.623,32.543l-.013.01A1.192,1.192,0,0,1,8.917,32.777Z'
          transform='translate(0 -7.971)'
          fill={fillIcon}
        />
      </g>
    </SvgIcon>
  );
};
