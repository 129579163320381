import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const AvatarUserIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='52.5' height='52.5' viewBox='0 0 52.5 52.5' {...props}>
      <g id='icons8-male-user_1_' data-name='icons8-male-user (1)' transform='translate(-4 -4)'>
        <path
          id='Path_12899'
          data-name='Path 12899'
          d='M50.05,40.743A24.684,24.684,0,0,1,5.5,26.027,26.565,26.565,0,0,1,6.838,18'
          transform='translate(0 4.223)'
          fill='none'
          stroke={fill}
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='3'
        />
        <path
          id='Path_12900'
          data-name='Path 12900'
          d='M12.4,10.985A24.384,24.384,0,0,1,27.919,5.5a24.78,24.78,0,0,1,24.75,24.75,26.525,26.525,0,0,1-.4,4.682'
          transform='translate(2.331)'
          fill='none'
          stroke={fill}
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='3'
        />
        <circle
          id='Ellipse_2890'
          data-name='Ellipse 2890'
          cx='6'
          cy='6'
          r='6'
          transform='translate(24.5 16.5)'
          fill='none'
          stroke={fill}
          stroke-width='3'
        />
        <path
          id='Path_12901'
          data-name='Path 12901'
          d='M38.243,31.246A3.739,3.739,0,0,0,34.5,27.5H19.246A3.739,3.739,0,0,0,15.5,31.246h0c0,3.612,4.549,6.957,11.372,6.957s11.372-3.345,11.372-6.957Z'
          transform='translate(3.378 7.432)'
          fill='none'
          stroke={fill}
          stroke-width='3'
        />
      </g>
    </SvgIcon>
  );
};
