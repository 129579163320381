import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DoctorIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.16' height='21' viewBox='0 0 20.16 21' {...props}>
      <path
        id='icons8-stethoscope_6_'
        data-name='icons8-stethoscope (6)'
        d='M6.36,2a1.68,1.68,0,0,0-1.454.84H4.68A1.693,1.693,0,0,0,3,4.52V9.56A6.732,6.732,0,0,0,7.236,15.8,2.506,2.506,0,0,0,8.88,17.806v.154A5.053,5.053,0,0,0,13.92,23h2.52a5.053,5.053,0,0,0,5.04-5.04V14.454a2.522,2.522,0,1,0-1.68,0V17.96a3.348,3.348,0,0,1-3.36,3.36H13.92a3.348,3.348,0,0,1-3.36-3.36v-.154A2.506,2.506,0,0,0,12.2,15.8,6.732,6.732,0,0,0,16.44,9.56V4.52a1.693,1.693,0,0,0-1.68-1.68h-.228a1.679,1.679,0,1,0,0,1.68h.226V9.56a5.04,5.04,0,1,1-10.08,0V4.52h.228A1.679,1.679,0,1,0,6.36,2Z'
        transform='translate(-3 -2)'
        fill={palette.primary[1200]}
      />
    </SvgIcon>
  );
};
