import { useEffect, useState } from "react";
import { useDoctorAvailableTimeSlotsQuery } from "../../gql/queries";
import { Moment } from "moment/moment";
import { formatQueryTimeSlots, groupTimeSlotsByTimeOfDay } from "../../utils";
import { IFormattedTimeSlot } from "../../types/types";

interface IUseAppointmentScheduler {
  doctorId: string;
  initialSelectedDay: Moment;
  onSubmit: (selectedTimeSlots: IFormattedTimeSlot[]) => void;
}

export const useAppointmentScheduler = ({ doctorId, initialSelectedDay, onSubmit: _onSubmit }: IUseAppointmentScheduler) => {
  const [selectedDay, setSelectedDay] = useState<Moment>(initialSelectedDay);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<IFormattedTimeSlot[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { data, loading } = useDoctorAvailableTimeSlotsQuery({
    variables: {
      doctorId,
      start: selectedDay.startOf("day").toISOString(),
      end: selectedDay.endOf("day").toISOString(),
    },
    context: {
      headers: {
        Authorization: undefined,
      },
    },
  });

  const availableTimeSlots = groupTimeSlotsByTimeOfDay(formatQueryTimeSlots(data?.availableTimeSlots));

  const handleDateChange = (date: Moment) => {
    setSelectedDay(date);
    setError(null);
  };

  const handleTimeSlotSelect = (timeSlot: IFormattedTimeSlot) => {
    setSelectedTimeSlots(prevSelected => {
      const isAlreadySelected = prevSelected.some(slot => slot.startTime.isSame(timeSlot.startTime));

      if (isAlreadySelected) {
        return prevSelected.filter(slot => !slot.startTime.isSame(timeSlot.startTime));
      } else {
        return [...prevSelected, timeSlot];
      }
    });
    setError(null);
  };

  const onSubmit = () => {
    if (selectedTimeSlots.length === 0) {
      setError("Please select at least one time slot.");
    } else {
      _onSubmit(selectedTimeSlots);
    }
  };

  useEffect(() => {
    setSelectedTimeSlots([]);
    setError(null);
  }, [selectedDay]);

  return {
    selectedDay,
    handleDateChange,
    selectedTimeSlots,
    handleTimeSlotSelect,
    availableTimeSlots,
    loading,
    error,
    onSubmit,
  };
};
