import React, { FC } from "react";
import { SvgIconProps } from "../../base/mui";
export const EmptyChartBar: FC<SvgIconProps> = ({ width }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || "97.692"} height='170' viewBox='0 0 102 55'>
      <g id='Group_11752' data-name='Group 11752' transform='translate(-294.154 -540)'>
        <rect
          id='Rectangle_Copy_21'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(388.369 540)'
          fill='rgba(115,136,169,0.35)'
        />
        <rect
          id='Rectangle_Copy_21-2'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(372.668 540)'
          fill='rgba(115,136,169,0.35)'
        />
        <rect
          id='Rectangle_Copy_21-3'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(356.965 540)'
          fill='rgba(115,136,169,0.35)'
        />
        <rect
          id='Rectangle_Copy_21-4'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(341.262 540)'
          fill='rgba(115,136,169,0.35)'
        />
        <rect
          id='Rectangle_Copy_21-5'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(325.559 540)'
          fill='#ced5e1'
        />
        <rect
          id='Rectangle_Copy_21-6'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(309.855 540)'
          fill='rgba(115,136,169,0.35)'
        />
        <rect
          id='Rectangle_Copy_21-7'
          data-name='Rectangle Copy 21'
          width='3.478'
          height='50'
          rx='1.739'
          transform='translate(294.154 540)'
          fill='rgba(115,136,169,0.35)'
        />
      </g>
    </svg>
  );
};
