import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const InfoIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='14.5' height='14.5' viewBox='0 0 14.5 14.5' {...props}>
      <path
        id='icons8-info_1_'
        data-name='icons8-info (1)'
        d='M10.25,3a7.25,7.25,0,1,0,7.25,7.25A7.25,7.25,0,0,0,10.25,3Zm.6,10.875H9.646V9.646h1.208Zm-.6-5.74a.906.906,0,1,1,.906-.906A.907.907,0,0,1,10.25,8.135Z'
        transform='translate(-3 -3)'
        fill={props?.fill || theme.palette.primary[1400]}
      />
    </SvgIcon>
  );
};
