import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";

export const AddImageIcon: FC<IconProps> = ({ width = 47.961, height = 42.822, viewBox = "0 0 47.961 42.822", ...props }) => {
  return (
    <SvgIcon color={"inherit"} width={width} height={height} viewBox={viewBox} {...props}>
      <path
        d={
          "M5.426,5A3.425,3.425,0,0,0,2,8.426V35.832a3.425,3.425,0,0,0,3.426,3.426H22.949a14.929,14.929,0,0,1-.294-5.139H7.139V22.129l3.921-3.921a4.144,4.144,0,0,1,5.861,0L25.5,26.789a15.393,15.393,0,0,1,21.033-3.774V8.426A3.425,3.425,0,0,0,43.109,5Zm32.545,5.139a3.426,3.426,0,1,1-3.426,3.426A3.425,3.425,0,0,1,37.971,10.139Zm0,13.7a11.99,11.99,0,1,0,11.99,11.99A11.99,11.99,0,0,0,37.971,23.842Zm0,5.139a1.712,1.712,0,0,1,1.713,1.713v3.426h3.426a1.713,1.713,0,0,1,0,3.426H39.684v3.426a1.713,1.713,0,0,1-3.426,0V37.545H32.832a1.713,1.713,0,0,1,0-3.426h3.426V30.693A1.712,1.712,0,0,1,37.971,28.98Z"
        }
        transform='translate(-2 -5)'
      />
    </SvgIcon>
  );
};
