import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const Receipt: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='18.588' height='20.278' viewBox='0 0 18.588 20.278' {...props}>
      <path
        id='icons8-receipt-approved'
        d='M8.112,2a.422.422,0,0,0-.189.045l-.656.328-.656-.328A.422.422,0,0,0,6,2.422V21.011a.422.422,0,0,0,.422.422h10.17a5.075,5.075,0,0,1-.616-.845H6.845V3.105l.234.117a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0L9.8,2.894l.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.656-.328.656.328a.422.422,0,0,0,.378,0l.234-.117V13.06a5.03,5.03,0,0,1,.845.219V2.422a.422.422,0,0,0-.611-.378l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328-.656-.328a.422.422,0,0,0-.378,0l-.656.328L8.3,2.044A.421.421,0,0,0,8.112,2Zm.845,3.8a.422.422,0,1,0,0,.845h6.337a.422.422,0,1,0,0-.845Zm8.872,0a.422.422,0,1,0,0,.845H19.1a.422.422,0,1,0,0-.845ZM8.957,8.337a.422.422,0,1,0,0,.845H13.6a.422.422,0,1,0,0-.845Zm8.872,0a.422.422,0,1,0,0,.845H19.1a.422.422,0,1,0,0-.845ZM8.957,10.872a.422.422,0,1,0,0,.845h6.337a.422.422,0,1,0,0-.845Zm8.872,0a.422.422,0,1,0,0,.845H19.1a.422.422,0,1,0,0-.845ZM8.957,13.406a.422.422,0,1,0,0,.845H13.6a.422.422,0,1,0,0-.845Zm11.406.422a4.225,4.225,0,1,0,4.225,4.225A4.237,4.237,0,0,0,20.364,13.829Zm0,.845a3.38,3.38,0,1,1-3.38,3.38A3.39,3.39,0,0,1,20.364,14.674ZM8.957,15.941a.422.422,0,1,0,.422.422A.422.422,0,0,0,8.957,15.941Zm1.267,0a.422.422,0,1,0,.422.422A.422.422,0,0,0,10.225,15.941Zm1.267,0a.422.422,0,1,0,.422.422A.422.422,0,0,0,11.492,15.941Zm1.267,0a.422.422,0,1,0,.422.422A.422.422,0,0,0,12.759,15.941Zm1.267,0a.422.422,0,1,0,.422.422A.422.422,0,0,0,14.027,15.941Zm1.267,0a.422.422,0,1,0,.422.422A.422.422,0,0,0,15.294,15.941Zm6.886.169L19.9,18.771,18.5,17.715,18,18.392l1.985,1.521,2.831-3.253Z'
        transform='translate(-6 -2)'
      />
    </SvgIcon>
  );
};
