import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const TermsIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "38px"}
      height={height ? height : "38px"}
      viewBox={viewBox ? viewBox : "0 0 38 38"}
      {...props}
    >
      <path
        id='icons8-terms-and-conditions'
        d='M4.9,4a.9.9,0,0,0-.9.9V33.857a.9.9,0,0,0,.9.9h8.143v-1.81H5.81V5.81H29.333V8.524h1.81V4.9a.9.9,0,0,0-.9-.9Zm5.429,6.333a.9.9,0,1,0,0,1.81h2.714v-1.81Zm5.429,0a.9.9,0,0,0-.9.9V41.1a.9.9,0,0,0,.9.9H41.1a.9.9,0,0,0,.9-.9V11.238a.9.9,0,0,0-.9-.9Zm.9,1.81H40.19V40.19H16.667Zm-6.333,3.619a.9.9,0,1,0,0,1.81h2.714v-1.81Zm10.857,1.81a.9.9,0,1,0,0,1.81H36.571a.9.9,0,1,0,0-1.81ZM10.333,21.19a.9.9,0,1,0,0,1.81h2.714V21.19ZM21.19,23a.9.9,0,1,0,0,1.81H32.048a.9.9,0,1,0,0-1.81ZM10.333,26.619a.9.9,0,1,0,0,1.81h2.714v-1.81Zm10.857,1.81a.9.9,0,1,0,0,1.81H36.571a.9.9,0,1,0,0-1.81Zm0,5.429a.9.9,0,1,0,0,1.81H32.048a.9.9,0,1,0,0-1.81Z'
        transform='translate(-4 -4)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
