import React, { FC } from "react";
import { IProps } from "./types";

export const PharmacyOrders: FC<IProps> = ({ width, height }) => {
  return (
    <svg
      id='Group_6545'
      data-name='Group 6545'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "46.813"}
      height={height ? height : "50.4"}
      viewBox='0 0 46.813 50.405'
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_4045' data-name='Rectangle 4045' width='46.813' height='50.405' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_6544' data-name='Group 6544'>
        <path
          id='Path_4397'
          data-name='Path 4397'
          d='M80.773,96.365a6.771,6.771,0,0,0,5.855-1.033,6.489,6.489,0,0,0,2.4-3.308,6.66,6.66,0,0,0-10.091-7.547,6.71,6.71,0,0,0-2.446,8,7.264,7.264,0,0,1-1.12-.275c-.257-.042-.509-.117-.766-.157-.748-.116-.749-.112-.84-.874a9.007,9.007,0,1,1,10.557,7.645,9.572,9.572,0,0,1-2.193.143c-.314-.011-.5-.1-.569-.451a8.048,8.048,0,0,0-.788-2.144'
          transform='translate(-51.544 -56.627)'
          fill='#1b2346'
        />
        <path
          id='Path_4398'
          data-name='Path 4398'
          d='M40.378,13.339a3.134,3.134,0,0,1,2.752,4.674c-1.184,2.064-2.381,4.12-3.568,6.182a.811.811,0,0,1-.47.394A7.866,7.866,0,0,0,36.5,26.04a.48.48,0,0,1-.648.054q-1.537-.911-3.093-1.787c-.275-.155-.371-.282-.186-.6,1.727-2.955,3.431-5.923,5.156-8.878a2.983,2.983,0,0,1,2.644-1.491'
          transform='translate(-22.718 -9.329)'
          fill='#4ca7e1'
        />
        <path
          id='Path_4399'
          data-name='Path 4399'
          d='M53.742,64.349a3.03,3.03,0,0,1,2.231-2.882c3.273-.884,6.552-1.75,9.825-2.637.371-.1.546-.053.648.357.358,1.437.747,2.866,1.146,4.293.1.363.021.479-.357.533a10.276,10.276,0,0,0-4.984,2.113,1.867,1.867,0,0,1-.695.339c-1.277.335-2.55.686-3.829,1.014a3.11,3.11,0,0,1-3.984-3.13'
          transform='translate(-37.588 -41.115)'
          fill='#4ca7e1'
        />
        <path
          id='Path_4400'
          data-name='Path 4400'
          d='M91.743,109.363c-1.468,0-2.937-.013-4.4.008-.389.006-.561-.118-.6-.5a6.672,6.672,0,0,1,.009-1.411c.041-.352.2-.45.543-.448,2.235.013,4.47.006,6.7.006.783,0,1.566.011,2.349,0,.3-.006.437.1.481.4a5.842,5.842,0,0,1,.008,1.507c-.04.352-.2.452-.542.449-1.517-.016-3.034-.007-4.551-.007Z'
          transform='translate(-60.645 -74.843)'
          fill='#4ca7e1'
        />
        <path
          id='Path_4401'
          data-name='Path 4401'
          d='M63.429,128.746a6.854,6.854,0,0,0-6.963-6.048,6.951,6.951,0,0,0-6.684,6.539c-.179,3.25,2.662,7.393,6.73,6.949a8.241,8.241,0,0,0,2.477-.225,6.831,6.831,0,0,0,4.441-7.215m-1.977,1.363a10.3,10.3,0,0,0-.355,1.32c-.077.375-.233.46-.61.356-2.139-.59-4.285-1.155-6.429-1.729-.662-.177-1.322-.363-1.987-.529-.265-.066-.345-.193-.268-.456.156-.531.3-1.067.437-1.6.049-.185.129-.3.256-.255l3.823,1.026c1.592.426,3.182.862,4.779,1.268.37.094.485.225.354.6'
          transform='translate(-34.812 -85.814)'
          fill='#4ca7e1'
        />
        <path
          id='Path_4402'
          data-name='Path 4402'
          d='M75.21,41.961a6.954,6.954,0,0,0-8.635-5.739l-.167.044q-10.3,2.7-20.58,5.5a6.89,6.89,0,0,0-4.9,8.377,6.96,6.96,0,0,0,7.96,5.33c.243-.038.582.013.686-.28a14.027,14.027,0,0,1,1.3-2.634c-.651.174-1.183.312-1.713.457a4.814,4.814,0,0,1-2.609.061,4.716,4.716,0,0,1-3.477-4.608,4.59,4.59,0,0,1,3.568-4.508Q52.193,42.471,57.748,41c2.641-.7,5.277-1.432,7.923-2.118q.789-.2,1.581-.405a4.52,4.52,0,0,1,5.172,2.409,4.692,4.692,0,0,1-1.092,5.741,4.4,4.4,0,0,1-1.261.745,9.636,9.636,0,0,1-1.159.367c-1.525.4-3.045.81-4.677,1.246a13.112,13.112,0,0,1,2.422,1.633c.148.13.3.062.462.02.992-.268,1.987-.525,2.976-.8a7.339,7.339,0,0,0,2.76-1.437,6.909,6.909,0,0,0,2.355-6.438'
          transform='translate(-28.484 -25.173)'
          fill='#1b2346'
        />
        <path
          id='Path_4403'
          data-name='Path 4403'
          d='M23.055,2.447a6.957,6.957,0,0,0-9.98-.633,8,8,0,0,0-1.647,2.051C7.98,9.87,4.5,15.856,1.032,21.852A6.948,6.948,0,0,0,4.282,31.929a6.987,6.987,0,0,0,8.48-2.266.484.484,0,0,0-.036-.743A5.935,5.935,0,0,1,11.9,27.77c-.36-.6-.36-.6-.733.024a4.463,4.463,0,0,1-4.3,2.379A4.67,4.67,0,0,1,3.04,23.067Q8.26,14,13.512,4.954a12.967,12.967,0,0,1,.952-1.217,4.62,4.62,0,0,1,.414-.383,4.677,4.677,0,0,1,5.6.047A4.581,4.581,0,0,1,21.82,9.367c-.063.114-.127.227-.191.341l-.039.056H21.6c-.682,1.218-1.4,2.418-2.1,3.627-.2.349-.4.7-.622,1.095a1.233,1.233,0,0,0,.245,0c.9-.234,1.8-.476,2.7-.708a.623.623,0,0,0,.377-.357c.569-.989,2.278-4.261,2.3-4.267a6.909,6.909,0,0,0-1.437-6.7'
          transform='translate(0 0)'
          fill='#1b2346'
        />
      </g>
    </svg>
  );
};
