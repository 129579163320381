import { FormAutocomplete } from "@toolkit/ui"; // Adjust path if necessary
import {
  appointmentListFiltersFormDefaultValues,
  AppointmentsListFiltersFormSchema,
  IAppointmentsListFiltersFormValues,
} from "../AppointmentsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { userGenderOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentFilterFormProps } from "../types";

export const GenderFilterForm: React.FC<AppointmentFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IAppointmentsListFiltersFormValues>
      schema={AppointmentsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='gender'
      dialogTitle={t("Gender")}
      formLabel={t("Gender")}
      render={name => <FormAutocomplete options={userGenderOptions} name={name as string} label={t("Gender")} />}
      defaultValues={appointmentListFiltersFormDefaultValues}
    />
  );
};
