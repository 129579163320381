import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SpeechBubbleIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='19.231' viewBox='0 0 20 19.231' {...props}>
      <path
        id='icons8-speech-bubble_1_'
        data-name='icons8-speech-bubble (1)'
        d='M12,3C6.477,3,2,6.788,2,11.462A7.991,7.991,0,0,0,5.547,17.92a3.4,3.4,0,0,1-1.758,3.565l0,0a.384.384,0,0,0,.137.744l.027,0h.006a6.761,6.761,0,0,0,4.558-2.093,1.489,1.489,0,0,1,1.31-.416,11.645,11.645,0,0,0,2.175.2c5.523,0,10-3.788,10-8.462S17.523,3,12,3Z'
        transform='translate(-2 -3)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
