import React from "react";
import { useTheme } from "../../base/mui";

export const AtHomeVisit = () => {
  const { palette } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24.932' height='24.932' viewBox='0 0 24.932 24.932'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={palette.primary.main} />
          <stop offset='1' stopColor={palette.secondary.main} />
        </linearGradient>
      </defs>
      <path
        id='icons8-home_2_'
        data-name='icons8-home (2)'
        d='M15.466,2a1.039,1.039,0,0,0-.726.3L3.408,11.564l-.039.03-.039.032v0a1.038,1.038,0,0,0,.708,1.8H5.078V24.854a2.077,2.077,0,0,0,2.078,2.078H23.776a2.077,2.077,0,0,0,2.078-2.078V13.427h1.039a1.039,1.039,0,0,0,.708-1.8l-.016-.012q-.034-.031-.071-.059L25.854,10.2V6.155a1.039,1.039,0,0,0-1.039-1.039H23.776a1.039,1.039,0,0,0-1.039,1.039v1.5L16.17,2.278A1.039,1.039,0,0,0,15.466,2Zm3.116,13.5h4.155v8.311H18.582Z'
        transform='translate(-3 -2)'
        fill='url(#linear-gradient)'
      />
    </svg>
  );
};
