import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const ViewIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9636' data-name='Group 9636' transform='translate(-296 -880)'>
        <circle id='Ellipse_2621' data-name='Ellipse 2621' cx='22' cy='22' r='22' transform='translate(296 880)' fill='#305e9d' />
        <path
          id='icons8-video-conference_7_'
          data-name='icons8-video-conference (7)'
          d='M4.75,6A1.75,1.75,0,0,0,3,7.75v10.5A1.75,1.75,0,0,0,4.75,20h17.5A1.75,1.75,0,0,0,24,18.25V7.75A1.75,1.75,0,0,0,22.25,6Zm0,2.625H7.375v1.75H4.75Zm8.75,0a1.151,1.151,0,0,1,1.094.593c.746,0,1.094.817,1.094,1.776a2.224,2.224,0,0,1-.193.875.48.48,0,0,1,.174.473c-.059.507-.263.635-.391.646a2.245,2.245,0,0,1-.9,1.166v.791c.327,1.065,2.144.543,2.625,2.43H10c.481-1.887,2.3-1.365,2.625-2.43v-.791a2.245,2.245,0,0,1-.9-1.166c-.129-.01-.332-.139-.391-.646a.477.477,0,0,1,.173-.473,2.523,2.523,0,0,1-.191-.875A2.1,2.1,0,0,1,13.5,8.625Zm-8.75,3.5H7.375v1.75H4.75Zm0,3.5H7.375v1.75H4.75ZM9.125,21.75a.875.875,0,1,0,0,1.75h8.75a.875.875,0,1,0,0-1.75Z'
          transform='translate(305 888)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
