import { PageLayout } from "./PageLayout";
import { PageHeader } from "../PageHeader";
import { PageLayoutProps } from "./type";

export const MainPageLayout = (props: PageLayoutProps) => {
  const { containerBreakpoint, containerBackground, withBlob = false, children } = props;
  return (
    <PageLayout
      displayBlob={withBlob}
      header={<PageHeader hasBlob={withBlob} />}
      containerBreakpoint={containerBreakpoint}
      containerBackground={containerBackground}
    >
      {children}
    </PageLayout>
  );
};
