import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PillIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20.933px"}
      height={height ? height : "21px"}
      viewBox={viewBox ? viewBox : "0 0 20.933 21"}
      {...props}
    >
      <path
        id='icons8-pills'
        d='M17.383,3.007a7.169,7.169,0,0,0-.752.009A7.259,7.259,0,0,0,12,5.2L5.237,11.973a7.114,7.114,0,0,0-.3,9.89A6.928,6.928,0,0,0,13.3,23.129a6.984,6.984,0,0,1,1.169-9.9L11.083,9.836a.874.874,0,0,1,0-1.237l2.331-2.331a5.167,5.167,0,0,1,8.21,6.1,6.987,6.987,0,0,1,1.511.925A6.927,6.927,0,0,0,17.383,3.007ZM18.76,13.5a5.25,5.25,0,1,0,5.25,5.25A5.25,5.25,0,0,0,18.76,13.5ZM16.9,16.018a.873.873,0,0,1,.619.258l3.712,3.712A.875.875,0,0,1,20,21.225l-3.712-3.712a.877.877,0,0,1,.619-1.5Z'
        transform='translate(-3.078 -3.001)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
