import { useEffect, useRef, useState } from "react";
import { useCreatePaymentCheckoutIdMutation } from "../gql";
import { ERROR_URL, logPaymentEvent, PAYMENT_PENDING_URL, SUCCESS_URL } from "../constants";
import { useAddToast } from "@toolkit/ui";
import { PaymentCheckoutInfo } from "../types/types";
import { AmountSourceType } from "@health/queries/types";
import { formatGraphQLError } from "@/utils/formatErrors";

export const initialPaymentInfo: PaymentCheckoutInfo = {
  id: null,
  checkoutPaymentFormUrl: null,
  shopperResultUrl: null,
};

export type PreparePaymentHook = {
  amountSourceId: string;
  amountSource: AmountSourceType;
};

export const usePreparePayment = (options?: PreparePaymentHook) => {
  const [failedPayment, setFailedPayment] = useState(false);
  const paymentInfo = useRef<PaymentCheckoutInfo>(initialPaymentInfo);
  const { failed } = useAddToast();

  const { amountSource, amountSourceId } = options || {};

  const [fetchCreateCheckoutId, { loading: isPaymentProcessLoading }] = useCreatePaymentCheckoutIdMutation({
    fetchPolicy: "no-cache",
    onCompleted: ({ checkout }) => {
      if (!checkout?.checkoutPaymentFormUrl) {
        failed("Payment Failed");
        setFailedPayment(true);
      } else {
        paymentInfo.current = checkout;
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const getPreparedShopperResultUrl = () => {
    const { shopperResultUrl } = paymentInfo.current;
    const result = !shopperResultUrl
      ? ""
      : `${shopperResultUrl}${
          shopperResultUrl.includes("?") ? "&" : "?"
        }client_redirect=${SUCCESS_URL}&client_redirect_error=${ERROR_URL}&refunded_redirect_to=${ERROR_URL}&client_redirect_pending=${PAYMENT_PENDING_URL}`;
    console.log("getPreparedShopperResultUrl", result);
    return result;
  };

  const resultUrl = getPreparedShopperResultUrl();

  useEffect(() => {
    return () => {
      paymentInfo.current = initialPaymentInfo;
    };
  }, []);

  useEffect(() => {
    if (!amountSource || !amountSourceId || !resultUrl) {
      return;
    }

    logPaymentEvent({
      resultUrl,
      checkoutUrl: paymentInfo.current?.checkoutPaymentFormUrl,
      amountSourceId: amountSourceId!,
      amountSourceType: amountSource!,
    });
  }, [resultUrl, amountSourceId, amountSource]);

  return {
    resultUrl,
    paymentInfo,
    failedPayment,
    isPaymentProcessLoading,
    getCheckoutId: () => paymentInfo.current?.id,
    getMerchantTransactionId: () => paymentInfo.current?.merchantTransactionId,
    getPreparedShopperResultUrl,
    fetchCreateCheckoutId,
  };
};
