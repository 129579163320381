import { Box, Container, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

export const PageContainer: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        width: "100%",
        height: "100%",
      }}
    >
      <Container
        maxWidth='sm'
        sx={{
          backgroundColor: theme.palette.common.white,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "30px",
          height: "100%",
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
