import { Box } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useCommonStyles } from "../CommonContainer.styles";
import { useNotificationDetailsStyle, useAppointmentQuery } from "@/domains/notifications";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { routesPaths } from "@/routing/routesPaths";
import { AppointmentNotificationViewItem } from "@/domains/notifications/components/AppointmentNotification/AppointmentNotificationViewItem";
import { Appointment, AppointmentStatus } from "@health/queries/types";
import {
  getAppointmentDateTime,
  getNotificationAppointmentStatus,
  getNotificationAppointmentType,
} from "@/domains/notifications/components/AppointmentNotification/utils";
import moment from "moment-timezone";
import { getApolloContextFromToken } from "@/providers/auth";
import { useAuth } from "react-oidc-context";
import { NotificationDetailsSkeleton } from "@/domains/notifications/components";
import { AppointmentNotificationDetailsActions } from "../AppointmentNotificationDetailsActions/AppointmentNotificationDetailsActions";
import { useHeaderContext } from "@/providers";

interface QueryParams {
  appointmentId?: string;
}

export const AppointmentNotificationDetailsContainer: React.FC = () => {
  const { classes, cx, theme } = useCommonStyles();
  const { classes: notificationsClasses } = useNotificationDetailsStyle();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const queryParams: QueryParams = queryString.parse(location.search);
  const id = queryParams.appointmentId;
  const { setHeaderTitle } = useHeaderContext();

  const {
    data: appointmentInfo,
    loading,
    error,
    refetch,
  } = useAppointmentQuery({
    variables: {
      appointmentId: id!,
    },
    context: getApolloContextFromToken(user?.access_token),
    skip: !id,
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (!queryParams.appointmentId || error || (loading && appointmentInfo && !appointmentInfo.appointment)) {
      navigate(routesPaths.notifications);
    }
  }, [appointmentInfo]);
  const notificationAppointmentStatus = getNotificationAppointmentStatus(theme);
  const appointmentType = getNotificationAppointmentType();

  useEffect(() => {
    id && refetch();
  }, [id, refetch]);

  const data = (appointmentInfo?.appointment || undefined) as Appointment;

  const timeframeStart = getAppointmentDateTime(data, "HH:mm");
  const appointmentDate = getAppointmentDateTime(data);

  const formNowTzItem = moment(data?.confirmedStartTime)?.fromNow();
  const doctor = (data?.doctor?.user?.firstName || "-") + " " + (data?.doctor?.user?.lastName || "");

  const appointmentStatus = data?.status;

  useEffect(() => {
    if (!queryParams.appointmentId || error || (appointmentInfo && !appointmentInfo.appointment)) {
      navigate(routesPaths.notifications);
    }
  }, [id, error, appointmentInfo]);
  useEffect(() => {
    id && refetch();
  }, [id, refetch]);

  useEffect(() => {
    setHeaderTitle(notificationAppointmentStatus[appointmentStatus!]?.label);
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, appointmentStatus, notificationAppointmentStatus]);

  const Icon = notificationAppointmentStatus[appointmentStatus!] ? notificationAppointmentStatus[appointmentStatus!].icon : null;

  return (
    <Box className={classes.pageContainer}>
      <Box className={cx(classes.pageContent, notificationsClasses.contentContainer)}>
        {loading ? (
          <NotificationDetailsSkeleton />
        ) : (
          <>
            <Box className={notificationsClasses.notificationsIcon}>
              {Icon && <Icon fill={notificationAppointmentStatus[appointmentStatus!]?.color} opacity={0.8} />}
            </Box>
            <Box className={notificationsClasses.content}>
              <AppointmentNotificationViewItem
                id={data?.id}
                status={appointmentStatus!}
                appointmentStatus={appointmentStatus as AppointmentStatus}
                doctor={doctor}
                appointmentType={data?.type ? appointmentType[data?.type]?.label : ""}
                formNowTzItem={formNowTzItem}
                timeframeStart={timeframeStart!}
                confirmedStartTime={data?.confirmedStartTime}
                confirmedEndTime={data?.confirmedEndTime}
                requestedTimeSlots={data?.requestedTimeSlots as string[]}
                suggestedTimeSlots={data?.suggestedTimeSlots as string[]}
                consumer={data?.consumer || {}}
                appointmentDate={appointmentDate!}
                appointmentSlotTimePeriod={data?.doctor?.appointmentSlotTimePeriod}
                cancellationReason={data?.cancellationReason}
              />
            </Box>
            <AppointmentNotificationDetailsActions
              appointment={data}
              doctorId={data?.doctor?.id || ""}
              appointmentId={data?.id}
              appointmentStatus={appointmentStatus as AppointmentStatus}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
