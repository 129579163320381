import { useState } from "react";
import { useNavigate, Location } from "react-router-dom";
import queryString from "query-string";
import { useAuth } from "react-oidc-context";
import { useAddToast } from "@toolkit/ui";
import { formatQueryStringSlots, getTimeOfDay, getVisitPrice } from "../../utils";
import { useConsumerAppointmentConfirmMutation } from "../../gql";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { getApolloContextFromToken } from "@/providers/auth";
import { ConfirmDoctorTimeContainerData } from "../../utils/confirm-doctor-time.utils";
import { AppointmentScheduleSuccessContainerData } from "../AppointmentCreateSuccessContainer/type";
import { routes } from "../../routes";
import { IFormattedTimeSlot } from "../../types/types";

export const useConfirmDoctorTime = (location: Location<ConfirmDoctorTimeContainerData>) => {
  const { appointmentId } = queryString.parse(location.search) as { appointmentId: string };
  const { suggestedTimeSlots: _suggestedTimeSlots, doctorInfo, paymentInfo, appointmentType } = location.state || {};
  const suggestedTimeSlots = formatQueryStringSlots(_suggestedTimeSlots);
  const { priceAmountAfterVat: totalAmount = 0 } = paymentInfo || {};
  const { user } = useAuth();
  const { failed } = useAddToast();
  const navigate = useNavigate();

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<IFormattedTimeSlot | undefined>(undefined);

  const [ConsumerAppointmentConfirm, { loading: isSubmitting }] = useConsumerAppointmentConfirmMutation({
    onCompleted: data => {
      const errors = data?.consumerAppointmentConfirm?.appointmentErrors;
      if (!errors?.length && selectedTimeSlot) {
        navigate(routes.createSuccess.absRoute, {
          state: {
            doctorInfo: doctorInfo,
            selectedTimeSlots: [JSON.stringify(selectedTimeSlot)],
            visitType: appointmentType,
          } as AppointmentScheduleSuccessContainerData,
        });
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFromToken(user?.access_token),
  });

  const handleTimeSlotSelect = (slot: IFormattedTimeSlot) => {
    setSelectedTimeSlot(slot);
  };

  const handleAppointmentConfirmation = () => {
    if (isSubmitting || !appointmentId || !selectedTimeSlot) {
      return;
    }
    ConsumerAppointmentConfirm({
      variables: {
        consumerAppointmentConfirmId: appointmentId,
        input: {
          confirmedStartTime: selectedTimeSlot.startTime,
          confirmedEndTime: selectedTimeSlot.endTime,
        },
      },
    });
  };

  const paymentAmount =
    totalAmount ||
    getVisitPrice({
      type: appointmentType,
      onlineVisitPrice: doctorInfo?.onlineVisitPrice,
      onsiteVisitPrice: doctorInfo?.onsiteVisitPrice,
      atHomeVisitPrice: doctorInfo?.atHomeVisitPrice,
    });

  const appointmentDate = suggestedTimeSlots[0]?.startTime?.format("DD MMMM YYYY");
  const timeFrame = getTimeOfDay(suggestedTimeSlots[0]?.startTime.hour());

  return {
    isSubmitting,
    selectedTimeSlot,
    paymentAmount,
    appointmentDate,
    timeFrame,
    doctorInfo,
    handleTimeSlotSelect,
    handleAppointmentConfirmation,
    suggestedTimeSlots,
  };
};
