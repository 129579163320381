import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SurgeryIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22.534px"}
      height={height ? height : "26px"}
      viewBox={viewBox ? viewBox : "0 0 22.534 26"}
      {...props}
    >
      <path
        id='icons8-surgery'
        d='M17.417.014a.861.861,0,0,0-.84.658l-.391,1.569-.008.008-.164.65a2.573,2.573,0,0,0,.217,1.9,2.6,2.6,0,0,0,4.489,0,2.573,2.573,0,0,0,.217-1.9l-.164-.65-.008-.008L20.373.672a.861.861,0,0,0-.84-.658Zm.191,2.6h1.733a.867.867,0,1,1,0,1.733H17.608a.867.867,0,1,1,0-1.733Zm-2.248,5.2a2.6,2.6,0,0,0-2,.936L9.81,13.88h2.6l2.6-3.467V13.88h6.933l1.733.867.467-1.762a1.733,1.733,0,0,0-.08-1.633L22.7,9.076a2.6,2.6,0,0,0-2.229-1.263ZM2.85,11.255a.864.864,0,0,0-.85.884v13a.864.864,0,0,0,.858.875H23.675a.866.866,0,0,0,.858-.858V22.539a.865.865,0,0,0-.858-.884H23.65a.682.682,0,0,0-.122.017H3.733V12.139a.865.865,0,0,0-.858-.884Zm4.784,4.35A2.167,2.167,0,1,0,9.8,17.772,2.167,2.167,0,0,0,7.633,15.605Zm3.9,0v4.333H23.667V18.205a2.6,2.6,0,0,0-2.6-2.6Z'
        transform='translate(-2 -0.014)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
