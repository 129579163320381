import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const WalletIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.633' height='14.811' viewBox='0 0 15.633 14.811' {...props}>
      <g id='icons8-wallet_2_' data-name='icons8-wallet (2)' transform='translate(-5 -6)'>
        <path
          id='Path_12990'
          data-name='Path 12990'
          d='M5.617,16.377A.617.617,0,0,1,5,15.76V10.617a.617.617,0,1,1,1.234,0V15.76A.617.617,0,0,1,5.617,16.377Z'
          transform='translate(0 -2.354)'
          fill={fill}
        />
        <path
          id='Path_12991'
          data-name='Path 12991'
          d='M17.548,33.465H7.263A2.265,2.265,0,0,1,5,31.2V28.117a.617.617,0,1,1,1.234,0V31.2a1.03,1.03,0,0,0,1.029,1.029H17.548A1.03,1.03,0,0,0,18.576,31.2V29.146a.617.617,0,0,1,1.234,0V31.2A2.265,2.265,0,0,1,17.548,33.465Z'
          transform='translate(0 -12.655)'
          fill={fill}
        />
        <path
          id='Path_12992'
          data-name='Path 12992'
          d='M23.078,17.937a.617.617,0,0,1-.617-.617V15.263a1.03,1.03,0,0,0-1.029-1.029H12.217a.617.617,0,1,1,0-1.234h9.215A2.265,2.265,0,0,1,23.7,15.263V17.32A.617.617,0,0,1,23.078,17.937Z'
          transform='translate(-3.885 -4.12)'
          fill={fill}
        />
        <path
          id='Path_12993'
          data-name='Path 12993'
          d='M8.291,10.114H7.057A2.057,2.057,0,0,1,7.057,6h8.434a2.284,2.284,0,0,1,2.041,1.3.617.617,0,0,1-1.121.517,1.044,1.044,0,0,0-.921-.588H7.057a.823.823,0,0,0,0,1.646H8.291a.617.617,0,0,1,0,1.234Z'
          fill={fill}
        />
        <path
          id='Path_12994'
          data-name='Path 12994'
          d='M34.5,26.525H33.263a2.263,2.263,0,0,1,0-4.525H34.5a1.442,1.442,0,0,1,1.44,1.44v1.646A1.442,1.442,0,0,1,34.5,26.525Zm-1.234-3.291a1.029,1.029,0,1,0,0,2.057H34.5a.208.208,0,0,0,.206-.206V23.44a.208.208,0,0,0-.206-.206Z'
          transform='translate(-15.303 -9.418)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
