import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const AllergiesIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const theme = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "19.586px"}
      height={height ? height : "22.139px"}
      viewBox={viewBox ? viewBox : "0 0 19.586 22.139"}
      {...props}
    >
      <path
        id='icons8-sneeze'
        d='M4.008,2,4,2.009V24.141H7.406V23.088a1.271,1.271,0,0,1,1.48-1.254l1.657.276a1.694,1.694,0,0,0,1.973-1.67v-.83l-1.545-.246a1.2,1.2,0,0,1,0-2.368l1.545-.246V15.407l1.934-.667a.85.85,0,0,0,.394-1.4l-2.362-2.831C12.136,5.744,8.889,2.005,4.008,2ZM22.734,12.219a.852.852,0,1,0,.852.852A.852.852,0,0,0,22.734,12.219Zm-2.555,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,20.18,13.922Zm-2.555,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,17.625,15.625Zm5.109,0a.852.852,0,1,0,.852.852A.852.852,0,0,0,22.734,15.625Zm-7.664,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,15.07,17.328Zm5.109,0a.852.852,0,1,0,.852.852A.852.852,0,0,0,20.18,17.328Zm-2.555,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,17.625,19.032Zm5.109,0a.852.852,0,1,0,.852.852A.852.852,0,0,0,22.734,19.032Zm-2.555,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,20.18,20.735Zm2.555,1.7a.852.852,0,1,0,.852.852A.852.852,0,0,0,22.734,22.438Z'
        transform='translate(-4 -2.002)'
        fill={fill ? fill : theme.palette.common.white}
      />
    </SvgIcon>
  );
};
