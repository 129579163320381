import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const HeaderCustomerIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.226' height='22.818' viewBox='0 0 21.226 22.818' {...props}>
      <path
        id='icons8-customer_1_'
        data-name='icons8-customer (1)'
        d='M15.613,3a5.3,5.3,0,0,0-5.307,5.307v3.184a5.314,5.314,0,0,0,2.123,4.229v2.4c0,.056-.01.1-.149.232a3.222,3.222,0,0,1-.713.464c-.653.328-1.6.663-2.554,1.128a8.718,8.718,0,0,0-2.72,1.907A5,5,0,0,0,5,25.288v.531H26.226v-.531a5.02,5.02,0,0,0-1.293-3.449,8.562,8.562,0,0,0-2.72-1.89c-.958-.458-1.9-.8-2.554-1.128a3.2,3.2,0,0,1-.713-.448c-.137-.129-.149-.182-.149-.249v-2.4a5.314,5.314,0,0,0,2.123-4.229V8.307A5.3,5.3,0,0,0,15.613,3Zm0,1.061a4.214,4.214,0,0,1,4.245,4.245v3.184a4.274,4.274,0,0,1-1.89,3.532l-.232.166v2.935a1.443,1.443,0,0,0,.481,1.028,3.969,3.969,0,0,0,.978.63c.742.369,1.654.7,2.554,1.128a7.638,7.638,0,0,1,2.388,1.642,3.879,3.879,0,0,1,.929,2.206H6.161a3.879,3.879,0,0,1,.929-2.189A7.749,7.749,0,0,1,9.477,20.91c.9-.437,1.814-.775,2.554-1.144a4.093,4.093,0,0,0,.978-.63,1.41,1.41,0,0,0,.481-1.012V15.189l-.232-.166a4.274,4.274,0,0,1-1.89-3.532V8.307A4.214,4.214,0,0,1,15.613,4.061Z'
        transform='translate(-5 -3)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
