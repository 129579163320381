import { makeStyles, Theme } from "@toolkit/ui";
export const useAppointmentConfirmationGuestOTPContainerStyles = makeStyles<{ isDisabled: boolean }>()((theme: Theme, { isDisabled }) => ({
  verificationTitle: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: "2px",
    marginTop: "200px",
  },
  verificationDescription: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    marginBottom: "20px",
  },
  otpContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resendContainer: {
    marginTop: "46px",
    textAlign: "center",
    fontSize: theme.mixins.fonts.fontSize.lg,
  },
  resendButton: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    display: "inline",
    textTransform: "none",
    height: "10px",
    padding: "0px",
    cursor: isDisabled ? "not-allowed" : "pointer",
    color: isDisabled ? theme.palette.stale.main : theme.palette.secondary.main,
    pointerEvents: isDisabled ? "none" : "auto",
    textDecoration: "underline",
  },
}));
