import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SwitchLanguageIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='88'
      height='88'
      viewBox='0 0 88 88'
      {...props}
    >
      <defs>
        <filter id='Ellipse_2933' x='0' y='0' width='88' height='88' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='5' result='blur' />
          <feFlood floodOpacity='0.102' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g id='Group_10959' data-name='Group 10959' transform='translate(-15 -420)'>
        <g transform='matrix(1, 0, 0, 1, 15, 420)' filter='url(#Ellipse_2933)'>
          <g
            id='Ellipse_2933-2'
            data-name='Ellipse 2933'
            transform='translate(15 12)'
            fill={palette.common.white}
            stroke='#0000000D'
            strokeWidth='1'
          >
            <circle cx='29' cy='29' r='29' stroke='none' />
            <circle cx='29' cy='29' r='28' fill='none' />
          </g>
        </g>
        <path
          id='icons8-update_1_'
          data-name='icons8-update (1)'
          d='M20.7,1.986a1.1,1.1,0,0,0-.759.334l-2.85,2.85a1.1,1.1,0,0,0-.208.208l-.254.254a1.1,1.1,0,0,0,0,1.561l3.313,3.312A1.1,1.1,0,1,0,21.5,8.945L19.732,7.173A11.027,11.027,0,0,1,28.97,23.424a1.1,1.1,0,0,0,1.942,1.052A13.24,13.24,0,0,0,20.386,5L21.5,3.881a1.1,1.1,0,0,0-.8-1.895ZM12.314,7.158a1.1,1.1,0,0,0-.694.21,13.35,13.35,0,0,0-3.334,3.392A1.1,1.1,0,1,0,10.112,12a11.14,11.14,0,0,1,2.782-2.829,1.1,1.1,0,0,0-.579-2.014Zm-4.543,6a1.1,1.1,0,0,0-1.065.762A13.249,13.249,0,0,0,18.114,31.383L17,32.5a1.1,1.1,0,1,0,1.562,1.562l2.849-2.85A1.1,1.1,0,0,0,21.616,31l.254-.254a1.1,1.1,0,0,0,0-1.561l-3.313-3.312A1.1,1.1,0,1,0,17,27.437l1.771,1.771A11.033,11.033,0,0,1,8.8,14.631a1.1,1.1,0,0,0-1.025-1.477ZM28.155,25.386a1.114,1.114,0,0,0-.863.358,11.16,11.16,0,0,1-1.686,1.465,1.1,1.1,0,0,0,1.274,1.8A13.357,13.357,0,0,0,28.9,27.26a1.1,1.1,0,0,0-.745-1.873Z'
          transform='translate(40 442.814)'
          fill={props.fill ? props.fill : palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};
