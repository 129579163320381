import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    minHeight: "380px",
    height: "max-content",
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      height: "fit-content",
      "& .section__container": {
        gap: "10px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .title": {
        marginBottom: "20px",
      },
    },
  },
  container: {
    paddingInline: "10px",
    alignContent: "start",
    width: "100%",
    height: "100%",
  },
  categoriesContainer: {
    minHeight: "240px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    alignItems: "start",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      gap: "5px",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridTemplateRows: "repeat(2, auto)",
      "& > :nth-last-child(-n+2)": {
        display: "none",
      },
    },
    [theme.breakpoints.down("xl")]: {
      gap: "10px",
    },
  },

  seeAllButton: {
    width: "120px",
    height: "120px",
    "& svg": {
      width: "32px",
      height: "32px",
    },
    "& path": {
      fill: theme.palette.common.white,
    },
    boxShadow: theme.mixins.shadows.sm,
  },
  item__button: {
    "&& svg": {
      width: "45px",
      height: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "58px",
      height: "58px",
      "&& svg": {
        width: "30px",
        height: "30px",
      },
    },
  },
  item__root: {
    minWidth: "120px",
    maxWidth: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "30px",
      height: "min-content",
    },
  },
  item__icon: {
    width: "45px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
    },
  },
  item__title: {
    marginTop: "16px",
    wordBreak: "break-word",
    overflow: "break-word",
    hyphens: "manual",
  },
}));
