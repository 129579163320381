/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeVisitsCallInProgressQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeVisitsCallInProgressQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, visits?: { __typename?: 'VisitCountableConnection', edges: Array<{ __typename?: 'VisitCountableEdge', node: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null, meetingPlatformLinkForJoinCall?: string | null, number?: string | null, type?: Types.VisitType | null, consumerJoined?: boolean | null, consumerJoinedDate?: any | null, consumerLeft?: boolean | null, consumerLeftDate?: any | null, appointment?: { __typename?: 'Appointment', id: string } | null } }> } | null } | null };


export const MeVisitsCallInProgressDocument = gql`
    query meVisitsCallInProgress {
  me {
    id
    visits(
      first: 1
      filter: {status: [ACCEPTED, ASSIGNED, CALL_IN_PROGRESS, CHAT_IN_PROGRESS]}
    ) {
      edges {
        node {
          id
          status
          meetingPlatformLinkForJoinCall
          number
          type
          appointment {
            id
          }
          consumerJoined
          consumerJoinedDate
          consumerLeft
          consumerLeftDate
        }
      }
    }
  }
}
    `;

/**
 * __useMeVisitsCallInProgressQuery__
 *
 * To run a query within a React component, call `useMeVisitsCallInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeVisitsCallInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeVisitsCallInProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeVisitsCallInProgressQuery(baseOptions?: Apollo.QueryHookOptions<MeVisitsCallInProgressQuery, MeVisitsCallInProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeVisitsCallInProgressQuery, MeVisitsCallInProgressQueryVariables>(MeVisitsCallInProgressDocument, options);
      }
export function useMeVisitsCallInProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeVisitsCallInProgressQuery, MeVisitsCallInProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeVisitsCallInProgressQuery, MeVisitsCallInProgressQueryVariables>(MeVisitsCallInProgressDocument, options);
        }
export type MeVisitsCallInProgressQueryHookResult = ReturnType<typeof useMeVisitsCallInProgressQuery>;
export type MeVisitsCallInProgressLazyQueryHookResult = ReturnType<typeof useMeVisitsCallInProgressLazyQuery>;
export type MeVisitsCallInProgressQueryResult = Apollo.QueryResult<MeVisitsCallInProgressQuery, MeVisitsCallInProgressQueryVariables>;