import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const ConferenceIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9644' data-name='Group 9644' transform='translate(-286.566 -969.064)'>
        <circle id='Ellipse_2640' data-name='Ellipse 2640' cx='22' cy='22' r='22' transform='translate(286.566 969.064)' fill='#1d2344' />
        <path
          id='icons8-conference_1_'
          data-name='icons8-conference (1)'
          d='M10,5A2,2,0,0,0,8,7V23a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2ZM3,7A2,2,0,0,0,1,9V21a2,2,0,0,0,2,2H6V20H3c0-2.938,2.579-2.1,3-3.367v-.844s-.785-.738-.842-1.242a.78.78,0,0,1-.408-.789c0-.328.156-.4.156-.4a2.06,2.06,0,0,1-.344-1.09A2.112,2.112,0,0,1,6,10.174V7ZM24,7v3.5c.862,0,1.375.787,1.375,1.809a2.633,2.633,0,0,1-.281,1.045s.156.053.156.4a1.026,1.026,0,0,1-.408.789c-.057.505-.842,1.242-.842,1.242v.842c.421,1.263,3,.42,3,3.367H24v3h3a2,2,0,0,0,2-2V9a2,2,0,0,0-2-2Zm-9,3a1.327,1.327,0,0,1,1,.5c.862,0,1.375.787,1.375,1.809a2.633,2.633,0,0,1-.281,1.045s.156.053.156.4a1.026,1.026,0,0,1-.408.789c-.057.505-.842,1.242-.842,1.242v.842c.421,1.263,3,.42,3,3.367H11c0-2.935,2.579-2.1,3-3.365v-.844s-.785-.738-.842-1.242a.78.78,0,0,1-.408-.789c0-.328.156-.4.156-.4a2.06,2.06,0,0,1-.344-1.09A2.234,2.234,0,0,1,15,10Z'
          transform='translate(294 976)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
