import { toInteger } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useSendOtpMessageForAnonymousAppointmentMutation } from "../../gql";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";

export const useOTPVerification = ({ initialResetTime = 10 } = {}) => {
  const [otp, setOtp] = useState("");
  const [resetRemainingTime, setResetRemainingTime] = useState(initialResetTime);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const { failed } = useAddToast();
  const { t } = useTranslation("");
  const { guestInfo } = useAppointmentBookingContext();
  const [sendOTPForGuest, { loading: isResendLoading }] = useSendOtpMessageForAnonymousAppointmentMutation({
    onCompleted: () => {
      setResetRemainingTime(initialResetTime);
    },
    onError: () => {
      failed(t("Something went wrong, please try again"));
    },
  });

  const handleChange = (value: string) => {
    setOtp(value);
  };

  const handleResend = () => {
    if (resetRemainingTime === 0) {
      guestInfo?.name &&
        guestInfo?.phone &&
        sendOTPForGuest({
          variables: {
            consumerName: guestInfo?.name,
            consumerMobileNumber: guestInfo?.phone,
          },
        });
    }
  };

  useEffect(() => {
    if (resetRemainingTime > 0) {
      ref.current = setInterval(() => {
        setResetRemainingTime(prevTime => prevTime - 1);
      }, 1000);

      return () => {
        if (ref.current) clearInterval(ref.current);
      };
    }
  }, [resetRemainingTime, initialResetTime]);

  const formattedTime = `${String(toInteger(resetRemainingTime / 60)).padStart(2, "0")}:${String(resetRemainingTime % 60).padStart(
    2,
    "0"
  )}`;

  return {
    otp,
    resetRemainingTime: formattedTime,
    isDisabled: resetRemainingTime > 0,
    isNextDisabled: otp.length !== 4 || isResendLoading,
    handleChange,
    handleResend,
  };
};
