import { FC } from "react";
import { NotificationWrapperIcon, NotificationIconProps } from "./NotificationWrapperIcon";

export const CancelledNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g transform='translate(10.5 11.5)' fill='#fff'>
        <path
          d='M10.829,3.478a.856.856,0,0,0-.843.868v.571H9.7a3.713,3.713,0,0,0-3.71,3.71V10.2a.856.856,0,0,0,0,.278v2.394a.856.856,0,1,0,1.712,0V11.2H24.825V16.32a.856.856,0,1,0,1.712,0V10.48a.856.856,0,0,0,0-.278V8.627a3.713,3.713,0,0,0-3.71-3.71h-.285V4.347a.856.856,0,1,0-1.712,0v.571H11.7V4.347a.856.856,0,0,0-.869-.868ZM9.7,6.63h.285v.285a.856.856,0,1,0,1.712,0V6.63H20.83v.285a.856.856,0,1,0,1.712,0V6.63h.285a1.941,1.941,0,0,1,2,2v.856H7.7V8.627A1.941,1.941,0,0,1,9.7,6.63ZM6.834,15.84a.856.856,0,0,0-.843.868v5.045a3.713,3.713,0,0,0,3.71,3.71H22.828a3.713,3.713,0,0,0,3.71-3.71V20.035a.856.856,0,1,0-1.712,0v1.719a1.941,1.941,0,0,1-2,2H9.7a1.941,1.941,0,0,1-2-2V16.709a.856.856,0,0,0-.869-.868Zm14.735.272a.856.856,0,0,0-.6.255l-1.2,1.174-1.2-1.174a.856.856,0,1,0-1.2,1.223l1.173,1.148-1.173,1.148a.856.856,0,1,0,1.2,1.223l1.2-1.174,1.2,1.174a.856.856,0,1,0,1.2-1.223L21,18.739l1.174-1.148a.856.856,0,0,0-.6-1.478Z'
          transform='scale(0.8)'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
