import React from "react";
import { useTheme } from "../../base/mui";
export const HeightChartIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50.945' height='54.096' viewBox='0 0 50.945 54.096'>
      <g id='Group_13948' data-name='Group 13948' transform='translate(-359.281 -769)'>
        <g id='Group_13947' data-name='Group 13947' transform='translate(359.281 778.112)'>
          <g id='icons8-tall-person_1_' data-name='icons8-tall-person (1)'>
            <circle
              id='Ellipse_3237'
              data-name='Ellipse 3237'
              cx='4.466'
              cy='4.466'
              r='4.466'
              transform='translate(1.406)'
              fill={palette.primary.main}
            />
            <path
              id='Path_20841'
              data-name='Path 20841'
              d='M14.436,14.218V32.449A1.453,1.453,0,0,1,12.983,33.9h0a1.453,1.453,0,0,1-1.45-1.357l-.616-9.237a.7.7,0,0,0-.7-.654h0a.7.7,0,0,0-.7.655L8.9,32.546A1.453,1.453,0,0,1,7.452,33.9h0A1.453,1.453,0,0,1,6,32.449V14.218A4.218,4.218,0,0,1,10.218,10h0A4.218,4.218,0,0,1,14.436,14.218Z'
              transform='translate(-4.594 1.248)'
              fill={palette.primary.main}
            />
            <path
              id='Path_20842'
              data-name='Path 20842'
              d='M14.842,24.059a1.406,1.406,0,0,1-1.406-1.406V14.218a1.408,1.408,0,0,0-1.406-1.406H9.218a1.408,1.408,0,0,0-1.406,1.406v8.436a1.406,1.406,0,0,1-2.812,0V14.218A4.223,4.223,0,0,1,9.218,10H12.03a4.223,4.223,0,0,1,4.218,4.218v8.436A1.406,1.406,0,0,1,14.842,24.059Z'
              transform='translate(-5 1.248)'
              fill={palette.primary.main}
            />
          </g>
        </g>
        <g id='icons8-height_1_' data-name='icons8-height (1)' transform='translate(361.049 769)'>
          <path
            id='Line_1861'
            data-name='Line 1861'
            d='M45.719,2.963H1.459A2.259,2.259,0,0,1-1,.982,2.259,2.259,0,0,1,1.459-1h44.26A2.259,2.259,0,0,1,48.178.982,2.259,2.259,0,0,1,45.719,2.963Z'
            transform='translate(1 1)'
            fill={palette.primary.main}
          />
          <path
            id='Line_1862'
            data-name='Line 1862'
            d='M45.719,3.172H1.459A2.3,2.3,0,0,1-1,1.086,2.3,2.3,0,0,1,1.459-1h44.26a2.3,2.3,0,0,1,2.459,2.086A2.3,2.3,0,0,1,45.719,3.172Z'
            transform='translate(1 50.924)'
            fill={palette.primary.main}
          />
          <path
            id='Line_1863'
            data-name='Line 1863'
            d='M1.459,40.8A2.459,2.459,0,0,1-1,38.342V1.459a2.459,2.459,0,1,1,4.918,0V38.342A2.459,2.459,0,0,1,1.459,40.8Z'
            transform='translate(23.13 7.147)'
            fill={palette.primary.main}
            stroke={palette.common.white}
            strokeLinecap='round'
            strokeWidth='2'
          />
          <path
            id='Path_20843'
            data-name='Path 20843'
            d='M24.294,15.836a2.451,2.451,0,0,1-1.739-.72l-3.179-3.179L16.2,15.115a2.459,2.459,0,0,1-3.477-3.477L17.638,6.72a2.459,2.459,0,0,1,3.477,0l4.918,4.918a2.459,2.459,0,0,1-1.739,4.2Z'
            transform='translate(5.212 -0.082)'
            fill={palette.primary.main}
            stroke={palette.common.white}
            strokeLinecap='round'
            strokeWidth='1'
          />
          <path
            id='Path_20844'
            data-name='Path 20844'
            d='M19.377,29.836a2.451,2.451,0,0,1-1.739-.72L12.72,24.2A2.459,2.459,0,0,1,16.2,20.72L19.377,23.9l3.179-3.179A2.459,2.459,0,0,1,26.033,24.2l-4.918,4.918A2.451,2.451,0,0,1,19.377,29.836Z'
            transform='translate(5.212 18.342)'
            fill={palette.primary.main}
            stroke={palette.common.white}
            strokeLinecap='round'
            strokeWidth='1'
          />
          <circle
            id='Ellipse_3238'
            data-name='Ellipse 3238'
            cx='2.316'
            cy='2.316'
            r='2.316'
            transform='translate(44.23 19.687)'
            fill={palette.primary.main}
          />
          <ellipse
            id='Ellipse_3239'
            data-name='Ellipse 3239'
            cx='2.316'
            cy='2.895'
            rx='2.316'
            ry='2.895'
            transform='translate(44.23 9.264)'
            fill={palette.primary.main}
          />
          <ellipse
            id='Ellipse_3240'
            data-name='Ellipse 3240'
            cx='2.316'
            cy='2.895'
            rx='2.316'
            ry='2.895'
            transform='translate(44.23 28.951)'
            fill={palette.primary.main}
          />
          <circle
            id='Ellipse_3241'
            data-name='Ellipse 3241'
            cx='2.316'
            cy='2.316'
            r='2.316'
            transform='translate(44.23 39.374)'
            fill={palette.primary.main}
          />
        </g>
      </g>
    </svg>
  );
};
