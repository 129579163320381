import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const NewIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.835' height='21.697' viewBox='0 0 21.835 21.697' {...props}>
      <path
        id='icons8-new'
        d='M15.247,2.036a1.107,1.107,0,0,0-.562.106L12.8,3.037l-1.882-.9h0a1.123,1.123,0,0,0-1.374.337h0L8.3,4.147l-2.084.082h0a1.122,1.122,0,0,0-1.057.939L4.821,7.227,3.015,8.269a1.122,1.122,0,0,0-.5,1.322l.663,1.979L2.059,13.331a1.12,1.12,0,0,0,.171,1.4l1.5,1.443-.17,2.078a1.123,1.123,0,0,0,.8,1.164h0l2,.577.816,1.92h0a1.118,1.118,0,0,0,1.256.656l2.041-.418,1.613,1.32h0a1.115,1.115,0,0,0,1.416,0l1.613-1.32,2.041.418a1.118,1.118,0,0,0,1.256-.657L19.237,20l2-.577h0a1.122,1.122,0,0,0,.8-1.164l-.17-2.079,1.5-1.443a1.121,1.121,0,0,0,.172-1.4l-1.116-1.761L23.1,9.59h0a1.123,1.123,0,0,0-.5-1.321L20.787,7.226l-.332-2.059h0A1.125,1.125,0,0,0,19.4,4.229l-2.084-.082L16.061,2.479a1.123,1.123,0,0,0-.815-.444Zm-4.787.911a.206.206,0,0,1,.067.019L12.8,4.05,15.08,2.967h0a.2.2,0,0,1,.247.062l1.512,2.017,2.518.1a.2.2,0,0,1,.193.173l.4,2.485,2.185,1.26a.2.2,0,0,1,.091.24l-.8,2.389,1.349,2.129a.2.2,0,0,1-.031.254l-1.819,1.744.2,2.512a.2.2,0,0,1-.145.211l-2.422.7-.984,2.319a.2.2,0,0,1-.223.121h0l-2.473-.506-1.95,1.6a.2.2,0,0,1-.256,0l-1.95-1.6-2.473.506h0a.2.2,0,0,1-.222-.122v0l-.984-2.32-2.421-.7a.2.2,0,0,1-.146-.211l.2-2.513L2.863,14.075h0a.2.2,0,0,1-.031-.253l1.35-2.129-.8-2.39a.2.2,0,0,1,.091-.239h0L5.655,7.8l.4-2.488a.2.2,0,0,1,.191-.171l2.518-.1,1.511-2.016A.2.2,0,0,1,10.46,2.947Zm-3.8,8.044V14.77h.671V11.978L9.211,14.77H9.97V10.992H9.3v2.792L7.423,10.992Zm4.251,0V14.77h2.834v-.576H11.592V12.956H13.58v-.533H11.592v-.857h2.157v-.575Zm3.306,0,1.03,3.779h.73l.859-2.875.816,2.875h.687l1.074-3.779h-.73l-.731,2.916-.773-2.916h-.73l-.816,2.916-.73-2.916Z'
        transform='translate(-1.887 -2.033)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
export default NewIcon;
