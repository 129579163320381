import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const RobotIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.229' height='18.061' viewBox='0 0 20.229 18.061' {...props}>
      <path
        id='icons8-ai'
        d='M11.114,0a1.444,1.444,0,0,0-.722,2.7v1.64H6.78a2.89,2.89,0,0,0-2.89,2.89V18.061H18.339V7.224a2.89,2.89,0,0,0-2.89-2.89H11.837V2.694A1.443,1.443,0,0,0,11.114,0ZM8.224,7.947A1.445,1.445,0,1,1,6.78,9.392,1.445,1.445,0,0,1,8.224,7.947Zm5.78,0a1.445,1.445,0,1,1-1.445,1.445A1.445,1.445,0,0,1,14,7.947ZM1.711,8.66A.723.723,0,0,0,1,9.392v4.335a.722.722,0,1,0,1.445,0V9.392a.723.723,0,0,0-.734-.732Zm18.784,0a.723.723,0,0,0-.711.732v4.335a.722.722,0,1,0,1.445,0V9.392a.723.723,0,0,0-.734-.732ZM8.224,13.727H14a1.445,1.445,0,0,1,1.445,1.445v1.445H6.78V15.171A1.445,1.445,0,0,1,8.224,13.727Z'
        transform='translate(-1)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
