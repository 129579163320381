import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";

export const BookAnAppointmentIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.common.white } = props;

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='Group_10514'
      data-name='Group 10514'
      width='30.098'
      height='30.098'
      viewBox='0 0 30.098 32.098'
      {...props}
    >
      <g xmlns='http://www.w3.org/2000/svg' id='icons8-home_1_' data-name='icons8-home (1)' transform='translate(0 1.5)'>
        <path
          id='Path_12758'
          data-name='Path 12758'
          d='M27.14,31.8H10.658A4.623,4.623,0,0,1,6,27.14v-6.45a1.075,1.075,0,1,1,2.15,0v6.45a2.481,2.481,0,0,0,2.508,2.508H27.14a2.481,2.481,0,0,0,2.508-2.508V10.658A2.481,2.481,0,0,0,27.14,8.15H20.619a1.075,1.075,0,0,1,0-2.15H27.14A4.623,4.623,0,0,1,31.8,10.658V27.14A4.623,4.623,0,0,1,27.14,31.8Z'
          transform='translate(-1.7 -1.701)'
          fill={fill}
        />
        <path
          id='Line_1486'
          data-name='Line 1486'
          d='M13.764.65H-.425A1.075,1.075,0,0,1-1.5-.425,1.075,1.075,0,0,1-.425-1.5H13.764A1.075,1.075,0,0,1,14.839-.425,1.075,1.075,0,0,1,13.764.65Z'
          transform='translate(15.259 11.532)'
          fill={fill}
        />
        <path
          id='Path_12759'
          data-name='Path 12759'
          d='M8.241,16.482A8.262,8.262,0,0,1,0,8.241a9.309,9.309,0,0,1,.241-2.1,1.075,1.075,0,1,1,2.1.466,7.15,7.15,0,0,0-.189,1.63,6.083,6.083,0,0,0,10.392,4.3A6.091,6.091,0,0,0,8.241,2.15a6.461,6.461,0,0,0-1.913.313A1.075,1.075,0,0,1,5.711.4,8.624,8.624,0,0,1,8.241,0a8.233,8.233,0,0,1,5.821,14.062A8.183,8.183,0,0,1,8.241,16.482Z'
          transform='translate(0 0)'
          fill={fill}
        />
        <path
          id='Path_12760'
          data-name='Path 12760'
          d='M12.508,12.166a1.072,1.072,0,0,1-.76-.315l-1.433-1.433A1.075,1.075,0,0,1,10,9.658V6.075a1.075,1.075,0,1,1,2.15,0V9.213l1.118,1.118a1.075,1.075,0,0,1-.76,1.835Z'
          transform='translate(-2.834 -1.417)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
