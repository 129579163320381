/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyItemQueryVariables = Types.Exact<{
  guidedCareJourneyItemId: Types.Scalars['ID'];
}>;


export type GuidedCareJourneyItemQuery = { __typename?: 'Query', guidedCareJourneyItem?: { __typename?: 'GuidedCareJourneyItem', id: string, note?: string | null, guidedCareJourneyItemSource?: Types.GuidedCareJourneyItemSource | null, guidedCareJourneyItemStatus?: Types.GuidedCareJourneyItemStatus | null, guidedCareJourneyItemType?: Types.GuidedCareActivityType | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, nameAr?: string | null, questions?: Array<{ __typename?: 'Question', id?: string | null, options?: Array<string | null> | null, questionType?: Types.QuestionType | null, question?: string | null, required?: boolean | null }> | null } | null, appointment?: { __typename?: 'Appointment', id: string, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, name?: string | null } | null } | null };


export const GuidedCareJourneyItemDocument = gql`
    query GuidedCareJourneyItem($guidedCareJourneyItemId: ID!) {
  guidedCareJourneyItem(id: $guidedCareJourneyItemId) {
    id
    note
    medicalForm {
      id
      name
      nameAr
      questions {
        id
        options
        questionType
        question
        required
      }
    }
    appointment {
      id
      doctor {
        id
        user {
          id
          fullName
        }
      }
    }
    medicalMessage {
      id
      name
    }
    guidedCareJourneyItemSource
    guidedCareJourneyItemStatus
    guidedCareJourneyItemType
  }
}
    `;

/**
 * __useGuidedCareJourneyItemQuery__
 *
 * To run a query within a React component, call `useGuidedCareJourneyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareJourneyItemQuery({
 *   variables: {
 *      guidedCareJourneyItemId: // value for 'guidedCareJourneyItemId'
 *   },
 * });
 */
export function useGuidedCareJourneyItemQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareJourneyItemQuery, GuidedCareJourneyItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareJourneyItemQuery, GuidedCareJourneyItemQueryVariables>(GuidedCareJourneyItemDocument, options);
      }
export function useGuidedCareJourneyItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareJourneyItemQuery, GuidedCareJourneyItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareJourneyItemQuery, GuidedCareJourneyItemQueryVariables>(GuidedCareJourneyItemDocument, options);
        }
export type GuidedCareJourneyItemQueryHookResult = ReturnType<typeof useGuidedCareJourneyItemQuery>;
export type GuidedCareJourneyItemLazyQueryHookResult = ReturnType<typeof useGuidedCareJourneyItemLazyQuery>;
export type GuidedCareJourneyItemQueryResult = Apollo.QueryResult<GuidedCareJourneyItemQuery, GuidedCareJourneyItemQueryVariables>;