import { IconsExplorer } from "@toolkit/ui";
import * as AllIcons from "@/components/Icons";

export const PlaygroundIconsPage = () => {
  return (
    <>
      <IconsExplorer icons={AllIcons} />
    </>
  );
};
