import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const PharmacyIconHealth: FC<IconProps> = ({ width = "18.42", height = "24", viewBox = "0 0 18.42 24", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-pharmacy_3_'
        data-name='icons8-pharmacy (3)'
        d='M23.919,14H21.751a1,1,0,0,0-.878.521l-2.067,3.786h-.171l-3.477-6.583A4.261,4.261,0,0,0,17.586,7.7c0-2.941-1.994-4.7-5.32-4.7H7A1,1,0,0,0,6,4V16a1,1,0,0,0,1,1H8.622a1,1,0,0,0,1-1V12.257h2.143l4.663,8.185-3.529,5.8a.5.5,0,0,0,.428.759h2.06a1,1,0,0,0,.856-.483l2.195-3.634h.171l2.177,3.631a1,1,0,0,0,.857.486H23.89a.5.5,0,0,0,.423-.767L20.68,20.477l3.66-5.708A.5.5,0,0,0,23.919,14ZM9.622,9.7V5.734h2.132a1.936,1.936,0,0,1,2.132,1.993A1.884,1.884,0,0,1,11.764,9.7Z'
        transform='translate(-6 -3)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
