/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnReadMessagesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnReadMessagesCountQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, unReadMessagesCount?: number | null } | null };


export const UnReadMessagesCountDocument = gql`
    query unReadMessagesCount {
  me {
    id
    unReadMessagesCount
  }
}
    `;

/**
 * __useUnReadMessagesCountQuery__
 *
 * To run a query within a React component, call `useUnReadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnReadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnReadMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnReadMessagesCountQuery(baseOptions?: Apollo.QueryHookOptions<UnReadMessagesCountQuery, UnReadMessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnReadMessagesCountQuery, UnReadMessagesCountQueryVariables>(UnReadMessagesCountDocument, options);
      }
export function useUnReadMessagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnReadMessagesCountQuery, UnReadMessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnReadMessagesCountQuery, UnReadMessagesCountQueryVariables>(UnReadMessagesCountDocument, options);
        }
export type UnReadMessagesCountQueryHookResult = ReturnType<typeof useUnReadMessagesCountQuery>;
export type UnReadMessagesCountLazyQueryHookResult = ReturnType<typeof useUnReadMessagesCountLazyQuery>;
export type UnReadMessagesCountQueryResult = Apollo.QueryResult<UnReadMessagesCountQuery, UnReadMessagesCountQueryVariables>;