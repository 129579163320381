import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const RedCircleIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "13.5px"}
      height={height ? height : "13.5px"}
      viewBox={viewBox ? viewBox : "0 0 13.5 13.5"}
      {...props}
    >
      <circle id='Ellipse_2593' data-name='Ellipse 2593' cx='6.75' cy='6.75' r='6.75' fill={fill ? fill : palette.error.main} />
    </SvgIcon>
  );
};
