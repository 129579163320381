import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const LastStepIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24.447px"}
      height={height ? height : "24.447px"}
      viewBox={viewBox ? viewBox : "0 0 24.447 24.447"}
      {...props}
    >
      <circle id='Ellipse_2628' data-name='Ellipse 2628' cx='12.223' cy='12.223' r='12.223' fill={palette.primary.main} />
    </SvgIcon>
  );
};
