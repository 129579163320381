import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const RegisterIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "17.111"}
      height={height ? height : "23.1"}
      viewBox={viewBox ? viewBox : "0 0 17.111 23.1"}
      {...props}
    >
      <path
        id='icons8-task'
        d='M13.555,0a2.58,2.58,0,0,0-2.567,2.567H7.567A2.58,2.58,0,0,0,5,5.133v15.4A2.58,2.58,0,0,0,7.567,23.1H19.544a2.58,2.58,0,0,0,2.567-2.567V5.133a2.58,2.58,0,0,0-2.567-2.567H16.122A2.58,2.58,0,0,0,13.555,0Zm0,1.711a.856.856,0,1,1-.856.856A.843.843,0,0,1,13.555,1.711ZM7.567,4.278H9.278a1.711,1.711,0,0,0,1.711,1.711h5.133a1.711,1.711,0,0,0,1.711-1.711h1.711a.842.842,0,0,1,.856.856v15.4a.842.842,0,0,1-.856.856H7.567a.842.842,0,0,1-.856-.856V5.133A.842.842,0,0,1,7.567,4.278Zm9.424,4.278a1.067,1.067,0,0,0-.755.314l-.755.755,2.139,2.139.755-.755a1.067,1.067,0,0,0,0-1.511l-.63-.63A1.063,1.063,0,0,0,16.991,8.555Zm-2.58,2.139-4.8,4.8.68.389.267.8.8.267.389.68,4.8-4.8ZM8.9,17.159l-.456,1.293a.266.266,0,0,0,.343.351h0l1.3-.458Z'
        transform='translate(-5)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
