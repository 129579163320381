import { Avatar, Box, Button } from "@toolkit/ui";
import { ArrowIcon } from "@/components/Icons";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import useStyles from "./SignIn.styles";

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const onClick = () => {
    navigate(routesPaths.login);
  };

  return (
    <Box className={classes.container}>
      <Avatar className={classes.avatar} />
      <Button
        onClick={onClick}
        className={classes.button}
        endIcon={
          <Box className={classes.buttonIcon}>
            <ArrowIcon />
          </Box>
        }
      >
        Sign In
      </Button>
    </Box>
  );
};
