import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Apple: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='41.553' height='48.003' viewBox='0 0 41.553 48.003' {...props}>
      <path
        id='icons8-apple-logo_1_'
        d='M33.375,0a11.682,11.682,0,0,0-7.75,4.156,10.265,10.265,0,0,0-2.5,8c-.469-.145-.895-.16-1.406-.344a14.238,14.238,0,0,0-4.969-1.031c-3.934,0-7.961,2.34-10.5,6.25C2.555,22.711,3.3,32.707,8.906,41.25a23.4,23.4,0,0,0,3.563,4.438A7.8,7.8,0,0,0,17.625,48a8.918,8.918,0,0,0,4-1.031,8.658,8.658,0,0,1,3.969-.906h.031a8.078,8.078,0,0,1,3.906.875A8.712,8.712,0,0,0,33.5,48a7.822,7.822,0,0,0,5.25-2.531,26.592,26.592,0,0,0,3.625-4.594,34.81,34.81,0,0,0,3.063-5.719.983.983,0,0,0,.008-.793,1,1,0,0,0-.57-.551A9.094,9.094,0,0,1,39,26a8.77,8.77,0,0,1,4.656-8.687,1,1,0,0,0,.313-1.5,12.7,12.7,0,0,0-9.625-5.031,14.1,14.1,0,0,0-5.219,1.031c-.223.086-.387.078-.594.156A10.789,10.789,0,0,0,32,9.094,11.062,11.062,0,0,0,34.438.844,1,1,0,0,0,33.375,0ZM32.313,2.375a9.525,9.525,0,0,1-1.906,5.469,8.492,8.492,0,0,1-5.187,2.938,9.023,9.023,0,0,1,1.969-5.375A10.125,10.125,0,0,1,32.313,2.375ZM16.75,12.781a12.2,12.2,0,0,1,4.281.906,13.188,13.188,0,0,0,4.531,1.063,11.658,11.658,0,0,0,4.281-1.062,11.771,11.771,0,0,1,4.5-.906,10.487,10.487,0,0,1,7.344,3.563A10.816,10.816,0,0,0,37,26.156a11.192,11.192,0,0,0,6.188,9,30.218,30.218,0,0,1-2.469,4.625,25.089,25.089,0,0,1-3.344,4.25A5.984,5.984,0,0,1,33.469,46a6.556,6.556,0,0,1-3.125-.875,10.442,10.442,0,0,0-4.75-1.062,11.215,11.215,0,0,0-4.781,1.063A6.793,6.793,0,0,1,17.656,46a5.828,5.828,0,0,1-3.844-1.781,21.917,21.917,0,0,1-3.25-4.062c-5.25-8-5.672-17.4-2.656-22.031C10.117,14.723,13.629,12.781,16.75,12.781Z'
        transform='translate(-3.972 0.003)'
        fill={palette.common.white}
        className='appleIcon'
      />
    </SvgIcon>
  );
};
