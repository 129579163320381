import { FC } from "react";
import { Box, Skeleton } from "@toolkit/ui";

export const NotificationCardSkeleton: FC = () => {
  return (
    <Box display='flex' flexDirection='row' gap='13px' alignItems='start' width='100%'>
      <Box marginTop='8px'>
        <Skeleton variant='circular' width={50} height={50} />
      </Box>

      <Box flex={1} display='flex' flexDirection='column' gap='8px'>
        <Box display='flex' justifyContent='space-between' alignItems='start' width='100%'>
          <Skeleton variant='text' width='70%' height={24} />
          <Skeleton
            sx={{
              marginTop: "2px",
            }}
            variant='text'
            width='20%'
            height={16}
          />
        </Box>

        <Skeleton variant='text' width='90%' height={20} />
        <Skeleton variant='text' width='90%' height={20} />
      </Box>
    </Box>
  );
};
