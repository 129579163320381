import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SehatiIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const theme = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "338.99"}
      height={height ? height : "188"}
      viewBox={viewBox ? viewBox : "0 0 338.99 188"}
      {...props}
    >
      <g id='Group_8914' data-name='Group 8914' transform='translate(-1197.01 -2575)'>
        <path
          id='Path_11619'
          data-name='Path 11619'
          d='M-18337.793-3018.62l-1.1-8.969-6.926-11.83-9.352-6.686,5.906-14.786,32.154-4.776,16.322,17.3,43.24-7.582,10.129-15.146-23.959-9.128,22.375-13.518h25l8.744,11.982,13.588,6.761,30.422-1.276,25.389-14.595,6.482-2.872s11.127-1.815,11.127,4.576v85.707Z'
          transform='translate(19664.248 5735.363)'
          fill={theme.palette.common.white}
        />
        <path
          id='Path_11618'
          data-name='Path 11618'
          d='M-18417.309-2991h-120.225l28.643,22.988h56.5Z'
          transform='translate(19740.512 5698.107)'
          fill={theme.palette.gray.light}
        />
        <g id='Group_8899' data-name='Group 8899' transform='translate(1197.01 2575)'>
          <g id='Group_8908' data-name='Group 8908' transform='translate(0)'>
            <path
              id='Path_11599'
              data-name='Path 11599'
              d='M135.467,97.649c.278-4.176-4.873-7.657-15.453-10.163-1.253-.278-5.012-1.253-4.873-3.9,0-.835.557-1.671,1.671-2.645l-14.618.418a15.437,15.437,0,0,0-3.48,8.631c-.418,4.594,3.063,7.518,10.441,9.188,3.2.557,6.4,1.253,9.467,1.81,1.531.278,5.847,1.253,5.569,4.176-.139,1.114-.7,2.367-8.074,6.125a91.357,91.357,0,0,1-21.578,7.657,140.283,140.283,0,0,1-34.664,3.341c-16.567-.557-29.792-4.176-39.537-10.58C12.68,106.559,9.06,101.686,9.2,96.814c.139-5.847,6.822-11,20.325-15.592a151.573,151.573,0,0,1,37.31-8.214c14.478-1.114,18.1-2.645,19.072-3.063-1.114-.7-5.151-1.949-20.186-2.506-2.645-.139-5.012-.139-7.239-.139A107.013,107.013,0,0,0,45.4,68a78.285,78.285,0,0,0-25.2,8.074A35.967,35.967,0,0,0,3.77,93.055,38.133,38.133,0,0,0,.011,108.368c-.278,7.1,4.455,13.782,13.921,19.908,10.023,6.543,22.274,10.163,36.474,10.58,22,.7,41.625-3.2,58.609-11.416,16.567-8.074,25.476-18.1,26.451-29.792'
              transform='translate(0 26.392)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11600'
              data-name='Path 11600'
              d='M74.389,134.559c.139-2.088-.278-2.923-.557-3.341a1.037,1.037,0,0,0-.975-.418h-.139a6.752,6.752,0,0,0-2.784.835,72.483,72.483,0,0,1-21.021,6.543c-2.506.418-4.873.7-7.378.974-1.114.278-1.253.835-1.114,1.531,0,.278.418,1.671,4.733,3.759a26.707,26.707,0,0,0,8.214,2.923c7.378,1.114,13.086.278,16.845-2.506,2.367-2.088,3.9-5.429,4.176-10.3'
              transform='translate(15.834 40.156)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11601'
              data-name='Path 11601'
              d='M141.023,79.743a9.623,9.623,0,0,1,.557-2.506c-3.48-.418-8.074-1.81-9.606-3.48-1.114-1.114-1.949-3.341-2.227-6.543-.7-5.012-3.063-7.518-7.8-7.8-6.265-.418-13.782,1.253-22.135,5.151-9.467,4.316-19.49,11.137-27.008,21.021l3.9,8.91A58.952,58.952,0,0,1,96.2,80.021c7.935-3.759,13.643-5.29,17.541-4.873,4.873.557,7.8,3.2,9.049,8.214,1.531,6.543,9.188,7.518,9.467,7.518a7.213,7.213,0,0,0,6.265-3.341,15.654,15.654,0,0,0,2.506-7.8'
              transform='translate(28.552 23.276)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11602'
              data-name='Path 11602'
              d='M125.016,34.959A4.884,4.884,0,0,0,124.6,31.9a1.305,1.305,0,0,0-1.253-.7h-.139a6.753,6.753,0,0,0-2.784.835A72.48,72.48,0,0,1,99.4,38.578c-2.506.278-4.873.557-7.239.975-1.253.278-1.253.835-1.253,1.531,0,.139.278,1.392,4.733,3.759a25.353,25.353,0,0,0,8.074,2.784c7.378,1.114,13.086.278,16.984-2.506,2.506-1.949,3.9-5.29,4.316-10.163'
              transform='translate(35.65 12.235)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11603'
              data-name='Path 11603'
              d='M116.041,89.017h0c.557,1.81-1.671,2.924-1.531,4.176,4.594,1.531,17.541,1.671,24.919.835a64.81,64.81,0,0,0,24.919-7.657,47.473,47.473,0,0,0,10.58-8.353q4.385-4.8,5.012-8.353c.7-3.62-1.253-6.4-6.125-8.492-4.037-1.392-8.353-2.784-12.529-4.176l-11.833-3.9c-4.176,3.62-6.682,7.1-7.239,10.3-.139,1.114,0,2.784,3.62,4.037l13.5,4.037c2.645.835,2.924,2.227,2.784,3.2a3.494,3.494,0,0,1-2.227,2.506c-3.62,1.949-9.884,3.063-19.351,3.2a112.088,112.088,0,0,1-20.6-1.531c-1.81-.278-3.62,1.531-4.872,3.2-.7.974.278,4.733.974,6.961'
              transform='translate(44.903 20.823)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11604'
              data-name='Path 11604'
              d='M142.392,70.3c5.29-5.151,12.251-9.049,20.6-11.694a47.714,47.714,0,0,1,13.921-2.645,14.367,14.367,0,0,1,3.62.418c5.29,1.253,8.074,4.594,8.074,9.327q0,8.771,16.288,11.694c5.151.975,13.086.7,23.527-.557,12.251-1.531,21.857-4.316,28.4-8.074,9.188-5.29,14.618-12.112,16.149-20.325a11.629,11.629,0,0,0-2.645-9.327c-2.367-3.063-5.151-5.012-8.492-5.569q-7.935-1.462-22.97,8.771c-5.012,3.48-13.086,9.884-23.806,19.072l-.418.278h-.557a74.4,74.4,0,0,1-7.657-.418c-5.847-1.114-9.049-4.873-9.049-11,0-5.151-2.227-7.8-6.961-8.631-6.265-1.114-13.922-.278-22.553,2.506-9.884,3.2-20.325,9.606-28.261,16.706Zm82.972-9.467c12.39-10.58,21.161-15.174,26.868-14.2a9.026,9.026,0,0,1,5.429,3.341c1.531,1.949,2.227,3.759,1.949,5.429-.278,1.253-1.253,2.645-11.973,5.429a71.282,71.282,0,0,1-18.376,2.645,21.554,21.554,0,0,1-3.063-.139l-3.48-.278Z'
              transform='translate(54.747 13.086)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11605'
              data-name='Path 11605'
              d='M160.112,0c-.278.139-1.392.975-2.784,1.949l-1.949,1.392c-.975.7-1.81,1.253-2.506,1.81-1.81,1.253-2.367,1.671-2.645,1.81a104.779,104.779,0,0,1-12.947,5.29c-4.037,1.392-4.872,2.088-4.872,2.506a.511.511,0,0,0,.139.418c.7.7,3.48.7,7.935,0a30.317,30.317,0,0,0,14.2-5.708c3.2-2.645,5.151-5.012,5.847-7.518.418-1.114.278-1.531.139-1.671,0-.139-.139-.278-.557-.278'
              transform='translate(51.924)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11606'
              data-name='Path 11606'
              d='M157.171,26.89a10.808,10.808,0,0,0,3.063-8.074c0-2.645-.557-3.62-.974-4.037a1.493,1.493,0,0,0-.835-.278c-.139,0-.7.139-1.81,3.341-1.114,2.645-2.227,3.9-3.48,3.9-.974,0-1.81-.975-2.367-3.2-.557-2.367-1.114-2.645-1.392-2.645-.139,0-.835.7-2.784,4.176-1.531,2.924-3.62,4.455-5.986,4.455-1.531,0-2.227-1.253-2.506-3.62-.139-3.48-.7-3.759-.835-3.759-.557,0-1.81,2.088-2.506,3.341a16.024,16.024,0,0,0-1.949,6.682c0,3.2,1.949,4.873,5.986,4.873,2.923,0,5.569-1.253,8.214-3.759l.278-.278.278.278a3.638,3.638,0,0,0,3.2,1.253,8.012,8.012,0,0,0,6.4-2.645'
              transform='translate(52.081 5.686)'
              fill={theme.palette.primary.main}
            />
            <path
              id='Path_11607'
              data-name='Path 11607'
              d='M230.2,75.845c-1.531,2.227-4.316,4.037-7.935,5.569a29.78,29.78,0,0,1-15.453,1.531c-5.569-.835-8.214-1.671-7.8-3.063.139-.7,2.088-.974,5.708-1.253a129.82,129.82,0,0,0,13.921-1.392c.278,0,2.227-.835,5.847-2.088S230.065,73.2,230.2,73.2c.974.418.974,1.114,0,2.645'
              transform='translate(78.029 28.705)'
              fill={theme.palette.primary.main}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
