import React from "react";
import { useTheme } from "../../base/mui";
export const RotationIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25.658' height='25.658' viewBox='0 0 25.658 25.658'>
      <path
        id='icons8-update-left-rotation_1_'
        data-name='icons8-update-left-rotation (1)'
        d='M17.829,5A12.813,12.813,0,0,0,7.566,10.138V6.283a.642.642,0,1,0-1.283,0V12.7H12.7a.641.641,0,1,0,0-1.283H8.235a11.542,11.542,0,0,1,21.14,6.414.641.641,0,1,0,1.283,0A12.839,12.839,0,0,0,17.829,5ZM5.631,17.179a.642.642,0,0,0-.631.65,12.825,12.825,0,0,0,23.092,7.689v3.857a.641.641,0,1,0,1.283,0V22.961h-.682a.641.641,0,0,0-.239,0H22.961a.641.641,0,1,0,0,1.283h4.463a11.542,11.542,0,0,1-21.14-6.414.642.642,0,0,0-.651-.65Z'
        transform='translate(-5 -5)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
