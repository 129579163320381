import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const DoctorRegisterIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='doctor_register'
      data-name='doctor register'
      width='132'
      height='131.411'
      viewBox='0 0 132 131.411'
      {...props}
    >
      <defs>
        <linearGradient
          id='doctor-register-linear-gradient'
          x1='-0.002'
          y1='-75.025'
          x2='0.999'
          y2='-75.025'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stop-color='#cbcfdd' />
          <stop offset='1' stop-color='#eef0f1' />
        </linearGradient>
      </defs>
      <g id='Group_11341' data-name='Group 11341' transform='translate(0 0)'>
        <g id='Group_11340' data-name='Group 11340' transform='translate(0 0)'>
          <path
            id='Path_12746_00000121959265908568702120000003119399686751359141_'
            d='M131.512,195c-15.248-3.449-29.014-8.895-41.69-18.576-12.555-9.651-24.082-23.447-24.536-37.576-.3-14.1,10.377-28.469,22.025-39.754a116.5,116.5,0,0,1,38.967-25.777c15.006-6.2,32.947-10.256,45.2-3.752,12.192,6.565,18.7,23.689,22.358,39.542,3.721,15.823,4.568,30.375,1.846,45.5s-9.046,30.708-20.6,37.818c-11.557,7.2-28.288,5.869-43.566,2.572'
            transform='translate(-65.279 -66.252)'
            fill='#283275'
            opacity='0.09'
          />
          <g id='Group_11339' data-name='Group 11339' transform='translate(14.014 6.912)'>
            <path
              id='Path_13198'
              data-name='Path 13198'
              d='M238.822,129.031v81.626a4.939,4.939,0,0,1-4.962,4.931H147.031a4.932,4.932,0,0,1-4.931-4.931V129.031a4.932,4.932,0,0,1,4.931-4.931h86.829a4.92,4.92,0,0,1,4.962,4.931Z'
              transform='translate(-132.872 -113.51)'
              fill='#fff'
            />
            <g id='Group_11336' data-name='Group 11336' transform='translate(23.78 27.259)'>
              <g id='Group_11329' data-name='Group 11329' transform='translate(0 3.207)'>
                <g id='Pharmacist_00000051367545359556890040000010729692836252341913_'>
                  <path
                    id='Path_13199'
                    data-name='Path 13199'
                    d='M255.452,324.843v17.305H192.1V324.843a13.064,13.064,0,0,1,6.051-11.013c1.3-.817.908-.575,16.731-7.231h17.759l14.492,6.081A13.1,13.1,0,0,1,255.452,324.843Z'
                    transform='translate(-191.525 -271.263)'
                    fill='#fff'
                  />
                  <path
                    id='Path_13200'
                    data-name='Path 13200'
                    d='M328.765,307.689c-2.451-1.029-2.6-1.089-2.572-1.089Z'
                    transform='translate(-285.047 -271.263)'
                    fill='url(#doctor-register-linear-gradient)'
                  />
                  <path
                    id='Path_13201'
                    data-name='Path 13201'
                    d='M267.4,306.669a7.76,7.76,0,0,0,7.745,7.745h2.269a7.76,7.76,0,0,0,7.745-7.745h0C285.189,306.639,285.583,306.669,267.4,306.669Z'
                    transform='translate(-244.044 -271.302)'
                    fill='#dee1ec'
                    stroke='#d3d9ed'
                    stroke-width='1'
                  />
                  <path
                    id='Path_13202'
                    data-name='Path 13202'
                    d='M191.389,340.933H190.3V323.658a13.678,13.678,0,0,1,8.622-12.646L213.5,304.9h8.864v1.089h-8.622l-14.4,6.051a12.586,12.586,0,0,0-7.957,11.648v17.245Z'
                    transform='translate(-190.27 -270.078)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13203'
                    data-name='Path 13203'
                    d='M334.747,309.792l-.847-.363.212-.514c-.03-.03-.333-.121,0,0l.212-.514.847.333Z'
                    transform='translate(-290.425 -272.519)'
                    fill='#5f6276'
                  />
                  <path
                    id='Path_13204'
                    data-name='Path 13204'
                    d='M243.059,306.7v35.488h-2.814l-4.205-12.253H232.5l5.748-21.238Z'
                    transform='translate(-219.703 -271.333)'
                    fill='#d3d9ed'
                  />
                  <path
                    id='Path_13205'
                    data-name='Path 13205'
                    d='M326.2,306.7v35.488h2.814l4.175-12.253h3.54L331.01,308.7Z'
                    transform='translate(-285.054 -271.333)'
                    fill='#d3d9ed'
                  />
                  <path
                    id='Path_13206'
                    data-name='Path 13206'
                    d='M238.127,341.114l-4.054-11.89H230.2l5.99-22.176,6.384-2.148L242,340.933H240.91V306.261l-3.812,1.6-5.476,20.3h3.207l4.326,12.616Z'
                    transform='translate(-218.098 -270.078)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13207'
                    data-name='Path 13207'
                    d='M328.273,341.114l-1.029-.333,4.326-12.616h3.207l-5.476-20.3-3.842-1.6v34.671H324.4l.635-36.033,5.174,2.148,5.99,22.176h-3.872Z'
                    transform='translate(-283.799 -270.078)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13208'
                    data-name='Path 13208'
                    d='M267.4,306.7a7.76,7.76,0,0,0,7.745,7.745h2.269a7.76,7.76,0,0,0,7.745-7.745h0v35.518H267.4Z'
                    transform='translate(-244.044 -271.333)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13209'
                    data-name='Path 13209'
                    d='M284.448,342.188h-1.089v-31.4a8.365,8.365,0,0,1-7.2,4.205H273.89a8.3,8.3,0,0,1-7.2-4.205v31.434H265.6V306.7h1.089a7.218,7.218,0,0,0,7.2,7.2h2.269a7.218,7.218,0,0,0,7.2-7.2h1.089Z'
                    transform='translate(-242.788 -271.333)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13210'
                    data-name='Path 13210'
                    d='M392.075,326.474h-1.513v-1.513h1.513Zm0-4.024h-1.513c0-.484,0-.908-.03-1.482l1.513-.06c.03.605.03,1.029.03,1.543Zm-1.785-3.9c-.061-.484-.151-.968-.242-1.452l1.482-.3a12.63,12.63,0,0,1,.272,1.543Zm-.877-3.782c-.151-.454-.333-.938-.514-1.361l1.392-.575a12.386,12.386,0,0,1,.545,1.482Zm-1.543-3.57c-.242-.424-.484-.847-.756-1.24l1.271-.817c.272.424.545.877.817,1.331Zm-2.209-3.207c-.3-.363-.635-.726-.968-1.089l1.089-1.059a13.32,13.32,0,0,1,1.059,1.18Zm-2.753-2.723c-.363-.3-.756-.605-1.15-.908l.877-1.24c.424.3.847.635,1.24.968Zm-3.237-2.209a12.443,12.443,0,0,0-1.271-.635l.545-1.422q.726.318,1.452.726Z'
                    transform='translate(-321.462 -267.357)'
                    fill='#aab5d3'
                  />
                  <path
                    id='Path_13211'
                    data-name='Path 13211'
                    d='M254.641,340.863h-1.089V323.558a12.635,12.635,0,0,0-7.927-11.648l-14.4-6.051H213.647l-14.4,6.051a12.617,12.617,0,0,0-7.957,11.648v17.305H190.2V323.558a13.558,13.558,0,0,1,8.622-12.646l15.793-6.111h16.761l14.583,6.111a13.558,13.558,0,0,1,8.622,12.646v17.305Z'
                    transform='translate(-190.2 -270.008)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13212'
                    data-name='Path 13212'
                    d='M230.057,193.915a2.035,2.035,0,1,1,1.119-.3A2.382,2.382,0,0,1,230.057,193.915Zm0-3.086a1,1,0,1,0,.968,1.24.978.978,0,0,0-.756-1.18c-.061-.03-.151-.03-.212-.061Z'
                    transform='translate(-216.564 -189.8)'
                    fill='#535783'
                  />
                  <path
                    id='Path_13213'
                    data-name='Path 13213'
                    d='M200.4,229.707a1.6,1.6,0,1,1,0-3.207,1.614,1.614,0,0,1,.877,2.965A1.919,1.919,0,0,1,200.4,229.707Zm0-2.118a.51.51,0,0,0-.514.514.53.53,0,0,0,.514.514.554.554,0,0,0,.514-.393.563.563,0,0,0-.393-.635Z'
                    transform='translate(-196.198 -215.397)'
                    fill='#535783'
                  />
                </g>
              </g>
              <g id='Group_11332' data-name='Group 11332' transform='translate(15.092 36.531)'>
                <g id='Group_11330' data-name='Group 11330' transform='translate(20.196)'>
                  <path
                    id='Path_13214'
                    data-name='Path 13214'
                    d='M317.082,323.321a1.3,1.3,0,0,0,.393-.938c.7-7.9,4.357-19.6-8.8-22.418-.787-.091-.787.242-1.271.363-.242.06-.212.212-.333.3a.585.585,0,0,0-.212.454c.06.242-.182.605.212.756s.545,0,1.24.121c11.073,1.936,7.17,16.791,6.928,19.635s-.061.726-.061.756c-.121,1.029.182.938.605,1.3.121.121.212.091.272.121s.061-.03.091-.03h.121C316.628,323.653,316.84,323.653,317.082,323.321Z'
                    transform='translate(-306.837 -299.949)'
                    fill='#283275'
                  />
                </g>
                <g id='Group_11331' data-name='Group 11331' transform='translate(1.442 0.05)'>
                  <path
                    id='Path_13215'
                    data-name='Path 13215'
                    d='M246.62,323.738c-.242-.333-.363-.333-.424-.908-.968-7.866-4.992-19.453,8.048-22.691.787-.121.787.212,1.271.333.242.061.212.182.333.3a.769.769,0,0,1,.242.424c-.03.242.182.605-.212.756s-.545,0-1.24.151c-10.982,2.3-6.626,17-6.293,19.847.333,2.814.091.726.091.756.151,1.029-.151.938-.545,1.331a.339.339,0,0,1-.272.121c-.06.03-.06-.03-.091-.03h-.121C247.074,324.071,246.862,324.071,246.62,323.738Z'
                    transform='translate(-244.85 -300.114)'
                    fill='#283275'
                  />
                </g>
                <path
                  id='Path_13216'
                  data-name='Path 13216'
                  d='M320.287,385.212a1.091,1.091,0,0,0,.817.363l1.6.121a1.184,1.184,0,0,0,1.24-1.059l.3-4.024a8.471,8.471,0,0,0-1.936-6.172,8.171,8.171,0,0,0-3.54-2.42,10.492,10.492,0,0,0-2.239-.484,8.468,8.468,0,0,0-6.172,2,8.208,8.208,0,0,0-2.874,5.718l-.3,4.024a1.17,1.17,0,0,0,.242.877,1.091,1.091,0,0,0,.817.363l1.6.121a1.153,1.153,0,0,0,.182-2.3l-.454-.03.212-2.874a6.013,6.013,0,0,1,2.148-4.145,5.943,5.943,0,0,1,4.417-1.452,6.013,6.013,0,0,1,4.145,2.148,5.943,5.943,0,0,1,1.452,4.417l-.212,2.874-.454-.03a1.17,1.17,0,0,0-.877.242,1.091,1.091,0,0,0-.363.817A1.207,1.207,0,0,0,320.287,385.212Z'
                  transform='translate(-286.881 -349.859)'
                  fill='#283275'
                />
                <path
                  id='Path_13217'
                  data-name='Path 13217'
                  d='M244.718,374.083a4.877,4.877,0,1,0,5.113,4.629A4.954,4.954,0,0,0,244.718,374.083Zm.363,7.412a2.544,2.544,0,1,1-.242-5.083,2.709,2.709,0,0,1,2.662,2.36v.061A2.577,2.577,0,0,1,245.081,381.495Z'
                  transform='translate(-240.084 -351.649)'
                  fill='#283275'
                />
                <circle
                  id='Ellipse_3055'
                  data-name='Ellipse 3055'
                  cx='2.541'
                  cy='2.541'
                  r='2.541'
                  transform='translate(2.335 24.763)'
                  fill='#d3d9ed'
                />
              </g>
              <g id='Group_11335' data-name='Group 11335' transform='translate(15.097)'>
                <g id='Group_11334' data-name='Group 11334' transform='translate(8.138 30.466)'>
                  <g id='Group_11333' data-name='Group 11333'>
                    <path
                      id='Path_13218'
                      data-name='Path 13218'
                      d='M273.686,315.8h4.508a5.009,5.009,0,0,0,4.629-3.086L285,307.485s-.121-1.513-4.689-1.785l-8.834.484A9.52,9.52,0,0,0,267,307.213l1.936,5.294A5.1,5.1,0,0,0,273.686,315.8Z'
                      transform='translate(-267 -297.895)'
                      fill='#fff'
                    />
                    <rect
                      id='Rectangle_7333'
                      data-name='Rectangle 7333'
                      width='8.955'
                      height='13.403'
                      transform='translate(4.296)'
                      fill='#fff'
                    />
                    <line
                      id='Line_1786'
                      data-name='Line 1786'
                      y2='13.403'
                      transform='translate(4.296)'
                      fill='none'
                      stroke='#535783'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='4'
                    />
                    <line
                      id='Line_1787'
                      data-name='Line 1787'
                      y2='13.403'
                      transform='translate(13.251)'
                      fill='none'
                      stroke='#535783'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='4'
                    />
                  </g>
                </g>
                <path
                  id='Path_13219'
                  data-name='Path 13219'
                  d='M265.124,225.054A13.519,13.519,0,0,1,251.6,211.531v-6.807a13.524,13.524,0,0,1,27.047,0v6.807a13.5,13.5,0,0,1-13.524,13.524Z'
                  transform='translate(-248.121 -187.569)'
                  fill='#fff'
                />
                <path
                  id='Path_13220'
                  data-name='Path 13220'
                  d='M244.835,236h-.575a2.943,2.943,0,0,0-2.36,2.844v1.906a2.864,2.864,0,0,0,.847,2.027l.908.908a2.61,2.61,0,0,0,1.18.7C244.8,243.715,244.835,237.119,244.835,236Z'
                  transform='translate(-241.355 -218.816)'
                  fill='#fff'
                />
                <path
                  id='Path_13221'
                  data-name='Path 13221'
                  d='M244.184,243.951a4.586,4.586,0,0,1-2.178-1.059l-.908-.908a3.435,3.435,0,0,1-1-2.42v-1.906a3.434,3.434,0,0,1,2.783-3.358h1.21C244.124,243.739,244.094,242.408,244.184,243.951Zm-1.15-8.592a2.365,2.365,0,0,0-1.845,2.3v1.906a2.242,2.242,0,0,0,.7,1.634,15.406,15.406,0,0,0,1.15,1.119Z'
                  transform='translate(-240.1 -217.63)'
                  fill='#535783'
                />
                <path
                  id='Path_13222'
                  data-name='Path 13222'
                  d='M341.1,236h.575a2.943,2.943,0,0,1,2.36,2.844v1.906a2.864,2.864,0,0,1-.847,2.027l-.908.908a2.61,2.61,0,0,1-1.18.7C341.13,243.715,341.1,237.119,341.1,236Z'
                  transform='translate(-310.543 -218.816)'
                  fill='#fff'
                />
                <path
                  id='Path_13223'
                  data-name='Path 13223'
                  d='M339,243.881c.121-1.573.091-.03.091-9.681h1.18a3.459,3.459,0,0,1,2.844,3.358v1.906a3.436,3.436,0,0,1-1,2.42C340.6,243.367,340.755,243.367,339,243.881Zm1.18-1.634c.182-.121.151-.151,1.15-1.119a2.383,2.383,0,0,0,.7-1.664v-1.906a2.359,2.359,0,0,0-1.846-2.3Z'
                  transform='translate(-309.079 -217.56)'
                  fill='#535783'
                />
                <path
                  id='Path_13224'
                  data-name='Path 13224'
                  d='M263.868,224.344A14.061,14.061,0,0,1,249.8,210.275v-6.807a14.068,14.068,0,0,1,28.136,0v6.807A14.1,14.1,0,0,1,263.868,224.344Zm0-33.885a12.976,12.976,0,0,0-12.979,12.979v6.807a12.979,12.979,0,0,0,25.958,0v-6.777a12.964,12.964,0,0,0-12.979-13.009Z'
                  transform='translate(-246.865 -186.314)'
                  fill='#535783'
                />
                <path
                  id='Path_13225'
                  data-name='Path 13225'
                  d='M253.989,193.183l2.844,2.844a4.374,4.374,0,0,0,3.086,1.271h16.428v-7.443a8.954,8.954,0,0,0-8.955-8.955H256.167a4.229,4.229,0,0,0-4.236,4.236h-.7A4.229,4.229,0,0,0,247,189.371h0v8.138h2.693a4.281,4.281,0,0,0,4.3-4.326Z'
                  transform='translate(-244.913 -180.386)'
                  fill='#283275'
                />
                <path
                  id='Path_13226'
                  data-name='Path 13226'
                  d='M248.268,196.9H245v-8.683a4.79,4.79,0,0,1,4.78-4.78h.212a4.779,4.779,0,0,1,4.75-4.236h11.224a9.516,9.516,0,0,1,9.5,9.5v7.987H258.493a4.876,4.876,0,0,1-3.449-1.422l-2.057-2.057A4.894,4.894,0,0,1,248.268,196.9Zm-2.178-1.089h2.178A3.8,3.8,0,0,0,252.08,192v-1.3l3.782,3.782a3.8,3.8,0,0,0,2.693,1.119h15.883v-6.9a8.412,8.412,0,0,0-8.411-8.411H254.8a3.686,3.686,0,0,0-3.691,3.691v.545h-1.24a3.674,3.674,0,0,0-3.691,3.721Z'
                  transform='translate(-243.518 -179.2)'
                  fill='#283275'
                />
              </g>
            </g>
            <path
              id='Path_13227'
              data-name='Path 13227'
              d='M387.134,338.067v20.452a4.939,4.939,0,0,1-4.962,4.931H355.155a23.1,23.1,0,0,1,22.691-27.35A22.854,22.854,0,0,1,387.134,338.067Z'
              transform='translate(-281.184 -261.372)'
              fill='#d3d9ed'
            />
            <path
              id='Path_13228'
              data-name='Path 13228'
              d='M232.008,214.483H145.179A5.817,5.817,0,0,1,139.4,208.7V127.079a5.791,5.791,0,0,1,5.748-5.779h86.829a5.778,5.778,0,0,1,5.779,5.779V208.7A5.733,5.733,0,0,1,232.008,214.483Zm-86.86-91.488a4.087,4.087,0,0,0-4.084,4.084V208.7a4.087,4.087,0,0,0,4.084,4.084h86.829a4.087,4.087,0,0,0,4.084-4.084V127.079a4.087,4.087,0,0,0-4.084-4.084Z'
              transform='translate(-130.989 -111.558)'
              fill='#535783'
            />
            <path
              id='Path_13229'
              data-name='Path 13229'
              d='M238.822,129.031v6.051H142.1v-6.051a4.932,4.932,0,0,1,4.931-4.931h86.829a4.919,4.919,0,0,1,4.962,4.931Z'
              transform='translate(-132.873 -113.51)'
              fill='#283275'
            />
            <circle
              id='Ellipse_3056'
              data-name='Ellipse 3056'
              cx='2.148'
              cy='2.148'
              r='2.148'
              transform='translate(14.522 13.917)'
              fill='#f5f5f5'
            />
            <circle
              id='Ellipse_3057'
              data-name='Ellipse 3057'
              cx='2.148'
              cy='2.148'
              r='2.148'
              transform='translate(22.6 13.917)'
              fill='#f5f5f5'
            />
            <g id='Group_11337' data-name='Group 11337' transform='translate(103.409)'>
              <path
                id='Path_13230'
                data-name='Path 13230'
                d='M473.546,102.725a.822.822,0,0,0-1.18,0l-2.541,2.541a.834.834,0,1,0,1.18,1.18l2.541-2.541A.822.822,0,0,0,473.546,102.725Z'
                transform='translate(-464.681 -98.429)'
                fill='#535783'
              />
              <path
                id='Path_13231'
                data-name='Path 13231'
                d='M454.217,89.1a.813.813,0,0,0-.817.817v3.57a.817.817,0,0,0,1.634,0v-3.54A.84.84,0,0,0,454.217,89.1Z'
                transform='translate(-453.4 -89.1)'
                fill='#535783'
              />
              <path
                id='Path_13232'
                data-name='Path 13232'
                d='M484.487,126.1h-3.57a.817.817,0,1,0,0,1.634h3.57a.817.817,0,1,0,0-1.634Z'
                transform='translate(-472.022 -114.906)'
                fill='#535783'
              />
            </g>
            <path
              id='Path_13233'
              data-name='Path 13233'
              d='M114.232,428.964a2.632,2.632,0,1,1,2.632-2.632h0A2.615,2.615,0,0,1,114.232,428.964Zm0-3.57a.938.938,0,1,0,.938.938h0A.943.943,0,0,0,114.232,425.394Z'
              transform='translate(-111.6 -322.47)'
              fill='#535783'
            />
            <path
              id='Path_13234'
              data-name='Path 13234'
              d='M152.3,455.27a3.585,3.585,0,1,1,3.57-3.6h0A3.564,3.564,0,0,1,152.3,455.27Zm0-5.537a1.936,1.936,0,1,0,1.936,1.936h0A1.93,1.93,0,0,0,152.3,449.734Z'
              transform='translate(-137.476 -339.488)'
              fill='#535783'
            />
            <g id='Group_11338' data-name='Group 11338' transform='translate(39.603 104.831)'>
              <path
                id='Path_13235'
                data-name='Path 13235'
                d='M284.251,465.294h-1.664V463.6h1.694v1.694Zm-4.447,0h-1.694V463.6H279.8Zm-4.447,0h-1.694V463.6h1.694Zm-4.447,0h-1.694V463.6h1.694Zm-4.447,0h-1.694V463.6h1.694Zm-4.478,0h-1.694V463.6h1.694Zm-4.447,0h-1.694V463.6h1.694Zm-4.447,0h-1.664V463.6h1.694v1.694Zm-4.447,0h-1.694V463.6h1.694Zm-4.447,0H242.5V463.6h1.694Z'
                transform='translate(-242.5 -455.129)'
                fill='#aab5d3'
              />
              <path
                id='Path_13236'
                data-name='Path 13236'
                d='M244.194,441.59H242.5V439.9h1.694Zm0-4.447H242.5V435.6h1.694Z'
                transform='translate(-242.5 -435.6)'
                fill='#aab5d3'
              />
            </g>
          </g>
        </g>
      </g>
      <g id='Group_11342' data-name='Group 11342' transform='translate(86.866 79.734)'>
        <path
          id='Path_15289'
          data-name='Path 15289'
          d='M20.058,0A20.058,20.058,0,1,1,0,20.058,20.058,20.058,0,0,1,20.058,0Z'
          transform='translate(1.967 1.664)'
          fill='#46a200'
        />
        <path
          id='Path_13237'
          data-name='Path 13237'
          d='M374.425,329.8a22.025,22.025,0,1,0,22.025,22.025A22.047,22.047,0,0,0,374.425,329.8ZM394.3,351.886a19.877,19.877,0,1,1-19.877-19.877A19.91,19.91,0,0,1,394.3,351.886Z'
          transform='translate(-352.4 -329.8)'
          fill='#fff'
        />
        <path
          id='Path_13238'
          data-name='Path 13238'
          d='M411.378,376.287l-4.417-4.387-10.74,10.74-5.2-5.2-4.417,4.387,5.264,5.234h-.03l4.387,4.387h0l4.417-4.387h0Z'
          transform='translate(-376.253 -359.163)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
};
