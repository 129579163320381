import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const VideoCallIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main } = props;

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-video-call_1_'
      data-name='icons8-video-call (1)'
      width='24.75'
      height='18.563'
      viewBox='0 0 24.75 18.563'
      {...props}
    >
      <path
        id='Path_12782'
        data-name='Path 12782'
        d='M8.022,27.563A3.992,3.992,0,0,1,4,23.541V13.022A3.992,3.992,0,0,1,8.022,9h6.064a.928.928,0,0,1,0,1.856H8.022a2.143,2.143,0,0,0-2.166,2.166V23.541a2.143,2.143,0,0,0,2.166,2.166.928.928,0,1,1,0,1.856Z'
        transform='translate(-4 -9)'
        fill={fill}
      />
      <path
        id='Path_12783'
        data-name='Path 12783'
        d='M22.963,27.563H16.528a.928.928,0,1,1,0-1.856h6.435a2.143,2.143,0,0,0,2.166-2.166V13.022a2.143,2.143,0,0,0-2.166-2.166.928.928,0,1,1,0-1.856,3.992,3.992,0,0,1,4.022,4.022V23.541a3.992,3.992,0,0,1-4.022,4.022Z'
        transform='translate(-8.422 -9)'
        fill={fill}
      />
      <path
        id='Path_12784'
        data-name='Path 12784'
        d='M37.505,26.67a1.567,1.567,0,0,1-.778-.21l-.025-.015L34.784,25.27a.928.928,0,1,1,.97-1.583l1.433.878V14.856l-4.782,2.869a.928.928,0,0,1-.955-1.592l5.259-3.156.017-.01a1.53,1.53,0,0,1,1.542,0,1.558,1.558,0,0,1,.775,1.366V25.1a1.558,1.558,0,0,1-.775,1.366A1.517,1.517,0,0,1,37.505,26.67Z'
        transform='translate(-14.294 -10.433)'
        fill={fill}
      />
    </SvgIcon>
  );
};
