import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "500px",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    padding: "0 5px",
  },
  healthPackagesCardWrapper: {
    paddingBlock: "5px",
    "&&:first-child": {
      marginInlineStart: "5px",
    },
    "&:last-child": {
      marginInlineEnd: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "318px",
      height: "285px",
    },
    width: "230px",
    height: "335px",
  },
  cardsContainerClassName: {
    height: "100%",
    overflowY: "hidden",
    backgroundColor: "inherit",
  },
  cardsUpperContainer: {
    gap: "0px",
  },
  cardsContainer: {},
  card: {
    [theme.breakpoints.down("sm")]: {
      marginInlineEnd: 0,
    },
    height: "fit-content",
  },
  navBtnClassName: {},
  navBtnContainerClassName: {},
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  seeAllButton__container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    "& .see-all__title": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  skeletonContainer: {
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },
}));
