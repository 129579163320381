import { Box, Button, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { CreatedAppointmentCard } from "../../components/CreatedAppointmentCard/CreatedAppointmentCard";
import { DetailCard } from "../../components/DetailCard/DetailCard";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import { useTranslation } from "@toolkit/i18n";
import { toTitleCase } from "@toolkit/core";
import { useLocation, useNavigate, Location } from "react-router-dom";
import { routes } from "../../routes";
import { useCommonStyles } from "../CommonContainer.styles";
import { AppointmentScheduleSuccessContainerData } from "./type";
import { formatQueryStringSlots } from "../../utils";

export const AppointmentCreateSuccessContainer: FC = () => {
  const location: Location<AppointmentScheduleSuccessContainerData> = useLocation();

  const { visitType, selectedTimeSlots: _selectedTimeSlots, doctorInfo } = location.state || {};
  const { t } = useTranslation("consumer");
  const { classes } = useCommonStyles();
  const navigate = useNavigate();
  const selectedTimeSlots = formatQueryStringSlots(_selectedTimeSlots);

  useEffect(() => {
    if (!doctorInfo?.doctorId) {
      navigate(routes.root.absRoute);
    }
  }, [doctorInfo]);
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <CreatedAppointmentCard doctorName={doctorInfo && doctorInfo?.name ? doctorInfo?.name : ""} />
        <DetailCard title={t("Visit Type")} content={t(toTitleCase(visitType || ""))} />
        <DetailCard title={t("Appointment Date")} content={selectedTimeSlots[0]?.startTime?.format("DD MMMM YYYY")} />
        <DetailCard
          title={t("Appointment Time")}
          content={
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {selectedTimeSlots.map(slot => `${slot?.startTime?.format("HH:mm A")}-${slot?.endTime?.format("HH:mm A")}`).join("\n")}
            </Typography>
          }
        />
        <AppointmentDoctorCard
          classes={{
            card: classes.mgInline_0,
          }}
          {...doctorInfo}
        />
      </Box>
      <Box className={classes.footer}>
        <Button
          className={classes.button}
          onClick={() => {
            navigate(routes.root.absRoute);
          }}
        >
          {t("Done")}
        </Button>
      </Box>
    </Box>
  );
};
