import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EveningIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  theme.palette.primary.main;
  const fillValue = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26.5' height='26.5' viewBox='0 0 26.5 26.5' {...props}>
      <g id='icons8-moon_1_' data-name='icons8-moon (1)' transform='translate(-3 -3)'>
        <path
          id='Path_12986'
          data-name='Path 12986'
          d='M34.486,7.358h0a.894.894,0,0,1,.872-.872h0a.872.872,0,1,0,0-1.743h0a.894.894,0,0,1-.872-.872h0a.872.872,0,1,0-1.743,0h0a.894.894,0,0,1-.872.872h0a.872.872,0,1,0,0,1.743h0a.894.894,0,0,1,.872.872h0a.872.872,0,1,0,1.743,0Z'
          transform='translate(-11.731)'
          fill={fillValue}
        />
        <path
          id='Path_12987'
          data-name='Path 12987'
          d='M40.358,17.743h0a.894.894,0,0,1-.872-.872h0a.872.872,0,1,0-1.743,0h0a.894.894,0,0,1-.872.872h0a.872.872,0,1,0,0,1.743h0a.894.894,0,0,1,.872.872h0a.872.872,0,1,0,1.743,0h0a.894.894,0,0,1,.872-.872h0a.872.872,0,1,0,0-1.743Z'
          transform='translate(-13.825 -5.446)'
          fill={fillValue}
        />
        <path
          id='Path_12988'
          data-name='Path 12988'
          d='M25.978,37a.872.872,0,0,1-.211-1.717,9.665,9.665,0,0,0,5.326-3.534,12.189,12.189,0,0,1-2.813-1.066,11.814,11.814,0,0,1-3.363-2.635.872.872,0,0,1,1.307-1.153,10.077,10.077,0,0,0,2.869,2.246,10.437,10.437,0,0,0,3.56,1.126.872.872,0,0,1,.646,1.3,11.437,11.437,0,0,1-7.111,5.41A.874.874,0,0,1,25.978,37Z'
          transform='translate(-9.091 -9.887)'
          fill={fillValue}
        />
        <path
          id='Path_12989'
          data-name='Path 12989'
          d='M11.774,28.37a.873.873,0,0,1-.217-.028,11.471,11.471,0,0,1-6.119-3.994A11.3,11.3,0,0,1,3,17.33a11.2,11.2,0,0,1,.895-4.4A11.432,11.432,0,0,1,9.929,6.895,11.2,11.2,0,0,1,14.33,6H14.8a.872.872,0,0,1,.786,1.249,9.789,9.789,0,0,0-.96,4.271,10.069,10.069,0,0,0,.429,2.834.872.872,0,0,1-1.672.492,11.808,11.808,0,0,1-.5-3.325,11.61,11.61,0,0,1,.607-3.74A9.444,9.444,0,0,0,10.61,8.5,9.689,9.689,0,0,0,5.5,13.61a9.559,9.559,0,0,0-.209,6.913,9.611,9.611,0,0,0,1.517,2.746,9.718,9.718,0,0,0,5.183,3.385.872.872,0,0,1-.216,1.716Z'
          transform='translate(0 -1.257)'
          fill={fillValue}
        />
      </g>
    </SvgIcon>
  );
};
