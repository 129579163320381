import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const ArabicIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='Group_15188'
      data-name='Group 15188'
      width='11.727'
      height='17.615'
      viewBox='0 0 11.727 17.615'
      {...props}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_9933' data-name='Rectangle 9933' width='11.727' height='17.615' fill='#214545' />
        </clipPath>
      </defs>
      <g id='Group_15188-2' data-name='Group 15188' clipPath='url(#clip-path)'>
        <path
          id='Path_22499'
          data-name='Path 22499'
          d='M11.727,14.767a5,5,0,0,1-3,2.154,11.3,11.3,0,0,1-3.775.695,5.66,5.66,0,0,1-2.028-.347,4.582,4.582,0,0,1-1.565-.977,4.284,4.284,0,0,1-1.007-1.5A5.093,5.093,0,0,1,0,12.864a7.911,7.911,0,0,1,.528-2.683A16.353,16.353,0,0,1,1.993,7.207a5.041,5.041,0,0,1,.508-.68,2.092,2.092,0,0,1,.418-.367A2.227,2.227,0,0,1,1.168,3.916a4.593,4.593,0,0,1,.856-2.466A2.578,2.578,0,0,1,4.047,0a1.642,1.642,0,0,1,.7.186,3.386,3.386,0,0,1,.735.483,3.285,3.285,0,0,1,.584.644,1.2,1.2,0,0,1,.237.669c-.007.034-.024.05-.051.05a3.655,3.655,0,0,0-1.62-.322,2.91,2.91,0,0,0-.77.106,2.973,2.973,0,0,0-.71.292,1.879,1.879,0,0,0-.523.433.807.807,0,0,0-.2.518.88.88,0,0,0,.2.533,2.066,2.066,0,0,0,.488.463,2.647,2.647,0,0,0,.639.322,1.959,1.959,0,0,0,.639.121,4.648,4.648,0,0,0,1.883-.574q.111-.06.548-.282T8.043,3.02L7.67,5.083H7.6a5.666,5.666,0,0,0-1.812.785Q1.057,8.466,1.057,12.3A5.915,5.915,0,0,0,1.2,13.589q.463,2.033,4.832,2.033.533,0,1.284-.051T9.059,15.4a6,6,0,0,0,2.667-.634'
          fill={props?.fill || theme.palette.primary[1600]}
        />
      </g>
    </SvgIcon>
  );
};
