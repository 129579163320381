import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const PillsIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9643' data-name='Group 9643' transform='translate(-286.566 -910)'>
        <circle id='Ellipse_2621' data-name='Ellipse 2621' cx='22' cy='22' r='22' transform='translate(286.566 910)' fill='#305e9d' />
        <path
          id='icons8-pills_7_'
          data-name='icons8-pills (7)'
          d='M16.745,3.007a6.849,6.849,0,0,0-.718.008,6.935,6.935,0,0,0-4.42,2.092L5.14,11.573a6.8,6.8,0,0,0-.289,9.45,6.619,6.619,0,0,0,8,1.21,6.673,6.673,0,0,1,1.117-9.463L10.727,9.532a.835.835,0,0,1,0-1.182l2.227-2.227A4.937,4.937,0,0,1,20.8,11.95a6.675,6.675,0,0,1,1.443.883,6.618,6.618,0,0,0-5.5-9.827Zm1.316,10.026a5.016,5.016,0,1,0,5.016,5.016A5.016,5.016,0,0,0,18.062,13.033Zm-1.773,2.405a.834.834,0,0,1,.591.247l3.547,3.547a.836.836,0,1,1-1.182,1.182L15.7,16.867a.838.838,0,0,1,.591-1.429Z'
          transform='translate(295.488 919)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
