/* eslint-disable no-restricted-imports */
import { useCustomForm } from "@toolkit/core";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  AppointmentsListFiltersFormSchema,
  appointmentListFiltersFormDefaultValues,
  IAppointmentsListFiltersFormValues,
} from "./AppointmentsListFiltersFormSchema";
import { AppointmentTypesFilterForm } from "./Filters/AppointmentTypesFilter";
import { SpecializationsFilterForm } from "./Filters/SpecializationsFilter";
import { GenderFilterForm } from "./Filters/GenderFilter";
import { FacilityFilterForm } from "./Filters/Facility";
import { LanguagesFilterForm } from "./Filters/LanguagesFilter";
import { Box, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useStyles } from "./AppointmentListStyle";

type AppointmentAppointmentListFiltersFormProps = {
  onSubmit?: (values: IAppointmentsListFiltersFormValues) => void;
};

export type AppointmentAppointmentListFiltersFormRef = {
  submit: () => void;
};

export const AppointmentAppointmentListFiltersForm: React.FC<AppointmentAppointmentListFiltersFormProps> = props => {
  const { t } = useTranslation("consumer");
  const form = useCustomForm<IAppointmentsListFiltersFormValues>({
    schema: AppointmentsListFiltersFormSchema,
    defaultValues: appointmentListFiltersFormDefaultValues,
    mode: "onChange",
  });
  const { setValue } = form;
  const onSubmit = <K extends keyof IAppointmentsListFiltersFormValues>(fieldName: K, value: IAppointmentsListFiltersFormValues[K]) => {
    setValue(
      fieldName as keyof IAppointmentsListFiltersFormValues,
      value as IAppointmentsListFiltersFormValues[keyof IAppointmentsListFiltersFormValues]
    );
    props.onSubmit?.({
      ...form.getValues(),
      [fieldName]: value,
    });
  };
  const getValue = <K extends keyof IAppointmentsListFiltersFormValues>(fieldName: K) => {
    return form.getValues()[fieldName];
  };
  const resetForm = () => {
    form.reset(appointmentListFiltersFormDefaultValues);
    props.onSubmit?.(appointmentListFiltersFormDefaultValues);
  };

  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form>
        <Box className={classes.container}>
          <Button className={classes.button} onClick={resetForm} variant='outlined'>
            {t("All")}
          </Button>
          <AppointmentTypesFilterForm getValue={getValue} onSubmit={onSubmit} />
          <SpecializationsFilterForm getValue={getValue} onSubmit={onSubmit} />
          <GenderFilterForm getValue={getValue} onSubmit={onSubmit} />
          <FacilityFilterForm getValue={getValue} onSubmit={onSubmit} />
          <LanguagesFilterForm getValue={getValue} onSubmit={onSubmit} />
        </Box>
      </form>
    </FormProvider>
  );
};
