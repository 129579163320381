import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const VideoConference6Icon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "97.296"}
      height={height ? height : "76.402"}
      viewBox={viewBox ? viewBox : "0 0 97.296 76.402"}
      {...props}
    >
      <path
        id='icons8-video-conference_6_'
        data-name='icons8-video-conference (6)'
        d='M3.7,8A2.093,2.093,0,0,0,2,10.093v62.8a2.1,2.1,0,0,0,2.093,2.093H96.2A2.1,2.1,0,0,0,98.3,72.895v-62.8A2.1,2.1,0,0,0,96.2,8H3.7Zm2.486,4.187H94.109V70.8H6.187Zm43.961,6.28a12.529,12.529,0,0,0-6.869,23.027,12.593,12.593,0,0,0-11.972,12.56h4.187a8.348,8.348,0,0,1,8.374-8.374h12.56A8.348,8.348,0,0,1,64.8,54.054h4.187a12.593,12.593,0,0,0-11.972-12.56,12.529,12.529,0,0,0-6.869-23.027Zm0,4.187a8.374,8.374,0,1,1-8.374,8.374A8.338,8.338,0,0,1,50.148,22.654Zm-31.4,35.588v8.374h12.56V58.241Zm16.747,0v8.374h12.56V58.241Zm16.747,0v8.374H64.8V58.241Zm16.747,0v8.374h12.56V58.241ZM28.625,79.175a2.114,2.114,0,0,0,.589,4.187H71.082a2.094,2.094,0,1,0,0-4.187H28.625Z'
        transform='translate(-1.5 -7.5)'
        fill={palette.common.white}
        stroke={palette.common.white}
        strokeWidth='1'
      />
    </SvgIcon>
  );
};
