import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const AppleIcon: FC<SvgIconProps> = props => {
  const { width, height } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20.424"}
      height={height ? height : "25.87"}
      viewBox='0 0 20.424 25.87'
      {...props}
    >
      <path
        id='_'
        data-name=''
        d='M2.632,22.581A16.259,16.259,0,0,1,0,14.013c0-5.033,3.175-7.7,6.3-7.7,1.643,0,3.032,1.118,4.086,1.118.973,0,2.521-1.184,4.419-1.184A5.836,5.836,0,0,1,19.77,8.816a5.886,5.886,0,0,0-2.7,4.934,5.665,5.665,0,0,0,3.352,5.23A14.724,14.724,0,0,1,18.7,22.647c-1.069,1.578-2.2,3.19-3.893,3.19-1.723,0-2.153-1.035-4.116-1.035-1.931,0-2.616,1.069-4.164,1.069C4.962,25.87,3.877,24.39,2.632,22.581ZM10.18,5.937a2.335,2.335,0,0,1-.048-.461,5.67,5.67,0,0,1,1.372-3.5A5.522,5.522,0,0,1,15.19,0a2.512,2.512,0,0,1,.048.51,5.988,5.988,0,0,1-1.325,3.619,4.878,4.878,0,0,1-3.4,1.842A1.832,1.832,0,0,1,10.18,5.937Z'
      />
    </SvgIcon>
  );
};
