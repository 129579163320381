import React, { FC } from "react";
import { SvgIconProps } from "../../base/mui";
export const EmptyGradientChart: FC<SvgIconProps> = ({ width }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || "124.857"} height='200' viewBox='0 0 129 50'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' y1='0.012' x2='0.5' y2='0.998' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fff' />
          <stop offset='0.999' stopColor='#c5c6e2' stopOpacity='0' />
          <stop offset='1' stopColor='#eff0fe' />
        </linearGradient>
      </defs>
      <g id='Group_11751' data-name='Group 11751' transform='translate(0.45 0.5)'>
        <path
          id='Line_1'
          d='M0,1.6S8.6,19.173,17.714,19.173c8.6,0,8.6-7.456,17.714-7.456,8.6,0,8.6,12.25,17.714,12.25,8.6,0,8.6-23.966,17.714-23.966,8.6,0,8.6,36.749,17.714,36.749,8.6,0,8.6-14.912,17.714-14.912,8.6,0,17.714,12.782,17.714,12.782V52.061H0Z'
          transform='translate(0 0)'
          opacity='0.759'
          fill='url(#linear-gradient)'
        />
        <path
          id='Line_1-2'
          data-name='Line_1'
          d='M0,1.606s8.6,17.67,17.714,17.67c8.6,0,8.6-7.5,17.714-7.5,8.6,0,8.6,12.315,17.714,12.315,8.6,0,8.6-24.1,17.714-24.1,8.6,0,8.6,36.946,17.714,36.946,8.6,0,8.6-14.993,17.714-14.993C114.89,21.954,124,34.8,124,34.8'
          transform='translate(0 0)'
          fill='none'
          stroke='#cdd5e1'
          strokeWidth='1'
        />
      </g>
    </svg>
  );
};
