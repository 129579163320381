import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const CacheNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-cash' transform='translate(6 7)' fill='#fff'>
        <path
          id='Path_12804'
          data-name='Path 12804'
          d='M26.557,36.789a2.421,2.421,0,0,1-.98-.21A73.021,73.021,0,0,0,7.86,31.057a1.036,1.036,0,1,1,.351-2.042,75.01,75.01,0,0,1,18.2,5.669.421.421,0,0,0,.066.023,18.293,18.293,0,0,1,.535-1.821,1.036,1.036,0,0,1,1.966.655,14.87,14.87,0,0,0-.52,1.81q0,.024-.011.048a1.782,1.782,0,0,1-.969,1.182A2.119,2.119,0,0,1,26.557,36.789Z'
          transform='translate(-0.618 -6.493)'
          fill='#fff'
        />
        <path
          id='Path_12805'
          data-name='Path 12805'
          d='M28.834,28.012H7.418A2.421,2.421,0,0,1,5,25.594V20.136a1.036,1.036,0,0,1,2.072,0v5.458a.35.35,0,0,0,.345.345H28.834a.35.35,0,0,0,.345-.345V23.521a1.036,1.036,0,1,1,2.072,0v2.072A2.421,2.421,0,0,1,28.834,28.012Z'
          transform='translate(0 -3.432)'
          fill='#fff'
        />
        <path
          id='Path_12806'
          data-name='Path 12806'
          d='M30.215,18.086a1.036,1.036,0,0,1-1.036-1.036V10.418a.35.35,0,0,0-.345-.345H7.418a.35.35,0,0,0-.345.345v2.9a1.036,1.036,0,0,1-2.072,0v-2.9A2.421,2.421,0,0,1,7.418,8H28.834a2.421,2.421,0,0,1,2.418,2.418V17.05A1.036,1.036,0,0,1,30.215,18.086Z'
          fill='#fff'
        />
        <circle
          id='Ellipse_2856'
          data-name='Ellipse 2856'
          cx='1.382'
          cy='1.382'
          r='1.382'
          transform='translate(25.034 14.908)'
          fill='#fff'
        />
        <circle
          id='Ellipse_2857'
          data-name='Ellipse 2857'
          cx='1.382'
          cy='1.382'
          r='1.382'
          transform='translate(8.454 14.908)'
          fill='#fff'
        />
        <path
          id='Ellipse_2858'
          data-name='Ellipse 2858'
          d='M2.645-1.5a3.853,3.853,0,0,1,3,1.493A5.275,5.275,0,0,1,6.79,3.336,5.275,5.275,0,0,1,5.645,6.679a3.853,3.853,0,0,1-3,1.493,3.853,3.853,0,0,1-3-1.493A5.275,5.275,0,0,1-1.5,3.336,5.275,5.275,0,0,1-.355-.007,3.853,3.853,0,0,1,2.645-1.5Zm0,7.6a1.821,1.821,0,0,0,1.4-.733,3.2,3.2,0,0,0,.676-2.031,3.2,3.2,0,0,0-.676-2.031,1.821,1.821,0,0,0-1.4-.733,1.821,1.821,0,0,0-1.4.733A3.2,3.2,0,0,0,.572,3.336a3.2,3.2,0,0,0,.676,2.031A1.821,1.821,0,0,0,2.645,6.1Z'
          transform='translate(15.481 12.954)'
          fill='#fff'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
