import { FC } from "react";
import { HealthServicesIconProps, HealthServicesIconWrapper } from "./HealthServicesIconWrapper";

export const HealthServiceCallDoctorNowIcon: FC<HealthServicesIconProps> = props => {
  return (
    <HealthServicesIconWrapper {...props}>
      <g
        xmlns='http://www.w3.org/2000/svg'
        id='Group_10223'
        data-name='Group 10223'
        transform='translate(
        18,16)'
        clip-path='url(#clip-path)'
      >
        <path
          id='Path_12697'
          data-name='Path 12697'
          d='M52.517,73.283a2.293,2.293,0,0,0-2-.333c-3.674.969-5.076.459-6.1.087a3.679,3.679,0,0,0-1.374-.305,3.288,3.288,0,0,0-3.354,3.4,12.863,12.863,0,0,0,1.194,5.392c.063.147.124.289.185.428a9.722,9.722,0,0,1,.741,2.159l.027.141h1.411v-.174c0-1.28,0-2.716-.007-3.928l0-1a.683.683,0,0,0-1.367,0c0,.334,0,.667,0,1a10.514,10.514,0,0,1-.792-4.49,1.847,1.847,0,0,1,.7-1.265,1.249,1.249,0,0,1,1-.305c.263.047.509.1.746.153v1.743a1.177,1.177,0,1,0,1.017,0v-1.5a9.173,9.173,0,0,0,4.694.072v1.225A1.8,1.8,0,0,0,47.938,77.5v.323a.5.5,0,0,0,.995,0V77.5a.8.8,0,1,1,1.591,0v.323a.5.5,0,1,0,.995,0V77.5a1.8,1.8,0,0,0-1.293-1.719V74.31c.216-.061.439-.126.672-.2a.9.9,0,0,1,.834.126,1.606,1.606,0,0,1,.57,1.29v.038a12.055,12.055,0,0,1-.155,2.426c-.161.786-.4,1.574-.64,2.336l-.058.188c-.025-.441-.041-.988-.041-1.4a.64.64,0,1,0-1.279,0v5.14h1.311l.024-.147a11.269,11.269,0,0,1,.69-2.48l.159-.385a12.633,12.633,0,0,0,1.365-5.55,3.157,3.157,0,0,0-1.157-2.409m-8.194,3.76a.295.295,0,1,1-.3-.3.3.3,0,0,1,.3.3'
          transform='translate(-28.305 -51.875)'
          fill='#fff'
        />
        <path
          id='Path_12698'
          data-name='Path 12698'
          d='M54.875,53.26h.007a3.985,3.985,0,0,0,2.845-1.182A4.028,4.028,0,0,0,58.9,49.242a4.012,4.012,0,1,0-8.024-.013,3.984,3.984,0,0,0,4,4.032m-2.647-4.049a2.509,2.509,0,0,1,.7-1.891,2.629,2.629,0,0,1,1.931-.826h.051a2.67,2.67,0,0,1,2.64,2.853,2.719,2.719,0,0,1-2.674,2.582h-.021a2.717,2.717,0,0,1-1.985-.9,2.372,2.372,0,0,1-.643-1.786v-.029Z'
          transform='translate(-36.284 -32.213)'
          fill='#fff'
        />
        <path
          id='Path_12699'
          data-name='Path 12699'
          d='M24.635,5.817a8.314,8.314,0,0,1,1.89-.123,1.924,1.924,0,0,1,.951-.249h.14a8.491,8.491,0,0,0-1.487-.134,8.752,8.752,0,0,0-1.395.115c-.089.015-.182.032-.279.051a3.573,3.573,0,0,0-.228.425c.136-.032.272-.06.408-.085'
          transform='translate(-17.279 -3.788)'
          fill='#fff'
        />
        <path
          id='Path_12700'
          data-name='Path 12700'
          d='M40.944,0H29.077l0,.013H26.693a3.548,3.548,0,0,0-1.9.548c.184-.011.369-.023.55-.023a9.013,9.013,0,0,1,4.409,1.119h.775a1.548,1.548,0,0,1-.172-.013H40.944a1.906,1.906,0,0,1,1.918,1.918v3l0,0v23.1a1.906,1.906,0,0,1-1.918,1.918H26.693a1.906,1.906,0,0,1-1.918-1.918V18.718c-.34-.1-.709-.211-1.1-.347-.184-.064-.365-.134-.545-.2v11.5a3.555,3.555,0,0,0,3.562,3.562H40.942A3.555,3.555,0,0,0,44.5,29.664V13.339h0V3.562A3.555,3.555,0,0,0,40.944,0'
          transform='translate(-16.498)'
          fill='#fff'
        />
        <path
          id='Path_12701'
          data-name='Path 12701'
          d='M23.445,58.066c-.106-.054-.21-.109-.313-.165v.5c.085.035.167.072.254.106.2.08.408.157.616.23.269.094.528.177.774.251v-.336a14.146,14.146,0,0,1-1.331-.586'
          transform='translate(-16.498 -41.297)'
          fill='#fff'
        />
        <path
          id='Path_12702'
          data-name='Path 12702'
          d='M16.061,9.058A7.523,7.523,0,0,0,8.833,4.411,8.991,8.991,0,0,0,7.4,4.529c-.218.036-.435.081-.65.132a9.207,9.207,0,0,0-1.155.35c-.046.017-.091.036-.137.055l-.064.026c-.2.079-.386.167-.574.258A8.746,8.746,0,0,0,.861,9.145a7.075,7.075,0,0,0-.417,5.839,6.924,6.924,0,0,0,.322.716,9.72,9.72,0,0,0,4.053,4c.182.1.367.2.559.3.437.221.9.424,1.37.611.2.08.408.158.617.231a18.6,18.6,0,0,0,2.264.633l1.182.249L9.893,19.3l.206-.056c.1-.027.2-.05.3-.073a3.877,3.877,0,0,0,.512-.143c4.306-1.633,6.616-6.107,5.149-9.972M8.115,18.335,8.632,19.7l-.386-.125c-.258-.083-.507-.171-.75-.259-.259-.095-.506-.193-.748-.293-.426-.176-.828-.36-1.192-.55-.258-.135-.5-.274-.737-.418a7.481,7.481,0,0,1-2.843-2.888l-.022-.031-.021-.05a5.537,5.537,0,0,1-.256-.571C.63,11.752,2,8.526,4.819,6.848a7.846,7.846,0,0,1,.729-.385c.191-.088.355-.157.514-.218q.338-.128.687-.225c.236-.066.475-.121.716-.165a6.456,6.456,0,0,1,7.363,3.671c1.208,3.185-.758,6.895-4.383,8.27A8.02,8.02,0,0,1,8.91,18.2Z'
          transform='translate(0 -3.146)'
          fill='#fff'
        />
        <path
          id='Path_12703'
          data-name='Path 12703'
          d='M13.828,26.519a1.207,1.207,0,1,0,1.207,1.207,1.208,1.208,0,0,0-1.207-1.207'
          transform='translate(-9.002 -18.914)'
          fill='#fff'
        />
        <path
          id='Path_12704'
          data-name='Path 12704'
          d='M25.425,26.519a1.207,1.207,0,1,0,1.207,1.207,1.208,1.208,0,0,0-1.207-1.207'
          transform='translate(-17.273 -18.914)'
          fill='#fff'
        />
        <path
          id='Path_12705'
          data-name='Path 12705'
          d='M37.02,28.933a1.207,1.207,0,1,0-1.207-1.207,1.208,1.208,0,0,0,1.207,1.207'
          transform='translate(-25.543 -18.914)'
          fill='#fff'
        />
      </g>
    </HealthServicesIconWrapper>
  );
};
