import { getEnvVariable } from "@/env";
import { Box } from "@toolkit/ui";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import { PlaygroundIconsPage } from "./Icons";
import { PlaygroundCardsPage } from "./PlaygroundCardsPage";
import { PlaygroundFormsPage } from "./PlaygroundFromsPage";

const Playground: React.FC = () => {
  if (getEnvVariable("SADA_API_ENV") !== "dev") {
    return <PageNotFound />;
  }

  return (
    <Box height='100vh' width='100vw' overflow='auto'>
      <Routes>
        <Route path='cards' element={<PlaygroundCardsPage />} />
        <Route path='icons' element={<PlaygroundIconsPage />} />
        <Route path='forms' element={<PlaygroundFormsPage />} />
        <Route path='/' element={<PlayGroundIndex />} />
      </Routes>
    </Box>
  );
};

const PlayGroundIndex = () => {
  return (
    <div>
      <h1>Playground</h1>
      <p>Choose a playground from the left menu</p>
      <Link to='cards'>Cards</Link>
      <Link to='icons'>icons</Link>
    </div>
  );
};

export default Playground;
