import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const AppointmentConfirmationFormSchema = z.object({
  name: z.string().min(1).max(50),
  phone: z.string().min(6, i18n.t("Phone number is required", { ns: "consumer" })),
  nationality: z.string().min(1).max(50),
  showInsuranceSection: z.boolean().optional(),
  nationalId: z.string().optional(),
  insuranceCompanyName: z.string().optional(),
  insuranceId: z.string().optional(),
});

export type IAppointmentConfirmationFormValues = z.infer<typeof AppointmentConfirmationFormSchema>;
export const appointmentConfirmationFormDefaultValues: Partial<IAppointmentConfirmationFormValues> = {
  name: "",
  phone: "",
  nationality: "",
  showInsuranceSection: false,
  nationalId: "",
  insuranceCompanyName: "",
  insuranceId: "",
};
