import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DermatologyIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "26px"}
      height={height ? height : "28px"}
      viewBox={viewBox ? viewBox : "0 0 26 28"}
      {...props}
    >
      <path
        id='icons8-dermatology'
        d='M15,2C12.568,2,4,3,4,13v5a3.989,3.989,0,0,0,2.221,3.771C7.189,24.157,10.017,28,15,28a9.133,9.133,0,0,0,1.566-.141,8.059,8.059,0,0,1-1.49-1.865C15.05,26,15.026,26,15,26a6.975,6.975,0,0,1-2.23-.363,1,1,0,1,0-1.742-.865,7.634,7.634,0,0,1-1.266-1.127,1,1,0,0,0-1.141-1.57,9.5,9.5,0,0,1-.672-1.391l-.174-.521-.533-.133A1.918,1.918,0,0,1,6,18V17H7c0-7,10-2,13-9a11.074,11.074,0,0,1,2.752,6.037A7.937,7.937,0,0,1,26,15.078V13c0-4-2-9-6-9C20,4,19,2,15,2Zm7,14a6,6,0,0,0,0,12,5.954,5.954,0,0,0,3.469-1.117l2.824,2.824a1,1,0,1,0,1.414-1.414l-2.824-2.824A5.954,5.954,0,0,0,28,22,6.015,6.015,0,0,0,22,16Zm0,2a3.973,3.973,0,0,1,2.689,1.051,1,1,0,1,0,1.064,1.6A3.992,3.992,0,1,1,22,18ZM9.5,19a.5.5,0,1,0,.5.5A.5.5,0,0,0,9.5,19ZM12,21a1,1,0,1,0,1,1A1,1,0,0,0,12,21Zm10.5,0A1.5,1.5,0,1,0,24,22.5,1.5,1.5,0,0,0,22.5,21Zm-8,2a.5.5,0,1,0,.5.5A.5.5,0,0,0,14.5,23Z'
        transform='translate(-4 -2)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
