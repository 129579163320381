import { useMeProfileInfoQuery } from "@/domains/patient/gql";
import { useUpdatePatientProfileUpdateMutation } from "../../gql/mutations";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { getApolloContextFromToken } from "@/providers/auth";
import { formatGraphQLError } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";

export const usePatientAvatarWithUploadAndDependents = () => {
  const { user } = useAuth();
  const { failed } = useAddToast();

  const { data, loading } = useMeProfileInfoQuery({
    context: getApolloContextFromToken(user?.access_token),
    fetchPolicy: "cache-first",
  });
  const meData = data?.me;

  const [updatePatientImage, { loading: isSubmitting }] = useUpdatePatientProfileUpdateMutation({
    onCompleted: () => {
      setIsImageUploading(false);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFromToken(user?.access_token),
  });
  const handleUpdatePatientImage = (image: string | string[]) => {
    updatePatientImage({
      variables: {
        input: {
          photo: typeof image === "string" ? image : image[image.length - 1],
        },
      },
      context: getApolloContextFromToken(user?.access_token),
    });
  };

  const [isImageUploading, setIsImageUploading] = useState(false);

  const isLoading = isImageUploading || isSubmitting || loading;

  const handleChangIsImageUploading = (status: boolean) => {
    return () => setIsImageUploading(status);
  };

  return {
    ...meData,
    isLoading,
    handleChangIsImageUploading,
    handleUpdatePatientImage,
  };
};
