import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PatientIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8900'
      data-name='Group 8900'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "97.094"}
      height={height ? height : "89.383"}
      viewBox={viewBox ? viewBox : "0 0 97.094 89.383"}
      {...props}
    >
      <g id='Group_8325' data-name='Group 8325' transform='translate(64.846 41.011)'>
        <path
          id='Path_7782'
          data-name='Path 7782'
          d='M57.641,31.2H49.579A2.9,2.9,0,0,0,46.6,33.829V37.86H60.27V33.829h0A2.48,2.48,0,0,0,57.641,31.2Zm-2.1,4.206-.351.7-1.052-.526v1.227h-.7V35.582l-1.227.526-.351-.7,1.052-.526-1.052-.526.351-.7,1.052.526V32.953h.7v1.227l1.052-.526.351.7-1.052.526Z'
          transform='translate(-37.311 -29.798)'
          fill={palette.common.white}
          opacity='0.832'
        />
        <g id='Group_8324' data-name='Group 8324' transform='translate(0 0)'>
          <path
            id='Path_7783'
            data-name='Path 7783'
            d='M61.805,52.658a7.878,7.878,0,0,0,4.031-7.01V34.606a4.1,4.1,0,0,0-1.227-2.979A3.844,3.844,0,0,0,61.63,30.4H53.568a4.463,4.463,0,0,0-4.557,4.206V45.472a8.188,8.188,0,0,0,4.206,7.01A16.145,16.145,0,0,0,41.3,68.081a.876.876,0,1,0,1.753,0,14.353,14.353,0,0,1,7.711-12.794,8.8,8.8,0,0,0-.351,2.1,4.522,4.522,0,0,0-3.33,4.382v5.083a2.262,2.262,0,0,0,2.1,2.1.876.876,0,1,0,0-1.753.56.56,0,0,1-.526-.526V61.6a2.627,2.627,0,0,1,2.629-2.8,2.735,2.735,0,0,1,2.629,2.8v5.083a.56.56,0,0,1-.526.526.876.876,0,0,0,0,1.753h0a2.262,2.262,0,0,0,2.1-2.1V61.772a4.373,4.373,0,0,0-3.33-4.382,8.65,8.65,0,0,1,.526-2.629L56.9,60.37c.175.175.351.351.526.351s.526,0,.526-.175l.175-.175,4.206-5.608a19.116,19.116,0,0,1,1.4,5.433,2.784,2.784,0,0,0-1.753,1.4,3.5,3.5,0,0,0-.351,2.278A3.167,3.167,0,1,0,67.764,62.3a3.31,3.31,0,0,0-2.278-2.278,19.2,19.2,0,0,0-1.052-4.557A14.581,14.581,0,0,1,71.8,68.256a.876.876,0,0,0,1.753,0A15.99,15.99,0,0,0,61.805,52.658Zm2.8,9.114a1.382,1.382,0,0,1,1.4,1.4,1.508,1.508,0,0,1-1.4,1.4h0a1.4,1.4,0,0,1,0-2.8Zm-.526-21.732v1.753A15.311,15.311,0,0,1,57.6,40.039ZM57.774,52.132h0a6.712,6.712,0,0,1-7.01-6.485V40.215H54.8c.526.526,3.505,3.155,9.289,3.33v1.928a5.734,5.734,0,0,1-1.928,4.557A5.317,5.317,0,0,1,57.774,52.132Zm2.979,1.928-3.33,4.557-3.155-4.382a10.785,10.785,0,0,1,3.33-.351A9.573,9.573,0,0,1,60.754,54.06ZM53.568,31.977H61.63a2.4,2.4,0,0,1,2.454,2.454v4.031H50.764V34.606A2.856,2.856,0,0,1,53.568,31.977Z'
            transform='translate(-41.3 -30.4)'
            fill={palette.common.white}
          />
        </g>
      </g>
      <g id='Group_8327' data-name='Group 8327' transform='translate(0 9.815)'>
        <g id='Group_8326' data-name='Group 8326'>
          <path
            id='Path_7785'
            data-name='Path 7785'
            d='M24.805,34.858a7.878,7.878,0,0,0,4.031-7.01V16.806a4.1,4.1,0,0,0-1.227-2.979A3.844,3.844,0,0,0,24.63,12.6H16.568a4.463,4.463,0,0,0-4.557,4.206V27.848a8.188,8.188,0,0,0,4.206,7.01A16.145,16.145,0,0,0,4.3,50.456a.828.828,0,0,0,.876.876.828.828,0,0,0,.876-.876,14.353,14.353,0,0,1,7.711-12.794,8.8,8.8,0,0,0-.351,2.1,4.522,4.522,0,0,0-3.33,4.382v5.083a2.262,2.262,0,0,0,2.1,2.1.876.876,0,1,0,0-1.753.56.56,0,0,1-.526-.526V43.972a2.627,2.627,0,0,1,2.629-2.8,2.735,2.735,0,0,1,2.629,2.8v5.083a.56.56,0,0,1-.526.526.876.876,0,1,0,0,1.753h0a2.262,2.262,0,0,0,2.1-2.1V44.147a4.373,4.373,0,0,0-3.33-4.382,8.65,8.65,0,0,1,.526-2.629L19.9,42.745c.175.175.351.351.526.351s.526,0,.526-.175l.175-.175,4.206-5.608a19.115,19.115,0,0,1,1.4,5.433,2.784,2.784,0,0,0-1.753,1.4,3.5,3.5,0,0,0-.351,2.278,3.167,3.167,0,0,0,6.134-1.577,3.31,3.31,0,0,0-2.278-2.278,19.2,19.2,0,0,0-1.052-4.557A14.581,14.581,0,0,1,34.8,50.631a.876.876,0,0,0,1.753,0A16.456,16.456,0,0,0,24.805,34.858Zm2.8,9.114a1.382,1.382,0,0,1,1.4,1.4,1.508,1.508,0,0,1-1.4,1.4h0a1.382,1.382,0,0,1-1.4-1.4,1.29,1.29,0,0,1,1.4-1.4Zm-.526-21.732v1.753A15.311,15.311,0,0,1,20.6,22.239ZM20.774,34.332h0a6.712,6.712,0,0,1-7.01-6.485V22.415H17.8c.526.526,3.505,3.155,9.289,3.33v2.1A5.734,5.734,0,0,1,25.156,32.4,5.7,5.7,0,0,1,20.774,34.332Zm2.979,1.928-3.33,4.557-3.33-4.382a10.785,10.785,0,0,1,3.33-.351A10.372,10.372,0,0,1,23.754,36.26ZM16.568,14.177H24.63a2.4,2.4,0,0,1,2.454,2.454v3.856H13.764V16.631A2.717,2.717,0,0,1,16.568,14.177Z'
            transform='translate(-4.3 -12.6)'
            fill={palette.common.white}
          />
        </g>
      </g>
      <path
        id='Path_7786'
        data-name='Path 7786'
        d='M83.573,33.464H77.264V25.753A18.774,18.774,0,0,0,58.511,7H25.387A16.415,16.415,0,0,0,18.9,8.227a2.348,2.348,0,0,0-1.4,2.278,2.324,2.324,0,0,0,1.052,1.753,2.411,2.411,0,0,0,1.928.175,15.2,15.2,0,0,1,4.907-.876H58.511A14.436,14.436,0,0,1,72.882,25.928V33.64H66.573L74.985,44.33Z'
        transform='translate(5.634 -7)'
        fill={palette.common.white}
      />
      <path
        id='Path_7787'
        data-name='Path 7787'
        d='M33.787,74.03H66.911a26.148,26.148,0,0,0,3.68-.351,2.1,2.1,0,0,0,1.577-2.1v-.526a2.164,2.164,0,0,0-.7-1.4,2.038,2.038,0,0,0-1.753-.351,13.264,13.264,0,0,1-2.629.175h-33.3A14.436,14.436,0,0,1,19.416,55.1V47.391h6.309L17.313,36.7,8.9,47.391h6.309V55.1A18.555,18.555,0,0,0,33.787,74.03Z'
        transform='translate(-0.838 15.352)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
