import { SvgIcon, useTheme } from "@toolkit/ui";
import React from "react";
import { SvgWithSecondaryColorProps } from "../types";

export const AppBarHomeIcon: React.FC<SvgWithSecondaryColorProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main, secondaryColor = theme.palette.secondary.main, removeSecondaryColor = false } = props;
  const secondaryColorFill = removeSecondaryColor ? fill : secondaryColor;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-play_4_' data-name='icons8-play_4_' viewBox='0 0 22.75 25.563' {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='icons8-home_1_' data-name='icons8-home (1)' transform='translate(0 1.5)'>
        <path
          id='Path_12720'
          data-name='Path 12720'
          d='M19.9,30.461H8.07A2.073,2.073,0,0,1,6,28.39V17.683a4.522,4.522,0,0,1,1.7-3.474L9.821,12.5a.887.887,0,1,1,1.113,1.382L8.8,15.594,8.8,15.6a2.747,2.747,0,0,0-1.021,2.083V28.39a.3.3,0,0,0,.3.3H19.9a.887.887,0,1,1,0,1.775Z'
          transform='translate(-6 -7.552)'
          fill={fill}
        />
        <path
          id='Path_12721'
          data-name='Path 12721'
          d='M29.841,27.183H27.77a.887.887,0,1,1,0-1.775h2.07a.3.3,0,0,0,.3-.3V14.405a2.548,2.548,0,0,0-1-2.065l-.024-.018L21.263,6.145,18.734,8.124a.887.887,0,0,1-1.094-1.4l2.69-2.1a1.424,1.424,0,0,1,1.821-.036l.016.012L30.2,10.919a4.329,4.329,0,0,1,1.71,3.486V25.112A2.073,2.073,0,0,1,29.841,27.183Z'
          transform='translate(-10.615 -4.274)'
          fill={fill}
        />
        <path
          id='Path_12722'
          data-name='Path 12722'
          d='M24.335,35.648a.887.887,0,0,1-.887-.887V26.775H18.775V31.33a.887.887,0,0,1-1.775,0V26.479A1.446,1.446,0,0,1,18.479,25h5.265a1.446,1.446,0,0,1,1.479,1.479v8.282A.887.887,0,0,1,24.335,35.648Z'
          transform='translate(-10.493 -12.74)'
          fill={secondaryColorFill}
        />
      </g>
    </SvgIcon>
  );
};
