import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const MenuSettingIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.598' height='16.125' viewBox='0 0 15.598 16.125' {...props}>
      <g id='icons8-setting' transform='translate(-4.8 -4)'>
        <path
          id='Ellipse_3361'
          data-name='Ellipse 3361'
          d='M1.741-1.5A3.241,3.241,0,1,1-1.5,1.741,3.245,3.245,0,0,1,1.741-1.5Zm0,5.267A2.026,2.026,0,1,0-.285,1.741,2.028,2.028,0,0,0,1.741,3.767Z'
          transform='translate(10.838 10.362)'
          fill={fill}
        />
        <path
          id='Path_22504'
          data-name='Path 22504'
          d='M26.119,17.856a.607.607,0,0,1-.247-.052l-1.094-.486-.008,0a1.243,1.243,0,0,0-1.1.054.608.608,0,1,1-.612-1.05,2.442,2.442,0,0,1,2.218-.11l.685.3a6.589,6.589,0,0,0,1.145-1.984l-.6-.426a2.335,2.335,0,0,1-.992-1.959,2.445,2.445,0,0,1,.98-1.95l.006,0,.608-.431a6.75,6.75,0,0,0-1.152-1.981l-.678.3a2.45,2.45,0,0,1-2.2-.1,2.3,2.3,0,0,1-1.208-1.851l-.1-.8a6.828,6.828,0,0,0-1.155-.108,7.188,7.188,0,0,0-1.565.187.608.608,0,0,1-.273-1.184A8.439,8.439,0,0,1,20.609,4a8.721,8.721,0,0,1,1.829.216.608.608,0,0,1,.475.516l.162,1.256q0,.017,0,.034a1.089,1.089,0,0,0,.573.886,1.246,1.246,0,0,0,1.118.064l.008,0,1.094-.486a.608.608,0,0,1,.7.148,7.982,7.982,0,0,1,1.835,3.2.608.608,0,0,1-.233.663l-.969.686a1.244,1.244,0,0,0-.476.965,1.124,1.124,0,0,0,.459.953l.014.01.972.689a.608.608,0,0,1,.233.663,7.8,7.8,0,0,1-1.845,3.209A.608.608,0,0,1,26.119,17.856Z'
          transform='translate(-8.03)'
          fill={fill}
        />
        <path
          id='Path_22505'
          data-name='Path 22505'
          d='M12.579,23.575a8.721,8.721,0,0,1-1.829-.216.608.608,0,0,1-.477-.532l-.122-1.175q0-.01,0-.019a1.089,1.089,0,0,0-.573-.886,1.246,1.246,0,0,0-1.118-.064l-.008,0-1.094.486a.608.608,0,0,1-.7-.148,7.983,7.983,0,0,1-1.835-3.2.608.608,0,0,1,.233-.663l.969-.686a1.244,1.244,0,0,0,.476-.965,1.124,1.124,0,0,0-.459-.953l-.014-.01-.972-.689a.608.608,0,0,1-.233-.663A7.8,7.8,0,0,1,6.669,9.989a.608.608,0,0,1,.687-.137l1.094.486.008,0a1.243,1.243,0,0,0,1.1-.054.608.608,0,1,1,.612,1.05,2.442,2.442,0,0,1-2.218.11l-.685-.3a6.589,6.589,0,0,0-1.145,1.984l.6.426a2.335,2.335,0,0,1,.992,1.959,2.445,2.445,0,0,1-.98,1.95l-.006,0-.608.431a6.75,6.75,0,0,0,1.152,1.981l.678-.3a2.45,2.45,0,0,1,2.2.1,2.3,2.3,0,0,1,1.209,1.859l.074.718a6.81,6.81,0,0,0,1.144.106,7.188,7.188,0,0,0,1.565-.187.608.608,0,1,1,.273,1.184A8.439,8.439,0,0,1,12.579,23.575Z'
          transform='translate(0 -3.45)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
