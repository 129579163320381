import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const HeartHealth: FC<IconProps> = ({ width = "24", height = "22", viewBox = "0 0 24 22", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-heart-health'
        d='M15.707,17.707a1,1,0,0,1-1.414,0l-5-5a1,1,0,0,1,1.414-1.414L15,15.586,24.866,5.72A6.512,6.512,0,0,0,20.455,4,7.131,7.131,0,0,0,15,7,7.131,7.131,0,0,0,9.545,4,6.545,6.545,0,0,0,3,10.545C3,16.886,10.421,22.951,13.8,25.6l0-.006a1.965,1.965,0,0,0,2.4,0l0,.006C19.579,22.951,27,16.886,27,10.545a6.512,6.512,0,0,0-.873-3.257Z'
        transform='translate(-3 -4)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
