import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const BloodIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "20.498px"}
      viewBox={viewBox ? viewBox : "0 0 22 20.498"}
      {...props}
    >
      <path
        id='icons8-sphygmomanometer_1_'
        data-name='icons8-sphygmomanometer (1)'
        d='M8.369-.006A1.294,1.294,0,0,0,7.079,1.283V15.47a1.3,1.3,0,0,0,1.291,1.289h3.224v.015h1.554v1.442a.759.759,0,0,1-.759.759H4.8a.759.759,0,0,1-.759-.759V10.444A3.1,3.1,0,0,0,5.561,7.6c0-1.676-1.019-3.034-2.276-3.034S1.01,5.919,1.01,7.6a3.1,3.1,0,0,0,1.517,2.849v7.771A2.279,2.279,0,0,0,4.8,20.492h7.586a2.279,2.279,0,0,0,2.276-2.276V16.774h4.021a1.292,1.292,0,0,0,1.289-1.289v-2.2a4.931,4.931,0,0,0,0-9.1V1.3A1.294,1.294,0,0,0,18.686.009H15.462V-.006Zm9.709,5.325a3.414,3.414,0,1,1-3.414,3.414A3.391,3.391,0,0,1,18.079,5.319ZM16.941,6.835a.761.761,0,0,0-.538,1.3L17.92,9.652a.771.771,0,0,0,1.077,0,.765.765,0,0,0,0-1.077L17.48,7.057A.761.761,0,0,0,16.941,6.835Z'
        transform='translate(-1.01 0.006)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
