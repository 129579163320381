import { FC } from "react";
import { NotificationIconProps, NotificationWrapperIcon } from "./NotificationWrapperIcon";

export const CallNotificationIcon: FC<NotificationIconProps> = props => {
  return (
    <NotificationWrapperIcon {...props}>
      <g id='icons8-phone-ringing' transform='translate(12 12)'>
        <path
          id='Path_15015'
          data-name='Path 15015'
          d='M28.107,35.414a2.671,2.671,0,0,1-.353-.023h-.008l-3.235-.462-.032-.005a2.609,2.609,0,0,1-2.066-1.832L22,31.65A7.053,7.053,0,0,0,19.55,31.3a7,7,0,0,0-2.451.353l-.411,1.44a2.609,2.609,0,0,1-2.066,1.832.867.867,0,0,1-.31-1.705.894.894,0,0,0,.705-.589l0-.01L15.482,31l.011-.036a1.383,1.383,0,0,1,.856-.881,8.361,8.361,0,0,1,3.2-.52,8.383,8.383,0,0,1,3.2.52,1.383,1.383,0,0,1,.855.881l.011.036.462,1.617,0,.01a.892.892,0,0,0,.692.586l3.211.459a.867.867,0,0,0,.955-.593l.007-.023.338-1.07a2.277,2.277,0,0,0-.694-2.213l-.152-.116c-.19-.145-.406-.31-.648-.471a.867.867,0,1,1,.961-1.442c.288.192.538.383.739.536l.167.127.048.038a4.024,4.024,0,0,1,1.255,3.984q-.006.026-.014.051L30.6,33.57A2.546,2.546,0,0,1,29.526,35,2.639,2.639,0,0,1,28.107,35.414Z'
          transform='translate(-8.069 -14.545)'
          fill='#fff'
        />
        <path
          id='Path_15016'
          data-name='Path 15016'
          d='M7.007,33.894a2.638,2.638,0,0,1-1.419-.412A2.546,2.546,0,0,1,4.51,32.049l-.343-1.087q-.008-.025-.014-.051a3.878,3.878,0,0,1,1.276-4,11.833,11.833,0,0,1,3.654-1.96A20.25,20.25,0,0,1,15.564,24a20.074,20.074,0,0,1,6.4.911.867.867,0,1,1-.548,1.644,18.389,18.389,0,0,0-5.849-.822,18.54,18.54,0,0,0-5.924.857,10.109,10.109,0,0,0-3.121,1.666,2.158,2.158,0,0,0-.692,2.211l.338,1.071.007.023a.867.867,0,0,0,.959.592.867.867,0,0,1,.229,1.718A2.673,2.673,0,0,1,7.007,33.894Z'
          transform='translate(-4.025 -13.024)'
          fill='#fff'
        />
        <path
          id='Line_1834'
          data-name='Line 1834'
          d='M-.634,6.01A.866.866,0,0,1-1.5,5.143V-.633A.866.866,0,0,1-.634-1.5a.866.866,0,0,1,.866.867V5.143A.866.866,0,0,1-.634,6.01Z'
          transform='translate(12.172 1.5)'
          fill='#fff'
        />
        <path
          id='Line_1835'
          data-name='Line 1835'
          d='M1.793,5.432a.867.867,0,0,1-.786-.5l-2.426-5.2A.867.867,0,0,1-1-1.419.866.866,0,0,1,.152-1L2.578,4.2a.867.867,0,0,1-.785,1.233Z'
          transform='translate(4.489 3.233)'
          fill='#fff'
        />
        <path
          id='Line_1836'
          data-name='Line 1836'
          d='M-.634,5.432A.863.863,0,0,1-1,5.351.867.867,0,0,1-1.419,4.2L1.007-1a.866.866,0,0,1,1.152-.419A.867.867,0,0,1,2.578-.267L.152,4.932A.867.867,0,0,1-.634,5.432Z'
          transform='translate(17.429 3.233)'
          fill='#fff'
        />
      </g>
    </NotificationWrapperIcon>
  );
};
