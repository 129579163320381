/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeProfileInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeProfileInfoQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, avatar?: string | null, fullName?: string | null, photo?: string | null, dependents?: Array<{ __typename?: 'User', id: string, fullName?: string | null, avatar?: string | null, photo?: string | null } | null> | null } | null };


export const MeProfileInfoDocument = gql`
    query meProfileInfo {
  me {
    id
    avatar
    fullName
    photo
    dependents {
      id
      fullName
      avatar
      photo
    }
  }
}
    `;

/**
 * __useMeProfileInfoQuery__
 *
 * To run a query within a React component, call `useMeProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeProfileInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeProfileInfoQuery(baseOptions?: Apollo.QueryHookOptions<MeProfileInfoQuery, MeProfileInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeProfileInfoQuery, MeProfileInfoQueryVariables>(MeProfileInfoDocument, options);
      }
export function useMeProfileInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeProfileInfoQuery, MeProfileInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeProfileInfoQuery, MeProfileInfoQueryVariables>(MeProfileInfoDocument, options);
        }
export type MeProfileInfoQueryHookResult = ReturnType<typeof useMeProfileInfoQuery>;
export type MeProfileInfoLazyQueryHookResult = ReturnType<typeof useMeProfileInfoLazyQuery>;
export type MeProfileInfoQueryResult = Apollo.QueryResult<MeProfileInfoQuery, MeProfileInfoQueryVariables>;