import { Box, Container, IconButton, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderContext } from "../../providers/HeaderProvider";
import { ArrowIcon } from "../Icons";
import useStyles from "./PageHeader.styles";

const useCustomHeaderStyles = makeStyles()(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  root: {
    padding: "10px!important",
  },
}));

export const PageHeaderWithPack: FC = () => {
  const { classes, theme, cx } = useStyles({});
  const navigate = useNavigate();
  const { headerTitle } = useHeaderContext();
  const { classes: customClasses } = useCustomHeaderStyles();

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth='sm' className={cx(classes.container, customClasses.root)}>
      <Box className={customClasses.headerContainer}>
        <IconButton onClick={onBackClick} className={classes.iconButton}>
          <ArrowIcon direction='left' />
        </IconButton>
        <Box className={customClasses.title}>
          <Typography fontSize={theme.mixins.fonts.fontSize.lg}>{headerTitle}</Typography>
        </Box>
      </Box>
    </Container>
  );
};
