import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const Ellipse: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8906'
      data-name='Group 8906'
      width={44}
      height={44}
      viewBox='0 0 44 44'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Group_9448' data-name='Group 9448' transform='translate(-1165.066 -185)'>
        <circle id='Ellipse_2622' data-name='Ellipse 2622' cx='22' cy='22' r='22' transform='translate(1165.066 185)' fill='#e05729' />
        <path
          id='icons8-rocket'
          d='M26.979,3.021A24.56,24.56,0,0,0,11,10a7.711,7.711,0,0,0-.859,1.039c-1.241-.1-3.141-.067-4.113.906A13.572,13.572,0,0,0,3,18l5-.715V19l3,3h1.715L12,27a13.572,13.572,0,0,0,6.055-3.027c.973-.972,1.005-2.872.906-4.113A7.711,7.711,0,0,0,20,19,24.56,24.56,0,0,0,26.979,3.021ZM19,9a2,2,0,1,1-2,2A2,2,0,0,1,19,9ZM7.2,20a2.271,2.271,0,0,0-1.037.588c-1.366,1.366-1.148,4.4-1.148,4.4s3.014.236,4.4-1.148A2.277,2.277,0,0,0,10,22.8l-.457-.457a1.256,1.256,0,0,1-.131.2,2.551,2.551,0,0,1-2.437.488,2.549,2.549,0,0,1,.488-2.437,1.369,1.369,0,0,1,.193-.131Z'
          transform='translate(1171.338 191.988)'
          fill={palette.common.white}
        />
      </g>{" "}
    </SvgIcon>
  );
};
