import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const EventAccepted: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='82.25' height='82.25' viewBox='0 0 82.25 82.25' {...props}>
      <path
        id='icons8-event-accepted'
        d='M10.327,4A6.325,6.325,0,0,0,4,10.327V67.269A6.325,6.325,0,0,0,10.327,73.6H37.29a28.074,28.074,0,0,1,.148-19.4l-6.747,4.788a1.394,1.394,0,0,1-2.138-1.551l2.96-9.484-7.97-5.938a1.392,1.392,0,0,1,.816-2.509l9.935-.111,3.182-9.416a1.392,1.392,0,0,1,2.638,0L43.3,39.391l6.586.074A28.2,28.2,0,0,1,73.6,37.29V10.327A6.325,6.325,0,0,0,67.269,4Zm0,6.327H67.269V22.981H10.327ZM64.106,41.962A22.144,22.144,0,1,0,86.25,64.106,22.144,22.144,0,0,0,64.106,41.962ZM73.6,54.615a3.163,3.163,0,0,1,2.237,5.4L63.179,72.669a3.162,3.162,0,0,1-4.473,0l-6.327-6.327a3.163,3.163,0,1,1,4.473-4.473l4.09,4.09L71.359,55.542A3.152,3.152,0,0,1,73.6,54.615Z'
        transform='translate(-4 -4)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
export default EventAccepted;
