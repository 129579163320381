import React from "react";
import { useTheme } from "../../base/mui";
export const PlannerIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26.015' height='23.878' viewBox='0 0 26.015 23.878'>
      <path
        id='icons8-planner_1_'
        data-name='icons8-planner (1)'
        d='M7.655,0A1.077,1.077,0,0,0,6.524.995V1.99H3.131a1.228,1.228,0,0,0-.809.284A.943.943,0,0,0,2,2.985v19.9a.95.95,0,0,0,.323.713,1.237,1.237,0,0,0,.809.282H26.884a1.247,1.247,0,0,0,.811-.282.955.955,0,0,0,.32-.713V2.985a.948.948,0,0,0-.32-.711,1.238,1.238,0,0,0-.811-.284H23.491V.995A1.077,1.077,0,0,0,22.359,0H21.228A1.077,1.077,0,0,0,20.1.995V1.99H9.918V.995A1.077,1.077,0,0,0,8.786,0Zm0,.995H8.786V3.98H7.655Zm13.573,0h1.131V3.98H21.228Zm-18.1,1.99H6.524V3.98a1.077,1.077,0,0,0,1.131.995H8.786A1.077,1.077,0,0,0,9.918,3.98V2.985H20.1V3.98a1.077,1.077,0,0,0,1.131.995h1.131a1.077,1.077,0,0,0,1.131-.995V2.985h3.393V6.467H3.131Zm0,4.477H26.884V22.883H3.131Zm14.81,6.965A1.179,1.179,0,0,0,16.7,15.515v3.793A1.179,1.179,0,0,0,17.941,20.4h4.312a1.179,1.179,0,0,0,1.237-1.088V15.515a1.179,1.179,0,0,0-1.237-1.088Zm0,.995h4.312c.066,0,.106.035.106.093,0,3.85-.04,3.886-.106,3.886H17.941a.094.094,0,0,1-.106-.093V15.515A.093.093,0,0,1,17.941,15.421Z'
        transform='translate(-2)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
