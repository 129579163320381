import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const BmiIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "22px"}
      height={height ? height : "21.92px"}
      viewBox={viewBox ? viewBox : "0 0 22 21.92"}
      {...props}
    >
      <path
        id='icons8-bmi'
        d='M5.984,4.006a2,2,0,0,0-2,2v17.92a1.993,1.993,0,0,0,2,2h18a2,2,0,0,0,2-2V6.006a2.006,2.006,0,0,0-2-2Zm9.01,3a15.634,15.634,0,0,1,7.73,1.68,2.012,2.012,0,0,1,.959,2.4l-1.51,4.551a2.009,2.009,0,0,1-1.9,1.369H9.715a2.009,2.009,0,0,1-1.9-1.369L6.3,11.086a2.012,2.012,0,0,1,.959-2.4A15.634,15.634,0,0,1,14.994,7.006Zm-1,2.02a19.814,19.814,0,0,0-2,.18v.8a1,1,0,0,1-2,0v-.32a9.6,9.6,0,0,0-1.77.75l1.49,4.57h4.879l2.4-3-.6,3h3.879l1.51-4.539A10.165,10.165,0,0,0,19.994,9.7v.311a1,1,0,0,1-2,0V9.217a17.192,17.192,0,0,0-2-.191v.98a1,1,0,0,1-2,0Zm-6,10.98h6v1a1,1,0,0,0,2,0v-1h2v1a1,1,0,0,0,2,0v-1h1.189a.808.808,0,0,1,.811.811V22.2a.808.808,0,0,1-.811.811H7.994Z'
        transform='translate(-3.984 -4.006)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
