import React, { FC } from "react";
import { useTheme } from "../../base/mui";
export const VideoCallIcon: FC = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'>
      <g id='Group_9641' data-name='Group 9641' transform='translate(-286.566 -791)'>
        <circle id='Ellipse_2619' data-name='Ellipse 2619' cx='22' cy='22' r='22' transform='translate(286.566 791)' fill='#e05729' />
        <path
          id='icons8-video-call_2_'
          data-name='icons8-video-call (2)'
          d='M1.6,6A1.6,1.6,0,0,0,0,7.6V18.8a1.6,1.6,0,0,0,1.6,1.6H15.2a1.6,1.6,0,0,0,1.6-1.6V7.6A1.6,1.6,0,0,0,15.2,6ZM23.2,7.6a.8.8,0,0,0-.558.227L18.4,11.6v3.2l4.259,3.789.012.011A.8.8,0,0,0,24,18V8.4A.8.8,0,0,0,23.2,7.6Z'
          transform='translate(296.566 800)'
          fill={palette.common.white}
        />
      </g>
    </svg>
  );
};
