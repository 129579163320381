/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { DoctorInfoFragmentFragmentDoc } from './doctorInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveDoctorsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ActiveDoctorsQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, arabicDisplay?: string | null, display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, name: string, nameAr?: string | null }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null, id: string } | null> | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const ActiveDoctorsDocument = gql`
    query ActiveDoctors($filter: DoctorFilterInput, $first: Int, $last: Int, $after: String, $before: String) {
  doctors(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...DoctorInfoFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${DoctorInfoFragmentFragmentDoc}`;

/**
 * __useActiveDoctorsQuery__
 *
 * To run a query within a React component, call `useActiveDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveDoctorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useActiveDoctorsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveDoctorsQuery, ActiveDoctorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveDoctorsQuery, ActiveDoctorsQueryVariables>(ActiveDoctorsDocument, options);
      }
export function useActiveDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveDoctorsQuery, ActiveDoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveDoctorsQuery, ActiveDoctorsQueryVariables>(ActiveDoctorsDocument, options);
        }
export type ActiveDoctorsQueryHookResult = ReturnType<typeof useActiveDoctorsQuery>;
export type ActiveDoctorsLazyQueryHookResult = ReturnType<typeof useActiveDoctorsLazyQuery>;
export type ActiveDoctorsQueryResult = Apollo.QueryResult<ActiveDoctorsQuery, ActiveDoctorsQueryVariables>;