import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

export interface NotificationIconProps extends SvgIconProps {
  variant?: "circular" | "current";
}

export const NotificationWrapperIcon: FC<PropsWithChildren<NotificationIconProps>> = ({ variant = "current", children, ...props }) => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  const isCircular = variant === "circular";

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={isCircular ? "46" : "45.719"}
      height={isCircular ? "46" : "46.299"}
      viewBox='0 0 46 46'
      {...props}
    >
      {isCircular ? (
        <circle cx='22' cy='22' r='22' fill={fill} transform='translate(1 2)' />
      ) : (
        <g id='Group_11881' data-name='Group 11881' transform='translate(-18 -105)'>
          <g id='Group_10453' data-name='Group 10453' transform='translate(18 105)'>
            <path
              id='Path_12745'
              data-name='Path 12745'
              d='M29.419,45.828a36.284,36.284,0,0,1-15.866-.9C8.27,43.335,2.815,40.27.926,35.728-.916,31.179.789,25.181,3.182,20.068a40.6,40.6,0,0,1,9.5-13.2c4.123-3.876,9.456-7.4,14.252-6.8C31.712.7,35.951,5.47,39.1,10.185a37.942,37.942,0,0,1,6.219,14.58c.983,5.257.85,11.116-2.039,14.856-2.861,3.76-8.478,5.4-13.858,6.208'
              transform='translate(-0.149 0)'
              fill={fill}
              opacity='0.2'
            />
            <path
              id='Path_12746'
              data-name='Path 12746'
              d='M22.3,46.857A35.083,35.083,0,0,1,8.262,40.6C4.033,37.349.152,32.707,0,27.952-.1,23.208,3.5,18.369,7.413,14.567A39.256,39.256,0,0,1,20.532,5.89C25.589,3.8,31.621,2.438,35.749,4.631c4.1,2.211,6.3,7.976,7.525,13.318A36.705,36.705,0,0,1,43.9,33.265c-.915,5.09-3.045,10.342-6.939,12.732-3.877,2.418-9.518,1.974-14.659.86'
              transform='translate(0.673 -1.493)'
              fill={fill}
            />
          </g>
        </g>
      )}
      {children}
    </SvgIcon>
  );
};
