import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const DoubleTickIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='24.863' height='13.329' viewBox='0 0 24.863 13.329' {...props}>
      <path
        id='icons8-double-tick_1_'
        data-name='icons8-double-tick (1)'
        d='M19.63,7.99a.888.888,0,0,0-.61.269l-8.252,8.252L7.852,13.6l-.009-.009a.888.888,0,1,0-1.256,1.256l2.924,2.924L8.1,19.175,2.516,13.587A.888.888,0,1,0,1.26,14.843l6.216,6.216a.888.888,0,0,0,1.256,0L20.275,9.515A.888.888,0,0,0,19.63,7.99ZM24.975,8a.888.888,0,0,0-.628.26L13.432,19.175l-.26-.26L11.916,20.17l.888.888a.888.888,0,0,0,1.256,0L25.594,9.523A.888.888,0,0,0,24.975,8Z'
        transform='translate(-1 -7.99)'
        fill={props.fill ? props.fill : palette.stale.main}
      />
    </SvgIcon>
  );
};
export default DoubleTickIcon;
